import React, { FC } from 'react';
import Button from 'components/UI/controls/Button';

interface Props {
  onClick: (data: React.MouseEvent) => void;
  isUpdate?: boolean;
  text?: string;
}

export const ButtonAddNote: FC<Props> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      className={
        'note-creator__button-add-note' + (props.isUpdate ? ' update' : '')
      }
      variant="dark">
      {props.text ? props.text : 'Add'}
    </Button>
  );
};

export default ButtonAddNote;
