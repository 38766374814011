import React, { FC, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

/* components */
import PopupFullImage from "components/modals/PopupFullImage";
import OptionsMenu from "components/UI/controls/OptionsMenu";
import Link from "components/UI/controls/Link";

/* controllers */
import { getPropertyAction } from "controllers/property/actions";

/* types */
import { IStore } from "controllers/store";
import { IPropertyState } from "controllers/property/models";
import uuid from "utils/uuid";
import CustomScrollbars from "components/CustomScrollbars";
import { FileIcon } from "components/icons/FileIcon";
import { IMAGE_TYPES } from "config";
import { addShowedElement } from "controllers/showElement/actions";
import { propertyModalKey } from "components/modals/properties/PropertyModalConent";
import {
  ShowElementEnum
} from "controllers/showElement/models.d";

interface Props {
  propertyId: number;
  propertyState: IPropertyState;
}

const PropertySupplementsTab: FC<Props> = (props) => {
  const {
    propertyId,
    propertyState: { propertyData }
  } = props;

  const dispatch = useDispatch();

  const [isFullImageOpen, setIsFullImageOpen] = useState<boolean>(false);
  const [fullImageUrl, setFullImageUrl] = useState<string>("");

  const supplements = propertyData[propertyId]?.supplements;
  const documents = supplements?.filter(
    (supplement) =>
      !IMAGE_TYPES.some((type) => supplement.title?.endsWith(type))
  );
  const images = supplements?.filter((supplement) =>
    IMAGE_TYPES.some((type) => supplement.title?.endsWith(type))
  );

  const showFullImage = (imageUrl: string) => {
    setFullImageUrl(imageUrl);
    setIsFullImageOpen(true);
  };

  useEffect(() => {
    dispatch(getPropertyAction.request({ id: propertyId }));
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Supplements | Voltore{" "}
          {process.env.REACT_APP_COMPANY_NAME
            ? `| ${process.env.REACT_APP_COMPANY_NAME}`
            : ""}
        </title>
        <meta name="description" content="Voltore application" />
      </Helmet>
      <CustomScrollbars id="supplements-tab-container">
        <div className="properties__supplements-tab supplements-tab">
          <header>
            <h3 className="supplements-tab__title">Supplements</h3>

            <OptionsMenu
              on={{
                edit: () => {
                  dispatch(addShowedElement({
                    key: propertyModalKey,
                    id: uuid(),
                    type: ShowElementEnum.modal,
                    props: {
                      id: propertyId
                    }
                  }));
                  // addShowedElement({
                  //   id: uuid(),
                  //   key: propertyModalKey,
                  //   type: ShowElementEnum.modal,
                  //   props: { id: propertyId },
                  // } as IModalElement);
                }
              }}
            />
          </header>

          {(documents?.length || 0) > 0 && (
            <div className="supplements-tab__documents-list">
              {documents?.map((document) => (
                <Link
                  className="document-supplement"
                  url={document.url}
                  key={document.id}
                  isNewTab={true}>
                  <div className="document-supplement__image">
                    {document.preview ? (
                      <img src={document.preview} alt="" />
                    ) : (
                      <FileIcon />
                    )}
                  </div>
                  <div className="document-supplement__title">
                    {document.title}
                  </div>
                </Link>
              ))}
            </div>
          )}

          {(images?.length || 0) > 0 && (
            <div className="supplements-tab__images-list">
              {images?.map((image) => (
                <div
                  className="supplements-image"
                  key={image.id}
                  onClick={() => showFullImage(image.url)}>
                  <div className="supplements-image__preview">
                    <img src={image.url} alt="" />
                  </div>

                  <div className="supplements-image__name">
                    {image.title?.replace(/\.[^/.]+$/, "")}
                  </div>
                </div>
              ))}
            </div>
          )}

          {!documents?.length && !images?.length ? (
            <span className="no-items-specified" style={{ margin: "0px" }}>
              No supplements are specified.
            </span>
          ) : (
            ""
          )}
        </div>
      </CustomScrollbars>

      {isFullImageOpen && (
        <PopupFullImage
          url={fullImageUrl}
          onClose={() => setIsFullImageOpen(false)}
        />
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  propertyState: store.property
}))(PropertySupplementsTab);
