import { appName } from 'config';
import { createAsyncAction, createAction } from 'typesafe-actions';

import {
  TagCategoryGetListRequest,
  TagCategoryGetListResponse,
  TagDetachRequest,
  TagDetachResponse,
  TagDTO,
  TagCreateRequest,
  TagCreateResponse,
  TagDeleteRequest,
  TagDeleteResponse,
  TagUpdateRequest,
  TagUpdateResponse,
  TagCategoryUpdateRequest,
  TagCategoryUpdateResponse,
  TagCategoryCreateRequest,
  TagCategoryCreateResponse,
  TagCategoryDeleteResponse,
  TagCategoryDeleteRequest
} from "@ternala/voltore-types";
import { TagEntityEnum } from "@ternala/voltore-types/lib/card";
import { IException } from '../store';

/* Actions */
export const widgetName = 'tag';

// ** Action
export const setTagsAction = createAction(`${widgetName}/SET_TAGS`)<{
  tags: TagDTO[];
  entityId: number;
  entityType: TagEntityEnum;
}>();

export const addTagAction = createAction(`${widgetName}/ADD_TAG`)<{
  tag: TagDTO;
  entityId: number;
  entityType: TagEntityEnum;
}>();

export const deleteTagLocallyAction = createAction(`${widgetName}/DELETE_TAG_LOCALLY`)<{
  tagId: number;
  entityId: number;
  entityType: TagEntityEnum;
}>();

export const getTagCategoriesAction = createAsyncAction(
  `${appName}/${widgetName}/GET_TAG_CATEGORIES_REQUEST`,
  `${appName}/${widgetName}/GET_TAG_CATEGORIES_SUCCESS`,
  `${appName}/${widgetName}/GET_TAG_CATEGORIES_FILED`,
)<
  TagCategoryGetListRequest & { callback?: Function },
  {
    response: TagCategoryGetListResponse;
    searchParams: TagCategoryGetListRequest;
    isAll: boolean;
  },
  IException
>();

export const createTagAction = createAsyncAction(
  `${appName}/${widgetName}/CREATE_TAG_REQUEST`,
  `${appName}/${widgetName}/CREATE_TAG_SUCCESS`,
  `${appName}/${widgetName}/CREATE_TAG_FILED`,
)<
  TagCreateRequest & { callback?: Function },
  { response: TagCreateResponse },
  IException
>();

export const updateTagAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_TAG_REQUEST`,
  `${appName}/${widgetName}/UPDATE_TAG_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_TAG_FILED`,
)<
  TagUpdateRequest & { callback?: Function; groupId?: number },
  TagUpdateResponse & { groupId?: number },
  IException
>();

export const createTagCategoryAction = createAsyncAction(
  `${appName}/${widgetName}/CREATE_TAG_CATEGORY_REQUEST`,
  `${appName}/${widgetName}/CREATE_TAG_CATEGORY_SUCCESS`,
  `${appName}/${widgetName}/CREATE_TAG_CATEGORY_FILED`,
)<
  TagCategoryCreateRequest & { callback?: Function },
  TagCategoryCreateResponse,
  IException
>();

export const updateTagCategoryAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_TAG_CATEGORY_REQUEST`,
  `${appName}/${widgetName}/UPDATE_TAG_CATEGORY_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_TAG_CATEGORY_FILED`,
)<
  TagCategoryUpdateRequest & { callback?: Function },
  TagCategoryUpdateResponse,
  IException
>();

export const detachTagAction = createAsyncAction(
  `${appName}/${widgetName}/DETACH_TAG_REQUEST`,
  `${appName}/${widgetName}/DETACH_TAG_SUCCESS`,
  `${appName}/${widgetName}/DETACH_TAG_FILED`,
)<
  TagDetachRequest & {
    id?: number;
    entity?: { type: TagEntityEnum; id: number };
    callback?: Function;
  },
  TagDetachResponse & {
    id?: number;
    entity?: { type: TagEntityEnum; id: number };
  },
  IException
>();

export const deleteTagAction = createAsyncAction(
  `${appName}/${widgetName}/DELATE_TAG_REQUEST`,
  `${appName}/${widgetName}/DELATE_TAG_SUCCESS`,
  `${appName}/${widgetName}/DELATE_TAG_FILED`,
)<
  TagDeleteRequest & { callback?: Function; groupId?: number },
  TagDeleteResponse & { id?: number; groupId?: number },
  IException
>();

export const deleteTagCategoryAction = createAsyncAction(
  `${appName}/${widgetName}/DELATE_TAG_CATEGORY_REQUEST`,
  `${appName}/${widgetName}/DELATE_TAG_CATEGORY_SUCCESS`,
  `${appName}/${widgetName}/DELATE_TAG_CATEGORY_FILED`,
)<
  TagCategoryDeleteRequest & { callback?: Function },
  TagCategoryDeleteResponse & { id?: number },
  IException
>();
