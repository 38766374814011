import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const FiltersOpenArrow: FC<Props> = ({ color, ...props }) => (
   <svg
      {...props}
      width="9"
      height="18"
      viewBox="0 0 9 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 9L9 0V18L0 9Z"
         fill={color}
      />
   </svg>
)

FiltersOpenArrow.defaultProps = {
   color: "#1F2531"
}