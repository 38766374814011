import { INotification } from './models.d';
import { createAction } from 'typesafe-actions';

/* Actions */
export const widgetName = 'modal';

// ** Action
export const addNotification = createAction(
  `${widgetName}/ADD_NOTIFICATION`,
)<INotification>();

export const removeNotification = createAction(
  `${widgetName}/REMOVE_NOTIFICATION`,
)<{ id: string }>();
