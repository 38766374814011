/* eslint-disable */
import React, { FC, RefObject, useEffect, useRef, useState } from "react";

import {
  CardActionEnum,
} from "@ternala/voltore-types/lib/constants";

/* draft modules */
import {
  ContentState,
  EditorState,
  getDefaultKeyBinding
} from "draft-js";
import {
  Editor,
  RawDraftContentState,
  SyntheticKeyboardEvent
} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

/* types */
import {
  ActionListTypeEnum,
} from "../../model.d";

//Actions
import { CardFilterEnum } from "controllers/card/models";

//Utils
import {
  getSearchValue,
  removeAction
} from "../utils";

/* components */
import ActionsListExpanded from "../Actions/ActionListExpanded";

//Config
import ActionBlockPortal from "../../../Hocs/ActionBlockPortal";
import { moveFocusToEnd } from "../../Draft/utils";
import { controlSymbol, startPhrase } from "../config";

export interface IControlInputProps {
  id: string;

  searchValue: string;
  setSearchValue: (value: string) => void;

  closeControl: () => void;
  openTagEditor: () => void;
  openReminderEditor: () => void;

  modal: CardFilterEnum;
}

let hasFocusOutside = false;
let hasFocusOutsideObj: { [key in CardFilterEnum]?: boolean } = {};

export const ControlInput: FC<IControlInputProps> = ({ modal, openTagEditor, openReminderEditor, closeControl, searchValue: searchValueParent }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const actionBlockRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef() as RefObject<any>;

  const [hasFocus, setHasFocus] = useState<boolean>(true);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(ContentState.createFromText('/' + searchValueParent))
  );

  const [searchValue, setSearchValue] = useState<string>(searchValueParent);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef?.current?.contains(event.target as Node) ||
      actionBlockRef?.current?.contains(event.target as Node)
    ) {
      if (!hasFocusOutsideObj[modal]) {
        setHasFocus(true);
        hasFocusOutsideObj[modal] = true;
      }
    } else {
      if (hasFocusOutside) {
        setHasFocus(false);
        hasFocusOutsideObj[modal] = false;
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      editorRef.current?.focusEditor();
      setEditorState(moveFocusToEnd(editorState));
    }, 1);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // If we try to edit card
  const onEditorValueChange = (contentStateRaw: RawDraftContentState) => {
    let contentState = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    const currentBlockIndex = contentState
      .getBlockMap()
      .keySeq()
      .findIndex((key) => key === currentSelection.getStartKey());

    const currentBlock = contentStateRaw.blocks[currentBlockIndex];
    const currentText = currentBlock.text;

    if (currentText.indexOf(controlSymbol) === -1) closeControl();
    const newSearchValue = currentText.split(controlSymbol)[1];
    if(newSearchValue !== searchValue){
      setSearchValue(newSearchValue);
    }
  };

  const myKeyBindingFn = (e: SyntheticKeyboardEvent): string | null => {
    // Handle key press
    if (e.key === 'Enter') {
      // prevent row split when open menu and press enter button
      e.preventDefault();
      return null;
    }
    return getDefaultKeyBinding(e); // use default handler
  };

  return (
    <div className={'reminder-editor'}>
      {(hasFocus) && (
        <ActionBlockPortal modal={modal}>
          <div className="note-creator__control-list" ref={actionBlockRef}>
            <div className={'note-creator__control-list_items'}>
              <ActionsListExpanded
                type={ActionListTypeEnum.Actions}
                searchValue={searchValue}
                onSelect={(action) => {
                  if (action.key === CardActionEnum.TAG) {
                    closeControl();
                    openTagEditor();
                  }
                  if (action.key === CardActionEnum.REMIND) {
                    // setEditorState(removeAction(editorState, '/', false));
                    closeControl();
                    openReminderEditor();
                  }
                }}
              />
            </div>
          </div>
        </ActionBlockPortal>
      )}
      <Editor
        editorState={editorState}
        editorClassName={'reminder-creator__input-field'}
        onEditorStateChange={(editorState) => {
          setEditorState(editorState);
        }}
        onChange={onEditorValueChange}
        // @ts-ignore
        keyBindingFn={myKeyBindingFn}
        ref={editorRef}
        toolbarHidden={true}
        onFocus={() => {
          setHasFocus(true);
          hasFocusOutside = true;
          hasFocusOutsideObj[modal] = true;
        }}
      />
    </div>
  );
};

export default ControlInput;
