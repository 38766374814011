import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const DotsIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    width="19"
    height="3"
    viewBox="0 0 19 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="1.5"
      cy="1.5"
      r="1.5"
      fill={color}
    />
    <circle
      cx="9.5"
      cy="1.5"
      r="1.5"
      fill={color}
    />
    <circle
      cx="17.5"
      cy="1.5"
      r="1.5"
      fill={color}
    />
  </svg>
);

DotsIcon.defaultProps = {
  color: '#1F2531',
};
