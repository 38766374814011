/* eslint-disable array-callback-return */

import React, { FC, useState, useEffect, ReactNode } from 'react';

/* components */
import Select from 'components/UI/controls/Select';
import { InfoIcon } from './Controls/InfoIcon';
import ListingStatusItem from './ListingStatusItem';

/* listing forms */
import leaseListingForm from '../../../../assets/img/lease-status-order.jpg';
import saleListingForm from '../../../../assets/img/sale-status-order.jpg';

/* styles */
import styles from './SelectListingStatus.module.scss';
import { Color } from '../shared/styles';

/* types */
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  SaleTransactionStatusHistoryDTO,
  LeaseTransactionStatusHistoryDTO,
} from '@ternala/voltore-types';
import {
  LeaseTransactionStatusEnum,
  SaleTransactionStatusEnum,
} from '@ternala/voltore-types/lib/constants';
import { OptionType } from 'models';

/* utils */
import { uniqueNumberId } from 'utils/helper-functions';
import { useTrail, a } from '@react-spring/web';

const Trail: React.FC<{
  open: boolean;
  transactionType: 'lease' | 'sale';
}> = ({ open, children, transactionType }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    from: { opacity: 0, x: 20, height: 0 },
  });
  return (
    <div>
      {trail.map(({ ...style }, index) => (
        <a.div
          className={styles.listing_status_form}
          key={index}
          style={{
            ...style,
            width: open
              ? transactionType === 'lease'
                ? '338px'
                : '464px'
              : '0px',
            right: transactionType === 'lease' ? '175px' : '55px',
          }}>
          <a.div style={{ boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.1)' }}>
            {items[index]}
          </a.div>
        </a.div>
      ))}
    </div>
  );
};

interface IProps {
  containerStyle?: { [key: string]: number | string };
  label?: string;
  history:
    | SaleTransactionStatusHistoryDTO[]
    | LeaseTransactionStatusHistoryDTO[];
  options: OptionType[];
  onUpdate: (
    history:
      | SaleTransactionStatusHistoryDTO[]
      | LeaseTransactionStatusHistoryDTO[],
  ) => void;
  setActiveStatus?: boolean;
  transactionType: 'lease' | 'sale';
  required?: boolean;
  disabled?: boolean;
  /* Error */
  error: string | undefined;
  setError: (err: string | undefined) => void;
}

export const SelectListingStatus: FC<IProps> = (props) => {
  const {
    error,
    label,
    history,
    onUpdate,
    required,
    disabled,
    setError,
    setActiveStatus,
    transactionType,
  } = props;

  const sortStatusHistory = (
    a: SaleTransactionStatusHistoryDTO | LeaseTransactionStatusHistoryDTO,
    b: SaleTransactionStatusHistoryDTO | LeaseTransactionStatusHistoryDTO,
  ) => Number(new Date(a.startDate)) - Number(new Date(b.startDate));

  const [value, setValue] = useState<OptionType | undefined>();
  const [info, setInfo] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [statusHistory, setStatusHistory] = useState<any[]>(
    [...history].sort(sortStatusHistory) || [],
  );

  useEffect(() => {
    if (setActiveStatus) {
      const findSoldStatus = props.options.find(
        (option) => option.value === SaleTransactionStatusEnum.Sold,
      );
      onChange(
        findSoldStatus
          ? SaleTransactionStatusEnum.Sold
          : LeaseTransactionStatusEnum.Leased,
      );
    }
  }, [setActiveStatus]);

  useEffect(() => {
    setError(undefined);
  }, [statusHistory]);

  const isValid = (
    date: SaleTransactionStatusHistoryDTO | LeaseTransactionStatusHistoryDTO,
  ) => {
    return isNaN(Date.parse(String(date.startDate)));
  };

  const updateStatusHistory = (
    date: MaterialUiPickersDate,
    value: string | null | undefined,
    id: number,
  ) => {
    setHasError(false);
    const updatedStatusHistory = [...statusHistory];
    const updatedElement = updatedStatusHistory.find((el) => el.id === id);

    let isError = updatedStatusHistory.some(isValid);

    if (date !== null && !isNaN(Date.parse(date?.toString())) && !isError) {
      updatedElement.startDate = date?.toISOString();
      setStatusHistory(updatedStatusHistory.sort(sortStatusHistory));
    }
  };

  const onChange = (
    status: SaleTransactionStatusEnum | LeaseTransactionStatusEnum,
  ) => {
    const updatedStatusHistory = [...statusHistory];
    updatedStatusHistory.push({
      id: uniqueNumberId(),
      startDate: new Date().toISOString(),
      status,
    });
    updatedStatusHistory.sort(sortStatusHistory);
    setStatusHistory(updatedStatusHistory);
    onUpdate(updatedStatusHistory);
    setValue({ label: status, value: status });
  };

  const removeFromHistory = (itemId: number) => {
    const updatedStatusHistory = statusHistory.filter(
      (item) => item.id !== itemId,
    );
    setStatusHistory(updatedStatusHistory);
    onUpdate(updatedStatusHistory);
  };

  return (
    <div
      style={{ ...props.containerStyle }}
      className={'field' + (hasError ? ' error' : '')}>
      <div
        className={styles.label}
        style={{
          color: disabled
            ? Color.disabled
            : hasError || error
            ? Color.error
            : Color.label,
        }}>
        {label}
        {required && <span className={styles.required}>*</span>}
        <span
          className={styles.info}
          onMouseEnter={() => setInfo(true)}
          onMouseLeave={() => setInfo(false)}>
          <InfoIcon />
        </span>
      </div>

      <Trail open={info} transactionType={transactionType}>
        <img
          src={transactionType === 'lease' ? leaseListingForm : saleListingForm}
          alt="Listing status form"
        />
      </Trail>

      {statusHistory.map((item) => (
        <ListingStatusItem
          key={item.id}
          item={item}
          removeItem={(id) => removeFromHistory(id)}
          onChange={updateStatusHistory}
          onError={(error: ReactNode, value: any) => {
            setHasError(Boolean(error));
            if (error)
              Boolean(error) ? setError('Status error') : setError(undefined);
          }}
        />
      ))}
      {error ? <span className={styles.listing_error}>{error}</span> : ''}
      <Select
        placeholder="Select one"
        options={props.options}
        value={value}
        onChange={(option) =>
          onChange(option.value as SaleTransactionStatusEnum)
        }
        autoResetValue
        halfWidth
      />
    </div>
  );
};

SelectListingStatus.defaultProps = {
  label: 'Listing status',
};

export default SelectListingStatus;
