import React, {
  FC,
  useState,
  useEffect,
  useRef,
  RefObject,
  ChangeEvent,
  ClipboardEvent,
} from 'react';

/* components */
import Loader from 'components/Loader';
import { SelectDropdownIcon } from 'components/icons/SelectDropdownIcon';
import { TimesIcon } from 'components/icons/TimesIcon';
import { Creation } from '../shared/components/Creation';

/* utils */
import { getUniqueId } from 'utils/helper-functions';

/* styles */
import { Color } from '../shared/styles';
import style from './SelectSearch.module.scss';

/* types */
import { ICreation, ItemsLoadType, OptionType } from 'models';
import Datalist from './Optionslist';
import { LOADED_ITEMS_LIMIT } from 'config';

export interface AsyncOptions {
  limit: number;
  query: string;
  offset: number;
}

interface Props {
  label?: string;
  style?: { [key: string]: number | string };
  className?: string;
  name?: string;
  placeholder?: string;
  value?: OptionType;
  options?: OptionType[];
  searchByFields?: string[];
  asyncOptions?: (searchParams: AsyncOptions) => Promise<OptionType[]>;
  creation?: ICreation;
  onChange: (option?: OptionType) => void;
  maxOptions?: number;
  optionHeight?: number;
  fullWidth?: boolean;
  required?: boolean;
  isDisabled?: boolean;
  readonly?: boolean;
  children?: JSX.Element | JSX.Element[];
  tags?: number[];
  uppercase?: boolean;
  selectedOptions?: OptionType[];
}

export const SelectSearch: FC<Props> = (props) => {
  const {
    label,
    options,
    asyncOptions,
    uppercase,
    searchByFields,
    optionHeight,
    maxOptions,
    creation,
    isDisabled,
    required,
    selectedOptions,
    fullWidth,
    tags,
  } = props;

  const menuHeight = optionHeight && maxOptions && optionHeight * maxOptions;
  const [menuHeightMin, setMenuHeightMin] = useState<number | undefined>(
    menuHeight,
  );

  const [isAllItemsLoaded, setIsAllItemsLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isSelected, setSelected] = useState<boolean>(false);

  const [value, setValue] = useState<string>(props.value?.label || '');
  const [filteredOptions, setFilteredOptions] = useState<
    OptionType[] | undefined
  >();
  const [fetchedOptions, setFetchedOptions] = useState<OptionType[]>([]);

  const timeout = useRef<number>();
  const inputRef = useRef() as RefObject<HTMLInputElement>;
  const inputId = useRef(`input-${getUniqueId()}`);

  if (options && asyncOptions) {
    throw new Error(
      'only static options or async options function can be provided',
    );
  }

  /* effects */
  useEffect(() => {
    if (options?.length && !asyncOptions) setFilteredOptions(options);
  }, []);

  useEffect(() => {
    if (options?.length) setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if(value === "" && isMenuOpen) setFilteredOptions(options);
  }, [isMenuOpen, value])

  useEffect(() => {
    if (isMenuOpen && asyncOptions) {
      asyncLoadOptions('start');
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (!props.value) return;
    //@ts-ignore
    if (props.value.label || props.value.title) {
      setSelected(true);
      //@ts-ignore
      setValue(props.value.label || props.value.title);
    }
  }, [props.value]);

  useEffect(() => {
    if (menuHeight && optionHeight && filteredOptions) {
      if (optionHeight * filteredOptions?.length <= menuHeight) {
        setMenuHeightMin(filteredOptions.length * optionHeight);
      } else {
        setMenuHeightMin(menuHeight);
      }
    }
  }, [filteredOptions?.length]);

  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, []);

  /* handlers */
  const onClickOutside = (event: MouseEvent) => {
    if (event.target instanceof HTMLElement) {
      if (
        event.target &&
        event.target.tagName === 'INPUT' &&
        inputId.current !== event.target.id
      )
        setIsMenuOpen(false);
      if (
        !event.target.className.includes('SelectSearch') &&
        !event.target.className.includes('creation')
      )
        setIsMenuOpen(false);
    }
  };

  const asyncLoadOptions = async (loadType: ItemsLoadType, localValue?: string): Promise<void> => {
    if (asyncOptions && !loading) {
      setLoading(true);
      const options = await asyncOptions({
        limit: Number(LOADED_ITEMS_LIMIT),
        query: localValue ?? value,
        offset: loadType === 'more' ? fetchedOptions.length : 0,
      });
      const filteredAsyncOptions = options.filter((option) => {
        if (!selectedOptions) return option;
        return selectedOptions.every(
          (selected) => selected.value !== option.value,
        );
      });
      if (filteredAsyncOptions.length < LOADED_ITEMS_LIMIT)
        setIsAllItemsLoaded(true);
      setFetchedOptions([
        ...(loadType === 'more' ? fetchedOptions || [] : []),
        ...filteredAsyncOptions,
      ]);
      setFilteredOptions([
        ...(loadType === 'more' ? filteredOptions || [] : []),
        ...filteredAsyncOptions,
      ]);
      setLoading(false);
    }
  };

  const openMenu = () => {
    if (isDisabled) return;
    setIsMenuOpen(true);
    document.addEventListener('click', onClickOutside);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    resetState();
    document.removeEventListener('click', onClickOutside);
  };

  const onInputClick = () => {
    if (isSelected || isDisabled) return;

    if (!isMenuOpen) {
      inputRef.current && inputRef.current.focus();
    }
    if (isMenuOpen) {
      inputRef.current && inputRef.current.blur();
      resetState();
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const filterOptionsByInputValue = (
    event: ChangeEvent<HTMLInputElement> | ClipboardEvent<HTMLInputElement>,
  ) => {
    const { value } = (event as any).target;
    setValue(value);
    if (asyncOptions) {
      clearTimeout(timeout.current);
      timeout.current = window.setTimeout(function (value: string) {
        asyncLoadOptions('start', value);
      }, 200, value);
      return
    }
    const filteredOptionsFunction = (array: OptionType[]) => {
      return array.filter((option) => {
        return searchByFields && searchByFields.length > 0
          ? searchByFields
              .filter((field) => (option as any)[field])
              .some((field) =>
                (option as any)[field]
                  .trim()
                  .toLowerCase()
                  .includes(value.trim().toLowerCase()),
              )
          : option.label
              ?.trim()
              .toLowerCase()
              .includes(value.trim().toLowerCase());
      });
    };
    if (filteredOptionsFunction(options || [])?.length)
      setFilteredOptions(filteredOptionsFunction(options || []));
    else setFilteredOptions([]);
  };

  const selectOption = (option: OptionType) => {
    setValue(option.label || '');
    setSelected(true);
    setIsMenuOpen(false);
    props.onChange(option);
  };

  const removeSelection = () => {
    resetState();
    setSelected(false);
    props.onChange(undefined);
  };

  const resetState = () => {
    setValue('');
    setFilteredOptions(options);
  };

  return (
    <div className={fullWidth ? style.container_full : style.container}>
      {label && (
        <label
          htmlFor={inputId.current}
          className={style.label}
          style={{
            color:
              props.isDisabled && !isSelected ? Color.disabled : Color.label,
          }}>
          {label}
          {required && <span className={style.required}>*</span>}
        </label>
      )}

      {isSelected && (
        <div
          className={
            style.selection_single +
            (props.readonly ? ` ${style.selection_readonly}` : '')
          }>
          <span style={{ textTransform: uppercase ? 'uppercase' : 'initial' }}>
            {value}
          </span>

          {!props.readonly && (
            <span onClick={removeSelection} title="Remove selected option">
              {/* <TimesIcon /> */}
            </span>
          )}
        </div>
      )}

      {!isSelected && (
        <div className={style.control} onClick={onInputClick}>
          <input
            className={style.input}
            id={inputId.current}
            ref={inputRef}
            type="text"
            name={props.name}
            onPaste={filterOptionsByInputValue}
            value={value}
            onChange={filterOptionsByInputValue}
            placeholder={
              isMenuOpen
                ? ''
                : props.placeholder
                ? props.placeholder
                : creation
                ? 'Select one or create new'
                : 'Select one'
            }
            disabled={props.isDisabled}
          />

          <div className={style.dropdown_indicator}>
            {isMenuOpen ? (
              <TimesIcon onClick={closeMenu} />
            ) : (
              <SelectDropdownIcon onClick={openMenu} />
            )}
          </div>
        </div>
      )}

      {isMenuOpen && (
        <div
          className={style.menu_wrapper}
          style={{
            height: menuHeight,
            ...(tags
              ? { width: '107%', marginLeft: '-10px', marginTop: '15px' }
              : ''),
          }}>
          <div className={style.menu}>
            {filteredOptions?.length ? (
              <Datalist
                tags={tags}
                loading={loading}
                menuHeight={menuHeight}
                selectOption={selectOption}
                onLoadMore={asyncLoadOptions}
                menuHeightMin={menuHeightMin}
                filteredOptions={filteredOptions}
                allItemsLoaded={isAllItemsLoaded}
              />
            ) : loading ? (
              <div
                className="select-options__loader"
                style={{
                  width: '100%',
                  display: 'flex',
                  margin: '10px 0',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Loader />
              </div>
            ) : (
              <div className={style.no_options_message} key="empty">
                No results
              </div>
            )}

            {creation && (
              <Creation
                creation={creation}
                topIndent={
                  loading
                    ? undefined
                    : filteredOptions?.length
                    ? menuHeightMin
                    : optionHeight
                }
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

SelectSearch.defaultProps = {
  optionHeight: 26,
  maxOptions: 6,
};

export default SelectSearch;
