import { all, call, put, takeEvery } from "redux-saga/effects";

// APIs
import { PropertyApi } from '../transport/property.api';

// Actions
import {
  getPropertiesAction,
  getPropertyAction,
  createPropertyAction,
  updatePropertyAction,
  deletePropertyAction,
  getPropertyTreeAction,
  getMapItemsAction,
} from '../actions';

import { addError, addLoader, removeLoader } from '../index';

// Utils
import uuid from 'utils/uuid';

// Constants
import { LoaderAction } from 'config/constants';
import {
  EnterpriseFullWithTreeDTO,
  PropertyCreateResponse,
  PropertyDeleteResponse,
  PropertyGetListResponse,
  PropertyGetResponse,
  PropertyMapGetListResponse,
  PropertyUpdateResponse,
} from '@ternala/voltore-types';
import { IError } from '../../model';
import { getAccessTokenSaga } from "../../auth/sagas/auth";

export function* getPropertiesSaga({
  payload,
}: ReturnType<typeof getPropertiesAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga)
  const loadId = uuid();

  try {
    yield put(
      addLoader({
        id: loadId,
        message: 'Please wait, properties are loading!',
        type: LoaderAction.property.getList,
      }),
    );

    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: PropertyGetListResponse | string | IError =
      yield PropertyApi.getProperties(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getPropertiesAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getPropertiesAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get properties!',
          type: LoaderAction.property.getList,
        }),
      );
    } else {
      yield put(
        getPropertiesAction.success({
          response: res,
          searchParams: payload,
          isAll: payload.limit ? res.items.length < payload.limit : true,
        }),
      );
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getPropertiesAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to get properties!',
        type: LoaderAction.property.getList,
      }),
    );
  }
}

export function* getPropertiesMapSaga({
  payload,
}: ReturnType<typeof getMapItemsAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();

  try {
    yield put(
      addLoader({
        id: loadId,
        message: 'Please wait, map items are loading!',
        type: LoaderAction.property.getList,
      }),
    );

    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: PropertyMapGetListResponse | string | IError =
      yield PropertyApi.getMapItems(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getMapItemsAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getMapItemsAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get maps!',
          type: LoaderAction.property.getList,
        }),
      );
    } else {
      yield put(
        getMapItemsAction.success({
          response: res,
          searchParams: payload,
          isAll: payload.limit
            ? (res.properties?.length || 0) < payload.limit
            : true,
        }),
      );
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getMapItemsAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to get map items!',
        type: LoaderAction.property.getList,
      }),
    );
  }
}

export function* createPropertySaga({
  payload,
}: ReturnType<typeof createPropertyAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, property is creating!',
      type: LoaderAction.property.create,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: PropertyCreateResponse | string | IError =
      yield PropertyApi.createProperty(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        createPropertyAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          createPropertyAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to create property!',
          type: LoaderAction.property.create,
        }),
      );
    } else {
      yield put(createPropertyAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(true, res.id);
    }
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      createPropertyAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to create property!',
        type: LoaderAction.property.create,
      }),
    );
  }
}

export function* getPropertySaga({
  payload,
}: ReturnType<typeof getPropertyAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, property is getting!',
      type: LoaderAction.property.getItem,
      purposeId: payload.id,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: PropertyGetResponse | string | IError =
      yield PropertyApi.getProperty(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getPropertyAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getPropertyAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get property!',
          type: LoaderAction.property.getItem,
        }),
      );
    } else {
      yield put(getPropertyAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(true, res.id);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getPropertyAction.failure({
        key: String(payload.id),
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        key: String(payload.id),
        id: loadId,
        message: error.message || 'Failed to get property!',
        type: LoaderAction.property.getItem,
      }),
    );
  }
}

export function* updatePropertySaga({
  payload,
}: ReturnType<typeof updatePropertyAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, property is updating!',
      type: LoaderAction.property.update,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: PropertyUpdateResponse | string | IError =
      yield PropertyApi.updateProperty(data, accessToken);
    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        updatePropertyAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          updatePropertyAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to update property!',
          type: LoaderAction.property.update,
        }),
      );
    } else {
      yield put(updatePropertyAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(true, res.id);
    }
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      updatePropertyAction.failure({
        key: String(payload.id),
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        key: String(payload.id),
        id: loadId,
        message: 'Failed to update property!',
        type: LoaderAction.property.update,
      }),
    );
  }
}

export function* deletePropertySaga({
  payload,
}: ReturnType<typeof deletePropertyAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, property is deleting!',
      type: LoaderAction.property.delete,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: PropertyDeleteResponse | string =
      yield PropertyApi.deleteProperty(data, accessToken);
    if (typeof res === "undefined" || typeof res === 'string') {
      deletePropertyAction.failure({
        code: res,
        message: res || 'Something was wrong',
      });
      yield put(
        addError({
          id: loadId,
          message: 'Failed to delete property!',
          type: LoaderAction.property.delete,
        }),
      );
    } else {
      yield put(
        deletePropertyAction.success({
          ...res,
          id: payload.id,
        }),
      );
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      deletePropertyAction.failure({
        key: String(payload.id),
        code: error.code || 403,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        key: String(payload.id),
        id: loadId,
        message: 'Failed to delete property!',
        type: LoaderAction.property.delete,
      }),
    );
  }
}

export function* getPropertyTreeSaga({
  payload,
}: ReturnType<typeof getPropertyTreeAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, property is getting!',
      type: LoaderAction.property.getItem,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    const res: EnterpriseFullWithTreeDTO | string | IError =
      yield PropertyApi.getPropertyTree(payload, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getPropertyTreeAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getPropertyTreeAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      // yield put(
      //   addError({
      //     id: loadId,
      //     message: 'Failed to get property tree!',
      //     type: LoaderAction.property.getItem,
      //   }),
      // );
    } else {
      yield put(
        getPropertyTreeAction.success({ id: payload.id, parentsTree: [res] }),
      );
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getPropertyTreeAction.failure({
        key: String(payload.id),
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    // yield put(
    //   addError({
    //     key: String(payload.id),
    //     id: loadId,
    //     message: 'Failed to get property tree!',
    //     type: LoaderAction.property.getItem,
    //   }),
    // );
  }
}

export function* propertyActionSaga() {
  yield all([
    takeEvery(getPropertiesAction.request, getPropertiesSaga),
    takeEvery(getMapItemsAction.request, getPropertiesMapSaga),
    takeEvery(getPropertyAction.request, getPropertySaga),
    takeEvery(getPropertyTreeAction.request, getPropertyTreeSaga),
    takeEvery(createPropertyAction.request, createPropertySaga),
    takeEvery(updatePropertyAction.request, updatePropertySaga),
    takeEvery(deletePropertyAction.request, deletePropertySaga),
  ]);
}
