import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const FileIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.6008 0H5.60078C5.15922 0 4.80078 0.358437 4.80078 0.8V39.2C4.80078 39.6416 5.15922 40 5.60078 40H34.4008C34.8423 40 35.2008 39.6416 35.2008 39.2V13.6C35.2008 9.91359 25.2872 0 21.6008 0ZM33.6008 38.4H6.40078V1.6H21.6008C24.404 1.6 33.6008 10.7968 33.6008 13.6V38.4Z"
      fill={color}
    />
    <path
      d="M33.9204 11.2005H24.0004V1.28047C24.0004 0.837266 23.642 0.480469 23.2004 0.480469C22.7587 0.480469 22.4004 0.838906 22.4004 1.28047V12.0005C22.4004 12.4421 22.7588 12.8005 23.2004 12.8005H33.9204C34.3636 12.8005 34.7204 12.4421 34.7204 12.0005C34.7204 11.5589 34.362 11.2005 33.9204 11.2005Z"
      fill={color}
    />
  </svg>
);

FileIcon.defaultProps = {
  color: '#1F2531',
};
