import React, { FC } from 'react';

interface Props {
}

export const GoogleMapStreetView: FC<Props> = (props) => (
    <svg 
        width={40} 
        height={40} 
        {...props} 
        viewBox="0 0 40 40" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g 
            opacity="0.08" 
            clipPath="url(#clip0_12718:4525)"
        >
            <path d="M19.7637 9.375C22.3485 9.375 24.4512 7.27234 24.4512 4.6875C24.4512 2.10266 22.3485 0 19.7637 0C17.1791 0 15.0762 2.10266 15.0762 4.6875C15.0762 7.27234 17.1791 9.375 19.7637 9.375Z" fill="#1F2531"/>
            <path d="M31.3513 31.6856C30.4721 31.4887 29.5114 31.3175 28.4875 31.1732C27.854 31.0841 27.2635 31.5437 27.1799 32.1738C27.0963 32.8025 27.5259 33.4055 28.1619 33.4949C28.8693 33.5944 29.5435 33.7073 30.1761 33.8331C32.9059 34.3751 34.0704 34.997 34.437 35.3132C34.0704 35.6293 32.9059 36.2513 30.1761 36.7936C27.3718 37.3502 23.7582 37.6569 20.0003 37.6569C16.2424 37.6569 12.6285 37.3502 9.82422 36.7933C7.09442 36.2513 5.93018 35.6293 5.56366 35.3132C5.93018 34.997 7.09442 34.3751 9.82422 33.8331C10.3198 33.7345 10.8408 33.6442 11.3831 33.5615C12.009 33.4666 12.435 32.8742 12.356 32.2526C12.2748 31.6138 11.6812 31.1451 11.0382 31.2428C10.191 31.3715 9.39117 31.5195 8.64929 31.6856C4.93195 32.5178 3.125 33.7043 3.125 35.3132C3.125 36.9221 4.93195 38.1086 8.64929 38.9408C11.7023 39.6244 15.7336 40.0007 20.0003 40.0007C24.267 40.0007 28.298 39.6244 31.3513 38.9408C35.0687 38.1086 36.8756 36.9221 36.8756 35.3132C36.8756 33.7043 35.0687 32.5178 31.3513 31.6856Z" fill="#1F2531"/>
            <path d="M14.6492 23.8525V32.0312C14.6492 33.9697 16.2263 35.5469 18.1648 35.5469H21.3697C23.3082 35.5469 24.8854 33.9697 24.8854 32.0312V23.8525C26.0566 23.2724 26.8348 22.0706 26.8348 20.7031V15.2344C26.8348 13.2959 25.2577 11.7188 23.3192 11.7188H16.2153C14.2769 11.7188 12.6997 13.2959 12.6997 15.2344V20.7031C12.6997 22.0706 13.4782 23.2724 14.6492 23.8525Z" fill="#1F2531"/>
        </g>
        <defs>
            <clipPath id="clip0_12718:4525">
            <rect width="40" height="40" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default GoogleMapStreetView;
