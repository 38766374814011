import { Dispatch } from "react";
import { addShowedElement } from "../controllers/showElement/actions";
import uuid from "./uuid";
import { ShowElementEnum } from "../controllers/showElement/models.d";
import { notificationKey } from "../components/elements/Notification";
import { NotificationTypeEnum } from "../controllers/modals/models.d";

export enum callbackTypeEnum {
  create = "created",
  update = "updated",
  delete = "deleted",
}

export const requestCallback = (dispatch: Dispatch<any>, status: boolean, type?: callbackTypeEnum, error?: string) => {
  if (status) {
    // if update was success - the additional popup appear
    dispatch(
      addShowedElement({
        id: uuid(),
        type: ShowElementEnum.notification,
        key: notificationKey,
        timeout: 5000,
        props: {
          text: 'Successfully ' + type,
          type: NotificationTypeEnum.success,
          title: 'Successfully ' + type,
        }
      })
    );
  } else {
    // if update was wrong - the additional popup appear
    dispatch(
      addShowedElement({
        id: uuid(),
        type: ShowElementEnum.notification,
        key: notificationKey,
        timeout: 5000,
        props: {
          text: 'Something goes wrong',
          type: NotificationTypeEnum.error,
          title: error ? error : 'Something goes wrong',
        }
      })
    );
  }
}