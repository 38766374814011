import {all, put, takeEvery, call} from 'redux-saga/effects';

// APIs
import {FavoriteApi} from '../transport/favorite.api';
import {getAccessToken} from '../../auth';

// Actions
import {changeStatusAction} from '../actions';
import {addError, addLoader, removeLoader} from '../index';

// Utils
import uuid from 'utils/uuid';

// Constants
import {LoaderAction} from 'config/constants';
import {FavoriteCreateResponse} from "@ternala/voltore-types";
import { getAccessTokenSaga } from "../../auth/sagas/auth";

export function* changeStatusSaga({
                                      payload,
                                  }: ReturnType<typeof changeStatusAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, favorite status is changing!',
            type: LoaderAction.favorite.change,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: FavoriteCreateResponse | string = yield FavoriteApi.changeStatus(
            data,
            accessToken,
        );
        if (typeof res === "undefined" || typeof res === "string") {
                changeStatusAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to change favorite status!',
                    type: LoaderAction.favorite.change,
                }),
            );
        } else {
            yield put(
                changeStatusAction.success({
                    ...res,
                    id: payload.itemId,
                    entityId: payload.entityId,
                    status: payload.isFavorite,
                }),
            );
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        console.error('error: ', error);
        yield put(
            changeStatusAction.failure({
                code: error.code || 403,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                id: loadId,
                message: 'Failed to change favorite status!',
                type: LoaderAction.favorite.change,
            }),
        );
    }
}

export function* favoriteActionSaga() {
    yield all([takeEvery(changeStatusAction.request, changeStatusSaga)]);
}
