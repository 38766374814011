import React, { FC, ReactNode } from 'react';
import { useSelector } from "react-redux";

/* components */
import PropertyInfo from './PropertyInfo';
import PropertiesInTheSameGroup from './PropertiesInTheSameGroup';

/* types */
import { IStore } from 'controllers/store';
import { PropertyFullExpandDTO } from "controllers/property/models";
import CustomScrollbars from 'components/CustomScrollbars';
import { ILoader, IError } from "../../../../models";

interface Props {
  propertyId: number;
  tabName: string;
  children?: ReactNode;
}

const PropertyItemInfoTab: FC<Props> = (props) => {
  const {
    propertyId,
    tabName,
  } = props;

  const loader = useSelector<IStore, ILoader | undefined>((store) => store.property.state.loaders.find((loader) => loader.purposeId === propertyId))
  const error = useSelector<IStore, IError | undefined>((store) => store.property.state.errors.find((error) => error.key === String(propertyId)));
  const currentProperty = useSelector<IStore, PropertyFullExpandDTO | undefined>((store) => store.property.propertyData[propertyId]);

  return (
    <CustomScrollbars id="property-info-container">
      <div className="item-info__tab properties-page__property-tab">
        <PropertyInfo
          propertyId={propertyId}
          tabName={tabName}
          loading={!!loader}
          error={error}
        />
        {currentProperty?.sameGroupProperties &&
          currentProperty.propertyGroup &&
          currentProperty.sameGroupProperties.length > 0 && (
            <div className="property-additional-info">
              <PropertiesInTheSameGroup
                properties={currentProperty.sameGroupProperties}
                title={currentProperty.propertyGroup?.title}
              />
            </div>
          )}
      </div>
    </CustomScrollbars>
  );
};

export default PropertyItemInfoTab;
