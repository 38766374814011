import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";

// @ternala/voltore-types
import { CardEntityEnum, NoteEntityRelation, NoteEntityRequestEnum } from "@ternala/voltore-types/lib/constants";
import {
  CardCreateRequest,
  CardDTO,
  CardEntityConnectUpdateRequest,
  CardUpdateRequest,
  NoteCreateRequest,
  ReminderCreateRequest
} from "@ternala/voltore-types";
import { actionToMention, TagEntityEnum } from "@ternala/voltore-types/lib/card";

/* draft modules */
import {
  CompositeDecorator,
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  getDefaultKeyBinding
} from "draft-js";
import { Editor, RawDraftContentState, SyntheticKeyboardEvent } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Draft-js utils
import { noteDecorators } from "../Draft/Decorators";

/* types */
import { IStore } from "controllers/store";
import { IPropertyState } from "controllers/property/models";
import { NotificationTypeEnum } from "controllers/modals/models.d";
import { CardFilterEnum } from "controllers/card/models";
import {
  IActionElement,
  IControlEditor,
  IReminderEditor,
  ITagEditor,
  ReminderEditorStepEnum
} from "../model.d";

//Context
import { FocusToMainProvider } from "../Contexts";

//Actions
import { createCardAction, updateCardAction } from "../../../controllers/card/actions";
import { addNotification } from "controllers/modals/actions";

//Utils
import {
  generateDefaultContent,
  getSearchValue,
  insertMention,
  openMenu,
  removeAction
} from "./utils";
import uuid from "../../../utils/uuid";

/* components */
import SuggestionControl from "./Controls/SuggestionControl";
import ControlButton from "./Controls/ControlButton";
import ButtonAddNote from "./Controls/ButtonAddNote";
import { ActionBlock } from "./Actions/ActionBlock";
import Loader from "../../Loader";
import ButtonCancelEditNote from "./Controls/ButtonCancelEditNote";

//Config
import { styleMap } from "./config";
import CustomScrollbars from "components/CustomScrollbars";
import { IPersonState } from "controllers/person/models";
import { IEnterpriseState } from "controllers/enterprise/models";
import { EditorType, useEditors } from "./hooks/useTags";
import { generateControls, generateTags, generateReminders } from "./Editors";
import { ErrorBoundary } from "../../ErrorBoundary";

export interface IDefaultStateData {
  enterprise?: number[];
  property?: number[];
  person?: number[];

  propertyData: IPropertyState["propertyData"];
  enterpriseData: IEnterpriseState["enterpriseData"];
  personData: IPersonState["personData"];
}

export interface IPropsNoteCreatorProps extends IDefaultStateData {
  editedCard: CardDTO | null;
  clearEditedCard: () => void;

  modal: CardFilterEnum;
}

let hasFocusOutsideObj: { [key in CardFilterEnum]?: boolean } = {};


const logError = (error: Error, info: { componentStack: string }) => {
  console.log('error: ', error);
};

export const NoteCreator: FC<IPropsNoteCreatorProps> = (props) => {
  const defaultContent = generateDefaultContent(props);
  const prevDefaultContent = useRef<ContentState>();
  const { editedCard, enterprise, person, property } = props;

  const dispatch = useDispatch();
  const initialLoading = useRef(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const actionBlockRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef() as RefObject<any>;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hidePlaceholder, setHidePlaceholder] = useState<boolean>(false);

  const [errors, setErrors] = useState<string[]>([]);
  const [hasFocus, setHasFocus] = useState<boolean>(true);

  const [editorState, setEditorState] = useState<EditorState>(defaultContent);

  const { isMenuOpen, searchValue, isControlMenuOpen } =
    getSearchValue(editorState);

  useEffect(() => {
    if (prevDefaultContent.current === editorState.getCurrentContent() && initialLoading.current && !editedCard) {
      setEditorState(defaultContent);
    }
    if (!enterprise && !person && !property) {
      setHidePlaceholder(false);
    }
    prevDefaultContent.current = defaultContent.getCurrentContent();
    initialLoading.current = true;
  }, [enterprise, person, property]);

  useEffect(() => {
    if (isControlMenuOpen) {
      controlManager.create("");
      setEditorState(removeAction(editorState, " /"));
    }
  }, [isControlMenuOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // If we try to edit card
  useEffect(() => {
    if (editedCard) {
      let noteText: string | undefined | JSX.Element = editedCard?.note?.text;
      try {
        if (noteText) {
          const res = JSON.parse(noteText);
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(res),
              new CompositeDecorator(noteDecorators)
            )
          );
        }
      } catch (e) {
        console.log("e: ", e);
      }
      reminderManager.set(
        editedCard.reminders?.map((reminder) => ({
          id: reminder.id,
          role: reminder.role,
          date: reminder.date,
          description: reminder.text,
          step: ReminderEditorStepEnum.description,
          error: []
        })) || []
      );
      const connect = editedCard.cardTagConnect;

      let type: TagEntityEnum = TagEntityEnum.Property;
      if (connect?.enterprise) type = TagEntityEnum.Enterprise;
      if (connect?.person) type = TagEntityEnum.Person;

      tagManager.set(
        connect
          ? [
            {
              id: connect.id,
              entity: {
                type: type,
                data: connect.property || connect.enterprise || connect.person
              },
              step: undefined,
              tag: connect.tag,
              error: []
            }
          ]
          : []
      );
    }
  }, [editedCard]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef?.current?.contains(event.target as Node) ||
      actionBlockRef?.current?.contains(event.target as Node)
    ) {
      if (!hasFocusOutsideObj[props.modal]) {
        setHasFocus(true);
        hasFocusOutsideObj[props.modal] = true;
      }
    } else {
      if (hasFocusOutsideObj[props.modal]) {
        setHasFocus(false);
        hasFocusOutsideObj[props.modal] = false;
      }
    }
  };

  const onEditorValueChange = (contentState: RawDraftContentState) => {
    const contentStateParsed = convertFromRaw(contentState);
    // Check is need placeholder
    setHidePlaceholder(
      contentStateParsed.hasText() ||
      contentStateParsed.getBlockMap().first().getType() !== "unstyled"
    );
  };

  const myKeyBindingFn = (e: SyntheticKeyboardEvent): string | null => {
    // Handle key press
    if (e.key === "Enter" && (isMenuOpen || isControlMenuOpen)) {
      // prevent row split when open menu and press enter button
      e.preventDefault();
      return null;
    }
    return getDefaultKeyBinding(e); // use default handler
  };

  const executeAction = (action: IActionElement) => {
    const mention = actionToMention(action);
    setEditorState(insertMention(mention, editorState));
    editorRef.current?.focusEditor();
  };

  // const reminderManager = useReminders(openControlEditor);
  const controlManager = useEditors<IControlEditor>(EditorType.control);
  const tagManager = useEditors<ITagEditor>(EditorType.tag, controlManager.create);
  const reminderManager = useEditors<IReminderEditor>(EditorType.reminder, controlManager.create);

  const validate = (note?: NoteCreateRequest) => {
    let hasError = false;

    if (note) {
      if (
        note[NoteEntityRequestEnum.Property]?.length ||
        note[NoteEntityRequestEnum.Enterprise]?.length ||
        // note[NoteEntityRequestEnum.Role]?.length ||
        note[NoteEntityRequestEnum.Person]?.length
      ) {
        setErrors([]);
      } else {
        hasError = true;
        setErrors(["The note should contain at least one mention."]);
      }
    }

    const newReminderEditors = reminderManager.items.map((editor) => {
      const error = [];
      if (!editor.role) {
        error.push("Role must be selected");
        hasError = true;
      }
      if (editor.date && editor.date < new Date()) {
        error.push("Date must be future");
        hasError = true;
      }
      if (!editor.description) {
        error.push("Description must be provided");
        hasError = true;
      }

      return {
        ...editor,
        error
      };
    });

    const newTagEditors = tagManager.items.map((editor) => {
      const error = [];
      if (!editor.entity) {
        error.push(`Entity must be selected`);
        hasError = true;
      }
      if (!editor.tag) {
        error.push("Tag must be selected");
        hasError = true;
      }
      return {
        ...editor,
        error
      };
    });

    if (hasError) {
      reminderManager.set(newReminderEditors);
      tagManager.set(newTagEditors);
    }

    if (!note && !newTagEditors.length && !newReminderEditors.length) {
      setErrors(["Nothing to create, please add note, reminder or tag"]);
      return true;
    }

    return hasError;
  };

  const onSubmit = () => {
    setIsLoading(true);
    const content = editorState.getCurrentContent();
    let note: NoteCreateRequest | undefined;
    if (content.hasText()) {
      const rawContent = convertToRaw(content);
      let blocks = rawContent.blocks;
      if (blocks.length) {
        for (let i = 0; i < blocks.length; i++) {
          if (blocks[i].text.trim().length === 0) {
            delete blocks[i];
          } else {
            break;
          }
        }

        for (let i = blocks.length - 1; i >= 0; i--) {
          if (!blocks[i]?.text?.trim().length) {
            delete blocks[i];
          } else {
            break;
          }
        }
      }

      rawContent.blocks = blocks.filter(Boolean);
      note = {
        text: JSON.stringify(rawContent),
        [NoteEntityRequestEnum.Property]: [],
        [NoteEntityRequestEnum.Enterprise]: [],
        [NoteEntityRequestEnum.Person]: []
        // [NoteEntityRequestEnum.Role]: [],
      };
      Object.values(rawContent.entityMap).forEach((entity) => {
        if (entity.type === "MENTION" && note) {
          const entityType = entity.data.entityType as CardEntityEnum;
          if (entityType === CardEntityEnum.Role) return;
          // @ts-ignore
          note[NoteEntityRelation[entityType]]?.push(entity.data.id);
        }
      });
    }
    const hasError = validate(note);
    if (hasError) {
      setIsLoading(false);
      return;
    }

    const reminders = reminderManager.items
      .map((editor) => ({
        role: editor.role?.id,
        date: editor.date,
        text: editor.description?.trim(),
        ...(typeof editor.id === "number" ? { id: editor.id } : {})
      }))
      .filter(
        (reminder) => reminder.role !== undefined
      ) as ReminderCreateRequest[];

    const connects = tagManager.items
      .map((editor) => ({
        tag: editor.tag?.id,
        ...(typeof editor.id === "number" ? { id: editor.id } : {}),
        ...(editor.entity && editor.entity.type && editor.entity.data
          ? { [editor.entity.type]: editor.entity.data.id }
          : {})
      }))
      .filter((connect) => connect.tag) as CardEntityConnectUpdateRequest[];
    if (editedCard) {
      const updateData: CardUpdateRequest = {
        id: editedCard.id,
        note: note
          ? {
            ...note,
            id: editedCard.note?.id
          }
          : undefined,
        reminders,
        connects
      };
      dispatch(
        updateCardAction.request({
          ...updateData,
          callback: (status: boolean) => {
            setIsLoading(false);
            if (status) {
              // if create was success - we need to clear note creator
              setEditorState(EditorState.createEmpty());
              reminderManager.set([]);
              tagManager.set([]);
              setHidePlaceholder(false);
              props.clearEditedCard();
              dispatch(
                addNotification({
                  id: uuid(),
                  text: "Successfully created",
                  type: NotificationTypeEnum.success,
                  title: "Successfully created"
                })
              );
            } else {
              // if create was wrong - we need to show notification(and add some message)
              dispatch(
                addNotification({
                  id: uuid(),
                  text: "Something goes wrong",
                  type: NotificationTypeEnum.error,
                  title: "Something goes wrong"
                })
              );
            }
          }
        })
      );
    } else {
      const createData: CardCreateRequest = {
        note,
        reminders,
        connects
      };
      dispatch(
        createCardAction.request({
          ...createData,
          callback: (status: boolean) => {
            setIsLoading(false);
            if (status) {
              // if create was success - we need to clear note creator
              setEditorState(EditorState.createEmpty());
              reminderManager.set([]);
              tagManager.set([]);
              setHidePlaceholder(false);
              dispatch(
                addNotification({
                  id: uuid(),
                  text: "Successfully created",
                  type: NotificationTypeEnum.success,
                  title: "Successfully created"
                })
              );
            } else {
              // if create was wrong - we need to show notification(and add some message)
              dispatch(
                addNotification({
                  id: uuid(),
                  text: "Something goes wrong",
                  type: NotificationTypeEnum.error,
                  title: "Something goes wrong"
                })
              );
            }
          }
        })
      );
    }
  };

  const allErrors = [
    ...errors,
    ...reminderManager.items.map((editor) => editor.error).flat(1),
    ...tagManager.items.map((editor) => editor.error).flat(1)
  ];

  return (
    <ErrorBoundary>
      <div className="note-creator">
        <div className="note-creator_inside">
          {isLoading && <Loader />}
          <FocusToMainProvider
            value={{
              moveFocusToMain: () => {
                editorRef.current?.focusEditor();
              }
            }}>
            <div
              className="note-creator_top-part"
              id={"action-block-" + props.modal}>
              {hasFocusOutsideObj[props.modal] &&
                isMenuOpen && (
                  <ActionBlock
                    ref={actionBlockRef}
                    searchValue={searchValue}
                    onActionSelection={executeAction}
                    removeAction={() => {
                      setEditorState(removeAction(editorState, " @", false));
                    }}
                  />
                )}
            </div>
            {allErrors.length ? (
              <div className={"error-block"}>
                <div className="title">Please check:</div>
                <ul className="errors">
                  {allErrors.map((error) => (
                    <li className={"error"}>{error}</li>
                  ))}
                </ul>
              </div>
            ) : (
              ""
            )}
            <div className="note-creator_bottom-part">
              <CustomScrollbars
                autoHeight={true}
                className={"note-creator__scrollbar"}>
                <div className="top-editor" ref={wrapperRef}>
                  <Editor
                    placeholder={
                      "Leave a note, press @ to mention entity or / to trigger an action"
                    }
                    customDecorators={noteDecorators}
                    editorState={editorState}
                    wrapperClassName={
                      "note-creator__wrapper" +
                      (hidePlaceholder ? " hide-placeholder" : "")
                    }
                    toolbarClassName="note-creator__toolbar"
                    editorClassName={
                      "note-creator__input-field" +
                      (errors.length ? " error" : "")
                    }
                    onEditorStateChange={(editorState) => {
                      setEditorState(editorState);
                    }}
                    spellCheck={true}
                    onChange={onEditorValueChange}
                    customStyleMap={styleMap}
                    // @ts-ignore
                    keyBindingFn={myKeyBindingFn}
                    ref={editorRef}
                    toolbar={{
                      options: ["inline", "list", "link"],
                      inline: {
                        options: ["bold", "italic", "underline", "strikethrough"]
                      },
                      list: {
                        options: ["unordered", "ordered"]
                      },
                      link: {
                        options: ["link"]
                      }
                    }}
                    toolbarCustomButtons={[
                      <SuggestionControl
                        isToggled={isMenuOpen}
                        onClick={() => {
                          setEditorState(openMenu(editorState, "@"));
                        }}
                      />,
                      <ControlButton
                        isToggled={isControlMenuOpen}
                        onClick={() => {
                          setEditorState(openMenu(editorState, "/"));
                        }}
                      />
                    ]}
                    onFocus={() => {
                      setHasFocus(true);
                      hasFocusOutsideObj[props.modal] = true;
                    }}
                  />
                </div>
                <>
                  {generateControls({
                    createReminder: reminderManager.create,
                    createTag: tagManager.create,
                    manager: controlManager,
                    modal: props.modal
                  })}
                  {generateReminders(reminderManager, props.modal)}
                  {generateTags(tagManager, props.modal)}
                </>
              </CustomScrollbars>
              <div className="note-creator__bottom-panel">
                {props.editedCard ? (
                  <>
                    <ButtonAddNote
                      onClick={onSubmit}
                      text={"Save"}
                      isUpdate={true}
                    />
                    <ButtonCancelEditNote
                      onClick={() => {
                        props.clearEditedCard();
                        setEditorState(EditorState.createEmpty());
                        reminderManager.set([]);
                        tagManager.set([]);
                        setHidePlaceholder(false);
                      }}
                    />
                  </>
                ) : (
                  <ButtonAddNote onClick={onSubmit} />
                )}
              </div>
            </div>
          </FocusToMainProvider>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default connect((store: IStore) => ({
  propertyData: store.property.propertyData,
  enterpriseData: store.enterprise.enterpriseData,
  personData: store.person.personData
}))(NoteCreator);
