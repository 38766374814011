/* eslint-disable array-callback-return */

import React, { FC, useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

/* components */
import MapLegend from '../LeaseTransactionViewLegend'
import Map from 'pages/Properties/GoogleMaps/LeasesFloorsMap'
import SelectSort from 'components/UI/controls/SelectSort'

/* constants */
import { PageEnum } from 'config/constants'
import { propertiesTabs } from 'config/constants/tabs'

/* utils */
import { compareArrays } from 'utils/compareArrays'

/* types */
import { IStore } from 'controllers/store'
import { RouterState } from 'connected-react-router'
import { IPropertyState } from 'controllers/property/models'
import { ItemsViewProps } from '../LeaseTransactionItemsView'
import { LeaseTransactionFullExpandDTO, FloorDTO } from '@ternala/voltore-types'
import { OptionType } from 'models'
import { IStackItem } from '../index'

interface Props extends ItemsViewProps {
   routerState: RouterState
   propertyState: IPropertyState
}

const MapView: FC<Props> = props => {
   const {
      routerState: {
         location: {
            pathname
         }
      },
      propertyState,
      currentProperty,
      transactionsVisualizationFilter
   } = props

   const floorsFilterOptions: OptionType[] = [
      { label: 'All floors', value: 'all' },
      ...currentProperty?.floors?.map(floor => ({
         label: floor.title,
         value: floor.id
      }))
   ]   
   const dispatch = useDispatch()
   const [floorFilter, setFloorFilter] = useState<OptionType>(floorsFilterOptions[0])
   useEffect(() => {
      props.loadTransactions({ offset: 0, limit: 99 })
   }, [])

   const loadLeaseTransactionInfo = (transactionId: number) => {
      if (transactions && transactions?.length > 1) {
         props.setSelectedLeaseTransactions(transactions)
      }
      if (pathname.includes(propertiesTabs.leases.title)) {
         dispatch(push(`/${PageEnum.PROPERTIES}/${currentProperty.id}/${propertiesTabs.leases.path}/${transactionId}`))
      }
      if (pathname.includes(propertiesTabs.tenants.title)) {
         dispatch(push(`/${PageEnum.PROPERTIES}/${currentProperty.id}/${propertiesTabs.tenants.path}/${transactionId}`))
      }
   }

   let transactions: LeaseTransactionFullExpandDTO[] | undefined

   if (floorFilter.value === 'all') {
      let wholeList: IStackItem[] = props.leaseTransactionsNA.items;
      props.leaseTransactionsSplitted.forEach((mapItem) => {
         wholeList = [...wholeList, ...mapItem.items]
      })
      wholeList = wholeList.filter((item, index, array) => {
         const leasesIds = item.items.map(item => item.id);
         let foundId;
         array.some((stackItem, i) => {
            if(compareArrays<number>(stackItem.items.map(item => item.id), leasesIds)){
               foundId = i;
               return true;
            }
         });
         if (foundId === index) return true;
      });
      transactions = (wholeList && wholeList[0] && wholeList[0].items) || []
   } else {
      transactions = currentProperty.leaseTransactionState?.items?.map(id => propertyState.leaseTransactionData[id])
      .filter(transaction => {
         let filteredTransaction
         transaction.floors?.forEach((floor: FloorDTO) => {
            if (floor.title === floorFilter.label) {
               filteredTransaction = transaction
            }
         })
         return filteredTransaction
      })
   }

   /**
    * display markers at full opacity only if
    * select filter value matches active status of the transaction
    * otherwise field "opacity" is telling the map to display marker
    * at half opacity
    */
   transactions = transactions?.map(transaction => {
      if (transactionsVisualizationFilter.value !== transaction.activeStatus?.status) {
         return {
            ...transaction,
            inactive: true
         }
      } else {
         return {
            ...transaction,
            inactive: false
         }
      }
   })

   return (
      <div className="lease-transactions-map">
         <div className="lease-transactions-map__floors-filter">
            <SelectSort
               options={floorsFilterOptions}
               value={floorFilter}
               onChange={floor => setFloorFilter(floor)}
            />
            <hr />
         </div>

         <div className="lease-transactions-map__legend">
            <MapLegend mapView />
         </div>

         <Map
            propertyCoords={
               (typeof currentProperty?.address?.latitude == 'number' && typeof currentProperty?.address?.longitude === 'number') 
               ? { latitude: currentProperty?.address?.latitude, longitude: currentProperty?.address?.longitude }
               : undefined
            }
            transactions={transactions}
            onMarkerClick={loadLeaseTransactionInfo}
         />
      </div>
   )
}

export default connect((store: IStore) => ({
   routerState: store.router,
   propertyState: store.property
}))(MapView)