import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import moment from 'moment';

/* components */
import NotFoundPage from 'pages/Static/NotFound';
import LeaseTransactionModal from 'components/modals/properties/LeaseTransactionModal';
import PopupEditTransaction from 'components/modals/PopupEditTransaction';
import PopupDelete from 'components/modals/PopupDelete';

import InvolvedPerson from '../InvolvedPerson';
import OptionsMenu from 'components/UI/controls/OptionsMenu';
import QuickChangeListingStatus from 'components/UI/controls/SelectListingStatusQuickChange';
import Link from 'components/UI/controls/Link';
import { CloseSmallIcon } from 'components/icons/CloseSmallIcon';
import CustomScrollbars from 'components/CustomScrollbars';
import TransitionCard from 'components/UI/controls/TransitionCard';
import Loader from 'components/Loader';

/* controllers */
import {
  deleteLeaseTransactionAction,
  getLeaseTransactionAction,
  updateLeaseTransactionAction,
  updateLeaseTransactionActiveStatusAction,
} from 'controllers/property/actions/leaseTransationActions';
import { addNotification } from 'controllers/modals/actions';

/* constants & utils */
import { PageEnum, SQFT_IN_ACRE } from 'config/constants';
import { enterprisesTabs, propertiesTabs } from 'config/constants/tabs';
import { EntityEnum, LeaseTransactionStatusEnum } from "@ternala/voltore-types/lib/constants";
import {
  computeDaysOnMarket,
  withThousandsSeparators,
} from 'utils/helper-functions';
import uuid from 'utils/uuid';
import { NotificationTypeEnum } from 'controllers/modals/models.d';

/* types */
import { IStore } from 'controllers/store';
import {
  IPropertyState,
  LeaseTransactionUpdateRequestExpanded,
} from 'controllers/property/models';
import {
  callbackTypeEnum,
  requestCallback,
} from 'utils';
import {EnterpriseInfo} from "../../../Enterprise/Tabs/Info/EnterpriseInfo";
import { IMAGE_TYPES } from "../../../../config";
import { FileIcon } from "../../../../components/icons/FileIcon";

interface Props {
  params: { id?: string; tabName?: string; transactionId: string };
  propertyState: IPropertyState;
  onClose?: () => void;
  shadow?: boolean;
}

const LeaseTransactionInfoContent: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    propertyState: { propertyData, leaseTransactionData },
    params,
    onClose,
    shadow,
  } = props;

  // Tags (filters)
  const propertyId = Number(params?.id);
  const transactionId = Number(params?.transactionId);
  const property = propertyData[propertyId];
  const transaction = leaseTransactionData[transactionId];
  const daysOnMarket = computeDaysOnMarket(transaction);

  const [loading, setLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPopupDeleteOpen, setIsPopupDeleteOpen] = useState<boolean>(false);
  const [isPopupEditTransactionOpen, setIsPopupEditTransactionOpen] =
    useState<boolean>(false);
  const [listingStatusToUpdate, setListingStatusToUpdate] =
    useState<LeaseTransactionStatusEnum>();
  const [listingStatus, setListingStatus] = useState<
    LeaseTransactionStatusEnum | undefined
  >(transaction?.activeStatus?.status);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getLeaseTransactionAction.request({
        id: transactionId,
        property: propertyId,
        callback: () => setLoading(false),
      }),
    );
  }, []);

  useEffect(() => {
    if (transaction?.activeStatus) {
      setListingStatus(transaction.activeStatus.status);
    }
  }, [transaction?.activeStatus]);

  if (isNaN(transactionId)) {
    return <NotFoundPage />;
  }
  const enterprise = params.tabName === 'tenants' ? transaction.tenant : transaction.owner;

  const documents = transaction.supplements?.filter(
    (supplement) =>
      !IMAGE_TYPES.some((type) => supplement.title?.endsWith(type)),
  );
  const images = transaction.supplements?.filter((supplement) =>
    IMAGE_TYPES.some((type) => supplement.title?.endsWith(type)),
  );

  return transaction ? (
    <>
      <div className={`transaction-info-container ${shadow ? 'shadow' : ''}`}>
        <Helmet>
          <title>
            {transaction?.activeStatus?.status ===
              LeaseTransactionStatusEnum.Leased && transaction?.tenant
              ? 'Tenants'
              : 'Leases'}{' '}
            | {listingStatus ? `Listing Status - ${listingStatus} | ` : ''}
            Voltore{' '}
            {process.env.REACT_APP_COMPANY_NAME
              ? `| ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>

        <CustomScrollbars className="box-shadow-container">
          <>
            {enterprise && (
                <div
                  id="transaction-info"
                  className="transaction-info sale-transaction-info">
                  <header>
                    <h2>{params.tabName === 'tenants' ? "Tenant" : "Landlord"} info</h2>
                  </header>
                  <EnterpriseInfo enterprise={enterprise} className={"flex-column"}/>
                </div>
              )}
            <div
              id="transaction-info"
              className="transaction-info sale-transaction-info">
              <header>
                <h2>Transaction info</h2>

                <div className="options">
                  <OptionsMenu
                    on={{
                      edit: () => setIsModalOpen(true),
                      delete: () => setIsPopupDeleteOpen(true),
                    }}
                  />
                  {onClose ? (
                    <div className="cross-icon" onClick={onClose}>
                      <CloseSmallIcon />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </header>

              <div className="transaction-info__content">
                <div className="system-id">
                  <span className="system-id__key">System id:</span>
                  <span className="system-id__value">{transactionId}</span>
                </div>

                <div className="data-field">
                  <div className="data-field__key">Listing status</div>
                  <div
                    className="data-field__value"
                    style={{ lineHeight: '18px' }}>
                    <QuickChangeListingStatus
                      value={listingStatus}
                      onChange={(status) => {
                        setListingStatusToUpdate(
                          status as LeaseTransactionStatusEnum,
                        );
                        setIsPopupEditTransactionOpen(true);
                      }}
                      statusHistory={transaction?.historyStatuses}
                      statusType="lease"
                      statusDate={transaction.activeStatus?.startDate}
                    />
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.owner ? '' : 'no-information'
                    }`}>
                    Landlord
                  </div>

                  {transaction?.owner ? (
                    <Link
                      id={transaction.owner.id}
                      type={EntityEnum.enterprise}
                      fullPath={`/${PageEnum.ENTERPRISES}/${transaction.owner.id}/${enterprisesTabs.info.title}`}
                      newTabIcon>
                      {transaction.owner.title}
                    </Link>
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.tenant ? '' : 'no-information'
                    }`}>
                    Tenant
                  </div>
                  {transaction?.tenant ? (
                    <Link
                      id={transaction.tenant.id}
                      type={EntityEnum.enterprise}
                      fullPath={`/${PageEnum.ENTERPRISES}/${transaction.tenant.id}/${enterprisesTabs.info.title}`}
                      newTabIcon>
                      {transaction.tenant.title}
                    </Link>
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.leaseCommencement ? '' : 'no-information'
                    }`}>
                    Lease commencement
                  </div>
                  <div className="data-field__value">
                    {transaction?.leaseCommencement ? (
                      moment(transaction.leaseCommencement).format('MM/DD/YYYY')
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.leaseExpiration ? '' : 'no-information'
                    }`}>
                    Lease expiration
                  </div>
                  <div className="data-field__value">
                    {transaction?.leaseExpiration ? (
                      moment(transaction.leaseExpiration).format('MM/DD/YYYY')
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.leasableSQFT && transaction.leasableSQFT > 0
                        ? ''
                        : 'no-information'
                    }`}>
                    Leasable Property sqft
                  </div>
                  <div className="data-field__value">
                    {transaction?.leasableSQFT &&
                    transaction.leasableSQFT > 0 ? (
                      `${withThousandsSeparators(
                        transaction.leasableSQFT,
                      )} SQFT`
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.leasableLotSQFT &&
                      transaction.leasableLotSQFT > 0
                        ? ''
                        : 'no-information'
                    }`}>
                    Leasable lot sqft
                  </div>
                  <div className="data-field__value">
                    {transaction?.leasableLotSQFT &&
                    transaction.leasableLotSQFT > 0 ? (
                      `${withThousandsSeparators(transaction.leasableLotSQFT)}
                        (${(transaction.leasableLotSQFT / SQFT_IN_ACRE).toFixed(
                          2,
                        )} ACRE)`
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.offeredRate && transaction.offeredRate > 0
                        ? ''
                        : 'no-information'
                    }`}>
                    Offered monthly lease rate / sqft
                  </div>
                  <div className="data-field__value">
                    {transaction?.offeredRate && transaction.offeredRate > 0 ? (
                      `$${Math.round(transaction.offeredRate)} SQFT`
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.offeredRate && transaction.offeredRate > 0
                        ? ''
                        : 'no-information'
                    }`}>
                    Offered annual lease rate / sqft
                  </div>
                  <div className="data-field__value">
                    {transaction?.offeredRate && transaction.offeredRate > 0 ? (
                      `$${Math.round(
                        transaction.offeredRate * 12,
                      )} / SQFT / Year`
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.actualRate && transaction.actualRate > 0
                        ? ''
                        : 'no-information'
                    }`}>
                    Actual Monthly Lease Rate /SQFT
                  </div>
                  <div className="data-field__value">
                    {transaction?.actualRate && transaction.actualRate > 0 ? (
                      `$${Math.round(transaction.actualRate)} / SQFT`
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.actualRate && transaction.actualRate > 0
                        ? ''
                        : 'no-information'
                    }`}>
                    Actual annual lease rate / sqft
                  </div>
                  <div className="data-field__value">
                    {transaction?.actualRate && transaction.actualRate > 0 ? (
                      `$${Math.round(
                        transaction.actualRate * 12,
                      )} / SQFT / Year`
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.leaseType ? '' : 'no-information'
                    }`}>
                    Lease type
                  </div>
                  <div className="data-field__value">
                    {transaction?.leaseType ? (
                      transaction.leaseType.title
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.leaseExpense ? '' : 'no-information'
                    }`}>
                    Lease expense
                  </div>
                  <div className="data-field__value">
                    {transaction?.leaseExpense ? (
                      `$${withThousandsSeparators(transaction.leaseExpense)}`
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.floors && transaction.floors.length > 0
                        ? ''
                        : 'no-information'
                    }`}>
                    Floor
                  </div>
                  <div className="data-field__value">
                    {transaction?.floors && transaction.floors.length > 0 ? (
                      transaction.floors[0].title
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      transaction?.suites && transaction.suites.length > 0
                        ? ''
                        : 'no-information'
                    }`}>
                    Suite
                  </div>
                  <div className="data-field__value">
                    {transaction?.suites && transaction.suites.length > 0 ? (
                      transaction.suites[0].title
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                <div className="data-field">
                  <div
                    className={`data-field__key_lg ${
                      daysOnMarket > 0 ? '' : 'no-information'
                    }`}>
                    Days on market
                  </div>
                  <div className="data-field__value">
                    {daysOnMarket > 0 ? (
                      daysOnMarket
                    ) : (
                      <span className="no-information">-</span>
                    )}
                  </div>
                </div>

                {transaction?.supplements && transaction.supplements.length > 0 && (
                  <>
                    <h3>Supplements</h3>

                    {/*{transaction.supplements.map((item) => (*/}
                    {/*  <div className="supplement-item" key={item.id}>*/}
                    {/*    <Link url={item.url} newTabIcon>*/}
                    {/*      {item.title}*/}
                    {/*    </Link>*/}
                    {/*  </div>*/}
                    {/*))}*/}
                    {documents?.length ? (
                      <div className="supplements-tab__documents-list">
                        {documents?.map((document) => (
                          <Link
                            className="document-supplement"
                            url={document.url}
                            key={document.id}
                            isNewTab={true}
                          >
                            <div className="document-supplement__image">
                              {document.preview  ? <img src={document.preview } alt="" /> : <FileIcon />}
                            </div>
                            <div className="document-supplement__title">
                              {document.title}
                            </div>
                          </Link>
                        ))}
                      </div>
                    ) : ""}
                    {images?.length ? (
                      <div className="supplement-images">
                        {images?.map((image) => (
                          <div key={image.id}>
                            <Link url={image.url} isNewTab={true} className="supplement-image">
                              <img src={image.url} alt="" />
                            </Link>
                            <div className="supplement-title">
                              <span>{image.title}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : ""}
                  </>
                )}

                {transaction?.details && (
                  <>
                    <h3>Details</h3>
                    <div className="content-block__notes description">
                      {transaction.details}
                    </div>
                  </>
                )}
              </div>
            </div>

            {/*<Tags*/}
            {/*  cards={property.cardTagConnects || []}*/}
            {/*  tabName={'leases'}*/}
            {/*  entity={{*/}
            {/*    id: transactionId,*/}
            {/*    type: TagEntityEnum.Lease_Transaciton,*/}
            {/*  }}*/}
            {/*  preview={!!onClose}*/}
            {/*  loading={loading}*/}
            {/*/>*/}

            <TransitionCard
              condition={
                !!(
                  transaction?.tenantInvolved &&
                  transaction?.tenantInvolved?.length > 0
                )
              }>
              <>
                <h3>Involved | Sell Side</h3>
                {transaction?.tenantInvolved?.map((item) => (
                  <InvolvedPerson
                    key={item.id}
                    data={{
                      person: item.person,
                      enterprise: item.enterprise,
                      positionOrRole: item.involvementType?.title,
                    }}
                  />
                ))}
              </>
            </TransitionCard>

            <TransitionCard
              condition={
                !!(
                  transaction?.ownerInvolved &&
                  transaction.ownerInvolved.length > 0
                )
              }>
              <>
                <h3>Involved | Sell Side</h3>
                {transaction?.ownerInvolved?.map((item) => (
                  <InvolvedPerson
                    key={item.id}
                    data={{
                      person: item.person,
                      enterprise: item.enterprise,
                      positionOrRole: item.involvementType?.title,
                    }}
                  />
                ))}
              </>
            </TransitionCard>
          </>
        </CustomScrollbars>
      </div>

      {isPopupEditTransactionOpen && (
        <PopupEditTransaction
          onClose={() => setIsPopupEditTransactionOpen(false)}
          onSubmitNo={(callback) => {
            if (listingStatusToUpdate) {
              dispatch(
                updateLeaseTransactionActiveStatusAction.request({
                  property: propertyId,
                  idTransaction: transactionId,
                  status: listingStatusToUpdate,
                  startDate: new Date(),
                  callback: (status: boolean) => {
                    if (status) {
                      // if update was success - the additional popup appear
                      dispatch(
                        addNotification({
                          id: uuid(),
                          text: `Successfully updated`,
                          type: NotificationTypeEnum.success,
                          title: `Successfully updated`,
                        }),
                      );
                      if (callback) callback();
                    } else {
                      // if update was wrong - the additional popup appear
                      dispatch(
                        addNotification({
                          id: uuid(),
                          text: 'Something goes wrong',
                          type: NotificationTypeEnum.error,
                          title: 'Something goes wrong',
                        }),
                      );
                    }
                  },
                }),
              );
            }
          }}
          onSubmitYes={(callback) => {
            if (listingStatusToUpdate) {
              dispatch(
                updateLeaseTransactionActiveStatusAction.request({
                  property: propertyId,
                  idTransaction: transactionId,
                  status: listingStatusToUpdate,
                  startDate: new Date(),
                  callback: (status: boolean) => {
                    if (status) {
                      // if update was success - the additional popup appear
                      dispatch(
                        addNotification({
                          id: uuid(),
                          text: `Successfully updated`,
                          type: NotificationTypeEnum.success,
                          title: `Successfully updated`,
                        }),
                      );
                      if (callback) callback();
                    } else {
                      // if update was wrong - the additional popup appear
                      dispatch(
                        addNotification({
                          id: uuid(),
                          text: 'Something goes wrong',
                          type: NotificationTypeEnum.error,
                          title: 'Something goes wrong',
                        }),
                      );
                    }
                  },
                }),
              );
            }
            setIsPopupEditTransactionOpen(false);
            setIsModalOpen(true);
          }}
        />
      )}

      {isModalOpen && (
        <LeaseTransactionModal
          property={{
            id: propertyId,
            transactionId,
            defaultOwner: property.owner,
          }}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(payload) => {
            dispatch(
              updateLeaseTransactionAction.request({
                ...(payload as LeaseTransactionUpdateRequestExpanded),
                callback: (status: boolean, err?: string) => {
                  requestCallback(dispatch, status, callbackTypeEnum.update, err)
                  if (payload.callback) payload.callback(status);
                },
              }),
            );
          }}
        />
      )}

      {isPopupDeleteOpen && (
        <PopupDelete
          onClose={() => setIsPopupDeleteOpen(false)}
          onSubmit={(callback) => {
            dispatch(
              deleteLeaseTransactionAction.request({
                property: propertyId,
                id: transactionId,
                callback: (status: boolean) => {
                  if (status) {
                    // if delete was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully deleted`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully deleted`,
                      }),
                    );
                    if (callback) callback();
                  } else {
                    // if delete was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                },
              }),
            );
            dispatch(
              push(
                `/${PageEnum.PROPERTIES}/${params.id}/${
                  transaction?.activeStatus?.status ===
                    LeaseTransactionStatusEnum.Leased && transaction?.tenant
                    ? propertiesTabs.tenants.path
                    : propertiesTabs.leases.path
                }`,
              ),
            );
          }}
        />
      )}
    </>
  ) : (
    <div className="preview-modal-loader">
      <Loader />
    </div>
  );
};

export default connect((store: IStore) => ({
  propertyState: store.property,
}))(LeaseTransactionInfoContent);
