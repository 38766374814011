import React, { FC } from 'react';
import Moment from 'react-moment';

/* constants & utils */
import { DATE_FORMAT } from 'config';
import {
  isAfterCurrentDate,
} from 'utils/helper-functions';

/* types */
import { InvolvementFullDTO } from '@ternala/voltore-types';
import { DotIcon } from 'components/icons/DotIcon';

interface Props {
  involvement: InvolvementFullDTO;
}

const InvolvementItem: FC<Props> = ({involvement}) => (
  <div
    className={
      'involvement-history__item' +
      (!involvement.endDate || isAfterCurrentDate(involvement.endDate)
        ? ' involvement-history__item_current'
        : '')
    }>
    <div className="item-summary">
      {involvement?.decisionMaker && !involvement?.owner && (
        <div className="item-summary__post">Decision Maker</div>
      )}

      {involvement?.owner && !involvement?.decisionMaker && (
        <div className="item-summary__post">Principal</div>
      )}

      {involvement?.owner && involvement?.decisionMaker && (
        <div className="item-summary__post">Principal | Decision Maker</div>
      )}
      <div className="item-summary__position">
        {involvement.involvementType?.title}
        <DotIcon />
        {involvement.enterprise && <span>{involvement.enterprise.title}</span>}
      </div>
      <div className="item-summary__date">
        {involvement.startDate ? (
          <>
            <Moment format={DATE_FORMAT}>{involvement.startDate}</Moment>
            &nbsp;-&nbsp;
          </>
        ) : (
          'N\\A - '
        )}

        {involvement.endDate ? <Moment format={DATE_FORMAT}>{involvement.endDate}</Moment> : 'Present'}
      </div>
    </div>
  </div>
);
export default InvolvementItem;
