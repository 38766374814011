import React, {
  FC,
  RefObject,
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';

/* components */
import Modal from 'components/Hocs/Portal';
import CustomScrollbars from 'components/CustomScrollbars';

import {
  removeShowedElement,
} from 'controllers/showElement/actions';

export interface IBaseModalProps {
  showElementId: string;
  id?: number;
  children: (closeModal: Function) => React.ReactNode;
}

export const BaseModal: FC<IBaseModalProps> = (props) => {
  const dispatch = useDispatch();

  const { showElementId, children } = props;

  const scrollAreaRef = useRef() as RefObject<HTMLDivElement>;
  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onCloseHandler();
    }
  };

  const onCloseHandler = () => {
    dispatch(removeShowedElement(showElementId));
  };

  return (
    <Modal>
      <div className="modal modal-property">
        <div className="scroll-area" ref={scrollAreaRef}>
          <CustomScrollbars
            style={{
              width: '700px',
              height: '100%',
            }}
            renderView={(props) => (
              <div
                {...props}
                className={'scroll-area'}
                style={{
                  position: 'absolute',
                  inset: '0px',
                  overflowY: 'scroll',
                  marginRight: `${
                    navigator.platform === 'MacIntel' ? '-17px' : '-35px'
                  }`,
                  marginBottom: '-17px',
                }}
              />
            )}>
            {children(onCloseHandler)}
          </CustomScrollbars>
        </div>
        <div className="modal-overlay" onClick={onCloseHandler} />
      </div>
    </Modal>
  );
};
