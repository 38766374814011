
import React, { FC } from 'react';

import CustomScrollbars from 'components/CustomScrollbars';
import SaleTransactionContacts from './SaleTransactionContacts';

interface Props {
  params: { id?: string; tabName?: string; transactionId: string };

  shadow?: boolean;
}

const SalesTransactionInfoContent: FC<Props> = (props) => {
  const { params, shadow } = props;

  return (
    <>
      <div className={`transaction-info-container ${shadow ? 'shadow' : ''}`}>
        <CustomScrollbars className="box-shadow-container">
          <SaleTransactionContacts params={params} />
        </CustomScrollbars>
      </div>
    </>
  );
};

export default SalesTransactionInfoContent;
