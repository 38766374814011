import React, {
  FC,
  useState,
  useEffect,
  useRef,
  RefObject,
  ChangeEvent,
} from 'react';

/* components */
import { SelectDropdownIcon } from 'components/icons/SelectDropdownIcon';
import { TimesIcon } from 'components/icons/TimesIcon';
import { Creation } from '../shared/components/Creation';

/* utils */
import { getUniqueId } from 'utils/helper-functions';

/* styles */
import { Color } from '../shared/styles';
import style from './SelectMultiSearch.module.scss';

/* types */
import { ICreation, ItemsLoadType, OptionType } from 'models';
import { AsyncOptions } from '../SelectSearchCustom';
import { LOADED_ITEMS_LIMIT } from 'config';
import Datalist from '../SelectSearchCustom/Optionslist';

interface Props {
  width?: number | string;
  label?: string;
  className?: string;
  containerStyle?: { [key: string]: number | string };
  name?: string;
  placeholder?: string;
  selectedOptions?: OptionType[];
  options?: OptionType[];
  asyncOptions?: (searchParams: AsyncOptions) => Promise<OptionType[]>;
  searchByFields?: string[];
  creation?: ICreation;
  onChange: (selections?: OptionType[]) => void;
  onOpen?: Function;
  optionHeight?: number;
  error?: Record<string, any> | null;
  maxOptions?: number;
  isDisabled?: boolean;
  required?: boolean;
  darkTheme?: boolean;
  children?: JSX.Element | JSX.Element[];
}

export const SelectMulti: FC<Props> = (props) => {
  const {
    width,
    error,
    selectedOptions,
    options,
    asyncOptions,
    searchByFields,
    optionHeight,
    maxOptions,
    creation,
    darkTheme,
    isDisabled,
  } = props;

  const timeout = useRef<number>();

  const menuHeight = optionHeight && maxOptions && optionHeight * maxOptions;
  const [menuHeightMin, setMenuHeightMin] = useState<number | undefined>(
    menuHeight,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [isAllItemsLoaded, setIsAllItemsLoaded] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const [fetchedOptions, setFetchedOptions] = useState<OptionType[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<
    OptionType[] | undefined
  >();
  const [selections, setSelections] = useState<OptionType[]>(
    props.selectedOptions || [],
  );

  const inputRef = useRef() as RefObject<HTMLInputElement>;
  const inputId = useRef(`input-${getUniqueId()}`);

  if (options && asyncOptions) {
    throw new Error(
      'only static options or async options function can be provided',
    );
  }

  /* effects */
  useEffect(() => {
    if (selectedOptions) {
      setSelections(selectedOptions);
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (isMenuOpen) {
      props.onOpen?.();
      if (asyncOptions) {
        asyncLoadOptions('start');
      } else {
        if (options && value === '') setStaticOptions(options);
      }
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (menuHeight && optionHeight && filteredOptions) {
      if (optionHeight * filteredOptions.length <= menuHeight) {
        setMenuHeightMin(filteredOptions.length * optionHeight);
      } else {
        setMenuHeightMin(menuHeight);
      }
    }
    if (!filteredOptions) {
      setIsMenuOpen(false);
      inputRef.current?.blur();
    }
  }, [filteredOptions]);

  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, []);

  /* h a n d l e r s */
  const onClickOutside = (event: MouseEvent) => {
    if (event.target instanceof HTMLElement) {
      if (
        event.target &&
        event.target.tagName === 'INPUT' &&
        inputId.current !== event.target.id
      ) {
        setIsMenuOpen(false);
      }
      if (
        !event.target.className.includes('SelectMultiSearch') &&
        !event.target.className.includes('creation')
      ) {
        setIsMenuOpen(false);
      }
    }
  };

  const asyncLoadOptions = async (loadType: ItemsLoadType, valueFromFunction?: string): Promise<void> => {
    if(!asyncOptions || loading) return;

    const localValue = valueFromFunction ?? value;

    setLoading(true);
    const options = await asyncOptions({
      limit: Number(LOADED_ITEMS_LIMIT),
      query: localValue,
      offset: loadType === 'more' ? fetchedOptions.length : 0,
    });
    const filteredAsyncOptions = options.filter((option) => {
      if (!selectedOptions) return option;
      return selectedOptions.every(
        (selected) => selected.value !== option.value,
      );
    });

    if (options.length < LOADED_ITEMS_LIMIT) setIsAllItemsLoaded(true);
    setFetchedOptions([
      ...(loadType === 'more' ? fetchedOptions || [] : []),
      ...filteredAsyncOptions,
    ]);
    setFilteredOptions([
      ...(loadType === 'more' ? filteredOptions || [] : []),
      ...filteredAsyncOptions,
    ]);
    setLoading(false);
  };

  const setStaticOptions = (array: OptionType[]) => {
    if (selectedOptions?.length) {
      const filteredOptions = array.filter((option) => {
        return selectedOptions.every(
          (selected) => selected.value !== option.value,
        );
      });
      setFilteredOptions(filteredOptions);
    } else {
      setFilteredOptions(array);
    }
  };

  const openMenu = () => {
    if (isDisabled) return;
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    resetState();
    setIsMenuOpen(false);
  };

  const onInputClick = () => {
    if (isDisabled) return;

    if (!isMenuOpen) {
      inputRef.current && inputRef.current.focus();
    }
    if (isMenuOpen) {
      inputRef.current && inputRef.current.blur();
      resetState();
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const filterOptionsByInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    if (asyncOptions) {
      clearTimeout(timeout.current);
      timeout.current = window.setTimeout((value: string) => {
        asyncLoadOptions('start', value);
      }, 200, value)
      return;
    }
    const filterAmongSpecificOptions = (array: OptionType[]) => {
      if (selectedOptions && selectedOptions.length > 0) {
        return array.filter((option) => {
          if (searchByFields && searchByFields.length > 0) {
            return (
              selectedOptions.every(
                (selected) => selected.value !== option.value,
              ) &&
              searchByFields
                .filter((field) => (option as any)[field])
                .some((field) => {
                  if (!Array.isArray((option as any)[field])) {
                    return (option as any)[field]
                      .trim()
                      .toLowerCase()
                      .includes(value.trim().toLowerCase());
                  } else {
                    return (option as any)[field].some((fieldValue: string) => {
                      return fieldValue
                        .trim()
                        .toLowerCase()
                        .includes(value.trim().toLowerCase());
                    });
                  }
                })
            );
          }
          return (
            selectedOptions.every(
              (selected) => selected.value !== option.value,
            ) &&
            option.label
              ?.trim()
              .toLowerCase()
              .includes(value.trim().toLowerCase())
          );
        });
      } else {
        return array.filter((option) => {
          return searchByFields && searchByFields.length > 0
            ? searchByFields
                .filter((field) => (option as any)[field])
                .some((field) => {
                  if (!Array.isArray((option as any)[field])) {
                    return (option as any)[field]
                      .trim()
                      .toLowerCase()
                      .includes(value.trim().toLowerCase());
                  } else {
                    return (option as any)[field].some((fieldValue: string) => {
                      return fieldValue
                        .trim()
                        .toLowerCase()
                        .includes(value.trim().toLowerCase());
                    });
                  }
                })
            : option.label
                ?.trim()
                .toLowerCase()
                .includes(value.trim().toLowerCase());
        });
      }
    };
    if (filterAmongSpecificOptions(options || [])?.length)
      setFilteredOptions(filterAmongSpecificOptions(options || []));
    else setFilteredOptions([]);
  };

  const selectOption = (option: OptionType) => {
    const updatedSelections = [...selections, option];
    setSelections(updatedSelections);
    let filteredOptions: OptionType[] | undefined = [];
    const filterAmongSpecificOptions = (array: OptionType[]) => {
      filteredOptions = array.filter((option) => {
        return updatedSelections.every(
          (selected) => selected.value !== option.value,
        );
      });
    };
    if (asyncOptions) filterAmongSpecificOptions(fetchedOptions);
    else options && filterAmongSpecificOptions(options);

    setFilteredOptions(filteredOptions);
    props.onChange(updatedSelections);
    setValue('');
    inputRef.current?.focus();
  };

  const deselectOption = (option: OptionType) => {
    const updatedSelections = selections.filter(
      (selection) => selection.value !== option.value,
    );
    const updatedOptions = filteredOptions?.length
      ? [...filteredOptions, option]
      : [option];
    setSelections(updatedSelections);
    setFilteredOptions(updatedOptions);
    props.onChange(updatedSelections);
    inputRef.current?.focus();
  };

  const resetState = () => {
    setValue('');
    props.options && setFilteredOptions(props.options);
  };

  return (
    <div
      className={style.container + (` ${props.className}` || '')}
      style={{ minWidth: width, maxWidth: width, ...props.containerStyle }}>
      <label
        htmlFor={inputId.current}
        className={darkTheme ? style.label_dark : style.label}
        style={{
          color: error?.parents
            ? Color.error
            : isDisabled
            ? Color.disabled
            : Color.label,
        }}>
        {props.label ? props.label : ''}
        {props.required && <span className={style.required}>*</span>}
      </label>

      {selections.length > 0 && (
        <div className={style.selections}>
          {selections.map((selection) => (
            <div
              className={
                `${(darkTheme ? style.selection_multi_dark : style.selection_multi)}`
              }
              key={Math.random()}>
              <span>{selection.label}</span>

              <span
                className={style.remove_selection_icon}
                onClick={() => deselectOption(selection)}
                title="Remove"
              />
            </div>
          ))}
        </div>
      )}

      <div
        className={darkTheme ? style.control_dark : style.control}
        onClick={onInputClick}>
        <input
          className={style.input + (!filteredOptions?.length && !loading ? ` ${style.input_havent_results}` : "")}
          id={inputId.current}
          ref={inputRef}
          type="text"
          style={{ borderColor: error?.parents ? Color.error : '' }}
          name={props.name}
          value={value}
          onChange={filterOptionsByInputValue}
          autoComplete={'select-multi'}
          placeholder={
            isMenuOpen
              ? ''
              : props.placeholder
              ? props.placeholder
              : creation
              ? 'Select one, multiple or create new'
              : 'Select one or multiple'
          }
          disabled={isDisabled}
        />

        {error?.parents && <div className={style.error}>{error?.parents}</div>}

        <div className={style.dropdown_indicator}>
          {isMenuOpen ? (
            <TimesIcon onClick={closeMenu} />
          ) : (
            <SelectDropdownIcon onClick={openMenu} />
          )}
        </div>
      </div>

      {isMenuOpen && (
        <div className={style.menu_wrapper}>
          <div className={style.menu}>
            {filteredOptions?.length ? (
              <Datalist
                onLoadMore={asyncLoadOptions}
                selectOption={selectOption}
                filteredOptions={filteredOptions}
                allItemsLoaded={isAllItemsLoaded}
                menuHeightMin={menuHeightMin}
                menuHeight={menuHeight}
                loading={loading}
              />
            ) : (
              <div className={style.no_options_message} key="empty">
                No results
              </div>
            )}

            {creation && (
              <Creation
                creation={creation}
                topIndent={
                  filteredOptions?.length ? menuHeightMin : optionHeight
                }
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

SelectMulti.defaultProps = {
  optionHeight: 26,
  maxOptions: 6,
};

export default SelectMulti;
