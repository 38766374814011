import React, { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

/* components */
import DataList from 'components/page/DataList';

/* controllers */

/* constants */
import { LOADED_ITEMS_LIMIT } from 'config';
import { Sorting, tagsSortOptions } from 'config/constants/sorting';
import { sortType, tagSortFields } from '@ternala/voltore-types/lib/constants';
import { PageEnum } from 'config/constants';

/* types */
import { IStore } from 'controllers/store';
import { ITagState } from 'controllers/tag/models';
import { ItemsLoadType, OptionType, sortingTypeKeys } from 'models';
import { RouterState } from 'connected-react-router';
import { TagCategoryGetListRequest } from '@ternala/voltore-types';
import {
  createTagCategoryAction,
  getTagCategoriesAction,
} from 'controllers/tag/actions';
import PopupTagGroup from 'components/modals/PopupTagGroup';
import { omit } from 'lodash';
import { Helmet } from 'react-helmet';
import { addNotification } from 'controllers/modals/actions';
import uuid from 'utils/uuid';
import { NotificationTypeEnum } from 'controllers/modals/models.d';

interface Props {
  store: IStore;
  tagsState: ITagState;
  routerState: RouterState;
}

const TagPage: FC<Props> = (props) => {
  const {
    routerState: {
      location: { pathname },
    },
    tagsState: { groups, isAll, newGroups, storedSearchParams },
    children,
  } = props;

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [sortValue, setSortValue] = useState<OptionType>(tagsSortOptions[0]);
  const [sortingType, setSortingType] = useState<sortType>(sortType.asc);
  const [sortField, setSortField] = useState<tagSortFields | undefined>(
    tagSortFields.title,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [isTagCategoryCreationOpen, setIsTagCategoryCreationOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    loadTagGroups('start', () => setLoading(false));
  }, [sortingType, sortField]);

  useEffect(() => {
    if (searchQuery === '') {
      setLoading(true);
      loadTagGroups('start', () => setLoading(false));
    }
  }, [searchQuery]);

  const loadTagGroups = (
    loadType: ItemsLoadType = 'start',
    callback?: Function,
  ) => {
    const searchParams: TagCategoryGetListRequest = {
      limit: LOADED_ITEMS_LIMIT,
      offset: loadType === 'more' ? groups?.length : 0,
      query: searchQuery,
      sortType: sortingType,
      sortField: sortField,
    };
    if (
      JSON.stringify(omit(storedSearchParams, ['limit', 'offset'])) !==
      JSON.stringify(omit(searchParams, ['limit', 'offset']))
    ) {
      searchParams.offset = 0;
    }
    dispatch(getTagCategoriesAction.request({ ...searchParams, callback }));
  };

  const onSort = (sortOption: OptionType) => {
    const sorting = Sorting[sortOption.value as sortingTypeKeys];
    setSortValue(sortOption);
    setSortField(sorting.sortField as tagSortFields);
    setSortingType(sorting.sortType);
  };

  const tagGroupsListItems = groups?.map((group) => ({
    id: group.id,
    description: group.title,
    image: '',
    tag: group.title,
  }));

  const newTagGroupsListItems = newGroups?.map((group) => ({
    id: group?.id,
    description: group?.title,
    image: '',
    tag: group?.title,
  }));

  return (
    <>
      <div className="tag-page">
        <Helmet>
          <title>
            Tags | Voltore
            {process.env.REACT_APP_COMPANY_NAME
              ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        <div className="page-layout__content">
          <DataList
            setIsLoading={setLoading}
            items={groups.length ? tagGroupsListItems : []}
            newItems={newTagGroupsListItems}
            itemsCount={groups.length}
            itemsLoading={loading}
            allItemsLoaded={isAll}
            activeTab="info"
            pageTitle={PageEnum.TAGS}
            singleItem="tag group"
            onLoadMore={loadTagGroups}
            onSort={onSort}
            sortValue={sortValue}
            sortOptions={tagsSortOptions}
            checkSuffixByActive={false}
            listControls={{
              searchPlaceholder: 'Search by Tag Title or Group',
              searchQuery,
              clearSearchQuery: () => setSearchQuery(''),
              onSearch: (query) => setSearchQuery(query),
              onAddNew: () => setIsTagCategoryCreationOpen(true),
            }}
          />
          <div className="tag-page__content">
            {pathname === `/tag-groups` && (
              <div className="empty-container-wrapper">
                <div className="empty-container" />
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
      {isTagCategoryCreationOpen ? (
        <PopupTagGroup
          onClose={() => {
            setIsTagCategoryCreationOpen(false);
          }}
          groups={groups}
          onCreate={(data, callback) =>
            dispatch(
              createTagCategoryAction.request({
                title: data.title,
                callback: (status: boolean) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Successfully created',
                        type: NotificationTypeEnum.success,
                        title: 'Successfully created',
                      }),
                    );
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (callback) callback(status);
                },
              }),
            )
          }
        />
      ) : (
        ''
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  tagsState: store.tag,
  routerState: store.router,
}))(TagPage);
