import { all } from 'redux-saga/effects';
import { createReducer, ActionType, getType } from 'typesafe-actions';
import { omit } from 'lodash';

// Actions
import * as actions from './actions';

// Interfaces
import { IStore, LoaderActionType } from 'controllers/store';
import { IUserState } from './models';

//Sagas
import { userActionSaga } from './sagas/user';
import { userRoleActionSaga } from './sagas/userRole';
import { concatWithUnique } from 'utils/concatWithUnique';
import { UserFullDTO, UserRoleDTO } from '@ternala/voltore-types';
import { widgetName } from './actions';
import { generateLoaderActions } from '../based';

export type UserActionType = ActionType<typeof actions>;

export const userSaga = function* () {
  yield all([userActionSaga(), userRoleActionSaga()]);
};

/* Reducer */
const initialState: IUserState = {
  state: {
    errors: [],
    loaders: [],
  },
  userData: {},
  userRoleData: {},
  storedSearchParams: null,
  storedUserRoleSearchParams: null,
};

type ActionTypes = UserActionType | LoaderActionType;

const loaderActions = generateLoaderActions<IUserState, ActionTypes>(
  widgetName,
);

export const loaderHandlers = loaderActions.handlers;

export const userReducer = createReducer<IUserState, ActionTypes>(
  initialState,
  {
    ...loaderHandlers,
    [getType(actions.deleteUserRoleAction.success)]: (
      state: IUserState,
      { payload }: any,
    ): IUserState => {
      return {
        ...state,
        userRoleData: payload.id
          ? omit(state.userRoleData, payload.id)
          : state.userRoleData,
        userRoles: state.userRoles?.filter(
          (userRole) => userRole !== payload.id,
        ),
        count: (state.count || 1) - 1,
      };
    },
  },
)
  .handleAction(
    [actions.getUsersAction.success],
    (state: IUserState, { payload }): IUserState => {
      const storedSearchParams = state.storedSearchParams;
      const { searchParams } = payload;
      let newUsersList;

      if (
        JSON.stringify(omit(storedSearchParams, ['limit', 'offset'])) ===
        JSON.stringify(omit(searchParams, ['limit', 'offset']))
      ) {
        newUsersList = concatWithUnique<UserFullDTO>(
          state.users || [],
          payload.response.items,
          'id',
          true,
        );
      } else {
        newUsersList = concatWithUnique<UserFullDTO>(
          [],
          payload.response.items,
          'id',
          true,
        );
      }

      return {
        ...state,
        storedSearchParams: searchParams,
        count: payload.response.counts,
        users: newUsersList,
        isAll: payload.isAll,
      };
    },
  )
  .handleAction(
    [actions.createUserAction.success],
    (state: IUserState, { payload }): IUserState => {
      return {
        ...state,
        userData: {
          ...state.userData,
          [payload.id]: payload,
        },
        users: concatWithUnique<UserFullDTO>(
          state.users || [],
          [payload],
          'id',
          true,
        ),
      };
    },
  )
  .handleAction(
    [actions.getUserAction.success],
    (state: IUserState, { payload }): IUserState => {
      return {
        ...state,
        userData: {
          ...state.userData,
          [payload.id]: payload,
        },
        users: concatWithUnique<UserFullDTO>(
          state.users?.slice() || [],
          [payload],
          'id',
          true,
        ),
      };
    },
  )
  .handleAction(
    [actions.updateUserAction.success],
    (state: IUserState, { payload }): IUserState => {
      return {
        ...state,
        userData: {
          ...state.userData,
          [payload.id]: payload,
        },
        users: concatWithUnique<UserFullDTO>(
          state.users || [],
          [payload],
          'id',
          true,
        ),
      };
    },
  )
  .handleAction(
    [actions.deleteUserAction.success],
    (state: IUserState, { payload }): IUserState => {
      return {
        ...state,
        userData: payload.id
          ? omit(state.userData, payload.id)
          : state.userData,
        users: state.users?.filter((user) => user.id !== payload.id),
        count: (state.count || 1) - 1,
      };
    },
  )
  .handleAction(
    [actions.getUserRolesAction.success],
    (state: IUserState, { payload }): IUserState => {
      const storedSearchParams = state.storedUserRoleSearchParams;
      const { searchParams, response } = payload;
      let newUsersList: number[];
      const userRoleData = {
        ...state.userRoleData,
      };

      response.items.forEach((role) => {
        userRoleData[role.id] = {
          ...userRoleData?.[role.id],
          ...role,
        };
      });

      if (
        JSON.stringify(omit(storedSearchParams, ['limit', 'offset'])) ===
        JSON.stringify(omit(searchParams, ['limit', 'offset']))
      ) {
        newUsersList = concatWithUnique<number>(
          state.userRoles || [],
          response.items.map(({ id }) => id)
        );
      } else {
        newUsersList = concatWithUnique<number>(
          [],
          response.items.map(({ id }) => id)
        );
      }

      return {
        ...state,
        storedUserRoleSearchParams: searchParams,
        userRoleData,
        count: payload.response.counts,
        userRoles: newUsersList,
        isAll: payload.isAll,
      };
    },
  )
  .handleAction(
    [actions.createUserRoleAction.success],
    (state: IUserState, { payload }): IUserState => {
      return {
        ...state,
        userRoleData: {
          ...state.userRoleData,
          [payload.id]: payload,
        },
        userRoles: concatWithUnique<number>(
          state.userRoles || [],
          [payload.id]
        ),
      };
    },
  )
  .handleAction(
    [actions.getUserRoleAction.success],
    (state: IUserState, { payload }): IUserState => {
      return {
        ...state,
        userRoleData: {
          ...state.userRoleData,
          [payload.id]: payload,
        },
        userRoles: concatWithUnique<number>(
          state.userRoles?.slice() || [],
          [payload.id]
        ),
      };
    },
  )
  .handleAction(
    [actions.updateUserRoleAction.success],
    (state: IUserState, { payload }): IUserState => {
      return {
        ...state,
        userRoleData: {
          ...state.userRoleData,
          [payload.id]: payload,
        },
        users: concatWithUnique<number>(
          state.userRoles || [],
          [payload.id]
        ),
      };
    },
  );

/* Loader actions */
export const addLoader = loaderActions.actions.addLoader;
export const addError = loaderActions.actions.addError;
export const removeError = loaderActions.actions.removeError;
export const removeLoader = loaderActions.actions.removeLoader;

/* Selectors */
export const getUsersList = (state: IStore) => state.user.users;
export const getUsersCount = (state: IStore) => state.user.count;
