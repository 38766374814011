import React from "react";
import { ActivityHistoryDTO } from "@ternala/voltore-types";
import { getInitials, getListImage } from "../../../../utils";
import { generatePersonName } from "../../../../pages/People/utils";
import moment from "moment";

const CardInfoBar:React.FC<ActivityHistoryDTO> = (card) => {
  return (
    <div className={`information`}>
      {card.user?.id === 1 ? (
        <div className="initials">{getInitials(`Super Admin`)}</div>
      ) : card.user?.person?.images?.length ? (
        <img
          src={getListImage(card.user?.person?.images)}
          alt=""
          className="person-image"
        />
      ) : (
        <div className="initials">
          {card.user?.person?.firstName && card.user?.person?.firstName
            ? getInitials(
              `${card.user?.person?.firstName} ${card.user?.person?.lastName}`,
            )
            : ''}
        </div>
      )}
      <div className="details">
          <span className="user">
            {card.user?.id === 1
              ? 'Super Admin'
              : card?.user?.person
                ? generatePersonName(card.user.person)
                : ''}
          </span>{' '}
        <span className="action">{card.type}</span>{' '}
        <span className="entity">{card.entityName}</span>
      </div>
      <div className={'date-added'}>
          <span className={'date'}>
            <span className={'today'}>
              {moment().isSame(card.createdAt, 'day')
                ? 'Today'
                : moment(card.createdAt).format('L')}{' '}
              , {moment(card.createdAt).format('LT')}
            </span>
          </span>
      </div>
    </div>
  );
};

export default CardInfoBar;
