import { all } from "redux-saga/effects";
import { createReducer, ActionType } from "typesafe-actions";

// Packages from @ternala
import {UserTypeEnum} from "@ternala/voltore-types/lib/constants";

// Actions
import * as actions from "./actions";
// Interfaces
import { IStore } from "controllers/store";
import { IAuthState } from "./models";

//Sagas
import {authActionSaga, checkAccessTokenExpired} from "./sagas/auth";
import {getCredentials} from "../../utils/deviceCredentials";

export type AuthActionType = ActionType<typeof actions>;

export const authSaga = function* () {
  yield all([
    authActionSaga()
  ]);
};

/* Reducer */
const initialState: IAuthState = {
  isAuthenticated: false,
  state: null
};

export const authReducer = createReducer<IAuthState, AuthActionType>(
  initialState
)
  .handleAction(actions.setAuthStateAction, (store, { payload }) => ({
    ...store,
    state: payload,
  }))
  .handleAction(
    actions.setAuthenticatedStatusAction,
    (state: IAuthState, { payload }): IAuthState => ({
      ...state,
      isAuthenticated: payload.status
    })
  )
  .handleAction(
    [
      actions.loginAction.success,
    ],
    (state: IAuthState, {payload}): IAuthState => ({
      ...state,
      ...payload,
      account: payload.account,
      authData:{
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken
      },
      isAuthenticated: true,
      error: undefined
    })
  )
  .handleAction(
    [actions.refreshTokenAction.success],
    (state: IAuthState, {payload}): IAuthState => ({ ...state, authData: payload, isAuthenticated: true, error: undefined })
  )
  .handleAction(
    [actions.loginAction.failure],
    (state: IAuthState, { payload }): IAuthState => ({
      ...state,
      error: payload,
      isAuthenticated: false
    })
  )
  .handleAction(
    [actions.logoutAction.success],
    (state: IAuthState): IAuthState => ({...state, isAuthenticated: false, error: undefined })
  );


/* Selectors */

export const getAuthState = (state: IStore) => state.auth.state;

export const getAuthStatus = (state: IStore): boolean | undefined =>
  state.auth.isAuthenticated;

export const getUserType = (state: IStore): UserTypeEnum | undefined =>
  state.auth.account?.type.slug;

export const getAuthData = (store: IStore) => store.auth.authData

export const getAccessToken = async (store: IStore): Promise<string | false> => {
  if(store.auth.authData){
    const {accessToken, refreshToken} = store.auth.authData;
    const deviceCredentials = await getCredentials();
    const res = await checkAccessTokenExpired({accessToken, refreshToken, deviceCredentials}, store);
    if (res) {
      return res.accessToken;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
export const getRefreshToken = (state: IStore) => state.auth.authData?.refreshToken;

export const getDeviceCreds = (state: IStore) => (state.auth.deviceCredentials);
