import { appName } from 'config';
import { createAsyncAction } from 'typesafe-actions';
import {
  PersonCreateRequestExpanded,
  PersonUpdateRequestExpanded,
} from './models';
import { IException } from '../store';
import {
  PersonCreateResponse,
  PersonDeleteRequest,
  PersonDeleteResponse,
  PersonGetListRequest,
  PersonGetListResponse,
  PersonGetRequest,
  PersonGetResponse,
  PersonUpdateResponse,
  PropertyOwnedDTO,
} from '@ternala/voltore-types';

export const widgetName = 'person';

// ** Action
export const getPersonsAction = createAsyncAction(
  `${appName}/${widgetName}/GET_PROFESSIONALS_REQUEST`,
  `${appName}/${widgetName}/GET_PROFESSIONALS_SUCCESS`,
  `${appName}/${widgetName}/GET_PROFESSIONALS_FILED`,
)<
  PersonGetListRequest & { callback?: Function },
  {
    response: PersonGetListResponse;
    searchParams: PersonGetListRequest;
    isAll: boolean;
  },
  IException
>();

export const createPersonAction = createAsyncAction(
  `${appName}/${widgetName}/CREATE_PROFESSIONAL_REQUEST`,
  `${appName}/${widgetName}/CREATE_PROFESSIONAL_SUCCESS`,
  `${appName}/${widgetName}/CREATE_PROFESSIONAL_FILED`,
)<
  PersonCreateRequestExpanded & {
    callback?: (res: false | PersonCreateResponse) => void;
    id?: number;
    loadCallback?: Function;
  },
  PersonCreateResponse,
  IException
>();

export const getPersonAction = createAsyncAction(
  `${appName}/${widgetName}/GET_PROFESSIONAL_REQUEST`,
  `${appName}/${widgetName}/GET_PROFESSIONAL_SUCCESS`,
  `${appName}/${widgetName}/GET_PROFESSIONAL_FILED`,
)<
  PersonGetRequest & { callback?: (res: false | PersonCreateResponse) => void },
  PersonGetResponse,
  IException
>();

export const updatePersonAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_PROFESSIONAL_REQUEST`,
  `${appName}/${widgetName}/UPDATE_PROFESSIONAL_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_PROFESSIONAL_FILED`,
)<
  PersonUpdateRequestExpanded & { callback?: (res: false | PersonCreateResponse) => void },
  PersonUpdateResponse,
  IException
>();

export const deletePersonAction = createAsyncAction(
  `${appName}/${widgetName}/DELETE_PROFESSIONAL_REQUEST`,
  `${appName}/${widgetName}/DELETE_PROFESSIONAL_SUCCESS`,
  `${appName}/${widgetName}/DELETE_PROFESSIONAL_FILED`,
)<
  PersonDeleteRequest & { callback?: Function },
  PersonDeleteResponse & { id?: number },
  IException
>();

export const getPersonOwnedPropertiesAction = createAsyncAction(
  `${appName}/${widgetName}/GET_PERSON_OWNED_PROPERTIES_REQUEST`,
  `${appName}/${widgetName}/GET_PERSON_OWNED_PROPERTIES_SUCCESS`,
  `${appName}/${widgetName}/GET_PERSON_OWNED_PROPERTIES_FILED`,
)<
  { id: number } & { callback?: Function },
  { response: PropertyOwnedDTO[]; id: number },
  IException
>();

export const downloadPersonExportAction = createAsyncAction(
  `${appName}/${widgetName}/DOWNLOAD_PERSON_EXPORT_REQUEST`,
  `${appName}/${widgetName}/DOWNLOAD_PERSON_EXPORT_SUCCESS`,
  `${appName}/${widgetName}/DOWNLOAD_PERSON_EXPORT_FILED`,
)<
  {},
  {},
  IException
>();
