import { CardDTO, CardFullDTO } from '@ternala/voltore-types';
import { cardSortFields, sortType } from '@ternala/voltore-types/lib/constants';
import { sortFieldType } from "../config/constants/sorting";

export const sortCard = ({
  cards,
  field,
  type,
}: {
  cards?: (CardDTO | CardFullDTO)[];
  field: sortFieldType;
  type: sortType;
}): (CardDTO | CardFullDTO)[] | undefined => {
  return (
    cards &&
    cards.sort((a, b) => {
      if (field === cardSortFields.createdAt) {
        return (
          (new Date(b[field]).getTime() - new Date(a[field]).getTime()) *
          (type === sortType.asc ? -1 : 1)
        );
      } else {
        return 0;
      }
    })
  );
};
