import {Config} from "../../../config/api";
import {authHeader, handleErrors} from "../../../utils/API";

import {
    UserCreateRequest, UserCreateResponse,
    UserDeleteRequest, UserDeleteResponse,
    UserGetListRequest,
    UserGetListResponse,
    UserGetRequest,
    UserGetResponse, UserUpdateRequest, UserUpdateResponse
} from "@ternala/voltore-types";
import {appendSearchParams} from "../../../utils/appendSearchParams";
import {IError} from "../../model";

class API {
  public async getUsers(getUserData: UserGetListRequest, token: string): Promise<UserGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "user/list")

    url = appendSearchParams(url, getUserData);

    return handleErrors(
      fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        }
      })
    );
  }

  public async createUser(createUserData: UserCreateRequest, token: string): Promise<UserCreateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "user/create")

    return handleErrors(
      fetch(url.toString(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        },
        body: JSON.stringify(createUserData)
      })
    );
  }

  public async getUser(getUserData: UserGetRequest, token: string): Promise<UserGetResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "user/get")

    url.searchParams.append('id', String(getUserData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        }
      })
    );
  }

  public async updateUser(updateUserData: UserUpdateRequest, token: string): Promise<UserUpdateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "user/update")

    return handleErrors(
      fetch(url.toString(), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        },
        body: JSON.stringify(updateUserData)
      })
    );
  }

  public async deleteUser(deleteUserData: UserDeleteRequest, token: string): Promise<UserDeleteResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "user/delete")

    url.searchParams.append('id', String(deleteUserData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        }
      })
    );
  }




}
export const UserApi = new API();