import { ICardActionElement } from "./model.d";
import { CardActionEnum, CardEntityEnum } from '@ternala/voltore-types/lib/constants';
import { enterprisesTabs, personsTabs, propertiesTabs } from "../../config/constants/tabs";
import { Pages } from '../../routing';
import uuid from "../../utils/uuid";

export const cardActions: {
  [key in CardActionEnum]: { title: string; to?: Pages; tabName?: string, entityType?: CardEntityEnum };
} = {
  [CardActionEnum.REMIND]: {
    title: 'Remind (Works as Task)',
  },
  [CardActionEnum.TAG]: {
    title: 'Tag',
  },
  [CardActionEnum.MENTION_PROPERTY]: {
    title: 'Mention Property',
    to: 'properties-tab',
    tabName: propertiesTabs.property.path,
    entityType: CardEntityEnum.Property
  },
  [CardActionEnum.MENTION_SALE_TRANSACTION]: {
    title: 'Mention Sale Transaction',
    to: 'property-sale',
    tabName: propertiesTabs.sales.path,
    entityType: CardEntityEnum.Sale_Transaction
  },
  [CardActionEnum.MENTION_LEASE_TRANSACTION]: {
    title: 'Mention Lease Transaction',
    to: 'property-lease',
    tabName: propertiesTabs.leases.path,
    entityType: CardEntityEnum.Lease_Transaction
  },
  [CardActionEnum.MENTION_PERSON]: {
    title: 'Mention Person',
    to: 'people-tab',
    tabName: personsTabs.info.path,
    entityType: CardEntityEnum.Person
  },
  [CardActionEnum.MENTION_INVOLVEMENT]: {
    title: 'Mention Person through Involvement',
    to: 'person-involvement',
    tabName: personsTabs.enterpriseInvolvement.path,
    entityType: CardEntityEnum.Involvement
  },
  [CardActionEnum.MENTION_USER_ROLE]: {
    title: 'Mention User through Role',
    to: 'user',
    entityType: CardEntityEnum.Role
  },
  [CardActionEnum.MENTION_ENTERPRISE]: {
    title: 'Mention Enterprise',
    to: 'enterprises-tab',
    tabName: enterprisesTabs.info.path,
    entityType: CardEntityEnum.Enterprise
  },
};

export const cardActionsArray: ICardActionElement[] = Object.entries(
  cardActions,
).map(([key, item]) => ({
  key: key as CardActionEnum,
  uuid: uuid(),
  title: item.title,
  entityTitle: item.title,
  to: item.to,
  tabName: item.tabName,
  entityType: item.entityType
}));
