import { Suggestion } from 'react-places-autocomplete';
import React from 'react';
import { useDispatch } from 'react-redux';

import Loader from 'components/Loader';
import { Creation } from '../../shared/components/Creation';
import { addShowedElement } from 'controllers/showElement/actions';
import uuid from 'utils/uuid';
import {
  IModalElement,
  ShowElementEnum,
} from 'controllers/showElement/models.d';
import { addressModalKey } from 'components/modals/properties/AddressModal';
import { AddressShortDTO } from '@ternala/voltore-types/lib/modules/address/addressShort.dto';

export interface IProps {
  loading: boolean;
  suggestions: readonly Suggestion[];
  getSuggestionItemProps: Function;
  isNeedCreation?: boolean;
  setAddress: (data: AddressShortDTO | undefined) => void;
}

export const AutoCompleteSuggestionList: React.FC<IProps> = ({
  loading,
  suggestions,
  getSuggestionItemProps,
  isNeedCreation,
  setAddress,
}) => {
  const dispatch = useDispatch();

  if (loading) {
    return (
      <div className="list-options-loader">
        <Loader customStyle={{ margin: '0px' }} />
      </div>
    );
  }


  const generateSuggestionText = (suggestion: Suggestion) => {
    let description = suggestion.description;

    let res: JSX.Element[] = [];
    let prevCounter = 0;

    suggestion.matchedSubstrings.forEach((matchedSubstring) => {
      if (prevCounter < matchedSubstring.offset) {
        res.push(
          <span>
            {description.slice(prevCounter, matchedSubstring.offset)}
          </span>,
        );
      }
      res.push(
        <strong>
          {description.slice(
            matchedSubstring.offset,
            matchedSubstring.offset + matchedSubstring.length,
          )}
        </strong>,
      );

      prevCounter = matchedSubstring.offset + matchedSubstring.length;
    });
    if (prevCounter < description.length - 1) {
      res.push(
        <span>{description.slice(prevCounter, description.length - 1)}</span>,
      );
    }
    return res;
  };

  return (
    <>
      {suggestions.map((suggestion: Suggestion) => {
        const className =
          'list-gItem ' +
          (suggestion.active ? 'suggestion-item--active' : 'suggestion-item');
        const style = suggestion.active
          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
          : { backgroundColor: '#ffffff', cursor: 'pointer' };
        return (
          <div
            {...getSuggestionItemProps(suggestion, {
              className,
              style,
            })}>
            <p className="list-gItem-text">
              {generateSuggestionText(suggestion)}
            </p>
          </div>
        );
      })}
      {!suggestions.length ? (
        <>
          <div className="list-options-loader">
            Please continue typing to specify your address
          </div>
          {isNeedCreation ? (
            <Creation
              getSuggestionItemProps={getSuggestionItemProps}
              creation={{
                label: 'Create custom address',
                onClick: () => {
                  dispatch(
                    addShowedElement({
                      id: uuid(),
                      key: addressModalKey,
                      type: ShowElementEnum.modal,
                      callback: (res: AddressShortDTO) => {
                        setAddress(res);
                      },
                      props: {},
                    } as IModalElement),
                  );
                },
              }}
            />
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default AutoCompleteSuggestionList;
