import React, { FC, ChangeEvent } from 'react'

interface Props {
   className?: string
   containerStyle?: { [key: string]: number | string }
   label: string
   darkTheme?: boolean
   checked?: boolean
   disabled?: boolean
   onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox: FC<Props> = ({ className, label, darkTheme, checked, disabled, onChange, containerStyle }) => {
   return (
      <label className={`checkbox-container ${className || ''}`}  style={{ ...containerStyle }}>
         <input
            className={darkTheme ? "checkbox-dark" : "checkbox"}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={onChange}
         />
         <span className={darkTheme ? "checkbox-label-dark" : "checkbox-label"}>
            { label }
         </span>
      </label>
   )
}

export default Checkbox