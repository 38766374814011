import React, { FC, useState, useEffect, MouseEvent, FormEvent, useRef } from "react";
import { connect, useDispatch } from "react-redux";

/* components */
import InvolvementModal from "components/modals/InvolvementModal";
import EnterpriseModal from "components/modals/EnterpriseModal";
import PopupCreateTaxonomy from "components/modals/PopupCreateTaxonomy";
import Modal from "components/Hocs/Portal";

import InputNumber from "components/UI/controls/InputNumber";
import Textarea from "components/UI/controls/TextArea";
import SelectSearch from "components/UI/controls/SelectSearchNew";
import SelectMultiSearch from "components/UI/controls/SelectMultiSearch";
import SelectListingStatus from "components/UI/controls/SelectListingStatus";
import Attachments from "components/UI/controls/Attachments";
import Button from "components/UI/controls/Button";

/* controllers */
import { getPropertyAction } from "controllers/property/actions/propertyActions";
import { createEnterpriseAction } from "controllers/enterprise/actions";
import { createInvolvementAction } from "controllers/involvement/actions";
import {
  createTaxonomyAction,
  getTaxonomiesByTypeAction
} from "controllers/taxonomy/actions";

/* constants & utils */
import { RequestActionEnum, statusOrder, Unit } from "config/constants";
import {
  loadEnterprisesOptions,
  loadEnterpriseInvolvementsOptions,
  loadPropertiesOptions,
  saleTransactionStatusOptions
} from "config/constants/select-options";
import {
  LeaseTransactionStatusEnum,
  TaxonomyTypeSlug
} from "@ternala/voltore-types/lib/constants";
import { formatAddress, numberToString } from "utils/helper-functions";

/* types */
import { IStore } from "controllers/store";
import {
  IPropertyState,
  SaleTransactionCreateRequestExpanded,
  SaleTransactionUpdateRequestExpanded
} from "controllers/property/models";
import { ITaxonomyState } from "controllers/taxonomy/models";
import {
  PropertyShortDTO,
  SaleTransactionStatusHistoryDTO,
  TaxonomyCreateResponse
} from "@ternala/voltore-types";
import { INewTaxonomy, OptionType } from "models";
import {
  createSaleTransactionAction,
  updateSaleTransactionAction
} from "controllers/property/actions";
import PopupTagGroup from "../PopupTagGroup";
import { TagApi } from "controllers/tag/transport/tag.api";
import { ITagState } from "controllers/tag/models.d";
import { addNotification } from "controllers/modals/actions";
import uuid from "utils/uuid";
import { NotificationTypeEnum } from "controllers/modals/models.d";
import CustomScrollbars from "components/CustomScrollbars";
import { propertyToAction } from "@ternala/voltore-types/lib/card";
import { getAccessTokenUtil } from "utils";
import { addShowedElement } from "controllers/showElement/actions";
import {
  IModalElement,
  ShowElementEnum
} from "../../../controllers/showElement/models.d";
import { propertyModalKey } from "./PropertyModalConent";

interface Props {
  property: {
    id: number;
    transactionId?: number;
  };
  propertyState: IPropertyState;
  taxonomyState: ITaxonomyState;
  onClose: () => void;
  onSubmit: (
    payload: (
      | SaleTransactionCreateRequestExpanded
      | SaleTransactionUpdateRequestExpanded
      ) & { callback?: Function },
    requestAction: RequestActionEnum
  ) => void;
  tagState: ITagState;
}

const SaleTransactionModal: FC<Props> = (props) => {
  const {
    property,
    propertyState: { propertyData, saleTransactionData },
    taxonomyState: { taxonomies },
    // tagState: { entities },
    onClose,
    onSubmit
  } = props;

  const currentProperty = propertyData[property.id];
  const editableTransaction = property.transactionId
    ? saleTransactionData[property.transactionId]
    : undefined;

  console.log('editableTransaction: ', editableTransaction);

  const dispatch = useDispatch();

  // const [tags, setTags] = useState<OptionType[] | undefined>(
  //   entities[TagTypeEnum.saleTransaction][property.id]?.map((card) => ({
  //     label: card.title,
  //     value: card.id,
  //   })) || [],
  // );
  // const [isTagCreationOpen, setIsTagCreationOpen] = useState<boolean>(false);
  const [requestProcess, setRequestProcess] = useState<boolean>(false);
  const [isTagCategoryCreationOpen, setIsTagCategoryCreationOpen] =
    useState<boolean>(false);
  // const [isPropertyModalOpen, setIsPropertyModalOpen] =
  //   useState<boolean>(false);
  const [isInvolvementModalOpen, setIsInvolvementModalOpen] = useState<
    "buyer" | "seller" | undefined
  >(undefined);
  const [isEnterpriseModalOpen, setIsEnterpriseModalOpen] = useState<
    "buyer" | "seller" | undefined
  >(undefined);
  const [isCreateTaxonomyPopupOpen, setIsCreateTaxonomyPopupOpen] =
    useState<boolean>(false);
  const [creatableTaxonomy, setCreatableTaxonomy] = useState<INewTaxonomy>();

  const [propertyName, setPropertyName] = useState<OptionType | undefined>({
    label: propertyToAction(currentProperty)?.entityTitle,
    value: currentProperty?.id
  });
  const [listingStatus, setListingStatus] = useState<
    SaleTransactionStatusHistoryDTO[]
  >(editableTransaction?.historyStatuses || []);
  const [listingStatusError, setListingStatusError] = useState<
    string | undefined
  >(undefined);
  const [seller, setSeller] = useState<OptionType | undefined>(
    editableTransaction?.seller || currentProperty.owner
      ? editableTransaction?.seller
        ? {
          label: editableTransaction.seller.title,
          value: editableTransaction.seller.id
        }
        : {
          label: currentProperty.owner?.title,
          value: currentProperty.owner?.id
        }
      : undefined
  );
  const [buyer, setBuyer] = useState<OptionType | undefined>(
    editableTransaction?.buyer && {
      label: editableTransaction.buyer.title,
      value: editableTransaction.buyer.id
    }
  );
  const [listPrice, setListPrice] = useState<string>(
    numberToString(editableTransaction?.listPrice)
  );
  const [soldPrice, setSoldPrice] = useState<string>(
    numberToString(editableTransaction?.soldPrice)
  );
  console.log('soldPrice: ', soldPrice);
  const [capRate, setCapRate] = useState<string>(
    numberToString(editableTransaction?.capRate)
  );

  const isNoiChanged = useRef<boolean>(!!editableTransaction?.NOI);

  const [noi, setNoi] = useState<string>(
    numberToString(editableTransaction?.NOI)
  );

  useEffect(() => {
    let localNOI = Number(soldPrice) * Number(capRate);
    setNoi(localNOI === 0 ? "" : String(localNOI / 100));
  }, [soldPrice, capRate]);

  const [percentLeasedAtSale, setPercentLeasedAtSale] = useState<string>(
    numberToString(editableTransaction?.leaseAtSale)
  );
  const [financing, setFinancing] = useState<OptionType | undefined>(
    editableTransaction?.[TaxonomyTypeSlug.financing] && {
      label: editableTransaction[TaxonomyTypeSlug.financing]?.title,
      value: editableTransaction[TaxonomyTypeSlug.financing]?.id
    }
  );
  const [saleType, setSaleType] = useState<OptionType | undefined>(
    editableTransaction?.[TaxonomyTypeSlug.saleType] && {
      label: editableTransaction[TaxonomyTypeSlug.saleType]?.title,
      value: editableTransaction[TaxonomyTypeSlug.saleType]?.id
    }
  );
  const [involvedFromBuyersSide, setInvolvedFromBuyersSide] = useState<
    OptionType[] | undefined
  >(
    editableTransaction?.involvementsFromBuyerSide?.map((item) => ({
      label: `${item.person?.firstName} ${item.person?.lastName}`,
      value: item.id
    })) || []
  );
  const [involvedFromSellersSide, setInvolvedFromSellersSide] = useState<
    OptionType[] | undefined
  >(
    editableTransaction?.involvementsFromSellerSide?.map((item) => ({
      label: `${item.person?.firstName}${
        item.person?.middleName ? " " + item.person?.middleName : ""
      } ${item.person?.lastName}${
        item.person?.nickName ? ` (${item.person?.nickName})` : ""
      }`,
      value: item.id
    })) || []
  );
  const [details, setDetails] = useState<string>(
    editableTransaction?.details || ""
  );

  const [oldSupplements, setOldSupplements] = useState<number[]>(
    editableTransaction?.supplements?.map((supplement) => supplement.id) || []
  );
  const [newSupplements, setNewSupplements] = useState<File[]>([]);

  useEffect(() => {
    dispatch(getPropertyAction.request({ id: property.id }));
    dispatch(
      getTaxonomiesByTypeAction.request({ type: TaxonomyTypeSlug.financing })
    );
    dispatch(
      getTaxonomiesByTypeAction.request({ type: TaxonomyTypeSlug.saleType })
    );
  }, []);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.addEventListener("keydown", closeOnEscPress);

    return () => {
      document.body.removeAttribute("class");
      document.removeEventListener("keydown", closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  /* listing status validation */
  const validation = (arr: any[]) => {
    for (let i = 0; i < arr.length; i++) {
      if (i === 0) continue;
      const status = statusOrder[arr[i].status as LeaseTransactionStatusEnum];
      const prevStatus =
        statusOrder[arr[i - 1].status as LeaseTransactionStatusEnum];
      if (prevStatus >= status)
        setListingStatusError(
          "Order of transaction statuses is incorrect. Please see the instruction"
        );
      else setListingStatusError(undefined);
    }
  };

  const submitData = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>
  ) => {
    validation(listingStatus);
    if (listingStatusError) return;
    event.preventDefault();
    setRequestProcess(true);
    if (editableTransaction && property.transactionId) {
      const payload: ReturnType<
        typeof updateSaleTransactionAction.request
      >["payload"] = {
        ...createPayload(),
        id: editableTransaction?.id,
        oldSupplements,
        callback: (status: boolean) => {
          if (status) onClose();
          setRequestProcess(false);
        }
      };
      onSubmit(payload, RequestActionEnum.UPDATE);
    } else {
      const payload: ReturnType<
        typeof createSaleTransactionAction.request
      >["payload"] = {
        ...createPayload(),
        callback: (status: boolean) => {
          if (status) onClose();
          setRequestProcess(false);
        }
      };
      onSubmit(payload, RequestActionEnum.CREATE);
    }
  };

  const createPayload = (): SaleTransactionCreateRequestExpanded => {
    const payload: SaleTransactionCreateRequestExpanded = {
      property: Number(currentProperty?.id),
      statuses: listingStatus.map((status) => JSON.stringify(status))
    };

    if (propertyName?.value) payload.property = Number(propertyName.value);
    if (seller?.value) payload.seller = Number(seller.value);
    if (buyer?.value) payload.buyer = Number(buyer.value);
    if (listPrice) payload.listPrice = Number(listPrice);
    if (soldPrice) payload.soldPrice = Number(soldPrice);
    if (seller?.value) payload.seller = Number(seller.value);
    if (buyer?.value) payload.buyer = Number(buyer.value);
    if (capRate) payload.capRate = Number(capRate);
    if (noi) payload.NOI = Number(noi);
    if (percentLeasedAtSale) payload.leaseAtSale = Number(percentLeasedAtSale);
    if (financing?.value)
      payload[TaxonomyTypeSlug.financing] = Number(financing.value);
    if (saleType?.value)
      payload[TaxonomyTypeSlug.saleType] = Number(saleType.value);
    if (involvedFromBuyersSide?.length)
      payload.involvementsFromBuyerSide = involvedFromBuyersSide.map((item) =>
        Number(item.value)
      );
    if (involvedFromSellersSide?.length)
      payload.involvementsFromSellerSide = involvedFromSellersSide.map((item) =>
        Number(item.value)
      );
    if (newSupplements.length) payload.supplements = newSupplements;
    if (details) payload.details = details;
    // if (tags) payload.tags = tags.map((item) => item.value as number);

    return payload;
  };

  useEffect(() => {
    console.log("change");
    console.log("isNoiChanged.current: ", isNoiChanged.current);
    console.log({
      noi,
      isNoiChanged: isNoiChanged.current,
      capRate,
      soldPrice
    });
    if (capRate && soldPrice && !isNoiChanged.current) {
      setNoi(numberToString(Number(soldPrice) * (Number(capRate) / 100)));
    }
  }, [capRate, soldPrice]);

  return (
    <>
      <Modal>
        <div className="modal modal-sale-transaction">
          <div className="scroll-area">
            <CustomScrollbars
              style={{
                width: "700px",
                height: "100%"
              }}
              renderView={(props) => (
                <div
                  {...props}
                  className={"scroll-area"}
                  style={{
                    position: "absolute",
                    inset: "0px",
                    overflowY: "scroll",
                    marginRight: `${
                      navigator.platform === "MacIntel" ? "-17px" : "-35px"
                    }`,
                    marginBottom: "-17px"
                  }}
                />
              )}>
              <form className="modal-content" onSubmit={submitData}>
                <div className="modal__close" onClick={onClose} title="Close" />

                <div className="modal-title">
                  {editableTransaction ? "edit" : "create"} sale transaction
                </div>

                <div className="flex-container" style={{ margin: 0 }}>
                  <SelectSearch
                    label="property"
                    asyncOptions={async (searchParams) => {
                      {
                        const token = await getAccessTokenUtil(dispatch);
                        if (token) {
                          return await loadPropertiesOptions(
                            searchParams,
                            token
                          );
                        }
                        return [];
                      }
                    }}
                    searchByFields={["title", "address"]}
                    value={propertyName && [propertyName]}
                    onChange={(options) => setPropertyName(options?.[0])}
                    creation={{
                      label: "Create new property",
                      onClick: () => {
                        addShowedElement({
                          id: uuid(),
                          key: propertyModalKey,
                          type: ShowElementEnum.modal,
                          props: {},
                          callback: (payload: PropertyShortDTO) => {
                            setPropertyName({
                              label: formatAddress({
                                address: payload.address.address,
                                city: payload.address.city,
                                state: payload.address.state,
                                zipCode: payload.address.zipCode
                              })
                            });
                          }
                        } as IModalElement);
                        // setIsPropertyModalOpen(true)
                      }
                    }}
                    required
                    readonly={!!editableTransaction}
                  />

                  <InputNumber
                    label="Building SQFT"
                    defaultValue={numberToString(
                      propertyData[propertyName?.value as number]?.sqft
                    )}
                    onChange={() => {
                    }}
                    max={10000000000}
                    readonly
                    upperCaseLabel
                  />
                </div>

                <SelectListingStatus
                  transactionType="sale"
                  containerStyle={{ marginBottom: 50 }}
                  options={saleTransactionStatusOptions}
                  history={listingStatus}
                  onUpdate={(status) => setListingStatus(status as any)}
                  setActiveStatus={!property.transactionId}
                  error={listingStatusError}
                  setError={setListingStatusError}
                  required
                />
                <div className="flex-container">
                  <SelectSearch
                    label="seller"
                    asyncOptions={async (searchParams) => {
                      const token = await getAccessTokenUtil(dispatch);
                      if (token) {
                        return await loadEnterprisesOptions(
                          searchParams,
                          token
                        );
                      }
                      return [];
                    }}
                    searchByFields={["title", "type"]}
                    value={seller && [seller]}
                    onChange={(options) => setSeller(options?.[0])}
                    creation={{
                      label: "Create new enterprise",
                      onClick: () => setIsEnterpriseModalOpen("seller")
                    }}
                  />

                  <SelectSearch
                    label="buyer"
                    asyncOptions={async (searchParams) => {
                      const token = await getAccessTokenUtil(dispatch);
                      if (token) {
                        return await loadEnterprisesOptions(
                          searchParams,
                          token
                        );
                      }
                      return [];
                    }}
                    searchByFields={["title", "type"]}
                    value={buyer && [buyer]}
                    onChange={(options) => setBuyer(options?.[0])}
                    creation={{
                      label: "Create new enterprise",
                      onClick: () => setIsEnterpriseModalOpen("buyer")
                    }}
                  />
                </div>

                <div className="flex-container">
                  <InputNumber
                    units={[
                      { type: Unit.PRICE, label: "list price" },
                      { type: Unit.PRICE_PER_SQFT, label: "list price/sqft" }
                    ]}
                    sqft={currentProperty?.sqft}
                    placeholder="Enter numbers only"
                    defaultValue={listPrice}
                    onChange={(price) => setListPrice(price)}
                    max={1000000000000}
                    upperCaseLabel
                  />

                  <InputNumber
                    units={[
                      { type: Unit.PRICE, label: "sold price" },
                      { type: Unit.PRICE_PER_SQFT, label: "sold price/sqft" }
                    ]}
                    sqft={currentProperty?.sqft}
                    placeholder="Enter numbers only"
                    defaultValue={soldPrice}
                    onChange={(price) => setSoldPrice(price)}
                    max={1000000000000}
                    upperCaseLabel
                  />
                </div>

                <div className="flex-container">
                  <div>
                    <InputNumber
                      label="cap rate"
                      unit="%"
                      placeholder="Enter numbers only"
                      defaultValue={String(capRate)}
                      onChange={(capRate) => setCapRate(capRate)}
                      max={100}
                      thousandSeparator={false}
                      upperCaseLabel
                    />

                    <InputNumber
                      label="noi"
                      unit="$"
                      placeholder="Enter numbers only"
                      defaultValue={noi}
                      onChange={(noi) => {
                        setNoi(noi);
                        isNoiChanged.current = true;
                      }}
                      max={10000000000}
                      upperCaseLabel
                    />

                    <InputNumber
                      label="% leased at sale"
                      unit="%"
                      placeholder="Enter numbers only"
                      defaultValue={String(percentLeasedAtSale)}
                      onChange={(percent) => setPercentLeasedAtSale(percent)}
                      max={100}
                      thousandSeparator={false}
                      upperCaseLabel
                    />
                  </div>

                  <div>
                    <SelectSearch
                      label="financing"
                      options={taxonomies?.[TaxonomyTypeSlug.financing]?.map(
                        (taxonomy) => ({
                          label: taxonomy.title,
                          value: taxonomy.id
                        })
                      )}
                      value={financing && [financing]}
                      onChange={(options) => setFinancing(options?.[0])}
                      creation={{
                        label: "Create new financing",
                        onClick: () => {
                          setCreatableTaxonomy(creatableTaxonomyMap.financing);
                          setIsCreateTaxonomyPopupOpen(true);
                        }
                      }}
                    />

                    <SelectSearch
                      label="sale type"
                      options={taxonomies?.[TaxonomyTypeSlug.saleType]?.map(
                        (taxonomy) => ({
                          label: taxonomy.title,
                          value: taxonomy.id
                        })
                      )}
                      value={saleType && [saleType]}
                      onChange={(options) => setSaleType(options?.[0])}
                      creation={{
                        label: "Create new sale type",
                        onClick: () => {
                          setCreatableTaxonomy(creatableTaxonomyMap.saleType);
                          setIsCreateTaxonomyPopupOpen(true);
                        }
                      }}
                    />
                  </div>
                </div>

                {/*<div className="tag-field">*/}
                {/*  <SelectMultiSearch*/}
                {/*    label="tag(s)"*/}
                {/*    asyncOptions={async (searchParams) => {*/}
                {/*      const token = await getAccessTokenUtil(dispatch);*/}
                {/*      if (token) {*/}
                {/*        return await loadTagsOptions(searchParams, token);*/}
                {/*      }*/}
                {/*      return [];*/}
                {/*    }}*/}
                {/*    selectedOptions={tags}*/}
                {/*    onChange={(options) => setTags(options)}*/}
                {/*    creation={{*/}
                {/*      label: 'Create new tag',*/}
                {/*      onClick: () => {*/}
                {/*        setIsTagCreationOpen(true);*/}
                {/*      },*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</div>*/}

                <div className="flex-container">
                  <div>
                    <SelectMultiSearch
                      label="Involved from seller's side"
                      selectedOptions={involvedFromSellersSide}
                      asyncOptions={async (searchParams) => {
                        const token = await getAccessTokenUtil(dispatch);
                        if (token) {
                          return await loadEnterpriseInvolvementsOptions(
                            searchParams,
                            Number(seller?.value),
                            token
                          );
                        }
                        return [];
                      }}
                      searchByFields={[
                        "firstName",
                        "lastName",
                        "involvementType",
                        "enterprise"
                      ]}
                      onChange={(options) =>
                        setInvolvedFromSellersSide(options)
                      }
                      creation={{
                        label: "Create new involvement",
                        onClick: () => setIsInvolvementModalOpen("seller")
                      }}
                    />

                    <Attachments
                      label="supplements"
                      buttonText="click to upload .pdf .doc and images"
                      fileTypes={["pdf", "doc", "docx", "images"]}
                      attachments={editableTransaction?.supplements}
                      onChange={(oldSupplements, newSupplements) => {
                        setOldSupplements(oldSupplements);
                        setNewSupplements(newSupplements);
                      }}
                    />
                  </div>

                  <div>
                    <SelectMultiSearch
                      label="Involved from buyer's side"
                      selectedOptions={involvedFromBuyersSide}
                      asyncOptions={async (searchParams) => {
                        {
                          const token = await getAccessTokenUtil(dispatch);
                          if (token) {
                            return await loadEnterpriseInvolvementsOptions(
                              searchParams,
                              Number(buyer?.value),
                              token
                            );
                          }
                          return [];
                        }
                      }}
                      searchByFields={[
                        "firstName",
                        "lastName",
                        "involvementType",
                        "enterprise"
                      ]}
                      onChange={(options) => setInvolvedFromBuyersSide(options)}
                      // isDisabled={!buyer?.value}
                      creation={{
                        label: "Create new involvement",
                        onClick: () => setIsInvolvementModalOpen("buyer")
                      }}
                    />

                    <Textarea
                      label="details"
                      placeholder="Type here"
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                    />
                  </div>
                </div>

                <footer className="modal-footer">
                  <Button variant="light" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="dark"
                    onClick={submitData}
                    disabled={
                      !property ||
                      !listingStatus.length ||
                      requestProcess ||
                      listingStatusError !== undefined
                    }>
                    Save
                  </Button>
                </footer>
              </form>
            </CustomScrollbars>
          </div>

          <div className="modal-overlay" onClick={onClose} />
        </div>
      </Modal>

      {/*{isTagCreationOpen ? (*/}
      {/*  <PopupTag*/}
      {/*    setGroupCreation={setIsTagCategoryCreationOpen}*/}
      {/*    onClose={() => {*/}
      {/*      setIsTagCreationOpen(false);*/}
      {/*    }}*/}
      {/*    onCreate={async function (data) {*/}
      {/*      const token = await getAccessTokenUtil(dispatch);*/}
      {/*      let res: any;*/}
      {/*      if (token) res = await TagApi.createTag(data, token);*/}
      {/*      setTags([*/}
      {/*        ...(tags || []),*/}
      {/*        {*/}
      {/*          label: `${res.title} - ${res.category.title}`,*/}
      {/*          value: res.id,*/}
      {/*        },*/}
      {/*      ]);*/}
      {/*      setIsTagCreationOpen(false);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  ''*/}
      {/*)}*/}
      {isTagCategoryCreationOpen ? (
        <PopupTagGroup
          onClose={() => {
            setIsTagCategoryCreationOpen(false);
          }}
          onCreate={async function(data) {
            const token = await getAccessTokenUtil(dispatch);
            if (token) await TagApi.createTagCategory(data, token);
          }}
        />
      ) : (
        ""
      )}
      {isInvolvementModalOpen && (
        <InvolvementModal
          onClose={() => setIsInvolvementModalOpen(undefined)}
          onSubmit={(payload) => {
            dispatch(
              createInvolvementAction.request({
                ...payload,
                callback: (status: boolean, label: string, value: number) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`
                      })
                    );
                    const option: OptionType = {
                      label,
                      value
                    };
                    isInvolvementModalOpen === "buyer"
                      ? setInvolvedFromBuyersSide([
                        ...(involvedFromBuyersSide || []),
                        option
                      ])
                      : setInvolvedFromSellersSide([
                        ...(involvedFromSellersSide || []),
                        option
                      ]);
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: "Something goes wrong",
                        type: NotificationTypeEnum.error,
                        title: "Something goes wrong"
                      })
                    );
                    setIsInvolvementModalOpen(undefined);
                  }
                  if (payload.callback) payload.callback(status);
                },
                additionalFields: {
                  person: payload.person,
                  enterprise: payload.enterprise
                }
              })
            );
          }}
        />
      )}

      {isEnterpriseModalOpen && (
        <EnterpriseModal
          onClose={() => setIsEnterpriseModalOpen(undefined)}
          onSubmit={(payload) => {
            dispatch(
              createEnterpriseAction.request({
                ...payload,
                callback: (status: boolean, id: number) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`
                      })
                    );
                    const option: OptionType = {
                      label: payload.title,
                      value: id
                    };
                    isEnterpriseModalOpen === "buyer"
                      ? setBuyer(option)
                      : setSeller(option);
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: "Something goes wrong",
                        type: NotificationTypeEnum.error,
                        title: "Something goes wrong"
                      })
                    );
                  }
                  if (payload.callback) payload.callback(status);
                }
              })
            );
            setIsEnterpriseModalOpen(undefined);
          }}
        />
      )}

      {isCreateTaxonomyPopupOpen && (
        <PopupCreateTaxonomy
          title={creatableTaxonomy?.title}
          onClose={() => setIsCreateTaxonomyPopupOpen(false)}
          onSubmit={(title, callback) => {
            dispatch(
              createTaxonomyAction.request({
                title,
                type: creatableTaxonomy?.type as TaxonomyTypeSlug,
                callback: (res: false | TaxonomyCreateResponse) => {
                  if (res) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`
                      })
                    );
                    const option: OptionType = {
                      label: title,
                      value: res.id
                    };
                    switch (creatableTaxonomy?.type as TaxonomyTypeSlug) {
                      case TaxonomyTypeSlug.financing:
                        setFinancing(option);
                        break;
                      case TaxonomyTypeSlug.saleType:
                        setSaleType(option);
                        break;
                      default:
                        break;
                    }
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: "Something goes wrong",
                        type: NotificationTypeEnum.error,
                        title: "Something goes wrong"
                      })
                    );
                  }
                  if (callback) callback(res);
                }
              })
            );
            setIsCreateTaxonomyPopupOpen(false);
          }}
        />
      )}
    </>
  );
};

const creatableTaxonomyMap: { [key: string]: INewTaxonomy } = {
  financing: {
    title: "Create financing",
    type: TaxonomyTypeSlug.financing
  },
  saleType: {
    title: "Create sale type",
    type: TaxonomyTypeSlug.saleType
  }
};

export default connect((store: IStore) => ({
  propertyState: store.property,
  taxonomyState: store.taxonomy,
  tagState: store.tag
}))(SaleTransactionModal);
