import React, { FC, useState, useEffect } from 'react';
import Moment from 'react-moment';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';

/* components */
import NotFoundPage from 'pages/Static/NotFound';
import InvolvementModal from 'components/modals/InvolvementModal';
import PopupDelete from 'components/modals/PopupDelete';
import OptionsMenu from 'components/UI/controls/OptionsMenu';
import Link from 'components/UI/controls/Link';
import Loader from 'components/Loader';

/* controllers */
import {
  getInvolvementAction,
  updateInvolvementAction,
  deleteInvolvementAction,
} from 'controllers/involvement/actions';

/* constants & utils */
import { DATE_FORMAT } from 'config';
import { EntityEnum, TaxonomyTypeSlug } from "@ternala/voltore-types/lib/constants";
import { LoaderAction, PageEnum } from 'config/constants';
import { personsTabs, enterprisesTabs } from 'config/constants/tabs';

/* types */
import { IStore } from 'controllers/store';
import { IPersonState } from 'controllers/person/models';
import {
  IInvolvementState,
  IResponseAdditionalFields,
} from 'controllers/involvement/models';
import { InvolvementUpdateRequest } from '@ternala/voltore-types';
import { addNotification } from 'controllers/modals/actions';
import uuid from 'utils/uuid';
import { NotificationTypeEnum } from 'controllers/modals/models.d';
import { Helmet } from 'react-helmet';
import CustomScrollbars from 'components/CustomScrollbars';
import { getMiddleImage } from "../../../../utils/getImage";
import { AddressField } from "../../../../components/views/address";

interface Props
  extends RouteComponentProps<{
    id?: string;
    tabName?: string;
    involvementId: string;
  }> {
  personsState: IPersonState;
  involvementState: IInvolvementState;
}

const PersonInvolvementInfo: FC<Props> = (props) => {
  const {
    match: { params },
    personsState: { personData },
    involvementState: { involvementData },
  } = props;

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPopupDeleteOpen, setIsPopupDeleteOpen] = useState<boolean>(false);

  const personId = Number(params?.id);
  const involvementId = Number(params?.involvementId);

  const person = personId ? personData[personId] : undefined;
  const involvement = involvementData[involvementId];
  const loaders = person?.involvementState?.state?.loaders;

  useEffect(() => {
    const loadingItem = loaders?.some(
      (loader) => loader.type === LoaderAction.involvement.getItem,
    );
    loadingItem !== undefined && setIsLoading(loadingItem);
  }, [loaders]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getInvolvementAction.request({
        id: involvementId,
        additionalFields: {
          person: personId,
        },
        callback: () => setIsLoading(false),
      }),
    );
  }, []);

  if (isNaN(involvementId)) {
    return <NotFoundPage />;
  }

  return involvement ? (
    <>
      <div id="involvement-info-container">
        <Helmet>
          <title>
            {involvement.enterprise?.title
              ? involvement.enterprise?.title
              : 'Involvement history'}{' '}
            | Voltore
            {process.env.REACT_APP_COMPANY_NAME
              ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        {isLoading ? (
          <div className="loader-info">
            <Loader />
          </div>
        ) : (
          <CustomScrollbars id="involvement-info-scrollbars">
            <div className="involvement-info">
              <header>
                <h2>Involvement info</h2>

                <OptionsMenu
                  on={{
                    edit: () => setIsModalOpen(true),
                    delete: () => setIsPopupDeleteOpen(true),
                  }}
                />
              </header>

              <div className="involvement-info__content">
                {involvement?.enterprise?.images?.length && (
                  <div className="enterprise-logo">
                    <img src={getMiddleImage(involvement.enterprise.images)} alt="" />
                  </div>
                )}

                <div className="content__data-fields">
                  <div className="data-field">
                    <div className="data-field__key">Position or role</div>
                    <div className="data-field__value">
                      <div className="data-field__position-or-role">
                        {involvement?.[TaxonomyTypeSlug.involvementType]?.title}
                      </div>

                      <div className="data-field__involvement-date">
                        {involvement?.startDate ? (
                          <Moment format={DATE_FORMAT}>
                            {involvement.startDate}
                          </Moment>
                        ) : (
                          'N\\A'
                        )}
                        {involvement?.endDate ? (
                          <>
                            &nbsp;-&nbsp;
                            <Moment format={DATE_FORMAT}>
                              {involvement.endDate}
                            </Moment>
                          </>
                        ) : (
                          ' - Present'
                        )}
                      </div>
                    </div>
                  </div>

                  {involvement?.enterprise?.title && (
                    <div className="data-field">
                      <div className="data-field__key">Enterprise name</div>
                      <div className="data-field__value">
                        <Link
                          id={involvement.enterprise.id}
                          type={EntityEnum.enterprise}
                          fullPath={`/${PageEnum.ENTERPRISES}/${involvement.enterprise.id}/${enterprisesTabs.info.path}`}
                          newTabIcon>
                          {involvement.enterprise?.title}
                        </Link>
                      </div>
                    </div>
                  )}

                  {involvement?.owner && (
                    <div className="data-field">
                      <div className="data-field__key">Principal</div>
                      <div className="data-field__value">
                        {involvement.owner ? 'Yes' : 'No'}
                      </div>
                    </div>
                  )}

                  {involvement?.decisionMaker && (
                    <div className="data-field">
                      <div className="data-field__key">Decision maker</div>
                      <div className="data-field__value">
                        {involvement.decisionMaker ? 'Yes' : 'No'}
                      </div>
                    </div>
                  )}

                  <AddressField label={'Office address'} address={involvement?.enterprise?.officeAddress} />

                  <div className="data-field">
                    <div
                      className={`data-field__key_lg ${
                        involvement?.description ? '' : 'no-information'
                      }`}>
                      Description
                    </div>
                    <div className="data-field__value">
                      {involvement?.description ? (
                        involvement?.description
                      ) : (
                        <span className="no-information">-</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CustomScrollbars>
        )}
      </div>

      {isModalOpen && (
        <InvolvementModal
          person={{
            id: personId,
            involvementId,
          }}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(payload) => {
            dispatch(
              updateInvolvementAction.request({
                ...payload,
                additionalFields: {
                  person: payload.person,
                  enterprise: payload.enterprise,
                },
                callback: (status: boolean) => {
                  if (status) {
                    // if update was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully updated`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully updated`,
                      }),
                    );
                  } else {
                    // if update was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (payload.callback) payload.callback(status);
                },
                // callback: pathname.startsWith(`/${PageEnum.ENTERPRISES}`) ? loadInvolvements : undefined
              } as InvolvementUpdateRequest & { additionalFields: IResponseAdditionalFields } & { callback?: Function }),
            );
          }}
        />
      )}

      {isPopupDeleteOpen && (
        <PopupDelete
          onClose={() => setIsPopupDeleteOpen(false)}
          onSubmit={(callback) => {
            dispatch(
              deleteInvolvementAction.request({
                id: involvementId,
                additionalFields: {
                  person: personId,
                },
                callback: (status: boolean) => {
                  if (status) {
                    // if delete was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully deleted`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully deleted`,
                      }),
                    );
                    if (callback) callback();
                  } else {
                    // if delete was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                },
              }),
            );
            dispatch(
              push(
                `/${PageEnum.PEOPLE}/${params.id}/${personsTabs.enterpriseInvolvement.path}`,
              ),
            );
          }}
        />
      )}
    </>
  ) : null;
};

export default connect((store: IStore) => ({
  personsState: store.person,
  involvementState: store.involvement,
}))(PersonInvolvementInfo);
