
import React, { FC, useEffect } from 'react';
import Modal from 'components/Hocs/Portal';
import Button from 'components/UI/controls/Button';

interface Props {
  onClose: () => void;
  onSubmitNo: (callback: Function) => void;
  onSubmitYes: (callback: Function) => void;
}

export const PopupEditTransaction: FC<Props> = ({
  onClose,
  onSubmitNo,
  onSubmitYes,
}) => {
  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onClose();
  };

  return (
    <Modal>
      <div className="modal popup popup-edit-transaction">
        <div className="scroll-area">
          <div className="modal-content">
            <div
              className="popup-edit-transaction__close"
              onClick={onClose}
              title="Close"
            />
            <div className="vertical-center-phone">
              <div className="popup-edit-transaction__text">
                Do you want to add any details on the deal?
              </div>
            </div>
            <footer className="modal-footer">
              <Button variant="light" onClick={() => onSubmitNo(onClose)}>
                No
              </Button>
              <Button variant="dark" onClick={() => onSubmitYes(onClose)}>
                Yes
              </Button>
            </footer>
          </div>
        </div>
        <div className="modal-overlay" onClick={onClose} />
      </div>
    </Modal>
  );
};

export default PopupEditTransaction;
