import { appName } from 'config';
import { createAsyncAction } from 'typesafe-actions';
import {
  EnterpriseCreateRequestExpanded,
  EnterpriseUpdateRequestExpanded,
} from './models';
import {
    EnterpriseCreateResponse,
    EnterpriseDeleteRequest,
    EnterpriseDeleteResponse,
    EnterpriseFullExpandedDTO, EnterpriseFullWithTreeDTO,
    EnterpriseGetListRequest,
    EnterpriseGetListResponse,
    EnterpriseGetRequest,
    EnterpriseGetResponse,
    EnterpriseUpdateResponse,
    PropertyOwnedDTO,
} from '@ternala/voltore-types';
import { IException } from "../store";

/* Actions */
export const widgetName = 'enterprise';

// ** Action
export const getEnterprisesAction = createAsyncAction(
  `${appName}/${widgetName}/GET_ENTERPRISES_REQUEST`,
  `${appName}/${widgetName}/GET_ENTERPRISES_SUCCESS`,
  `${appName}/${widgetName}/GET_ENTERPRISES_FILED`,
)<
  EnterpriseGetListRequest & { callback?: Function },
  {
    response: EnterpriseGetListResponse;
    searchParams: EnterpriseGetListRequest;
    isAll: boolean;
  },
  IException
>();

export const createEnterpriseAction = createAsyncAction(
  `${appName}/${widgetName}/CREATE_ENTERPRISE_REQUEST`,
  `${appName}/${widgetName}/CREATE_ENTERPRISE_SUCCESS`,
  `${appName}/${widgetName}/CREATE_ENTERPRISE_FILED`,
)<
  EnterpriseCreateRequestExpanded & { callback?: Function } & {
    loadCallback?: Function;
  },
  EnterpriseCreateResponse,
  IException
>();

export const getEnterpriseAction = createAsyncAction(
  `${appName}/${widgetName}/GET_ENTERPRISE_REQUEST`,
  `${appName}/${widgetName}/GET_ENTERPRISE_SUCCESS`,
  `${appName}/${widgetName}/GET_ENTERPRISE_FILED`,
)<
  EnterpriseGetRequest & { callback?: Function },
  EnterpriseGetResponse,
  IException
>();

export const updateEnterpriseAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_ENTERPRISE_REQUEST`,
  `${appName}/${widgetName}/UPDATE_ENTERPRISE_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_ENTERPRISE_FILED`,
)<
  EnterpriseUpdateRequestExpanded & { callback?: Function },
  EnterpriseUpdateResponse,
  IException
>();

export const deleteEnterpriseAction = createAsyncAction(
  `${appName}/${widgetName}/DELETE_ENTERPRISE_REQUEST`,
  `${appName}/${widgetName}/DELETE_ENTERPRISE_SUCCESS`,
  `${appName}/${widgetName}/DELETE_ENTERPRISE_FILED`,
)<
  EnterpriseDeleteRequest & { callback?: Function },
  EnterpriseDeleteResponse & { id?: number },
  IException
>();

export const getEnterpriseTreeAction = createAsyncAction(
  `${appName}/${widgetName}/GET_ENTERPRISE_TREE_REQUEST`,
  `${appName}/${widgetName}/GET_ENTERPRISE_TREE_SUCCESS`,
  `${appName}/${widgetName}/GET_ENTERPRISE_TREE_FILED`,
)<
  EnterpriseGetRequest & { callback?: Function },
  EnterpriseFullWithTreeDTO,
  IException
>();

export const getEnterpriseOwnedPropertiesAction = createAsyncAction(
  `${appName}/${widgetName}/GET_ENTERPRISE_OWNED_PROPERTIES_REQUEST`,
  `${appName}/${widgetName}/GET_ENTERPRISE_OWNED_PROPERTIES_SUCCESS`,
  `${appName}/${widgetName}/GET_ENTERPRISE_OWNED_PROPERTIES_FILED`,
)<
  { id: number } & { callback?: Function },
  { response: PropertyOwnedDTO[]; id: number },
  IException
>();
