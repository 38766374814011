
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

/* types */
import { IStore } from 'controllers/store';
import LeaseTransactionInfoContent from './LeaseTransactionInfoContent';

interface Props
  extends RouteComponentProps<{
    id?: string;
    tabName?: string;
    transactionId: string;
  }> {}

const LeaseTransactionInfo: FC<Props> = (props) => {
  const {
    match: { params },
  } = props;
  return <LeaseTransactionInfoContent params={params} shadow />;
};

export default connect((store: IStore) => ({}))(LeaseTransactionInfo);
