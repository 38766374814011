import React, { FC } from 'react';

import { InnerLink } from 'routing/InnerLink';
import { BriefcaseIcon } from 'components/icons/BriefcaseIcon';

import { getInitials } from 'utils/helper-functions';

/* types */
import { Pages } from 'routing';
import { PropertyRiverIcon } from 'components/icons/PropertyRiverIcon';

interface Props {
  page: Pages;
  itemName: Pages;
  activeTab?: string;
  tag: string | undefined;
  id: number | string;
  itemTitle?: string | string[];
  itemDescription: string;
  initials?: string;
  imageUrl?: string;
  checkSuffixByActive?: boolean;
}

const DataListItem: FC<Props> = (props) => {
  const {
    id,
    tag,
    activeTab,
    page,
    itemName,
    initials,
    itemTitle,
    itemDescription,
    imageUrl,
    checkSuffixByActive,
  } = props;
  const useInitials = page === 'users' || page === 'people';
  const useFullData = page === 'properties' || page === 'tag groups';
  const useBriefcaseIcon = page === 'enterprises';
  const usePropertyRiverIcon = page === 'properties';

  const parsedItemTitle =
    typeof itemTitle === 'string' ? itemTitle : itemTitle?.sort().join(' | ');

  return (
    <InnerLink
      className="data-item"
      activeClass="data-item_active"
      to={itemName}
      checkSuffixByActive={checkSuffixByActive}
      additionalSuffix={activeTab ? `/${activeTab}` : undefined}
      id={String(id)}>
      {page !== 'tag groups' && page !== 'taxonomy' ? (
        <div className="data-item__image">
          {imageUrl && (
            <img
              alt=""
              src={imageUrl}
              className={`data-item__image ${
                page === 'properties' ? ' no-borders' : ' borders'
              }`}
            />
          )}
          {useInitials && !imageUrl && (
            <div className="data-item__image name-initials">
              {initials || getInitials(itemDescription)}
            </div>
          )}
          {usePropertyRiverIcon && !imageUrl && (
            <div className={'data-item__image borders'}>
              <PropertyRiverIcon />
            </div>
          )}
          {useBriefcaseIcon && !imageUrl && (
            <div className={'data-item__image borders'}>
              <BriefcaseIcon />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      <div
        className={`data-item__item-summary ${
          page !== 'properties' ? 'two-items' : 'three-items'
        }`}>
        {useFullData ? (
          <>
            {page !== 'properties' && parsedItemTitle ? (
              <div className="type">{parsedItemTitle}</div>
            ) : (
              ''
            )}
            {tag ? <div className="tag"> {tag}</div> : ''}
            {page !== 'tag groups' && itemDescription ? (
              <div className="description">{itemDescription}</div>
            ) : (
              ''
            )}
            {page === 'properties' && parsedItemTitle ? (
              <div className="type">{parsedItemTitle}</div>
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            {parsedItemTitle && <div className="title">{parsedItemTitle}</div>}
            <div className="name">{itemDescription}</div>
          </>
        )}
      </div>
    </InnerLink>
  );
};

export default DataListItem;
