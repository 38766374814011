/* PROFESSIONALS */

type PersonsTab = 'info' | 'enterpriseInvolvement' | 'properties';

type PersonsTabs = {
  [key in PersonsTab]: {
    title: string;
    path: string;
  };
};

const personsTabs: PersonsTabs = {
  info: {
    title: 'info',
    path: 'info',
  },
  enterpriseInvolvement: {
    title: 'enterprise involvement',
    path: 'enterprise-involvement',
  },
  properties: {
    title: 'properties',
    path: 'properties',
  },
};

/* ENTERPRISES */

type EnterpriseTab = 'info' | 'people' | 'ownership' | 'properties';

type EnterprisesTabs = {
  [key in EnterpriseTab]: {
    title: string;
    path: string;
  };
};

const enterprisesTabs: EnterprisesTabs = {
  info: {
    title: 'info',
    path: 'info',
  },
  people: {
    title: 'people',
    path: 'people',
  },
  ownership: {
    title: 'ownership',
    path: 'ownership',
  },
  properties: {
    title: 'properties',
    path: 'properties',
  },
};

/* PROPERTIES */

type PropertiesTab =
  | 'property'
  | 'sales'
  | 'leases'
  | 'tenants'
  | 'supplements'
  | 'ownership';
type PropertiesTabs = {
  [key in PropertiesTab]: {
    title: string;
    path: string;
  };
};

const propertiesTabs: PropertiesTabs = {
  property: {
    title: 'property',
    path: 'property',
  },
  sales: {
    title: 'sales',
    path: 'sales',
  },
  leases: {
    title: 'leases',
    path: 'leases',
  },
  tenants: {
    title: 'tenants',
    path: 'tenants',
  },
  supplements: {
    title: 'supplements',
    path: 'supplements',
  },
  ownership: {
    title: 'ownership',
    path: 'ownership',
  },
};

export type PageTabsType = PersonsTabs | EnterprisesTabs | PropertiesTabs;
export { personsTabs, enterprisesTabs, propertiesTabs };
