import React, {
  FC,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { push, RouterState } from 'connected-react-router';
import { omit } from 'lodash';
import Scrollbars from 'react-custom-scrollbars';

/* components */
import SaleTransactionItem from './SaleTransactionItem';
import SaleTransactionModal from 'components/modals/properties/SaleTransactionModal';
import Loader from 'components/Loader';
import SelectSort from 'components/UI/controls/SelectSort';
import Button from 'components/UI/controls/Button';

/* controllers */
import {
  createSaleTransactionAction,
  getSaleTransactionsAction,
} from 'controllers/property/actions/saleTransactions';

/* constants */
import { ITEMS_LOADER_HEIGHT, LOADED_ITEMS_LIMIT } from 'config';
import { PageEnum } from 'config/constants';
import { propertiesTabs } from 'config/constants/tabs';
import { salesFilterOptions } from 'config/constants/select-options';
import { SaleTransactionStatusEnum, sortType } from "@ternala/voltore-types/lib/constants";

/* types */
import { IStore } from 'controllers/store';
import { IPropertyState } from 'controllers/property/models';
import { SaleTransactionGetListRequest } from '@ternala/voltore-types';
import { OptionType } from 'models';
import { getItemPrice } from '../../../../utils';
import { Helmet } from 'react-helmet';
import {
  callbackTypeEnum,
  requestCallback,
} from '../../../../utils/requestCallback';
import DataListItem from "../../../../components/page/DataList/DataListItem";

interface Props {
  propertyId: number;
  tabName: string;
  routerState: RouterState;
  propertyState: IPropertyState;
  children?: ReactNode;
}

const TransactionSalesTab: FC<Props> = (props) => {
  const {
    propertyId,
    tabName,
    routerState: {
      location: { pathname },
    },
    propertyState: { propertyData, saleTransactionData },
    children,
  } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [transactionsFilter, setTransactionsFilter] = useState<OptionType>(
    salesFilterOptions[0],
  );

  const transactionsRef = useRef() as RefObject<Scrollbars>;

  const currentProperty = propertyData[propertyId];
  const transactionIds = currentProperty?.saleTransactionState?.items;
  const newTransactionIds = currentProperty?.saleTransactionState?.newItems;
  const transactions =
    transactionIds &&
    Object.values(transactionIds.map((id) => saleTransactionData[id]));

  const newTransactions =
    newTransactionIds &&
    Object.values(newTransactionIds.map((id) => saleTransactionData[id]));

  useEffect(() => {
    dispatch(
      push(
        `/${PageEnum.PROPERTIES}/${propertyId}/${propertiesTabs.sales.path}`,
      ),
    );
    setLoading(true);
    loadTransactions(() => setLoading(false));
  }, [transactionsFilter]);

  const loadTransactions = (callback?: Function) => {
    const searchParams: SaleTransactionGetListRequest = {
      property: propertyId,
      limit: LOADED_ITEMS_LIMIT,
      offset: currentProperty?.saleTransactionState?.items?.length || 0,
      sortType: sortType.desc
    };

    if (
      transactionsFilter.value &&
      typeof transactionsFilter.value === 'string'
    ) {
      searchParams.status =
        transactionsFilter.value as SaleTransactionStatusEnum;
    }

    if (
      JSON.stringify(
        omit(currentProperty?.saleTransactionState?.storedSearchParams, [
          'limit',
          'offset',
        ]),
      ) !== JSON.stringify(omit(searchParams, ['limit', 'offset']))
    ) {
      searchParams.offset = 0;
    }

    dispatch(getSaleTransactionsAction.request({ ...searchParams, callback }));
  };

  const loadMoreTransactions = () => {
    const { getClientHeight, getScrollHeight, getScrollTop, scrollToBottom } =
      transactionsRef.current as any;

    if (
      !currentProperty?.saleTransactionState?.isAll &&
      !loadingMore &&
      getClientHeight() + getScrollTop() >=
        getScrollHeight() - ITEMS_LOADER_HEIGHT
    ) {
      scrollToBottom();
      setLoadingMore(true);
      loadTransactions(() => setLoadingMore(false));
    }
  };

  return (
    <>
      <div className="item-info__tab properties__sales-tab sales-tab items-container">
        <Helmet>
          <title>
            Sales | Voltore{' '}
            {process.env.REACT_APP_COMPANY_NAME
              ? `| ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        <div
          className={
            'sales-tab__transactions transactions' +
            (!pathname.endsWith(propertiesTabs.sales.path)
              ? ' sales-tab__transactions_hidden'
              : '')
          }>
          <header className="transactions__top-controls">
            <SelectSort
              value={transactionsFilter}
              onChange={(option) => setTransactionsFilter(option)}
              options={salesFilterOptions}
            />

            <Button
              className="button-add-new"
              variant="light"
              onClick={() => setIsModalOpen(true)}>
              Add new
            </Button>
          </header>

          {loading ? (
            <div className="sale-transactions-container__loader">
              <Loader />
            </div>
          ) : (
            <Scrollbars
              autoHide
              ref={transactionsRef}
              onScroll={loadMoreTransactions}
              id="enterprise-involvements-scrollbars">
              <div className="transaction-items items-list">
                {newTransactions?.length ? (
                  <div className="new-items">
                    {newTransactions.map((transaction) => (
                      <SaleTransactionItem
                        key={transaction.id}
                        id={propertyId}
                        tabName={tabName}
                        transactionId={transaction.id}
                        data={{
                          buyer: transaction.buyer?.title,
                          sqft: currentProperty.sqft,
                          price: getItemPrice(transaction),
                          statuses: transaction.historyStatuses,
                          activeStatus: transaction.activeStatus,
                        }}
                      />
                    ))}
                    <div className="new-items-dash">
                      <span>New</span>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {transactions?.map((transaction) => (
                  <SaleTransactionItem
                    key={transaction.id}
                    id={propertyId}
                    tabName={tabName}
                    transactionId={transaction.id}
                    data={{
                      buyer: transaction.buyer?.title,
                      sqft: currentProperty.sqft,
                      price: getItemPrice(transaction),
                      statuses: transaction.historyStatuses,
                      activeStatus: transaction.activeStatus,
                    }}
                  />
                ))}

                {loadingMore ? (
                  <div className="items-list__loader">
                    <Loader />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Scrollbars>
          )}
        </div>

        {pathname.endsWith(propertiesTabs.sales.title) && (
          <div className="empty-container" />
        )}

        {children}
      </div>

      {isModalOpen && (
        <SaleTransactionModal
          property={{ id: propertyId }}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(payload) =>
            dispatch(
              createSaleTransactionAction.request({
                ...payload,
                callback: (status: boolean, err?: string) => {
                  requestCallback(dispatch, status, callbackTypeEnum.create)
                  if (payload.callback) payload.callback(status);
                },
              }),
            )
          }
        />
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  routerState: store.router,
  propertyState: store.property,
}))(TransactionSalesTab);
