/* eslint-disable */
import { all, put, takeEvery, select, call } from "redux-saga/effects";

// Actions
import {
  getCardsAction, toggleReminderAction
} from "../actions";

import { addError, addLoader, removeLoader } from "../index";

// Utils
import uuid from "utils/uuid";

// Constants
import { LoaderAction } from "config/constants";
import {ReminderApi} from "../transport/reminder.api";
import {IStore} from "../../store";
import { getAccessTokenUtil } from "../../../utils/getAccessToken";
import { ReminderDTO, ReminderToggleResponse } from "@ternala/voltore-types";
import { IError } from "../../model";
import { getAccessTokenSaga } from "../../auth/sagas/auth";

export function* toggleReminderSaga({ payload }: ReturnType<typeof toggleReminderAction.request>) {
  const accessToken:string | undefined = yield call(getAccessTokenSaga);

  const currentReminder: ReminderDTO | undefined = yield select((store: IStore) => {
    return Object.values(store.card.cardData).find((card) => {
      const foundReminder = card?.reminders?.find(reminder => reminder.id === payload.id);
      if(foundReminder){
        return foundReminder;
      }
    })
  })

  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: "Please wait, remind is executing",
      type: LoaderAction.reminder.toggle
    })
  )
  try {
    if(!accessToken) throw new Error("Not authorized");
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: ReminderToggleResponse | string | IError = yield ReminderApi.toggleReminder(data, accessToken);

    if(typeof res === "undefined" || typeof res === "string" || 'message' in res){
      if(typeof res === "string"){
        getCardsAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getCardsAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: "Failed to execute reminder!",
          type: LoaderAction.reminder.toggle
        })
      )
    } else {
      yield put(toggleReminderAction.success(res));
      yield put(
        removeLoader({
          id: loadId
        })
      );
      if (typeof payload.callback === 'function') payload.callback(res?.isComplete ?? currentReminder?.isComplete);
    }
  } catch (error: any) {
    console.error('error: ', error);
    yield put(toggleReminderAction.failure({
      code: error.code || 400,
      message: error.message || error ||  "Something was wrong"
    }));
    if (typeof payload.callback === 'function') payload.callback(currentReminder?.isComplete);
    yield put(
      addError({
        id: loadId,
        message: "Failed to execute reminder!",
        type: LoaderAction.reminder.toggle
      })
    )
  }
}


export function* reminderActionSaga() {
  yield all([
    takeEvery(toggleReminderAction.request, toggleReminderSaga)
  ]);
}
