import React, { FC, ReactNode } from 'react'

import DatePicker from 'components/UI/controls/DatePicker'

import { capitalize } from 'utils/helper-functions'
import style from './SelectListingStatus.module.scss'

/* types */
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { SaleTransactionStatusHistoryDTO } from '@ternala/voltore-types'

interface Props {
   item: SaleTransactionStatusHistoryDTO
   removeItem: (id: number) => void
   onChange: (date: MaterialUiPickersDate, value: string | null | undefined, id: number) => void
   onError: (error: ReactNode, value: any) => void
}

const ListingStatusItem: FC<Props> = ({ item, removeItem, onChange, onError }) => {
   return (
      <div className={style.listing_status}>
         <div className={style.status_type}>
            <div className={style.status_type_label}>{ capitalize(item.status) }</div>

            <div
               className={style.remove_selection_icon}
               onClick={() => removeItem(item.id)}
               title="Remove"
            />
         </div>

         <div>
            <DatePicker
               className="listing-status-date"
               containerClass="listing-status__date-picker-container"
               value={item.startDate}
               onChange={(date: MaterialUiPickersDate, value) => {
                  onChange(date, value, item.id)
               }}
               onError={onError}
               iconStyles={{ bottom: -8 }}
            />
         </div>
      </div>
   )
}

export default ListingStatusItem