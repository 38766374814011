import React, { FC, useState, useEffect } from 'react';

/* UI components */
import Modal from 'components/Hocs/Portal';
import Button from 'components/UI/controls/Button';

/* types */
import { getAccessToken } from 'controllers/auth';
import { OptionType } from 'models';
import SelectSearch from 'components/UI/controls/SelectSearchCustom';
import {
  loadTagsByGroupOptions, loadTagsOptions,
  loadTaxonomiesByTypeOptions, loadUserRoles
} from "config/constants/select-options";
import { TaxonomyTypeSlug } from '@ternala/voltore-types/lib/constants';
import Radio from 'components/UI/controls/Radio';
import { TaxonomyCreateResponse } from "@ternala/voltore-types";
import { getAccessTokenUtil } from "../../utils/getAccessToken";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";

interface IProps {
  onClose: () => void;
  onSubmit: (
    entityId: number,
    newEntityId?: number,
    callback?: Function,
  ) => void;
  setCreateEntity: ((
    callback?: (res: false | TaxonomyCreateResponse) => void
  ) => void) | Function;
  entityId: number;
  type?: TaxonomyTypeSlug;
  groupId?: number;
  entityType: 'taxonomy' | 'tag' | 'role';
}

const PopupProceed: FC<IProps> = ({
  onClose,
  onSubmit,
  setCreateEntity,
  entityId,
  type,
  groupId,
  entityType,
}) => {
  const dispatch = useDispatch();

  const [showReplace, setShowReplace] = useState<boolean>(true);
  const [requestProcess, setRequestProcess] = useState<boolean>(false);
  const [specialEntity, setSpecialEntity] = useState<OptionType | undefined>(
    undefined,
  );

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onClose();
  };

  const submitData = () => {
    setRequestProcess(true);
    onSubmit(
      entityId,
      entityType === 'taxonomy'
        ? showReplace
          ? Number(specialEntity?.value)
          : undefined
        : Number(specialEntity?.value),
      (status: boolean) => {
        onClose();
        setRequestProcess(false);
      },
    );
  };

  return (
    <Modal>
      <div className="modal modal_centered">
        <div className="scroll-area">
          <form className="modal-content" onSubmit={submitData}>
            <div className="modal__close" onClick={onClose} title="Close" />

            <div className="modal-title-delete">are you sure?</div>
            <div className="modal-subtitle-delete">
              <p>
                This entity will be deleted. All of the connections between it
                and other entities with be removed as well.
              </p>
              <p>
                Still, you can select any other entity from the list below with
                which all of the connections that deleted entity had will be
                recreated.
              </p>
            </div>
            <div
              className="radio-options"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}>
              <Radio
                label={'Delete links'}
                value={'Delete links'}
                checked={!showReplace}
                onChange={() => setShowReplace(false)}
              />
              <Radio
                label={'Recreate links with another entity'}
                value={'Recreate links with another entity'}
                checked={showReplace}
                onChange={() => setShowReplace(true)}
              />
            </div>
            {showReplace ? (
              <div
                className="items-container"
                style={{
                  flexDirection: 'column',
                  marginBottom: '25px',
                  marginTop: '10px',
                }}>
                <SelectSearch
                  label="replace to/create new"
                  placeholder="Select or create new"
                  value={specialEntity}
                  asyncOptions={async (searchParams) => {
                    switch (entityType) {
                      case 'tag': {
                          const token = await getAccessTokenUtil(dispatch);
                          if(token){
                            loadTagsByGroupOptions(
                              entityId,
                              Number(groupId),
                              searchParams, token)
                          }
                          return []
                        }
                      case 'taxonomy':{
                        if (!type) return [];

                        const token = await getAccessTokenUtil(dispatch);
                        if(token){
                          loadTaxonomiesByTypeOptions(
                            type,
                            entityId,
                            searchParams, token)
                        }
                        return []
                      }
                      case 'role': {
                        const token = await getAccessTokenUtil(dispatch);
                        if (token) {
                          loadUserRoles(entityId, searchParams, token);
                        }
                        return [];
                      }
                      default:
                        return [];
                    }
                  }}
                  onChange={(entity) => {
                    setSpecialEntity(entity);
                  }}
                  creation={{
                    label: `Create new ${entityType}`,
                    onClick: () => {
                      setCreateEntity((item: false | TaxonomyCreateResponse) => {
                        if(!item) return;
                        setSpecialEntity({
                          value: item.id,
                          label: item.title,
                        })
                      });
                    },
                  }}
                />
              </div>
            ) : (
              ''
            )}

            <footer className="modal-footer">
              <Button variant="light" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="dark"
                onClick={submitData}
                disabled={requestProcess}>
                Proceed
              </Button>
            </footer>
          </form>
        </div>

        <div className="modal-overlay" onClick={onClose} />
      </div>
    </Modal>
  );
};

export default PopupProceed
