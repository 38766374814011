import { appName } from 'config';
import { createAction, createAsyncAction } from "typesafe-actions";

import { widgetName } from './widjet';
import { IException } from 'controllers/store';

import {
  PropertyCreateRequestExpanded,
  PropertyUpdateRequestExpanded,
} from '../models';

import {
  PropertyCreateResponse,
  PropertyDeleteRequest,
  PropertyDeleteResponse,
  PropertyGetListRequest,
  PropertyGetListResponse,
  PropertyGetRequest,
  PropertyGetResponse,
  PropertyUpdateResponse,
  EnterpriseFullWithTreeDTO, PropertyMapGetListResponse, PropertyMapGetListRequest, CardEntityConnectDTO
} from "@ternala/voltore-types";

// ** Action
export const getPropertiesAction = createAsyncAction(
  `${appName}/${widgetName}/GET_PROPERTIES_REQUEST`,
  `${appName}/${widgetName}/GET_PROPERTIES_SUCCESS`,
  `${appName}/${widgetName}/GET_PROPERTIES_FILED`,
)<
  PropertyGetListRequest & { callback?: Function },
  {
    response: PropertyGetListResponse;
    searchParams: PropertyGetListRequest;
    isAll: boolean;
  },
  IException
>();

export const getMapItemsAction = createAsyncAction(
  `${appName}/${widgetName}/GET_MAP_ITEMS_REQUEST`,
  `${appName}/${widgetName}/GET_MAP_ITEMS_SUCCESS`,
  `${appName}/${widgetName}/GET_MAP_ITEMS_FILED`,
)<
  PropertyMapGetListRequest & { callback?: Function },
  {
    response: PropertyMapGetListResponse;
    searchParams: PropertyMapGetListRequest;
    isAll: boolean;
  },
  IException
  >();

export const createPropertyAction = createAsyncAction(
  `${appName}/${widgetName}/CREATE_PROPERTY_REQUEST`,
  `${appName}/${widgetName}/CREATE_PROPERTY_SUCCESS`,
  `${appName}/${widgetName}/CREATE_PROPERTY_FILED`,
)<
  PropertyCreateRequestExpanded & { callback?: Function },
  PropertyCreateResponse,
  IException
>();

export const getPropertyAction = createAsyncAction(
  `${appName}/${widgetName}/GET_PROPERTY_REQUEST`,
  `${appName}/${widgetName}/GET_PROPERTY_SUCCESS`,
  `${appName}/${widgetName}/GET_PROPERTY_FILED`,
)<
  PropertyGetRequest & { callback?: Function },
  PropertyGetResponse,
  IException
>();

export const updatePropertyAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_PROPERTY_REQUEST`,
  `${appName}/${widgetName}/UPDATE_PROPERTY_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_PROPERTY_FILED`,
)<PropertyUpdateRequestExpanded & { callback?: Function }, PropertyUpdateResponse, IException>();

export const deletePropertyAction = createAsyncAction(
  `${appName}/${widgetName}/DELETE_PROPERTY_REQUEST`,
  `${appName}/${widgetName}/DELETE_PROPERTY_SUCCESS`,
  `${appName}/${widgetName}/DELETE_PROPERTY_FILED`,
)<
  PropertyDeleteRequest & { callback?: Function },
  PropertyDeleteResponse & { id?: number },
  IException
>();

export const getPropertyTreeAction = createAsyncAction(
  `${appName}/${widgetName}/GET_PROPERTY_TREE_REQUEST`,
  `${appName}/${widgetName}/GET_PROPERTY_TREE_SUCCESS`,
  `${appName}/${widgetName}/GET_PROPERTY_TREE_FILED`,
)<
  PropertyGetRequest & { callback?: Function },
  { id: number; parentsTree: EnterpriseFullWithTreeDTO[] },
  IException
>();

export const setPropertyTagsAction = createAction(`${widgetName}/SET_TAGS`)<{
  tags: CardEntityConnectDTO[];
  entityId: number;
}>();

export const setSearchParams = createAction(
  `${widgetName}/SET_SEARCH_PARAMS`,
)<PropertyGetListRequest | null>();