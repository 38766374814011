import { eventChannel } from "redux-saga";
import {ISocketEvent} from "./models";

export function subscribe(socket: SocketIOClient.Socket, events: ISocketEvent[]) {
  return eventChannel((emit) => {
    events.forEach((event) => {
      socket.on(event.eventName, (data: any) => {
        emit(event.action(data));
      });
    });
    return () => {};
  });
}
