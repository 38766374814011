import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
   width?: number
   height?: number
}

export const BurgerIcon: FC<Props> = ({ color, width, height, ...props }) => (
   <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M8.00098 0.491837C8.00098 0.763471 7.83046 0.983673 7.62012 0.983671L0.38388 0.983617C0.17354 0.983616 0.00302503 0.763411 0.00302504 0.491777C0.00302505 0.220143 0.17354 -5.88088e-05 0.38388 -5.72285e-05L7.62012 -2.86142e-06C7.83046 -1.2811e-06 8.00098 0.220203 8.00098 0.491837Z"
         fill={color}
         fillOpacity="0.5"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M8 6.45863C8 6.73027 7.82949 6.95047 7.61915 6.95047L0.382903 6.95041C0.172563 6.95041 0.00204847 6.73021 0.00204848 6.45857C0.00204848 6.18694 0.172563 5.96674 0.382903 5.96674L7.61915 5.96679C7.82949 5.9668 8 6.187 8 6.45863Z"
         fill={color}
         fillOpacity="0.5"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M7.99805 3.47524C7.99805 3.74687 7.82753 3.96707 7.61719 3.96707L0.38095 3.96702C0.17061 3.96701 9.53416e-05 3.74681 9.53508e-05 3.47518C9.536e-05 3.20354 0.17061 2.98334 0.38095 2.98334L7.61719 2.9834C7.82753 2.9834 7.99805 3.2036 7.99805 3.47524Z"
         fill={color}
         fillOpacity="0.5"
      />
   </svg>
)

BurgerIcon.defaultProps = {
   color: 'white',
   width: 8,
   height: 7
}