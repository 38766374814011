import React, { FC } from 'react';

import { PlusIcon } from 'components/icons/PlusIcon';
import { Color } from "../styles";
import style from '../styles/control.module.scss';

/* types */
import { ICreation } from 'models';

interface Props {
  getSuggestionItemProps?: Function;
  creation: ICreation;
  topIndent?: number;
}

export const Creation: FC<Props> = ({ creation, topIndent, getSuggestionItemProps  }) => (
  <div
    {...getSuggestionItemProps?.({}, {
      className: "",
      style: {},
    })}
    className={
      "creation " + style.creation + (creation.disabled ? ` ${style.creation_disabled}` : '')
    }
    style={{ top: topIndent }}
    onClick={creation.onClick}>
    <span className={style.creation_label}>{creation.label}</span>
    <PlusIcon
      className={style.creation_icon}
      color={Color.creationDefault}
      fill="white"
    />
  </div>
);
