import React, { FC } from 'react';

interface Props {
  style?: { [key: string]: number | string };
  className?: string;
  name?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?:
    | 'light'
    | 'dark'
    | 'toggle'
    | 'toggle-sm'
    | 'light-sm'
    | 'dark-sm'
    | 'toggle-light'
    | 'toggle-dark';
  disabled?: boolean;
  children?: string | JSX.Element[] | JSX.Element;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button: FC<Props> = ({ className, ...props }) => {
  const classList: string[] = [];

  switch (props.variant) {
    case 'light':
      classList.push('button-light');
      break;
    case 'dark':
      classList.push('button-dark');
      break;
    case 'toggle':
      classList.push('button-toggle');
      break;
    case 'toggle-sm':
      classList.push('button-toggle-sm');
      break;
    case 'light-sm':
      classList.push('button-sm-light');
      break;
    case 'dark-sm':
      classList.push('button-sm-dark');
      break;
    case 'toggle-light':
      classList.push('button-toggle-light');
      break;
    case 'toggle-dark':
      classList.push('button-toggle-dark');
      break;
    default:
      classList.push('button');
  }

  className && classList.push(className);

  return (
    <button
      className={classList.join(' ')}
      style={{ ...props.style }}
      type={props.type}
      name={props.name}
      disabled={props.disabled}
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}>
      <span>{props.children}</span>
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
};

export default Button;
