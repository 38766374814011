import React, {
  FC,
  useState,
  useEffect,
  useRef,
  RefObject,
  ReactNode,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { Helmet } from 'react-helmet';
import { sortType } from "@ternala/voltore-types/lib/constants";

/* components */
import InvolvementHistoryItem from './InvolvementHistoryItem';
import InvolvementModal from 'components/modals/InvolvementModal';
import Loader from 'components/Loader';
import Button from 'components/UI/controls/Button';

/* controllers */
import {
  createInvolvementAction, getInvolvementsAction,
} from 'controllers/involvement/actions';

/* constants */
import { LOADED_ITEMS_LIMIT, ITEMS_LOADER_HEIGHT } from 'config';
import { personsTabs } from 'config/constants/tabs';

/* types */
import { IStore } from 'controllers/store';
import { IInvolvementState } from 'controllers/involvement/models';
import { IPersonState } from 'controllers/person/models';

interface Props {
  personId: number;
  tabName: string;
  personsState: IPersonState;
  involvementState: IInvolvementState;
  children?: ReactNode;
  pathname: string;
}

const InvolvementHistory: FC<Props> = (props) => {
  const dispatch = useDispatch();

  const {
    personId,
    tabName,
    involvementState: { involvementData },
    personsState: { personData },
    children,
    pathname
  } = props;

  const [involvementsLoading, setInvolvementsLoading] =
    useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const involvementHistoryRef = useRef() as RefObject<Scrollbars>;

  const currentPerson = personData[personId];
  const involvements = currentPerson?.involvementState?.allInvolvements;

  useEffect(() => {
    if (!involvements) {
      setInvolvementsLoading(true);
      loadInvolvements(() => setInvolvementsLoading(false));
    }
  }, []);

  const loadInvolvements = (callback?: Function) => {
    const searchParams = {
      person: personId,
      limit: LOADED_ITEMS_LIMIT,
      // offset: involvements?.items.length || 0,
      sortType: sortType.desc,
      additionalFields: {
        person: personId,
      },
    };
    dispatch(getInvolvementsAction.request({ ...searchParams, callback }));
  };

  const loadMoreInvolvements = () => {
    const { getClientHeight, getScrollHeight, getScrollTop, scrollToBottom } =
      involvementHistoryRef.current as any;
    if (
      !involvements?.isAll &&
      !involvementsLoading &&
      getClientHeight() + getScrollTop() >=
        getScrollHeight() - ITEMS_LOADER_HEIGHT
    ) {
      setInvolvementsLoading(true);
      scrollToBottom();
      loadInvolvements(() => setInvolvementsLoading(false));
    }
  };

  return (
    <>
      <div className="item-info__tab items-container involvement-history-tab">
        <Helmet>
          <title>
            Involvement history | Voltore
            {process.env.REACT_APP_COMPANY_NAME
              ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        <div
          className={
            'involvement-history involvement-items' +
            (!pathname.endsWith(personsTabs.enterpriseInvolvement.path)
              ? ' involvement-history_hidden'
              : '')
          }>
          <Button
            className="button-add-new"
            variant="light"
            onClick={() => setIsModalOpen(true)}>
            Add new
          </Button>
          {involvementsLoading ? (
            <div className="involvement-items-container__loader">
              <Loader />
            </div>
          ) : involvements && involvements.items.length ? (
            <Scrollbars
              autoHide
              ref={involvementHistoryRef}
              onScroll={loadMoreInvolvements}
              id="involvement-history-container">
              <div className="involvement-history__items items-list">
                {involvements.items.map((id) => {
                  const involvement = involvementData[id];

                  return (
                    <InvolvementHistoryItem
                      involvement={involvement}
                      key={involvement.id}
                      id={personId}
                      tabName={tabName}
                      involvementId={involvement.id}
                      data={{
                        position: involvement.involvementType?.title,
                        startDate: involvement.startDate,
                        enterprise: involvement.enterprise,
                        endDate: involvement.endDate,
                      }}
                    />
                  );
                })}

                {!involvements?.isAll && involvements?.isAll !== undefined && (
                  <div className="items-list__loader">
                    <Loader />
                  </div>
                )}
              </div>
            </Scrollbars>
          ) : (
            <span className="no-items-specified" style={{ marginTop: '0px' }}>
              No enterprise involvements are specified.
            </span>
          )}
        </div>

        {pathname.endsWith(personsTabs.enterpriseInvolvement.path) && (
          <div className="empty-container" />
        )}

        {children}
      </div>

      {isModalOpen && (
        <InvolvementModal
          person={{ id: personId }}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(payload) => {
            dispatch(
              createInvolvementAction.request({
                ...payload,
                callback: payload.callback,
                additionalFields: {
                  person: payload.person,
                  enterprise: payload.enterprise,
                },
              }),
            );
          }}
        />
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  pathname: store.router.location.pathname,
  personsState: store.person,
  involvementState: store.involvement,
}))(InvolvementHistory);
