import React from 'react';

// Types
import {
  NotificationTypeEnum,
} from 'controllers/modals/models.d';
import { CloseIconModals } from 'components/icons/CloseIconModals';
import { useDispatch } from 'react-redux';
import { removeShowedElement } from "../../controllers/showElement/actions";

export const notificationKey = 'Notification';

export interface INotification {
  text: string;
  title: string;
  type: NotificationTypeEnum;
}

export interface IProps extends INotification{
  id: string;
}

const Notification = (props: IProps) => {
  const dispatch = useDispatch();
  return (
    <>
      <div
        className="notification"
        style={{
          backgroundColor:
            props.type === NotificationTypeEnum.success
              ? '#53A956'
              : '#CF4628',
        }}>
        <span className="notification-title">{props.title}</span>
        <div
          className="notification-cross-icon"
          onClick={() => {
            dispatch(removeShowedElement(props.id))
          }}>
          <CloseIconModals />
        </div>
      </div>
      <div className="indicator-progress" />
    </>
  );
};

export default Notification;
