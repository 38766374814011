import { PropertyGetListFilters, TagCategoryDTO, TagDTO } from "@ternala/voltore-types";
import { defaultState } from '../based';
import { TagEntityEnum } from "@ternala/voltore-types/lib/card";

export interface ITagState extends defaultState {
  tags: TagDTO[];
  groups: TagCategoryDTO[];
  newGroups?: TagCategoryDTO[];
  entities: { [key in TagEntityEnum]: { [id: number]: TagDTO[] } };
  storedSearchParams: PropertyGetListFilters | null;
}

export enum TagTypeEnum {
  person = 'person',
  property = 'property',
  enterprise = 'enterprise',
  saleTransaction = 'saleTransaction',
  leaseTransaction = 'leaseTransaction',
}
