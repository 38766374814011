import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const StackIcon: FC<Props> = ({ color, width = 13, height = 13, ...props }) => (
   <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
   <path
      d="M7.47708 0C7.68343 0.0736971 7.88978 0.103176 8.06665 0.206352C9.89434 1.17915 11.7073 2.18143 13.535 3.15423C13.7413 3.27215 13.9477 3.37533 14.1688 3.49324C14.2277 3.52272 14.2719 3.5522 14.3309 3.58168C15.2005 4.08282 15.23 4.92296 14.3751 5.45358C12.6064 6.57378 10.8229 7.66449 9.05419 8.76995C8.74467 8.96156 8.4204 9.15318 8.11087 9.35953C7.72765 9.6101 7.35916 9.6101 6.9612 9.35953C6.4011 8.99104 5.81152 8.6373 5.23669 8.28355C3.95436 7.50236 2.67203 6.72117 1.3897 5.92524C1.09491 5.74837 0.785382 5.5715 0.505333 5.35041C-0.18742 4.83453 -0.17268 4.06808 0.57903 3.6259C1.40444 3.1395 2.25932 2.69731 3.09947 2.24039C4.35232 1.57712 5.60517 0.884365 6.85802 0.221091C7.04963 0.117915 7.25599 0.0736971 7.47708 0ZM1.00647 4.53974C1.10965 4.61344 1.16861 4.67239 1.2423 4.71661C2.06771 5.23249 2.90786 5.74837 3.73326 6.26425C4.88294 6.98648 6.03261 7.69397 7.18229 8.4162C7.40338 8.5636 7.60973 8.5636 7.83082 8.4162C8.12561 8.22459 8.43514 8.01824 8.72993 7.84137C10.3807 6.80961 12.0168 5.79259 13.6676 4.76083C13.7708 4.70187 13.8592 4.62818 13.9624 4.53974C13.874 4.48078 13.8298 4.4513 13.7855 4.42182C13.1812 4.08282 12.5622 3.75855 11.9579 3.41954C10.5281 2.65309 9.09841 1.88664 7.68343 1.10546C7.52129 1.01702 7.38864 1.01702 7.22651 1.10546C5.23669 2.21091 3.23212 3.30163 1.22756 4.39234C1.15387 4.42182 1.09491 4.48078 1.00647 4.53974Z"
      fill={color}
      fillOpacity="0.6"
   />
   <path
      d="M0.0338432 9.86035C0.505504 10.0962 0.991905 10.2878 1.41935 10.5531C3.35021 11.7323 5.2516 12.9262 7.16772 14.12C7.41829 14.2674 7.6099 14.2674 7.86047 14.12C9.80608 12.8819 11.7517 11.6733 13.6973 10.4647C14.0805 10.2288 14.4932 10.0667 14.9059 9.87509C15.0386 10.391 14.8322 10.8479 14.3458 11.1574C13.432 11.7323 12.5034 12.3071 11.5748 12.8819C10.4841 13.5599 9.37863 14.2232 8.31739 14.9307C7.7573 15.2992 7.2709 15.2845 6.7108 14.9307C4.75046 13.6779 2.77537 12.4545 0.800293 11.2459C0.284413 10.9363 -0.0398539 10.5384 0.0338432 9.86035Z"
      fill={color}
      fillOpacity="0.6"
   />
   <path
      d="M14.8766 6.98633C15.0535 7.59064 14.7587 7.97387 14.3755 8.2097C13.3437 8.84349 12.312 9.49203 11.2802 10.1406C10.219 10.8038 9.17249 11.4524 8.11125 12.1156C7.71329 12.3662 7.33006 12.3662 6.9321 12.1156C5.3255 11.1281 3.73364 10.1406 2.14179 9.13828C1.59643 8.79928 1.06581 8.46027 0.535192 8.12126C0.137227 7.87069 -0.0249061 7.50221 0.0635304 6.98633C0.476234 7.1632 0.888938 7.29586 1.25742 7.51695C3.24724 8.72558 5.23706 9.96369 7.22689 11.2018C7.43324 11.3345 7.61011 11.3345 7.8312 11.2018C9.80628 9.96369 11.7666 8.74032 13.7417 7.51695C14.0807 7.3106 14.4787 7.17794 14.8766 6.98633Z"
      fill={color}
      fillOpacity="0.6"
   />
   </svg>
)

StackIcon.defaultProps = {
   color: "#1F2531",
   width: 15,
   height: 16
}