import React, { FC } from 'react';
import Moment from 'react-moment';
import { InnerLink } from 'routing/InnerLink';
import { connect } from 'react-redux';

/* constants & utils */
import { DATE_FORMAT } from 'config';
import { LeaseTransactionStatusEnum } from '@ternala/voltore-types/lib/constants';
import {
  withThousandsSeparators,
  formattedDate,
  transactionItemDateDiff,
} from 'utils/helper-functions';

/* types */
import { CreateLeaseStatusDTO } from '@ternala/voltore-types';
import { IStore } from 'controllers/store';
import { IPropertyState } from 'controllers/property/models';
import { DotIcon } from 'components/icons/DotIcon';
import { getListImage } from "../../../../utils/getImage";

interface Props {
  id: number;
  tabName?: string;
  transactionId: number;
  data: {
    tenant?: string;
    sqft?: number;
    price?: number;
    statuses?: CreateLeaseStatusDTO[];
    activeStatus?: CreateLeaseStatusDTO;
    commencement?: Date;
    expiration?: Date;
  };
  propertyState: IPropertyState;
}

const LeaseTransactionItem: FC<Props> = (props) => {
  const {
    id,
    tabName,
    transactionId,
    data: {
      tenant,
      sqft,
      price,
      statuses,
      activeStatus,
      commencement,
      expiration,
    },
    propertyState: { leaseTransactionData },
  } = props;

  const transaction = leaseTransactionData[transactionId];

  let prevStatus = statuses?.find(
    (item) => item.status === LeaseTransactionStatusEnum.Active,
  ) as { status: string; startDate: Date | string };
  let showDaysOnMarket: boolean = true;

  let currStatus = {
    status:
      activeStatus?.status === LeaseTransactionStatusEnum.Active
        ? ''
        : activeStatus?.status,
        startDate:
      activeStatus?.status === LeaseTransactionStatusEnum.Active
        ? 'Now'
        : activeStatus?.startDate,
  };

  if (activeStatus?.status === LeaseTransactionStatusEnum.Leased) {
    if (commencement) {
      showDaysOnMarket = false;
      prevStatus = {
        status: 'Commencement',
        startDate: commencement,
      };
    }

    if (expiration) {
      showDaysOnMarket = false;
      currStatus = {
        status: 'Exp. date',
        startDate: expiration,
      };
    }

    if (!commencement && expiration) {
      prevStatus = {
        status: 'n/a',
        startDate: '',
      };
    }

    if (commencement && !expiration) {
      currStatus = {
        status: 'n/a',
        startDate: '',
      };
    }
  }

  const propertyInnerPage =
    tabName === 'leases' ? 'property-lease' : 'property-tenant';

  const enterprise = tabName === 'tenants' ? transaction.tenant : transaction.owner;

  const enterpriseImage = getListImage(enterprise?.images);
  const enterpriseTitle = enterprise?.title;

  return (
    <InnerLink
      className="transaction-item-container"
      activeClass="transaction-item_active"
      to={propertyInnerPage}
      id={id}
      tabName={tabName}
      transactionId={transactionId}>
      <div className="transaction-item">
        {activeStatus && (
          <div
            className={`transaction-item__current-status transaction-current-status transaction-current-status_${activeStatus?.status.toLowerCase()}`}>
            {activeStatus?.status}
          </div>
        )}
        <div className="transaction-item__information">
          {enterpriseImage && (
            <div className="transaction-item__image">
              <img src={enterpriseImage} alt="" />
            </div>
          )}
          <div className="transaction-item__data">
            {tenant && (
              <div
                className={`transaction-item__enterprise-name ${
                  tabName === 'tenants' &&
                  enterpriseImage &&
                  `with-image`
                }`}
                title={enterpriseTitle}>
                {enterpriseTitle}
              </div>
            )}

            <div
              className={`transaction-item__sqft-and-price_${activeStatus?.status.toLowerCase()}`}>
              {sqft && (
                <span className="lot-sqft">
                  {withThousandsSeparators(sqft)} SQFT
                </span>
              )}
              <DotIcon />
              <span
                className="lot-price"
                title={price ? `$${price.toFixed(2)}` : undefined}>
                {price ? `$${price.toFixed(2)}` : 'n/a'}
              </span>
            </div>
          </div>
        </div>

        {statuses && (
          <div className="transaction-item__status-and-date">
            {prevStatus ? (
              <>
                {prevStatus.status}
                {prevStatus.startDate !== '' && (
                  <>
                    :&nbsp;
                    <Moment format={DATE_FORMAT}>{prevStatus.startDate}</Moment>
                  </>
                )}
                &nbsp;-&nbsp;
              </>
            ) : (
              ''
            )}

            {
              <>
                {currStatus.status}
                {currStatus.startDate === 'Now'
                  ? 'Now'
                  : currStatus.startDate !== '' && (
                      <>
                        :&nbsp;
                        <Moment format={DATE_FORMAT}>
                          {currStatus.startDate}
                        </Moment>
                      </>
                    )}
              </>
            }

            {prevStatus && showDaysOnMarket && (
              <>
                &nbsp;|&nbsp;
                {transactionItemDateDiff(
                  formattedDate(
                    prevStatus.startDate
                      ? new Date(prevStatus.startDate)
                      : new Date(),
                  ),
                  formattedDate(
                    currStatus.startDate && currStatus.startDate !== 'Now'
                      ? new Date(currStatus.startDate)
                      : new Date(),
                  ),
                )}
                &nbsp;days
              </>
            )}
          </div>
        )}
      </div>
    </InnerLink>
  );
};

export default connect((store: IStore) => ({
  propertyState: store.property,
}))(LeaseTransactionItem);
