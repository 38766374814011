import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

// types
import { IStore } from 'controllers/store';
import {
  InvolvementWithPersonDTO,
  PropertyOwnedDTO,
} from '@ternala/voltore-types';
import { IPersonState } from 'controllers/person/models';
import { IEnterpriseState } from 'controllers/enterprise/models';

// additional
import { Helmet } from 'react-helmet';
import Link from 'components/UI/controls/Link';
import PropertyField from './Controls/PropertyField';
import CustomScrollbars from 'components/CustomScrollbars';
import SaleTransactionField from './Controls/SaleTransactionField';
import { getPersonOwnedPropertiesAction } from 'controllers/person/actions';
import { getEnterpriseOwnedPropertiesAction } from 'controllers/enterprise/actions';
import { NoteEntityEnum } from 'components/Windows/model.d';
import { PageEnum } from 'config/constants';
import { personsTabs } from 'config/constants/tabs';
import Loader from 'components/Loader';
import LocationContext from 'context/Location';
import { entityTypeEnum } from "@ternala/voltore-types/lib/constants";
import { EntityEnum } from "@ternala/voltore-types/lib/constants/main";
import { getPersonName } from "../../../utils/getName";

interface IProps {
  id: number;
  children?: ReactNode;
  personsState: IPersonState;
  type: EntityEnum.enterprise | EntityEnum.person;
  enterpriseState: IEnterpriseState;
}

const PropertiesTab = ({
  id,
  type,
  personsState: { personData },
  enterpriseState: { enterpriseData },
}: IProps) => {
  const dispatch = useDispatch();
  // const location = useContext(LocationContext);
  const entity = type === EntityEnum.enterprise ? enterpriseData[id] : personData[id];
  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    type === EntityEnum.enterprise
      ? dispatch(
          getEnterpriseOwnedPropertiesAction.request({
            id,
            callback: () => setLoader(false),
          }),
        )
      : dispatch(
          getPersonOwnedPropertiesAction.request({
            id,
            callback: () => setLoader(false),
          }),
        );
  }, []);

  const InvolvedItem = (array: InvolvementWithPersonDTO[]) => {
    return array
      .filter((item) => item !== undefined)
      .filter((item) => item.person !== undefined)
      ?.map((item) => (
        <Link
          id={item.person?.id}
          className="link"
          type={EntityEnum.person}
          fullPath={`/${PageEnum.PEOPLE}/${item.person?.id}/${personsTabs.info.path}`}
          newTabIcon>
          {getPersonName(item.person)}
        </Link>
      ));
  };

  return (
    <>
      <Helmet>
        <title>
          Properties | Voltore
          {process.env.REACT_APP_COMPANY_NAME
            ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
            : ''}
        </title>
        <meta name="description" content="Voltore application" />
      </Helmet>
      <CustomScrollbars id="owned-properties-container">
        <div
          className="item-info__tab owned-properties-tab"
          style={{ flexDirection: 'column' }}>
          <div className="owned-properties">
            {!loader ? (
              entity?.propertiesInvolved?.length ? (
                <table className="owned-properties-table">
                  <thead>
                    <tr>
                      <th>Properties</th>
                      <th>Principal(s)</th>
                      <th>Decision maker(s)</th>
                      <th>Sale transaction</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entity?.propertiesInvolved.map(
                      (property: PropertyOwnedDTO) => {
                        const decisionMakers: Array<InvolvementWithPersonDTO> =
                          [];
                        const owners: Array<InvolvementWithPersonDTO> = [];
                        property.saleTransaction?.buyer?.involvements?.filter(
                          (item) => {
                            if (item.decisionMaker) {
                              if (item.person?.id === id)
                                decisionMakers.unshift(item);
                              else decisionMakers.push(item);
                            }
                            if (item.owner) {
                              if (item.person?.id === id) owners.unshift(item);
                              else owners.push(item);
                            }
                          },
                        );
                        return (
                          <tr>
                            <td>
                              <PropertyField property={property} />
                            </td>

                            <td>
                              {owners.length &&
                              InvolvedItem(owners).length !== 0 ? (
                                InvolvedItem(owners)
                              ) : (
                                <div className="no-information">-</div>
                              )}
                            </td>

                            <td>
                              {decisionMakers.length &&
                              InvolvedItem(decisionMakers).length !== 0 ? (
                                InvolvedItem(decisionMakers)
                              ) : (
                                <div className="no-information">-</div>
                              )}
                            </td>

                            <td style={{ paddingRight: '0px' }}>
                              {property.saleTransaction ? (
                                <SaleTransactionField
                                  transaction={property.saleTransaction}
                                  data={{
                                    sqft: property.sqft,
                                    price:
                                      property.saleTransaction?.listPrice ||
                                      property.saleTransaction?.soldPrice,
                                    startDate:
                                      // @ts-ignore
                                      property.saleTransaction?.activeStatus?.startDate,
                                    status:
                                      // @ts-ignore
                                      property.saleTransaction?.activeStatus?.status,
                                    propertyId: property.id,
                                  }}
                                />
                              ) : (
                                <div className="no-information">-</div>
                              )}
                            </td>
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </table>
              ) : (
                <span className="no-items-specified" style={{ margin: '0px' }}>
                  No owned properties are specified.
                </span>
              )
            ) : (
              <div className="item-info__loader_properties">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </CustomScrollbars>
    </>
  );
};

export default connect((store: IStore) => ({
  enterpriseState: store.enterprise,
  personsState: store.person,
}))(PropertiesTab);
