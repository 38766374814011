export interface IModalState {
  notifications: INotification[];
}

export interface INotification {
  id: string;
  text: string;
  title: string;
  type: NotificationTypeEnum;
}

export enum NotificationTypeEnum {
  error = 'error',
  success = 'success',
}
