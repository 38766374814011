
import { Component } from 'react'
import ReactDOM from 'react-dom'
import { CardFilterEnum } from "../../controllers/card/models";

interface Props {
   children: JSX.Element | JSX.Element[];
   modal: CardFilterEnum;
}


export default class ActionBlockPortal extends Component<Props> {
   container = document.createElement('div')
   portalRoot = document.getElementById('action-block-' + this.props.modal)
   componentDidMount() {
      this.container.className = 'note-creator__actions-list actions-list';
      this.portalRoot?.append(this.container)
   }

   componentWillUnmount() {
      this.portalRoot?.removeChild(this.container)
   }

   render() {
      return ReactDOM.createPortal(
         this.props.children,
         this.container
      )
   }
}
