import { all, put, takeEvery, select, call } from 'redux-saga/effects';
import {
  DeviceDTO,
  AuthRefreshRequestDTO,
  AuthLoginResponseDTO,
} from '@ternala/voltore-types';

// Exceptions
import { BadRequest } from 'utils/API/Exceptions';

//APIs
import { AuthAPI } from '../transport/auth.api';

// Actions
import { getAuthData, getRefreshToken } from '../index';
import {
  loginAction,
  setAuthStateAction,
  logoutAction,
  refreshTokenAction,
  loginByTokenAction,
  getAccessTokenAction,
} from '../actions';

// Utils
import { clearAccess, saveAccess } from '../../../utils/manageAccess';
import { getCredentials } from '../../../utils/deviceCredentials';
import { isJWTTokenExpired } from '../../../utils/API';

// Interfaces
import { IAuthData } from '../models';
import { IException, IStore } from '../../store';

export async function checkAccessTokenExpired(
  { accessToken, refreshToken, deviceCredentials }: AuthRefreshRequestDTO,
  store?: IStore,
): Promise<{ accessToken: string; refreshToken: string } | false> {
  const isExpired = isJWTTokenExpired(accessToken);
  if (isExpired) {
    const res = await AuthAPI.refreshToken(refreshToken, deviceCredentials);
    if (typeof res === 'string') {
      console.error('something was wrong: ', res);
      return false;
    } else {
      const authData = store?.auth.authData;
      if (authData) {
        authData.accessToken = res.accessToken;
        authData.refreshToken = res.refreshToken;
      }
      return res;
    }
  } else {
    return { accessToken, refreshToken };
  }
}

export function* loginSaga({
  payload,
}:
  | ReturnType<typeof loginAction.request>
  | ReturnType<typeof loginByTokenAction>) {
  yield put(
    setAuthStateAction({
      isLoading: true,
      message: 'Loading...',
    }),
  );

  const deviceCredentials: DeviceDTO = yield getCredentials();

  try {
    let loginData: AuthLoginResponseDTO;

    if ('accessToken' in payload) {
      const tokens: IAuthData | string = yield checkAccessTokenExpired({
        ...payload,
        deviceCredentials,
      });
      if (typeof tokens === 'string') throw new BadRequest();
      loginData = {
        account: yield AuthAPI.loginByToken(tokens.accessToken),
        ...tokens,
      };
    } else {
      loginData = yield AuthAPI.login({ ...payload, deviceCredentials });
    }

    if (loginData) {
      yield put(
        loginAction.success({
          ...loginData,
        }),
      );
      saveAccess({
        accessToken: loginData.accessToken,
        refreshToken: loginData.refreshToken,
      });
      yield put(
        setAuthStateAction({
          isLoading: false,
          message: 'Login success',
        }),
      );
    } else {
      throw new BadRequest();
    }
  } catch (error: any) {
    clearAccess();
    if (error.statusCode === 401) {
      yield put(
        setAuthStateAction({
          code: error.statusCode,
          isLoading: false,
          message: 'The email/password combination you entered is incorrect.',
          error: true,
        }),
      );
    } else {
      yield put(
        setAuthStateAction({
          isLoading: false,
          message: 'The email/password combination you entered is incorrect.',
          error: true,
        }),
      );
    }
  }
}

export function* refreshTokenSaga() {
  const refreshToken: string | undefined = yield select(getRefreshToken);
  const deviceCredentials: DeviceDTO = yield getCredentials();

  if (!refreshToken) {
    yield put(
      refreshTokenAction.failure({
        code: '403',
        message: 'Something was wrong',
        name: 'BadRefreshToken',
      }),
    );
    return;
  }

  try {
    const res: IAuthData | IException | string = yield AuthAPI.refreshToken(
      refreshToken,
      deviceCredentials,
    );

    if (typeof res === 'string' || !('accessToken' in res)) {
      if (typeof res === 'string') {
        yield put(
          refreshTokenAction.failure({
            code: '400',
            message: 'Something was wrong',
            name: 'BadRefreshToken',
          }),
        );
      } else {
        yield put(
          refreshTokenAction.failure({
            code: res.code || '400',
            message: res.message || 'Something was wrong',
            name: 'BadRefreshToken',
          }),
        );
      }
    } else {
      yield put(refreshTokenAction.success(res));
    }
  } catch (error: any) {
    yield put(
      refreshTokenAction.failure({
        code: error.code || 403,
        message: error.message || error || 'Something was wrong',
        name: 'BadRefreshToken',
      }),
    );
  }
}

export function* logoutSaga() {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const refreshToken: string | undefined = yield select(getRefreshToken);

  try {
    if (!refreshToken || !accessToken) throw new Error("Haven't refresh token");
    yield AuthAPI.logout(accessToken, refreshToken);
    yield put(logoutAction.success({}, {}));
  } catch (error) {
    console.error(error);
    yield put(logoutAction.failure({}, {}));
  }
}

export function* getAccessTokenSaga(
  action?: ReturnType<typeof getAccessTokenAction>,
) {
  const payload = action?.payload;
  try {
    const authData: IAuthData | undefined = yield select(getAuthData);

    if (!authData) {
      return payload?.callback({
        message: "Auth data wasn't find",
      });
    }
    const { accessToken, refreshToken } = authData;

    const isExpired = isJWTTokenExpired(accessToken);
    if (isExpired) {
      const deviceCredentials: DeviceDTO = yield getCredentials();

      const res: string | IAuthData = yield AuthAPI.refreshToken(
        refreshToken,
        deviceCredentials,
      );

      if (typeof res === 'string') {
        console.error('something was wrong: ', res);
        yield put(logoutAction.request({}, {}))
      } else {
        yield put(refreshTokenAction.success(authData));
        if (authData) {
          authData.accessToken = res.accessToken;
          authData.refreshToken = res.refreshToken;
        }
        payload?.callback(res.accessToken);
        return res.accessToken;
      }
    } else {
      payload?.callback?.(accessToken);
      return accessToken;
    }
  } catch (error: any) {
    console.error(error);
    payload?.callback({
      message: error,
    });
    return undefined;
  }
}

export function* authActionSaga() {
  yield all([
    takeEvery(loginAction.request, loginSaga),
    takeEvery(refreshTokenAction.request, refreshTokenSaga),
    takeEvery(loginByTokenAction, loginSaga),
    takeEvery(logoutAction.request, logoutSaga),
    takeEvery(getAccessTokenAction, getAccessTokenSaga),
  ]);
}
