import { ImageDTO, SaleTransactionFullExpandDTO } from '@ternala/voltore-types';
import { SaleTransactionStatusEnum } from '@ternala/voltore-types/lib/constants';

export * from './addressUtils';
export * from './appendSearchParams';
export * from './checkCommonItems';
export * from './compareArrays';
export * from './concatWithUnique';
export * from './cordova';
export * from './dataReturner';
export * from './deviceCredentials';
export * from './getAccessToken';
export * from './getFCMToken';
export * from './getImage';
export * from './getName';
export * from './googleResultToAddress';
export * from './helper-functions';
export * from './isArraysEaqual';
export * from './manageAccess';
export * from './nFormatter';
export * from './requestCallback';
export * from './sortCard';
export * from './usePrevious';
export * from './uuid';


export const getItemPrice = (
  transaction: SaleTransactionFullExpandDTO,
): number | undefined => {
  if (
    transaction.activeStatus?.status === SaleTransactionStatusEnum.Sold &&
    transaction.soldPrice
  ) {
    return transaction.soldPrice;
  }
  return transaction.listPrice;
};

export const calculateMainImageIndex = ({
  imageIndex,
  oldImages,
}: {
  imageIndex: number | undefined;
  oldImages?: ImageDTO[];
}): number => {
  let calculatedImageIndex = imageIndex;

  if (imageIndex === -1) {
    calculatedImageIndex = 0;
  }

  if (calculatedImageIndex === undefined) {
    if (oldImages?.length) {
      const mainImageIndex = oldImages.findIndex((image) => image.isMain);
      calculatedImageIndex = mainImageIndex === -1 ? 0 : mainImageIndex;
    } else {
      calculatedImageIndex = 0;
    }
  }

  return calculatedImageIndex;
};

export const getNumberFromString = (data: string) => {
  const arr = data.match(/[0-9]+/);
  if (arr) {
    return arr[0];
  }
  return null;
};
