import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const FiltersIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    {...props}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M13.959 0.790203C13.889 0.641638 13.7395 0.546875 13.5752 0.546875H0.423847C0.259611 0.546875 0.11014 0.641638 0.0400438 0.790203C-0.0299958 0.938769 -0.00804475 1.11438 0.0964489 1.2411L5.15203 7.37291V13.027C5.15203 13.1738 5.2279 13.3102 5.35259 13.3875C5.42093 13.4299 5.49849 13.4513 5.57634 13.4513C5.64067 13.4513 5.70522 13.4367 5.76473 13.4072L8.60524 11.9993C8.74956 11.9278 8.84088 11.7808 8.8411 11.6197L8.84665 7.37308L13.9025 1.24105C14.0071 1.11438 14.0291 0.938712 13.959 0.790203ZM8.09522 6.95058C8.0327 7.02639 7.99842 7.1216 7.99831 7.21993L7.99288 11.3557L6.00071 12.3431V7.22049C6.00071 7.122 5.96648 7.02656 5.9038 6.95058L1.32361 1.39544H12.6754L8.09522 6.95058Z"
        fill={color}
        fillOpacity="0.6"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

FiltersIcon.defaultProps = {
  color: '#1F2531',
};

export default FiltersIcon;
