/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component, createRef, RefObject } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { connect } from "react-redux";

import {
  PropertyMapGetExtremePointsResponse,
  PropertyMapGetListRequest
} from "@ternala/voltore-types";
import { PreferMapItemTypeEnum } from "@ternala/voltore-types/lib/constants";

import { CountyDTO } from "@ternala/voltore-types/lib/modules/county/county.dto";
import { ZipCodeDTO } from "@ternala/voltore-types/lib/modules/property/zipCode.dto";
import { PropertyDTO } from "@ternala/voltore-types/lib/modules/property/property.dto";
import {
  ICircle,
  IPoint,
  PropertyGetListFiltersByArea
} from "@ternala/voltore-types/lib/modules/property/filters/area";

/* config */
import { MAP_OPTIONS, FIGURE_OPTIONS } from "config/google-maps";

/* context */
import { GoogleMapsAPIContext } from "context/GoogleMapsAPI";

/* styles */
import stylesWithBusinessLabels from "./styles/with-business-labels";

/* map controls */
import {
  CircleDrawingControl,
  CloseFullScreenControl,
  DragMapControl,
  FullScreenControl,
  PolygonDrawingControl,
  ZoomControls
} from "./map-controls";
import { Marker } from "./markers";

/* types */
import { FilterType } from "models";
import { getMapItemsAction } from "../../../controllers/property/actions";
import { IStore } from "../../../controllers/store";
import {
  getBoundsPolygon,
  preparePropertyFilters,
  propertyToPropertyData
} from "../utils";
import { PropertyApi } from "../../../controllers/property/transport/property.api";
import { getAccessToken } from "../../../controllers/auth";
import { PropertyGetListFiltersExtended } from "../../../controllers/property/models";
import Loader from "../../../components/Loader";

interface IProps {
  className?: string;
  onMarkerClick: (propertyId: number) => void;
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  onFigureClear: (type: FilterType) => void;
  figure?: PropertyGetListFiltersByArea;
  searchQuery?: string;
  filters?: PropertyGetListFiltersExtended;

  isShow: boolean;
  mapItems?: {
    properties?: PropertyDTO[];
    counties?: CountyDTO[];
    zipCodes?: ZipCodeDTO[];
  };
  state: IStore;

  onFigureComplete: (circle: ICircle | IPoint[]) => void;
  getMapItemsAction: (
    data: PropertyMapGetListRequest & { callback?: Function }
  ) => void;
}

interface IState {
  isLoading: boolean;
  isInitialized: boolean;
}

let timeout: NodeJS.Timeout;

let markers: google.maps.Marker[] = [];
let cluster: MarkerClusterer;

export class MapWithSearch extends Component<Readonly<IProps>, IState> {
  constructor(props: IProps) {
    super(props);
    this.mapRef = createRef();
    this.state = {
      isLoading: false,
      isInitialized: false
    };
  }

  static contextType = GoogleMapsAPIContext;
  mapsAPI: typeof google.maps | undefined;
  map: google.maps.Map | undefined;
  drawingManager: google.maps.drawing.DrawingManager | undefined;
  mapRef: RefObject<HTMLDivElement>;
  bounds: IPoint[] = [];
  markers: google.maps.Marker[] = [];
  infoWindows: google.maps.InfoWindow[] = [];
  drawnOverlay: google.maps.Circle | google.maps.Polygon | null = null;

  initialize = () => {
    const { isInitialized } = this.state;
    const { isShow, state } = this.props;
    if(!isInitialized && isShow){
      getAccessToken(state).then((token) => {
        if (token) {
          const payload = preparePropertyFilters({});
          PropertyApi.getExtremePoints(payload, token).then((res) => {
            if (typeof res === "string") {
              console.error("We have trouble");
              return;
            }
            this.initializeMap(res);
          });
        }
      });
    }
  }

  componentDidMount() {
    this.initialize()
  }

  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: IState) {
    if(nextState.isLoading !== this.state.isLoading){
      return true;
    }
    if (nextProps.isShow) {
      return JSON.stringify(this.props.filters) !== JSON.stringify(nextProps.filters) ||
        this.props.isShow !== nextProps.isShow ||
        this.props.searchQuery !== nextProps.searchQuery;
    }
    return false;
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { isFullScreen, toggleFullScreen } = this.props;
    const { isInitialized } = this.state;
    if (
      this.mapsAPI &&
      !this.props.figure &&
      JSON.stringify(prevProps) !== JSON.stringify(this.props)
    ) {
      this.drawnOverlay?.setMap(null);
      this.drawingManager?.setDrawingMode(null);
      document.querySelector(".drawing-control__button-clear")?.remove();
    }

    if (
      this.mapsAPI &&
      this.map &&
      this.mapRef.current &&
      prevProps.isFullScreen !== this.props.isFullScreen
    ) {
      const fullScreenControl = new FullScreenControl(
        this.mapRef.current,
        isFullScreen,
        toggleFullScreen
      ).element;
      const zoomControls = new ZoomControls(
        this.mapRef.current,
        this.map,
        isFullScreen
      ).element;
      this.map.controls[this.mapsAPI.ControlPosition.RIGHT_BOTTOM].clear();
      this.map.controls[this.mapsAPI.ControlPosition.RIGHT_BOTTOM].push(
        fullScreenControl
      );
      this.map.controls[this.mapsAPI.ControlPosition.RIGHT_BOTTOM].push(
        zoomControls
      );
    }

    if (this.props.isShow) {
      this.initialize();
      if (
        isInitialized &&
        (JSON.stringify(this.props.filters) !==
        JSON.stringify(prevProps.filters) ||
        this.props.isShow !== prevProps.isShow ||
        this.props.searchQuery !== prevProps.searchQuery)
      ) {
        const newBounds = this.map?.getBounds();
        if (newBounds) {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            this.getData(getBoundsPolygon(newBounds));
          }, 200);
        }
      }
    }
  }

  getData(bounds: IPoint[]) {
    const { getMapItemsAction, filters, searchQuery } = this.props;
    const payload = preparePropertyFilters({
      filters,
      searchQuery,
      data: { bounds }
    });
    let preferType = PreferMapItemTypeEnum.county;

    const zoomLevel = this.map?.getZoom() || 15;

    if (zoomLevel >= 9) preferType = PreferMapItemTypeEnum.zipCode;
    if (zoomLevel > 13) preferType = PreferMapItemTypeEnum.property;

    getMapItemsAction({
      ...payload,
      preferType,
      callback: () => {
        this.renderMarkers();
        this.setState({
          isLoading: false,
          isInitialized: true
        });
      }
    });
  }

  initializeMap(data?: PropertyMapGetExtremePointsResponse) {
    const { GoogleMaps } = this.context;
    if (GoogleMaps && this.mapRef.current) {
      this.mapsAPI = GoogleMaps;
      this.map = new GoogleMaps.Map(this.mapRef.current, MAP_OPTIONS);

      this.applyMapStyles();
      this.initializeMapControls();
      this.renderMarkers();
    }

    if (data?.points) {
      //@ts-ignore
      const bounds = new google.maps.LatLngBounds();
      for (let point of data.points) {
        bounds.extend(new google.maps.LatLng(point.latitude, point.longitude));
      }
      this.map?.fitBounds(bounds);
      this.getData(data.points);
    }
  }

  applyMapStyles() {
    if (this.mapsAPI && this.map) {
      /* without business labels */
      // const styles = new this.mapsAPI.StyledMapType(
      //   stylesWithoutBusinessLabels,
      //   { name: "Styled Map" }
      // );
      /* with business labels */
      const stylesBL = new this.mapsAPI.StyledMapType(
        stylesWithBusinessLabels,
        { name: "Styled Map BL" }
      );
      this.map.mapTypes.set("styled_map_bl", stylesBL);
      this.map.setMapTypeId("styled_map_bl");
    }
  }

  initializeMapControls() {
    const {
      isFullScreen,
      toggleFullScreen,
      onFigureComplete,
      onFigureClear,
      figure
    } = this.props;

    const mapRef = this.mapRef.current;

    if (this.mapsAPI) {
      this.drawingManager = new this.mapsAPI.drawing.DrawingManager({
        drawingControl: false,
        circleOptions: FIGURE_OPTIONS,
        polygonOptions: FIGURE_OPTIONS,
        map: this.map
      });

      this.mapsAPI?.event.addListener(
        this.drawingManager,
        "overlaycomplete",
        (event: google.maps.drawing.OverlayCompleteEvent) => {
          this.drawnOverlay = event.overlay as
            | google.maps.Circle
            | google.maps.Polygon;
        }
      );

      this.map?.addListener("zoom_changed", () => {
        const newBounds = this.map?.getBounds();
        if (newBounds && this.state.isInitialized) {
          this.getData(getBoundsPolygon(newBounds));
        }
      });

      if (mapRef && this.map) {
        new DragMapControl(mapRef, this.map, (bounds) => {
          this.getData(bounds);
        });
        const circleDrawingControl = new CircleDrawingControl({
          mapNode: mapRef,
          map: this.map,
          drawingManager: this.drawingManager,
          onComplete: onFigureComplete,
          onClear: onFigureClear,
          circle: figure?.circle,
          isFullScreen
        }).element;
        const polygonDrawingControl = new PolygonDrawingControl({
          mapNode: mapRef,
          map: this.map,
          drawingManager: this.drawingManager,
          onComplete: onFigureComplete,
          onClear: onFigureClear,
          polygon: figure?.polygon,
          isFullScreen
        }).element;
        const fullScreenControl = new FullScreenControl(
          mapRef,
          isFullScreen,
          toggleFullScreen
        ).element;
        const closeFullScreenControl = new CloseFullScreenControl(
          mapRef,
          isFullScreen,
          toggleFullScreen
        ).element;
        const zoomControls = new ZoomControls(mapRef, this.map, isFullScreen)
          .element;
        //   TODO: implement street view control as soon as moved to the production mode
        // const streetViewControl = new StreetViewControl(mapRef, this.map, isFullScreen).element

        const TOP_CONTROLS = isFullScreen
          ? this.mapsAPI.ControlPosition.LEFT_TOP
          : this.mapsAPI.ControlPosition.RIGHT_TOP;
        const BOTTOM_CONTROLS = isFullScreen
          ? this.mapsAPI.ControlPosition.LEFT_BOTTOM
          : this.mapsAPI.ControlPosition.RIGHT_BOTTOM;

        closeFullScreenControl &&
        this.map.controls[this.mapsAPI.ControlPosition.RIGHT_TOP].push(
          closeFullScreenControl
        );
        // this.map.controls[this.mapsAPI.ControlPosition.TOP_CENTER].push(dragMapControl);
        this.map.controls[TOP_CONTROLS].push(circleDrawingControl);
        this.map.controls[TOP_CONTROLS].push(polygonDrawingControl);
        this.map.controls[BOTTOM_CONTROLS].push(fullScreenControl);
        this.map.controls[BOTTOM_CONTROLS].push(zoomControls);
        // this.map.controls[BOTTOM_CONTROLS].push(streetViewControl)
      }
    }
  }

  clearMapControls() {
    if (this.mapsAPI && this.map) {
      this.map.controls[this.mapsAPI.ControlPosition.TOP_CENTER].clear();
      this.map.controls[this.mapsAPI.ControlPosition.LEFT_TOP].clear();
      this.map.controls[this.mapsAPI.ControlPosition.LEFT_BOTTOM].clear();
      this.map.controls[this.mapsAPI.ControlPosition.RIGHT_TOP].clear();
      this.map.controls[this.mapsAPI.ControlPosition.RIGHT_BOTTOM].clear();
    }
  }

  renderMarkers() {
    const { onMarkerClick, mapItems } = this.props;
    cluster?.clearMarkers();

    this.markers.forEach((marker) => {
      marker.setMap(null);
      if (this.mapsAPI) {
        this.mapsAPI.event.clearListeners(marker, "click");
        this.mapsAPI.event.clearListeners(marker, "mouseover");
        this.mapsAPI.event.clearListeners(marker, "mouseout");
      }
    });

    this.markers = [];
    markers = [];

    // Render counties
    mapItems?.counties?.forEach((county) => {
      if (this.mapsAPI && this.map) {
        const marker = new Marker({
          map: this.map,
          coords: county.center,
          count: county.countProperties,
          defaultColor: "#0f0",
          iconType: "county",
          title: `${county.title} County`
        }).marker;

        marker.addListener("click", () => {
          const bounds = new google.maps.LatLngBounds();
          for (let point of county.points) {
            bounds.extend(
              new google.maps.LatLng(point.latitude, point.longitude)
            );
          }
          this.map?.fitBounds(bounds);
          const newBounds = this.map?.getBounds();
          if (newBounds) {
            this.getData(getBoundsPolygon(newBounds));
          }
        });

        this.markers.push(marker);
        markers.push(marker);
      }
    });

    // Render zipCodes
    mapItems?.zipCodes?.forEach((zipCode) => {
      if (this.mapsAPI && this.map) {
        const marker = new Marker({
          map: this.map,
          coords: zipCode.center,
          defaultColor: "#ff4212",
          iconType: "zipCode",
          count: zipCode.countProperties,
          title: `${zipCode.zipCode} zip code`
        }).marker;

        marker.addListener("click", () => {
          const bounds = new google.maps.LatLngBounds();
          for (let point of zipCode.points) {
            bounds.extend(
              new google.maps.LatLng(point.latitude, point.longitude)
            );
          }
          this.map?.fitBounds(bounds);
          this.renderMarkers();
        });

        this.markers.push(marker);
        markers.push(marker);
      }
    });

    // Render properties
    mapItems?.properties
      ?.map(propertyToPropertyData)
      ?.forEach((propertyData) => {
        if (this.mapsAPI && this.map) {
          const marker = new Marker({
            map: this.map,
            coords: propertyData.coords
          }).marker;

          const infoWindow = new this.mapsAPI.InfoWindow({
            content: this.generateInfoWindowMarkup(propertyData)
          });

          marker.addListener("mouseover", () => {
            infoWindow.open(this.map, marker);
          });

          marker.addListener("mouseout", () => {
            this.infoWindows.forEach((element) => element.close());
          });

          marker.addListener("click", () => {
            this.infoWindows.forEach((element) => element.close());
            infoWindow.open(this.map, marker);
            onMarkerClick(propertyData.id);
          });

          this.markers.push(marker);
          this.infoWindows.push(infoWindow);
          markers.push(marker);
        }
      });

    cluster = new MarkerClusterer({
      markers: this.markers,
      map: this.map
    });
  }

  generateInfoWindowMarkup(propertyData: IPropertyMapData): string {
    return `<div class="properties-info-window">
        ${
      propertyData.image
        ? `<div class="properties-info-window__image">
                 <img src="${propertyData.image}" alt="property image">
              </div>`
        : ""
    }
        <div class="properties-info-window__description">
           <div class="description__property-type">${
      propertyData.propertyTypes
    }</div>
           <div class="description__property-address">${
      propertyData.address
    }</div>
        </div>
     </div>`;
  }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        <div className={"loader-wrapper-map" + (isLoading ? " show" : "")}>
          <Loader />
        </div>
        <div
          ref={this.mapRef}
          className={this.props.className || ""}
          id="map-with-search">
          <div className="map__street-view-panorama" />
        </div>
      </>
    );
  }
}

export default connect(
  (state: IStore) => ({
    mapItems: state.property.mapItems,
    searchParams: state.property.searchParams,
    state: state
  }),
  {
    getMapItemsAction: getMapItemsAction.request
  }
)(MapWithSearch);
