import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
  width?: number;
  height?: number;
}

export const RollerOpenIcon: FC<Props> = ({
  width,
  height,
  color,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.82812 0V5.5H7.82813V1H3.32813V0H8.82812Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8.87695L-4.63615e-07 3.37695L0.999999 3.37695L1 7.87695L5.5 7.87695L5.5 8.87695L0 8.87695Z"
      fill={color}
    />
    <rect
      x="5.47457"
      y="3.70703"
      width="2.5"
      height="0.5"
      transform="rotate(135 5.47457 3.70703)"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
  </svg>
);

RollerOpenIcon.defaultProps = {
  width: 9,
  height: 9,
  color: '#1F2531',
};

export default RollerOpenIcon;
