import React, { Component, createRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';

/* constants & utils */
import { IActionElement } from '../../model.d';

// Controllers
import { getAccessToken } from 'controllers/auth';
import { SearchApi } from 'controllers/search/transport/search.api';
import { IStore } from 'controllers/store';

//Custom components
import ActionsListMentionItem from './List/ActionsListMentionItem';

import Loader from '../../../Loader';
import {
  InvolvementFullDTO,
  LeaseTransactionFullDTO,
  PersonShortDTO,
  PropertyShortDTO,
  SaleTransactionFullDTO,
  SearchGetListResponse,
  UserDTO,
  UserRoleDTO,
} from '@ternala/voltore-types';
import { entityToAction } from '@ternala/voltore-types/lib/card';
import { CardEntityEnum } from '@ternala/voltore-types/lib/constants';
import { generateMentionBlockName } from '../utils';
import { limitMention, mentionEntityOrder } from '../config';
import { IData } from './List/model';
import { UserWithPersonDTO } from '@ternala/voltore-types/lib/modules/user/userWithPerson.dto';
import { CountActionsContext } from "../../Contexts";
import { ISelectEntityData } from "../../../../controllers/showElement/models";

export interface IProps {
  type?: CardEntityEnum;
  allEntityTypes?: CardEntityEnum[];
  store: IStore;
  searchValue: string;
  onSelect: (action: IActionElement) => void;
  account?: UserWithPersonDTO;
  selectedEntity?: ISelectEntityData
}

interface IState {
  type?: CardEntityEnum;
  selectedAction?: IActionElement | null;
  isAll?: boolean;
  isLoading?: boolean;
  data: IData;
}

let delayTimer: NodeJS.Timeout;

export class ActionsListMention extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      type: props.type,
      selectedAction: null,
      isAll: false,
      isLoading: false,
      data: {
        property: {
          counts: 0,
          items: [],
        },
        leaseTransaction: {
          counts: 0,
          items: [],
        },
        saleTransaction: {
          counts: 0,
          items: [],
        },
        enterprise: {
          counts: 0,
          items: [],
        },
        person: {
          counts: 0,
          items: [],
        },
        involvement: {
          counts: 0,
          items: [],
        },
        role: {
          counts: 0,
          items: [],
        },
      },
    };
    document.addEventListener('keydown', this.handleButtonKeyDown);
    document.addEventListener('keyup', this.handleButtonKeyUp);
    document.addEventListener('keypress', this.handleButtonKeyPress);
  }

  componentDidMount() {
    this.generateActions(this.props.type);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleButtonKeyDown);
    document.removeEventListener('keyup', this.handleButtonKeyUp);
    document.removeEventListener('keypress', this.handleButtonKeyPress);
  }

  scrollToItem = () => {
    if (this.scrollbar.current && this.selectedItem.current) {
      const scrollHeight = this.scrollbar.current?.getClientHeight();
      const itemTop = this.selectedItem.current?.offsetTop;
      const itemHeight = this.selectedItem.current?.offsetHeight;

      const centerPosY = itemTop + itemHeight / 2 - scrollHeight / 2;
      this.scrollbar.current?.scrollTop(centerPosY);
    }
  };

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ) {
    const { selectedAction, type: stateType } = this.state;
    const { searchValue, type } = this.props;

    if (prevState?.selectedAction !== selectedAction) {
      this.scrollToItem();
    }

    if (stateType !== type || prevProps.searchValue !== searchValue) {
      this.setState({
        type,
      });
      this.scrollToItem();
      this.generateActions(type);
      this.scrollbar.current?.scrollTop(0);
    }
  }

  getActionsList = async (
    type?: CardEntityEnum,
  ): Promise<Partial<SearchGetListResponse>> => {
    const { store, searchValue, allEntityTypes, selectedEntity } = this.props;
    const accessToken = await getAccessToken(store);
    if (!accessToken) return {};
    let data;
    if(!type && !searchValue && selectedEntity) {
      data = await SearchApi.getSuggestedList(
        {
          limit: limitMention,
          offset: 0,
          id: selectedEntity.ids[0],
          entityType: selectedEntity.type
        },
        accessToken,
      );
    } else {
      data = await SearchApi.search(
        {
          query: searchValue,
          entities: type ? [type] : allEntityTypes ? allEntityTypes : [],
          limit: limitMention,
          offset: 0,
        },
        accessToken,
      );
    }
    // const data = await SearchApi.search(
    //   {
    //     query: searchValue,
    //     entities: type ? [type] : allEntityTypes ? allEntityTypes : [],
    //     limit: limitMention,
    //     offset: 0,
    //   },
    //   accessToken,
    // );
    if (typeof data === 'string') return {};

    return data;
  };

  generateActions = (type?: CardEntityEnum) => {
    const { account, type: singleType } = this.props;
    this.setState({
      isLoading: true,
    });
    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      new Promise<Partial<SearchGetListResponse>>((resolve) => {
        this.getActionsList(type).then((actions) => {
          resolve(actions);
        });
      }).then((data) => {
        const newData: IData = {};
        for (const type in data) {
          const useType = type as CardEntityEnum;
          const entity: any = data[useType];
          if (entity) {
            switch (useType) {
              case CardEntityEnum.Sale_Transaction:
              case CardEntityEnum.Lease_Transaction:
                {
                  entity.items.sort((transaction1: any, transaction2: any) => {
                    if (!transaction1.property && !transaction2.property)
                      return 0;
                    if (!transaction1.property) return 1;
                    if (!transaction2.property) return -1;

                    return (
                      transaction1.property?.id - transaction2.property?.id
                    );
                  });
                  let property: PropertyShortDTO | undefined;
                  for (let i = 0; i < entity.items.length; i++) {
                    const item = entity.items[i] as
                      | LeaseTransactionFullDTO
                      | SaleTransactionFullDTO;
                    if (property?.id !== item.property?.id) {
                      property = item.property;
                      if (property) {
                        entity.items.splice(i, 0, property);
                        i++;
                      }
                    }
                  }
                }
                break;
              case CardEntityEnum.Involvement:
                {
                  entity.items.sort((involvement1: any, involvement2: any) => {
                    if (!involvement1.person && !involvement2.person) return 0;
                    if (!involvement1.person) return 1;
                    if (!involvement2.person) return -1;

                    return involvement1.person?.id - involvement2.person?.id;
                  });
                  let person: PersonShortDTO | undefined;
                  for (let i = 0; i < entity.items.length; i++) {
                    const item = entity.items[i] as InvolvementFullDTO;
                    if (person?.id !== item.person?.id) {
                      person = item.person;
                      if (person) {
                        entity.items.splice(i, 0, person);
                        i++;
                      }
                    }
                  }
                }
                break;
              case CardEntityEnum.Role:
                {
                  let items: any[] = [];
                  if(singleType === CardEntityEnum.Role) {
                    items = [
                      ...(account?.roles.map((role) => ({
                        ...role,
                        user: account
                      })) || [])
                    ]
                  }
                  entity.items = [
                    ...items,
                    ...entity.items.filter(
                      (role: UserRoleDTO) =>
                        role &&
                        (singleType === CardEntityEnum.Role
                          ? !account?.roles.map(({ id }) => id).includes(role.id)
                          : true)
                    ),
                  ].sort((role1: any, role2: any) => {
                    if(account?.id === role1?.user?.id) return -1;
                    if(account?.id === role2?.user?.id) return 1;
                    if (!role1.user && !role2.user) return 0;
                    if (!role1.user) return 1;
                    if (!role2.user) return -1;

                    return role1.user?.id - role2.user?.id;
                  });
                  let user: UserDTO & { isDisabled?: boolean };

                  for (let i = 0; i < entity.items.length; i++) {
                    const item = entity.items[i] as UserRoleDTO;
                    if (item.user) {
                      // @ts-ignore
                      if (user?.id !== item.user.id) {
                        user = item.user;
                        user.isDisabled = true;
                        if (user) {
                          entity.items.splice(i, 0, user);
                          i++;
                        }
                      }
                    }
                  }
                }
                break;
            }
          }
          entity.items = entity.items.map((item: any) => {
            return ({
              ...entityToAction(item),
              isDisabled: item.isDisabled,
            })
          });
          newData[useType] = entity;
        }
        this.setState({
          data: newData,
          isLoading: false,
        });

        this.setState({
          selectedAction: Object.values(newData)
            .map((item) => {
              return item?.items;
            })
            .filter(Boolean)
            .flat(1)
            .filter((item: any) => !item.isDisabled)[0],
        });
        return;
      });
    }, 150);
  };

  private selectNext = () => {
    const { selectedAction, data } = this.state;
    const allActions = Object.entries(data)
      .sort(
        ([title1], [title2]) =>
          mentionEntityOrder.indexOf(title1 as CardEntityEnum) -
          mentionEntityOrder.indexOf(title2 as CardEntityEnum),
      )
      .map(([, item]) => item?.items)
      .filter(Boolean)
      .flat(1)
      .filter((item: any) => !item.isDisabled);
    const maxLength = allActions.length;
    const currentIndex = allActions.findIndex(
      (item) => item.uuid === selectedAction?.uuid,
    );
    const nextIndex =
      currentIndex === maxLength - 1 ? currentIndex : currentIndex + 1;
    this.setState({
      selectedAction: allActions[nextIndex],
    });
  };
  private selectPrev = () => {
    const { data, selectedAction } = this.state;
    const allActions = Object.entries(data)
      .sort(
        ([title1], [title2]) =>
          mentionEntityOrder.indexOf(title1 as CardEntityEnum) -
          mentionEntityOrder.indexOf(title2 as CardEntityEnum),
      )
      .map(([, item]) => item?.items)
      .filter(Boolean)
      .flat(1)
      .filter((item: any) => !item.isDisabled);
    const minLength = 0;
    const currentIndex = allActions.findIndex(
      (item) => item.uuid === selectedAction?.uuid,
    );
    const prevIndex = currentIndex <= minLength ? minLength : currentIndex - 1;
    this.setState({
      selectedAction: allActions[prevIndex],
    });
  };
  private handleButtonKeyPress = (e: KeyboardEvent) => {
    if (
      e.code === 'Enter' ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowRight' ||
      e.code === 'ArrowLeft'
    ) {
      e.preventDefault();
    }
  };
  private handleButtonKeyUp = (e: KeyboardEvent) => {
    if (
      e.code === 'Enter' ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowRight' ||
      e.code === 'ArrowLeft'
    ) {
      e.preventDefault();
    }
  };
  private handleButtonKeyDown = (e: KeyboardEvent) => {
    const { selectedAction } = this.state;
    if (
      e.code === 'Enter' ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowRight' ||
      e.code === 'ArrowLeft'
    ) {
      e.preventDefault();
    }
    if (e.code === 'ArrowUp') {
      this.selectPrev();
    }
    if (e.code === 'ArrowDown') {
      this.selectNext();
    }

    if (e.code === 'Enter') {
      if (selectedAction && selectedAction.data) {
        this.props.onSelect(selectedAction);
        return;
      }
      if (selectedAction && 'key' in selectedAction) {
        this.props.onSelect(selectedAction);
        return;
      }
    }
  };

  selectedItem = createRef<HTMLDivElement>();
  scrollbar = createRef<Scrollbars>();

  private renderList = () => {
    const { onSelect, searchValue, selectedEntity } = this.props;
    const { selectedAction, data, type } = this.state;
    return Object.entries(data)
      .sort(
        ([title1], [title2]) =>
          mentionEntityOrder.indexOf(title1 as CardEntityEnum) -
          mentionEntityOrder.indexOf(title2 as CardEntityEnum),
      )
      .map(([entity, data]) => {
        const entityType = entity as CardEntityEnum;
        let key: 'property' | 'user' | 'person';

        let item: PropertyShortDTO | PersonShortDTO | UserDTO | undefined;

        switch (entityType) {
          case CardEntityEnum.Lease_Transaction:
          case CardEntityEnum.Sale_Transaction:
            key = 'property';
            break;
          case CardEntityEnum.Involvement:
            key = 'person';
            break;
          case CardEntityEnum.Role:
            key = 'user';
            break;
        }

        const actions = data.items;
         return actions.length ? (
          <div className={'entity'} key={'entity' + entity}>
            {!type && (
              <div className="title">
                {!type && selectedEntity &&  !searchValue ? "Suggested " : ""}{generateMentionBlockName(entity as CardEntityEnum)}
              </div>
            )}
            <div className="items">
              {actions.map((action: IActionElement, index: number) => {
                const nextAction = actions[index + 1];
                const nextActionData = nextAction?.data;
                const data = action.data;
                let isEnd = false;
                let isStart = false;
                let hasParent = true;

                if (
                  data &&
                  !(key in data) &&
                  data.id === nextActionData?.[key]?.id
                ) {
                  // @ts-ignore
                  item = data;
                  isStart = true;
                }
                if (
                  !nextAction ||
                  !(nextAction?.data && key in nextAction?.data)
                ) {
                  item = undefined;
                  isEnd = true;
                }
                if (data && !(key in data) && !isStart) {
                  isStart = true;
                  hasParent = false;
                }

                return (
                  <ActionsListMentionItem
                    key={action.uuid}
                    index={index}
                    disabled={action.isDisabled}
                    isSelected={selectedAction?.uuid === action.uuid}
                    type={isStart && hasParent ? key : entityType}
                    action={action}
                    isStart={isStart}
                    isExpanded={!!item?.id}
                    isEnd={isEnd}
                    onClick={() => {
                      !action.isDisabled && onSelect(action);
                    }}
                    ref={
                      selectedAction?.uuid === action.uuid
                        ? this.selectedItem
                        : null
                    }
                  />
                );
              })}
            </div>
          </div>
        ) : (
          ''
        );
      });
  };

  render() {
    const { data, isLoading } = this.state;
    const { countActions, setCount } = this.context;
    let count = 0;
    const allActions = Object.values(data)
      .map((item) => {
        count += item.counts;
        return item?.items;
      })
      .filter(Boolean)
      .flat(1)
      .filter((item: any) => !item.isDisabled);
    let nextValue;
    if (!isLoading && this.props.searchValue) {
      nextValue = count;
    } else {
      nextValue = undefined;
    }

    if(nextValue !== countActions) setCount(nextValue);

    count -= limitMention;
    if (count < 0) count = 0;

    return (
      <Scrollbars
        autoHide
        renderThumbVertical={(props) => (
          <div {...props} className="page-scrollbar-y" />
        )}
        className="actions-list__scrollbars"
        ref={this.scrollbar}>
        {isLoading ? <Loader /> : ''}
        <main className="actions-list__content">
          {!allActions.length ? <div className={'placeholder'}>
              <h3>NOTHING FOUND</h3>
              <p>
                Sorry, but nothing matched your search terms. Please try again
                with some different keywords
              </p>
            </div> : ''}
          {this.renderList()}
          {count ? (
            <div className="info">
              {count} Items found, please add more keywords to reduce your
              search results
            </div>
          ) : (
            ''
          )}
        </main>
      </Scrollbars>
    );
  }
}

ActionsListMention.contextType = CountActionsContext;

export default connect((store: IStore) => ({
  store,
  account: store.auth.account,
}))(ActionsListMention);
