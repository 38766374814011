import React, { FC } from 'react';

interface Props {
  color?: {
    background: string;
    plus: string;
  };
}

export const TagAddButton: FC<Props> = ({ color, ...props }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginBottom: '-2px' }}>
    <circle cx="9" cy="9" r="9" fill={color?.background} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.96416 4.73633C9.18652 4.73633 9.36679 4.91659 9.36679 5.13896V12.789C9.36679 13.0113 9.18652 13.1916 8.96416 13.1916C8.74179 13.1916 8.56152 13.0113 8.56152 12.789V5.13896C8.56152 4.91659 8.74179 4.73633 8.96416 4.73633Z"
      fill={color?.plus}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1919 8.96318C13.1919 9.18555 13.0116 9.36581 12.7893 9.36581L5.13926 9.36581C4.9169 9.36581 4.73663 9.18555 4.73663 8.96318C4.73663 8.74081 4.9169 8.56055 5.13926 8.56055L12.7893 8.56055C13.0116 8.56055 13.1919 8.74081 13.1919 8.96318Z"
      fill={color?.plus}
    />
  </svg>
);

TagAddButton.defaultProps = {
  color: {
    background: '#1F3560',
    plus: '#FFFFFF',
  },
};

export default TagAddButton;
