import React, { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { TaxonomyTypeSlug } from '@ternala/voltore-types/lib/constants';
import {
  TaxonomyCreateRequest,
  TaxonomyCreateResponse,
  TaxonomyDTO,
  TaxonomyUpdateRequest,
  TaxonomyUpdateResponse,
} from '@ternala/voltore-types';

/* UI components */
import Modal from 'components/Hocs/Portal';
import Input from 'components/UI/controls/Input';
import Button from 'components/UI/controls/Button';
import { callbackTypeEnum, requestCallback } from 'utils/requestCallback';
import { removeShowedElement } from 'controllers/showElement/actions';
import {
  createTaxonomyAction,
  updateTaxonomyAction,
} from 'controllers/taxonomy/actions';
import { IStore } from 'controllers/store';
import { ITaxonomyState } from 'controllers/taxonomy/models';

export const taxonomyModalKey = 'TaxonomyModal';

export interface ITaxonomyModalProps {
  showElementId: string;
  id?: number;
  completeAction?: (taxonomy: TaxonomyDTO) => void;
  type: TaxonomyTypeSlug;

  // redux
  taxonomyState: ITaxonomyState;
}

const TaxonomyModal: FC<ITaxonomyModalProps> = ({
  id,
  completeAction,
  showElementId,
  type,
  taxonomyState,
}) => {
  const dispatch = useDispatch();
  const editableTaxonomy = taxonomyState.taxonomies?.[type]?.find(
    (taxonomy) => taxonomy.id === id,
  );
  const [taxonomyTitle, setTaxonomyTitle] = useState<string | undefined>(
    editableTaxonomy ? editableTaxonomy.title : undefined,
  );
  const [validationError, setValidationError] = useState<string>('');

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onCloseHandler();
  };

  const onCloseHandler = () => {
    dispatch(removeShowedElement(showElementId));
  };

  const submitData = () => {
    if (taxonomyTitle) {
      if (editableTaxonomy) {
        const payload: TaxonomyUpdateRequest = {
          id: editableTaxonomy.id,
          title: taxonomyTitle,
        };
        dispatch(
          updateTaxonomyAction.request({
            ...payload,
            callback: (res: false | TaxonomyUpdateResponse) => {
              requestCallback(dispatch, Boolean(res), callbackTypeEnum.update);
              if (res) {
                completeAction?.(res);
              }
              onCloseHandler();
            },
          }),
        );
      } else {
        const payload: TaxonomyCreateRequest = {
          title: taxonomyTitle,
          type,
        };
        dispatch(
          createTaxonomyAction.request({
            ...payload,
            callback: (res: false | TaxonomyCreateResponse) => {
              requestCallback(dispatch, Boolean(res), callbackTypeEnum.create);
              if (res) {
                completeAction?.(res);
              }
              onCloseHandler();
            },
          }),
        );
      }
    } else {
      setValidationError('Please fill this field');
    }
  };

  return (
    <Modal>
      <div className="modal modal_centered">
        <div className="scroll-area">
          <form className="modal-content" onSubmit={submitData}>
            <div
              className="modal__close"
              onClick={onCloseHandler}
              title="Close"
            />

            <div className="modal-title">
              {editableTaxonomy ? 'Edit' : 'Create'} taxonomy
            </div>
            <div
              className="items-container"
              style={{
                flexDirection: 'column',
                marginBottom: '25px',
              }}>
              <Input
                label="title"
                placeholder="Type here"
                value={taxonomyTitle}
                onChange={(e) => setTaxonomyTitle(e.target.value)}
                required
                error={validationError}
              />
            </div>

            <footer className="modal-footer">
              <Button variant="light" onClick={onCloseHandler}>
                Cancel
              </Button>
              <Button
                variant="dark"
                onClick={submitData}
                disabled={!taxonomyTitle}>
                Save
              </Button>
            </footer>
          </form>
        </div>

        <div className="modal-overlay" onClick={onCloseHandler} />
      </div>
    </Modal>
  );
};

export default connect((store: IStore) => ({
  taxonomyState: store.taxonomy,
}))(TaxonomyModal);
