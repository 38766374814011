import React, { FC, useState, useEffect, MouseEvent, FormEvent } from "react";
import { useDispatch } from "react-redux";

import { AddressShortDTO } from "@ternala/voltore-types/lib/modules/address/addressShort.dto";
import { states } from "@ternala/voltore-types/lib/constants";

import Modal from "components/Hocs/Portal";
import Button from "components/UI/controls/Button";

/* types */
import { removeShowedElement } from "controllers/showElement/actions";
import CustomScrollbars from "../../CustomScrollbars";
import Input from "../../UI/controls/Input";
import SelectSearch from "../../UI/controls/SelectSearchNew";
import { IError, OptionType } from "../../../models";
import { loadCountiesOptions } from "config/constants/select-options";
import { getAccessTokenUtil } from "utils/getAccessToken";
import { GoogleSearchAPI } from "../../../controllers/googleMaps/transport/googleMaps.api";

export const addressModalKey = "AddressModal";

export interface IAddressModalProps {
  address?: AddressShortDTO;
  showElementId: string;
  completeAction?: (address: AddressShortDTO) => void;
}

const AddressModal: FC<IAddressModalProps> = ({
                                                showElementId,
                                                completeAction,
                                                ...props
                                              }) => {
  const dispatch = useDispatch();
  const [addressEl, setAddress] = useState<Partial<AddressShortDTO>>(
    props.address || {}
  );
  const { address, city, state: defaultState, zipCode, suite, county: defaultCounty } = addressEl;

  const [county, setCounty] = useState<OptionType | undefined>(defaultCounty ? {
    value: defaultCounty,
    label: defaultCounty
  } : undefined);
  const [state, setState] = useState<OptionType | undefined>(defaultState ? {
    value: defaultState,
    label: defaultState
  } : undefined);
  const [isShowValidate, setIsShowValidate] = useState<boolean>(false);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.addEventListener("keydown", closeOnEscPress);

    return () => {
      document.body.removeAttribute("class");
      document.removeEventListener("keydown", closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      onCloseHandler();
    }
  };

  const onCloseHandler = () => {
    dispatch(removeShowedElement(showElementId));
  };

  const submitData = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const {
      address,
      city,
      state,
      zipCode
    } = addressEl;
    if (address && city && state && zipCode) {
      completeAction?.({
        ...addressEl,
        address,
        city,
        zipCode,
        state,
        county: addressEl.county?.replace(/ county/, "")
          .replace(/ County/, "")
      });
      onCloseHandler();
    } else {
      setIsShowValidate(true);
    }
  };

  const setValue = (
    key: keyof AddressShortDTO,
    value: string | number | undefined
  ) => {
    setAddress({
      ...addressEl,
      [key]: value
    });
  };

  const getAdditionalData = async () => {
    if (zipCode && zipCode.length > 4) {
      const data = await GoogleSearchAPI.getListOfGeoObjectsByApi(zipCode);
      const currentResult = data.results?.[0];

      const foundCity = currentResult?.address_components.find(address => {
        return address.types.includes("locality");
      });
      const foundCounty = currentResult?.address_components.find(address => {
        return address.types.includes("administrative_area_level_2");
      });
      const foundState = currentResult?.address_components.find(address => {
        return address.types.includes("administrative_area_level_1");
      });
      const newData: Partial<AddressShortDTO> = {};
      if (foundState && !state) {
        newData.state = String(foundState?.short_name);
        setState({
          label: foundState?.long_name,
          value: foundState?.short_name
        });
      }
      if (foundCounty && !county) {
        newData.county = String(foundCounty?.short_name);
        setCounty({
          label: foundCounty.long_name + (foundState ? ` - ${foundState?.long_name}` : ""),
          value: foundCounty?.short_name,
          title: foundCounty?.long_name
        } as OptionType);
      }
      if (foundCity && !city) {
        newData.city = String(foundCity.long_name);
      }
      setAddress({
        ...addressEl,
        ...newData
      });
    }
  };

  useEffect(() => {
    getAdditionalData();
  }, [zipCode]);

  return (
    <Modal>
      <div className="modal">
        <div className="scroll-area">
          <CustomScrollbars
            style={{
              width: "700px",
              height: "100%"
            }}
            renderView={(props) => (
              <div
                {...props}
                className={"scroll-area"}
                style={{
                  position: "absolute",
                  inset: "0px",
                  overflowY: "scroll",
                  marginRight: `${
                    navigator.platform === "MacIntel" ? "-17px" : "-35px"
                  }`,
                  marginBottom: "-17px"
                }}
              />
            )}>
            <form className="modal-content" onSubmit={submitData}>
              <div
                className="modal__close"
                onClick={onCloseHandler}
                title="Close"
              />

              <div className="modal-title">create address</div>
              <div className="flex-container">
                <Input
                  label="ADDRESS/PO BOX"
                  placeholder="Type here"
                  name={"new-address"}
                  required={true}
                  autocomplete={"new-address"}
                  error={isShowValidate && !address ? "Please fill this field" : ""}
                  value={address}
                  onChange={(e) => setValue("address", e.target.value)}
                />
                <Input
                  label="City"
                  placeholder="Type here"
                  name={"new-city"}
                  autocomplete={"new-city"}
                  required={true}
                  error={isShowValidate && !city ? "Please fill this field" : ""}
                  value={city}
                  onChange={(e) => setValue("city", e.target.value)}
                />
              </div>
              <div className="flex-container">
                <Input
                  label="SUITE NUMBER"
                  name={"new-suite"}
                  autocomplete={"new-suite"}
                  placeholder="Type here"
                  value={suite}
                  onChange={(e) => setValue("suite", e.target.value)}
                />
                <SelectSearch
                  label="State"
                  name={"new-state"}
                  autoComplete={"new-state"}
                  placeholder="Type here"
                  required={true}
                  options={Object.values(states).map(state => ({
                    label: state.full,
                    value: state.short
                  }))}
                  error={isShowValidate && !state ? "Please fill this field" : ""}
                  value={state && [state]}
                  onChange={(res) => {
                    const value = res?.[0].value;
                    if (typeof value !== "boolean") {
                      setValue("state", value);
                    }
                    setState(res?.[0]);
                  }}
                />
              </div>
              <div className="flex-container">
                <Input
                  label="Zip-code"
                  placeholder="Type here"
                  autocomplete={"new-zip-code"}
                  name={"new-zip-code"}
                  required={true}
                  error={isShowValidate && !zipCode ? "Please fill this field" : ""}
                  value={zipCode}
                  onChange={(e) => setValue("zipCode", e.target.value)}
                />
                <SelectSearch
                  label="County"
                  name={"new-county"}
                  autoComplete={"new-county"}
                  placeholder="Type here"
                  asyncOptions={async (searchParams) => {
                    const token = await getAccessTokenUtil(dispatch).catch(
                      (e: IError) => {
                        console.error(e);
                        throw new Error(e.message);
                      }
                    );
                    return loadCountiesOptions(searchParams, token);
                  }}
                  value={county && [county]}
                  onChange={(res) => {
                    const el = res?.[0];
                    setValue("county", (el as any)?.title);
                    setCounty(res?.[0]);
                  }}
                />
              </div>
              <footer className="modal-footer">
                <Button variant="light" onClick={onCloseHandler}>
                  Cancel
                </Button>
                <Button variant="dark" onClick={submitData}>
                  Save
                </Button>
              </footer>
            </form>
          </CustomScrollbars>
        </div>
      </div>
      <div className="modal-overlay" onClick={onCloseHandler} />
    </Modal>
  );
};

export default AddressModal;
