import React, { FC, useState, useEffect, useRef, RefObject } from 'react';
import SwiperCore, { Controller, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Portal from 'components/Hocs/Portal';

/* types */
import { ImageDTO } from '@ternala/voltore-types';
import { getMiddleImageOne } from 'utils';

interface Props {
  images?: ImageDTO[];
  initialSlideKey?: number;
  initialSlideIndex: number;
  onClose: () => void;
}

SwiperCore.use([Controller, Navigation, Pagination]);

const GalleryFull: FC<Props> = ({ images, initialSlideIndex, onClose }) => {
  const [fullImageSwiper, setFullImageSwiper] = useState<
    SwiperCore | undefined
  >();
  const [thumbnailsSwiper, setThumbnailsSwiper] = useState<
    SwiperCore | undefined
  >();

  const swiperRef = useRef() as RefObject<any>;

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardControls);
    return () => {
      document.removeEventListener('keydown', handleKeyboardControls);
    };
  }, []);

  const fullImageSwiperParams = {
    controller: { control: thumbnailsSwiper },
    navigation: images && images?.length > 1,
    onSwiper: setFullImageSwiper,
    initialSlide: initialSlideIndex,
    watchSlidesProgress: true,
    grabCursor: true,
    updateOnWindowResize: true,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
      pageUpDown: true,
    },
  };

  const thumbnailsSwiperParams = {
    controller: { control: fullImageSwiper },
    onSwiper: setThumbnailsSwiper,
    ref: swiperRef,
    initialSlide: initialSlideIndex,
    grabCursor: true,
    centeredSlides: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    slidesPerView: 'auto',
    keyboard: {
      enabled: true,
      onlyInViewport: true,
      pageUpDown: true,
    },
  };

  useEffect(() => {
    const { swiper } = swiperRef.current;
    setTimeout(() => swiper.slideTo(initialSlideIndex, 0), 40);
  }, [initialSlideIndex, fullImageSwiper, thumbnailsSwiper]);

  const handleKeyboardControls = (event: KeyboardEvent) => {
    const { swiper } = swiperRef.current;

    if (event.key === 'Escape') {
      onClose();
    }

    if (event.key === 'ArrowLeft') {
      swiper.slidePrev();
    }

    if (event.key === 'ArrowRight') {
      swiper.slideNext();
    }
  };
  return (
    <Portal>
      <div className="gallery-fullscreen">
        <div
          className="gallery-fullscreen__close"
          onClick={onClose}
          title="Close"
        />

        <div className="gallery-fullscreen__image-full image-full">
          <Swiper {...fullImageSwiperParams} initialSlide={initialSlideIndex}>
            {images?.map((image, index) => (
              <SwiperSlide key={String(image.id)} virtualIndex={index}>
                <img className="image-full__img" src={image.url} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Swiper
          {...thumbnailsSwiperParams}
          slidesPerView="auto"
          initialSlide={initialSlideIndex}>
          {images?.map((image, index) => (
            <SwiperSlide virtualIndex={index} key={String(image.id)}>
              <div className="gallery-fullscreen__thumbnail">
                <img src={getMiddleImageOne(image)} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="gallery-fullscreen__overlay" onClick={onClose} />
      </div>
    </Portal>
  );
};

export default GalleryFull;
