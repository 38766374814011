import { all } from 'redux-saga/effects';
import { createReducer, ActionType } from 'typesafe-actions';
import { omit } from 'lodash';

// Actions
import * as actions from './actions';
import { widgetName } from './actions';
import * as involvementActions from '../involvement/actions';
import * as favoriteActions from '../favorite/actions';

// Interfaces
import { IStore, LoaderActionType } from 'controllers/store';
import { IPersonState, PersonExpandedDTO } from './models';
import { IError, ILoader } from 'models';

// Sagas
import { personActionSaga } from './sagas/person';
import { concatWithUnique } from 'utils/concatWithUnique';
import { generateLoaderActions } from '../based';
import { InvolvementShortDTO } from '@ternala/voltore-types';

export type PersonActionType = ActionType<typeof actions>;
export type InvolvementActionType = ActionType<typeof involvementActions>;
export type FavoriteActionType = ActionType<typeof favoriteActions>;

export const personSaga = function* () {
  yield all([personActionSaga()]);
};

/* Reducer */
const initialState: IPersonState = {
  state: {
    loaders: [],
    errors: [],
  },
  storedSearchParams: null,
  personData: {},
};

type ActionTypes = PersonActionType | LoaderActionType | InvolvementActionType | FavoriteActionType;

const loaderActions = generateLoaderActions<IPersonState, ActionTypes>(
  widgetName,
);

export const loaderHandlers = loaderActions.handlers;

export const personReducer = createReducer<IPersonState, ActionTypes>(
  initialState,
  loaderHandlers,
)
  // favorite actions
  .handleAction(
    [favoriteActions.changeStatusAction.success],
    (state: IPersonState, { payload }): IPersonState => {
      let entity;
      if(payload.entityId){
        entity = Object.assign({}, state.personData[payload.entityId]);
      }
      if(entity && payload.entityId){
        entity.emails = entity.emails?.map(item => {
          if(item.id === payload.id){
            return {
              ...item,
              isFavorite: payload.status
            }
          }
          return item
        })
        entity.phones = entity.phones?.map(item => {
          if(item.id === payload.id){
            return {
              ...item,
              isFavorite: payload.status
            }
          }
          return item
        })
        return {
          ...state,
          personData: {
            ...state.personData,
            [payload.entityId]: entity
          }
        }
      }
      return state;
    },
  )
  .handleAction(
    [actions.getPersonsAction.success],
    (state: IPersonState, { payload }): IPersonState => {
      const storedSearchParams = state.storedSearchParams;
      const { searchParams } = payload;
      const personData = {
        ...state.personData,
      };

      payload.response.items.forEach((property) => {
        personData[property.id] = {
          ...personData[property.id],
          ...property,
        };
      });

      let newItemsList;
      if (
        JSON.stringify(omit(storedSearchParams, ['limit', 'offset'])) ===
        JSON.stringify(omit(searchParams, ['limit', 'offset']))
      ) {
        newItemsList = concatWithUnique<number>(
          state.persons || [],
          payload.response.items.map((item) => item.id),
        );
      } else {
        newItemsList = concatWithUnique<number>(
          [],
          payload.response.items.map((item) => item.id),
        );
      }
      return {
        ...state,
        personData,
        storedSearchParams: searchParams,
        count: payload.response.counts,
        persons: newItemsList,
        isAll: payload.isAll,
      };
    },
  )
  .handleAction(
    [actions.createPersonAction.success],
    (state: IPersonState, { payload }): IPersonState => ({
      ...state,
      personData: {
        ...state.personData,
        [payload.id]: payload,
      },
      newPersons: [...(state.newPersons || []), payload.id],
    }),
  )
  // get owned properties
  .handleAction(
    [actions.getPersonOwnedPropertiesAction.success],
    (state: IPersonState, { payload }): IPersonState => {
      return {
        ...state,
        persons: concatWithUnique<number>(state.persons || [], [payload.id]),
        personData: {
          ...state.personData,
          [payload.id]: {
            ...state.personData[payload.id],
            propertiesInvolved: payload.response,
          },
        },
      };
    },
  )
  .handleAction(
    [actions.getPersonAction.success, actions.updatePersonAction.success],
    (state: IPersonState, { payload }): IPersonState => ({
      ...state,
      personData: {
        ...state.personData,
        [payload.id]: {
          ...({
            involvementState: state.personData?.[payload.id]?.involvementState,
            propertiesInvolved: state.personData?.[payload.id]?.propertiesInvolved
          }),
          ...payload,
        },
      },
    }),
  )
  .handleAction(
    [actions.deletePersonAction.success],
    (state: IPersonState, { payload }): IPersonState => {
      return {
        ...state,
        persons: state.persons?.filter((person) => person !== payload.id),
        newPersons: state.newPersons?.filter(
          (person) => person !== payload.id,
        ),
        count: (state.count || 1) - 1,
        personData: payload.id
          ? omit(state.personData, [payload.id])
          : state.personData,
      };
    },
  )
  // working with involvements
  .handleAction(
    [involvementActions.getInvolvementsAction.success],
    (state: IPersonState, { payload }): IPersonState => {
      if (payload.additionalFields.person) {
        return {
          ...state,
          personData: {
            ...state.personData,
            ...{
              [payload.additionalFields.person]: {
                ...(state.personData?.[payload.additionalFields.person] || {}),
                involvementState: {
                  ...(state.personData?.[payload.additionalFields.person]
                    ?.involvementState || {}),
                  ...(payload.additionalFields.timeMarker === 'current'
                    ? {
                        currentInvolvements: {
                          items: payload.searchParams?.offset
                            ? concatWithUnique<number>(
                                state.personData?.[
                                  payload.additionalFields.person
                                ].involvementState?.currentInvolvements
                                  ?.items || [],
                                payload.res.items.map((item) => item.id),
                              )
                            : payload.res.items.map((item) => item.id),
                          isAll: payload.isAll,
                          count: payload.res.counts,
                        },
                      }
                    : {}),
                  allInvolvements: {
                    items: payload.searchParams?.offset
                      ? concatWithUnique<number>(
                          state.personData?.[payload.additionalFields.person]
                            .involvementState?.allInvolvements?.items || [],
                          payload.res.items.map((item) => item.id),
                        )
                      : payload.res.items.map((item) => item.id),
                    isAll: payload.isAll,
                    count: payload.res.counts,
                  },
                },
              } as PersonExpandedDTO,
            },
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.createInvolvementAction.success],
    (state: IPersonState, { payload }): IPersonState => {
      if (
        payload.additionalFields.person &&
        state.personData?.[payload.additionalFields.person]
      ) {
        return {
          ...state,
          personData: {
            ...state.personData,
            [payload.additionalFields.person]: {
              ...(state.personData?.[payload.additionalFields.person] || {}),
              involvements: [
                payload.res,
                ...(state.personData?.[payload.additionalFields.person]
                  ?.involvements || []),
              ].filter(
                (involvement) =>
                  !involvement.endDate ||
                  new Date(involvement.endDate) > new Date(),
              ),
              involvementState: {
                ...(state.personData?.[payload.additionalFields.person]
                  ?.involvementState || {}),
                currentInvolvements: {
                  ...(state.personData?.[payload.additionalFields.person]
                    ?.involvementState?.currentInvolvements || {}),
                  items: [
                    ...(!payload.res.endDate ||
                    new Date(payload.res.endDate) > new Date()
                      ? [payload.res.id]
                      : []),
                    ...(state.personData?.[payload.additionalFields.person]
                      ?.involvementState?.currentInvolvements?.items || []),
                  ],
                },
                allInvolvements: {
                  ...(state.personData?.[payload.additionalFields.person]
                    ?.involvementState?.allInvolvements || {}),
                  items: [
                    payload.res.id,
                    ...(state.personData?.[payload.additionalFields.person]
                      ?.involvementState?.allInvolvements?.items || []),
                  ],
                },
              },
            } as PersonExpandedDTO,
          },
        };
      }
      return state;
    },
  )

  .handleAction(
    [involvementActions.updateInvolvementAction.success],
    (state: IPersonState, { payload }): IPersonState => {
      if (
        payload.additionalFields.person &&
        state.personData?.[payload.additionalFields.person]
      ) {
        return {
          ...state,
          personData: {
            ...state.personData,
            [payload.additionalFields.person]: {
              ...(state.personData?.[payload.additionalFields.person] || {}),
              involvements:
                payload.res.person?.id === payload.additionalFields.person
                  ? concatWithUnique<InvolvementShortDTO>(
                      state.personData?.[payload.additionalFields.person]
                        ?.involvements || [],
                      [payload.res],
                      'id',
                      false,
                    ).filter(
                      (involvement) =>
                        !involvement.endDate ||
                        new Date(involvement.endDate) > new Date(),
                    )
                  : (
                      state.personData?.[payload.additionalFields.person]
                        ?.involvements || []
                    ).filter(
                      (involvement) =>
                        involvement.id !== payload.res.id &&
                        (!involvement.endDate ||
                          new Date(involvement.endDate) > new Date()),
                    ),
              involvementState: {
                ...(state.personData?.[payload.additionalFields.person]
                  ?.involvementState || {}),
                currentInvolvements: {
                  ...(state.personData?.[payload.additionalFields.person]
                    ?.involvementState?.currentInvolvements || {}),
                  items:
                    payload.res.person?.id === payload.additionalFields.person
                      ? concatWithUnique<number>(
                          !payload.res.endDate ||
                            new Date(payload.res.endDate) > new Date()
                            ? [payload.res.id]
                            : [],
                          state.personData?.[payload.additionalFields.person]
                            ?.involvementState?.currentInvolvements?.items ||
                            [],
                        )
                      : (
                          state.personData?.[payload.additionalFields.person]
                            ?.involvementState?.currentInvolvements?.items || []
                        ).filter(
                          (involvement) => involvement !== payload.res.id,
                        ),
                },
                allInvolvements: {
                  ...(state.personData?.[payload.additionalFields.person]
                    ?.involvementState?.allInvolvements || {}),
                  items:
                    payload.res.person?.id === payload.additionalFields.person
                      ? concatWithUnique<number>(
                          [payload.res.id],
                          state.personData?.[payload.additionalFields.person]
                            ?.involvementState?.allInvolvements?.items || [],
                        )
                      : (
                          state.personData?.[payload.additionalFields.person]
                            ?.involvementState?.allInvolvements?.items || []
                        ).filter(
                          (involvement) => involvement !== payload.res.id,
                        ),
                },
              },
            } as PersonExpandedDTO,
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.deleteInvolvementAction.success],
    (state: IPersonState, { payload }): IPersonState => {
      const personId = payload.additionalFields.person;
      if (personId && state.personData?.[personId]) {
        return {
          ...state,
          personData: {
            ...state.personData,
            [personId]: {
              ...(state.personData?.[personId] || {}),
              involvements:
                state.personData?.[personId].involvements?.filter(
                  (involvement) => involvement.id !== payload.id,
                ) || [],
              involvementState: {
                ...(state.personData?.[personId]?.involvementState || {}),
                currentInvolvements: {
                  ...(state.personData?.[personId]?.involvementState
                    ?.currentInvolvements || {}),
                  items:
                    state.personData?.[
                      personId
                    ]?.involvementState?.currentInvolvements?.items?.filter(
                      (id) => id !== payload.id,
                    ) || [],
                },
                allInvolvements: {
                  ...(state.personData?.[personId]?.involvementState
                    ?.allInvolvements || {}),
                  items:
                    state.personData?.[
                      personId
                    ]?.involvementState?.allInvolvements?.items.filter(
                      (id) => id !== payload.id,
                    ) || [],
                },
              },
            } as PersonExpandedDTO,
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.addInvolvementLoader],
    (state: IPersonState, { payload }): IPersonState => {
      if (
        payload.additionalFields.person &&
        state.personData?.[payload.additionalFields.person]
      ) {
        return {
          ...state,
          personData: {
            ...state.personData,
            ...{
              [payload.additionalFields.person]: {
                ...(state.personData?.[payload.additionalFields.person] || {}),
                involvementState: {
                  ...(state.personData?.[payload.additionalFields.person]
                    ?.involvementState || {}),
                  state: {
                    loaders: [
                      ...(state.personData?.[payload.additionalFields.person]
                        .involvementState?.state?.loaders || []),
                      omit(payload, ['additionalFields']),
                    ],
                    errors:
                      state.personData?.[payload.additionalFields.person]
                        .involvementState?.state?.errors || [],
                  },
                },
              } as PersonExpandedDTO,
            },
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.removeInvolvementLoader],
    (state: IPersonState, { payload }): IPersonState => {
      if (
        payload.additionalFields.person &&
        state.personData?.[payload.additionalFields.person]
      ) {
        return {
          ...state,
          personData: {
            ...state.personData,
            ...{
              [payload.additionalFields.person]: {
                ...(state.personData?.[payload.additionalFields.person] || {}),
                involvementState: {
                  ...(state.personData?.[payload.additionalFields.person]
                    ?.involvementState || {}),
                  state: {
                    loaders:
                      state.personData?.[
                        payload.additionalFields.person
                      ].involvementState?.state?.loaders.filter(
                        (loader: ILoader) => loader.id !== payload.id,
                      ) || [],
                    errors:
                      state.personData?.[payload.additionalFields.person]
                        .involvementState?.state?.errors || [],
                  },
                },
              } as PersonExpandedDTO,
            },
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.addInvolvementError],
    (state: IPersonState, { payload }): IPersonState => {
      if (
        payload.additionalFields.person &&
        state.personData?.[payload.additionalFields.person]
      ) {
        return {
          ...state,
          personData: {
            ...state.personData,
            ...{
              [payload.additionalFields.person]: {
                ...(state.personData?.[payload.additionalFields.person] || {}),
                involvementState: {
                  ...(state.personData?.[payload.additionalFields.person]
                    ?.involvementState || {}),
                  state: {
                    loaders:
                      state.personData?.[payload.additionalFields.person]
                        .involvementState?.state?.loaders || [],
                    errors: [
                      ...(state.personData?.[payload.additionalFields.person]
                        .involvementState?.state?.errors || []),
                      payload,
                    ],
                  },
                },
              } as PersonExpandedDTO,
            },
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.removeInvolvementError],
    (state: IPersonState, { payload }): IPersonState => {
      if (
        payload.additionalFields.person &&
        state.personData?.[payload.additionalFields.person]
      ) {
        return {
          ...state,
          personData: {
            ...state.personData,
            ...{
              [payload.additionalFields.person]: {
                ...(state.personData?.[payload.additionalFields.person] || {}),
                involvementState: {
                  ...(state.personData?.[payload.additionalFields.person]
                    ?.involvementState || {}),
                  state: {
                    loaders:
                      state.personData?.[payload.additionalFields.person]
                        .involvementState?.state?.loaders || [],
                    errors:
                      state.personData?.[
                        payload.additionalFields.person
                      ].involvementState?.state?.errors.filter(
                        (error: IError) => error.type !== payload.target,
                      ) || [],
                  },
                },
              } as PersonExpandedDTO,
            },
          },
        };
      }
      return state;
    },
  );
/* Loader actions */
export const addLoader = loaderActions.actions.addLoader;
export const addError = loaderActions.actions.addError;
export const removeError = loaderActions.actions.removeError;
export const removeLoader = loaderActions.actions.removeLoader;

/* Selectors */
export const getPersonsList = (state: IStore) => state.person.persons;
export const getPersonsCount = (state: IStore) => state.person.count;
