import React, { FC, useState, ChangeEvent } from 'react';

/* components */
import PopupFullImage from 'components/modals/PopupFullImage';
import { ViewIcon } from 'components/icons/ViewIcon';
import { TrashIcon } from 'components/icons/TrashIcon';
import { CameraIcon } from 'components/icons/CameraIcon';

/* constants & utils */
import { MAX_IMAGE_SIZE } from 'config';

/* styles */
import { Color } from 'components/UI/controls/shared/styles';

/* types */
import { ImageDTO } from "@ternala/voltore-types";

interface IPreview {
  id: number;
  url: string;
  file: File;
  isMain?: boolean;
}

interface Props {
  label?: string;
  images?: ImageDTO[];
  multiple?: boolean;
  onChange: (oldImages: number[], newImages: File[]) => void;
  onMainImageChange: (index: number) => void;
}

const ModalPreviewGallery: FC<Props> = ({
  label,
  images,
  multiple,
  onMainImageChange,
  ...props
}) => {
  const FILE_TYPES = ['image/jpeg', 'image/png'];

  const [oldImages, setOldImages] = useState<ImageDTO[]>(images || []);
  const [newImages, setNewImages] = useState<IPreview[]>([]);

  const [isFullImagePopupOpen, setFullImagePopupOpen] =
    useState<boolean>(false);
  const [fullImageUrl, setFullImageUrl] = useState<string>('');

  const onAddImage = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    let updatedNewImages: IPreview[] = [...newImages];

    if (files) {
      if (updatedNewImages.length > 0) {
        updatedNewImages.forEach((preview) => {
          Array.prototype.forEach.call(files, (file) => {
            if (FILE_TYPES.includes(file.type) && file.size < MAX_IMAGE_SIZE) {
              if (preview.id === file.name) {
                /* filter existing values */
                updatedNewImages = updatedNewImages.filter((preview) => {
                  return preview.id !== file.name;
                });
              }
              updatedNewImages.push({
                id: file.name,
                url: URL.createObjectURL(file),
                file,
              });
            }
          });
        });
      } else {
        Array.prototype.forEach.call(files, (file) => {
          /* reject files with the same name */
          const fileNameExists = images?.some(
            (image) => image.title === file.name,
          );

          if (
            !fileNameExists &&
            FILE_TYPES.includes(file.type) &&
            file.size < MAX_IMAGE_SIZE
          ) {
            updatedNewImages.push({
              id: file.name,
              url: URL.createObjectURL(file),
              file,
            });
          }
        });
      }
    }

    const uniquePreviews = [];
    const map = new Map();
    for (const preview of updatedNewImages) {
      if (!map.has(preview.id)) {
        map.set(preview.id, true);
        uniquePreviews.push({
          id: preview.id,
          url: preview.url,
          file: preview.file,
        });
      }
    }

    setNewImages(uniquePreviews);
    props.onChange(
      oldImages.map((image) => image.id),
      uniquePreviews.map((image) => image.file),
    );

    event.target.value = '';
  };

  const viewImage = (url: string) => {
    setFullImageUrl(url);
    setFullImagePopupOpen(true);
  };

  const onRemoveImage = (imageId: number, type: 'old' | 'new') => {
    let updatedOldImages: ImageDTO[] = [];
    let updatedNewImages: IPreview[] = [];

    if (type === 'old') {
      updatedOldImages = oldImages.filter((image) => image.id !== imageId);
      setOldImages(updatedOldImages);
    }
    if (type === 'new') {
      updatedNewImages = newImages.filter((image) => image.id !== imageId);
      setNewImages(updatedNewImages);
    }

    const mainImageIndex = [...updatedOldImages, ...updatedNewImages].findIndex(
      (image) => image.isMain,
    );

    mainImageIndex ? onMainImageChange(mainImageIndex) : onMainImageChange(0);
    props.onChange(
      updatedOldImages.map((image) => image.id),
      updatedNewImages.map((image) => image.file),
    );
  };

  const selectMainImage = (
    imageId: number,
    type: 'old' | 'new',
    index: number,
  ) => {
    const updatedOldImages = oldImages.map((image) => ({
      ...image,
      isMain: false,
    }));
    const updatedNewImages = newImages.map((image) => ({
      ...image,
      isMain: false,
    }));

    if (type === 'old') {
      const selectedImage = updatedOldImages.find(
        (image) => image.id === imageId,
      );
      if (selectedImage) selectedImage.isMain = true;
    }
    if (type === 'new') {
      const selectedImage = updatedNewImages.find(
        (image) => image.id === imageId,
      );
      if (selectedImage) selectedImage.isMain = true;
    }
    setOldImages(updatedOldImages);
    setNewImages(updatedNewImages);
    onMainImageChange(index);
  };

  return (
    <div className="modal-gallery">
      <div className="modal-gallery-label">{label}</div>
      <div className="modal-gallery-images-container">
        <div className="modal-gallery__image gallery-upload">
          <input
            type="file"
            id="images"
            className="visually-hidden"
            onChange={onAddImage}
            multiple={multiple}
            accept={FILE_TYPES.join(', ')}
          />

          <label htmlFor="images" className="upload-label">
            <div className="upload-icon">
              <CameraIcon color={Color.main} />
            </div>
            <span className="upload-hint">.jpg .jpeg .png</span>
          </label>
        </div>

        {[...oldImages, ...newImages].map((preview, index) => (
          <div className="modal-gallery__image" key={preview.id}>
            <img src={preview.url} alt="building" />

            <div className="image-mask">
              <div className="image-controls">
                <div>
                  <div
                    onClick={() => viewImage(preview.url)}
                    className="icon view-icon"
                    title="View">
                    <ViewIcon />
                  </div>

                  <div
                    onClick={() =>
                      onRemoveImage(
                        preview.id,
                        'file' in preview ? 'new' : 'old',
                      )
                    }
                    className="icon trash-icon"
                    title="Remove">
                    <TrashIcon />
                  </div>
                </div>

                {!preview.isMain && (
                  <div
                    className="image-controls__make-as-main-btn"
                    onClick={() =>
                      selectMainImage(
                        preview.id,
                        'file' in preview ? 'new' : 'old',
                        index,
                      )
                    }>
                    <div>Make as main</div>
                  </div>
                )}
              </div>

              {preview.isMain && (
                <div className="main-image-label">Main image</div>
              )}
            </div>
          </div>
        ))}
      </div>

      {isFullImagePopupOpen && (
        <PopupFullImage
          url={fullImageUrl}
          onClose={() => setFullImagePopupOpen(false)}
        />
      )}
    </div>
  );
};

ModalPreviewGallery.defaultProps = {
  multiple: true,
};

export default ModalPreviewGallery;
