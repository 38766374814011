import { dataReturner } from "./dataReturner";
import { AddressShortDTO } from "@ternala/voltore-types/lib/modules/address/addressShort.dto";

export const googleResultToAddress = (data: google.maps.GeocoderResult & {isConnected?: boolean}): AddressShortDTO => {
  return {
    address: `${dataReturner(
      data.address_components,
      'street_number',
    )} ${dataReturner(data.address_components, 'route')}`,
    city: dataReturner(data.address_components, 'locality') || dataReturner(data.address_components, 'sublocality'),
    state: dataReturner(data.address_components, 'administrative_area_level_1'),
    zipCode: String(dataReturner(data.address_components, 'postal_code')),
    county: dataReturner(data.address_components, 'administrative_area_level_2')
      ?.replace(/ county/, '')
      .replace(/ County/, ''),
    latitude:
      typeof data.geometry.location?.lat === 'function'
        ? data.geometry.location?.lat()
        : (data.geometry.location?.lat as unknown as number) ?? 0,
    longitude:
      typeof data.geometry.location?.lng === 'function'
        ? data.geometry.location?.lng()
        : (data.geometry.location?.lng as unknown as number) ?? 0,
    isConnected: data.isConnected,
  };
}