import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const LogoIcon: FC<Props> = ({ color, ...props }) => (
   <svg
      {...props}
      width="111"
      height="34"
      viewBox="0 0 111 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M46.8705 7.89551L42.7086 25.6207L38.4941 7.89551H39.9618L42.6889 19.3626L45.4072 7.89551H46.8705Z"
         fill="white"
      />
      <path
         d="M55.3297 7.89558C56.5072 7.89082 57.6733 8.1248 58.7578 8.58338C59.807 9.02074 60.7637 9.65329 61.5769 10.4475C62.394 11.2592 63.0385 12.2278 63.4717 13.2951C63.934 14.3935 64.173 15.573 64.1748 16.7647C64.1782 17.9357 63.9443 19.0951 63.487 20.1731C62.5948 22.3122 60.898 24.0153 58.7622 24.9154C57.6893 25.3726 56.5357 25.6103 55.3694 25.6145C54.2032 25.6186 53.0479 25.3891 51.9718 24.9395C50.9047 24.4942 49.9367 23.8412 49.1242 23.0185C48.3084 22.1989 47.6544 21.2328 47.1966 20.1709C46.7277 19.0968 46.4875 17.9368 46.4913 16.7647C46.4911 15.591 46.7211 14.4286 47.1681 13.3432C47.5982 12.2665 48.2463 11.2903 49.0716 10.4759C49.9003 9.66565 50.8766 9.02172 51.9477 8.579C53.0182 8.12792 54.1681 7.89555 55.3297 7.89558ZM55.3188 9.29309C54.3291 9.27028 53.3456 9.45562 52.432 9.83711C51.5185 10.2186 50.6953 10.7877 50.0157 11.5076C49.3294 12.1985 48.7879 13.0193 48.4227 13.9219C48.0574 14.8246 47.8759 15.7911 47.8888 16.7647C47.8888 18.9012 48.624 20.6791 50.0946 22.0985C50.781 22.7806 51.5966 23.3189 52.4937 23.6819C53.3907 24.0448 54.3512 24.2252 55.3188 24.2123C57.4348 24.2123 59.0258 23.653 60.0918 22.5344C61.1578 21.4158 61.8712 20.3936 62.2319 19.4678C62.5784 18.6081 62.7618 17.6915 62.7729 16.7647C62.7959 15.7717 62.6103 14.7848 62.2281 13.868C61.8459 12.9511 61.2756 12.1246 60.554 11.4419C59.8687 10.7528 59.0526 10.2076 58.1535 9.83861C57.2544 9.46957 56.2906 9.2841 55.3188 9.29309Z"
         fill="white"
      />
      <path
         d="M65.6367 7.88672H67.1043V24.1618H70.2739V25.6294H65.6367V7.88672Z"
         fill="white"
      />
      <path
         d="M73.263 25.6294H71.7976V9.35213H69.2129V7.88672H75.839V9.35213H73.263V25.6294Z"
         fill="white"
      />
      <path
         d="M83.6325 7.90241C84.8099 7.89766 85.9761 8.13164 87.0606 8.59022C88.1102 9.02789 89.0675 9.6604 89.8819 10.4543C90.6994 11.2657 91.3439 12.2344 91.7766 13.3019C92.2368 14.3993 92.4743 15.5772 92.4754 16.7672C92.4786 17.9388 92.2446 19.099 91.7876 20.1777C90.8963 22.3188 89.1983 24.0231 87.0606 24.9223C85.9861 25.381 84.8304 25.6193 83.6621 25.6231C82.4938 25.6269 81.3366 25.396 80.2592 24.9442C79.1921 24.4997 78.2241 23.8474 77.4116 23.0253C76.5956 22.2017 75.9417 21.232 75.484 20.1668C75.0185 19.0941 74.7806 17.9365 74.7852 16.7672C74.7851 15.5934 75.015 14.431 75.4621 13.3457C75.8937 12.2686 76.5424 11.2918 77.3678 10.4762C78.1964 9.66591 79.1727 9.02198 80.2438 8.57927C81.3169 8.12964 82.4691 7.89949 83.6325 7.90241ZM83.6215 9.29993C82.6322 9.27513 81.6487 9.45848 80.7348 9.83809C79.8208 10.2177 78.9969 10.7851 78.3162 11.5035C77.6291 12.1938 77.0868 13.0144 76.7212 13.9172C76.3555 14.82 76.174 15.7867 76.1871 16.7606C76.1871 18.8971 76.9231 20.6757 78.3951 22.0966C79.0801 22.7824 79.8954 23.3243 80.793 23.6903C81.6905 24.0563 82.6523 24.2391 83.6215 24.2279C85.7375 24.2279 87.3292 23.6679 88.3967 22.5478C89.4642 21.4278 90.1776 20.4055 90.5368 19.4812C90.8852 18.616 91.0687 17.6933 91.0779 16.7606C91.1008 15.7676 90.9152 14.7807 90.533 13.8638C90.1508 12.947 89.5805 12.1205 88.8589 11.4378C88.1725 10.7504 87.3556 10.2071 86.4561 9.83996C85.5567 9.47282 84.5929 9.28921 83.6215 9.29993Z"
         fill="white"
      />
      <path
         d="M93.9883 7.88676H95.4537C96.7056 7.88242 97.9341 8.22595 99.0022 8.87904C100.088 9.53153 100.989 10.4516 101.618 11.5514C102.337 12.7812 102.674 14.1973 102.585 15.6192C102.497 17.0412 101.987 18.4046 101.12 19.5356C100.135 20.8791 98.9161 21.7392 97.4645 22.116L101.817 25.6404L99.6988 25.6273L95.4537 22.2408V25.6295H93.9883V7.88676ZM95.4537 20.9923C96.2101 21.0026 96.9603 20.8533 97.6551 20.5542C98.3166 20.2621 99.0614 19.6824 99.8894 18.815C100.717 17.9475 101.131 16.7333 101.131 15.1722C101.131 13.5659 100.714 12.34 99.8784 11.4944C99.0431 10.6489 98.294 10.0809 97.631 9.79027C96.9438 9.4937 96.2021 9.34446 95.4537 9.35218V20.9923Z"
         fill="white"
      />
      <path
         d="M106.287 9.36702V12.3241H109.505L110.002 13.7436H106.278V24.1767H109.564V25.6421H104.819V13.7436H104.041L103.568 12.3241H104.821V7.89941H109.577V9.36702H106.287Z"
         fill="white"
      />
      <path
         d="M18.9086 15.7457L10.5892 17.739L10.8805 20.3676L7.22247 17.739L10.1511 13.2201L10.5892 10.4492L22.5557 2.86799L23.7298 1.45953C20.4247 -0.0127491 16.7338 -0.383192 13.2021 0.402903C9.67032 1.189 6.48499 3.08996 4.11641 5.82508C1.74783 8.5602 0.321558 11.9845 0.0482152 15.5923C-0.225128 19.2001 0.668943 22.8002 2.5984 25.861C4.52787 28.9217 7.39044 31.2809 10.7634 32.5901C14.1364 33.8994 17.841 34.0892 21.3301 33.1317C24.8193 32.1742 27.9081 30.12 30.1403 27.2726C32.3726 24.4251 33.6301 20.9353 33.727 17.3184L28.8445 17.4959L18.9086 15.7457Z"
         fill="white"
      />
      <path
         d="M23.7343 1.45703L22.5559 2.86988L10.5894 10.4511L10.1513 13.222L7.22266 17.7409L10.8807 20.3694L10.5894 17.7409L18.9131 15.7454L28.8381 17.4978L33.7272 17.3203C33.7272 17.167 33.7272 17.0159 33.7272 16.8625C33.7278 13.6038 32.7844 10.4146 31.011 7.6807C29.2376 4.94675 26.7102 2.78507 23.7343 1.45703Z"
         fill={color}
      />
      <path
         d="M30.2786 21.8577L24.9514 19.2773L22.9932 23.5465L24.406 25.2376L24.187 23.2662L25.5012 20.7931L27.9326 21.9322L25.5012 23.9321L26.0445 26.0721L27.1397 26.4949L26.7016 24.4884L28.9819 22.482L30.0771 23.5378L29.317 25.2288L28.4408 24.4884L28.1692 25.8181L28.9709 26.3722L30.4604 25.5968L31.6082 23.9321L32.2654 23.7481C32.7918 22.5752 33.1814 21.3455 33.4263 20.0834L30.2786 21.8577Z"
         fill={color}
      />
      <path
         d="M14.3733 12.5387L15.4817 9.91016L13.806 11.6822L12.8027 12.0174L14.3733 12.5387Z"
         fill="white"
      />
   </svg>
)

LogoIcon.defaultProps = {
   color: "#1F2531"
}