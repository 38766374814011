
import React, { FC, useState, useEffect } from 'react';

/* UI components */
import Modal from 'components/Hocs/Portal';
import Button from 'components/UI/controls/Button';

/* types */
import { EmailUpdateDTO } from '@ternala/voltore-types';
import InputMulti from 'components/UI/controls/InputMulti';
import uuid from 'utils/uuid';

interface IProps {
  onClose: () => void;
  data?: EmailUpdateDTO & { uuid?: string };
  emails?: EmailUpdateDTO[];
  onChange: (
    email: EmailUpdateDTO & { uuid?: string },
    callback: Function,
  ) => void;
}

const PopupEmail: FC<IProps> = ({ onClose, onChange, data, emails }) => {
  const [email, setEmail] = useState<string>(data ? data.email : '');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  const submitData = () => {
    if (!email) return;
    const payload: EmailUpdateDTO & { uuid?: string } = {
      email,
      id: data?.id,
      uuid: data?.uuid || uuid(),
      isFavorite: data?.isFavorite,
    };
    onChange(payload, () => onClose());
  };

  return (
    <Modal>
      <div className="modal modal_centered">
        <div className="scroll-area">
          <form className="modal-content" onSubmit={submitData}>
            <div className="modal__close" onClick={onClose} title="Close" />

            <div className="modal-title">{data ? 'edit' : 'add'} email</div>
            <div
              className="items-container"
              style={{
                flexDirection: 'column',
                marginBottom: '25px',
              }}>
              <InputMulti
                type="email"
                label="email"
                placeholder="Type here"
                items={emails?.filter(
                  //@ts-ignore
                  (email) => email.id !== data?.id || email.uuid !== data?.uuid,
                )}
                name="email"
                data={[].map((value: any) => value)}
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
                onError={(error) => {
                  setError(error);
                }}
              />
            </div>

            <footer className="modal-footer">
              <Button variant="light" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="dark"
                onClick={submitData}
                disabled={!email || error !== ''}>
                Save
              </Button>
            </footer>
          </form>
        </div>

        <div className="modal-overlay" onClick={onClose} />
      </div>
    </Modal>
  );
};

export default PopupEmail;
