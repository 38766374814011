import React, { FC, useState, useEffect } from "react";
import { Color, ColorPicker, createColor } from "material-ui-color";
import { useDispatch, useSelector } from "react-redux";
import { TagFullDTO } from "@ternala/voltore-types/lib/modules/card/tag/tagFull.dto";
import {
  TagUpdateRequest,
  TagCreateRequest,
  TagShortDTO, TagCategoryDTO
} from "@ternala/voltore-types";


/* UI components */
import Modal from "components/Hocs/Portal";
import Input from "components/UI/controls/Input";
import Button from "components/UI/controls/Button";
import { tagCategoryModalKey } from "./PopupTagGroup";

/* types */
import { IError, OptionType } from "models";
import SelectSearch from "components/UI/controls/SelectSearchCustom";
import { loadTagCategoriesOptions } from "config/constants/select-options";
import {
  addShowedElement,
  removeShowedElement
} from "../../controllers/showElement/actions";
import { ShowElementEnum } from "controllers/showElement/models.d";

/* utils */
import uuid from "utils/uuid";
import { getAccessTokenUtil } from "utils/getAccessToken";

// controllers
import { getGroups } from "controllers/tag/selectors";
import { createTagAction, updateTagAction } from "controllers/tag/actions";

export const tagModalKey = "TagModal";

interface ITagModalProps {
  showElementId?: string;
  isByShowElement?: boolean;
  id?: number;
  groupId?: number;
  completeAction?: (tag: TagFullDTO | boolean) => void;

  onClose?: () => void;
  onUpdate?: (tag: TagUpdateRequest, callback: Function) => void;
  onCreate?: (tag: TagCreateRequest, callback: Function) => void;
  setGroupCreation?: Function;
  group?: TagCategoryDTO;
  tags?: TagShortDTO[];
  tag?: TagUpdateRequest | TagShortDTO;
}

const TagModal: FC<ITagModalProps> = ({
                                        onClose,
                                        onUpdate,
                                        onCreate,
                                        completeAction,
                                        id,
                                        isByShowElement,
                                        showElementId,
                                        groupId,
                                        ...props
                                      }) => {
  let { group, tags, tag } = props;
  const groups = useSelector(getGroups);
  if (groups && groupId) {
    group = groups.find(group => group.id === groupId);
    tags = group?.tags;
    tag = group?.tags.find(tag => tag.id === id)
  }
  const [requestProcess, setRequestProcess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [tagTitle, setTagTitle] = useState<string | undefined>(
    tag ? tag.title : undefined
  );
  const [tagColor, setTagColor] = useState<Color>(
    createColor(tag ? tag.color : "#1F2531")
  );
  const [tagCategory, setTagCategory] = useState<OptionType | undefined>(
    group
      ? {
        label: group?.title,
        value: group?.id
      }
      : undefined
  );

  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.addEventListener("keydown", closeOnEscPress);

    return () => {
      document.body.removeAttribute("class");
      document.removeEventListener("keydown", closeOnEscPress);
    };
  }, []);

  useEffect(() => {
    if (
      tags &&
      !!tags.find((item) => item.title === tagTitle && tag?.id !== item.id)?.id
    )
      setError("Tag with this name is already created");
    else setError("");
  }, [tagTitle]);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      onCloseHandle();
    }
  };

  const submitData = () => {
    setRequestProcess(true);
    if (tagTitle && tagColor && tagCategory) {
      if (tag) {
        const payload: TagUpdateRequest = {
          category: Number(tagCategory.value),
          color: tagColor.hex,
          title: tagTitle,
          id: tag?.id
        };
        if (isByShowElement) {
          dispatch(updateTagAction.request({
            ...payload,
            callback: (res: TagFullDTO | boolean) => {
              if (res) onCloseHandle();
              completeAction?.(res);
              setRequestProcess(false);
            }
          }));
        } else {
          onUpdate?.(payload, (status: boolean) => {
            if (status) onCloseHandle();
            setRequestProcess(false);
          });
        }
      } else {
        const payload: TagCreateRequest = {
          category: Number(tagCategory.value),
          color: tagColor.hex,
          title: tagTitle
        };
        if (isByShowElement) {
          dispatch(createTagAction.request({
            ...payload,
            callback: (res: TagFullDTO | boolean) => {
              if (res) onCloseHandle();
              completeAction?.(res);
              setRequestProcess(false);
            }
          }));
        } else {
          onCreate?.(payload, (status: boolean) => {
            if (status) onCloseHandle();
            setRequestProcess(false);
          });
        }
      }
    }
  };

  const onCloseHandle = () => {
    if (isByShowElement) {
      if (showElementId != null) {
        dispatch(removeShowedElement(showElementId));
      }
    } else {
      onClose?.();
    }
  };

  return (
    <Modal>
      <div className="modal modal_centered">
        <div className="scroll-area">
          <form className="modal-content" onSubmit={submitData}>
            <div className="modal__close" onClick={onClose} title="Close" />

            <div className="modal-title">{tag ? "Edit" : "Create"} tag</div>
            <div
              className="items-container"
              style={{
                flexDirection: "column",
                marginBottom: "25px"
              }}>
              <Input
                label="title"
                placeholder="Type here"
                value={tagTitle}
                onChange={(e) => setTagTitle(e.target.value)}
                error={error}
                required
              />
              <SelectSearch
                label="group"
                placeholder="Select or create new"
                value={tagCategory}
                asyncOptions={async (searchParams) => {
                  const token = await getAccessTokenUtil(dispatch).catch(
                    (e: IError) => {
                      console.error(e);
                      throw new Error(e.message);
                    }
                  );
                  return loadTagCategoriesOptions(
                    searchParams,
                    undefined,
                    token
                  );
                }}
                onChange={(category) => {
                  setTagCategory({
                    value: category?.value,
                    label: category?.label
                  });
                }}
                creation={{
                  label: "Create new group",
                  onClick: () => {
                    dispatch(
                      addShowedElement({
                        id: uuid(),
                        key: tagCategoryModalKey,
                        type: ShowElementEnum.modal,
                        callback: (res: boolean | TagFullDTO) => {
                          if (typeof res !== "boolean") setTagCategory({ label: res.title, value: res.id });
                        },
                        props: {}
                      })
                    );
                  }
                }}
                required
              />
              <div className="color-container">
                <label className="taxes-field_label">color</label>
                <div className="color-picker" style={{ marginTop: "12px" }}>
                  <ColorPicker
                    value={tagColor}
                    onChange={(color) => setTagColor(color)}
                  />
                </div>
              </div>
            </div>

            <footer className="modal-footer">
              <Button variant="light" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="dark"
                onClick={submitData}
                disabled={
                  !tagTitle ||
                  !tagColor ||
                  !tagCategory ||
                  requestProcess ||
                  error !== ""
                }>
                Save
              </Button>
            </footer>
          </form>
        </div>

        <div className="modal-overlay" onClick={onClose} />
      </div>
    </Modal>
  );
};

export default TagModal;
