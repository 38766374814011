// packages from @ternala
import {AuthLoginRequestDTO} from "@ternala/voltore-types/lib/modules/auth/request.dto";
import {AuthLoginByTokenResponseDTO, AuthLoginResponseDTO} from "@ternala/voltore-types/lib/modules/auth/repsonse.dto.js";
import {DeviceDTO} from "@ternala/voltore-types/lib/modules/device.dto";

import {Config} from "../../../config/api";
import {authHeader, handleErrors, refreshHeader} from "../../../utils/API";

import {IAuthData} from "../models";

class API {
  public async login(loginData: AuthLoginRequestDTO): Promise<AuthLoginResponseDTO | string> {
    return handleErrors(
      fetch(Config.AUTH_SERVICE_ENDPOINT + "login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData)
      })
    );
  }

  public async loginByToken(accessToken: string): Promise<AuthLoginByTokenResponseDTO | string> {
    return handleErrors(
      fetch(Config.MAIN_SERVICE_ENDPOINT + "user/", {
        method: "GET",
        headers: {
          ...authHeader(accessToken),
        },
      })
    );
  }

  public async refreshToken(refreshToken: string, deviceCredentials: DeviceDTO): Promise<IAuthData | string> {
    return handleErrors(
      fetch(Config.AUTH_SERVICE_ENDPOINT + "refresh-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...refreshHeader(refreshToken),
        },
        body: JSON.stringify(deviceCredentials)
      })
    );
  }

  public async logout(
    accessToken: string,
    refreshToken: string
  ): Promise<boolean | string> {
    return handleErrors(
      fetch(Config.AUTH_SERVICE_ENDPOINT + "logout", {
        method: "POST",
        headers: {
          ...authHeader(accessToken),
          ...refreshHeader(refreshToken),
          "Content-Type": "application/json",
        },
      })
    );
  }
}
export const AuthAPI = new API();