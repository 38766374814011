import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const DownArrowIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    {...props}
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 0.5V9.5M4 9.5L1 6.5M4 9.5L7 6.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

DownArrowIcon.defaultProps = {
  color: '#1F1F1F',
};
