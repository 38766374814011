import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const UploadIcon: FC<Props> = ({ color, ...props }) => (
   <svg
      {...props}
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0.809997 3.5919L3.02405 1.52054V10.5547C3.02405 10.8021 3.23558 11 3.5 11C3.76442 11 3.97595 10.8021 3.97595 10.5547V1.52054L6.19 3.5919C6.28167 3.67766 6.40506 3.72384 6.52493 3.72384C6.64833 3.72384 6.76819 3.68096 6.85986 3.5919C7.04671 3.41709 7.04671 3.13673 6.85986 2.96192L3.83493 0.131934C3.74679 0.0494753 3.62339 0 3.5 0C3.37308 0 3.25321 0.0461769 3.16507 0.131934L0.140141 2.96192C-0.0467137 3.13673 -0.0467137 3.41709 0.140141 3.5919C0.32347 3.76342 0.626668 3.76342 0.809997 3.5919Z"
         fill={color}
      />
   </svg>
)

UploadIcon.defaultProps = {
   color: 'white'
}