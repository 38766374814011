import React, { FC, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';

/* components */
import NotFoundPage from 'pages/Static/NotFound';
import InvolvementModal from 'components/modals/InvolvementModal';
import PopupDelete from 'components/modals/PopupDelete';

import OptionsMenu from 'components/UI/controls/OptionsMenu';
import Link from 'components/UI/controls/Link';
import Loader from 'components/Loader';

/* controllers */
import {
  deleteInvolvementAction,
  getInvolvementAction,
  updateInvolvementAction,
} from 'controllers/involvement/actions';

/* constants & utils */
import { InvolvementUpdateRequest } from '@ternala/voltore-types';
import {
  EntityEnum,
  TaxonomyTypeSlug,
} from '@ternala/voltore-types/lib/constants';
import { DATE_FORMAT } from 'config';
import { LoaderAction, PageEnum } from 'config/constants';
import { enterprisesTabs } from 'config/constants/tabs';

/* types */
import { IStore } from 'controllers/store';
import { IEnterpriseState } from 'controllers/enterprise/models';
import {
  IInvolvementState,
  IResponseAdditionalFields,
} from 'controllers/involvement/models';
import { deletePersonAction, getPersonAction } from "controllers/person/actions";
import { IPersonState } from 'controllers/person/models';
import { addShowedElement } from "controllers/showElement/actions";
import { ShowElementEnum } from "controllers/showElement/models.d";

import { personModalKey } from "components/modals/PersonModal";
import CustomScrollbars from 'components/CustomScrollbars';
import { AddressField } from 'components/views/address';

import PersonInfoContent from '../../../People/Tabs/Info/PersonInfo';

import { uuid, getListImage, callbackTypeEnum, requestCallback } from "utils";

interface Props
  extends RouteComponentProps<{
    id?: string;
    tabName?: string;
    involvementId: string;
  }> {
  enterpriseData: IEnterpriseState['enterpriseData'];
  personData: IPersonState['personData'];
  involvementData: IInvolvementState['involvementData'];
}

const PersonInfo: FC<Props> = (props) => {
  const {
    enterpriseData,
    personData,
    involvementData,
    match: { params },
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPopupDeleteOpen, setIsPopupDeleteOpen] = useState<false | {id: number, entity: EntityEnum.person | EntityEnum.involvement}>(false);

  const dispatch = useDispatch();

  const enterpriseId = Number(params?.id);
  const involvementId = Number(params?.involvementId);

  const enterprise = enterpriseData[enterpriseId];
  const involvement = involvementData[involvementId];

  const loaders = enterprise?.involvementState?.state?.loaders;

  useEffect(() => {
    const loadingItem = loaders?.some(
      (loader) => loader.type === LoaderAction.involvement.getItem,
    );
    loadingItem !== undefined && setIsLoading(loadingItem);
  }, [loaders]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getInvolvementAction.request({
        id: involvementId,
        additionalFields: {
          enterprise: enterpriseId,
        },
        callback: () => setIsLoading(false),
      }),
    );

  }, []);

  useEffect(() => {
    if(involvement.person?.id){
      dispatch(
        getPersonAction.request({
          id: involvement.person?.id
        }),
      );
    }
  }, [involvement])

  if (isNaN(involvementId)) {
    return <NotFoundPage />;
  }

  const person = involvement?.person?.id ? personData?.[involvement?.person?.id] : undefined;

  return involvement ? (
    <>
      <div className="person-info-container">
        <Helmet>
          <title>
            Person | Voltore
            {process.env.REACT_APP_COMPANY_NAME
              ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        {isLoading ? (
          <div className="loader-info">
            <Loader />
          </div>
        ) : (
          <CustomScrollbars className="box-shadow-container">
            {involvement.person?.id ? <div className="person-info">
              <header>
                <h2>Personal contact</h2>
                <OptionsMenu
                  on={{
                    edit: () => dispatch(addShowedElement({
                      id: uuid(),
                      type: ShowElementEnum.modal,
                      props: {
                        personId: involvement.person?.id as number
                      },
                      key: personModalKey
                    })),
                    delete: () => setIsPopupDeleteOpen({
                      entity: EntityEnum.person,
                      id: involvement.person?.id as number
                    }),
                  }}
                />
              </header>

              <PersonInfoContent person={person} addLink={true}/>
            </div> : ""}
            <div className="involvement-info">
              <header>
                <h2>Involvement info</h2>

                <OptionsMenu
                  on={{
                    edit: () => setIsModalOpen(true),
                    delete: () => setIsPopupDeleteOpen({
                      entity: EntityEnum.involvement,
                      id: involvementId
                    }),
                  }}
                />
              </header>

              <div className="involvement-info__content content">
                {involvement?.enterprise?.images?.length ? (
                  <div className="photo">
                    <img src={getListImage(involvement.enterprise.images)} alt="" />
                  </div>
                ) : (
                  ''
                )}

                <div className="content__data-fields">
                  <div className="data-field">
                    <div className="data-field__key">Position or role</div>
                    <div className="data-field__value">
                      {involvement?.[TaxonomyTypeSlug.involvementType] && (
                        <div className="data-field__position-or-role">
                          {
                            involvement?.[TaxonomyTypeSlug.involvementType]
                              ?.title
                          }
                        </div>
                      )}

                      <div className="data-field__involvement-date">
                        {involvement?.startDate ? (
                          <Moment format={DATE_FORMAT}>
                            {involvement.startDate}
                          </Moment>
                        ) : (
                          'N\\A'
                        )}
                        &nbsp;-&nbsp;
                        {involvement?.endDate ? (
                          <>
                            <Moment format={DATE_FORMAT}>
                              {involvement.endDate}
                            </Moment>
                          </>
                        ) : (
                          'Present'
                        )}
                      </div>
                    </div>
                  </div>

                  {involvement?.enterprise?.title && (
                    <div className="data-field">
                      <div className="data-field__key">Enterprise name</div>
                      <Link
                        id={involvement?.enterprise?.id}
                        type={EntityEnum.enterprise}
                        fullPath={`/${PageEnum.ENTERPRISES}/${involvement?.enterprise?.id}/${enterprisesTabs.info.path}`}
                        newTabIcon>
                        {involvement?.enterprise?.title}
                      </Link>
                    </div>
                  )}

                  {involvement.owner && (
                    <div className="data-field">
                      <div className="data-field__key">Principal</div>
                      <div className="data-field__value">
                        {involvement.owner ? 'Yes' : 'No'}
                      </div>
                    </div>
                  )}

                  {involvement.decisionMaker && (
                    <div className="data-field">
                      <div className="data-field__key">Decision maker</div>
                      <div className="data-field__value">
                        {involvement.decisionMaker ? 'Yes' : 'No'}
                      </div>
                    </div>
                  )}

                  {involvement?.emails && involvement.emails.length > 0 && (
                    <div className="data-field">
                      <div className="data-field__key">Personal email</div>
                      <div className="data-field__value ellipsis-truncate">
                        {involvement.emails.map((email) => (
                          <Link key={email.email} mailto>
                            {email.email}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                  <AddressField address={involvement?.enterprise?.officeAddress} label={'Office address'}/>
                  {involvement?.description && (
                    <p className="description">{involvement.description}</p>
                  )}
                </div>
              </div>
            </div>
          </CustomScrollbars>
        )}
      </div>

      {isModalOpen && (
        <InvolvementModal
          enterprise={{
            id: enterpriseId,
            involvementId,
          }}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(payload) => {
            dispatch(
              updateInvolvementAction.request({
                ...payload,
                additionalFields: {
                  person: payload.person,
                  enterprise: enterpriseId,
                },
                callback: (status: boolean) => {
                  requestCallback(dispatch, status, callbackTypeEnum.update)
                  if (payload.callback) payload.callback(status);
                },
              } as unknown as InvolvementUpdateRequest & { additionalFields: IResponseAdditionalFields }),
            );
            if (enterpriseId !== payload.enterprise) {
              dispatch(
                push(
                  `/${PageEnum.ENTERPRISES}/${params.id}/${enterprisesTabs.people.path}`,
                ),
              );
            }
          }}
        />
      )}

      {isPopupDeleteOpen && (
        <PopupDelete
          onClose={() => setIsPopupDeleteOpen(false)}
          onSubmit={(callback) => {
            if(isPopupDeleteOpen.entity === EntityEnum.person) {
              dispatch(
                deletePersonAction.request({
                  id: isPopupDeleteOpen.id,
                  callback: (status: boolean) => {
                    requestCallback(dispatch, status, callbackTypeEnum.delete)
                  }
                }),
              );
            }
            dispatch(
              deleteInvolvementAction.request({
                id: involvementId,
                additionalFields: {
                  enterprise: enterpriseId,
                },
                callback: (status: boolean) => {
                  requestCallback(dispatch, status, callbackTypeEnum.delete)
                }
              }),
            );
            dispatch(
              push(
                `/${PageEnum.ENTERPRISES}/${params.id}/${enterprisesTabs.people.path}`,
              ),
            );
          }}
        />
      )}
    </>
  ) : null;
};

export default connect((store: IStore) => ({
  enterpriseData: store.enterprise.enterpriseData,
  involvementData: store.involvement.involvementData,
  personData: store.person.personData
}))(PersonInfo);
