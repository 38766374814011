import React, { FC } from 'react';
import { TaxonomyDTO, TaxonomyUpdateRequest } from '@ternala/voltore-types';
import { NoteIcon } from 'components/icons/NoteIcon';
import { TrashIconAlt } from 'components/icons/TrashIconAlt';

interface Props {
  taxonomy: TaxonomyDTO;
  editTaxonomy: (taxonomy: TaxonomyUpdateRequest) => void;
  deleteTaxonomy: (taxonomy: number) => void;
}

const ListItem: FC<Props> = ({ deleteTaxonomy, editTaxonomy, taxonomy }) => {
  return (
    <div className="list-row-item">
      <div className="list-row-item-content">
        <div className="list-row-title">{taxonomy.title}</div>
        <div className="list-row-controls">
          <div
            className="list-row-controls-edit"
            onClick={() =>
              editTaxonomy({
                id: taxonomy.id,
                title: taxonomy.title,
              })
            }>
            <NoteIcon
              width={10}
              height={10}
              style={{ marginBottom: 0 }}
              color={'#ffffff'}
            />
            <span>edit</span>
          </div>
          <div
            className="list-row-controls-delete"
            onClick={() => deleteTaxonomy(taxonomy.id)}>
            <TrashIconAlt fill={'#1F2531'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
