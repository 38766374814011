import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import uuid from 'utils/uuid';

/* modals & popups */
import PopupTag from 'components/modals/PopupTag';
import PopupTagGroup from 'components/modals/PopupTagGroup';

/* UI components */
import ListItem from './Controls/ListItem';
import Loader from 'components/Loader';

/* controllers */
import { ITagState } from 'controllers/tag/models';
import { TagApi } from 'controllers/tag/transport/tag.api';

/* constants */
import { GoBackIcon } from 'components/icons/GoBackIcon';
import { DownArrowIcon } from 'components/icons/DownArrowIcon';

/* types */
import { IStore } from 'controllers/store';
import { TagCategoryDTO, TagUpdateRequest } from '@ternala/voltore-types';
import {
  createTagAction,
  deleteTagAction,
  deleteTagCategoryAction,
  updateTagAction,
  updateTagCategoryAction,
} from 'controllers/tag/actions';
import OptionsMenu from 'components/UI/controls/OptionsMenu';
import PopupProceed from 'components/modals/PopupProceed';
import { Helmet } from 'react-helmet';
import CustomScrollbars from 'components/CustomScrollbars';
import { addNotification } from 'controllers/modals/actions';
import { NotificationTypeEnum } from 'controllers/modals/models.d';
import PopupDeleteGroup from 'components/modals/PopupDeleteTagGroup';
import { callbackTypeEnum, requestCallback } from '../../utils/requestCallback';
import { getAccessTokenUtil } from "../../utils/getAccessToken";

interface Props extends RouteComponentProps<{ id?: string }> {
  tagState: ITagState;
}

const CategoryInfo: FC<Props> = (props) => {
  const {
    match: {
      params: { id },
    },
    tagState: { groups, newGroups },
  } = props;

  const dispatch = useDispatch();
  const newGroup = !!newGroups?.find((group) => group.id === Number(id))?.id;
  const [deleteTagGroup, setDeleteTagGroup] = useState<number | undefined>(
    undefined,
  );
  const [deleteTag, setDeleteTag] = useState<number | undefined>(undefined);
  const [editTag, setEditTag] = useState<TagUpdateRequest | undefined>(
    undefined,
  );
  const [editGroup, setEditGroup] = useState<boolean>(false);
  const [createTag, setCreateTag] = useState<boolean>(false);
  const [createTagGroup, setCreateTagGroup] = useState<boolean>(false);
  const [group, setGroup] = useState<TagCategoryDTO>(
    newGroup && newGroups
      ? newGroups.filter((group) => group.id === Number(id))[0]
      : groups.filter((group) => group.id === Number(id))[0],
  );

  useEffect(() => {
    setGroup(
      newGroup && newGroups
        ? newGroups.filter((group) => group.id === Number(id))[0]
        : groups.filter((group) => group.id === Number(id))[0],
    );
  }, [groups, newGroups]);

  return group ? (
    <>
      <Helmet>
        <title>
          {group?.title ? group?.title : 'Tags'} | Voltore
          {process.env.REACT_APP_COMPANY_NAME
            ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
            : ''}
        </title>
        <meta name="description" content="Voltore application" />
      </Helmet>
      <CustomScrollbars id="tags-info-container">
        <div id="tag-info">
          <div
            className="button-go-back"
            onClick={() => dispatch(push(`/tag-groups`))}
            title="Back to tags">
            <GoBackIcon />
          </div>
          <div className="group-header">
            <span className="group-title">{group.title}</span>
            <OptionsMenu
              on={{
                edit: () => setEditGroup(true),
                delete: () => setDeleteTagGroup(group.id),
              }}
            />
          </div>
          <div className="header">
            <div className="header-title-container">
              <span className="title">Name</span>
              <DownArrowIcon />
            </div>
            <div className="add-new" onClick={() => setCreateTag(true)}>
              <span>add new</span>
            </div>
          </div>

          <div className="tag-info__content">
            {group.tags?.length ? (
              group.tags.map((tag) => (
                <ListItem
                  key={group.id + "_" + tag.id}
                  tag={tag}
                  groupId={group.id}
                  setIsEditTagOpen={setEditTag}
                  deleteTag={(id) => setDeleteTag(id)}
                />
              ))
            ) : (
              <span
                className="no-items-specified"
                style={{
                  marginLeft: ' 0px',
                  marginBottom: ' 0px',
                  marginTop: '15px',
                  marginRight: '0px',
                }}>
                No tags are specified.
              </span>
            )}
          </div>
        </div>
      </CustomScrollbars>
      {createTag ? (
        <PopupTag
          group={group}
          tags={group.tags}
          setGroupCreation={setCreateTagGroup}
          onClose={() => {
            setCreateTag(false);
          }}
          onCreate={(data, callback) =>
            dispatch(
              createTagAction.request({
                ...data,
                callback: (status: boolean) => {
                  requestCallback(dispatch, status, callbackTypeEnum.create)
                  if (callback) callback(status);
                },
              }),
            )
          }
        />
      ) : (
        ''
      )}

      {editGroup ? (
        <PopupTagGroup
          onClose={() => {
            setEditGroup(false);
          }}
          groups={groups}
          group={{ id: group.id, title: group.title }}
          onUpdate={(data, callback) =>
            dispatch(
              updateTagCategoryAction.request({
                ...data,
                callback: (status: boolean) => {
                  requestCallback(dispatch, status, callbackTypeEnum.update)
                  if (callback) callback(status);
                },
              }),
            )
          }
        />
      ) : (
        ''
      )}

      {deleteTagGroup ? (
        <PopupDeleteGroup
          id={deleteTagGroup}
          onClose={() => setDeleteTagGroup(undefined)}
          hasTags={!!group.tags.length}
          onSubmit={(id, callback) => {
            dispatch(
              deleteTagCategoryAction.request({
                id,
                callback: (status: boolean) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Successfully deleted',
                        type: NotificationTypeEnum.success,
                        title: 'Successfully deleted',
                      }),
                    );
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (callback) callback(status);
                },
                newTagCategoryId: 1,
              }),
            );
            dispatch(push(`/tag-groups`));
          }}
        />
      ) : (
        ''
      )}

      {deleteTag ? (
        <PopupProceed
          entityId={deleteTag}
          entityType={'tag'}
          setCreateEntity={setCreateTag}
          onClose={() => {
            setDeleteTag(undefined);
          }}
          groupId={group.id}
          onSubmit={(id, newTagId, callback) =>
            dispatch(
              deleteTagAction.request({
                id,
                callback: (status: boolean) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Successfully created',
                        type: NotificationTypeEnum.success,
                        title: 'Successfully created',
                      }),
                    );
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (callback) callback(status);
                },
                groupId: group.id,
                newTagId: Number(newTagId),
              }),
            )
          }
        />
      ) : (
        ''
      )}

      {editTag ? (
        <PopupTag
          tag={editTag}
          group={group}
          setGroupCreation={setCreateTagGroup}
          onClose={() => {
            setEditTag(undefined);
          }}
          onUpdate={(data, callback) =>
            dispatch(
              updateTagAction.request({
                ...data,
                callback: (status: boolean) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Successfully created',
                        type: NotificationTypeEnum.success,
                        title: 'Successfully created',
                      }),
                    );
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (callback) callback(status);
                },
                groupId: group.id,
              }),
            )
          }
        />
      ) : (
        ''
      )}

      {createTagGroup ? (
        <PopupTagGroup
          onClose={() => {
            setCreateTagGroup(false);
          }}
          onCreate={async function (data) {
            const token = await getAccessTokenUtil(dispatch);
            if (token) await TagApi.createTagCategory(data, token);
          }}
        />
      ) : (
        ''
      )}
    </>
  ) : (
    <div className="item-info__loader_info-tab" style={{ marginTop: '20px' }}>
      <Loader />
    </div>
  );
};

export default connect((store: IStore) => ({
  tagState: store.tag,
}))(CategoryInfo);
