import React, { FC, ReactNode, useState } from 'react';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';

import { CalendarIcon } from 'components/icons/CalendarIcon';
import { Color } from './shared/styles';
import style from './shared/styles/control.module.scss';
import { ReminderClockIcon } from 'components/icons/ReminderClockIcon';

interface Props {
  className?: string;
  containerClass?: string;
  containerStyle?: { [key: string]: number | string };
  iconStyles?: { [key: string]: number | string };
  name?: string;
  label?: string;
  placeholder?: string;
  value: MaterialUiPickersDate | number;
  onChange: (
    date: MaterialUiPickersDate,
    value?: string | null | undefined,
  ) => void;
  errorMessage?: string;
  onError?: (error: ReactNode, value: any) => void;
  onOpen?: () => void;
  onClose?: () => void;
  disableFuture?: boolean;
  darkTheme?: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  timeFormat?: boolean;
  yearOnly?: boolean;
}

export const DatePicker: FC<Props> = (props) => {
  const {
    label,
    value,
    onChange,
    disableFuture,
    darkTheme,
    required,
    disabled,
    readOnly,
    errorMessage,
    timeFormat,
    yearOnly,
    onOpen,
    onClose,
  } = props;

  const [placeholder, setPlaceholder] = useState<string | undefined>(
    props.placeholder,
  );

  return (
    <div
      className={props.containerClass || style.container}
      style={{ ...props.containerStyle }}>
      {label ? <label
        className={darkTheme ? style.label_dark : style.label}
        style={{ color: disabled ? Color.error : Color.label }}>
        {label}
        {required && <span className={style.required}>*</span>}
      </label> : ""}

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <>
        {timeFormat ? (
          <KeyboardTimePicker
            className={props.className}
            name={props.name}
            placeholder={placeholder}
            value={value ? new Date(value) : null}
            onChange={onChange}
            onFocus={() => setPlaceholder('')}
            onBlur={() => setPlaceholder(props.placeholder)}
            onOpen={onOpen}
            onClose={onClose}
            onError={props.onError}
            invalidDateMessage="Invalid date format"
            KeyboardButtonProps={{
              'aria-label': 'change time',
              style: {
                position: 'absolute',
                right: -7,
                bottom: -4,
                backgroundColor: 'transparent',
                ...props.iconStyles,
              },
            }}
            keyboardIcon={
              <ReminderClockIcon color={darkTheme ? 'white' : 'black'} />
            }
            disabled={disabled}
            readOnly={readOnly}
          />
        ) : (
          <KeyboardDatePicker
            className={props.className}
            name={props.name}
            disableFuture={disableFuture}
            openTo="year"
            format={yearOnly ? 'yyyy' : 'MM/dd/yyyy'}
            views={yearOnly ? ['year'] : ['year', 'month', 'date']}
            placeholder={placeholder}
            value={
              value
                ? yearOnly
                  ? new Date(Number(value), 0)
                  : new Date(value)
                : null
            }
            onChange={onChange}
            onFocus={() => setPlaceholder('')}
            onBlur={() => setPlaceholder(props.placeholder)}
            onOpen={onOpen}
            onClose={onClose}
            onError={props.onError}
            invalidDateMessage="Invalid date format"
            KeyboardButtonProps={{
              'aria-label': 'change date',
              style: {
                position: 'absolute',
                right: -7,
                bottom: -4,
                backgroundColor: 'transparent',
                ...props.iconStyles,
              },
            }}
            keyboardIcon={
              <CalendarIcon color={darkTheme ? 'white' : 'black'} />
            }
            disabled={disabled}
            readOnly={readOnly}
          />
        )}

        {errorMessage && typeof errorMessage === 'string' && (
          <div className={style.error}>{errorMessage}</div>
        )}
        </>
      </MuiPickersUtilsProvider>
    </div>
  );
};

DatePicker.defaultProps = {
  placeholder: 'Select date',
  disableFuture: false,
};

export default DatePicker;
