import React, { createRef, useEffect, useState } from "react";
import Moment from 'react-moment';
import { connect, useDispatch } from "react-redux";
import moment from 'moment';

import { ReminderDTO, UserRoleShortDTO } from "@ternala/voltore-types";
import { EntityEnum } from "@ternala/voltore-types/lib/constants";

import { CheckMarkIcon } from 'components/icons/Notes/CheckMarkIcon';

import { toggleReminderAction } from 'controllers/card/actions';
import { CardFilterEnum } from 'controllers/card/models.d';
import { IStore } from "controllers/store";

import PreviewLink from '../Preview/Controls/PreviewLink';

interface IProps {
  modal?: CardFilterEnum;
  reminder: ReminderDTO;
  history?: boolean;
  isMain?: boolean;
  roles?: UserRoleShortDTO[]
}

export const ReminderCard = ({ reminder, history, modal, roles, isMain }: IProps) => {
  const [checkMark, setCheckMark] = useState<boolean>(reminder.isComplete);
  const insideRef = createRef<HTMLDivElement>();
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    setCheckMark(reminder.isComplete);
  }, [reminder.isComplete]);

  // Change reminder state
  const dispatch = useDispatch();

  const changeDoneState = () => {
    // Once the request is sent, update state again
    if (checkMark) {
      setCheckMark(false);
    } else {
      setCheckMark(true);
    }

    // Send the actual request
    dispatch(
      toggleReminderAction.request({
        id: reminder.id,
      }),
    );
  };

  const generateUserTitle = (reminder: ReminderDTO) => {
    return `${reminder.role.user?.person?.firstName} ${
      reminder.role.user?.person?.lastName
    } ${reminder.role.title ? `| ${reminder.role.title}` : ''}`
  }

  useEffect(() => {
    const offsetHeight = insideRef.current?.offsetHeight;
    offsetHeight && height !== offsetHeight && setHeight(offsetHeight);
  }, []);

  return (
    <div className={"reminder__wrapper" +  (isMain ? " main": "")} style={{ maxHeight: history ? "initial" : (height || 'initial')}}>
      <div className={`reminder ${history ? 'history' : ''}`} ref={insideRef}>
        <div className="reminder-container">
          <div
            className={`check-button ${checkMark ? 'done' : 'not-done'}`}
            onClick={history ? () => {} : changeDoneState}>
            {checkMark ? (
              <CheckMarkIcon color="#FFFFFF" />
            ) : (
              <CheckMarkIcon color="#1F2531" />
            )}
          </div>
          <div className={`reminder-information ${checkMark ? 'completed' : ''}`}>
            {modal ? <PreviewLink
              modal={modal}
              id={reminder.role.user?.person?.id}
              type={EntityEnum.person}
              title={generateUserTitle(reminder)}
              className={roles?.find(role => role.id === reminder.role.id) ? "selected" : ""}
            /> : generateUserTitle(reminder)}
            <span className="details">
            at{' '}
              <span className="time">
              {moment(reminder?.date).format('L')},{' '}
                <Moment format="LT">{reminder?.date}</Moment>
            </span>{' '}
              {reminder.text}
          </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((store: IStore) => ({
  roles: store.auth.account?.roles
}))(ReminderCard)
