import React, { FC, useState, useEffect, MouseEvent, FormEvent } from 'react';
import { connect, useDispatch } from 'react-redux';

import PersonModal from 'components/modals/PersonModal';
import EnterpriseModal from 'components/modals/EnterpriseModal';
import PopupCreateTaxonomy from 'components/modals/PopupCreateTaxonomy';

/* UI components */
import Modal from 'components/Hocs/Portal';
import DatePicker from 'components/UI/controls/DatePicker';
import SelectSearch from 'components/UI/controls/SelectSearchCustom';
import SelectSearchNew from 'components/UI/controls/SelectSearchNew';
import Checkbox from 'components/UI/controls/Checkbox';
import TextArea from 'components/UI/controls/TextArea';
import Button from 'components/UI/controls/Button';

/* controllers */
import { getTaxonomiesByTypeAction } from 'controllers/taxonomy/actions';
import { createPersonAction } from 'controllers/person/actions';
import { createEnterpriseAction } from 'controllers/enterprise/actions';
import { createTaxonomyAction } from 'controllers/taxonomy/actions';

/* types */
import { IStore } from 'controllers/store';
import { IPersonState } from 'controllers/person/models';
import { IEnterpriseState } from 'controllers/enterprise/models';
import { IInvolvementState } from 'controllers/involvement/models';
import { ITaxonomyState } from 'controllers/taxonomy/models';
import {
  InvolvementFullDTO,
  InvolvementCreateRequest,
  InvolvementUpdateRequest, TaxonomyCreateResponse, PersonCreateResponse
} from "@ternala/voltore-types";
import { TaxonomyTypeSlug } from '@ternala/voltore-types/lib/constants';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { OptionType } from 'models';

/* constants & utils */
import { RequestActionEnum } from 'config/constants';
import {
  loadEnterprisesOptions,
  loadPersonsFormInvolvementsOptions
} from "config/constants/select-options";
import { isStartDateAfterEndDate } from 'utils/helper-functions';
import {
  createInvolvementAction,
  updateInvolvementAction,
} from 'controllers/involvement/actions';
import PopupTagGroup from './PopupTagGroup';
import { TagApi } from 'controllers/tag/transport/tag.api';
import { addNotification } from 'controllers/modals/actions';
import uuid from 'utils/uuid';
import { NotificationTypeEnum } from 'controllers/modals/models.d';
import CustomScrollbars from 'components/CustomScrollbars';
import { getAccessTokenUtil } from "../../utils/getAccessToken";

interface Props {
  person?: {
    id: number;
    involvementId?: number;
  };
  enterprise?: {
    id: number;
    involvementId?: number;
  };
  store: IStore;
  personsState: IPersonState;
  enterpriseState: IEnterpriseState;
  involvementState: IInvolvementState;
  taxonomyState: ITaxonomyState;
  onClose: () => void;
  onSubmit: (
    payload: (InvolvementCreateRequest | InvolvementUpdateRequest) & {
      callback?: Function;
    },
    requestAction: RequestActionEnum,
  ) => void;
}

const InvolvementModal: FC<Props> = (props) => {
  const {
    person,
    enterprise,
    store,
    personsState: { personData },
    enterpriseState: { enterpriseData },
    involvementState: { involvementData },
    taxonomyState: { taxonomies },
    onClose,
    onSubmit,
  } = props;

  const currentPerson = person?.id ? personData[person.id] : undefined;
  const currentEnterprise = enterprise?.id
    ? enterpriseData[enterprise?.id]
    : undefined;

  const dispatch = useDispatch();

  const [requestProcess, setRequestProcess] = useState<boolean>(false);
  const [involvement, setInvolvement] = useState<InvolvementFullDTO>();
  const [isTagCategoryCreationOpen, setIsTagCategoryCreationOpen] =
    useState<boolean>(false);
  const [personName, setPersonName] = useState<OptionType | undefined>();
  const [enterpriseName, setEnterpriseName] = useState<
    OptionType | undefined
  >();
  const [positionOrRole, setPositionOrRole] = useState<
    OptionType | undefined
  >();
  const [isPrincipal, setIsPrincipal] = useState<boolean>(false);
  const [isDecisionMaker, setIsDecisionMaker] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<MaterialUiPickersDate>(null);
  const [endDate, setEndDate] = useState<MaterialUiPickersDate>(null);

  const [description, setDescription] = useState<string>('');
  const [dateError, setDateError] = useState<string>('');

  const [isPersonModalOpen, setIsPersonModalOpen] = useState<boolean>(false);
  const [isEnterpriseModalOpen, setIsEnterpriseModalOpen] =
    useState<boolean>(false);
  const [
    isPopupCreateInvolvementTypeOpen,
    setIsPopupCreateInvolvementTypeOpen,
  ] = useState<boolean>(false);

  useEffect(() => {
    /**
     * Set editable person when new involvement is creating on persons page
     * or set editable enterprise when new involvement is creating on enterprises page
     */
    if (currentPerson) {
      setPersonName({
        label: `${currentPerson?.firstName} ${currentPerson?.lastName}`,
        value: currentPerson?.id,
      });
    }
    if (currentEnterprise) {
      setEnterpriseName({
        label: currentEnterprise?.title,
        value: currentEnterprise?.id,
      });
    }

    let involvement: InvolvementFullDTO | undefined;

    if (person && person.involvementId) {
      involvement = involvementData[person.involvementId];
      setPersonName({
        label: `${currentPerson?.firstName} ${currentPerson?.lastName}`,
        value: currentPerson?.id,
      });
    }

    if (enterprise && enterprise.involvementId) {
      involvement = involvementData[enterprise.involvementId];
    }

    if (involvement) {
      /**
       * setting editable involvement in state just for one reason:
       * determine whether this is creation or editing and conditionally render the respective title
       */
      setInvolvement(involvement);

      /**
       * on persons page we use involvement.enterprise field
       * but on enterprise such field is absent so we use values from variable in root
       */
      if (!currentPerson) {
        setPersonName({
          label: `${involvement?.person?.firstName} ${involvement?.person?.lastName}`,
          value: involvement?.person?.id,
        });
      }
      setEnterpriseName({
        label: involvement.enterprise?.title || currentEnterprise?.title,
        value: involvement.enterprise?.id || currentEnterprise?.id,
      });
      setPositionOrRole({
        label: involvement?.involvementType?.title,
        value: involvement?.involvementType.id,
      });
      setIsPrincipal(involvement.owner);
      setIsDecisionMaker(involvement.decisionMaker);
      involvement.startDate && setStartDate(involvement.startDate);
      involvement.endDate && setEndDate(involvement.endDate);
      involvement.description && setDescription(involvement.description);
    }
  }, []);

  useEffect(() => {
    if (isStartDateAfterEndDate({ startDate, endDate })) {
      setDateError('Start date can’t be after end date');
    } else {
      setDateError('');
    }
  }, [startDate, endDate]);

  useEffect(() => {
    dispatch(
      getTaxonomiesByTypeAction.request({
        type: TaxonomyTypeSlug.involvementType,
      }),
    );
  }, []);

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  const submitData = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    setRequestProcess(true);
    if (involvement?.id) {
      const payload: Omit<
        ReturnType<typeof updateInvolvementAction.request>['payload'],
        'additionalFields'
      > = {
        id: involvement.id,
        ...createPayload(),
        callback: (status: boolean) => {
          if (status) onClose();
          setRequestProcess(false);
        },
      };
      onSubmit(payload, RequestActionEnum.UPDATE);
    } else {
      const payload: Omit<
        ReturnType<typeof createInvolvementAction.request>['payload'],
        'additionalFields'
      > = {
        ...createPayload(),
        callback: (status: boolean) => {
          if (status) onClose();
          setRequestProcess(false);
        },
      };
      onSubmit(payload, RequestActionEnum.CREATE);
    }
  };

  const createPayload = () => {
    const payload: InvolvementCreateRequest = {
      person: Number(personName?.value),
      enterprise: Number(enterpriseName?.value),

      [TaxonomyTypeSlug.involvementType]: Number(positionOrRole?.value),
    };

    if (isPrincipal) payload.owner = isPrincipal;
    if (isDecisionMaker) payload.decisionMaker = isDecisionMaker;
    if (startDate) payload.startDate = startDate;
    if (endDate) payload.endDate = endDate;
    if (description) payload.description = description;

    return payload;
  };

  return (
    <>
      <Modal>
        <div className="modal">
          <div className="scroll-area">
            <CustomScrollbars
              style={{
                width: '700px',
                height: '100%',
                display: 'flex',
              }}>
              <form className="modal-content" onSubmit={submitData}>
                <div className="modal__close" onClick={onClose} title="Close" />

                <div className="modal-title">
                  {involvement ? 'edit' : 'create'} person's involvement
                </div>

                <div className="flex-container">
                  <div>
                    <SelectSearchNew
                      label="person"
                      asyncOptions={async (searchParams) => {
                        const token = await getAccessTokenUtil(dispatch);
                        if(token){
                          return loadPersonsFormInvolvementsOptions(searchParams, token)
                        }
                        return []
                      }}
                      value={personName && [personName]}
                      onChange={(option) => setPersonName(option?.[0])}
                      creation={{
                        label: 'Create new person',
                        onClick: () => setIsPersonModalOpen(true),
                      }}
                      required
                    />

                    <SelectSearchNew
                      label="enterprise"
                      asyncOptions={async (searchParams) => {
                        const token = await getAccessTokenUtil(dispatch);
                        if(token){
                          return loadEnterprisesOptions(searchParams, token)
                        }
                        return []
                      }}
                      searchByFields={['title', 'type']}
                      value={enterpriseName && [enterpriseName]}
                      onChange={(option) => setEnterpriseName(option?.[0])}
                      creation={{
                        label: 'Create new enterprise',
                        onClick: () => setIsEnterpriseModalOpen(true),
                      }}
                      required
                    />

                    <SelectSearch
                      label="position or role"
                      options={taxonomies?.[
                        TaxonomyTypeSlug.involvementType
                      ]?.map((taxonomy) => ({
                        value: taxonomy.id,
                        label: taxonomy.title,
                      }))}
                      value={positionOrRole}
                      onChange={(option) => setPositionOrRole(option)}
                      creation={{
                        label: 'Create new position or role',
                        onClick: () =>
                          setIsPopupCreateInvolvementTypeOpen(true),
                      }}
                      required
                    />

                    <div className="checkboxes-container">
                      <Checkbox
                        label="Principal"
                        checked={isPrincipal}
                        onChange={() => setIsPrincipal(!isPrincipal)}
                      />

                      <Checkbox
                        label="Decision Maker"
                        checked={isPrincipal && isDecisionMaker}
                        onChange={() => setIsDecisionMaker(!isDecisionMaker)}
                        disabled={!isPrincipal}
                      />
                    </div>

                    <DatePicker
                      label="start date"
                      value={startDate}
                      onChange={(date: MaterialUiPickersDate) =>
                        setStartDate(date)
                      }
                      errorMessage={dateError}
                    />

                    <DatePicker
                      label="end date"
                      value={endDate}
                      onChange={(date: MaterialUiPickersDate) =>
                        setEndDate(date)
                      }
                    />
                  </div>

                  <TextArea
                    label="description"
                    placeholder="Type here"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <footer className="modal-footer">
                  <Button variant="light" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="dark"
                    onClick={submitData}
                    disabled={
                      !personName ||
                      !enterpriseName ||
                      !positionOrRole ||
                      Boolean(dateError) ||
                      requestProcess
                    }>
                    Save
                  </Button>
                </footer>
              </form>
            </CustomScrollbars>
          </div>

          <div className="modal-overlay" onClick={onClose} />
        </div>
      </Modal>

      {isPersonModalOpen && (
        <PersonModal
          onClose={() => setIsPersonModalOpen(false)}
          onSubmit={(payload) =>
            dispatch(
              createPersonAction.request({
                ...payload,
                callback: (res: false | PersonCreateResponse) => {
                  if (res) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`,
                      }),
                    );
                    setPersonName({
                      label: `${payload.firstName} ${payload.lastName}`,
                      value: res.id,
                    });
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (payload.callback) payload.callback(res);
                },
              }),
            )
          }
        />
      )}

      {isEnterpriseModalOpen && (
        <EnterpriseModal
          onClose={() => setIsEnterpriseModalOpen(false)}
          onSubmit={(payload) =>
            dispatch(
              createEnterpriseAction.request({
                ...payload,
                callback: (status: boolean, id: number) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`,
                      }),
                    );
                    setEnterpriseName({
                      label: payload.title,
                      value: id,
                    });
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                    setIsEnterpriseModalOpen(false);
                  }
                  if (payload.callback) payload.callback(status);
                },
              }),
            )
          }
        />
      )}

      {isTagCategoryCreationOpen ? (
        <PopupTagGroup
          onClose={() => {
            setIsTagCategoryCreationOpen(false);
          }}
          onCreate={async function (data) {
            if (store) {
              const token = await getAccessTokenUtil(dispatch);
              if (token) await TagApi.createTagCategory(data, token);
              setIsTagCategoryCreationOpen(false);
            }
          }}
        />
      ) : (
        ''
      )}
      {isPopupCreateInvolvementTypeOpen && (
        <PopupCreateTaxonomy
          title="Create position or role"
          onClose={() => setIsPopupCreateInvolvementTypeOpen(false)}
          onSubmit={(title, callback) => {
            dispatch(
              createTaxonomyAction.request({
                title,
                type: TaxonomyTypeSlug.involvementType,
                callback: (res: false | TaxonomyCreateResponse) => {
                  if (res) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`,
                      }),
                    );
                    setPositionOrRole({
                      label: title,
                      value: res.id,
                    });
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (callback) callback(res);
                },
              }),
            );
            setIsPopupCreateInvolvementTypeOpen(false);
          }}
        />
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  store,
  personsState: store.person,
  involvementState: store.involvement,
  enterpriseState: store.enterprise,
  taxonomyState: store.taxonomy,
}))(InvolvementModal);
