import { Dispatch } from "react";
import { getAccessTokenAction, logoutAction } from "../controllers/auth/actions";

export const getAccessTokenUtil = (dispatch: Dispatch<any>): Promise<string> => {
  return new Promise((resolve, reject) => {
    dispatch(getAccessTokenAction({
      callback: (res) => {
        if (typeof res === 'string') {
          resolve(res)
        } else {
          dispatch(logoutAction.request({}, {}))
          reject(res)
        }
      },
    }));
  })
}
