
import React, {
  FC,
  useState,
  useEffect,
  useRef,
  RefObject,
  ReactNode,
  useContext,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';

/* components */
import InvolvementsListItem from './InvolvementsListItem';
import PersonInvolvementModal from 'components/modals/InvolvementModal';
import Loader from 'components/Loader';
import Button from 'components/UI/controls/Button';

/* controllers */
import {
  getInvolvementsAction,
  createInvolvementAction,
} from 'controllers/involvement/actions';

/* constants */
import { LOADED_ITEMS_LIMIT, ITEMS_LOADER_HEIGHT } from 'config';
import { enterprisesTabs } from 'config/constants/tabs';
import { EntityEnum, sortType } from "@ternala/voltore-types/lib/constants";

/* types */
import { IStore } from 'controllers/store';
import { RouterState } from 'connected-react-router';
import { IEnterpriseState } from 'controllers/enterprise/models';
import { IInvolvementState } from 'controllers/involvement/models';
import { addNotification } from 'controllers/modals/actions';
import uuid from 'utils/uuid';
import { NotificationTypeEnum } from 'controllers/modals/models.d';
import { Helmet } from 'react-helmet';
import CustomScrollbars from 'components/CustomScrollbars';

interface Props {
  enterpriseId: number;
  tabName: string;
  routerState: RouterState;
  enterpriseState: IEnterpriseState;
  involvementState: IInvolvementState;
  children?: ReactNode;
}

enum Filter {
  CURRENT = 'current',
  PAST = 'past',
}

const EnterpriseInvolvementsList: FC<Props> = (props) => {
  const {
    enterpriseId,
    tabName,
    routerState: {
      location: { pathname },
    },
    involvementState: { involvementData },
    enterpriseState: { enterpriseData },
    children,
  } = props;

  const dispatch = useDispatch();

  // Location (filters)
  // const location = useContext(LocationContext);

  // useEffect(() => {
  //   location.setCardEntityData({
  //     ids: [enterpriseId],
  //     type: EntityEnum.enterprise,
  //   });
  // }, [tabName]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<Filter>(Filter.CURRENT);
  const [allInvolvementsLoaded, setAllInvolvementsLoaded] = useState<
    boolean | undefined
  >(true);
  const [involvementsLoading, setInvolvementsLoading] =
    useState<boolean>(false);

  const involvementsRef = useRef() as RefObject<Scrollbars>;

  const currentEnterprise = enterpriseData[enterpriseId];
  const involvementState = currentEnterprise?.involvementState;

  useEffect(() => {
    setIsLoading(true);
    loadInvolvements(() => setIsLoading(false));
    // dispatch(push(`/${PageEnum.ENTERPRISES}/${enterpriseId}/${enterprisesTabs.people.path}`))
  }, [activeFilter]);

  useEffect(() => {
    switch (activeFilter) {
      case Filter.CURRENT:
        setAllInvolvementsLoaded(
          involvementState?.currentInvolvements?.isAll ||
            (involvementState?.currentInvolvements?.items &&
              involvementState.currentInvolvements.items.length <
                LOADED_ITEMS_LIMIT),
        );
        break;
      case Filter.PAST:
        setAllInvolvementsLoaded(
          involvementState?.pastInvolvements?.isAll ||
            (involvementState?.pastInvolvements?.items &&
              involvementState.pastInvolvements.items.length <
                LOADED_ITEMS_LIMIT),
        );
        break;
    }
  }, [
    involvementState?.currentInvolvements,
    involvementState?.pastInvolvements,
  ]);

  const loadInvolvements = (callback?: Function) => {
    let offset;
    switch (activeFilter) {
      case Filter.CURRENT:
        offset = involvementState?.currentInvolvements?.items.length || 0;
        break;
      case Filter.PAST:
        offset = involvementState?.pastInvolvements?.items.length || 0;
        break;
      default:
        offset = 0;
    }

    const searchParams = {
      limit: LOADED_ITEMS_LIMIT,
      offset,
      sortType: sortType.desc,
      timeMarker: activeFilter,
      additionalFields: {
        enterprise: enterpriseId,
        timeMarker: activeFilter,
      },
    };

    dispatch(getInvolvementsAction.request({ ...searchParams, callback }));
  };

  const loadMoreInvolvements = () => {
    if (involvementsRef.current) {
      const { getClientHeight, getScrollTop, getScrollHeight, scrollToBottom } =
        involvementsRef.current;

      if (
        !allInvolvementsLoaded &&
        !involvementsLoading &&
        getClientHeight() + getScrollTop() >=
          getScrollHeight() - ITEMS_LOADER_HEIGHT
      ) {
        setInvolvementsLoading(true);
        scrollToBottom();
        loadInvolvements(() => setInvolvementsLoading(false));
      }
    }
  };

  const involvements = (
    activeFilter === Filter.CURRENT
      ? involvementState?.currentInvolvements &&
        Object.values(
          involvementState.currentInvolvements.items.map(
            (id) => involvementData[id],
          ),
        )
      : involvementState?.pastInvolvements &&
        Object.values(
          involvementState.pastInvolvements.items.map(
            (id) => involvementData[id],
          ),
        )
  )?.filter((involvement) => involvement.person);

  return (
    <>
      <div className="item-info__tab persons-tab items-container ">
        <Helmet>
          <title>
            People | Voltore
            {process.env.REACT_APP_COMPANY_NAME
              ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        <div
          className={
            'enterprise-involvements involvement-items' +
            (!pathname.endsWith(enterprisesTabs.people.path)
              ? ' enterprise-involvements_hidden'
              : '')
          }>
          <header className="enterprise-involvements__controls controls">
            <div className="involvements-filters">
              <span
                className={
                  'involvements-filters__toggle' +
                  (activeFilter === Filter.CURRENT
                    ? ' involvements-filters__toggle_active'
                    : '')
                }
                onClick={() => {
                  if (activeFilter !== Filter.CURRENT)
                    setActiveFilter(Filter.CURRENT);
                }}>
                {Filter.CURRENT}
              </span>

              <span
                className={
                  'involvements-filters__toggle' +
                  (activeFilter === Filter.PAST
                    ? ' involvements-filters__toggle_active'
                    : '')
                }
                onClick={() => {
                  if (activeFilter !== Filter.PAST)
                    setActiveFilter(Filter.PAST);
                }}>
                {Filter.PAST}
              </span>
            </div>

            <Button
              className="button-add-new"
              variant="light"
              onClick={() => setIsModalOpen(true)}>
              Add new
            </Button>
          </header>

          {isLoading ? (
            <div className="involvement-items-container__loader enterprise-involvements-container__loader">
              <Loader />
            </div>
          ) : (
            <CustomScrollbars
              ref={involvementsRef}
              onScroll={loadMoreInvolvements}
              id="enterprise-involvements-scrollbars">
              <div className="enterprise-involvements-list items-list">
                {involvements?.map((involvement) => (
                  <InvolvementsListItem
                    key={involvement.id}
                    id={enterpriseId}
                    tabName={tabName}
                    involvementId={involvement.id}
                    user={{
                      decisionMaker: involvement.decisionMaker,
                      owner: involvement.owner,
                      position: involvement.involvementType?.title,
                      employeeName: `${involvement.person?.firstName}${involvement.person?.middleName ? " " + involvement.person?.middleName : ""} ${involvement.person?.lastName}`,
                      startDate: involvement.startDate,
                      endDate: involvement.endDate,
                      nickname: involvement.person?.nickName,
                    }}
                  />
                ))}

                {!allInvolvementsLoaded && allInvolvementsLoaded !== undefined && (
                  <div className="items-list__loader">
                    <Loader />
                  </div>
                )}
              </div>
            </CustomScrollbars>
          )}
        </div>

        {pathname.endsWith(enterprisesTabs.people.title) && (
          <div className="empty-container" />
        )}

        {children}
      </div>

      {isModalOpen && (
        <PersonInvolvementModal
          enterprise={{ id: enterpriseId }}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(payload) => {
            dispatch(
              createInvolvementAction.request({
                ...payload,
                additionalFields: {
                  person: payload.person,
                  enterprise: enterpriseId,
                  timeMarker: activeFilter,
                },
                callback: (status: boolean) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`,
                      }),
                    );
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (payload.callback) payload.callback(status);
                },
              }),
            );
          }}
        />
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  routerState: store.router,
  enterpriseState: store.enterprise,
  involvementState: store.involvement,
}))(EnterpriseInvolvementsList);
