import React from 'react';
import { config } from '@react-spring/core';
import { animated, useTransition } from 'react-spring';

interface IProps {
  condition: boolean;
  children: JSX.Element | JSX.Element[];
}
const TransitionCard = ({ condition, children }: IProps) => {
  const transitions = useTransition(condition, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  });
  return transitions(
    (styles, item) =>
      item && (
        <animated.div style={styles} className="transaction-card">
          {children}
        </animated.div>
      ),
  );
};

export default TransitionCard;
