import { getUniqueId } from 'utils/helper-functions';

type InputType = 'text' | 'tel' | 'url' | 'number' | 'email' | 'password';
export type ErrorType =
  | 'required'
  | 'email'
  | 'password'
  | 'minlength'
  | 'maxlength'
  | 'pattern'
  | 'passwordsMismatch';
export type ErrorsType = { [key in ErrorType]: boolean };
type ValidatorsType = Array<
  (
    value: string,
  ) => boolean | ((param: number | RegExp) => (value: string) => boolean)
>;

interface iControl {
  type?: InputType;
  id?: number | string;
  label?: string;
  placeholder?: string;
  value?: string;
  error?: string;
  touched?: boolean;
  untouched?: boolean;
  valid?: boolean;
  invalid?: boolean;
  required?: boolean;
  validators?: ValidatorsType;
}

abstract class Validators {
  public static required(value: string): boolean {
    return Boolean(value.trim());
  }

  public static email(value: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    return re.test(value.toLowerCase());
  }

  public static password(value: string): boolean {
    const re = /[\w[\]`!@#$%^&*()={}:;<>+'-]*/;
    return re.test(value.toLowerCase());
  }

  public static Name(value: string): boolean {
    const re = /^[- a-zA-Zа-яА-яїЇіІєЄьЬ#№]+$/;
    return re.test(value.toLowerCase());
  }

  public static minLength(minLength: number): (value: string) => boolean {
    return function minlength(value: string): boolean {
      return value.trim().length >= minLength;
    };
  }

  public static maxLength(maxLength: number): (value: string) => boolean {
    return function maxlength(value: string): boolean {
      return value.trim().length <= maxLength;
    };
  }

  public static pattern(re: RegExp): (value: string) => boolean {
    return function pattern(value: string): boolean {
      return re.test(String(value).toLowerCase());
    };
  }
}

abstract class AbstractControl {
  /* custom */
  validators?: ValidatorsType;
  type?: InputType;
  id?: number | string;
  label?: string;
  placeholder?: string | undefined;
  error?: string;
  /* default */
  value: string = '';
  touched: boolean = false;
  untouched: boolean = true;
  pristine: boolean = true;
  dirty: boolean = false;
  valid: boolean = true;
  invalid: boolean = false;
  required: boolean = false;
  errors: ErrorsType | {} = {};
  errorMessages: string[] = [];

  update: (value?: string) => void = () => undefined;
  validate: (value?: string) => void = () => undefined;
  _generateErrorMessages: () => string[] = () => [];
}

class FormControl extends AbstractControl {
  constructor(
    control: iControl,
    validators?: ValidatorsType,
    errorMessages?: { [key in ErrorType]?: string },
  ) {
    super();

    this.id = control.id || getUniqueId();
    this.type = control.type || 'text';
    this.label = control.label || '';
    this.placeholder = control.placeholder || '';
    this.required = control.required || false;
    this.error = control.error || undefined;
    this.valid = !control.required;
    this.invalid = !!control.required;

    this.update = function (value?: string): void {
      this.touched = true;
      this.untouched = false;
      this.pristine = !this.value.length;
      this.dirty = Boolean(this.value.length);

      if (value !== undefined) {
        this.value = value;
      }
    };

    this.validate = function (value?: string): void {
      this.update(value);

      if (!validators) {
        return;
      }

      let isValid: boolean = true;
      for (const validator of validators) {
        isValid = validator(this.value) && isValid;

        if (validator(this.value)) {
          (this.errors as ErrorsType)[validator.name as ErrorType] = false;

          if (errorMessages) {
            this.errorMessages = this._generateErrorMessages();
          }
        } else {
          (this.errors as ErrorsType)[validator.name as ErrorType] = true;
        }
      }
      this.valid = isValid;
      this.invalid = !isValid;
    };

    this._generateErrorMessages = function (): string[] {
      if (!errorMessages) {
        return [];
      }

      return Object.entries(this.errors)
        .map((entry) => {
          const [name, invalid] = entry;

          if (invalid) {
            if (this.value && name !== 'required') {
              return errorMessages[name as ErrorType];
            }
            if (!this.value && name === 'required') {
              return errorMessages.required;
            }
          }
          return undefined;
        })
        .filter((message) => message) as string[];
    };
  }
}

abstract class AbstractControlsGroup {
  controls: { [key: string]: AbstractControl } = {};
  valid: boolean = true;
  invalid: boolean = false;

  validate: () => void = () => undefined;
  validateConfirmation: (primaryField: string, secondaryField: string) => void =
    () => undefined;
}

class FormGroup extends AbstractControlsGroup {
  constructor(controls: { [key: string]: AbstractControl }) {
    super();

    this.controls = controls;
    this.valid = true;
    this.invalid = false;

    this.validate = function (): void {
      let isFormValid: boolean = true;

      for (const control in this.controls) {
        if (this.controls[control].touched) {
          this.controls[control].validate(this.controls[control].value);
        }
        isFormValid = this.controls[control].valid && isFormValid;
      }

      this.valid = isFormValid;
      this.invalid = !isFormValid;
    };

    this.validateConfirmation = function (
      primaryField: string,
      secondaryField: string,
      errorMessageKey: string = 'passwordsMismatch',
    ): void {
      if (
        this.controls[primaryField].value &&
        this.controls[secondaryField].value &&
        this.controls[primaryField].value !==
          this.controls[secondaryField].value
      ) {
        (this.controls[primaryField].errors as ErrorsType)[
          errorMessageKey as ErrorType
        ] = true;
        this.controls[primaryField].valid = false;
        this.controls[primaryField].invalid = true;

        (this.controls[secondaryField].errors as ErrorsType)[
          errorMessageKey as ErrorType
        ] = true;
        this.controls[secondaryField].valid = false;
        this.controls[secondaryField].invalid = true;

        this.valid = false;
        this.invalid = true;
      } else {
        (this.controls[primaryField].errors as ErrorsType)[
          errorMessageKey as ErrorType
        ] = false;
        this.controls[primaryField].valid = true;
        this.controls[primaryField].invalid = false;

        (this.controls[secondaryField].errors as ErrorsType)[
          errorMessageKey as ErrorType
        ] = false;
        this.controls[secondaryField].valid = true;
        this.controls[secondaryField].invalid = false;

        this.valid = true;
        this.invalid = false;
      }
    };

    this.validate();
  }
}

export { Validators, FormControl, FormGroup };
