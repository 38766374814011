import React, { FC, FormEvent, MouseEvent, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { omit } from 'lodash';

/* components */
import PopupTag from './PopupTag';
import Modal from 'components/Hocs/Portal';
import PopupTagGroup from './PopupTagGroup';
import Input from 'components/UI/controls/Input';
import Button from 'components/UI/controls/Button';
import Logo from 'components/UI/controls/InputImage';
import TextArea from 'components/UI/controls/TextArea';
import CustomScrollbars from 'components/CustomScrollbars';
import SelectSearch from 'components/UI/controls/SelectSearchCustom';
import MultiField from 'components/UI/controls/MultiField/MultiField';
import PopupCreateTaxonomy from 'components/modals/PopupCreateTaxonomy';
import SelectMultiSearch from 'components/UI/controls/SelectMultiSearch';

/* controllers */
import {
  createTaxonomyAction,
  getTaxonomiesByTypeAction,
} from 'controllers/taxonomy/actions';
import {
  createEnterpriseAction,
  updateEnterpriseAction,
} from 'controllers/enterprise/actions';

/* types & interfaces */
import { IStore } from 'controllers/store';
import {
  EnterpriseCreateRequestExpanded,
  EnterpriseUpdateRequestExpanded,
  IEnterpriseState,
} from 'controllers/enterprise/models';
import { ITaxonomyState } from 'controllers/taxonomy/models';

/* constants */
import {
  loadEnterprisesOptionsWithPagination,
  RequestActionEnum,
} from 'config/constants';
import { TaxonomyTypeSlug } from '@ternala/voltore-types/lib/constants';

/* types */
import { OptionType } from 'models';
import { TagApi } from 'controllers/tag/transport/tag.api';
import { addNotification } from 'controllers/modals/actions';
import { loadTagsOptions } from 'config/constants/select-options';
import { NotificationTypeEnum } from 'controllers/modals/models.d';
import {
  EmailUpdateDTO,
  PhoneUpdateDTO,
  TaxonomyCreateResponse,
} from '@ternala/voltore-types';

import { AddressShortDTO } from '@ternala/voltore-types/lib/modules/address/addressShort.dto';
import GoogleMapSearchAutoComplete from '../UI/controls/GoogleMapsSearch/AutoComplete';
import { getAccessTokenUtil, uuid, getMainImage } from 'utils';

interface IParentEnterpriseModal {
  count: number;
  onClose: () => void;
  onSubmit: (
    payload: EnterpriseCreateRequestExpanded & { callback?: Function },
  ) => void;
}

interface Props {
  enterpriseId?: number;
  enterpriseState: IEnterpriseState;
  taxonomyState: ITaxonomyState;
  searchState?: any;
  loadEnterprises?: () => void;
  onClose: () => void;
  onSubmit: (
    payload: (
      | EnterpriseCreateRequestExpanded
      | EnterpriseUpdateRequestExpanded
    ) & { callback?: Function },
    requestAction: RequestActionEnum,
  ) => void;
}

const EnterpriseModal: FC<Props> = (props) => {
  const {
    enterpriseId,
    enterpriseState: { enterpriseData },
    taxonomyState: { taxonomies },
    loadEnterprises,
    onClose,
    onSubmit,
  } = props;

  const editableEnterprise = enterpriseId
    ? enterpriseData[enterpriseId]
    : undefined;

  const dispatch = useDispatch();

  const [requestProcess, setRequestProcess] = useState<boolean>(false);
  const [isCreateEnterpriseTypePopupOpen, setIsCreateEnterpriseTypePopupOpen] =
    useState<boolean>(false);
  const [parentEnterpriseModalsCount, setParentEnterpriseModalsCount] =
    useState<number>(0);
  const [parentEnterpriseModals, setParentEnterpriseModals] = useState<
    IParentEnterpriseModal[]
  >([]);
  const [isTagCreationOpen, setIsTagCreationOpen] = useState<boolean>(false);
  const [isTagCategoryCreationOpen, setIsTagCategoryCreationOpen] =
    useState<boolean>(false);

  const [logo, setLogo] = useState<File | null>();
  const [imageUrl, setImageUrl] = useState<string>(
    getMainImage(editableEnterprise?.images)?.url || '',
  );

  const [enterpriseName, setEnterpriseName] = useState<string>(
    editableEnterprise?.title || '',
  );
  const [enterpriseType, setEnterpriseType] = useState<OptionType | undefined>(
    {
      label: editableEnterprise?.enterpriseType?.title,
      value: editableEnterprise?.enterpriseType?.id,
    } || undefined,
  );
  const [address, setAddress] = useState<AddressShortDTO | undefined>(
    editableEnterprise?.officeAddress,
  );

  const parents =
    editableEnterprise &&
    'parents' in editableEnterprise &&
    editableEnterprise.parents
      ? editableEnterprise?.parents
      : [];

  const [parentEnterprises, setParentEnterprises] = useState<
    OptionType[] | undefined
  >(
    parents.map((parent) => ({
      label: parent.title,
      value: parent.id,
    })) || [],
  );

  const [website, setWebsite] = useState<string>(
    editableEnterprise?.website || '',
  );
  const [tags, setTags] = useState<OptionType[] | undefined>(
    editableEnterprise?.cardTagConnects?.map((card) => ({
      label: card.tag?.title,
      value: card.tag?.id,
    })) || [],
  );
  const [emails, setEmails] = useState<(EmailUpdateDTO & { uuid?: string })[]>(
    editableEnterprise?.emails || [],
  );
  const [phones, setPhones] = useState<(PhoneUpdateDTO & { uuid?: string })[]>(
    editableEnterprise?.phones || [],
  );
  const [description, setDescription] = useState<string>(
    editableEnterprise?.description || '',
  );

  useEffect(() => {
    dispatch(
      getTaxonomiesByTypeAction.request({
        type: TaxonomyTypeSlug.enterpriseType,
      }),
    );
  }, []);

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onClose();
  };

  const createParentEnterprise = () => {
    setParentEnterpriseModalsCount(parentEnterpriseModalsCount + 1);
    const newParentEnterpriseModal = {
      count: parentEnterpriseModalsCount + 1,
      onClose: () => {
        const updatedParentEnterpriseModals = parentEnterpriseModals.filter(
          (modal) => modal.count !== parentEnterpriseModalsCount + 1,
        );
        setParentEnterpriseModals(updatedParentEnterpriseModals);
      },
      onSubmit: (payload: EnterpriseCreateRequestExpanded) =>
        dispatch(createEnterpriseAction.request(payload)),
    };
    setParentEnterpriseModals([
      ...parentEnterpriseModals,
      newParentEnterpriseModal,
    ]);
  };

  const submitData = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    setRequestProcess(true);
    if (editableEnterprise && enterpriseId) {
      const payload: ReturnType<
        typeof updateEnterpriseAction.request
      >['payload'] = {
        ...createPayload(),
        id: Number(enterpriseId),
        callback: (status: boolean) => {
          if (status) onClose();
          setRequestProcess(false);
        },
      };

      if (!imageUrl) {
        payload.isImageDeleted = true;
      }

      onSubmit(payload, RequestActionEnum.UPDATE);
    } else {
      const payload: ReturnType<
        typeof createEnterpriseAction.request
      >['payload'] = {
        ...createPayload(),
        callback: onClose,
        loadCallback: loadEnterprises,
      };
      onSubmit(payload, RequestActionEnum.CREATE);
    }
  };

  const createPayload = (): EnterpriseCreateRequestExpanded => {
    const payload: EnterpriseCreateRequestExpanded = {
      title: enterpriseName.trim(),
      [TaxonomyTypeSlug.enterpriseType]: Number(enterpriseType?.value),
    };

    if (description) payload.description = description;
    if (logo) payload.image = logo;
    if (parentEnterprises?.length)
      payload.parents = parentEnterprises.map((item) => Number(item.value));
    if (address && address.address && address.address !== ' ')
      payload.officeAddress = address;
    if (website) payload.website = website.trim();
    if (emails.length)
      payload.emails = emails.map((email) => omit(email, ['uuid']));
    if (phones.length)
      payload.phones = phones.map((phone) => omit(phone, ['uuid']));
    if (tags) payload.tags = tags.map((item) => item.value as number).filter(Boolean);
    return payload;
  };

  return (
    <>
      <Modal>
        <div className="modal">
          <div className="scroll-area">
            <CustomScrollbars
              style={{
                width: '700px',
                height: '100%',
              }}
              renderView={(props) => (
                <div
                  {...props}
                  className={'scroll-area'}
                  style={{
                    position: 'absolute',
                    inset: '0px',
                    overflowY: 'scroll',
                    marginRight: `${
                      navigator.platform === 'MacIntel' ? '-17px' : '-35px'
                    }`,
                    marginBottom: '-17px',
                  }}
                />
              )}>
              <form className="modal-content" onSubmit={submitData}>
                <div className="modal__close" onClick={onClose} title="Close" />
                <div className="modal-title">
                  {enterpriseId ? 'edit' : 'create'} enterprise
                </div>

                <Logo
                  imageUrl={imageUrl}
                  onChange={(image: File | null) => setLogo(image)}
                  onDelete={() => setImageUrl('')}
                />

                <div className="flex-container">
                  <div>
                    <Input
                      label="name"
                      placeholder="Enterprise name or self-employed person's name"
                      value={enterpriseName}
                      onChange={(e) => setEnterpriseName(e.target.value)}
                      required
                    />

                    <SelectSearch
                      label="type"
                      options={taxonomies?.[
                        TaxonomyTypeSlug.enterpriseType
                      ]?.map((taxonomy) => ({
                        value: taxonomy.id,
                        label: taxonomy.title,
                      }))}
                      value={enterpriseType}
                      onChange={(option) => setEnterpriseType(option)}
                      creation={{
                        label: 'Create new enterprise type',
                        onClick: () => setIsCreateEnterpriseTypePopupOpen(true),
                      }}
                      required
                    />

                    <SelectMultiSearch
                      label="parent enterprises"
                      selectedOptions={parentEnterprises}
                      asyncOptions={async (searchParams) => {
                        {
                          const token = await getAccessTokenUtil(dispatch);
                          if (token) {
                            return (
                              await loadEnterprisesOptionsWithPagination(
                                searchParams,
                                token,
                              )
                            ).map((item) => {
                              if (item.value === editableEnterprise?.id) {
                                return {
                                  ...item,
                                  isDisabled: true,
                                };
                              }
                              return item;
                            });
                          }
                          return [];
                        }
                      }}
                      searchByFields={['title', 'type']}
                      onChange={(parentEnterprises) =>
                        setParentEnterprises(parentEnterprises)
                      }
                      creation={{
                        label: 'Create parent enterprise',
                        onClick: createParentEnterprise,
                      }}
                    />

                    <Input
                      label="website"
                      placeholder="Type here"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    />

                    <MultiField
                      label="phone(s)"
                      type={'phone'}
                      items={phones}
                      onDelete={(id) =>
                        setPhones(
                          phones.filter((phone) =>
                            typeof id === 'string'
                              ? phone.uuid !== id
                              : phone.id !== id,
                          ),
                        )
                      }
                      onCreate={(phone) =>
                        setPhones([...(phones || []), phone as PhoneUpdateDTO])
                      }
                      changeFavorite={(id) => {
                        setPhones(
                          phones.map((phone) =>
                            phone.id === id || phone.uuid === id
                              ? { ...phone, isFavorite: !phone.isFavorite }
                              : phone,
                          ),
                        );
                      }}
                      onUpdate={(newPhone) => {
                        if ('phone' in newPhone)
                          setPhones(
                            phones.map((phone) => {
                              if (
                                (phone.id && newPhone.id === phone.id) ||
                                (phone.uuid && newPhone.uuid === phone.uuid)
                              )
                                return newPhone;
                              return phone;
                            }),
                          );
                      }}
                    />

                    <MultiField
                      label="emails(s)"
                      type={'email'}
                      items={emails}
                      onDelete={(id) =>
                        setEmails(
                          emails.filter((emails) =>
                            typeof id === 'string'
                              ? emails.uuid !== id
                              : emails.id !== id,
                          ),
                        )
                      }
                      onCreate={(email) =>
                        setEmails([...(emails || []), email as EmailUpdateDTO])
                      }
                      changeFavorite={(id) => {
                        setEmails(
                          emails.map((email) =>
                            email.id === id || email.uuid === id
                              ? { ...email, isFavorite: !email.isFavorite }
                              : email,
                          ),
                        );
                      }}
                      onUpdate={(newEmail) => {
                        if ('email' in newEmail) {
                          setEmails(
                            emails.map((email) => {
                              if (
                                (email.id && newEmail.id === email.id) ||
                                (email.uuid && newEmail.uuid === email.uuid)
                              )
                                return newEmail;
                              return email;
                            }),
                          );
                        }
                      }}
                    />

                    <TextArea
                      label="description"
                      placeholder="Type here"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  <div>
                    <GoogleMapSearchAutoComplete
                      address={address}
                      setAddress={(address) => {
                        setAddress(address);
                      }}
                      withMap={false}
                      label="address"
                      isRequired={false}
                      isNeedCreation={true}
                    />
                  </div>
                </div>
                <div className="tag-field">
                  <SelectMultiSearch
                    label="tag(s)"
                    asyncOptions={async (searchParams) => {
                      const token = await getAccessTokenUtil(dispatch);
                      if (token) {
                        return loadTagsOptions(searchParams, token);
                      }
                      return [];
                    }}
                    selectedOptions={tags?.filter(
                      (item) =>
                        item.label !== undefined && item.value !== undefined,
                    )}
                    onChange={(options) => setTags(options)}
                    creation={{
                      label: 'Create new tag',
                      onClick: () => {
                        setIsTagCreationOpen(true);
                      },
                    }}
                  />
                </div>
                <footer className="modal-footer">
                  <Button variant="light" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="dark"
                    onClick={submitData}
                    disabled={
                      !enterpriseName ||
                      !enterpriseType?.label ||
                      requestProcess
                    }>
                    Save
                  </Button>
                </footer>
              </form>
            </CustomScrollbars>
          </div>

          <div className="modal-overlay" onClick={onClose} />
        </div>
      </Modal>
      {isTagCreationOpen ? (
        <PopupTag
          setGroupCreation={setIsTagCategoryCreationOpen}
          onClose={() => {
            setIsTagCreationOpen(false);
          }}
          onCreate={async function (data) {
            const token = await getAccessTokenUtil(dispatch);
            let res: any;
            if (token) res = await TagApi.createTag(data, token);
            setTags([
              ...(tags || []),
              {
                label: `${res.title} - ${res.category.title}`,
                value: res.id,
              },
            ]);
            setIsTagCreationOpen(false);
          }}
        />
      ) : (
        ''
      )}
      {isTagCategoryCreationOpen ? (
        <PopupTagGroup
          onClose={() => {
            setIsTagCategoryCreationOpen(false);
          }}
          onCreate={async function (data) {
            const token = await getAccessTokenUtil(dispatch);
            if (token) await TagApi.createTagCategory(data, token);
            setIsTagCategoryCreationOpen(false);
          }}
        />
      ) : (
        ''
      )}
      {isCreateEnterpriseTypePopupOpen && (
        <PopupCreateTaxonomy
          title="create enterprise type"
          onClose={() => setIsCreateEnterpriseTypePopupOpen(false)}
          onSubmit={(title) => {
            dispatch(
              createTaxonomyAction.request({
                title,
                type: TaxonomyTypeSlug.enterpriseType,
                callback: (res: false | TaxonomyCreateResponse) => {
                  if (res) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`,
                      }),
                    );
                    const option: OptionType = {
                      label: taxonomies?.[
                        TaxonomyTypeSlug.enterpriseType
                      ]?.find((taxonomy) => taxonomy.title === title)?.title,
                      value: taxonomies?.[
                        TaxonomyTypeSlug.enterpriseType
                      ]?.find((taxonomy) => taxonomy.title === title)?.id,
                    };
                    setEnterpriseType(option);
                    setIsCreateEnterpriseTypePopupOpen(false);
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                    setIsCreateEnterpriseTypePopupOpen(false);
                  }
                },
              }),
            );
          }}
        />
      )}

      {parentEnterpriseModals.map((modal) => (
        <EnterpriseModal
          {...props}
          enterpriseId={undefined}
          key={modal.count}
          onClose={modal.onClose}
          onSubmit={modal.onSubmit}
        />
      ))}
    </>
  );
};

export default connect((store: IStore) => ({
  enterpriseState: store.enterprise,
  taxonomyState: store.taxonomy,
  searchState: store.IGoogleSearchState.data,
}))(EnterpriseModal);
