import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

//context
import PreviewContext from "context/Modal";

// controllers
import { deletePersonAction, getPersonAction } from "controllers/person/actions";
import { addNotification } from "controllers/modals/actions";
import { NotificationTypeEnum } from "controllers/modals/models.d";

// components
import Loader from "components/Loader";
import Trail from "components/Trail";
import PopupDelete from "components/modals/PopupDelete";
import CustomScrollbars from "components/CustomScrollbars";
import PropertyInfo from "pages/Properties/Tabs/Info/PropertyInfo";
import EnterpriseContacts from "pages/Enterprise/Tabs/Info/EnterpriseContacts";
import SaleTransactionContacts from "pages/Properties/Tabs/Sales/SaleTransactionContacts";
import LeaseTransactionInfoContent from "pages/Properties/Tabs/Leases/LeaseTransactionInfoContent";
import PersonalContacts from "pages/People/Tabs/Info/PersonalContacts";

// utils
import uuid from "utils/uuid";
import { EntityEnum } from "@ternala/voltore-types/lib/constants";
import {
  getLeaseTransactionAction,
  getPropertyAction,
  getSaleTransactionAction
} from "../../../controllers/property/actions";
import { getEnterpriseAction } from "../../../controllers/enterprise/actions";
import { getUserAction, getUserRoleAction } from "../../../controllers/user/actions";
import { getInvolvementAction } from "../../../controllers/involvement/actions";

interface IProps {
  class: string;
}

let progress: any;
const Preview = (props: IProps) => {
  const dispatch = useDispatch();
  const previewContext = useContext(PreviewContext);
  const [isPopupDeleteOpen, setIsPopupDeleteOpen] = useState<boolean>(false);
  const [permanent, setPermanent] = useState<boolean>(false);
  const [open, set] = useState<boolean>(true);

  // animation
  useEffect(() => {
    if (previewContext?.preview?.id) {
      set(true);
    } else {
      set(false);
    }
  }, [previewContext?.preview]);

  // five seconds functionality (preview)
  useEffect(() => {
    clearTimeout(progress);
    if (previewContext?.preview?.inside) setPermanent(true);
    if (!previewContext?.preview?.inside) {
      setPermanent(false);
      progress = setTimeout(function() {
        previewContext?.setPreview(undefined);
      }, 5000);
    }
  }, [previewContext?.preview?.inside]);

  useEffect(() => {
    if (previewContext?.preview) {
      const { id, type } = previewContext?.preview;
      if (id) {
        switch (type) {
          case EntityEnum.person:
            dispatch(getPersonAction.request({ id }));
            break;
          case EntityEnum.property:
            dispatch(getPropertyAction.request({ id }));
            break;
          case EntityEnum.enterprise:
            dispatch(getEnterpriseAction.request({ id }));
            break;
          case EntityEnum.userRole:
            dispatch(getUserRoleAction.request({ id }));
            break;
          case EntityEnum.leaseTransaction:
            dispatch(getLeaseTransactionAction.request({ id }));
            break;
          case EntityEnum.saleTransaction: {
            previewContext?.preview?.transactionId && dispatch(getSaleTransactionAction.request({ id: previewContext?.preview?.transactionId }));
          }
            break;
          case EntityEnum.involvement:
            dispatch(getInvolvementAction.request({ id, additionalFields: {} }));
            break;
          case EntityEnum.user:
            dispatch(getUserAction.request({ id }));
            break;
        }
      }
    }
  }, [previewContext?.preview?.id, previewContext?.preview?.type]);

  // switch functionality (modals)
  function switchComponent() {
    console.log({
      id: previewContext?.preview?.id,
      type: previewContext?.preview?.type,
      transactionId: previewContext?.preview?.transactionId
    });

    if (!previewContext?.preview?.id) {
      console.log("loader");
      return (
        <div className="preview-modal-loader">
          <Loader />
        </div>
      );
    }

    switch (previewContext?.preview?.type) {
      case EntityEnum.userRole: {
        return (
          <PersonalContacts
            personId={previewContext?.preview.id}
            onDelete={() => setIsPopupDeleteOpen(true)}
            onClose={() => previewContext?.setPreview(undefined)}
          />
        );
      }

      case EntityEnum.person:
        return (
          <PersonalContacts
            personId={previewContext?.preview.id}
            onDelete={() => setIsPopupDeleteOpen(true)}
            onClose={() => previewContext?.setPreview(undefined)}
          />
        );
      case EntityEnum.involvement:
        return (
          <PersonalContacts
            personId={previewContext?.preview.id}
            onDelete={() => setIsPopupDeleteOpen(true)}
            onClose={() => previewContext?.setPreview(undefined)}
          />
        );
      case EntityEnum.leaseTransaction:
        return (
          <LeaseTransactionInfoContent
            params={{
              id: String(previewContext?.preview.id),
              transactionId: String(previewContext?.preview.transactionId)
            }}
            onClose={() => previewContext?.setPreview(undefined)}
          />
        );
      case EntityEnum.saleTransaction:
        if (previewContext?.preview.transactionId)
          return (
            <SaleTransactionContacts
              params={{
                id: String(previewContext?.preview.id),
                transactionId: String(previewContext?.preview.transactionId)
              }}
              onClose={() => previewContext?.setPreview(undefined)}
            />
          );
        else {
          console.log("else loader");
          return <Loader />;
        }
      case EntityEnum.property:
        return (
          <PropertyInfo
            tabName={"properties"}
            propertyId={previewContext?.preview.id}
            onClose={() => previewContext?.setPreview(undefined)}
            modal
          />
        );
      case EntityEnum.enterprise:
        return (
          <EnterpriseContacts
            key={previewContext?.preview.id}
            enterpriseId={previewContext?.preview.id}
            onClose={() => previewContext?.setPreview(undefined)}
          />
        );
      default: {
        console.log("default loader");
        return (
          <div className="preview-modal-loader">
            <Loader />
          </div>
        );
      }
    }
  }

  return (
    <div className={`window preview ${props.class}`}>
      <Trail open={open}>
        <div className="window-content">
          <CustomScrollbars>
            <div
              className={`preview-modal-container`}
              onMouseEnter={() => {
                setPermanent(true);
                clearTimeout(progress);
              }}>
              {permanent ? "" : <div className="indicator-preview" />}
              {switchComponent()}
            </div>
          </CustomScrollbars>
        </div>
      </Trail>

      {isPopupDeleteOpen && (
        <PopupDelete
          onClose={() => setIsPopupDeleteOpen(false)}
          onSubmit={(callback) => {
            dispatch(
              deletePersonAction.request({
                id: previewContext?.preview?.id as number,
                callback: (status: boolean) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully deleted`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully deleted`
                      })
                    );
                    if (callback) callback();
                    previewContext?.setPreview(undefined);
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: "Something goes wrong",
                        type: NotificationTypeEnum.error,
                        title: "Something goes wrong"
                      })
                    );
                  }
                }
              })
            );
          }}
        />
      )}
    </div>
  );
};

export default Preview;
