import React, { FC, ChangeEvent } from 'react'
import { getUniqueId } from 'utils/helper-functions'
import style from './Radio.module.scss'

interface Props {
   label: string
   value: string
   checked: boolean
   onChange: (event: ChangeEvent<HTMLInputElement>) => void
   name?: string
   upperCaseLabel?: boolean
   disabled?: boolean
   darkTheme?: boolean
}

export const Radio: FC<Props> = ({ label, value, checked, onChange, darkTheme, upperCaseLabel, ...props }) => {
   const inputId = `radio-${getUniqueId()}`

   return (
      <div className={darkTheme ? style.radio_dark : style.radio}>
         <input
            id={inputId}
            type="radio"
            name={props.name}
            value={value}
            checked={checked}
            disabled={props.disabled}
            onChange={onChange}
         />
         <label htmlFor={inputId} style={{ textTransform: 'none' }}>
            { upperCaseLabel? label.toUpperCase() : label}
         </label>
      </div>
   )
}

export default Radio