export class Unauthorized extends Error {
  constructor(message: string = "User unauthorized!") {
    super(message);
  }
}

export class BadRequest extends Error {
  constructor(message: string = "Bad request!") {
    super(message);
  }
}

export class ValidationError extends Error {
  constructor(message: string = "Validation error!") {
    super(message);
  }
}
