import { appName } from "config";
import {createAction, createAsyncAction} from "typesafe-actions";
import { IAuthData, IAuthException, IRefreshToken } from "./models";
import {AuthLoginResponseDTO} from "@ternala/voltore-types/lib/modules/auth/repsonse.dto";
import { IError } from "../model";

/* Actions */
export const widgetName = "auth";

export interface ISetAuthenticatedStatus {
  status: boolean;
}

// ** Action
export const loginAction = createAsyncAction(
  `${appName}/${widgetName}/SIGN_IN_REQUEST`,
  `${appName}/${widgetName}/SIGN_IN_SUCCESS`,
  `${appName}/${widgetName}/SIGN_IN_FILED`
)<{login: string, password: string}, AuthLoginResponseDTO, IAuthException>();

export const loginByTokenAction = createAction(
  `${appName}/${widgetName}/LOGIN_TOKEN_REQUEST`
)<IAuthData>();

export const refreshTokenAction = createAsyncAction(
  `${appName}/${widgetName}/REFRESH_TOKEN_REQUEST`,
  `${appName}/${widgetName}/REFRESH_TOKEN_SUCCESS`,
  `${appName}/${widgetName}/REFRESH_TOKEN_FILED`
)<IRefreshToken, IAuthData, IAuthException>();

export const logoutAction = createAsyncAction(
  `${appName}/${widgetName}/LOGOUT_REQUEST`,
  `${appName}/${widgetName}/LOGOUT_SUCCESS`,
  `${appName}/${widgetName}/LOGOUT_FILED`
)<undefined, undefined, undefined>();

export const getAccessTokenAction = createAction(
  `${appName}/${widgetName}/GET_ACCESS_TOKEN`,
)<{callback: (res: string | IError) => void} | undefined>();

export const setAuthenticatedStatusAction = createAction(
  `${appName}/${widgetName}/SET_AUTHENTICATED_STATUS`
)<ISetAuthenticatedStatus>();

export const setAuthStateAction = createAction(`${widgetName}/SET_AUTH_STATE`)<{
  code?: number | undefined,
  message?: string;
  isLoading: boolean;
  error?: boolean;
}>();