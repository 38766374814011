import React, { Component, RefObject } from 'react';

/* constants & config */
import { LeaseTransactionStatusEnum } from '@ternala/voltore-types/lib/constants';
import { LeaseStatusVisualization } from 'config/constants';
import { MAP_OPTIONS } from 'config/google-maps';

/* utils */
import {
  computeDaysOnMarket,
  withThousandsSeparators,
} from 'utils/helper-functions';

/* context */
import { GoogleMapsAPIContext } from 'context/GoogleMapsAPI';

/* styles */
import stylesWithBusinessLabels from './styles/with-business-labels';
import stylesWithoutBusinessLabels from './styles/without-business-labels';

/* controls */
import { Marker } from './markers';

/* types */
import { LeaseTransactionMapVisualization } from 'controllers/property/models';
import { IPoint } from '@ternala/voltore-types/lib/modules/property/filters/area';
import { LeaseTransactionStatusHistoryDTO } from '@ternala/voltore-types';

interface Props {
  propertyCoords?: IPoint;
  transactions?: LeaseTransactionMapVisualization[];
  onMarkerClick: (transactionId: number) => void;
}

export class LeasesFloorsMap extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.mapRef = React.createRef();
  }

  static contextType = GoogleMapsAPIContext;
  mapsAPI?: typeof google.maps;
  map?: google.maps.Map;
  mapRef: RefObject<HTMLDivElement>;
  markers: google.maps.Marker[] = [];
  infoWindows: google.maps.InfoWindow[] = [];
  activeTransactionId?: number;

  componentDidMount() {
    this.initializeMap();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(prevProps.transactions) !==
      JSON.stringify(this.props.transactions)
    ) {
      this.renderMarkers();
    }
  }

  initializeMap() {
    const { GoogleMaps } = this.context;

    if (GoogleMaps && this.mapRef.current) {
      this.mapsAPI = GoogleMaps;
      this.map = new GoogleMaps.Map(this.mapRef.current, MAP_OPTIONS);

      if (this.map && this.props.propertyCoords) {
        this.map.setCenter(
          new GoogleMaps.LatLng(
            this.props.propertyCoords.latitude,
            this.props.propertyCoords.longitude,
          ),
        );
        const bounds = new GoogleMaps.LatLngBounds();
        bounds.extend({
          lat: this.props.propertyCoords.latitude,
          lng: this.props.propertyCoords.longitude,
        });
        this.map.fitBounds(bounds);
      }

      this.applyMapStyles();
      this.renderMarkers();
    }
  }

  applyMapStyles() {
    if (this.mapsAPI && this.map) {
      /* without business labels */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const styles = new this.mapsAPI.StyledMapType(
        stylesWithoutBusinessLabels,
        { name: 'Styled Map' },
      );
      /* with business labels */
      const stylesBL = new this.mapsAPI.StyledMapType(
        stylesWithBusinessLabels,
        { name: 'Styled Map BL' },
      );
      this.map.mapTypes.set('styled_map_bl', stylesBL);
      this.map.setMapTypeId('styled_map_bl');
    }
  }

  renderMarkers() {
    const { transactions, onMarkerClick } = this.props;

    this.markers.forEach((marker) => {
      marker.setMap(null);
      this.mapsAPI && this.mapsAPI.event.clearListeners(marker, 'click');
    });
    this.markers = [];

    transactions?.forEach((transaction) => {
      if (
        this.mapsAPI &&
        this.map &&
        transaction.latitude &&
        transaction.longitude
      ) {
        const latestTransactionStatus = transactions[0].historyStatuses?.[0];
        const leaseStatus = latestTransactionStatus
          ? this.getComputedTransactionStatus(
              latestTransactionStatus,
              transactions.length > 1,
              transaction.inactive,
            )
          : LeaseStatusVisualization.LEASED;

        const marker = new Marker({
          map: this.map,
          coords: {
            latitude: transaction.latitude,
            longitude: transaction.longitude,
          },
          leaseStatus,
          selected: transaction.id === this.activeTransactionId,
        }).marker;

        const infoWindow = new this.mapsAPI.InfoWindow({
          content: this.generateInfoWindowMarkup(transaction),
        });

        marker.addListener('mouseover', () => {
          infoWindow.open(this.map, marker);
        });

        marker.addListener('mouseout', () => {
          this.infoWindows.forEach((element) => element.close());
        });

        marker.addListener('click', () => {
          this.infoWindows.forEach((element) => element.close());
          infoWindow.open(this.map, marker);

          onMarkerClick(transaction.id);
          this.activeTransactionId = transaction.id;
          this.renderMarkers();
        });

        this.markers.push(marker);
        this.infoWindows.push(infoWindow);
      }
    });
  }

  getComputedTransactionStatus(
    latestTransactionStatus: LeaseTransactionStatusHistoryDTO,
    overlap: boolean,
    inactive?: boolean,
  ): LeaseStatusVisualization {
    let leaseStatus = LeaseStatusVisualization.LEASED;

    switch (latestTransactionStatus.status) {
      case LeaseTransactionStatusEnum.Leased:
        if (new Date(latestTransactionStatus?.startDate) > new Date()) {
          if (overlap) {
            leaseStatus = LeaseStatusVisualization.WILL_BE_LEASED_OVERLAP;
            if (inactive) {
              leaseStatus =
                LeaseStatusVisualization.WILL_BE_LEASED_OVERLAP_INACTIVE;
            }
          } else {
            leaseStatus = LeaseStatusVisualization.WILL_BE_LEASED;
            if (inactive) {
              leaseStatus = LeaseStatusVisualization.WILL_BE_LEASED_INACTIVE;
            }
          }
        } else {
          if (overlap) {
            leaseStatus = LeaseStatusVisualization.LEASED_OVERLAP;
            if (inactive) {
              leaseStatus = LeaseStatusVisualization.LEASED_OVERLAP_INACTIVE;
            }
          } else {
            leaseStatus = LeaseStatusVisualization.LEASED;
            if (inactive) {
              leaseStatus = LeaseStatusVisualization.LEASED_INACTIVE;
            }
          }
        }
        break;
      default:
        if (overlap) {
          leaseStatus = LeaseStatusVisualization.AVAILABLE_OVERLAP;
          if (inactive) {
            leaseStatus = LeaseStatusVisualization.AVAILABLE_OVERLAP_INACTIVE;
          }
        } else {
          leaseStatus = LeaseStatusVisualization.AVAILABLE;
          if (inactive) {
            leaseStatus = LeaseStatusVisualization.AVAILABLE_INACTIVE;
          }
        }
    }
    return leaseStatus;
  }

  generateInfoWindowMarkup(
    transaction: LeaseTransactionMapVisualization,
  ): string {
    const daysOnMarket = computeDaysOnMarket(transaction);

    return `
         <div class="leases-info-window">
            <div class="leases-info-window__transaction-info transaction-info">
               <div class="transaction-info__data-field">
                  <span>Listing status: </span>
                  <span class="listing-status_${transaction.activeStatus?.status.toLowerCase()}">
                     ${transaction.activeStatus?.status.toLowerCase()}
                  </span>
               </div>
               ${
                 transaction.owner?.title
                   ? `<div class="transaction-info__data-field">
                     <span>Landlord: </span>
                     <span>${transaction.owner.title}</span>
                  </div>`
                   : ''
               }
               ${
                 transaction.leasableLotSQFT
                   ? `<div class="transaction-info__data-field">
                     <span>Lot SQFT: </span>
                     <span>${withThousandsSeparators(
                       transaction.leasableLotSQFT,
                     )} SQFT</span>
                  </div>`
                   : ''
               }
               ${
                 daysOnMarket > 0
                   ? `<div class="transaction-info__data-field">
                     <span>Days on market: </span>
                     <span>${daysOnMarket}</span>
                  </div>`
                   : ''
               }
            </div>
         </div>
      `;
  }

  render() {
    return <div ref={this.mapRef} className="google-map_lease-floors" />;
  }
}

export default LeasesFloorsMap;
