import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TagDTO } from '@ternala/voltore-types/lib/modules/card/tag/tag.dto';
import { CardEntityEnum } from '@ternala/voltore-types/lib/constants';

// Contexts
import { FocusToMainContext } from '../../Contexts';

// Interfaces
import {
  ActionListTypeEnum,
  IActionElement,
  IFilter,
  TagEditorStepEnum,
  TagEntityType,
  tagEntityType,
} from '../../model.d';

// Custom components
import BackArrow from '../../../icons/NoteCreator/BackArrow';
import ActionsListExpanded from '../Actions/ActionListExpanded';
import ActionListMention from '../Actions/ActionListMention';

//Config
import { TagApi } from '../../../../controllers/tag/transport/tag.api';
import { getAccessToken } from '../../../../controllers/auth';
import { IStore } from '../../../../controllers/store';

export interface IActionBlockProps {
  value: string;
  step: TagEditorStepEnum;

  // Entity
  entityType?: TagEntityType;
  selectedEntity?: tagEntityType;
  onSelectEntity: (action: IActionElement) => void;
  onRemoveEntity: () => void;

  // Tag
  selectedTag?: TagDTO;
  onSelectTag: (entity: TagDTO) => void;
  onRemoveTag: () => void;

  // Modals
  setIsCreatepreviewContextOpen: Function;
  setIsCreateTagCategoryModalOpen: Function;

  // Focus
  setHasFocus: (isHasFocus: boolean) => void;
  hasFocus: boolean;

  store: IStore;
}

interface IState {
  selectedEntityTags?: TagDTO[];
  selectedFilter: CardEntityEnum | null;
}

const filters: IFilter[] = [
  { label: 'all', key: null },
  { label: 'property', key: CardEntityEnum.Property },
  // { label: 'sale', key: CardEntityEnum.Sale_Transaction },
  // { label: 'lease', key: CardEntityEnum.Lease_Transaction },
  { label: 'enterprise', key: CardEntityEnum.Enterprise },
  { label: 'person', key: CardEntityEnum.Person },
];

class ActionBlockComponent extends Component<IActionBlockProps, IState> {
  private wrapperRef = React.createRef<HTMLDivElement>();

  constructor(props: IActionBlockProps) {
    super(props);
    this.state = {
      selectedFilter: null,
    };
    document.addEventListener('mousedown', this.handleClickOutside);
    if (props.selectedEntity) {
      this.updateTagList();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IActionBlockProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ) {
    const { selectedEntity } = this.props;
    const { selectedEntityTags } = this.state;
    if (
      selectedEntity?.id !== prevProps.selectedEntity?.id ||
      !selectedEntityTags
    ) {
      this.updateTagList();
    }
  }

  updateTagList = async () => {
    const { entityType, selectedEntity } = this.props;
    const { store } = this.props;
    if (selectedEntity && entityType) {
      const token = await getAccessToken(store);
      if (token) {
        const res = await TagApi.getTagList(
          {
            [entityType]: selectedEntity?.id,
          },
          token,
        );
        if (typeof res !== 'string') {
          this.setState({
            selectedEntityTags: res.items,
          });
        }
      }
    }
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (this.wrapperRef?.current?.contains(event.target)) {
      if (!this.props.hasFocus) {
        this.props.setHasFocus(true);
      }
    } else {
      if (this.props.hasFocus) {
        this.props.setHasFocus(false);
      }
    }
  };

  render() {
    const { step, value, onSelectTag, onSelectEntity, onRemoveEntity } =
      this.props;
    const { selectedEntityTags, selectedFilter } = this.state;
    const disabledIds = selectedEntityTags?.map((tag) => tag.id);
    return (
      <div className="note-creator__actions-list_items" ref={this.wrapperRef}>
        <header
          className={
            'actions-list__header with-arrow' +
            (step !== TagEditorStepEnum.Entity ? ' with-shadow' : '')
          }>
          <div className={'actions-list__header--row'}>
            <BackArrow
              className={'actions-list__header--back-arrow'}
              onClick={onRemoveEntity}
            />
          </div>
        </header>
        {step === TagEditorStepEnum.Entity ? (
          <header className="actions-list__header with-tabs with-shadow">
            <div className="tabs">
              {filters.map((filter) => (
                <div
                  className={
                    'tab' + (filter.key === selectedFilter ? ' selected' : '')
                  }
                  onClick={() => this.setState({ selectedFilter: filter.key })}>
                  {filter.label}
                </div>
              ))}
            </div>
          </header>
        ) : (
          ''
        )}
        {step === 'entity' ? (
          <ActionListMention
            searchValue={value}
            type={selectedFilter || undefined}
            allEntityTypes={[
              CardEntityEnum.Property,
              CardEntityEnum.Person,
              CardEntityEnum.Enterprise,
            ]}
            onSelect={(entity) => {
              onSelectEntity(entity);
            }}
            key={'actions_block_actions_list'}
          />
        ) : (
          <ActionsListExpanded
            type={step as unknown as ActionListTypeEnum}
            searchValue={value}
            key={'tag_actions_list'}
            disabledIds={disabledIds}
            onSelect={(action) => {
              if (!action.data) return;
              if ('key' in action.data) return;
              if ('category' in action.data) {
                onSelectTag(action.data);
              }
            }}
          />
        )}
      </div>
    );
  }
}

ActionBlockComponent.contextType = FocusToMainContext;

export default connect((store: IStore) => ({
  store,
}))(ActionBlockComponent);
