
import React, { FC, useState, ChangeEvent } from 'react'

import { UploadIcon } from 'components/icons/UploadIcon'
import { MAX_DOCUMENT_SIZE } from 'config'
import style from './Attachments.module.scss'

import {
   PropertySupplementDTO,
   LeaseTransactionSupplementDTO,
   SaleTransactionSupplementDTO
} from '@ternala/voltore-types'

interface INewAttachment {
   id: number
   file: File
}

interface Props {
   label?: string
   buttonText: string
   multiple?: boolean
   fileTypes: string[]
   attachments?: PropertySupplementDTO[] | SaleTransactionSupplementDTO[] | LeaseTransactionSupplementDTO[]
   onChange: (oldSupplements: number[], newSupplements: File[]) => void
}

export const Attachments: FC<Props> = ({ label, buttonText, multiple, fileTypes, attachments, ...props }) => {
   const [oldAttachments, setOldAttachments] = useState<(PropertySupplementDTO | SaleTransactionSupplementDTO | LeaseTransactionSupplementDTO)[]>(attachments || [])
   const [newAttachments, setNewAttachments] = useState<INewAttachment[]>([])

   const validFileTypes: string[] = []
   if (fileTypes.includes('images')) {
      validFileTypes.push('image/jpeg')
      validFileTypes.push('image/png')
   }
   if (fileTypes.includes('jpg') || fileTypes.includes('jpeg')) {
      validFileTypes.push('image/jpeg')
   }
   if (fileTypes.includes('png')) {
      validFileTypes.push('image/png')
   }
   if (fileTypes.includes('pdf')) {
      validFileTypes.push('application/pdf')
   }
   if (fileTypes.includes('doc')) {
      validFileTypes.push('application/msword')
   }
   if (fileTypes.includes('docx')) {
      validFileTypes.push('application/vnd.openxmlformats-officedocument.wordprocessingml.document')
   }

   const onAddAttachment = (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target
      let updatedNewAttachments: INewAttachment[] = [...newAttachments]

      if (files) {
         if (updatedNewAttachments.length > 0) {
            updatedNewAttachments.forEach(attachment => {
               Array.prototype.forEach.call(files, file => {
                  if (validFileTypes.includes(file.type) && file.size < MAX_DOCUMENT_SIZE) {
                     if (attachment.file.name === file.name) {
                        /* filter existing values */
                        updatedNewAttachments = updatedNewAttachments.filter(attachment => {
                           return attachment.file.name !== file.name
                        })
                     }
                     updatedNewAttachments.push({ id: file.name, file })
                  }
               })
            })
         } else {
            Array.prototype.forEach.call(files, file => {
               /* reject files with the same name */
               const fileNameExists = attachments?.some(image => image.title === file.name)

               if (!fileNameExists && validFileTypes.includes(file.type) && file.size < MAX_DOCUMENT_SIZE) {
                  updatedNewAttachments.push({ id: file.name, file })
               }
            })
         }
      }

      const uniqueAttachments = []
      const map = new Map()
      for (const attachment of updatedNewAttachments) {
         if (!map.has(attachment.id)) {
            map.set(attachment.id, true)
            uniqueAttachments.push({
               id: attachment.id,
               file: attachment.file
            })
         }
      }  
      
      setNewAttachments(uniqueAttachments)
      props.onChange(
         oldAttachments.map(attachment => attachment.id),
         uniqueAttachments.map(attachment => attachment.file)
      )
   
      event.target.value = ''
   }

   const onRemoveAttachment = (id: number, type: 'old' | 'new') => {
      let updatedOldAttachments: (PropertySupplementDTO | SaleTransactionSupplementDTO | LeaseTransactionSupplementDTO)[] = []
      let updatedNewAttachments: INewAttachment[] = []

      if (type === 'old') {
         updatedOldAttachments = oldAttachments.filter(attachment => attachment.id !== id)
         setOldAttachments(updatedOldAttachments)
      }
      if (type === 'new') {
         updatedNewAttachments = newAttachments.filter(attachment => attachment.id !== id)
         setNewAttachments(updatedNewAttachments)
      }
      props.onChange(
         updatedOldAttachments.map(attachment => attachment.id),
         updatedNewAttachments.map(attachment => attachment.file)
      )
   }

   return (
      <div className={style.container}>
         <div className={style.label}>{ label }</div>

         {[...oldAttachments, ...newAttachments].map(attachment => 
            <div key={attachment.id} className={style.attachment_wrapper}>
               <div
                  className={style.remove_icon}
                  onClick={() => onRemoveAttachment(
                     attachment.id,
                     'file' in attachment ? 'new' : 'old'
                  )}
               />
               <span className={style.attachment_name}>
                  {'file' in attachment ? attachment.file.name : attachment.title}
               </span>
            </div>
         )}

         <input
            type="file"
            id="attachments"
            className="visually-hidden"
            onChange={onAddAttachment}
            multiple={multiple}
            accept={validFileTypes.join(', ')}
         />
         <label htmlFor="attachments" className={style.btn_upload}>
            { buttonText }
            <UploadIcon className={style.upload_icon} />
         </label>
      </div>
   )
}

Attachments.defaultProps = {
   multiple: true
}

export default Attachments