
import React, {
  FC,
  ComponentType,
  useState,
  useEffect,
  useContext,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

/* controllers */
import { logoutAction } from 'controllers/auth/actions';

/* routing */
import RoutingSchema, { Pages } from 'routing';
import Link from 'routing/Link';

/* constants */
import { PageEnum } from 'config/constants';

/* utils */
import { clearAccess } from '../utils/manageAccess';

/* icons */
import { LogoIcon } from './icons/LogoIcon';
import { NavigationPropertiesIcon } from 'components/icons/Navigation/PropertiesIcon';
import { NavigationEnterprisesIcon } from 'components/icons/Navigation/EnterprisesIcon';
import { NavigationPeopleIcon } from 'components/icons/Navigation/PeopleIcon';
import { NavigationMenuIcon } from 'components/icons/Navigation/MenuIcon';
import { UserTypeEnum } from '@ternala/voltore-types/lib/constants';
import { IStore } from '../controllers/store';
import TagsContext from 'context/Tags';

const mainLinks: {
  accessType?: UserTypeEnum[];
  page: Pages;
}[] = [
  {
    page: PageEnum.PROPERTIES,
  },
  {
    page: PageEnum.ENTERPRISES,
  },
];

const additionalLinks: {
  accessType?: UserTypeEnum[];
  page: Pages;
}[] = [
  {
    page: PageEnum.PEOPLE,
  },
  {
    page: PageEnum.TAXONOMY,
  },
  {
    page: PageEnum.SETTINGS,
    accessType: [UserTypeEnum.SA],
  },
  {
    page: PageEnum.TAGS,
  },
  {
    page: PageEnum.USERS,
    accessType: [UserTypeEnum.SA],
  },
];

const NavMenuIcon: { [page: string]: ComponentType } = {
  [PageEnum.PROPERTIES]: (props) => <NavigationPropertiesIcon {...props} />,
  [PageEnum.ENTERPRISES]: (props) => <NavigationEnterprisesIcon {...props} />,
  [PageEnum.PEOPLE]: (props) => <NavigationPeopleIcon {...props} />,
};

interface IProps {
  authType?: UserTypeEnum;
}

export const Navigation: FC<IProps> = ({ authType }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const tagsFilters = useContext(TagsContext);

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState<boolean>(false);
  const [link, setLink] = useState<string>();

  useEffect(() => {
    tagsFilters.removeTags();
  }, [link]);

  const logOut = () => {
    clearAccess();
    dispatch(logoutAction.request({}, {}));
  };

  return (
    <>
      <div className="navigation">
        <div className="navigation__main-links">
          <div className="logo">
            <LogoIcon />
          </div>

          <ul className="nav-menu">
            {mainLinks.map((menuItem) => {
              const purposeRoute = RoutingSchema.getSchemaItem(menuItem.page);
              const isActive = matchPath(pathname, {
                path: purposeRoute?.path,
                exact: purposeRoute?.isExact,
              });
              if (isActive && link !== menuItem.page) {
                setLink(menuItem.page);
              }
              return (
                <li
                  className="nav-menu__item"
                  key={menuItem.page}
                  onClick={() => setIsMobileDrawerOpen(false)}>
                  <Link
                    to={menuItem.page}
                    className={
                      'nav-menu__link' +
                      (isActive ? ' nav-menu__link_active' : '')
                    }>
                    {(NavMenuIcon[menuItem.page] as Function)()}
                    <span className="link-title">
                      {purposeRoute?.title || menuItem}
                    </span>
                  </Link>
                </li>
              );
            })}

            <li
              className="nav-menu__item nav-menu__menu"
              onClick={() => setIsMobileDrawerOpen(true)}>
              <div className="nav-menu__link">
                <NavigationMenuIcon />
                <span className="link-title">Menu</span>
              </div>
            </li>
          </ul>
        </div>

        <div
          className={
            'navigation__additional-links mobile-drawer' +
            (isMobileDrawerOpen ? ' mobile-drawer_is-open' : '')
          }
          style={{ height: '100%' }}>
          <ul className="nav-menu">
            {additionalLinks.map((menuItem) => {
              let hasLink = true;
              if (
                menuItem.accessType &&
                (!authType || !menuItem.accessType.includes(authType))
              )
                hasLink = false;

              const purposeRoute = RoutingSchema.getSchemaItem(menuItem.page);
              const isActive = matchPath(pathname, {
                path: purposeRoute?.path,
                exact: purposeRoute?.isExact,
              });
              if (isActive && link !== menuItem.page) {
                setLink(menuItem.page);
              }
              return (
                <li
                  key={menuItem.page}
                  className={
                    menuItem.page === PageEnum.TAXONOMY
                      ? ' nav-menu__item_taxonomy'
                      : ''
                  }
                  onClick={() => setIsMobileDrawerOpen(false)}>
                  {hasLink && (
                    <Link
                      to={menuItem.page}
                      className={
                        'nav-menu__link' +
                        (isActive ? ' nav-menu__link_active' : '')
                      }>
                      {purposeRoute?.title || menuItem.page}
                    </Link>
                  )}
                </li>
              );
            })}

            <li className="nav-menu__item nav-menu__item_log-out">
              <span
                className="nav-menu__link"
                onClick={logOut}
                style={{ textTransform: 'none' }}>
                Log out
              </span>
            </li>
          </ul>

          <div
            className="mobile-drawer__close"
            onClick={() => setIsMobileDrawerOpen(false)}
            title="Hide"
          />
        </div>

        {isMobileDrawerOpen && (
          <div
            className="mobile-drawer__backdrop"
            onClick={() => setIsMobileDrawerOpen(false)}
          />
        )}
      </div>
    </>
  );
};
export default connect((state: IStore) => ({
  authType: state.auth.account?.type.slug,
}))(Navigation);
