import React, { FC, useState, useEffect, useContext, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { google } from 'google-maps';
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ImageDTO } from '@ternala/voltore-types';
import GalleryModal from 'pages/Properties/Gallery/GalleryFull';

/* swiper */
import 'swiper/css';
import 'swiper/css/navigation';

import GoogleMapStreetView from 'components/icons/StreetViewGM';

// actions
import { searchRequestAction } from 'controllers/googleMaps/actions';

/* types */
import { IStore } from 'controllers/store';
import { IGoogleApiSearchResponse } from 'controllers/googleMaps/models';
import { IPropertyState } from 'controllers/property/models';

//Components
import Loader from 'components/Loader';
import { GoogleMapsAPIContext } from 'context/GoogleMapsAPI';
import { getMiddleImageOne, getNumberFromString } from 'utils';

interface Props {
  propertyState: IPropertyState;
  images?: ImageDTO[];
  searchState?: IGoogleApiSearchResponse;
  isLoading?: boolean;
}

let sv: google.maps.StreetViewService;

const Gallery: FC<Props> = ({ images, searchState, isLoading, ...props }) => {
  const sortImages = (
    images?: ImageDTO[],
  ): (ImageDTO & { isLoaded: boolean })[] | undefined => {
    return images
      ?.sort((a, b) => a.id - b.id)
      .map((image) => ({
        ...image,
        isLoaded: false,
      }));
  };

  const [localImages, setLocalImages] = useState<
    (ImageDTO & { isLoaded: boolean })[] | undefined
  >(sortImages(images));

  const swiperRef = useRef<any>();

  const context = useContext(GoogleMapsAPIContext);
  const {
    propertyState: { propertyData },
  } = props;

  const [isGalleryOpen, setIsGalleryOpen] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    const StreetView = context?.GoogleMaps?.StreetViewService;
    if (StreetView) {
      sv = new StreetView();
    }
  }, []);

  useEffect(() => {
    setLocalImages(sortImages(images));
    setTimeout(() => {
      swiperRef.current?.update();
    }, 1000);
  }, [images]);

  const generateUrl = async ({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }): Promise<{
    latitude: number;
    longitude: number;
  } | null> => {
    const LatLng = context?.GoogleMaps?.LatLng;
    const StreetViewStatus = context?.GoogleMaps?.StreetViewStatus;
    if (!LatLng) return null;
    const location = new LatLng(latitude, longitude);
    return await new Promise((resolve, reject) => {
      sv?.getPanoramaByLocation(location, 200, (data, status) => {
        if (status === StreetViewStatus?.OK) {
          if (data.location?.latLng) {
            resolve({
              latitude: data.location.latLng.lat(),
              longitude: data.location.latLng.lng(),
            });
          }
          reject('Street View data not found for this location.');
        } else {
          reject('Street View data not found for this location.');
        }
      });
    });
  };

  const id = getNumberFromString(window.location.pathname);

  const editableProperty = id ? propertyData[Number(id)] : undefined;

  const address =
    `${editableProperty?.address?.address}, ${editableProperty?.address?.city}, ${editableProperty?.address?.state}, USA` ||
    '';

  const [geometry, setGeometry] = useState<any>({
    latitude: editableProperty?.address?.latitude,
    longitude: editableProperty?.address?.longitude,
  });

  const [preparedGeometry, setPreparedGeometry] = useState<any>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (address && !geometry) {
      dispatch(searchRequestAction.request(address));

      if (searchState && searchState.results) {
        setGeometry(searchState.results[0]?.geometry?.location);
      }
    }
    if (geometry) {
      generateUrl(geometry)
        .then((location) => {
          setPreparedGeometry(location);
        })
        .catch((reason) => {
          console.log('reason: ', reason);
        });
    }
  }, [geometry]);

  let streetViewUrl = '';

  if (searchState && searchState.results) {
    streetViewUrl = `https://www.google.com.ua/maps/@${Number(
      searchState.results[0]?.geometry.location?.lat,
    ).toFixed(7)},${Number(
      searchState.results[0]?.geometry.location?.lng,
    ).toFixed(
      7,
    )},3a,75y,288.28h,79.43t/data=!3m7!1e1!3m5!1sghBDD3Uum8Gum4pkIf1sig!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DnhQCC3Uum8Gum4pkIf1sig%26cb_client%3Dmaps_sv.tactile.gps%26w%3D203%26h%3D100%26yaw%3D99.54437%26pitch%3D0%26thumbfov%3D100!7i5760!8i2880?hl=en`;
  }

  if (preparedGeometry) {
    streetViewUrl = `https://www.google.com.ua/maps/@${Number(
      preparedGeometry.latitude,
    ).toFixed(7)},${Number(preparedGeometry?.longitude).toFixed(
      7,
    )},3a,75y,288.28h,79.43t/data=!3m7!1e1!3m5!1sghBDD3Uum8Gum4pkIf1sig!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DnhQCC3Uum8Gum4pkIf1sig%26cb_client%3Dmaps_sv.tactile.gps%26w%3D203%26h%3D100%26yaw%3D99.54437%26pitch%3D0%26thumbfov%3D100!7i5760!8i2880?hl=en`;
  }

  const setImageLoaded = (id: number) => {
    setLocalImages(
      localImages?.map((image) => {
        if (image.id === id) {
          return {
            ...image,
            isLoaded: true,
          };
        }
        return image;
      }),
    );
  };

  useEffect(() => {
    if (localImages && !localImages.find((image) => !image.isLoaded)) {
      swiperRef.current?.update();
    }
  }, [localImages]);

  return (
    <>
      {localImages?.length || streetViewUrl ? (
        <Swiper
          slidesPerView="auto"
          modules={[Navigation, A11y]}
          watchOverflow
          freeMode
          grabCursor
          navigation
          onSwiper={(swiper) => (swiperRef.current = swiper)}>
          {streetViewUrl ? (
            <SwiperSlide key={'street-' + id}>
              <div
                className="gallery-previews__image-container"
                key={'preview' + id}>
                <div className={'g-street-view'}>
                  <GoogleMapStreetView />
                  <div
                    className={'g-street-view-btn'}
                    onClick={() => {
                      window.open(streetViewUrl, '_blank');
                    }}>
                    OPEN STREET VIEW
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ) : (
            <></>
          )}
          {!Boolean(isLoading) ? (
            localImages ? (
              localImages.map((image, index) => (
                <SwiperSlide key={'image-' + image.id}>
                  <div
                    className="gallery-previews__image-container"
                    key={image.id}
                    style={{ marginLeft: '15px', display: 'flex' }}>
                    <img
                      onClick={() => setIsGalleryOpen(index)}
                      src={getMiddleImageOne(image)}
                      alt=""
                      onLoad={() => setImageLoaded(image.id)}
                    />
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide />
            )
          ) : (
            <SwiperSlide virtualIndex={1}>
              <div
                className="gallery-previews__image-container"
                key={Math.random()}
                style={{
                  marginLeft: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                <Loader />
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      ) : (
        <></>
      )}
      {typeof isGalleryOpen !== 'undefined' ? (
        <GalleryModal
          images={localImages?.map((image, index) => ({
            ...image,
            index,
          }))}
          initialSlideKey={localImages?.[isGalleryOpen || 0].id}
          initialSlideIndex={isGalleryOpen || 0}
          onClose={() => setIsGalleryOpen(undefined)}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  searchState: store.IGoogleSearchState.data,
  propertyState: store.property,
}))(Gallery);
