import React, {
  FC,
  useState,
  useEffect,
  ChangeEvent,
  KeyboardEvent, useRef
} from "react";
import FormattedInput from 'react-number-format';

/* constants & helpers */
import { PHONE_NUMBER_LENGTH } from 'config';
import { getUniqueId } from 'utils/helper-functions';

/* styles */
import { PlusIcon } from 'components/icons/PlusIcon';
import { Color } from '../shared/styles';
import style from './InputMulti.module.scss';

/* types */
import validator from 'validator';
import NumberFormat from "react-number-format";

interface Props {
  className?: string;
  label: string;
  type?: string;
  name?: string;
  placeholder?: string;
  data?: any;
  value?: string;
  valueLabel?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeValue?: (value: string) => void;
  onUpdate?: (values: any[]) => void;
  onError?: (error: string) => void;
  items?: any;
  required?: boolean;
  error?: string;
  width?: number;
  disabled?: boolean;
  parcel?: boolean;
  multiple?: boolean;
  isTypePhones?: boolean;
}

const InputMulti: FC<Props> = (props) => {
  const { label, type, data, disabled, required } = props;

  const [value, setValue] = useState<string>(props.value || '');
  const [validationError, setValidationError] = useState<string>(
    props.error || '',
  );
  const [placeholder, setPlaceholder] = useState<string>(
    props.placeholder || '',
  );
  const [iconFillColor, setIconFillColor] = useState<string>('');
  const [valueExists, setValueExists] = useState<boolean>(false);
  const [selections, setSelections] = useState<any>([]);

  const inputId = `input-${getUniqueId()}`;

  useEffect(() => {
    if (data?.length && type === 'phone') {
      setSelections(
        data.map((item: any) => {
          if (
            typeof item === 'string' &&
            item !== '[object Object]' &&
            item.match('phone')
          ) {
            return JSON.parse(item);
          }

          return item;
        }),
      );
    }
    if (data?.length && type !== 'phone') {
      setSelections(data);
    }
  }, [data]);

  useEffect(() => {
    if (props.onError) props.onError(validationError);
    if (!value || (type === 'phone' && !isPhoneValid(value))) {
      setIconFillColor(Color.plusIconInvalid);
    } else if (value && !validationError) {
      setIconFillColor(Color.mainDark);
    } else {
      setIconFillColor(Color.main);
    }
  }, [value, validationError]);

  const onFocus = () => {
    setPlaceholder('');
  };

  const onBlur = () => {
    setPlaceholder(props.placeholder || '');
  };

  const onKeyPress = (event: KeyboardEvent) => {
    const { value } = event.target as HTMLInputElement;

    if (event.key === 'Enter' && value.trim() !== '') {
      updateSelections(value);
    }
  };

  const currentValueExists = (value: string): boolean => {
    return selections.some((selection: any) =>
      type === 'phone' ? selection.phone === value : selection === value,
    );
  };

  const isPhoneValid = (phone: string): boolean => {
    return phone.replace(/\d/g, '').length === PHONE_NUMBER_LENGTH - 4;
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === ''){
      setValidationError('');
    } else {
      if (type === 'phone' && !isPhoneValid(value))
        setValidationError('Invalid phone format');
      else if (
        type === 'phone' &&
        props.items?.find((item: any) => item.phone === value || item.phone === value.replace(/\D/g, ''))?.phone
      )
        setValidationError('Identical phone numbers should not be created');
      else if (
        type === 'email' &&
        props.items?.find((item: any) => item.email === value)?.email
      )
        setValidationError('Identical emails should not be created');
      else if (type === 'email' && !validator.isEmail(value)) {
        setValidationError('Invalid email');
      } else setValidationError('');
    }
    setValue(value);
    props.onChange?.(event);
    props.onChangeValue?.(event.target.value)
  };

  const updateSelections = (value: string) => {
    if (currentValueExists(value)) {
      if (type === 'email') {
        setValidationError('Such email already exists');
      } else if (type === 'phone') {
        setValidationError('Such phone already exists');
      } else {
        setValidationError('Such value already exists');
      }
    } else if (type === 'email' && !validator.isEmail(value)) {
      setValidationError('Invalid email');
    } else if (type === 'phone' && validator.isMobilePhone(value)) {
      setValidationError('Invalid phone format');
    } else {
      const updatedSelections = [...selections, value.toLowerCase()];
      setSelections(updatedSelections);
      props.onUpdate?.(updatedSelections);
      props.onChangeValue?.('')

      setValue('');
      setValueExists(false);
    }
  };

  const removeSelection = (current: string) => {
    const updatedSelections = selections.filter(
      (selection: any) => selection !== current,
    );
    setSelections(updatedSelections);
    if (props.onUpdate) props.onUpdate(updatedSelections);
  };

  const generateInput = (type?: string) => {
    let input;
    switch (type) {
      case 'phone':
        {
          input = (
            <FormattedInput
              id={inputId}
              format="(###) ### - ####"
              mask="_"
              style={{
                width: '100%',
                borderColor: validationError || valueExists ? Color.error : '',
              }}
              className={style.input}
              name={props.name}
              placeholder={placeholder}
              value={value}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              onChange={onChange}
              disabled={disabled}
            />
          );
        }
        break;
      default: {
        input = (
          <>
            <input
              id={inputId}
              type="text"
              style={{
                borderColor: validationError || valueExists ? Color.error : '',
              }}
              className={style.input + (value ? " " + style['has-value'] : "")}
              name={props.name}
              placeholder={placeholder}
              value={value}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              onChange={onChange}
              disabled={disabled}
            />
            {props.parcel || props.multiple ? (
              <div
                className={style.plus_icon}
                onClick={() => updateSelections(value)}>
                <PlusIcon color={Color.mainDark} fill={iconFillColor} />
              </div>
            ) : (
              ''
            )}
          </>
        );
      }
    }

    return (
      <div
        className={
          type === 'phone' ? style.input_phone_container : style.container
        }>
        {input}
        {validationError && (
          <div className={style.error} style={{ right: 'auto' }}>
            {validationError}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={style.container}>
      <label
        htmlFor={inputId}
        className={style.label}
        style={{
          color:
            validationError || valueExists
              ? Color.error
              : disabled
              ? Color.disabled
              : Color.label,
          marginBottom: type === 'phone' ? 11 : 12,
        }}>
        {label}
        {required && <span className={style.required}>*</span>}
      </label>

      {selections.length > 0 && (props.parcel || props.multiple) ? (
        <div className={style.selections}>
          {selections.map((selection: any) => (
            <div className={style.selection_multi} key={Math.random()}>
              <span>
                {type === 'phone'
                  ? `${selection.slug ? selection.slug + ' | ' : ''} ${
                      selection.phone
                    } ${
                      selection.label && selection.label.length > 0
                        ? '| ' + selection.label
                        : ''
                    }`
                  : type === 'email'
                  ? selection.email || selection
                  : selection}
              </span>
              <span
                className={style.selected_close}
                onClick={() => removeSelection(selection)}
                title="Remove"
              />
            </div>
          ))}
        </div>
      ) : (
        ''
      )}

      {generateInput(type)}
    </div>
  );
};

InputMulti.defaultProps = {
  width: 280,
  disabled: false,
};

export default InputMulti;
