import { appName } from "config";
import { createAction, createAsyncAction } from "typesafe-actions";
import { IResponseAdditionalFields } from "./models";
import {
  InvolvementCreateRequest,
  InvolvementCreateResponse,
  InvolvementDeleteRequest, InvolvementDeleteResponse,
  InvolvementGetListRequest,
  InvolvementGetListResponse,
  InvolvementGetRequest,
  InvolvementGetResponse,
  InvolvementUpdateRequest,
  InvolvementUpdateResponse
} from "@ternala/voltore-types";
import { IError, ILoader } from "models";
import { IException } from "../store";

/* Actions */
export const widgetName = "involvement";

// ** Action
export const getInvolvementsAction = createAsyncAction(
  `${appName}/${widgetName}/GET_INVOLVEMENTS_REQUEST`,
  `${appName}/${widgetName}/GET_INVOLVEMENTS_SUCCESS`,
  `${appName}/${widgetName}/GET_INVOLVEMENTS_FILED`
)<InvolvementGetListRequest & { additionalFields: IResponseAdditionalFields } & { callback?: Function }, { res: InvolvementGetListResponse, additionalFields: IResponseAdditionalFields, searchParams?: InvolvementGetListRequest, isAll: boolean }, IException>();

export const createInvolvementAction = createAsyncAction(
  `${appName}/${widgetName}/CREATE_INVOLVEMENT_REQUEST`,
  `${appName}/${widgetName}/CREATE_INVOLVEMENT_SUCCESS`,
  `${appName}/${widgetName}/CREATE_INVOLVEMENT_FILED`
)<InvolvementCreateRequest & { additionalFields: IResponseAdditionalFields } & { callback?: Function }, { res: InvolvementCreateResponse, additionalFields: IResponseAdditionalFields }, IException>();

export const getInvolvementAction = createAsyncAction(
  `${appName}/${widgetName}/GET_INVOLVEMENT_REQUEST`,
  `${appName}/${widgetName}/GET_INVOLVEMENT_SUCCESS`,
  `${appName}/${widgetName}/GET_INVOLVEMENT_FILED`
)<InvolvementGetRequest & { additionalFields: IResponseAdditionalFields } & { callback?: Function }, { res: InvolvementGetResponse, additionalFields: IResponseAdditionalFields }, IException>();

export const updateInvolvementAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_INVOLVEMENT_REQUEST`,
  `${appName}/${widgetName}/UPDATE_INVOLVEMENT_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_INVOLVEMENT_FILED`
)<InvolvementUpdateRequest & { additionalFields: IResponseAdditionalFields, callback?: Function }, { res: InvolvementUpdateResponse, additionalFields: IResponseAdditionalFields }, IException>();

export const deleteInvolvementAction = createAsyncAction(
  `${appName}/${widgetName}/DELETE_INVOLVEMENT_REQUEST`,
  `${appName}/${widgetName}/DELETE_INVOLVEMENT_SUCCESS`,
  `${appName}/${widgetName}/DELETE_INVOLVEMENT_FILED`
)<InvolvementDeleteRequest & { additionalFields: IResponseAdditionalFields } & { callback?: Function }, { res: InvolvementDeleteResponse, additionalFields: IResponseAdditionalFields, id?: number }, IException>();

export const addInvolvementLoader = createAction(`${widgetName}/ADD_INVOLVEMENT_LOADER`)<ILoader & { additionalFields: IResponseAdditionalFields }>();
export const addInvolvementError = createAction(`${widgetName}/ADD_INVOLVEMENT_ERROR`)<IError & { id: string } & { additionalFields: IResponseAdditionalFields }>();
export const removeInvolvementLoader = createAction(`${widgetName}/REMOVE_INVOLVEMENT_LOADER`)<{ id: string } & { additionalFields: IResponseAdditionalFields }>();
export const removeInvolvementError = createAction(`${widgetName}/REMOVE_INVOLVEMENT_ERROR`)<{ target: string } & { additionalFields: IResponseAdditionalFields }>();