import {
  CountyGetListRequest, CountyGetListResponse
} from "@ternala/voltore-types";

import { Config } from '../../../config/api';
import { authHeader, handleErrors } from '../../../utils/API';
import { appendSearchParams } from '../../../utils/appendSearchParams';
import { IError } from "../../model";

class API {
  public async getCounties(
    getData: CountyGetListRequest,
    token: string,
  ): Promise<CountyGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'county/list');

    url = appendSearchParams(url, getData);

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }
}

export const CountyApi = new API();
