import React, { FC } from 'react';
import {
  LeaseTransactionFullExpandDTO,
  PropertyShortDTO,
  SaleTransactionFullExpandDTO
} from "@ternala/voltore-types";
import { TaxonomyTypeSlug } from "@ternala/voltore-types/lib/constants";
import SaleTransactionItem from "./SaleTransactionItem";
import LeaseTransactionItem from "./LeaseTransactionItem";

import { ActionListTypeEnum, IActionElement } from "../../../model.d";
import { PropertyRiverIcon } from '../../../../icons/PropertyRiverIcon';
import { getItemPrice } from "../../../../../utils";

interface Props {
  action: IActionElement;
  index: number;
  isSelected: boolean;
  onClick?: (action: string) => void;

  type?: ActionListTypeEnum;
  property?: PropertyShortDTO;
  isExpanded?: boolean;
  isEnd?: boolean;

  disabled?: boolean;
}

export const ActionsListItem: FC<Props & { ref: React.Ref<HTMLDivElement> }> =
  React.forwardRef(({ action, index, onClick, isSelected, disabled, isExpanded, isEnd, property, type, children }, ref) => {
    const generateContent = () => {
      switch(type) {
        case ActionListTypeEnum.Sale_Transaction: {
          const transaction = property && action.data as SaleTransactionFullExpandDTO;
          return <SaleTransactionItem
            key={action.data?.id}
            data={{
              buyer: transaction?.buyer?.title,
              sqft: property?.sqft,
              price: transaction ? getItemPrice(transaction) : undefined,
              statuses: transaction?.historyStatuses,
              activeStatus: transaction?.activeStatus,
            }}
          />
        }
        case ActionListTypeEnum.Lease_Transaction: {
          const transaction = property && action.data as LeaseTransactionFullExpandDTO
          return <LeaseTransactionItem
            key={action.data?.id}
            transaction={transaction}
            data={{
              tenant: transaction?.tenant?.title,
              sqft: transaction?.leasableSQFT,
              price: transaction?.actualRate || transaction?.offeredRate,
              statuses: transaction?.historyStatuses,
              activeStatus: transaction?.activeStatus,
              commencement: transaction?.leaseCommencement,
              expiration: transaction?.leaseExpiration,
            }}
          />
        }
        default: {
          return <>
          {action.image ? (
            <div className="actions-list__item-image">
              {action.image ? (
                <img alt="" src={action.image} />
              ) : (
                <div className={'actions-list__item-image icon'}>
                  <PropertyRiverIcon />
                </div>
              )}
            </div>
          ) : (
            ''
          )}
          <div className={`actions-list__item-information`}>
            {action.category && <div className="category">{action.category}</div>}
            <div className="title">{action.title}</div>
            <div className="subtitle">{action.subTitle}</div>
            {disabled ? <div className="actions-list__item--already-added-mark">Already added</div> : ""}
          </div>
        </>
        }
      }
    }

    return (
      <div className={"actions-list__item-wrapper" + (isExpanded && property ? " actions-list__item-start" : "") + (isEnd ? " actions-list__item-end" : "")}>
        {isExpanded && property ? <div className={"actions-list__item-header"}>
          <div className="actions-list__item-image">
            {property?.image ? (
              <img alt="" src={property.image.url} />
            ) : (
              <div className={'actions-list__item-image icon'}>
                <PropertyRiverIcon />
              </div>
            )}
          </div>
          <div className={`actions-list__item-information`}>
            {property[TaxonomyTypeSlug.propertyType] && <div className="category">{property[TaxonomyTypeSlug.propertyType]?.map((type) => type.title).join(', ') || ''}</div>}
            {/*<div className="title">{property.title}</div>*/}
            <div className="subtitle">{property.address.address}</div>
          </div>
        </div> : ''}
        <div
          className={`actions-list__item ${isSelected ? ' actions-list__item_active' : ''} ${action.category ? 'category' : ''} ${disabled ? "disabled" : ''}`}
          key={action.key}
          tabIndex={index}
          data-index={index}
          onClick={() => onClick?.(action.key)}
          ref={ref}>
          {children ? children : generateContent()}
        </div>
      </div>
    );
  });

export default ActionsListItem;
