import React, { FC, useState, useEffect, ReactNode, useContext } from 'react';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { activityHistoryTypeEnum } from "@ternala/voltore-types/lib/constants";

/* modals & popups */
import EnterpriseModal from 'components/modals/EnterpriseModal';
import PopupDelete from 'components/modals/PopupDelete';

/* UI components */
import Loader from 'components/Loader';
import OptionsMenu from 'components/UI/controls/OptionsMenu';

/* controllers */
import {
  updateEnterpriseAction,
  deleteEnterpriseAction,
  getEnterpriseAction,
} from 'controllers/enterprise/actions';

/* constants */
import { PageEnum } from 'config/constants';

/* types */
import { IStore } from 'controllers/store';
import {
  IEnterpriseState,
  EnterpriseUpdateRequestExpanded, EnterpriseFullExpandedDTO
} from "controllers/enterprise/models";
import HistoryContext from 'context/History';
import { addNotification } from 'controllers/modals/actions';
import { NotificationTypeEnum } from 'controllers/modals/models.d';
import { TagTypeEnum } from 'controllers/tag/models.d';

// Components
import { CloseSmallIcon } from 'components/icons/CloseSmallIcon';
import Tags from 'components/Tags/Tags';

// Utils
import uuid from 'utils/uuid';
import { EnterpriseInfo } from "./EnterpriseInfo";
import { TagEntityEnum } from "@ternala/voltore-types/lib/card";

interface Props {
  enterpriseId: number;
  children?: ReactNode;
  onClose?: () => void;
  enterpriseData: IEnterpriseState['enterpriseData'];
}

const EnterpriseContacts: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useContext(HistoryContext);

  const {
    enterpriseId,
    onClose,
    enterpriseData,
  } = props;

  const currentEnterprise: EnterpriseFullExpandedDTO =  enterpriseData[enterpriseId];

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPopupDeleteOpen, setIsPopupDeleteOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getEnterpriseAction.request({
        id: enterpriseId,
        callback: () => setLoading(false),
      }),
    );
  }, []);

  return currentEnterprise ? (
    <>
      <Helmet>
        <title>
          {currentEnterprise?.title ? currentEnterprise?.title : 'Enterprise'} |
          Voltore
          {process.env.REACT_APP_COMPANY_NAME
            ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
            : ''}
        </title>
        <meta name="description" content="Voltore application" />
      </Helmet>
      <div className="enterprise-info" style={onClose && { height: '100%' }}>
        <header>
          <h2>Info</h2>
          <div className="options">
            <OptionsMenu
              on={{
                edit: () => setIsModalOpen(true),
                delete: () => setIsPopupDeleteOpen(true),
                ...(!onClose && {
                  history: () => {
                    history.setEntity({
                      id: currentEnterprise.id,
                      type: activityHistoryTypeEnum.enterprise,
                    })
                  }
                }),
              }}
            />
            {onClose ? (
              <div className="cross-icon" onClick={onClose}>
                <CloseSmallIcon />
              </div>
            ) : (
              ''
            )}
          </div>
        </header>
        <EnterpriseInfo enterprise={currentEnterprise}/>
      </div>
      <Tags
        cards={enterpriseData[enterpriseId]?.cardTagConnects || []}
        loading={loading}
        tabName={'properties'}
        preview={!!onClose}
        entity={{
          id: enterpriseId,
          type: TagEntityEnum.Enterprise,
        }}
      />

      {isModalOpen && (
        <EnterpriseModal
          enterpriseId={enterpriseId}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(payload) =>
            dispatch(
              updateEnterpriseAction.request({
                ...(payload as EnterpriseUpdateRequestExpanded),
                callback: (status: boolean) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Successfully updated',
                        type: NotificationTypeEnum.success,
                        title: 'Successfully updated',
                      }),
                    );
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (payload.callback) payload.callback(status);
                },
              }),
            )
          }
        />
      )}

      {isPopupDeleteOpen && (
        <PopupDelete
          onClose={() => setIsPopupDeleteOpen(false)}
          onSubmit={(callback) => {
            dispatch(
              deleteEnterpriseAction.request({
                id: enterpriseId,
                callback: (status: boolean) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Successfully deleted',
                        type: NotificationTypeEnum.success,
                        title: 'Successfully deleted',
                      }),
                    );
                    if (callback) callback();
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                },
              }),
            );
            dispatch(push(`/${PageEnum.ENTERPRISES}`));
          }}
        />
      )}
    </>
  ) : onClose ? (
    <div className="preview-modal-loader">
      <Loader />
    </div>
  ) : (
    <div className="item-info__loader_info-tab">
      <Loader />
    </div>
  );
};

export default connect((store: IStore) => ({
  enterpriseData: store.enterprise.enterpriseData,
}))(EnterpriseContacts);
