import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   width?: number
   height?: number
   color?: string
}

export const TrashIconAlt: FC<Props> = ({ width, height, color, ...props }) => (
   <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M7.50822 1.27799H5.75097V0.958496C5.75097 0.429985 5.32099 0 4.79248 0H3.51448C2.98597 0 2.55599 0.429985 2.55599 0.958496V1.27799H0.798746C0.358318 1.27799 0 1.63631 0 2.07674V3.19499C0 3.37143 0.143055 3.51448 0.319499 3.51448H0.494105L0.770131 9.31105C0.794513 9.82296 1.21505 10.224 1.72753 10.224H6.57943C7.09193 10.224 7.51247 9.82296 7.53683 9.31105L7.81286 3.51448H7.98746C8.16391 3.51448 8.30696 3.37143 8.30696 3.19499V2.07674C8.30696 1.63631 7.94864 1.27799 7.50822 1.27799ZM3.19499 0.958496C3.19499 0.782332 3.33832 0.638997 3.51448 0.638997H4.79248C4.96864 0.638997 5.11198 0.782332 5.11198 0.958496V1.27799H3.19499V0.958496ZM0.638997 2.07674C0.638997 1.98866 0.710665 1.91699 0.798746 1.91699H7.50822C7.5963 1.91699 7.66796 1.98866 7.66796 2.07674V2.87549C7.5695 2.87549 1.04702 2.87549 0.638997 2.87549V2.07674ZM6.89855 9.28065C6.89043 9.45129 6.75025 9.58496 6.57943 9.58496H1.72753C1.5567 9.58496 1.41652 9.45129 1.40841 9.28065L1.13382 3.51448H7.17314L6.89855 9.28065Z"
         fill={color}
      />
      <path
         d="M4.15348 8.9458C4.32993 8.9458 4.47298 8.80274 4.47298 8.6263V4.47282C4.47298 4.29638 4.32993 4.15332 4.15348 4.15332C3.97704 4.15332 3.83398 4.29638 3.83398 4.47282V8.6263C3.83398 8.80274 3.97702 8.9458 4.15348 8.9458Z"
         fill={color}
      />
      <path
         d="M5.75065 8.9458C5.92709 8.9458 6.07015 8.80274 6.07015 8.6263V4.47282C6.07015 4.29638 5.92709 4.15332 5.75065 4.15332C5.57421 4.15332 5.43115 4.29638 5.43115 4.47282V8.6263C5.43115 8.80274 5.57419 8.9458 5.75065 8.9458Z"
         fill={color}
      />
      <path
         d="M2.55534 8.9458C2.73178 8.9458 2.87484 8.80274 2.87484 8.6263V4.47282C2.87484 4.29638 2.73178 4.15332 2.55534 4.15332C2.3789 4.15332 2.23584 4.29638 2.23584 4.47282V8.6263C2.23584 8.80274 2.37888 8.9458 2.55534 8.9458Z"
         fill={color}
      />
   </svg>
)

TrashIconAlt.defaultProps = {
   width: 9,
   height: 11,
   color: '#1F2531'
}