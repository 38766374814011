import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const DotIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    {...props}
    width="5"
    height="5"
    viewBox="0 0 5 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{marginBottom: '3px', marginLeft: '5px', marginRight: '5px'}}>
    <circle cx="2.5" cy="2.5" r="2.5" fill={color} />
  </svg>
);

DotIcon.defaultProps = {
  color: '#D2E1E4',
};
