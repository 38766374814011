import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const DetachIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.79252 0C6.7347 0 6.67925 0.0229675 6.63837 0.0638498L0.0638501 6.63836C0.0229676 6.67924 -2.18861e-08 6.73469 0 6.79251C2.18861e-08 6.85032 0.0229676 6.90577 0.0638502 6.94665L3.82257 10.7054C3.9077 10.7905 4.04573 10.7905 4.13086 10.7054L5.44699 9.38924C5.80992 10.7205 7.02763 11.6992 8.47392 11.6992C10.2065 11.6992 11.6111 10.2947 11.6111 8.56209C11.6111 7.11579 10.6323 5.89809 9.30107 5.53515L10.6925 4.14377C10.7333 4.10289 10.7563 4.04744 10.7563 3.98962V0.217997C10.7563 0.0976008 10.6587 0 10.5383 0H6.79252ZM5.33679 8.56209C5.33679 8.66534 5.34178 8.76742 5.35153 8.8681L3.97672 10.2429L0.526293 6.79251L6.88282 0.435995H10.3203V3.89932L8.77994 5.4397C8.67925 5.42995 8.57717 5.42496 8.47392 5.42496C6.74134 5.42496 5.33679 6.8295 5.33679 8.56209ZM8.04354 2.05163C8.04354 1.72975 8.30448 1.46881 8.62636 1.46881C8.94825 1.46881 9.20919 1.72975 9.20919 2.05163C9.20919 2.37352 8.94825 2.63446 8.62636 2.63446C8.30448 2.63446 8.04354 2.37352 8.04354 2.05163ZM8.62636 1.03281C8.06368 1.03281 7.60754 1.48895 7.60754 2.05163C7.60754 2.61431 8.06368 3.07046 8.62636 3.07046C9.18904 3.07046 9.64518 2.61431 9.64518 2.05163C9.64518 1.48895 9.18904 1.03281 8.62636 1.03281ZM5.77279 8.56209C5.77279 7.0703 6.98213 5.86096 8.47392 5.86096C9.96572 5.86096 11.1751 7.0703 11.1751 8.56209C11.1751 10.0539 9.96572 11.2632 8.47392 11.2632C6.98213 11.2632 5.77279 10.0539 5.77279 8.56209ZM6.61279 8.31827C6.4924 8.31827 6.3948 8.41588 6.3948 8.53627C6.3948 8.65667 6.4924 8.75427 6.61279 8.75427H10.2036C10.324 8.75427 10.4216 8.65667 10.4216 8.53627C10.4216 8.41588 10.324 8.31827 10.2036 8.31827H6.61279Z"
      fill="#1F2531"
    />
  </svg>
);

DetachIcon.defaultProps = {
  color: '#1F2531',
};

export default DetachIcon;
