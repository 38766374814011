import { Config } from '../../../config/api';
import { authHeader, handleErrors } from '../../../utils/API';

import {
  InvolvementCreateRequest,
  InvolvementCreateResponse,
  InvolvementDeleteRequest,
  InvolvementDeleteResponse,
  InvolvementGetListRequest,
  InvolvementGetListResponse,
  InvolvementGetListTransactionSideRequest,
  InvolvementGetRequest,
  InvolvementGetResponse, InvolvementUpdateRequest, InvolvementUpdateResponse,
} from '@ternala/voltore-types';
import { appendSearchParams } from '../../../utils/appendSearchParams';
import { AsyncOptions } from 'components/UI/controls/SelectSearchCustom';
import {IError} from "../../model";

class API {
  public async getInvolvements(
    getInvolvementsData: InvolvementGetListRequest,
    token: string,
  ): Promise<InvolvementGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'involvement/list');

    url = appendSearchParams(url, getInvolvementsData);

    if (getInvolvementsData.hasOwnProperty('person'))
      url.searchParams.append('person', String(getInvolvementsData.person));
    if (getInvolvementsData.hasOwnProperty('enterprise'))
      url.searchParams.append(
        'enterprise',
        String(getInvolvementsData.enterprise),
      );
    if (getInvolvementsData.hasOwnProperty('timeMarker'))
      url.searchParams.append(
        'timeMarker',
        String(getInvolvementsData.timeMarker),
      );

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async createInvolvement(
    createInvolvementData: InvolvementCreateRequest,
    token: string,
  ): Promise<InvolvementCreateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'involvement/create');

    return handleErrors(
      fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(createInvolvementData),
      }),
    );
  }

  public async getInvolvement(
    getInvolvementData: InvolvementGetRequest,
    token: string,
  ): Promise<InvolvementGetResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'involvement/get');

    url.searchParams.append('id', String(getInvolvementData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async updateInvolvement(
    updateInvolvementData: InvolvementUpdateRequest,
    token: string,
  ): Promise<InvolvementUpdateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'involvement/update');
    return handleErrors(
      fetch(url.toString(), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(updateInvolvementData),
      }),
    );
  }

  public async deleteInvolvement(
    deleteInvolvementData: InvolvementDeleteRequest,
    token: string,
  ): Promise<InvolvementDeleteResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'involvement/delete');

    url.searchParams.append('id', String(deleteInvolvementData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async getAllInvolvementsByEnterpriseId(
    token: string,
    id: number,
    searchParams: AsyncOptions,
  ): Promise<InvolvementGetListResponse | null> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'involvement/list');
    if (searchParams) url = appendSearchParams(url, searchParams);

    try {
      const response = await fetch(String(url), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      });
      const data = await response.json();
      if (response.statusText === 'OK' || response.status === 200) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getInvolvementsByType(
    getInvolvementsData: InvolvementGetListTransactionSideRequest,
    searchParams: AsyncOptions,
    token: string,
  ): Promise<InvolvementGetListResponse> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'involvement/list');
    url = appendSearchParams(url, searchParams);
    if (getInvolvementsData.hasOwnProperty('enterprise'))
      url.searchParams.append(
        'enterprise',
        String(getInvolvementsData.enterprise),
      );
    if (getInvolvementsData.hasOwnProperty('type'))
      url.searchParams.append('type', String(getInvolvementsData.type));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }
}

export const InvolvementApi = new API();
