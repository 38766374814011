/* eslint-disable */
import {
  DraftEntityMutability,
  DraftInlineStyleType,
  EditorState,
  Modifier,
  RawDraftContentState,
} from 'draft-js';
import { TagEditorStepEnum, tagEntityType } from '../../model';
import { TagDTO } from '@ternala/voltore-types';
import { entityToAction, TagEntityEnum } from '@ternala/voltore-types/lib/card';

import { entitySymbol, generateStartPhrase, tagSymbol } from '../config';

export const generateContent = ({
  entity,
  tag,
}: {
  entity?: tagEntityType;
  tag?: TagDTO;
}): RawDraftContentState => {
  const startPhrase = generateStartPhrase();
  let text = startPhrase + ' ';
  let entityLength = 0;
  let entityOffset = 0;
  let tagLength = 0;
  let tagOffset = 0;

  let content = {
    entityMap: {
      0: {
        type: 'ACTION',
        mutability: 'IMMUTABLE' as DraftEntityMutability,
        data: {
          title: text,
        },
      },
    },
    blocks: [
      {
        key: '9k5h7',
        text,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            length: text.length,
            offset: 0,
            style: 'CREATE_ENTITY' as DraftInlineStyleType,
          },
        ],
        entityRanges: [
          {
            offset: 0,
            length: text.length - 1,
            key: 0,
          },
        ],
        data: {},
      },
    ],
  };

  if (entity) {
    const entityText = generateEntityName(entity) + entitySymbol;
    entityLength = entityText.length;
    entityOffset = text.length + 1;
    text += ` ${entityText} `;
    // @ts-ignore
    content.entityMap[1] = {
      type: 'ENTITY',
      mutability: 'IMMUTABLE' as DraftEntityMutability,
      data: {
        title: entityText,
        action: entity,
        id: entity.id,
      },
    };
    content.blocks[0].entityRanges.push({
      offset: entityOffset,
      length: entityLength,
      key: 1,
    });

    if (tag) {
      const tagText = generateTagName(tag) + tagSymbol;
      tagLength = tagText.length;
      tagOffset = text.length;
      text += `${tagText}`;

      // @ts-ignore
      content.entityMap[2] = {
        type: 'TAG',
        mutability: 'IMMUTABLE' as DraftEntityMutability,
        data: {
          title: tagText,
          action: tag,
          id: tag.id,
        },
      };
      content.blocks[0].entityRanges.push({
        offset: tagOffset,
        length: tagLength,
        key: 2,
      });
    }
  }
  content.blocks[0].text = text;

  return content;
};

export const generateEntityName = (entity: tagEntityType): string => {
  return entityToAction(entity).entityTitle;
};

export const generateTagName = (tag: TagDTO): string => {
  return tag.title;
};

export const insertDraftEntity = (
  {
    cardEntityType,
    id,
    title,
    data,
    startPhrase,
  }: {
    cardEntityType: TagEntityEnum | TagEditorStepEnum.Tag;
    id: number;
    title: string;
    data: any;
    startPhrase: string;
  },
  editorState: EditorState,
  symbol: string,
  entityType: 'TAG' | 'ENTITY',
) => {
  const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection();

  const currentBlockKey = editorState.getSelection().getStartKey();
  const carriagePosition = editorState.getSelection().getFocusOffset();

  const currentText = currentContent.getBlockForKey(currentBlockKey).getText();

  const prevCarriageText = currentText.slice(0, carriagePosition);

  const targetSymbolPosition = prevCarriageText.indexOf(symbol);

  const newSelection = currentSelection.merge({
    anchorOffset: targetSymbolPosition + startPhrase.length,
    focusOffset: carriagePosition,
  });

  const stateWithEntity = editorState
    .getCurrentContent()
    .createEntity(entityType, 'IMMUTABLE', {
      data,
      title,
      id,
    });

  const entityKey = stateWithEntity.getLastCreatedEntityKey();

  const stateWithText = Modifier.replaceText(
    stateWithEntity,
    newSelection,
    title,
    undefined,
    entityKey,
  );

  const stateWithTextWithSpace = Modifier.insertText(
    stateWithText,
    stateWithText.getSelectionAfter(),
    ' ',
  );

  const newEditorState = EditorState.push(
    editorState,
    stateWithTextWithSpace,
    'insert-fragment',
  );

  return EditorState.forceSelection(
    newEditorState,
    stateWithTextWithSpace.getSelectionAfter(),
  );
};
