import {
  CardCreateRequest,
  CardCreateResponse,
  CardDeleteRequest,
  CardDeleteResponse,
  CardGetListRequest,
  CardGetListResponse,
  CardGetRequest,
  CardGetResponse,
  CardUpdateRequest,
  CardUpdateResponse,
} from '@ternala/voltore-types';

import { Config } from '../../../config/api';
import { authHeader, handleErrors } from '../../../utils/API';
import { appendSearchParams } from '../../../utils/appendSearchParams';
import { IError } from '../../model';

class API {
  public async getCards(
    getCardData: CardGetListRequest,
    token: string,
  ): Promise<CardGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'card/list');

    url = appendSearchParams(url, getCardData);

    if (getCardData.hasOwnProperty('property'))
      url.searchParams.append('property', `${getCardData.property}`);
    if (getCardData.hasOwnProperty('enterprise'))
      url.searchParams.append('enterprise', `${getCardData.enterprise}`);
    if (getCardData.hasOwnProperty('person'))
      url.searchParams.append('person', `${getCardData.person}`);
    if (getCardData.hasOwnProperty('involvement'))
      url.searchParams.append('involvement', `${getCardData.involvement}`);
    if (getCardData.hasOwnProperty('leaseTransaction'))
      url.searchParams.append(
        'leaseTransaction',
        `${getCardData.leaseTransaction}`,
      );
    if (getCardData.hasOwnProperty('saleTransaction'))
      url.searchParams.append(
        'saleTransaction',
        `${getCardData.saleTransaction}`,
      );
    if (getCardData.hasOwnProperty('isComplete'))
      url.searchParams.append('isComplete', `${getCardData.isComplete}`);
    if (getCardData.hasOwnProperty('includeOwnedProperties'))
      url.searchParams.append(
        'includeOwnedProperties',
        `${getCardData.includeOwnedProperties}`,
      );
    if (getCardData.hasOwnProperty('onlyReminder'))
      url.searchParams.append('onlyReminder', `${getCardData.onlyReminder}`);
    if (getCardData.hasOwnProperty('author'))
      url.searchParams.append('author', `${getCardData.author}`);

    if (Array.isArray(getCardData.reminderRoles)) {
      getCardData.reminderRoles?.forEach((role) => {
        url.searchParams.append(`reminderRoles[]`, String(role));
      });
    }

    if (getCardData.hasOwnProperty('isDeleted'))
      url.searchParams.append('isDeleted', `${getCardData.isDeleted}`);


    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async createCard(
    createCardData: CardCreateRequest,
    token: string,
  ): Promise<CardCreateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'card/create');

    return handleErrors(
      fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(createCardData),
      }),
    );
  }

  public async getCard(
    getCardData: CardGetRequest,
    token: string,
  ): Promise<CardGetResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + `card/${getCardData.id}`);

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async updateCard(
    updateCardData: CardUpdateRequest,
    token: string,
  ): Promise<CardUpdateResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'card/update');

    return handleErrors(
      fetch(url.toString(), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(updateCardData),
      }),
    );
  }

  public async deleteCard(
    deleteCardData: CardDeleteRequest,
    token: string,
  ): Promise<CardDeleteResponse | string> {
    return handleErrors(
      fetch(
        new URL(
          Config.MAIN_SERVICE_ENDPOINT + `card/delete/${deleteCardData.id}`,
        ).toString(),
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            ...authHeader(token),
          },
        },
      ),
    );
  }
}

export const CardApi = new API();
