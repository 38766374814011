import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { omit } from 'lodash';
import {
  CardCreateResponse, CardDTO, CardEntityConnectDTO,
  CardGetListResponse,
  CardGetResponse,
  CardUpdateResponse
} from "@ternala/voltore-types";
import {TagEntityEnum} from "@ternala/voltore-types/lib/card"

// APIs
import { CardApi } from '../transport/card.api';

// Actions
import {
  getCardsAction,
  getCardAction,
  createCardAction,
  updateCardAction,
  deleteCardAction,
} from '../actions';

import { addError, addLoader, removeLoader } from '../index';

// Utils
import uuid from 'utils/uuid';

// Constants
import { LoaderAction } from 'config/constants';
import { addTagAction, deleteTagLocallyAction } from 'controllers/tag/actions';
import { IError } from "../../model";
import { getAccessTokenSaga } from "../../auth/sagas/auth";
import { IStore } from "../../store";

export function* getCardsSaga({
  payload,
}: ReturnType<typeof getCardsAction.request>) {
  const accessToken:string | undefined = yield call(getAccessTokenSaga);

  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, cards are loading!',
      type: LoaderAction.card.getList,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: CardGetListResponse | string | IError = yield CardApi.getCards(data, accessToken);

    if(typeof res === "undefined" || typeof res === "string" || 'message' in res){
      if(typeof res === "string"){
        getCardsAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getCardsAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get cards!',
          type: LoaderAction.card.getList,
        }),
      );
    } else {
      yield put(
        getCardsAction.success({
          response: res,
          searchParams: payload,
          isAll: payload.limit ? res.items.length < payload.limit : true,
        }),
      );
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
    }
    if (typeof payload.callback === 'function') payload.callback();
  } catch (error: any) {
    console.error('error: ', error);
    yield put(
      getCardsAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to get cards!',
        type: LoaderAction.card.getList,
      }),
    );
  }
}

export function* createCardSaga({
  payload,
}: ReturnType<typeof createCardAction.request>) {
  const accessToken:string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, card is creating!',
      type: LoaderAction.card.create,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: CardCreateResponse | string | IError = yield CardApi.createCard(data, accessToken);

    if(typeof res === "undefined" || typeof res === "string" || 'message' in res){
      if(typeof res === "string"){
        createCardAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          createCardAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to create card!',
          type: LoaderAction.card.create,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(false);
    } else {
      const resData = res as CardCreateResponse;
      yield put(createCardAction.success(res));

      for (const item of resData.items) {
        const connect = item.cardTagConnect;
        if (connect?.tag) {
          let key: keyof CardEntityConnectDTO;
          for (key in connect) {
            if (key in TagEntityEnum) {
              let newKey = key as unknown as TagEntityEnum;
              const entity = connect[newKey];
              if (entity) {
                yield put(
                  addTagAction({
                    tag: connect.tag,
                    entityId: entity.id,
                    entityType: newKey,
                  }),
                );
              }
            }
          }
        }
      }

      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    console.error('error: ', error);
    yield put(
      createCardAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to create card!',
        type: LoaderAction.card.create,
      }),
    );
    if (typeof payload.callback === 'function') payload.callback(false);
  }
}

export function* getCardSaga({
  payload,
}: ReturnType<typeof getCardAction.request>) {
  const accessToken:string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, card is getting!',
      type: LoaderAction.card.getItem,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: string | IError | CardGetResponse = yield CardApi.getCard(data, accessToken);
    if(typeof res === "undefined" || typeof res === "string" || 'message' in res){
      if(typeof res === "string"){
        createCardAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          createCardAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get card!',
          type: LoaderAction.card.getItem,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(false);
    } else {
      yield put(getCardAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    console.error('error: ', error);
    yield put(
      getCardAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to get card!',
        type: LoaderAction.card.getItem,
      }),
    );
    if (typeof payload.callback === 'function') payload.callback(false);
  }
}

export function* updateCardSaga({
  payload,
}: ReturnType<typeof updateCardAction.request>) {
  const accessToken:string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, card is updating!',
      type: LoaderAction.card.update,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: CardUpdateResponse | string | IError = yield CardApi.updateCard(data, accessToken);
    if(typeof res === "undefined" || typeof res === "string" || 'message' in res){
      if(typeof res === "string"){
        createCardAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          createCardAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to update card!',
          type: LoaderAction.card.update,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(false);
    } else {
      yield put(
        updateCardAction.success({
          ...res,
          updateCardId: payload.id,
        }),
      );
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    console.error('error: ', error);
    yield put(
      updateCardAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to update card!',
        type: LoaderAction.card.update,
      }),
    );
    if (typeof payload.callback === 'function') payload.callback(false);
  }
}

export function* deleteCardSaga({
  payload,
}: ReturnType<typeof deleteCardAction.request>) {
  const accessToken:string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, card is deleting!',
      type: LoaderAction.card.delete,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    const request = omit(payload, ['tag', 'callback']);
    const res: string | IError = yield CardApi.deleteCard(request, accessToken);
    if(typeof res === "undefined" || typeof res === "string"){
      createCardAction.failure({
        code: res,
        message: res || 'Something was wrong',
      });
      yield put(
        addError({
          id: loadId,
          message: 'Failed to delete card!',
          type: LoaderAction.card.delete,
        }),
      );
    } else {
      const card: CardDTO = yield select((store: IStore) => store.card.cardData[payload.id])
      yield put(
        deleteCardAction.success({
          ...res,
          id: payload.id,
          card
        }),
      );

      if (payload.tag) {
        for (const key in payload.tag) {
          if (key in TagEntityEnum) {
            const newKey = key as TagEntityEnum;
            const entity = payload.tag[newKey];
            if (entity) {
              yield put(
                deleteTagLocallyAction({
                  tagId: Number(payload.tag?.tag?.id),
                  entityId: entity.id,
                  entityType: newKey,
                }),
              );
            }
          }
        }
      }

      yield put(
        removeLoader({
          id: loadId,
        }),
      );
    }
    if (typeof payload.callback === 'function') payload.callback();
  } catch (error: any) {
    console.error('error: ', error);
    yield put(
      deleteCardAction.failure({
        code: error.code || 403,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to delete card!',
        type: LoaderAction.card.delete,
      }),
    );
  }
}

export function* cardActionSaga() {
  yield all([
    takeEvery(getCardsAction.request, getCardsSaga),
    takeEvery(getCardAction.request, getCardSaga),
    takeEvery(createCardAction.request, createCardSaga),
    takeEvery(updateCardAction.request, updateCardSaga),
    takeEvery(deleteCardAction.request, deleteCardSaga),
  ]);
}
