import React, { FC, ReactNode, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

/* components */
import CompanyInfo from 'components/CompanyInfo';

/* controllers */
import { getPropertyTreeAction } from 'controllers/property/actions';

/* types */
import { IStore } from 'controllers/store';
import { RouterState } from 'connected-react-router';
import { IPropertyState } from 'controllers/property/models';
import {
  EnterpriseFullDTO,
  EnterpriseFullWithTreeDTO,
} from '@ternala/voltore-types';
import CustomScrollbars from 'components/CustomScrollbars';
import Loader from 'components/Loader';
// Utils
import { getListImage } from "utils/getImage";

interface IProps {
  propertyId: number;
  tabName: string;
  routerState: RouterState;
  propertyState: IPropertyState;
  children?: ReactNode;
}

const PropertyOwnershipTab: FC<IProps> = (props) => {
  const {
    propertyId,
    propertyState: { propertyData },
  } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [noParentProperty, setNoParentProperty] = useState<boolean>(false);

  const [hoveredEnterpriseId, setHoveredEnterpriseId] = useState<number | null>(
    null,
  );
  const [selectedEnterpriseId, setSelectedEnterpriseId] = useState<
    number | null
  >(null);

  const currentProperty = propertyData[propertyId];

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      getPropertyTreeAction.request({
        id: propertyId,
        callback: (state: IStore) => {
          setLoading(false);
          if (!state) setNoParentProperty(true);
          else setNoParentProperty(false);
        },
      }),
    );
  }, []);

  const generateItem = (
    item: EnterpriseFullDTO,
    title?: string,
  ): JSX.Element => (
    <div
      className={
        'ownership-item' +
        (item.id === currentProperty.id ? ' this-company' : ' company') +
        (hoveredEnterpriseId === item.id ? ' hovered' : '') +
        (selectedEnterpriseId === item.id ? ' selected' : '')
      }
      onMouseOver={() => setHoveredEnterpriseId(item.id)}
      onMouseOut={() => setHoveredEnterpriseId(null)}
      onClick={() => setSelectedEnterpriseId(item.id)}
      key={item.id}>
      {title ? (
        <span
          className={
            'ownership-item__title' +
            (hoveredEnterpriseId === item.id ? ' hovered' : '') +
            (selectedEnterpriseId === item.id ? ' selected' : '')
          }>
          {title}
        </span>
      ) : item.images?.length ? (
        <div
          className={
            'ownership-item__image' +
            (hoveredEnterpriseId === item.id ? ' hovered' : '') +
            (selectedEnterpriseId === item.id ? ' selected' : '')
          }>
          <div className="" />
          <img src={getListImage(item.images)} alt="company img" />
        </div>
      ) : (
        <span
          className={
            'ownership-item__title-company' +
            (hoveredEnterpriseId === item.id ? ' hovered' : '') +
            (selectedEnterpriseId === item.id ? ' selected' : '')
          }>
          {item.title}
        </span>
      )}
    </div>
  );

  const generateBlock = (
    item: EnterpriseFullWithTreeDTO,
    property: 'parentsTree' | 'childrenTree',
  ): JSX.Element => {
    const res = [generateItem(item)];

    if (item[property]?.length) {
      if (property === 'parentsTree') {
        res.unshift(
          <div className="ownership-children parents-tree" key={item.id}>
            {item[property]?.map((subItem) => generateBlock(subItem, property))}
          </div>,
        );
      } else {
        res.push(
          <div className="ownership-children children-tree" key={item.id}>
            {item[property]?.map((subItem) => generateBlock(subItem, property))}
          </div>,
        );
      }
    }

    return <div className="ownership-block">{res}</div>;
  };

  return (
    <CustomScrollbars>
      <Helmet>
        <title>
          Ownership | Voltore
          {process.env.REACT_APP_COMPANY_NAME
            ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
            : ''}
        </title>
        <meta name="description" content="Voltore application" />
      </Helmet>
      {!noParentProperty ? (
        loading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          <div className="ownership-tab">
            {selectedEnterpriseId ? (
              <CompanyInfo id={selectedEnterpriseId} />
            ) : (
              ''
            )}

            <div className="ownership-structure">
              <div className="dynamic-area">
                {currentProperty?.parentsTree?.length ? (
                  <div className="ownership-parent-top">
                    {currentProperty?.parentsTree?.map((parent) => {
                      return generateBlock(parent, 'parentsTree');
                    })}
                  </div>
                ) : (
                  ''
                )}
                <div className="ownership-current-property">
                  <div className="ownership-item">
                    <span className="ownership-title">This property</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <span className="no-items-specified">No parent property is specified.</span>
      )}
    </CustomScrollbars>
  );
};

export default connect((store: IStore) => ({
  routerState: store.router,
  propertyState: store.property,
}))(PropertyOwnershipTab);
