import React, { useState, useEffect } from 'react';

/* libraries */
import { useTrail, a } from '@react-spring/web';
import { connect, useDispatch } from 'react-redux';
import { getHistoryAction } from 'controllers/history/actions';

/* types */
import { IStore } from 'controllers/store';
import {
  ActivityHistoryGetListRequest,
  activityHistoryTypeEnum,
} from '@ternala/voltore-types';
import { IHistoryState } from 'controllers/history/models.d';

/* components */
import Loader from 'components/Loader';
import HistoryCard from './Controls/HistoryCard';
import CustomScrollbars from 'components/CustomScrollbars';
import { CloseDarkIcon } from 'components/icons/Notes/CloseDarkIcon';

interface IProps {
  onClose: () => void;
  entity: {
    id?: number;
    type?: activityHistoryTypeEnum;
  };
  historyState: IHistoryState;
}

/* animation */
const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    from: { opacity: 0, x: 20, height: 0 },
  });
  return (
    <div>
      {trail.map(({ ...style }, index) => (
        <a.div key={index} className={'trail'} style={style}>
          <a.div style={{ height: '100vh' }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  );
};

const History = ({ onClose, entity, historyState }: IProps) => {
  /* History state */
  const { history } = historyState;

  // General context (filters, modals)
  /* Use state (hook) */
  const [trail, setTrail] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    if (entity.id) {
      setTrail(true);
      setLoading(false);
    } else {
      setTrail(false);
    }
  }, [entity.id, entity.type]);

  useEffect(() => {
    setLoading(true);
    loadHistory(() => setLoading(false), entity.id, entity.type);
  }, []);

  /* load functionality */
  const loadHistory = (
    callback: Function,
    id?: number,
    type?: activityHistoryTypeEnum,
  ) => {
    if (id === undefined) return;
    if (type === undefined) return;
    const searchParams: ActivityHistoryGetListRequest = {
      entityId: id,
      entityName: type,
    };
    dispatch(getHistoryAction.request({ ...searchParams, callback }));
  };

  /* Sort cards by date */
  const sortedCards =
    history.length &&
    history.sort((a, b) => {
      return (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    });

  return (
    <div className="window history">
      <Trail open={trail}>
        <div className="window-content">
          <div className={'header history'}>
            <div className={'header-title'}>Activity history</div>
            <div className={'header-close'} onClick={onClose}>
              <CloseDarkIcon />
            </div>
          </div>
          {loading ? (
            <div className="items-list__loader" style={{ height: '100vh' }}>
              <Loader />
            </div>
          ) : (
            <CustomScrollbars
              style={{ height: '100vh' }}
              renderView={(props) => (
                <div
                  {...props}
                  className={'datalist-items-y'}
                  style={{
                    position: 'absolute',
                    inset: '0px',
                    overflow: 'scroll',
                    marginRight: '-19px',
                    marginBottom: '-19px',
                    paddingLeft: '10px',
                    paddingBottom: '20px',
                  }}
                />
              )}>
              <>
                {sortedCards?.length ? (
                  sortedCards.map((card, index) => (
                    <HistoryCard card={card} type={entity.type} key={index} />
                  ))
                ) : (
                  <span className="no-cards-specified">
                    No cards are specified.
                  </span>
                )}
              </>
            </CustomScrollbars>
          )}
        </div>
      </Trail>
    </div>
  );
};

export default connect((store: IStore) => ({
  historyState: store.history
}))(History);
