
import React, { FC, useState, useEffect, useRef, CSSProperties } from 'react';
import ReactSelect, {
  components,
  IndicatorProps,
  MenuListComponentProps,
} from 'react-select';
import Scrollbars from 'react-custom-scrollbars';

/* styles */
import { SelectDropdownIcon } from 'components/icons/SelectDropdownIcon';
import {
  sharedStyles,
  Color,
  stateColor,
  ControlStateType,
} from './shared/styles';
import style from './shared/styles/control.module.scss';

/* types */
import { StylesConfig } from 'react-select/src/styles';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { OptionProps } from 'react-select/src/components/Option';
import { OptionType } from 'models';

interface Props {
  className?: string;
  containerStyles?: { [key: string]: number | string };
  label?: string;
  placeholder?: string;
  name?: string;
  value?: OptionType;
  options: OptionType[];
  onChange: (option: OptionType) => void;
  optionHeight?: number;
  maxOptions?: number;
  width?: number;
  halfWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  autoResetValue?: boolean;
  children?: JSX.Element | JSX.Element[];
}

export const Select: FC<Props> = (props) => {
  const { options, label, disabled, required, width, autoResetValue } = props;

  const [placeholder, setPlaceholder] = useState<string | undefined>('');

  useEffect(() => {
    props.value && setPlaceholder(props.value?.label);
  }, []);

  useEffect(() => {
    setPlaceholder('');
  }, [props.value]);

  const innerRef = useRef() as React.RefObject<any>;

  const menuHeight =
    props.optionHeight &&
    props.maxOptions &&
    props.optionHeight * props.maxOptions;

  const customStyles: StylesConfig<any, any> = {
    ...(sharedStyles as any),
    container: (base: any) => ({
      ...base,
      width: width ? width : props.halfWidth && '50%',
      marginTop: -8,
    }),
    placeholder: (
      base: any,
      state: PlaceholderProps<any, any>,
    ): CSSProperties => ({
      ...base,
      color: props.placeholder ? Color.default : stateColor(state as any, true),
      lineHeight: props.placeholder ? '15px' : undefined,
      fontSize: props.placeholder ? '12px' : undefined,
    }),
    option: (base: any, state: OptionProps<any, any>) => ({
      ...base,
      padding: 10,
      ':active': {
        backgroundColor:
          !autoResetValue && isOptionSelected(state as any)
            ? Color.main
            : Color.selection,
      },
      height: props.optionHeight,
      WebkitTapHighlightColor:
        !autoResetValue && isOptionSelected(state as any)
          ? Color.main
          : Color.selection,
      cursor:
        !autoResetValue && isOptionSelected(state as any)
          ? 'default'
          : 'pointer',
      color:
        !autoResetValue && isOptionSelected(state as any)
          ? Color.disabled
          : state.isFocused && Color.main,
      backgroundColor:
        !autoResetValue && isOptionSelected(state as any)
          ? Color.main
          : state.isFocused && Color.optionHover,
    }),
  };

  const handleChange = (option: OptionType) => {
    if (!autoResetValue && props.value?.value === option.value) return;
    innerRef.current.select.blur();
    props.onChange(option);
  };

  const isOptionSelected = (state: ControlStateType): boolean => {
    return state.label === props.value?.label || state.value === placeholder;
  };

  const DropdownIndicator = (
    componentProps: IndicatorProps<any, any>,
  ): JSX.Element =>
    components.DropdownIndicator && (
      <components.DropdownIndicator {...componentProps}>
        <SelectDropdownIcon
          color={props.disabled ? Color.disabled : Color.active}
        />
      </components.DropdownIndicator>
    );

  const ScrollbarRenderer = (
    componentProps: MenuListComponentProps<any, any>,
  ): JSX.Element => (
    <Scrollbars
      autoHeight
      autoHeightMax={menuHeight}
      renderThumbVertical={(props) => (
        <div {...props} className={style.scrollthumby} />
      )}>
      <>{componentProps.children}</>
    </Scrollbars>
  );

  return (
    <div className={style.container} style={{ ...props.containerStyles }}>
      {label && (
        <div
          className={style.label}
          style={{
            color: disabled ? Color.disabled : Color.label,
            marginTop: -5,
          }}>
          {label}
          {required && <span className={style.required}>*</span>}
        </div>
      )}

      <ReactSelect
        ref={innerRef}
        className={style.select}
        styles={customStyles}
        name={props.name}
        options={options}
        placeholder={props.placeholder || placeholder}
        value={
          autoResetValue
            ? props.placeholder
            : options.filter(
                (option: OptionType) => option.label === props.value?.label,
              )
        }
        onChange={(option: OptionType) => handleChange(option)}
        autoBlur
        isDisabled={disabled}
        isSearchable={false}
        /* use this option with default scrollbar */
        maxMenuHeight={menuHeight}
        captureMenuScroll={false}
        components={{
          DropdownIndicator,
          MenuList: ScrollbarRenderer,
        }}
      />
    </div>
  );
};

Select.defaultProps = {
  optionHeight: 35,
  maxOptions: 5,
};

export default Select;
