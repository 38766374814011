import React, {
  FC,
  useState,
  useEffect,
  ChangeEvent,
  MouseEvent,
  FormEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import { UserCreateRequest, UserCreateResponse, UserRoleCreateResponse } from "@ternala/voltore-types";
import { UserDTO } from '@ternala/voltore-types/lib/modules/user/user.dto';
import { UserTypeEnum } from '@ternala/voltore-types/lib/constants';

import { FormGroup, FormControl, Validators } from 'lib/validation';

/* components */
import Modal from 'components/Hocs/Portal';
import Input from 'components/UI/controls/Input';
import Select from 'components/UI/controls/Select';
import SelectSearch from 'components/UI/controls/SelectSearchCustom';
import Button from 'components/UI/controls/Button';

/* constants */
import { errorMessages } from 'config';
import {
  generatePersonLabel,
  loadPersonsOptions,
  loadUserRoleOptions,
  userStatusOptions,
  userTypeOptions
} from "config/constants/select-options";

/* types */
import { OptionType } from 'models';
import SelectMulti from 'components/UI/controls/SelectMultiSearch';
import { createUserAction } from 'controllers/user/actions';
import {
  IModalElement,
  ShowElementEnum,
} from 'controllers/showElement/models.d';
import {
  addShowedElement,
  removeShowedElement,
} from 'controllers/showElement/actions';
import CustomScrollbars from 'components/CustomScrollbars';
import {
  getAccessTokenUtil,
  callbackTypeEnum,
  requestCallback,
  uuid,
} from 'utils';
import { RequestActionEnum } from 'config/constants';
import { userRoleModalKey } from '../UserRoleModal';
import { personModalKey } from '../PersonModal';

export const createUserInfoModalKey = 'CreateUserInfoModalKey';

export interface ICreateUserInfoModalProps {
  showElementId?: string;
  isByShowElement?: boolean;
  id?: number;
  completeAction?: (user: UserDTO) => void;

  onCreatePerson: () => void;
  onClose: () => void;
  onSubmit?: (
    payload: UserCreateRequest & {
      callback?: Function;
    },
    requestAction: RequestActionEnum,
  ) => void;
  onCreate?: (user: UserCreateRequest, callback: Function) => void;
  onCreateUserRole: () => void;
}

const createForm = () => {
  return new FormGroup({
    login: new FormControl(
      {
        type: 'email',
        label: 'login',
        placeholder: 'example@domain.com',
        required: true,
      },
      [Validators.required, Validators.maxLength(100)],
      errorMessages,
    ),
    password: new FormControl(
      {
        type: 'password',
        label: 'Password',
        placeholder: 'At least 8 characters required',
        required: true,
      },
      [Validators.required, Validators.minLength(8), Validators.maxLength(50)],
      errorMessages,
    ),
    passwordConfirm: new FormControl(
      {
        type: 'password',
        label: 'Confirm password',
        placeholder: 'At least 8 characters required',
        required: true,
      },
      [Validators.required, Validators.minLength(8), Validators.maxLength(50)],
      errorMessages,
    ),
  });
};

const CreateUserInfoModal: FC<ICreateUserInfoModalProps> = ({
  showElementId,
  isByShowElement,
  onClose,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const [formGroup, setFormGroup] = useState<FormGroup>(createForm());
  const [requestProcess, setRequestProcess] = useState<boolean>(false);
  const [type, setType] = useState<OptionType>(userTypeOptions[0]);
  const [status, setStatus] = useState<OptionType>(userStatusOptions[0]);
  const [person, setPerson] = useState<OptionType | undefined>();
  const [roles, setRoles] = useState<OptionType[] | undefined>();

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onCloseHandler();
    }
  };

  const onCloseHandler = () => {
    if (isByShowElement) {
      if (showElementId != null) {
        dispatch(removeShowedElement(showElementId));
      }
    } else {
      onClose?.();
    }
  };

  const submitData = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    setRequestProcess(true);
    const payload: ReturnType<typeof createUserAction.request>['payload'] = {
      login: formGroup.controls.login.value.trim(),
      password: formGroup.controls.password.value,
      type: type.value as UserTypeEnum,
      isActive: Boolean(status.value),
      person: Number(person?.value),
      roles: roles?.map((role) => Number(role?.value)) || [],
    };
    dispatch(
      createUserAction.request({
        ...payload,
        callback: (res: false | UserCreateResponse) => {
          requestCallback(dispatch, Boolean(res), callbackTypeEnum.create);
          if (res) {
            onCloseHandler();
          }
        },
      }),
    );

    formGroup.validateConfirmation('password', 'passwordConfirm');
    formGroup.validate();
    setFormGroup({ ...formGroup });

    if (
      formGroup.controls.password.value &&
      formGroup.controls.passwordConfirm.value &&
      formGroup.controls.password.value ===
        formGroup.controls.passwordConfirm.value &&
      formGroup.valid
    ) {
      onSubmit?.(payload, RequestActionEnum.CREATE);
    }
  };

  return (
    <Modal>
      <div className="modal">
        <div className="scroll-area">
          <CustomScrollbars
            style={{
              width: '700px',
              height: '100%',
            }}
            renderView={(props) => (
              <div
                {...props}
                className={'scroll-area'}
                style={{
                  position: 'absolute',
                  inset: '0px',
                  overflowY: 'scroll',
                  marginRight: `${
                    navigator.platform === 'MacIntel' ? '-17px' : '-35px'
                  }`,
                  marginBottom: '-17px',
                }}></div>
            )}>
            <form className="modal-content" onSubmit={submitData}>
              <div
                className="modal__close"
                onClick={onCloseHandler}
                title="Close"
              />

              <div className="modal-title">Create user info</div>

              <div className="flex-container">
                <div>
                  {Object.keys(formGroup.controls).map(
                    (controlName: string) => {
                      const control = formGroup.controls[controlName];

                      return (
                        <Input
                          key={controlName}
                          type={control.type}
                          label={control.label}
                          placeholder={control.placeholder}
                          value={control.value}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            control.update(event.target.value);
                            setFormGroup({ ...formGroup });
                          }}
                          errorMessages={control.errorMessages}
                          required={control.required}
                        />
                      );
                    },
                  )}

                  <Select
                    label="user type"
                    options={userTypeOptions}
                    value={type}
                    onChange={(type) => setType(type)}
                    required
                  />
                </div>

                <div>
                  <Select
                    label="status"
                    options={userStatusOptions}
                    value={status}
                    onChange={(status) => setStatus(status)}
                  />

                  <SelectSearch
                    label="CONNECTED PERSON"
                    asyncOptions={async (searchParams) => {
                      const token = await getAccessTokenUtil(dispatch);
                      if (token) {
                        return loadPersonsOptions(searchParams, token);
                      }
                      return [];
                    }}
                    value={person}
                    onChange={(option) => setPerson(option)}
                    creation={{
                      label: 'Create new person',
                      onClick: () => {
                        dispatch(
                          addShowedElement({
                            id: uuid(),
                            key: personModalKey,
                            type: ShowElementEnum.modal,
                            props: {},
                            callback: (res) => {
                              setPerson({
                                value: res.id,
                                label: generatePersonLabel(res)
                              })
                            },
                          } as IModalElement),
                        );
                      },
                    }}
                    required
                  />

                  <SelectMulti
                    label="user role"
                    asyncOptions={async (searchParams) => {
                      const token = await getAccessTokenUtil(dispatch);
                      if (token) {
                        return loadUserRoleOptions(searchParams, token);
                      }
                      return [];
                    }}
                    placeholder="Select one, multiple or create new"
                    selectedOptions={roles}
                    onChange={(selections) => setRoles(selections)}
                    creation={{
                      label: 'Create new user role',
                      onClick: () => {
                        dispatch(
                          addShowedElement({
                            id: uuid(),
                            key: userRoleModalKey,
                            type: ShowElementEnum.modal,
                            props: {},
                            callback: (res: UserRoleCreateResponse) => {
                              res && setRoles([{
                                value: res.id,
                                label: res.title
                              }])
                            },
                          } as IModalElement),
                        );
                      },
                    }}
                    required
                  />
                </div>
              </div>

              <footer className="modal-footer">
                <Button variant="light" onClick={onCloseHandler}>
                  Cancel
                </Button>
                <Button
                  variant="dark"
                  onClick={submitData}
                  type="submit"
                  disabled={
                    !person ||
                    formGroup.controls.password.value.length < 8 ||
                    formGroup.controls.passwordConfirm.value.length < 8 ||
                    requestProcess
                  }>
                  Save
                </Button>
              </footer>
            </form>
          </CustomScrollbars>
        </div>

        <div className="modal-overlay" onClick={onCloseHandler} />
      </div>
    </Modal>
  );
};

export default CreateUserInfoModal;
