import {
  SearchGetListRequest,
  SearchGetListResponse, SuggestedEntitiesGetListRequest
} from "@ternala/voltore-types";

import { Config } from '../../../config/api';
import { authHeader, handleErrors } from '../../../utils/API';
import { appendSearchParams } from '../../../utils/appendSearchParams';

class API {
  public async search(
    data: SearchGetListRequest,
    token: string,
  ): Promise<SearchGetListResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'search/list');

    url = appendSearchParams(url, data);

    if (data.hasOwnProperty('entities') && data.entities)
      data.entities.forEach((entity) => {
        url.searchParams.append('entities[]', `${entity}`);
      })

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }
  public async getSuggestedList(
    data: SuggestedEntitiesGetListRequest,
    token: string,
  ): Promise<SearchGetListResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'search/suggested-list');
    url = appendSearchParams(url, data);
    if (data.hasOwnProperty('entityType'))
      url.searchParams.append('entityType', data.entityType);
    if (data.hasOwnProperty('id'))
      url.searchParams.append('id', String(data.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }
}
export const SearchApi = new API();
