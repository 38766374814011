
import React, {
  FC,
  useState,
  useEffect,
  useRef,
  RefObject,
  ChangeEvent,
} from 'react';
import { ErrorsType } from 'lib/validation';

import { SearchIcon } from 'components/icons/SearchIcon';
import { getUniqueId } from 'utils/helper-functions';
import { Color } from './shared/styles';
import style from './shared/styles/control.module.scss';
import { SearchCLoseIcon } from 'components/icons/SearchCLoseIcon';

interface Props {
  width?: number | '100%';
  className?: string;
  name?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClose?: () => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  darkTheme?: boolean;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
  noLabel?: boolean;
  autoFocus?: boolean;
  search?: boolean;
  error?: string;
  transparentIcon?: boolean;
  errors?: ErrorsType;
  errorMessages?: string[];
  callback?: Function;
  autocomplete?: string;
}

export const Input: FC<Props> = (props) => {
  const {
    width,
    className,
    label,
    placeholder,
    onClose,
    disabled,
    noLabel,
    required,
    autoFocus,
    onEnter,
    darkTheme,
    search,
    value,
    error,
    transparentIcon,
    errorMessages,
    callback,
    autocomplete,
  } = props;

  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    error || (errorMessages && errorMessages?.length > 0)
      ? setHasError(true)
      : setHasError(false);
  }, [error, errorMessages]);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  const inputRef = useRef() as RefObject<HTMLInputElement>;
  const inputId = `input-${getUniqueId()}`;

  const onFocus = () => {
    if (inputRef.current) {
      inputRef.current.placeholder = '';
    }
  };

  const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (inputRef.current && placeholder) {
      inputRef.current.placeholder = placeholder;
    }

    props.onBlur && props.onBlur(event);
  };

  return (
    <div
      className={`${style.container} ${className || ''}`}
      style={{ maxWidth: width }}>
      {!noLabel ? (
        <label
          htmlFor={inputId}
          className={darkTheme ? style.label_dark : style.label}
          style={{
            color: disabled
              ? Color.disabled
              : hasError
              ? Color.error
              : Color.label,
          }}>
          {label}
          {required && <span className={style.required}>*</span>}
        </label>
      ) : (
        ''
      )}

      <input
        id={inputId}
        onKeyDown={(e) => e.key === 'Enter' && onEnter && onEnter()}
        ref={inputRef}
        className={
          darkTheme
            ? style.input_dark
            : style.input + (hasError ? ` ${style.input_error}` : '')
        }
        style={{ borderColor: hasError ? 'rgba(255, 0, 0, .2)' : '' }}
        placeholder={placeholder || ''}
        type={props.type}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={props.readOnly}
        disabled={disabled}
        autoComplete={autocomplete}
      />

      <div
        className={
          darkTheme
            ? style.input__search_icon_dark
            : transparentIcon
            ? style.input__search_icon_transparent
            : style.input__search_icon
        }>
        {onClose ? (
          <div
            className={style.input__search_icon_close}
            onClick={() => {
              if (onClose) onClose();
              if (callback) callback();
            }}>
            <SearchCLoseIcon color={darkTheme ? 'white' : Color.mainDark} />
          </div>
        ) : search ? (
          (value === undefined || value === '') && search ? (
            <SearchIcon color={darkTheme ? 'white' : Color.mainDark} />
          ) : (
            <div
              className={style.input__search_icon_close}
              onClick={() => (callback ? callback() : {})}>
              <SearchCLoseIcon color={darkTheme ? 'white' : Color.mainDark} />
            </div>
          )
        ) : (
          ''
        )}
      </div>

      {error && typeof error === 'string' && (
        <span className={style.error}>{error}</span>
      )}

      {errorMessages && errorMessages.length > 0 && (
        <div
          className={
            style.multi_errors_container +
            (errorMessages.length === 1 ? ` ${style.single_error}` : '')
          }>
          {errorMessages.map((message) => (
            <div key={message} className={style.multi_error}>
              {message}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  disabled: false,
  required: false,
};

export default Input;
