import React, { FC, useState, useEffect, useRef } from 'react';

import style from './SelectSort.module.scss';

/* types */
import { OptionType } from 'models';

interface Props {
  style?: { [key: string]: number | string };
  options: OptionType[];
  value?: OptionType;
  onChange: (value: OptionType) => void;
  disabled?: boolean;
  withOverlap?: boolean;
  light?: boolean;
}

export const SelectSort: FC<Props> = ({
  options,
  value,
  disabled,
  light,
  withOverlap,
  ...props
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selectSortRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, []);

  const onClickOutside = (event: MouseEvent) => {
    if (!selectSortRef?.current?.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  const onContainerClick = () => {
    if (disabled) return;
    setIsMenuOpen(!isMenuOpen);
  };

  const onChange = (newValue: OptionType) => {
    if (newValue.label !== value?.label) {
      props.onChange(newValue);
    }
    setIsMenuOpen(false);
  };

  return (
    <div
      className={style.container}
      style={{ ...props.style, ...(withOverlap ? {  maxWidth: '100px' } : {}) }}
      ref={selectSortRef}>
      <div
        className={
          !disabled ? style.control_wrapper : style.control_wrapper_disabled
        }
        onClick={onContainerClick}>
        <div
          className={`${style.value} ${isMenuOpen ? style.value_active : ''}  ${
            light ? style.value_light : ''
          } ${withOverlap ? style.value_overlap : ''}`}>
          {value?.label}
        </div>

        <div
          className={`${style.dropdown_indicator} ${
            light ? style.dropdown_indicator_light : ''
          } ${isMenuOpen ? style.dropdown_indicator_active : ''}`}
        />
      </div>

      {isMenuOpen && (
        <div className={style.menu}>
          {options
            .filter((option) => option.label !== 'None')
            .map((option) => (
              <div
                className={`${style.option} ${
                  option.label?.toLowerCase() === value?.label?.toLowerCase()
                    ? style.option_active
                    : ''
                } ${option.isDisabled ? style.option_disabled : ''}`}
                onClick={() => !option.isDisabled && onChange(option)}
                key={option.label}>
                {option.label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SelectSort;
