import React, { FC } from 'react'

interface Props {
   isToggled: boolean
   onClick: () => void
}

export const SuggestionControl: FC<Props> = ({ isToggled, onClick }) => (
   <div
      className={
         "note-creator__suggestion-control" +
         (isToggled ? " note-creator__suggestion-control_active" : "")
      }
      onClick={onClick}
      title="Suggestion"
   >
      <span>&#64;</span>
   </div>
)

export default SuggestionControl