import { all, takeEvery, delay, put } from 'redux-saga/effects';

// Actions
import { addShowedElement, removeShowedElement } from '../actions';

export function* addShowedElementSaga({
  payload,
}: ReturnType<typeof addShowedElement>) {
  if (payload.timeout) {
    yield delay(payload.timeout);
    yield put(removeShowedElement(payload.id));
  }
}

export function* showElementActionSaga() {
  yield all([takeEvery(addShowedElement, addShowedElementSaga)]);
}
