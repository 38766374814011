import React, { useEffect, useState } from "react";

/* controllers */
import { useDispatch } from 'react-redux';
import { changeStatusAction } from 'controllers/favorite/actions';
import { formatPhoneNumber, phoneValidator } from 'utils/helper-functions';

/* components */
import OptionsMenu from '../OptionsMenu';
import Link from 'components/UI/controls/Link';
import { StarIcon } from 'components/icons/StarIcon';
import PopupEmail from 'components/modals/PopupEmail';
import PopupPhone from 'components/modals/PopupPhone';

/* types */
import { itemEnum } from '@ternala/voltore-types/lib/constants';
import { EmailUpdateDTO, PhoneDTO, PhoneUpdateDTO, EmailDTO } from "@ternala/voltore-types";

interface IProps {
  item: (EmailDTO | PhoneDTO) & {uuid?: string};
  entityId?: number;
  items?: EmailUpdateDTO[] | PhoneUpdateDTO[];
  isLast?: boolean;
  onDelete?: (id: string | number) => void;
  changeFavorite?: (uuid: string | number) => void;
  onUpdate?: (
    item: (EmailUpdateDTO | PhoneUpdateDTO) & { uuid?: string },
  ) => void;
}

const GenerateItem = ({
  entityId,
  item,
  items,
  isLast,
  onDelete,
  onUpdate,
  changeFavorite,
}: IProps) => {
  const dispatch = useDispatch();

  /* hooks */
  const [editPhone, setEditPhone] = useState<boolean>(false);
  const [editEmail, setEditEmail] = useState<boolean>(false);
  const [favorite, setFavorite] = useState<boolean>(Boolean(item.isFavorite));

  /* favorite functionality */
  const changeStatus = () => {
    setFavorite(!favorite);
    if (changeFavorite) changeFavorite(item.id || item.uuid || "");
    else
      dispatch(
        changeStatusAction.request({
          entityId,
          itemId: item.id,
          itemType: 'phone' in item ? itemEnum.phone : itemEnum.email,
          isFavorite: !favorite,
        }),
      );
  };

  useEffect(() => {
    if(item.isFavorite !== favorite){
      setFavorite(Boolean(item.isFavorite))
    }
  }, [item])

  return (
    <>
      <div className={`multi-item ${isLast ? 'last' : ''}`}>
        <div className="general">
          <Link mailto={'email' in item ? item.email : undefined} tel={'phone' in item ? item.phone : ''}>
            <span style={{ textTransform: 'lowercase' }}>
              {'phone' in item
                ? item?.phone?.charAt(0) === '+'
                  ? phoneValidator(item.phone)
                  : formatPhoneNumber(item.phone)
                : item?.email}
            </span>
          </Link>
          <div className="options">
            <div className="star" onClick={changeStatus}>
              <StarIcon color={favorite ? '#F7C206' : '#DCDEDF'} />
            </div>
            {onDelete ? (
              <OptionsMenu
                small
                on={{
                  edit: () =>
                    'phone' in item ? setEditPhone(true) : setEditEmail(true),
                  delete: () => onDelete(item.id || item.uuid || ""),
                }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        {'phone' in item ? (
          <div className="data">
            <span className="slug">{item.slug}</span>{' '}
            {item.label ? <span className="label">| {item.label}</span> : ''}
          </div>
        ) : (
          ''
        )}
      </div>
      {editPhone && 'phone' in item ? (
        <PopupPhone
          data={item}
          phones={items as PhoneUpdateDTO[]}
          onClose={() => setEditPhone(false)}
          onChange={(phone) => {
            if (onUpdate) onUpdate(phone);
            setEditPhone(false);
          }}
        />
      ) : (
        ''
      )}
      {editEmail && 'email' in item ? (
        <PopupEmail
          data={item}
          emails={items as EmailUpdateDTO[]}
          onClose={() => setEditEmail(false)}
          onChange={(email) => {
            if (onUpdate) onUpdate(email);
            setEditEmail(false);
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default GenerateItem;
