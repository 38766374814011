import { all, put, takeEvery, call } from "redux-saga/effects";

//APIs
import {LeaseApi} from '../transport/leaseTransactions.api';
import {getAccessToken} from '../../auth';

// Actions
import {
    getLeaseTransactionsAction,
    getLeaseTransactionAction,
    createLeaseTransactionAction,
    updateLeaseTransactionAction,
    deleteLeaseTransactionAction,
    updateLeaseTransactionStatusAction,
    updateLeaseTransactionActiveStatusAction,
    addLeaseTransactionLoader,
    addLeaseTransactionError,
    removeLeaseTransactionLoader,
} from '../actions/leaseTransationActions';

import uuid from 'utils/uuid';

// Constants
import {LoaderAction} from 'config/constants';
import {getAccessTokenUtil} from "../../../utils/getAccessToken";
import {
    LeaseTransactionCreateResponse, LeaseTransactionDeleteResponse,
    LeaseTransactionGetListResponse,
    LeaseTransactionGetResponse, LeaseTransactionStatusHistoryFullDTO, LeaseTransactionUpdateResponse
} from "@ternala/voltore-types";
import {IError} from "../../model";
import {
    createEnterpriseAction,
    getEnterpriseAction,
    getEnterprisesAction,
    updateEnterpriseAction
} from "../../enterprise/actions";
import {addError} from "../../enterprise";
import {changeStatusAction} from "../../favorite/actions";
import {IResponsePropertiesAdditionalFields} from "../models";
import { getAccessTokenSaga } from "../../auth/sagas/auth";

// Utils
export function* getLeasesSaga({
                                   payload,
                               }: ReturnType<typeof getLeaseTransactionsAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    const additionalFields = {
        property: payload.property,
    };
    yield put(
        addLeaseTransactionLoader({
            id: loadId,
            message: 'Please wait, leases are loading!',
            type: LoaderAction.lease.getList,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: LeaseTransactionGetListResponse | string | IError = yield LeaseApi.getLeaseTransactions(data, accessToken);
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                getLeaseTransactionsAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    getLeaseTransactionsAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to get leases!',
                    type: LoaderAction.lease.getList,
                }),
            );
        } else {
            yield put(
                getLeaseTransactionsAction.success({
                    response: res,
                    searchParams: payload,
                    additionalFields: {
                        property: payload.property,
                    },
                    isAll: payload.limit ? res.items.length < payload.limit : true,
                }),
            );
            yield put(
                removeLeaseTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            getLeaseTransactionsAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addLeaseTransactionError({
                id: loadId,
                message: 'Failed to get leases!',
                type: LoaderAction.lease.getList,
                additionalFields,
            }),
        );
    }
}

export function* createLeaseSaga({
                                     payload,
                                 }: ReturnType<typeof createLeaseTransactionAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    const additionalFields = {
        property: payload.property,
    };
    yield put(
        addLeaseTransactionLoader({
            id: loadId,
            message: 'Please wait, lease is creating!',
            type: LoaderAction.lease.create,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: LeaseTransactionCreateResponse | string | IError = yield LeaseApi.createLeaseTransaction(data, accessToken);

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                createLeaseTransactionAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    createLeaseTransactionAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to create leaseTransaction!',
                    type: LoaderAction.lease.create,
                }),
            );
        } else {
            yield put(
                createLeaseTransactionAction.success({
                    response: res,
                    additionalFields: {
                        property: payload.property,
                    },
                }),
            );
            yield put(
                removeLeaseTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function')
            payload.callback(false, error.data.statuses as string);
        yield put(
            createLeaseTransactionAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addLeaseTransactionError({
                id: loadId,
                message: 'Failed to create lease!',
                type: LoaderAction.lease.create,
                additionalFields,
            }),
        );
    }
}

export function* getLeaseSaga({
                                  payload,
                              }: ReturnType<typeof getLeaseTransactionAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    const additionalFields = {
        property: payload.property,
    };
    yield put(
        addLeaseTransactionLoader({
            id: loadId,
            message: 'Please wait, lease is getting!',
            type: LoaderAction.lease.getItem,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: LeaseTransactionGetResponse | string | IError = yield LeaseApi.getLeaseTransaction(data, accessToken);

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                getLeaseTransactionAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    getLeaseTransactionAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to get leaseTransaction!',
                    type: LoaderAction.lease.getItem,
                }),
            );
        } else {
            yield put(
                getLeaseTransactionAction.success({
                    response: res,
                    additionalFields: {
                        property: payload.property,
                    },
                }),
            );
            yield put(
                removeLeaseTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            getLeaseTransactionAction.failure({
                key: String(payload.id),
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addLeaseTransactionError({
                id: loadId,
                message: 'Failed to get lease!',
                type: LoaderAction.lease.getItem,
                additionalFields,
            }),
        );
    }
}

export function* updateLeaseSaga({
                                     payload,
                                 }: ReturnType<typeof updateLeaseTransactionAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    const additionalFields = {
        property: payload.property,
    };
    yield put(
        addLeaseTransactionLoader({
            id: loadId,
            message: 'Please wait, lease is updating!',
            type: LoaderAction.lease.update,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: LeaseTransactionUpdateResponse | string | IError = yield LeaseApi.updateLeaseTransaction(data, accessToken);
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                updateLeaseTransactionAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    updateLeaseTransactionAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to update leaseTransaction!',
                    type: LoaderAction.lease.update,
                }),
            );
        } else {
            yield put(
                updateLeaseTransactionAction.success({
                    response: res,
                    additionalFields: {
                        property: payload.property,
                    },
                }),
            );
            yield put(
                removeLeaseTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function')
            payload.callback(false, error.data.statuses as string);
        yield put(
            updateLeaseTransactionAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addLeaseTransactionError({
                id: loadId,
                message: 'Failed to update lease!',
                type: LoaderAction.lease.update,
                additionalFields,
            }),
        );
    }
}

export function* updateLeaseTransactionStatusSaga({
                                                      payload,
                                                  }: ReturnType<typeof updateLeaseTransactionStatusAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    const additionalFields = {
        property: payload.property,
    };
    yield put(
        addLeaseTransactionLoader({
            id: loadId,
            message: 'Please wait, sale is updating!',
            type: LoaderAction.lease.update,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        const res: LeaseTransactionStatusHistoryFullDTO | string | IError = yield LeaseApi.updateLeaseTransactionStatus(
            payload,
            accessToken,
        );
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                updateLeaseTransactionStatusAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    updateLeaseTransactionStatusAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to update leaseTransaction status!',
                    type: LoaderAction.lease.update,
                }),
            );
        } else {
            yield put(updateLeaseTransactionStatusAction.success(res));
            yield put(
                removeLeaseTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            updateLeaseTransactionStatusAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addLeaseTransactionError({
                id: loadId,
                message: 'Failed to update sale!',
                type: LoaderAction.lease.update,
                additionalFields,
            }),
        );
    }
}

export function* updateLeaseTransactionActiveStatusSaga({
                                                            payload,
                                                        }: ReturnType<typeof updateLeaseTransactionActiveStatusAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    const additionalFields = {
        property: payload.property,
    };
    yield put(
        addLeaseTransactionLoader({
            id: loadId,
            message: 'Please wait, sale is updating!',
            type: LoaderAction.lease.update,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: LeaseTransactionStatusHistoryFullDTO | string | IError = yield LeaseApi.updateLeaseTransactionActiveStatus(
            data,
            accessToken,
        );
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                updateLeaseTransactionActiveStatusAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    updateLeaseTransactionActiveStatusAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to update leaseTransaction active status!',
                    type: LoaderAction.lease.update,
                }),
            );
        } else {
            yield put(updateLeaseTransactionActiveStatusAction.success(res));
            yield put(
                removeLeaseTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            updateLeaseTransactionActiveStatusAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addLeaseTransactionError({
                id: loadId,
                message: 'Failed to update sale!',
                type: LoaderAction.lease.update,
                additionalFields,
            }),
        );
    }
}

export function* deleteLeaseSaga({
                                     payload,
                                 }: ReturnType<typeof deleteLeaseTransactionAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    const additionalFields = {
        property: payload.property,
    };
    yield put(
        addLeaseTransactionLoader({
            id: loadId,
            message: 'Please wait, lease is deleting!',
            type: LoaderAction.lease.delete,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: LeaseTransactionDeleteResponse | string = yield LeaseApi.deleteLeaseTransaction(data, accessToken);

        if (typeof res === "undefined" || typeof res === "string") {
            deleteLeaseTransactionAction.failure({
                code: res,
                message: res || 'Something was wrong',
            });
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to delete leaseTransaction!',
                    type: LoaderAction.lease.delete,
                }),
            );
        } else {
            yield put(
                deleteLeaseTransactionAction.success({
                    response: res,
                    //...res,
                    additionalFields: {
                        property: payload.property
                    },
                    id: payload.id
                }),
            );
            yield put(
                removeLeaseTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            deleteLeaseTransactionAction.failure({
                code: error.code || 403,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addLeaseTransactionError({
                id: loadId,
                message: 'Failed to delete lease!',
                type: LoaderAction.lease.delete,
                additionalFields,
            }),
        );
    }
}

export function* leaseTransactionActionSaga() {
    yield all([
        takeEvery(getLeaseTransactionsAction.request, getLeasesSaga),
        takeEvery(getLeaseTransactionAction.request, getLeaseSaga),
        takeEvery(createLeaseTransactionAction.request, createLeaseSaga),
        takeEvery(updateLeaseTransactionAction.request, updateLeaseSaga),
        takeEvery(
            updateLeaseTransactionStatusAction.request,
            updateLeaseTransactionStatusSaga,
        ),
        takeEvery(
            updateLeaseTransactionActiveStatusAction.request,
            updateLeaseTransactionActiveStatusSaga,
        ),
        takeEvery(deleteLeaseTransactionAction.request, deleteLeaseSaga),
    ]);
}
