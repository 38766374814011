import React, { Component, createRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { PropertyShortDTO } from '@ternala/voltore-types';
import { CardActionEnum } from '@ternala/voltore-types/lib/constants';

/* constants & utils */
import { ActionListTypeEnum, IActionElement } from '../../model.d';
import { cardActionsArray } from '../../constants';

// Controllers
import { getAccessToken } from '../../../../controllers/auth';
import { PropertyApi } from '../../../../controllers/property/transport/property.api';
import { IStore } from '../../../../controllers/store';

//Custom components
import Loader from '../../../Loader';
import ActionsListItem from './List/ActionsListItem';
import { UserRoleApi } from '../../../../controllers/user/transport/userRole.api';
import {
  enterpriseToAction,
  involvementToAction,
  leaseTransactionToAction,
  personToAction,
  propertyToAction,
  roleToAction,
  saleTransactionToAction,
  tagToAction,
} from '@ternala/voltore-types/lib/card';

import { TagApi } from '../../../../controllers/tag/transport/tag.api';
import { InvolvementApi } from '../../../../controllers/involvement/transport/involvement.api';
import { LeaseApi } from '../../../../controllers/property/transport/leaseTransactions.api';
import { SaleApi } from '../../../../controllers/property/transport/saleTransactions.api';
import { PersonApi } from '../../../../controllers/person/transport/person.api';
import { EnterpriseApi } from '../../../../controllers/enterprise/transport/enterprise.api';

export interface IProps {
  type: ActionListTypeEnum;
  store: IStore;
  searchValue: string;
  onSelect: (action: IActionElement) => void;
  disabledIds?: number[];
}

interface IState {
  type: ActionListTypeEnum;
  selectedAction?: IActionElement | null;
  actions: IActionElement[];
  filteredActions: IActionElement[];
  isAll?: boolean;
  isLoading?: boolean;
}

export class ActionsListExpanded extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      type: props.type,
      selectedAction: null,
      actions: [],
      filteredActions: [],
      isLoading: false,
    };
    this.generateActions(props.type);
    document.addEventListener('keydown', this.handleButtonKeyDown);
    document.addEventListener('keyup', this.handleButtonKeyUp);
    document.addEventListener('keypress', this.handleButtonKeyPress);
  }

  scrollToItem = () => {
    if (this.scrollbar.current && this.selectedItem.current) {
      const scrollHeight = this.scrollbar.current?.getClientHeight();
      const itemTop = this.selectedItem.current?.offsetTop;
      const itemHeight = this.selectedItem.current?.offsetHeight;

      const centerPosY = itemTop + itemHeight / 2 - scrollHeight / 2;
      this.scrollbar.current?.scrollTop(centerPosY);
    }
  };

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ) {
    const { selectedAction, type: stateType, actions } = this.state;
    const { searchValue, type } = this.props;

    if (prevProps.searchValue !== searchValue) {
      const filteredActions = this.filterActions(actions);
      this.setState({
        filteredActions,
        selectedAction: filteredActions.find(action => selectedAction?.key === action.key) || filteredActions[0]
      });
      this.scrollToItem();
    }

    if (prevState?.selectedAction !== selectedAction) {
      this.scrollToItem();
    }

    if (stateType !== type) {
      this.setState({
        type,
      });
      this.generateActions(type);
      this.scrollbar.current?.scrollTop(0);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleButtonKeyDown);
    document.removeEventListener('keyup', this.handleButtonKeyUp);
    document.removeEventListener('keypress', this.handleButtonKeyPress);
  }

  getActionsList = async (type: ActionListTypeEnum) => {
    const { store } = this.props;
    this.setState({
      isLoading: true,
    });
    const accessToken = await getAccessToken(store);
    if (!accessToken) return [];

    switch (type) {
      case ActionListTypeEnum.Role: {
        const data = await UserRoleApi.getUserRoles({}, accessToken);
        if (typeof data === 'string' || "message" in data) return [];
        return data.items.map(roleToAction) || [];
      }
      case ActionListTypeEnum.Property: {
        const data = await PropertyApi.getAllProperties(accessToken);
        return (data?.items || []).map(propertyToAction);
      }
      case ActionListTypeEnum.Sale_Transaction: {
        const data = await SaleApi.getSaleTransactions({}, accessToken);
        if (typeof data === 'string' || "message" in data) return [];
        return data.items.map(saleTransactionToAction) || [];
      }
      case ActionListTypeEnum.Lease_Transaction: {
        const data = await LeaseApi.getLeaseTransactions({}, accessToken);
        if (typeof data === 'string' || "message" in data) return [];
        return data.items.map(leaseTransactionToAction) || [];
      }
      case ActionListTypeEnum.Involvement: {
        const data = await InvolvementApi.getInvolvements({}, accessToken);
        if (typeof data === 'string' || "message" in data) return [];
        return data.items.map(involvementToAction) || [];
      }
      case ActionListTypeEnum.Enterprise: {
        const data = await EnterpriseApi.getEnterprises({}, accessToken);
        if (typeof data === 'string' || 'message' in data) return [];
        return data.items.map(enterpriseToAction) || [];
      }
      case ActionListTypeEnum.Person: {
        const data = await PersonApi.getPersons({}, accessToken);
        if (typeof data === 'string' || "message" in data) return [];
        return data.items.map(personToAction) || [];
      }
      case ActionListTypeEnum.Tag: {
        const data = await TagApi.getTagList({}, accessToken);
        if (typeof data === 'string') return [];
        return (data?.items || []).map(tagToAction);
      }
      default: {
        return [];
      }
    }
  };

  generateActions = (type: ActionListTypeEnum) => {
    new Promise<IActionElement[]>((resolve) => {
      if (type === ActionListTypeEnum.Actions) {
        resolve(
          cardActionsArray.filter((action) =>
            [CardActionEnum.REMIND, CardActionEnum.TAG].includes(action.key),
          ),
        );
      } else {
        this.getActionsList(type).then((actions) => {
          resolve(actions);
        });
      }
    }).then((actions) => {
      const { selectedAction } = this.state;
      const filteredActions = this.filterActions(actions);
      this.setState({
        actions: actions,
        filteredActions: filteredActions,
        isLoading: false,
        selectedAction: filteredActions.find(action => selectedAction?.key === action.key) || filteredActions[0]
      });

      return;
    });
  };

  private filterActions = (actions: IActionElement[]) => {
    const { searchValue } = this.props;
    return actions.filter((action) =>
      action.title
        .toLowerCase()
        .trim()
        .startsWith(searchValue?.toLowerCase().trim()),
    );
  };

  private selectNext = () => {
    const { filteredActions, selectedAction } = this.state;
    const maxLength = filteredActions.length;
    const currentIndex = filteredActions.findIndex(
      (item) => item.key === selectedAction?.key,
    );
    const nextIndex =
      currentIndex === maxLength - 1 ? currentIndex : currentIndex + 1;
    this.setState({
      selectedAction: filteredActions[nextIndex],
    });
  };
  private selectPrev = (actions?: IActionElement[]) => {
    const { filteredActions, selectedAction } = this.state;
    if(!actions) actions = filteredActions;
    const minLength = 0;
    const currentIndex = actions.findIndex(
      (item) => item.key === selectedAction?.key,
    );
    const prevIndex = currentIndex <= minLength ? minLength : currentIndex - 1;
    this.setState({
      selectedAction: actions[prevIndex],
    });
  };
  private handleButtonKeyPress = (e: KeyboardEvent) => {
    if (
      e.code === 'Enter' ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowRight' ||
      e.code === 'ArrowLeft'
    ) {
      e.preventDefault();
    }
  };
  private handleButtonKeyUp = (e: KeyboardEvent) => {
    if (
      e.code === 'Enter' ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowRight' ||
      e.code === 'ArrowLeft'
    ) {
      e.preventDefault();
    }
  };
  private handleButtonKeyDown = (e: KeyboardEvent) => {
    const { selectedAction } = this.state;
    const { disabledIds } = this.props;
    if (
      e.code === 'Enter' ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowRight' ||
      e.code === 'ArrowLeft'
    ) {
      e.preventDefault();
    }
    if (e.code === 'ArrowUp') {
      this.selectPrev();
    }
    if (e.code === 'ArrowDown') {
      this.selectNext();
    }

    if (e.code === 'Enter') {
      if (selectedAction && selectedAction.data) {
        if ((disabledIds || [])?.indexOf(selectedAction.data.id) === -1) {
          this.props.onSelect(selectedAction);
        }
        return;
      }
      if (selectedAction && 'key' in selectedAction) {
        this.props.onSelect(selectedAction);
        return;
      }
    }
  };

  selectedItem = createRef<HTMLDivElement>();
  scrollbar = createRef<Scrollbars>();

  render() {
    const { onSelect, disabledIds } = this.props;
    const { selectedAction, filteredActions, isLoading, type } = this.state;

    let property: PropertyShortDTO | undefined;
    let prevId: number | undefined;
    let isExpanded: boolean;
    if (
      type === ActionListTypeEnum.Sale_Transaction ||
      type === ActionListTypeEnum.Lease_Transaction
    ) {
      filteredActions.sort((transaction1, transaction2) => {
        if (
          transaction1.data &&
          transaction2.data &&
          'property' in transaction1.data &&
          'property' in transaction2.data
        ) {
          return (
            (transaction1?.data?.property?.id || 0) -
            (transaction2?.data?.property?.id || 0)
          );
        }
        return 0;
      });
    }
    return (
      <Scrollbars
        autoHide
        className="actions-list__scrollbars"
        ref={this.scrollbar}
        renderThumbVertical={
          (props) => (
            <div
              {...props}
              className="page-scrollbar-y"
              style={{ marginRight: '-5px', marginBottom: '-5px' }}
            />
          )
        }
        renderTrackHorizontal={(props) => (
          <div {...props} className="page-scrollbar-x" />
        )}>
        <>
          {isLoading ? <Loader /> : ''}
          <main className="actions-list__content">
            {!filteredActions.length ? (
              <span className={'placeholder'}>Haven't items</span>
            ) : (
              ''
            )}
            {filteredActions.map((action, index) => {
              const nextAction = filteredActions[index + 1];
              let nextItemProperty;
              const disabled =
                typeof action.data?.id !== 'undefined' &&
                (disabledIds || [])?.indexOf(action.data?.id) !== -1;

              if (action.data && 'property' in action.data) {
                property = action.data?.property;
              }
              isExpanded = prevId !== property?.id;
              prevId = property?.id;

              if (
                nextAction &&
                nextAction.data &&
                'property' in nextAction.data
              ) {
                nextItemProperty = nextAction.data.property;
              }
              const isEnd =
                nextItemProperty &&
                property &&
                nextItemProperty?.id !== property?.id;

              return (
                <ActionsListItem
                  key={index}
                  index={index}
                  disabled={disabled}
                  isSelected={selectedAction?.key === action.key}
                  type={type}
                  isExpanded={isExpanded}
                  isEnd={isEnd}
                  property={property}
                  action={action}
                  onClick={() => {
                    !disabled &&
                      (action.data ? onSelect(action) : onSelect(action));
                  }}
                  ref={
                    selectedAction?.key === action.key
                      ? this.selectedItem
                      : null
                  }
                />
              );
            })}
          </main>
        </>
      </Scrollbars>
    );
  }
}

export default connect((store: IStore) => ({
  store,
}))(ActionsListExpanded);
