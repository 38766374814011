import React from "react";

/* components */
import TagItem from "components/Tags/Controls/TagItem";

/* functions and libraries */
import groupBy from "lodash/groupBy";
import { Editor } from "react-draft-wysiwyg";
import { CompositeDecorator, convertFromRaw, EditorState } from "draft-js";
import { noteDecorators } from "components/Windows/Draft/Decorators";
import {
  formatAddress,
  arrayDifferences,
  objectDifferencesOneLevel,
  formatPhoneNumber,
  phoneValidator
} from "utils/helper-functions";

/* types */
import { dictionary } from "@ternala/voltore-types/lib/constants";
import {
  CardEntityConnectDTO,
  HistoryDataItem,
  TagShortDTO
} from "@ternala/voltore-types";
import { activityHistoryTypeEnum } from "@ternala/voltore-types/lib/constants";
import { ReminderDTO } from "@ternala/voltore-types/lib/modules/card/reminder/reminder.dto";
import { ReminderCard } from "../../Controls/ReminderCard";

/* actions */
/* tag functionality */
export const actionTag = (newValue: any, oldValue: any) => {
  function tagArrayComponent(oldArray: any[], newArray: any[]) {
    return (
      <div className="action">
        {oldValue && oldArray.length ? (
          <div className="action-tags old">
            {oldArray
              .filter((tag) => tag !== null)
              .map((tag: any, index) => {
                return (
                  <div className="action-tag" key={index}>
                    <TagItem tag={tag} history removed />
                    {tooltip(tag?.id)}
                  </div>
                );
              })}
          </div>
        ) : (
          ""
        )}
        {newValue && newArray.length ? (
          <div className="action-tags new">
            {newArray
              .filter((tag) => tag !== null)
              .map((tag: any) => (
                <div className="action-tag">
                  <TagItem tag={tag} history />
                  {tooltip(tag?.id)}
                </div>
              ))}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  /* check for array  */
  if (Array.isArray(newValue) || Array.isArray(oldValue)) {
    const onlyNewTags = newValue?.map((element: any) => element.tag);
    const onlyOldTags = oldValue?.map((element: any) => element.tag);
    const { oldArray, newArray } = arrayDifferences(onlyNewTags, onlyOldTags);
    return tagArrayComponent(oldArray, newArray);
  } else {
    if (oldValue?.cardTagConnects || newValue?.cardTagConnects) {
      /* check is tag/cardTagsConnect */
      const newValueArray = (
        newValue as { cardTagConnects: CardEntityConnectDTO[] }
      )?.cardTagConnects
        .filter((connect) => connect.tag !== undefined)
        .map((connect) => connect.tag);
      const oldValueArray = (
        oldValue as { cardTagConnects: CardEntityConnectDTO[] }
      )?.cardTagConnects
        .filter((connect) => connect.tag !== undefined)
        .map((connect) => connect.tag);
      const { oldArray, newArray } = arrayDifferences(
        newValueArray || [],
        oldValueArray || []
      );
      return tagArrayComponent(oldArray, newArray);
    }
    return (
      <div className="action">
        {oldValue ? (
          <div className="action-tags old">
            <div className="action-tag">
              <TagItem tag={oldValue.tag as TagShortDTO} history removed />
              {tooltip(oldValue.tag?.id)}
            </div>
          </div>
        ) : (
          ""
        )}
        {newValue?.cardTagConnect ? (
          <div className="action-tags new">
            <div className="action-tag">
              <TagItem
                tag={newValue.cardTagConnect.tag as TagShortDTO}
                history
              />
              {tooltip(newValue.cardTagConnect.tag?.id)}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
};

/* string functionality */
function actionStringItem(value: any, type: "old" | "new") {
  if (!value) return;
  /* functionality for notes */
  let noteText: string | undefined | JSX.Element =
    typeof value === "string"
      ? value
      : value?.text
        ? value?.text
        : value.note?.text;

  try {
    if (noteText) {
      const res = JSON.parse(noteText as string);
      if (noteText) {
        const content = convertFromRaw(res);
        noteText = content.hasText() ? (
          <Editor
            readOnly={true}
            editorState={EditorState.createWithContent(
              content,
              new CompositeDecorator(noteDecorators)
            )}
            toolbarHidden={true}
            customDecorators={noteDecorators}
          />
        ) : (
          ""
        );
      }
    }
  } catch (e) {
  }

  if (noteText) {
    return (
      <div className={`action-item ${type}`}>
        <span className="field">{noteText}</span>
      </div>
    );
  }
  /* functionality for string or number */
  return (
    <div className={`action-item ${type}`}>
      <span className="field">
        {typeof value == "boolean"
          ? value
            ? "True"
            : "False"
          : typeof value === "string" || typeof value === "number"
            ? value
            : "No value"}
      </span>
    </div>
  );
}

export const actionField = (
  newValue: any,
  oldValue: any,
  taxonomy?: boolean,
  entityType?: activityHistoryTypeEnum
) => {
  function actionArrayItem(
    array: any,
    type: "old" | "new",
    taxonomy?: boolean
  ) {
    const groups = Object.values(
      groupBy(array, function(item) {
        if (item.type) return item.type.title;
        return;
      })
    );
    return (
      <div className={`action-item ${type}`}>
        {taxonomy
          ? groups.map((arr: any) => {
            return arr.map((item: any, index: number) => (
              <div key={index}>
                {taxonomy && item.type && index === 0 ? (
                  <span className="title" style={{ marginBottom: "3px" }}>
                      {item.type.title}
                    </span>
                ) : (
                  ""
                )}
                <span className="field">
                    {item.title ? item.title : ""}
                  {item.address ? item.address : ""}
                  {typeof item === "string" || typeof item === "number"
                    ? item
                    : ""}
                  {tooltip(item.id)}
                  </span>
              </div>
            ));
          })
          : array.map((item: any, index: number) => {
            return (
              <span className="field" key={index}>
                  {item.phone
                    ? item.phone?.charAt(0) === "+"
                      ? phoneValidator(item.phone)
                      : formatPhoneNumber(item.phone)
                    : ""}
                {item.email ? item.email : ""}
                {item.title ? item.title : ""}
                {item.address ? item.address : ""}
                {typeof item === "string" || typeof item === "number"
                  ? item
                  : ""}
                {tooltip(item.id)}
                </span>
            );
          })}
      </div>
    );
  }

  /* check is array */
  if (Array.isArray(newValue) || Array.isArray(oldValue)) {
    const { oldArray, newArray } = arrayDifferences(newValue, oldValue);
    return (
      <div className="action">
        {oldValue && oldArray.length
          ? actionArrayItem(oldArray, "old", taxonomy)
          : ""}
        {newValue && newArray.length
          ? actionArrayItem(newArray, "new", taxonomy)
          : ""}
      </div>
    );
  } else {
    return (
      <div className="action">
        {oldValue ? actionStringItem(oldValue, "old") : ""}
        {newValue ? actionStringItem(newValue, "new") : ""}
      </div>
    );
  }
};

/* field */
type ReplacedFieldType = {
  data: any;
  column?: boolean;
  taxonomy?: boolean;
  type?: activityHistoryTypeEnum;
};

export const ReplacedField = ({
                                data,
                                column,
                                taxonomy,
                                type
                              }: ReplacedFieldType) => {
  const element = data.data ? data.data : data;

  if (data.field === "phones" || data.field === "emails") {
    const differences = arrayDifferences(data.newValue, data.oldValue);
    if (differences.newArray.length === 0 && differences.oldArray.length === 0)
      return <></>;
  }

  return (
    <div className="replaced field">
      {taxonomy ? (
        ""
      ) : (
        <span className="title">
          {type
            ? // @ts-ignore
            dictionary[type]?.[
              element.field === "cardTagConnects" ? "tags" : element.field
              ]
            : element.field}
        </span>
      )}
      <div className={`actions ${column ? "column" : ""}`}>
        {actionField(element.newValue, element.oldValue, taxonomy, type)}
      </div>
    </div>
  );
};

// Replaced tag
export const ReplacedTag = (data: any) => {
  const element = data.data ? data.data : data;
  let newValue = element.newValue;
  let oldValue = element.oldValue;
  const difference: any[] = objectDifferencesOneLevel(newValue, oldValue);
  if (difference.length === 0) return <div></div>;
  return (
    <div className="replaced tag">
      <span className="title">Tag(s)</span>
      <div className="actions">{actionTag(newValue, oldValue)}</div>
    </div>
  );
};

export const GenerateReminder = (reminder: ReminderDTO) => {
  return <ReminderCard reminder={reminder} history={true} />;
};

export const GenerateReminders = ({ reminders, isOld = false }: { reminders: ReminderDTO[], isOld?: boolean }) => {
  if (Array.isArray(reminders)) {
    return <div className={"reminder-list " + (isOld ? "old" : "new")}>
      {reminders?.map((reminder) => (
        <GenerateReminder {...reminder} />
      ))}
    </div>;
  } else {
    return <></>;
  }
};

export const GenerateReminderHistoryField = (data: HistoryDataItem) => {
  const removed = data.oldValue?.filter((reminder: ReminderDTO) => {
    const newItem = data.newValue?.find((newReminder: ReminderDTO) => newReminder.id === reminder.id);
    return !newItem || JSON.stringify(newItem) !== JSON.stringify(reminder)
  })

  const added = data.newValue?.filter((reminder: ReminderDTO) => {
    const oldItem = data.oldValue?.find((newReminder: ReminderDTO) => newReminder.id === reminder.id);
    return !oldItem || JSON.stringify(oldItem) !== JSON.stringify(reminder)
  })

  return (
    <div className="replaced tag">
      <span className="title">Reminder(s)</span>
      <div className="actions column">
        <GenerateReminders isOld={true} reminders={removed} />
        <GenerateReminders reminders={added} />
      </div>
    </div>
  );
};

/* objects */
export const ReplacedObject = (data: any, type?: activityHistoryTypeEnum) => {
  const oldValue = data.data.oldValue;
  const newValue = data.data.newValue;
  const difference = objectDifferencesOneLevel(newValue, oldValue);
  /* address */
  if (data.data.field === "address" || "taxBillingAddress") {
    const addressActionCreator = (
      address: string,
      latitude: number,
      longitude: number,
      type: "new" | "old"
    ) => {
      return (
        <div className={`actions-address-field ${type}`}>
          <span className="address">{address}</span>
          <span className="address-additional">{latitude}</span>
          <span className="address-additional">{longitude}</span>
        </div>
      );
    };
    if (
      formatAddress({
        address: newValue?.address,
        city: newValue?.city,
        state: newValue?.state,
        zipCode: newValue?.zipCode
      }) ===
      formatAddress({
        address: oldValue?.address,
        city: oldValue?.city,
        state: oldValue?.state,
        zipCode: oldValue?.zipCode
      })
    )
      return <div></div>;
    return (
      <div className="replaced field" style={{ marginBottom: "5px" }}>
        <div className="title-address">
          <span className="title">{data.data.field}</span>
          <span className="title" style={{ marginLeft: "auto" }}>
            latitude
          </span>
          <span
            className="title"
            style={{ marginLeft: "40px", marginRight: "5px" }}>
            longitude
          </span>
        </div>
        <div className={`actions column`}>
          {oldValue
            ? addressActionCreator(
              formatAddress({
                address: oldValue.address,
                city: oldValue.city,
                state: oldValue.state,
                zipCode: oldValue.zipCode
              }),
              oldValue.latitude,
              oldValue.longitude,
              "old"
            )
            : ""}
          {newValue
            ? addressActionCreator(
              formatAddress({
                address: newValue.address,
                city: newValue.city,
                state: newValue.state,
                zipCode: newValue.zipCode
              }),
              newValue.latitude,
              newValue.longitude,
              "new"
            )
            : ""}
        </div>
      </div>
    );
  }

  /* other functionality */
  function actionElement(element: any) {
    if (element.name === "id") return;
    if (element.name === "author") return;
    if (element.name === "createdAt") return;
    if (element.name === "deletedAt") return;
    if (element.name === "updatedAt") return;
    if (element.name === "IsConnected") return;
    if (element.name === "card" && oldValue) return;
    if (Array.isArray(element.old) && !element.old.length) return;
    if (Array.isArray(element.new) && !element.new.length) return;
    return (
      <div className="replaced field">
        <span className="title">
          {element.name === "text" ? "card" : element.name}
        </span>
        <div
          className={`actions ${
            element.name === "card" || "text" ? "column" : ""
          }`}>
          {actionStringItem(element.old, "old")}
          {actionStringItem(element.new, "new")}
        </div>
      </div>
    );
  }

  return difference.map((element: any) => actionElement(element));
};

/* functional tooltip */
function tooltip(id: number | undefined) {
  return (
    <div className="tooltip">
      <div className="tooltip-content">ID: {id}</div>
    </div>
  );
}
