// packages from @ternala
import { Config } from "config/api";
import { authHeader, handleErrors } from "utils/API";

import {
  LeaseTransactionGetRequest,
  LeaseTransactionDeleteRequest,
  LeaseTransactionGetListRequest,
  LeaseTransactionCreateResponse,
  LeaseTransactionGetResponse,
  LeaseTransactionUpdateResponse,
  LeaseTransactionDeleteResponse,
  LeaseTransactionGetListResponse,
  UpdateLeaseStatusDTO,
  LeaseTransactionStatusHistoryFullDTO,
  UpdateActiveLeaseStatusDTO
} from "@ternala/voltore-types";
import { appendSearchParams } from "utils/appendSearchParams";
import { LeaseTransactionCreateRequestExpanded, LeaseTransactionUpdateRequestExpanded } from "../models";
import {IError} from "../../model";

class API {
  public async getLeaseTransactions(getLeasesData: LeaseTransactionGetListRequest, token: string): Promise<LeaseTransactionGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "lease-transaction/list")

    url = appendSearchParams(url, getLeasesData);
    if(getLeasesData.hasOwnProperty('property')) url.searchParams.append('property', String(getLeasesData.property))
    if(getLeasesData.hasOwnProperty('statuses')) {
      if(Array.isArray(getLeasesData.statuses)){
        getLeasesData.statuses.forEach(singleValue => {
          url.searchParams.append(`statuses[]`, singleValue);
        })
      } 
    } 

    return handleErrors(
      fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        }
      })
    );
  }

  public async createLeaseTransaction(createLeaseData: LeaseTransactionCreateRequestExpanded, token: string): Promise<LeaseTransactionCreateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "lease-transaction/create")

    const data = new FormData();

    Object.entries(createLeaseData).forEach(([key, val]) => {
      if (Array.isArray(val)) {
        val.forEach(singleValue => {
          data.append(`${key}[]`, singleValue);
        })
      } else {
        data.append(key, val);
      }
    })

    return handleErrors(
      fetch(url.toString(), {
        method: "POST",
        headers: {
          ...authHeader(token)
        },
        body: data
      })
    );
  }

  public async getLeaseTransaction(getLeaseData: LeaseTransactionGetRequest, token: string): Promise<LeaseTransactionGetResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "lease-transaction/get")

    url.searchParams.append('id', String(getLeaseData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        }
      })
    );
  }

  public async updateLeaseTransaction(updateLeaseData: LeaseTransactionUpdateRequestExpanded, token: string): Promise<LeaseTransactionUpdateResponse | string | IError> {
    const url = new URL(Config.MAIN_SERVICE_ENDPOINT + "lease-transaction/update")

    const data = new FormData()

    Object.entries(updateLeaseData).forEach(([key, val]) => {
      if (Array.isArray(val)) {
        val.forEach(singleValue => {
          data.append(`${key}[]`, singleValue)
        })
      } else {
        data.append(key, val)
      }
    })

    return handleErrors(
      fetch(url.toString(), {
        method: "PUT",
        headers: {
          ...authHeader(token)
        },
        body: data
      })
    );
  }

  public async updateLeaseTransactionStatus(updateSaleData: UpdateLeaseStatusDTO, token: string): Promise<LeaseTransactionStatusHistoryFullDTO | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "lease-transaction/status-update")

    return handleErrors(
      fetch(url.toString(), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        },
        body: JSON.stringify(updateSaleData)
      })
    );
  }

  public async updateLeaseTransactionActiveStatus(updateSaleData: UpdateActiveLeaseStatusDTO, token: string): Promise<LeaseTransactionStatusHistoryFullDTO | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "lease-transaction/active-status-update")

    return handleErrors(
      fetch(url.toString(), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        },
        body: JSON.stringify(updateSaleData)
      })
    );
  }

  public async deleteLeaseTransaction(deleteLeaseData: LeaseTransactionDeleteRequest, token: string): Promise<LeaseTransactionDeleteResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "lease-transaction/delete")

    url.searchParams.append('id', String(deleteLeaseData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        }
      })
    );
  }
}

export const LeaseApi = new API();