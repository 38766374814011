import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { UserRoleUpdateRequest } from '@ternala/voltore-types';

/* UI components */
import Loader from 'components/Loader';
import ListItem from './ListItem';

/* Components */
import { GoBackIcon } from 'components/icons/GoBackIcon';
import { DownArrowIcon } from 'components/icons/DownArrowIcon';
import UserRoleModal, {
  userRoleModalKey,
} from 'components/modals/UserRoleModal';
import CustomScrollbars from 'components/CustomScrollbars';
import PopupProceed from 'components/modals/PopupProceed';

/* constants */
import { PageEnum } from 'config/constants';

/* types */
import { IStore } from 'controllers/store';
import { addNotification } from 'controllers/modals/actions';
import { NotificationTypeEnum } from 'controllers/modals/models.d';
import { IUserState } from 'controllers/user/models';
import {
  createUserRoleAction,
  deleteUserRoleAction,
  getUserRolesAction,
  updateUserRoleAction,
} from 'controllers/user/actions';

import uuid from 'utils/uuid';

import { ISettingItem } from '../config';
import { addShowedElement } from 'controllers/showElement/actions';
import { taxonomyModalKey } from '../../../components/modals/TaxonomyModal';
import {
  IModalElement,
  ShowElementEnum,
} from 'controllers/showElement/models.d';

interface Props {
  page?: ISettingItem;
  userState: IUserState;
}

const UserRolePage: FC<Props> = (props) => {
  const {
    page,
    userState: { userRoleData, userRoles },
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserRolesAction.request({}));
  }, []);

  // taxonomies (taxonomies from type)
  const items = userRoles?.map((id) => userRoleData[id]);

  // // actions (taxonomies)
  // const [createItem, setCreateItem] = useState<boolean>(false);
  // const [editItem, setEditItem] = useState<UserRoleUpdateRequest | undefined>(undefined);
  const [deleteItem, setDeleteItem] = useState<number | undefined>(undefined);

  return (
    <>
      <Helmet>
        <title>
          {page?.title ? page?.title : 'Setting'} | Voltore
          {process.env.REACT_APP_COMPANY_NAME
            ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
            : ''}
        </title>
        <meta name="description" content="Voltore application" />
      </Helmet>
      {items ? (
        <CustomScrollbars id="taxonomy-info-container">
          <div id="tag-info">
            <div
              className="button-go-back"
              onClick={() => dispatch(push(`/${PageEnum.SETTINGS}`))}
              title="Back to settings">
              <GoBackIcon />
            </div>
            <div className="group-header">
              <span className="group-title">{page?.title}</span>
            </div>
            <div className="header">
              <div>
                <span className="title">Name</span>
                {/*<DownArrowIcon/>*/}
              </div>
              <div
                className="add-new"
                onClick={() => {
                  dispatch(
                    addShowedElement({
                      id: uuid(),
                      key: userRoleModalKey,
                      type: ShowElementEnum.modal,
                      props: {},
                    } as IModalElement),
                  );
                }}>
                <span>add new</span>
              </div>
            </div>
            <div className="tag-info__content">
              {items?.length ? (
                items.map((item) => (
                  <ListItem
                    item={item}
                    editTaxonomy={(value) => {
                      dispatch(
                        addShowedElement({
                          id: uuid(),
                          key: userRoleModalKey,
                          type: ShowElementEnum.modal,
                          props: {
                            id: value.id,
                          },
                        } as IModalElement),
                      );
                    }}
                    deleteTaxonomy={(value) => setDeleteItem(value)}
                  />
                ))
              ) : (
                <span
                  className="no-items-specified"
                  style={{
                    marginLeft: ' 0px',
                    marginBottom: ' 0px',
                    marginTop: '15px',
                    marginRight: '0px',
                  }}>
                  No taxonomies are specified.
                </span>
              )}
              {}
            </div>
          </div>
        </CustomScrollbars>
      ) : (
        <div
          className="item-info__loader_info-tab"
          style={{ marginTop: '17px' }}>
          <Loader />
        </div>
      )}

      {deleteItem ? (
        <PopupProceed
          entityId={deleteItem}
          entityType={'role'}
          setCreateEntity={() => {
            dispatch(
              addShowedElement({
                id: uuid(),
                key: userRoleModalKey,
                type: ShowElementEnum.modal,
                props: {},
              } as IModalElement),
            );
          }}
          onClose={() => {
            setDeleteItem(undefined);
          }}
          onSubmit={(id, newUserRoleId, callback) =>
            dispatch(
              deleteUserRoleAction.request({
                id,
                ...(newUserRoleId ? { newUserRoleId } : {}),
                callback: (status: boolean) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Successfully deleted',
                        type: NotificationTypeEnum.success,
                        title: 'Successfully deleted',
                      }),
                    );
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (callback) callback(status);
                },
              }),
            )
          }
        />
      ) : (
        ''
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  userState: store.user,
}))(UserRolePage);
