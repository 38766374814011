import React  from 'react';
import { connect } from 'react-redux';
import { RouterState } from 'connected-react-router';

/* components */
import DataListControls from './DataListControls';
import Loader from 'components/Loader';
import SelectSort from 'components/UI/controls/SelectSort';

/* constants */
import { FilterTypeEnum } from 'config/constants';

/* types */
import { Pages } from 'routing';
import { PersonsGetListFiltersExtended } from 'controllers/person/models';
import { EnterpriseGetListFiltersExtended } from 'controllers/enterprise/models.d';
import {
  PropertyGetListFiltersByPropertyExtended,
  PropertyGetListFiltersBySaleTransactionExtended,
  PropertyGetListFiltersByLeaseTransactionExtended,
} from 'controllers/property/models';
import { IDataListItem, ItemsLoadType, OptionType } from 'models';

import { IStore } from 'controllers/store';
import ScrollList from './ScrollList';

export type singleItemType =
  | 'user'
  | 'person'
  | 'enterprise'
  | 'property'
  | 'tag group'
  | 'taxonomy-type'
  | 'setting';

interface IProps {
  routerState: RouterState;
  pageTitle: Pages;
  activeTab?: string;
  singleItem: singleItemType;

  items?: IDataListItem[];
  newItems?: IDataListItem[];
  itemsCount?: number;
  itemsLoading: boolean;
  allItemsLoaded?: boolean;

  onLoadMore?: (loadType: ItemsLoadType, callback?: Function) => void;
  onSort?: (sortBy: OptionType) => void;
  setIsLoading?: (state: boolean) => void;

  sortValue?: OptionType;
  sortOptions?: OptionType[];

  isFullScreen?: boolean;
  checkSuffixByActive?: boolean;
  isHidden?: boolean;

  listControls?: {
    searchPlaceholder: string;
    searchQuery?: string;
    onSearch: (query: string) => void;
    onAddNew?: () => void;
    clearSearchQuery?: Function;
  };

  Filters?: {
    Component?: () => JSX.Element;
    isOpen: boolean;
    toggle: (isFullScreen: boolean) => void;
    applied?: {
      persons?: PersonsGetListFiltersExtended;
      enterprises?: EnterpriseGetListFiltersExtended;
      property?: PropertyGetListFiltersByPropertyExtended;
      sale?: PropertyGetListFiltersBySaleTransactionExtended;
      lease?: PropertyGetListFiltersByLeaseTransactionExtended;
    };
    resetFilter: (filterType?: FilterTypeEnum) => void;
    onClear: () => void;
  };
}

export const DataList: React.FC<IProps> = (props) => {
  const {
    routerState: {
      location: { pathname },
    },
    pageTitle,
    activeTab,
    items,
    newItems,
    itemsCount,
    allItemsLoaded,
    sortValue,
    sortOptions,
    onSort,
    listControls,
    Filters,
    isFullScreen,
    setIsLoading,
    onLoadMore,
    checkSuffixByActive,
    isHidden,
    itemsLoading,
  } = props;

  return !isFullScreen ? (
    <>
      <div
        className={
          'data-list items-container' +
          (pathname !== `/${pageTitle}` && pathname !== `/tag-groups`
            ? ' data-list_hidden'
            : '') +
          (isHidden ? '  visually-hidden' : '') +
          (isFullScreen ? ' filters-and-map-full-screen' : '')
        }>
        {listControls && (
          <DataListControls
            onSearch={() => {
              if (setIsLoading) setIsLoading(true);
              if (onLoadMore)
                onLoadMore('start', () => setIsLoading && setIsLoading(false));
            }}
            pageTitle={pageTitle}
            searchPlaceholder={listControls.searchPlaceholder}
            searchValue={listControls.searchQuery}
            onChange={(e) => listControls.onSearch(e.target.value)}
            onAddNew={listControls.onAddNew ? listControls.onAddNew : undefined}
            callback={() =>
              listControls?.clearSearchQuery
                ? listControls?.clearSearchQuery()
                : {}
            }
            Filters={Filters}
          />
        )}
        {itemsLoading ? (
          <div className="items-container__loader">
            <Loader />
          </div>
        ) : (
          <>
            {sortOptions && onSort ? (
              <div
                className={`data-list__sort ${
                  items && items.length ? 'dash' : ''
                } sort`}>
                <SelectSort
                  options={sortOptions}
                  value={sortValue}
                  onChange={(value) => onSort(value)}
                  disabled={items && items.length === 0}
                />
                <div className="items-count">
                  <span>
                    {itemsCount?.toLocaleString()}&nbsp;Item
                    {itemsCount === 1 ? '' : 's'}
                    &nbsp;Found
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}
            {items && items.length > 0 ? (
              <ScrollList
                items={items}
                newItems={newItems}
                activeTab={activeTab}
                pageTitle={props.pageTitle}
                singleItem={props.singleItem}
                onLoadMore={props.onLoadMore}
                allItemsLoaded={allItemsLoaded || false}
                checkSuffixByActive={checkSuffixByActive}
              />
            ) : (
              <>
                {items && items.length === 0 && (
                  <div className="data-list__nothing-found">Nothing found</div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  ) : null;
};

export default connect((store: IStore) => ({
  routerState: store.router,
}))(DataList);
