import React from 'react';
import { ICardActionElement } from '../../../model.d';
import { InnerLink } from '../../../../../routing/InnerLink';
import { ContentBlock, ContentState } from 'draft-js';
import { CardEntityEnum } from '@ternala/voltore-types/lib/constants/card';
import { cardActions } from '../../../constants';
import uuid from '../../../../../utils/uuid';
import { CardActionEnum, EntityEnum } from '@ternala/voltore-types/lib/constants';
import { connect } from "react-redux";
import { IStore } from "controllers/store";

export const Mention: React.FC<any> = (
  { url, disabled, selectedEntity,  ...props },
) => {
  const {
    id,
    involvementId,
    transactionId,
    entityType,
  }: {
    id?: number;
    involvementId?: number;
    transactionId?: number;
    entityType?: CardEntityEnum;
  } = props.contentState.getEntity(props.entityKey).getData();
  let action: ICardActionElement = {
    ...(entityType ? cardActions[entityType] : {}),
    entityTitle: (entityType && cardActions[entityType]?.title) || '',
    title: (entityType && cardActions[entityType]?.title) || '',
    uuid: uuid(),
    key: entityType as unknown as CardActionEnum,
  };

  function previewContextData() {
    switch (entityType) {
      case CardEntityEnum.Involvement:
        return { id, type: EntityEnum.involvement };
      case CardEntityEnum.Person:
        return { id, type: EntityEnum.person };
      case CardEntityEnum.Lease_Transaction:
        return {
          id,
          type: EntityEnum.leaseTransaction,
          transactionId,
        };
      case CardEntityEnum.Sale_Transaction:
        return {
          id,
          type: EntityEnum.saleTransaction,
          transactionId,
        };
      case CardEntityEnum.Property:
        return { id, type: EntityEnum.property };
      case CardEntityEnum.Enterprise:
        return { id, type: EntityEnum.enterprise };
      default:
        break;
    }
  }

  if (action?.to) {
    return (
      <InnerLink
        id={id}
        to={action.to}
        className={'mention' + (selectedEntity?.type === action.key && selectedEntity?.ids.includes(id) ? " selected" : "")}
        tabName={action.tabName}
        transactionId={transactionId}
        involvementId={involvementId}
        type={previewContextData()?.type}>
        {props.children}
      </InnerLink>
    );
  } else {
    return <span className={'mention not-clicked'}>{props.children}</span>;
  }
};

export const connectedMention = connect((state: IStore) => ({
  selectedEntity: state.showElement.selectedEntity
}))(Mention)

export function findMentions(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'MENTION'
    );
  }, callback);
}
