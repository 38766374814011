import React from 'react';
import { TagCategoryShortDTO } from '@ternala/voltore-types/lib/modules/card/tag/category/tagCategoryShort.dto';

interface IProps {
  category: TagCategoryShortDTO;
}

const Category = ({ category }: IProps) => {
  return (
    <div className="tag-category">
      <label className="tag-category title">{category.title}</label>
    </div>
  );
};
export default Category;
