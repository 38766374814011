
import React, { FC, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import MapLegend from '../LeaseTransactionViewLegend';

/* constants & utils */
import { DATE_FORMAT } from 'config';
import { propertiesTabs } from 'config/constants/tabs';
import { LeaseTransactionStatusEnum } from '@ternala/voltore-types/lib/constants';
import { LeaseStatusVisualization, PageEnum } from 'config/constants';
import { checkCommonItems } from 'utils/checkCommonItems';
import nFormatter from 'utils/nFormatter';
import { compareArrays } from 'utils/compareArrays';
import {
  computeDaysOnMarket,
  withThousandsSeparators,
} from 'utils/helper-functions';

/* types */
import { LeaseTransactionFullDTO } from '@ternala/voltore-types';
import { PropertyFullExpandDTO } from 'controllers/property/models';
import { IFloor, IStackItem } from '../index';
import { OptionType } from 'models';

interface IProps {
  currentProperty: PropertyFullExpandDTO;
  loadTransactions: (data: {
    callback?: Function;
    limit?: number;
    offset?: number;
  }) => void;
  leaseTransactionsSplitted: IFloor[];
  leaseTransactionsNA: IFloor;
  transactionsVisualizationFilter: OptionType;
  setSelectedLeaseTransactions: (
    list: LeaseTransactionFullDTO[] | null,
  ) => void;
  selectedLeaseTransactions: LeaseTransactionFullDTO[] | null;
}

const StackView: FC<IProps> = ({
  currentProperty,
  loadTransactions,
  leaseTransactionsNA,
  leaseTransactionsSplitted,
  selectedLeaseTransactions,
  setSelectedLeaseTransactions,
  transactionsVisualizationFilter,
}) => {
  const dispatch = useDispatch();

  const [hoveredTransactionId, setHoveredTransactionId] = useState<
    number[] | null
  >(null);
  const [maxWidth, setMaxWidth] = useState<number>(0);

  useEffect(() => {
    loadTransactions({ offset: 0, limit: 999999 });
  }, []);

  useEffect(() => {
    const maxLeaseArea = Math.max(
      ...leaseTransactionsNA.items.map((stackItem) =>
        Math.min(
          ...stackItem.items.map(
            (item) => (item.leasableSQFT || 0) / (item.suites?.length || 1),
          ),
        ),
      ),
      ...leaseTransactionsSplitted.map((floor) =>
        getFloorWidth(
          floor.items.map((stackItem) =>
            Math.min(
              ...stackItem.items.map(
                (item) => (item.leasableSQFT || 0) / (item.suites?.length || 1),
              ),
            ),
          ),
        ),
      ),
    );

    setMaxWidth(maxLeaseArea);
  }, [leaseTransactionsNA, leaseTransactionsSplitted]);

  const getFloorWidth = (areas: number[]): number => {
    return areas.reduce((sum, area) => sum + (area || 0), 0);
  };

  const generateSuite = (stackItem: IStackItem, maxWidth?: number) => {
    const status = stackItem.items[0].historyStatuses?.[0];

    let leaseStatus = LeaseStatusVisualization.LEASED;

    if (status) {
      switch (status.status) {
        case LeaseTransactionStatusEnum.Leased:
          if (new Date(status?.startDate) > new Date()) {
            leaseStatus = LeaseStatusVisualization.WILL_BE_LEASED;
          } else {
            leaseStatus = LeaseStatusVisualization.LEASED;
          }
          break;
        default:
          leaseStatus = LeaseStatusVisualization.AVAILABLE;
      }
    }

    return (
      <div
        key={stackItem.id}
        className="suite-wrap"
        style={
          maxWidth
            ? {
                width:
                  (Math.min(
                    ...stackItem.items.map(
                      (leaseTransaction) =>
                        (leaseTransaction.leasableSQFT || 0) /
                        (leaseTransaction.suites?.length || 1),
                    ),
                  ) /
                    maxWidth) *
                    100 +
                  '%',
              }
            : {}
        }>
        <div
          className={
            'suite' +
            (stackItem.items.length > 1 ? ' overlap' : '') +
            ` ${leaseStatus}` +
            (transactionsVisualizationFilter.value &&
            transactionsVisualizationFilter.value !== status?.status
              ? ' disabled'
              : '') +
            (hoveredTransactionId &&
            checkCommonItems<number>(
              hoveredTransactionId,
              stackItem.items.map(({ id }) => id),
            )
              ? ' hovered'
              : '') +
            (compareArrays(
              selectedLeaseTransactions?.map((transaction) => transaction.id),
              stackItem.items.map((transaction) => transaction.id),
            )
              ? ' selected'
              : '')
          }
          onMouseOver={() =>
            setHoveredTransactionId(stackItem.items.map(({ id }) => id))
          }
          onMouseOut={() => setHoveredTransactionId(null)}
          onClick={() => {
            setSelectedLeaseTransactions(stackItem.items);
            dispatch(
              push(
                status?.status === LeaseTransactionStatusEnum.Leased
                  ? `/${PageEnum.PROPERTIES}/${currentProperty.id}/${propertiesTabs.tenants.path}/${stackItem.items[0].id}`
                  : `/${PageEnum.PROPERTIES}/${currentProperty.id}/${propertiesTabs.leases.path}/${stackItem.items[0].id}`,
              ),
            );
          }}>
          <span className="suite-inner">
            {stackItem.items.map((leaseTransaction, i, array) => {
              return (
                <React.Fragment key={leaseTransaction.id}>
                  <span data-id={leaseTransaction.id} className="suite-area">
                    {nFormatter(leaseTransaction.leasableSQFT || 0)}
                    <span className="suite-counts">
                      {(leaseTransaction.suites?.length || 0) > 1
                        ? `/${leaseTransaction.suites?.length}`
                        : ''}
                    </span>
                    {i + 1 !== array.length ? ', ' : ''}
                  </span>
                </React.Fragment>
              );
            })}
          </span>
        </div>

        <div className="tooltip-content">
          {stackItem.items.map((leaseTransaction) =>
            leaseTransaction.historyStatuses?.[0]?.status === 'Leased' ? (
              <div className="tooltip-content__block" key={leaseTransaction.id}>
                {leaseTransaction.leaseExpiration ? (
                  <div className="tooltip-content__row tooltip-content__row_date">
                    <div className="tooltip-content__row-title">
                      Expired:&nbsp;
                    </div>
                    <div className="tooltip-content__row-content">
                      <Moment format={DATE_FORMAT}>
                        {leaseTransaction.leaseExpiration}
                      </Moment>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {leaseTransaction.tenant ? (
                  <div className="tooltip-content__row">
                    <div className="tooltip-content__row-title">
                      Tenant:&nbsp;
                    </div>
                    <div className="tooltip-content__row-content ellipsis-truncate">
                      {leaseTransaction.tenant.title}
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {leaseTransaction.leasableSQFT ? (
                  <div className="tooltip-content__row">
                    <div className="tooltip-content__row-title">
                      LOT SQFT:&nbsp;
                    </div>
                    <div className="tooltip-content__row-content">
                      {withThousandsSeparators(leaseTransaction.leasableSQFT)}
                      &nbsp;SQFT
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div className="tooltip-content__block" key={leaseTransaction.id}>
                {leaseTransaction.historyStatuses &&
                  leaseTransaction.historyStatuses.length > 0 && (
                    <div className="tooltip-content__row">
                      <div className="tooltip-content__row-title">
                        listing status:&nbsp;
                      </div>
                      <div
                        className={
                          'tooltip-content__row-content ' +
                          (leaseTransaction.historyStatuses[0].status.toLowerCase() ||
                            '')
                        }>
                        {leaseTransaction.historyStatuses[0].status || ''}
                      </div>
                    </div>
                  )}

                {leaseTransaction.owner ? (
                  <div className="tooltip-content__row">
                    <div className="tooltip-content__row-title">
                      Landlord:&nbsp;
                    </div>
                    <div className="tooltip-content__row-content ellipsis-truncate">
                      {leaseTransaction.owner.title}
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {leaseTransaction.leasableSQFT ? (
                  <div className="tooltip-content__row">
                    <div className="tooltip-content__row-title">
                      LOT SQFT:&nbsp;
                    </div>
                    <div className="tooltip-content__row-content">
                      {withThousandsSeparators(leaseTransaction.leasableSQFT)}
                      &nbsp;SQFT
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {computeDaysOnMarket(leaseTransaction) > 0 && (
                  <div className="tooltip-content__row">
                    <div className="tooltip-content__row-title">
                      Days On Market:&nbsp;
                    </div>
                    <div className="tooltip-content__row-content">
                      {computeDaysOnMarket(leaseTransaction)}
                    </div>
                  </div>
                )}
              </div>
            ),
          )}
        </div>
      </div>
    );
  };

  const generateFloor = ({
    floor,
    maxWidth,
    className,
  }: {
    floor: IFloor;
    maxWidth?: number;
    className?: string;
  }) => {
    return (
      <tr
        className={'floor' + (className ? ` ${className}` : '')}
        key={floor.title}>
        {className === 'without-floor' ? <div className="line" /> : ''}
        <div className="information">
          <div className="floor-title">{floor.title}</div>
          <div className={`suites ${floor.items.length ? '' : 'background'}`}>
            <div className="suites-row">
              {floor.items.map((stackItem) =>
                generateSuite(stackItem, maxWidth),
              )}
            </div>
          </div>
        </div>
      </tr>
    );
  };

  return (
    <div className="transaction-stack">
      <MapLegend />

      <table className="transaction-stack-table">
        {leaseTransactionsSplitted?.map((floor) =>
          generateFloor({ floor, maxWidth }),
        )}
        {generateFloor({
          floor: leaseTransactionsNA,
          className: 'without-floor',
          maxWidth,
        })}
      </table>
    </div>
  );
};

export default StackView;
