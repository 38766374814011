import React, { FC, useContext, useEffect, useState } from 'react';
import { EntityEnum } from "@ternala/voltore-types/lib/constants";

import PreviewContext from 'context/Modal';
import {
  NavLink,
  generatePath,
  matchPath,
  useLocation,
} from 'react-router-dom';

import schema, { Pages } from './index';

interface Props {
  to: Pages;
  additionalSuffix?: string;
  checkSuffixByActive?: boolean;
  id?: number | string;
  tabName?: string;
  involvementId?: number | string;
  transactionId?: number | string;
  className?: string;
  activeClass?: string;
  slug?: string;
  onClick?: () => void;
  query?: string;
  children?: JSX.Element | JSX.Element[] | string;
  type?: EntityEnum;
}

let timeout: any;
export const InnerLink: FC<Props> = ({
  to,
  id,
  involvementId,
  transactionId,
  additionalSuffix,
  slug,
  tabName,
  children,
  className,
  activeClass,
  onClick,
  query,
  type,

  checkSuffixByActive = true,
}) => {
  const { pathname } = useLocation();

  // General context (information preview)
  const previewContext = useContext(PreviewContext);
  const [inside, setInside] = useState<boolean>(false);

  let link: string;

  try {
    link =
      id || tabName || involvementId || transactionId || slug
        ? generatePath(schema.getLink(to), {
            id,
            tabName,
            involvementId,
            transactionId,
            slug,
          })
        : schema.getLink(to) || '/error';
  } catch (e) {
    link = schema.getLink(to) || '/error';
  }

  const isActive = matchPath(pathname, { path: link, exact: false });

  if (additionalSuffix) {
    link = link + additionalSuffix;
  }

  if (query) {
    link = link + '?' + query;
  }

  const onClickFunction: () => void = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  useEffect(() => {
    if (!inside) {
      clearTimeout(timeout);
      if (previewContext.preview?.inside) {
        previewContext.setPreview({
          inside: false,
        });
      }
    } else {
      timeout = setTimeout(function () {
        previewContext.setPreview({
          type,
          inside,
          id: Number(id),
          modal: previewContext.preview?.modal,
          transactionId: Number(transactionId),
        });
      }, 500);
    }
  }, [inside]);

  return (
    <NavLink
      exact={false}
      to={link}
      className={
        (className || '') +
        (!checkSuffixByActive && isActive ? ` ${activeClass}` : '')
      }
      onClick={onClickFunction}
      activeClassName={activeClass}
      onMouseEnter={() =>
        id !== previewContext.preview?.id && type
          ? setInside(true)
          : {}
      }
      onMouseLeave={() => (setInside ? setInside(false) : {})}>
      {children}
    </NavLink>
  );
};

// export default InnerLink;
