import { take, call, put } from "redux-saga/effects";
import { subscribe } from "../suscribe";
import {ISocketEvent} from "../models";
import {EventChannel} from "redux-saga";
import {PayloadAction} from "typesafe-actions/dist/type-helpers";

export function* read(socket: SocketIOClient.Socket, events: ISocketEvent[]) {
  const channel: EventChannel<PayloadAction<any, any>> = yield call(subscribe, socket, events);
  while (true) {
    let action: PayloadAction<any, any> = yield take(channel);
    yield put(action);
  }
}
