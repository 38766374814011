import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
}

export const PointerLinked: FC<Props> = (props) => (
    <svg width="6px" height="12px" className='mapaddress-linker-linked-img' {...props} viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M5 8V9C5 10.1046 4.10457 11 3 11C1.89543 11 1 10.1046 1 9V8H0V9C0 10.6569 1.34315 12 3 12C4.65685 12 6 10.6569 6 9V8H5ZM5 4H6V3C6 1.34315 4.65685 0 3 0C1.34315 0 0 1.34315 0 3V4H1V3C1 1.89543 1.89543 1 3 1C4.10457 1 5 1.89543 5 3V4Z"/>
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M5 8V9C5 10.1046 4.10457 11 3 11C1.89543 11 1 10.1046 1 9V8H0V9C0 10.6569 1.34315 12 3 12C4.65685 12 6 10.6569 6 9V8H5ZM5 4H6V3C6 1.34315 4.65685 0 3 0C1.34315 0 0 1.34315 0 3V4H1V3C1 1.89543 1.89543 1 3 1C4.10457 1 5 1.89543 5 3V4Z" fill="white"/>
        <path d="M5 8V7H4V8H5ZM1 8H2V7H1V8ZM0 8V7H-1V8H0ZM6 8H7V7H6V8ZM5 4H4V5H5V4ZM6 4V5H7V4H6ZM0 4H-1V5H0V4ZM1 4V5H2V4H1ZM6 9V8H4V9H6ZM3 12C4.65685 12 6 10.6569 6 9H4C4 9.55228 3.55228 10 3 10V12ZM0 9C0 10.6569 1.34315 12 3 12V10C2.44772 10 2 9.55228 2 9H0ZM0 8V9H2V8H0ZM1 7H0V9H1V7ZM-1 8V9H1V8H-1ZM-1 9C-1 11.2091 0.790861 13 3 13V11C1.89543 11 1 10.1046 1 9H-1ZM3 13C5.20914 13 7 11.2091 7 9H5C5 10.1046 4.10457 11 3 11V13ZM7 9V8H5V9H7ZM6 7H5V9H6V7ZM5 5H6V3H5V5ZM7 4V3H5V4H7ZM7 3C7 0.790861 5.20914 -1 3 -1V1C4.10457 1 5 1.89543 5 3H7ZM3 -1C0.790861 -1 -1 0.790861 -1 3H1C1 1.89543 1.89543 1 3 1V-1ZM-1 3V4H1V3H-1ZM0 5H1V3H0V5ZM0 3V4H2V3H0ZM3 0C1.34315 0 0 1.34315 0 3H2C2 2.44772 2.44772 2 3 2V0ZM6 3C6 1.34315 4.65685 0 3 0V2C3.55228 2 4 2.44772 4 3H6ZM6 4V3H4V4H6Z" fill="white" mask="url(#path-1-inside-1)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3 3C3.27614 3 3.5 3.22386 3.5 3.5V8.5C3.5 8.77614 3.27614 9 3 9C2.72386 9 2.5 8.77614 2.5 8.5V3.5C2.5 3.22386 2.72386 3 3 3Z" fill="white"/>
    </svg>

)