import { Config } from 'config/api';
import { authHeader, handleErrors } from 'utils/API';
import { appendSearchParams } from 'utils/appendSearchParams';

import {
  CreateSuiteDTO,
  EnterpriseFullWithTreeDTO, PersonGetListRequest,
  PropertyCreateResponse,
  PropertyDeleteRequest,
  PropertyDeleteResponse,
  PropertyFullDTO,
  PropertyGetListRequest,
  PropertyGetListResponse,
  PropertyGetRequest,
  PropertyGetResponse,
  PropertyMapGetExtremePointsResponse,
  PropertyMapGetListRequest,
  PropertyMapGetListResponse,
  PropertyUpdateFloorsList,
  PropertyUpdateResponse,
  SuiteFullDTO
} from "@ternala/voltore-types";
import { PropertyCreateRequestExpanded, PropertyUpdateRequestExpanded } from "../models";
import { AsyncOptions } from 'components/UI/controls/SelectSearchCustom';
import {IError} from "../../model";
import { TaxonomyTypeSlug } from "@ternala/voltore-types/lib/constants";

class API {
  public prepareGetListLink (url: URL, data: PropertyGetListRequest){
    if (data.hasOwnProperty('property')) {
      url.searchParams.append(
        'property',
        JSON.stringify(data.property),
      );
    }

    if (data.hasOwnProperty('saleTransaction')) {
      url.searchParams.append(
        'saleTransaction',
        JSON.stringify(data.saleTransaction),
      );
    }

    if (data.hasOwnProperty('leaseTransaction')) {
      url.searchParams.append(
        'leaseTransaction',
        JSON.stringify(data.leaseTransaction),
      );
    }

    if (data.hasOwnProperty('selectedTags')) {
      data.selectedTags?.forEach((tag) => {
        url.searchParams.append('selectedTags[]', `${tag}`);
      });
    }

    if (data.hasOwnProperty('unselectedTags')) {
      data.unselectedTags?.forEach((tag) => {
        url.searchParams.append('unselectedTags[]', `${tag}`);
      });
    }

    if (data.hasOwnProperty('area')) {
      url.searchParams.append('area', JSON.stringify(data.area));
    }

    return url;
  }

  public async getProperties(
    getPropertyData: PropertyGetListRequest,
    token: string,
  ): Promise<PropertyGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'property/list');
    url = appendSearchParams(url, getPropertyData);

    url = this.prepareGetListLink(url, getPropertyData)

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async getMapItems(
    getPropertyData: PropertyMapGetListRequest,
    token: string,
  ): Promise<PropertyMapGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'property/list-for-map');

    url = appendSearchParams(url, getPropertyData);

    if (getPropertyData.hasOwnProperty('property')) {
      url.searchParams.append(
        'property',
        JSON.stringify(getPropertyData.property),
      );
    }

    if (getPropertyData.hasOwnProperty('saleTransaction')) {
      url.searchParams.append(
        'saleTransaction',
        JSON.stringify(getPropertyData.saleTransaction),
      );
    }

    if (getPropertyData.hasOwnProperty('leaseTransaction')) {
      url.searchParams.append(
        'leaseTransaction',
        JSON.stringify(getPropertyData.leaseTransaction),
      );
    }

    if (getPropertyData.hasOwnProperty('selectedTags')) {
      getPropertyData.selectedTags?.forEach((tag) => {
        url.searchParams.append('selectedTags[]', `${tag}`);
      });
    }

    if (getPropertyData.hasOwnProperty('area')) {
      url.searchParams.append('area', JSON.stringify(getPropertyData.area));
    }

    if (getPropertyData.hasOwnProperty('preferType') && getPropertyData.preferType) {
      url.searchParams.append('preferType', getPropertyData.preferType);
    }

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async createProperty(
    createPropertyData: PropertyCreateRequestExpanded,
    token: string,
  ): Promise<PropertyCreateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'property/create');
    const data = new FormData();

    Object.entries(createPropertyData).forEach(([key, val]) => {
      if (key === 'address' || key === 'taxBillingAddress') {
        for (let key1 in val) {
          if(val[key1]){
            data.append(`${key}[${key1}]`, val[key1]);
          }
        }
        return;
      }

      if (Array.isArray(val)) {
        val.forEach((singleValue) => {
          data.append(`${key}[]`, singleValue);
        });
        return;
      }

      return data.append(key, val);
    });

    return handleErrors(
      fetch(url.toString(), {
        method: 'POST',
        headers: {
          // "Content-Type": "application/json",
          ...authHeader(token),
        },
        body: data,
      }),
    );
  }

  public async getProperty(
    getPropertyData: PropertyGetRequest,
    token: string,
  ): Promise<PropertyGetResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'property/get');

    url.searchParams.append('id', String(getPropertyData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          // "Content-Type": "application/json",
          ...authHeader(token),
        },
      }),
    );
  }

  public async updateProperty(
    updatePropertyData: PropertyUpdateRequestExpanded,
    token: string,
  ): Promise<PropertyUpdateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'property/update');

    const data = new FormData();

    Object.entries(updatePropertyData).forEach(([key, val]) => {
      if (key === 'address' || key === 'taxBillingAddress') {
        for (let key1 in val) {
          if(val[key1]){
            data.append(`${key}[${key1}]`, val[key1]);
          }
        }
        return;
      }

      if (Array.isArray(val)) {
        val.forEach((singleValue) => {
          data.append(`${key}[]`, singleValue);
        });
        return;
      }

      return data.append(key, val);
    });

    return handleErrors(
      fetch(url.toString(), {
        method: 'PUT',
        headers: {
          // "Content-Type": "application/json",
          ...authHeader(token),
        },
        body: data,
      }),
    );
  }

  public async deleteProperty(
    deletePropertyData: PropertyDeleteRequest,
    token: string,
  ): Promise<PropertyDeleteResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'property/delete');

    url.searchParams.append('id', String(deletePropertyData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'DELETE',
        headers: {
          // "Content-Type": "application/json",
          ...authHeader(token),
        },
      }),
    );
  }

  public async getAllProperties(
    token: string,
    searchParams?: AsyncOptions,
  ): Promise<PropertyGetListResponse | null> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'property/list');
    if (searchParams) url = appendSearchParams(url, searchParams);
    try {
      const response = await fetch(String(url), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      });
      const data = await response.json();
      if (response.statusText === 'OK' || response.status === 200) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async createPropertySuite(
    createSuiteData: CreateSuiteDTO,
    token: string,
  ): Promise<SuiteFullDTO | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'property/suite/create');

    return handleErrors(
      fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(createSuiteData),
      }),
    );
  }

  public async updatePropertyFloorList(
    updatePropertyFloorListData: PropertyUpdateFloorsList,
    token: string,
  ): Promise<PropertyFullDTO | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'property/update-floors');

    return handleErrors(
      fetch(url.toString(), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(updatePropertyFloorListData),
      }),
    );
  }

  public async getPropertyTree(
    getPropertyData: PropertyGetRequest,
    token: string,
  ): Promise<EnterpriseFullWithTreeDTO | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'property/get-tree');

    url.searchParams.append('id', String(getPropertyData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          // "Content-Type": "application/json",
          ...authHeader(token),
        },
      }),
    );
  }

  public async getExtremePoints(
    getPropertyData: PropertyGetListRequest,
    token: string,
  ): Promise<PropertyMapGetExtremePointsResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'property/get-extreme-points');

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          ...authHeader(token),
        },
      }),
    );
  }
}
export const PropertyApi = new API();
