import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const StarIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.33426 1.04893C6.54381 0.404016 7.45619 0.404018 7.66574 1.04894L8.63895 4.04418C8.73266 4.33259 9.00143 4.52786 9.30469 4.52786H12.4541C13.1322 4.52786 13.4141 5.3956 12.8655 5.79418L10.3176 7.64534C10.0723 7.82359 9.96962 8.13954 10.0633 8.42796L11.0365 11.4232C11.2461 12.0681 10.508 12.6044 9.95935 12.2058L7.41145 10.3547C7.16611 10.1764 6.83389 10.1764 6.58855 10.3547L4.04065 12.2058C3.49205 12.6044 2.75391 12.0681 2.96346 11.4232L3.93667 8.42796C4.03038 8.13954 3.92772 7.82359 3.68238 7.64534L1.13448 5.79418C0.585876 5.39559 0.86782 4.52786 1.54593 4.52786H4.69531C4.99857 4.52786 5.26734 4.33259 5.36105 4.04418L6.33426 1.04893Z"
      fill={color}
    />
  </svg>
);

StarIcon.defaultProps = {
  color: '#DCDEDF',
};
