import { DropdownIcon } from 'components/icons/DropdownIcon';
import React, { useEffect, useState } from "react";
import GenerateItem from './Field';

interface IProps {
  entityId: number;
  label: string;
  items: Array<any>;
}

const InfoMultiField = ({ label, items = [], entityId }: IProps) => {
  const localItems = items.sort((item1, item2) => {
    if(item1.isFavorite && !item2.isFavorite) return -1
    if(!item1.isFavorite && item2.isFavorite) return 1
    return 0
  })

  const favoriteQuantity = localItems.filter(item => item.isFavorite).length;
  const allQuantity = localItems.length;
  const isHasMore = allQuantity > 3 && favoriteQuantity < allQuantity;

  const fixedQuantity = favoriteQuantity > 3 ? favoriteQuantity : 3;

  const [useSliced, setUseSliced] = useState<boolean>(isHasMore);

  return (
    <div className="data-field">
      <div
        className={`data-field__key_lg ${
          localItems?.length ? '' : 'no-information'
        }`}>
        {label}
      </div>
      {localItems?.length ? (
        <div className="data-field__value">
          {(useSliced ? localItems?.slice(0, fixedQuantity) : localItems)?.map((item, index, arr) => (
            <GenerateItem
              key={item.id}
              entityId={entityId}
              item={item}
              isLast={
                arr.length - 1 === index
              }
            />
          ))}
          {isHasMore ? (
            <div
              className="data-field__additional"
              onClick={() => setUseSliced(!useSliced)}>
              <span className="gen-title">{useSliced ? 'More' : 'Less'}</span>
              <div
                className="gen-icon"
                style={
                  !useSliced
                    ? { transform: 'rotate(180deg)', display: 'flex' }
                    : {}
                }>
                <DropdownIcon />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <span className="no-information">-</span>
      )}
    </div>
  );
};

export default InfoMultiField;
