import React from 'react';

import { AddressShortDTO } from '@ternala/voltore-types/lib/modules/address/addressShort.dto';

export const AddressField: React.FC<{
  label: string;
  address?: AddressShortDTO;
  className?: string;
}> = ({ label, address, className }) => {
  const hasData = address && address.address && address.address !== ' ';
  return (
    <div className={"data-field " + className}>
      <div className={`data-field__key_lg ${hasData ? '' : 'no-information'}`}>
        {label}
      </div>
      <div className="data-field__value">
        {hasData && address ? (
          <>
            {address.address || address.suite ? (
              <div className="address-filed">{address.address || ""} {address.suite ? ` ${address.suite} ` : ""}</div>
            ) : (
              ''
            )}

            {address.city || address.state || address.zipCode ? (
              <div className="address-filed">
                {address.city}, {address.state} {address.zipCode}
              </div>
            ) : (
              ''
            )}

            {address.county ? (
              <div className="address-filed">{address.county} County</div>
            ) : (
              ''
            )}
          </>
        ) : (
          <span className="no-information">-</span>
        )}
      </div>
    </div>
  );
};
