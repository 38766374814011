import React, { FC } from 'react';
import {
  InvolvementFullDTO,
  LeaseTransactionFullExpandDTO,
  SaleTransactionFullExpandDTO,
} from "@ternala/voltore-types";
import SaleTransactionItem from './SaleTransactionItem';
import LeaseTransactionItem from './LeaseTransactionItem';

import { IActionElement } from '../../../model';
import { PropertyRiverIcon } from '../../../../icons/PropertyRiverIcon';
import { getItemPrice } from '../../../../../utils';
import { CardEntityEnum } from '@ternala/voltore-types/lib/constants';
import { getInitials } from "../../../../../utils/helper-functions";
import InvolvementItem from "../InvolvementItem";

interface Props {
  action: IActionElement;
  index: number;
  isSelected: boolean;
  onClick: (action: string) => void;

  type?: CardEntityEnum |  "property" | "user" | "person";
  isExpanded?: boolean;
  isEnd?: boolean;
  isStart?: boolean;

  disabled?: boolean;
}

export const ActionsListItem: FC<Props & { ref: React.Ref<HTMLDivElement> }> =
  React.forwardRef(
    (
      {
        action,
        index,
        onClick,
        isSelected,
        disabled,
        type,
        isExpanded,
        isEnd,
        isStart,
      },
      ref,
    ) => {
      const generateContent = () => {
          switch (type) {
          case CardEntityEnum.Sale_Transaction: {
            const transaction = action.data as SaleTransactionFullExpandDTO;
            return (
              <SaleTransactionItem
                key={action.data?.id}
                data={{
                  buyer: transaction?.buyer?.title,
                  sqft:
                    action.data && 'property' in action.data
                      ? action.data.property?.sqft
                      : undefined,
                  price: transaction ? getItemPrice(transaction) : undefined,
                  statuses: transaction?.historyStatuses,
                  activeStatus: transaction?.activeStatus,
                }}
              />
            );
          }
          case CardEntityEnum.Lease_Transaction: {
            const transaction = action.data as LeaseTransactionFullExpandDTO;
            return (
              <LeaseTransactionItem
                key={action.data?.id}
                transaction={transaction}
                data={{
                  tenant: transaction?.tenant?.title,
                  sqft: transaction?.leasableSQFT,
                  price: transaction?.actualRate || transaction?.offeredRate,
                  statuses: transaction?.historyStatuses,
                  activeStatus: transaction?.activeStatus,
                  commencement: transaction?.leaseCommencement,
                  expiration: transaction?.leaseExpiration,
                }}
              />
            );
          }
          case CardEntityEnum.Person: {
            return (
              <>
                <div className="actions-list__item-image">
                  {action.image ? (
                    <img alt="" src={action.image} />
                  ) : (
                    <div className="data-item__image name-initials">
                      {getInitials(action.title)}
                    </div>
                  )}
                </div>
                <div className={`actions-list__item-information`}>
                  {action.category && (
                    <div className="category">{action.category}</div>
                  )}
                  <div className="title">{action.title}</div>
                  <div className="subtitle">{action.subTitle}</div>
                </div>
              </>
            );
          }
          case 'user': {
            // const user = action.data as unknown as UserFullDTO;
            return (
              <>
                <div className="actions-list__item-image">
                  {action.image ? (
                    <img alt="" src={action.image} />
                  ) : (
                    <div className="data-item__image name-initials">
                      {getInitials(action.title)}
                    </div>
                  )}
                </div>
                <div className={`actions-list__item-information`}>
                  {action.category && (
                    <div className="category">{action.category}</div>
                  )}
                  <div className="title">{action.title}</div>
                  <div className="subtitle">{action.subTitle}</div>
                </div>
              </>
            );
          }
          case CardEntityEnum.Involvement: {
            const involvement = action.data as InvolvementFullDTO;
            return (
              <InvolvementItem
                key={involvement.id}
                involvement={involvement}
              />
            );
          }
          case CardEntityEnum.Role: {
            return (
              <>
                <div className={`actions-list__item-information`}>
                  <div className="title">{action.subTitle}</div>
                </div>
              </>
            );
          }
          case CardEntityEnum.Property: {
            return (
              <>
                <div className="actions-list__item-image">
                  {action.image ? (
                    <img alt="" src={action.image} />
                  ) : (
                    <div className={'actions-list__item-image icon'}>
                      <PropertyRiverIcon />
                    </div>
                  )}
                </div>
                <div className={`actions-list__item-information`}>
                  {action.category && (
                    <div className="category">{action.category}</div>
                  )}
                  <div className="title">{action.title}</div>
                  <div className="subtitle">{action.subTitle}</div>
                  {disabled ? (
                    <div className="actions-list__item--already-added-mark">
                      Already added
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </>
            );
          }
          default: {
            return (
              <>
                {action.image ? (
                  <div className="actions-list__item-image">
                    {action.image ? (
                      <img alt="" src={action.image} />
                    ) : (
                      <div className={'actions-list__item-image icon'}>
                        <PropertyRiverIcon />
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}
                <div className={`actions-list__item-information`}>
                  {action.category && (
                    <div className="category">{action.category}</div>
                  )}
                  <div className="title">{action.title}</div>
                  <div className="subtitle">{action.subTitle}</div>
                  {disabled ? (
                    <div className="actions-list__item--already-added-mark">
                      Already added
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </>
            );
          }
        }
      };
      return (
        <div
          data-id={action.key}
          data-entity-type={action.entityType}
          className={
            'actions-list__item-wrapper' +
            (isExpanded ? ' actions-list__item-between' : '') +
            (isEnd ? ' actions-list__item-end' : '') +
            (isStart ? ' actions-list__item-start' + (disabled ? ' actions-list__item-disabled' : "") : '')
          }>
          <div
            className={`actions-list__item ${
              isSelected ? ' actions-list__item_active' : ''
            } ${action.category ? 'category' : ''} ${
              !isStart && disabled ? 'disabled' : ''
            }`}
            key={action.key}
            data-id={action.key}
            tabIndex={index}
            data-index={index}
            onClick={() => onClick(action.key)}
            ref={ref}>
            {generateContent()}
          </div>
        </div>
      );
    },
  );

export default ActionsListItem;
