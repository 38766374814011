import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
}

export const PointerUnlinked: FC<Props> = (props) => (
   <svg width="6px" height="12px" className='mapaddress-linker-unlinked-img' viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path fillRule="evenodd" clipRule="evenodd" d="M5 8V9C5 10.1046 4.10457 11 3 11C1.89543 11 1 10.1046 1 9V8H0V9C0 10.6569 1.34315 12 3 12C4.65685 12 6 10.6569 6 9V8H5ZM5 4H6V3C6 1.34315 4.65685 0 3 0C1.34315 0 0 1.34315 0 3V4H1V3C1 1.89543 1.89543 1 3 1C4.10457 1 5 1.89543 5 3V4Z" fill="#1F2531"/>
   </svg>
)
