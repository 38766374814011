import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const NavigationEnterprisesIcon: FC<Props> = ({ color, ...props }) => (
   <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M0 17.9819V11.1895H1.50943V17.2272H18.4906V11.1895H20V17.9819C20 18.3987 19.6621 18.7366 19.2453 18.7366H0.754717C0.337898 18.7366 0 18.3987 0 17.9819Z"
         fill={color}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M0 4.27327C0 3.85645 0.337898 3.51855 0.754717 3.51855H19.2453C19.6621 3.51855 20 3.85645 20 4.27327V8.80157C20 9.14789 19.7643 9.44976 19.4283 9.53376L10.3717 11.7979C10.2539 11.8274 10.1307 11.828 10.0125 11.7996L0.578586 9.53545C0.239251 9.45401 0 9.15055 0 8.80157V4.27327ZM1.50943 5.02799V8.20656L10.185 10.2887L18.4906 8.21231V5.02799H1.50943Z"
         fill={color}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M6 1.25472C6 0.837898 6.3379 0.5 6.75472 0.5H13.5472C13.964 0.5 14.3019 0.837898 14.3019 1.25472V2.76415H12.7925V2.00943H7.50943V2.76415H6V1.25472Z"
         fill={color}
      />
   </svg>
)

NavigationEnterprisesIcon.defaultProps = {
   color: "white"
}