import React, {
  FC,
  FormEvent,
  MouseEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { TaxonomyTypeSlug } from '@ternala/voltore-types/lib/constants';
import {
  CreateFloorDTO,
  FloorDTO,
  TaxonomyCreateResponse,
  AddressShortDTO,
  TagFullDTO,
  PropertyFullExpandDTO
} from '@ternala/voltore-types';

/* components */
import Input from 'components/UI/controls/Input';
import InputNumber from 'components/UI/controls/InputNumber';
import InputFloors from 'components/UI/controls/InputFloors';
import SelectSearch from 'components/UI/controls/SelectSearchCustom';
import SelectMultiSearch from 'components/UI/controls/SelectMultiSearch';
import Attachments from 'components/UI/controls/Attachments';
import Button from 'components/UI/controls/Button';
import PropertyImages from 'components/modals/properties/PropertyImages';
import InputMulti from 'components/UI/controls/InputMulti';
import GoogleMapSearchAutoComplete from 'components/UI/controls/GoogleMapsSearch/AutoComplete';

/* controllers */
import { getTaxonomiesAction } from 'controllers/taxonomy/actions';
import {
  createPropertyAction,
  updatePropertyAction,
} from 'controllers/property/actions';
import { taxonomyModalKey } from '../TaxonomyModal';
import { tagModalKey } from '../PopupTag';

/* types */
import { IStore } from 'controllers/store';
import {
  IPropertyState,
  PropertyCreateRequestExpanded,
} from 'controllers/property/models';
import { ITaxonomyState } from 'controllers/taxonomy/models';
import { IError, OptionType } from 'models';

/* constants & helpers */
import { Unit, loadTagsOptions } from 'config/constants';

// actions
import { ITagState, TagTypeEnum } from 'controllers/tag/models.d';
import { addShowedElement } from 'controllers/showElement/actions';
import { ShowElementEnum } from 'controllers/showElement/models.d';

// utils
import {
  calculateMainImageIndex,
  uuid,
  getAccessTokenUtil,
  numberToString,
  callbackTypeEnum,
  requestCallback,
} from 'utils';

export const propertyModalKey = 'PropertyModal';

export interface IPropertyModalProps {
  closeModal: Function;
  id?: number;
  completeAction?: (property: PropertyFullExpandDTO) => void;

  // redux
  propertyData: IPropertyState['propertyData'];
  taxonomyState: ITaxonomyState;
  tagState: ITagState;
}

const PropertyModalContent: FC<IPropertyModalProps> = (props) => {
  const dispatch = useDispatch();

  const {
    closeModal,
    id,
    propertyData,
    taxonomyState: { taxonomies },
    tagState: { entities },
  } = props;

  const editableProperty =
    typeof id === 'number' ? propertyData[id] : undefined;
  const [validationError, setValidationError] = useState<boolean>(false);


  const [isValidate, setIsValidate] = useState<boolean>(false);

  // address
  const [addressData, setAddressData] = useState<AddressShortDTO | undefined>(
    editableProperty?.address,
  );
  const [taxAddressData, setTaxAddressData] = useState<
    AddressShortDTO | undefined
  >(editableProperty?.taxBillingAddress);
  const [parcelValue, setParcelValue] = useState<string>('');
  const [parcels, setParcels] = useState<string[]>(
    editableProperty?.parcels || [],
  );
  const [taxes, setTaxes] = useState<string>(
    numberToString(editableProperty?.taxes),
  );
  const [taxesDate, setTaxesDate] = useState<Date | number | null>(
    Number(editableProperty?.taxesYear) || null,
  );

  const [requestProcess, setRequestProcess] = useState<boolean>(false);
  const [propertySqft, setPropertySqft] = useState<string>(
    numberToString(editableProperty?.sqft),
  );

  const [lotSqft, setLotSqft] = useState<string>(
    numberToString(editableProperty?.lotSqft),
  );
  const [propertyType, setPropertyType] = useState<OptionType[] | undefined>(
    editableProperty?.propertyType?.map((item) => ({
      label: item.title,
      value: item.id,
    })) || [],
  );
  const [subPropertyType, setSubPropertyType] = useState<
    OptionType[] | undefined
  >(
    editableProperty?.subPropertyType?.map((item) => ({
      label: item.title,
      value: item.id,
    })) || [],
  );
  const [secondaryType, setSecondaryType] = useState<OptionType[] | undefined>(
    editableProperty?.secondaryPropertyType?.map((item) => ({
      label: item.title,
      value: item.id,
    })) || [],
  );
  const [buildingClass, setBuildingClass] = useState<OptionType[] | undefined>(
    editableProperty?.buildingClass?.map((item) => ({
      label: item.title,
      value: item.id,
    })) || [],
  );

  const [tags, setTags] = useState<OptionType[] | undefined>(
    entities[TagTypeEnum.property][Number(id)]
      ?.filter((item) => item !== null)
      .map((item) => ({
        label: item.title,
        value: item.id,
      })) || [],
  );
  const [zoning, setZoning] = useState<OptionType | undefined>({
    label: editableProperty?.zoning?.title,
    value: editableProperty?.zoning?.id,
  });
  const [propertyGroup, setPropertyGroup] = useState<OptionType | undefined>({
    label: editableProperty?.propertyGroup?.title,
    value: editableProperty?.propertyGroup?.id,
  });

  const [yearBuilt, setYearBuilt] = useState<string>(
    numberToString(editableProperty?.yearBuild),
  );
  const [parking, setParking] = useState<string>(
    editableProperty?.parking || '',
  );
  const [floors, setFloors] = useState<FloorDTO[]>(
    editableProperty?.floors || [],
  );
  const [oldSupplements, setOldSupplements] = useState<number[]>(
    editableProperty?.supplements?.map((supplement) => supplement.id) || [],
  );
  const [newSupplements, setNewSupplements] = useState<File[]>([]);
  const [oldImages, setOldImages] = useState<number[]>(
    editableProperty?.images.map((image) => image.id) || [],
  );
  const [newImages, setNewImages] = useState<File[]>([]);
  const [galleryMainImageIndex, setGalleryMainImageIndex] = useState<number>();
  const scrollAreaRef = useRef() as RefObject<HTMLDivElement>;
  useEffect(() => {
    dispatch(getTaxonomiesAction.request({}));
  }, []);
  const isDisabled = !propertySqft ||
    (Boolean(yearBuilt) && String(yearBuilt).length !== 4) ||
    requestProcess ||
    !addressData;
  const submitData = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if(isDisabled){
      !isValidate && setIsValidate(true)
      return;
    }
    setRequestProcess(true);
    if (!addressData) {
      setValidationError(true);
      scrollAreaRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }

    if (editableProperty) {
      const payload: ReturnType<
        typeof updatePropertyAction.request
      >['payload'] = {
        ...createPayload(),
        id: editableProperty.id,
        oldSupplements,
        oldImages,
      };
      if (floors.length) {
        payload.floors = floors.map((floor, index) => {
          const mappedFloor: CreateFloorDTO & { id?: number } = {
            position: index,
            title: floor.title,
          };
          if (floor.sqft) mappedFloor.sqft = floor.sqft;
          if (floor.sqft) mappedFloor.id = floor.id;
          return JSON.stringify(mappedFloor);
        });
      }
      dispatch(
        updatePropertyAction.request({
          ...payload,
          callback: (status: boolean) => {
            requestCallback(dispatch, status, callbackTypeEnum.update);
            closeModal();
            if (payload.callback) payload.callback(status);
          },
        }),
      );
    } else {
      dispatch(
        createPropertyAction.request({
          ...createPayload(),
          callback: (status: boolean) => {
            requestCallback(dispatch, status, callbackTypeEnum.create);
            closeModal();
          },
        }),
      );
    }
  };

  const createPayload = (): PropertyCreateRequestExpanded => {
    const payload: PropertyCreateRequestExpanded = {
      address: addressData as any,
      taxBillingAddress: taxAddressData,
      sqft: Number(propertySqft),
      indexMainImage: calculateMainImageIndex({
        oldImages: editableProperty?.images,
        imageIndex: galleryMainImageIndex,
      }),
    };

    if (taxesDate)
      payload.taxesYear =
        typeof taxesDate === 'number' ? taxesDate : taxesDate.getFullYear();
    if (parcels.length) payload.parcels = parcels;
    if (taxes) payload.taxes = Number(taxes);

    if (propertySqft) payload.sqft = Number(propertySqft);
    if (lotSqft) payload.lotSqft = Number(lotSqft);
    if (propertyType?.length)
      payload[TaxonomyTypeSlug.propertyType] = propertyType.map((item) =>
        Number(item.value),
      );
    if (subPropertyType?.length)
      payload[TaxonomyTypeSlug.subPropertyType] = subPropertyType.map((item) =>
        Number(item.value),
      );
    if (secondaryType?.length)
      payload[TaxonomyTypeSlug.secondaryPropertyType] = secondaryType.map(
        (item) => Number(item.value),
      );
    if (buildingClass?.length)
      payload[TaxonomyTypeSlug.buildingClass] = buildingClass.map((item) =>
        Number(item.value),
      );

    if (zoning?.value) payload[TaxonomyTypeSlug.zoning] = Number(zoning.value);
    if (propertyGroup?.value)
      payload[TaxonomyTypeSlug.propertyGroup] = Number(propertyGroup.value);
    if (yearBuilt) payload.yearBuild = Number(yearBuilt);
    if (parking) payload.parking = parking.trim();
    if (newSupplements.length) payload.supplements = newSupplements;
    if (tags)
      payload.tags = tags
        ?.filter((item) => item.label !== undefined && item.value !== undefined)
        .map((item) => item.value as number);
    if (newImages.length) payload.images = newImages;
    if (floors.length) {
      payload.floors = floors.map((floor, index) => {
        const mappedFloor: CreateFloorDTO = {
          title: floor.title,
          position: index,
        };
        if (floor.sqft) {
          mappedFloor.sqft = floor.sqft;
        }
        return JSON.stringify(mappedFloor);
      });
    }
    return payload;
  };

  const openTaxonomyModal = (type: TaxonomyTypeSlug) => {
    dispatch(
      addShowedElement({
        id: uuid(),
        type: ShowElementEnum.modal,
        key: taxonomyModalKey,
        props: {
          type,
        },
        callback: (res: false | TaxonomyCreateResponse) => {
          if (!res) return;
          const option: OptionType = {
            label: res.title,
            value: res.id,
          };
          switch (res.type.slug) {
            case TaxonomyTypeSlug.propertyType:
              setPropertyType([...(propertyType || []), option]);
              break;
            case TaxonomyTypeSlug.buildingClass:
              setBuildingClass([...(buildingClass || []), option]);
              break;
            case TaxonomyTypeSlug.secondaryPropertyType:
              setSecondaryType([...(secondaryType || []), option]);
              break;
            case TaxonomyTypeSlug.subPropertyType:
              setSubPropertyType([...(subPropertyType || []), option]);
              break;
            case TaxonomyTypeSlug.propertyGroup:
              setPropertyGroup(option);
              break;
            case TaxonomyTypeSlug.zoning:
              setZoning(option);
              break;
            default:
              break;
          }
        },
      }),
    );
  };

  return (
    <form className="modal-content" onSubmit={submitData}>
      <div
        className="modal__close"
        onClick={() => closeModal()}
        title="Close"
      />

      <div className="modal-title">{id ? 'edit' : 'create'} property</div>

      <div className="flex-container">
        <div>
          <InputMulti
            parcel
            type="text"
            label="parcel #"
            placeholder="Type here"
            data={parcels}
            error={isValidate && parcelValue ? "You have unsaved changes here" : undefined}
            value={parcelValue}
            onChangeValue={(value) => {
              setParcelValue(value)
              isValidate && setIsValidate(false);
            }}
            onUpdate={(parcels) => setParcels(parcels)}
          />

          {/*<div className="taxes-field">*/}
          {/*  <div className="field">*/}
          {/*    <InputNumber*/}
          {/*      unit="$"*/}
          {/*      label="PROPERTY TAXES"*/}
          {/*      placeholder="Enter numbers only"*/}
          {/*      value={taxes}*/}
          {/*      onChange={(taxes) => setTaxes(taxes)}*/}
          {/*      max={10000000000}*/}
          {/*      upperCaseLabel={false}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="field">*/}
          {/*    <DatePicker*/}
          {/*      placeholder={'YYYY'}*/}
          {/*      value={taxesDate}*/}
          {/*      onChange={(date: MaterialUiPickersDate) =>*/}
          {/*        setTaxesDate(date ? date.getFullYear() : null)*/}
          {/*      }*/}
          {/*      yearOnly*/}
          {/*      disableFuture={false}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
          <GoogleMapSearchAutoComplete
            address={taxAddressData}
            setAddress={setTaxAddressData}
            withMap={false}
            label="tax billing address"
            isRequired={false}
            isNeedCreation={true}
          />
        </div>
        <div className="address-wrapper">
          <GoogleMapSearchAutoComplete
            address={addressData}
            setAddress={(address) => {
              setAddressData(address);
            }}
            error={validationError}
            withMap={true}
            label="site address"
            isRequired={true}
            isNeedCreation={false}
          />
        </div>
      </div>

      <div className="flex-container">
        <InputNumber
          unit="sqft"
          label="Building SQFT"
          placeholder="Enter numbers only"
          defaultValue={propertySqft}
          onChange={(sqft) => setPropertySqft(sqft)}
          max={1000000000000}
          decimalScale={0}
          required
          upperCaseLabel={false}
        />

        <InputNumber
          upperCaseLabel={false}
          units={[
            { type: Unit.SQFT, label: 'LOT SQFT' },
            { type: Unit.ACRES, label: 'LOT ACRES' },
          ]}
          placeholder="Enter numbers only"
          defaultValue={lotSqft}
          onChange={(sqft) => {
            setLotSqft(sqft)
          }}
          max={1000000000000}
        />
      </div>

      <div className="flex-container">
        <div>
          <SelectMultiSearch
            label="property type"
            selectedOptions={propertyType}
            options={taxonomies?.[TaxonomyTypeSlug.propertyType]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id,
              }),
            )}
            onChange={(options) => setPropertyType(options)}
            creation={{
              label: 'Create new property type',
              onClick: () => openTaxonomyModal(TaxonomyTypeSlug.propertyType),
            }}
          />

          <SelectMultiSearch
            label="sub-property type"
            selectedOptions={subPropertyType}
            options={taxonomies?.[TaxonomyTypeSlug.subPropertyType]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id,
              }),
            )}
            onChange={(options) => setSubPropertyType(options)}
            creation={{
              label: 'Create new sub-property type',
              onClick: () =>
                openTaxonomyModal(TaxonomyTypeSlug.subPropertyType),
            }}
          />

          <SelectMultiSearch
            label="secondary type"
            selectedOptions={secondaryType}
            options={taxonomies?.[TaxonomyTypeSlug.secondaryPropertyType]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id,
              }),
            )}
            onChange={(options) => setSecondaryType(options)}
            creation={{
              label: 'Create new secondary type',
              onClick: () =>
                openTaxonomyModal(TaxonomyTypeSlug.secondaryPropertyType),
            }}
          />
        </div>
        <div>
          <SelectMultiSearch
            label="building class"
            selectedOptions={buildingClass}
            options={taxonomies?.[TaxonomyTypeSlug.buildingClass]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id,
              }),
            )}
            onChange={(options) => setBuildingClass(options)}
            creation={{
              label: 'Create new building class',
              onClick: () => openTaxonomyModal(TaxonomyTypeSlug.buildingClass),
            }}
          />

          <SelectSearch
            label="zoning"
            options={taxonomies?.[TaxonomyTypeSlug.zoning]?.map((taxonomy) => ({
              label: taxonomy.title,
              value: taxonomy.id,
            }))}
            value={zoning}
            onChange={(option) => setZoning(option)}
            creation={{
              label: 'Create new zoning',
              onClick: () => openTaxonomyModal(TaxonomyTypeSlug.zoning),
            }}
          />

          <InputNumber
            label="year built"
            placeholder="4 digits, numbers only"
            defaultValue={yearBuilt}
            onChange={(year) => setYearBuilt(year)}
            max={10000}
            isYearValue
            upperCaseLabel={false}
          />
        </div>
      </div>

      <div className="flex-container">
        <div>
          <Input
            label="parking"
            placeholder="Type here"
            value={parking}
            onChange={(e) => setParking(e.target.value)}
          />
        </div>

        <div>
          <InputFloors
            label="# of Floors"
            placeholder="Type here"
            data={floors}
            onUpdate={(floors) => setFloors(floors)}
          />
        </div>
      </div>
      <div className="tag-field">
        <SelectMultiSearch
          label="tag(s)"
          asyncOptions={async (searchParams) => {
            const token = await getAccessTokenUtil(dispatch).catch(
              (e: IError) => {
                console.error(e);
                throw new Error(e.message);
              },
            );
            return loadTagsOptions(searchParams, token);
          }}
          selectedOptions={tags?.filter(
            (item) => item.label !== undefined && item.value !== undefined,
          )}
          onChange={(options) => setTags(options)}
          creation={{
            label: 'Create new tag',
            onClick: () => {
              dispatch(
                addShowedElement({
                  id: uuid(),
                  key: tagModalKey,
                  type: ShowElementEnum.modal,
                  callback: (res: TagFullDTO | boolean) => {
                    if (typeof res !== 'boolean')
                      setTags([
                        ...(tags || []),
                        {
                          label: `${res.title} - ${res.category.title}`,
                          value: res.id,
                        },
                      ]);
                  },
                  props: {},
                }),
              );
            },
          }}
        />
      </div>

      <div className="modal-section-heading">Attachments</div>

      <div className="flex-container">
        <Attachments
          label="supplements"
          buttonText="click to upload .pdf .doc and images"
          fileTypes={['pdf', 'doc', 'docx', 'images']}
          attachments={editableProperty?.supplements}
          onChange={(oldSupplements, newSupplements) => {
            setOldSupplements(oldSupplements);
            setNewSupplements(newSupplements);
          }}
        />

        <SelectSearch
          label="property group"
          options={taxonomies?.[TaxonomyTypeSlug.propertyGroup]?.map(
            (taxonomy) => ({
              label: taxonomy.title,
              value: taxonomy.id,
            }),
          )}
          value={propertyGroup}
          onChange={(option) => setPropertyGroup(option)}
          creation={{
            label: 'Create new property group',
            onClick: () => openTaxonomyModal(TaxonomyTypeSlug.propertyGroup),
          }}
        />
      </div>

      <PropertyImages
        label="property images"
        images={editableProperty?.images}
        onChange={(oldImages, newImages) => {
          setOldImages(oldImages);
          setNewImages(newImages);
        }}
        onMainImageChange={(index) => setGalleryMainImageIndex(index)}
      />

      <footer className="modal-footer">
        <Button variant="light" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          variant="dark"
          onClick={submitData}
          className={isDisabled ? "disabled" : ""}>
          Save
        </Button>
      </footer>
    </form>
  );
};

export default connect((store: IStore) => ({
  tagState: store.tag,
  propertyData: store.property.propertyData,
  taxonomyState: store.taxonomy,
}))(PropertyModalContent);
