import React, { FC, useState, useEffect, MouseEvent } from 'react';

/* components */
import Modal from 'components/Hocs/Portal';
import Input from 'components/UI/controls/Input';
import Button from 'components/UI/controls/Button';

/* constants */
import { TaxonomyOrEntityType } from 'config/constants';

interface Props {
  title?: string;
  type?: TaxonomyOrEntityType;
  existing?: Array<any>;
  label?: string;
  placeholder?: string;
  onClose: () => void;
  onSubmit: (value: string, callback: Function) => void;
}

const PopupCreateTaxonomy: FC<Props> = ({
  onClose,
  type,
  existing,
  ...props
}) => {
  const [newTaxonomyValue, setNewTaxonomyValue] = useState<string>('');
  const [validationError, setValidationError] = useState<string>('');
  const [requestProcess, setRequestProcess] = useState<boolean>(false);
  if ((type && !existing) || (!type && existing)) {
    throw new Error(
      'Both "type" and "existing" props must be specified or neither of them',
    );
  }

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  const submitData = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (type && existing) {
      validateInput();
    } else {
      props.onSubmit(newTaxonomyValue.trim(), (status: boolean) => {
        if (status) onClose();
        setRequestProcess(false);
      });
    }
  };

  const validateInput = () => {
    switch (type) {
      case TaxonomyOrEntityType.SUITE:
        if (
          existing?.some(
            (existingValue) => existingValue.title === newTaxonomyValue.trim(),
          )
        ) {
          setValidationError(
            `Such ${TaxonomyOrEntityType.SUITE} already exists`,
          );
        } else {
          props.onSubmit(newTaxonomyValue.trim(), onClose);
        }
        break;
    }
  };

  return (
    <Modal>
      <div className="modal popup popup-create-taxonomy">
        <div className="scroll-area">
          <div className="modal-content modal-password">
            <div className="modal__close" onClick={onClose} title="Close" />

            <div className="modal-title">{props.title}</div>

            <Input
              label={props.label}
              placeholder={props.placeholder}
              value={newTaxonomyValue}
              onChange={(e) => setNewTaxonomyValue(e.target.value)}
              error={validationError}
              required
              autoFocus
            />

            <footer className="modal-footer">
              <Button variant="light" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="dark"
                onClick={submitData}
                disabled={!newTaxonomyValue || requestProcess}>
                Save
              </Button>
            </footer>
          </div>
        </div>

        <div className="modal-overlay" onClick={onClose} />
      </div>
    </Modal>
  );
};

PopupCreateTaxonomy.defaultProps = {
  label: 'title',
  placeholder: 'Type here',
};

export default PopupCreateTaxonomy;
