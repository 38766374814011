import * as React from 'react';
import { connect, ConnectedComponent } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { UserTypeEnum } from '@ternala/voltore-types/lib/constants';

import schema from './index';

// Selectors
import { getAuthStatus, getUserType } from 'controllers/auth';

// Types
import { IStore } from 'controllers/store';

interface ProtectedRouteProps extends RouteComponentProps {
  isAuthenticated?: boolean;
  role?: string;
  type?: UserTypeEnum;
}

type RouteAccesses =
  | 'AUTHENTICATED_USERS'
  | 'UNAUTHENTICATED_USERS'
  | 'ANONYMOUS_USERS'
  | UserTypeEnum;

export const RedirectToHome = () => {
  return <Redirect to={schema.getLink('properties')} />;
};

const connectFunc = connect((state: IStore) => ({
  isAuthenticated: getAuthStatus(state),
  type: getUserType(state),
}));

export const ProtectedRoute = (
  ProtectedRoute:
    | React.ComponentType<RouteComponentProps>
    | React.FC
    | ConnectedComponent<any, any>,
  routeAccess: RouteAccesses | RouteAccesses[],
) =>
  connectFunc(
    class Component extends React.PureComponent<ProtectedRouteProps> {
      state = {
        component: null,
      };

      componentDidMount() {
        let component = <ProtectedRoute {...this.props} />;
        if (
          !this.props.isAuthenticated &&
          routeAccess === 'AUTHENTICATED_USERS'
        ) {
          component = <Redirect to="/login" />;
        }

        if (
          this.props.isAuthenticated &&
          routeAccess === 'UNAUTHENTICATED_USERS'
        ) {
          component = <Redirect to="/" />;
        }

        if (
          this.props.isAuthenticated &&
          routeAccess === UserTypeEnum.SA &&
          this.props.type !== UserTypeEnum.SA
        ) {
          component = <Redirect to="/" />;
        }
        // if (
        //   this.props.type &&
        //   routeAccess === "UNAUTHENTICATED_USERS"
        // ) {
        //   component = <Redirect to="/" />;
        // }

        this.setState({ component });
      }

      componentDidUpdate(
        prevProps: Readonly<ProtectedRouteProps>,
        prevState: Readonly<{}>,
        snapshot?: any,
      ) {
        if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
          let component = <ProtectedRoute {...this.props} />;
          if (
            routeAccess === 'AUTHENTICATED_USERS' &&
            this.props.isAuthenticated === false
          ) {
            component = <Redirect to="/login" />;
          }
          if (
            routeAccess === 'UNAUTHENTICATED_USERS' &&
            this.props.isAuthenticated === true
          ) {
            component = <Redirect to="/" />;
          }
          this.setState({ component });
        }
      }

      public render() {
        return this.state.component;
      }
    },
  );

export default ProtectedRoute;
