import React, { FC } from 'react';
import Moment from 'react-moment';
import { InnerLink } from 'routing/InnerLink';

/* constants & utils */
import { DATE_FORMAT } from 'config';
import { isAfterCurrentDate } from 'utils/helper-functions';
import { EnterpriseShortDTO, InvolvementFullDTO } from '@ternala/voltore-types';
import { DotIcon } from 'components/icons/DotIcon';

interface Props {
  id: number | string;
  tabName?: string;
  involvementId?: number | string;
  data: {
    position?: string;
    startDate?: Date;
    endDate?: Date;
    enterprise?: EnterpriseShortDTO | undefined;
  };
  involvement?: InvolvementFullDTO;
}

const InvolvementHistoryItem: FC<Props> = ({
  id,
  data: { position, startDate, endDate, enterprise },
  involvement,
  ...props
}) => (
  <InnerLink
    className={
      'involvement-history__item' +
      (!endDate || isAfterCurrentDate(endDate)
        ? ' involvement-history__item_current'
        : '')
    }
    activeClass='involvement-history__item_active'
    to='person-involvement'
    id={id}
    tabName={props.tabName}
    involvementId={props.involvementId}
  >
    <div className='item-summary'>
      {involvement?.decisionMaker && !involvement?.owner && (
        <div className='item-summary__post'>Decision Maker</div>
      )}

      {involvement?.owner && !involvement?.decisionMaker && (
        <div className='item-summary__post'>Principal</div>
      )}

      {involvement?.owner && involvement?.decisionMaker && (
        <div className='item-summary__post'>Principal | Decision Maker</div>
      )}
      <div className='item-summary__position'>
        {position}
        <DotIcon />
        {enterprise && <span>{enterprise.title}</span>}
      </div>
      <div className='item-summary__date'>
        {startDate ? (
          <>
            <Moment format={DATE_FORMAT}>{startDate}</Moment>
            &nbsp;-&nbsp;
          </>
        ) : (
          'N\\A - '
        )}

        {endDate ? <Moment format={DATE_FORMAT}>{endDate}</Moment> : 'Present'}
      </div>
    </div>
  </InnerLink>
);

export default InvolvementHistoryItem;
