import { ImageDTO } from "@ternala/voltore-types";
import { SizeTypeEnum } from "@ternala/voltore-types/lib/constants/images";

export const getMainImage = (images: ImageDTO[] | undefined): ImageDTO | undefined => {
  return images?.sort((i1, i2) => {
    if(i1.isMain) return -1;
    if(i2.isMain) return 1;
    return 0;
  })?.[0]
}

export const getListImage = (images: ImageDTO[] | undefined): string | undefined => {
  return getMainImage(images)?.thumbnails.find(thumbnail => thumbnail.imageType === SizeTypeEnum.small)?.url;
}

export const getListImageOne = (image: ImageDTO | undefined): string | undefined => {
    return image?.thumbnails.find(thumbnail => thumbnail.imageType === SizeTypeEnum.small)?.url;
}

export const getMiddleImage = (images: ImageDTO[] | undefined): string | undefined => {
  return getMainImage(images)?.thumbnails.find(thumbnail => thumbnail.imageType === SizeTypeEnum.medium)?.url;
}

export const getMiddleImageOne = (image: ImageDTO | undefined): string | undefined => {
  return image?.thumbnails.find(thumbnail => thumbnail.imageType === SizeTypeEnum.medium)?.url;
}

export const getFullImageOne = (image: ImageDTO | undefined): string | undefined => {
  return image?.thumbnails.find(thumbnail => thumbnail.imageType === SizeTypeEnum.original)?.url;
}
