//Node modules
import React from "react";

//Custom interfaces
import { IError, IField, IFieldValues } from "models/form";
import { ExceptionType } from "models/exception";

//Custom components
import { Formik, FormikErrors } from "formik";
import { Field } from "./Field";
import Button from 'components/UI/controls/Button'

interface IProps {
  initialValues?: IFieldValues;
  listOfField: IField[];
  errors?: IError[];
  error?: ExceptionType | null;
  submitBtn?: string;
  loading?: boolean;
  onSubmit: (values: IFieldValues) => void;
}

export const Form: React.FC<IProps> = ({
                                         initialValues,
                                         listOfField,
                                         submitBtn,
                                         onSubmit,
                                         loading,
                                         error,
                                       }) => {
  let defaultValueByForm:IFieldValues = {};
  if(initialValues){
    defaultValueByForm = initialValues
  } else {
    for(let field of listOfField){
      defaultValueByForm[field.name] = '';
    }
  }

  return (
    <Formik
      initialValues={defaultValueByForm}
      validate={(values: IFieldValues) => {
        const errors: IFieldValues = {};
        listOfField.forEach((field: IField) => {
          if (field.readonly) return;
          if (field.type === "email") {
            if (
              !values[field.name] ||
              typeof values[field.name] === "undefined"
            ) {
              errors[field.name] = "Required";
            } else if (
              typeof values[field.name] === "string" &&
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                String(values[field.name])
              )
            ) {
              errors[field.name] = "Invalid email address";
            }
          }
          if (
            field.type === "phone" ||
            typeof values[field.name] === "undefined"
          ) {
            if (!values[field.name]) {
              errors[field.name] = "Required";
            } else if (
              typeof values[field.name] === "string" &&
              !/^([+]46)\s*(\d{3})\s*(\d{3})\s*(\d{3})$/.test(
                String(values[field.name])
              )
            ) {
              errors[field.name] = "Invalid phone number";
            }
          }
        });
        return errors;
      }}
      onSubmit={(values ) => {
        onSubmit(values);
      }}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
        let serverErrors: FormikErrors<any> = {};
        if (
          error &&
          typeof error !== "string" &&
          "code" in error &&
          error.code !== null
        ) {
          serverErrors["login"] = " ";
          serverErrors["password"] = error.message;
        }

        return (
          <form className="form" onSubmit={handleSubmit}>
            {listOfField.map((field: IField) => {
              return !(field.name === 'phone' && !values[field.name]) ? (
                <label className={"field" + (errors[field.name] || serverErrors[field.name] ? " error" : "")}  key={field.name}>
                  {/* <span className={"label-text"}>
                    {field.title}{" "}
                    {field.isRequired ? <i className={"star red"}>*</i> : ""}
                  </span> */}

                  <Field
                    field={field}
                    value={values[field.name]}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setValue={setFieldValue}
                    key={field.name}
                    error={error}
                  />

                  {error && field.name === 'password' &&
                    <span className="error-message">
                      { (error as any).message }
                    </span>
                  }

                  {/* <span className="error-message">
                    {errors[field.name] &&
                    touched[field.name] &&
                    (errors[field.name] || serverErrors[field.name])}
                  </span> */}
                </label>
              ) : null
            })}
            {typeof error === "string" && (
              <div className="error-message-response">
                {error || "Something was wrong"}
              </div>
            )}
            {submitBtn && (
              <Button type="submit" variant="dark" className="login-button" disabled={loading}>
                {submitBtn ? submitBtn : "Submit"}
              </Button>
            )}
          </form>
        );
      }}
    </Formik>
  );
};
