import React, { FC } from "react";

import ListView from "./Views/List";
import StackView from "./Views/Stack";
import MapView from "./Views/Map";

/* constants & types */
import { LeaseTransactionViewTypeEnum } from "config/constants";
import { PropertyFullExpandDTO}  from "controllers/property/models";
import { LeaseTransactionFullDTO } from "@ternala/voltore-types";
import { IFloor } from "./index";
import { OptionType } from "models";

export interface ItemsViewProps {
  viewType?: LeaseTransactionViewTypeEnum,
  currentProperty: PropertyFullExpandDTO,
  loadTransactions: (data: { callback?: Function, limit?:number, offset?: number }) => void,
  propertyId: number,
  tabName: string,
  leaseTransactionsSplitted: IFloor[],
  leaseTransactionsNA: IFloor,
  transactionsVisualizationFilter: OptionType,
  setSelectedLeaseTransactions: (list: LeaseTransactionFullDTO[] | null) => void,
  selectedLeaseTransactions: LeaseTransactionFullDTO[] | null
}

const LeaseTransactionItemsView: FC<ItemsViewProps> = ({ viewType, ...props }) => {
  switch (viewType) {
    case LeaseTransactionViewTypeEnum.MAP:
      return <MapView {...props} />
    case LeaseTransactionViewTypeEnum.STACK:
      return <StackView {...props} />
    case LeaseTransactionViewTypeEnum.LIST:
    default:
      return <ListView {...props} />
  }
}

export default LeaseTransactionItemsView;