import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   width?: number
   height?: number
   color?: string
}

export const CloseIconWhite: FC<Props> = ({ width, height, color, ...props }) => (

    <svg width={8} height={8} {...props} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.65247 0.347782C7.84454 0.539857 7.84454 0.851272 7.65247 1.04335L1.04464 7.65121C0.852567 7.84329 0.541154 7.84329 0.34908 7.65121C0.157006 7.45914 0.157007 7.14772 0.34908 6.95565L6.95691 0.347782C7.14898 0.155707 7.46039 0.155707 7.65247 0.347782Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.65222 7.65247C7.46015 7.84454 7.14873 7.84454 6.95666 7.65247L0.348833 1.0446C0.156759 0.85253 0.156759 0.541115 0.348832 0.34904C0.540906 0.156965 0.85232 0.156965 1.04439 0.34904L7.65222 6.95691C7.84429 7.14898 7.84429 7.46039 7.65222 7.65247Z" fill="white"/>
    </svg>

)

