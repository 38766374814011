import React, { FC } from 'react';
import { InnerLink } from 'routing/InnerLink';

/* constants & utils */
import { SaleTransactionStatusEnum } from '@ternala/voltore-types/lib/constants';
import {
  withThousandsSeparators,
  formattedDate,
  transactionItemDateDiff,
} from 'utils/helper-functions';

/* types */
import { CreateSaleStatusDTO } from '@ternala/voltore-types';
import { DotIcon } from 'components/icons/DotIcon';
import moment from 'moment';

interface Props {
  id: number;
  tabName?: string;
  transactionId: number;
  data: {
    buyer?: string;
    sqft?: number;
    price?: number;
    statuses?: CreateSaleStatusDTO[];
    activeStatus?: CreateSaleStatusDTO;
  };
}

const SaleTransactionItem: FC<Props> = (props) => {
  const {
    id,
    tabName,
    transactionId,
    data: { buyer, sqft, price, statuses, activeStatus },
  } = props;

  const prevStatus = statuses?.find(
    (item) => item.status === SaleTransactionStatusEnum.Active,
  );

  const currStatus = {
    status:
      activeStatus?.status === SaleTransactionStatusEnum.Active
        ? ''
        : activeStatus?.status,
    startDate:
      activeStatus?.status === SaleTransactionStatusEnum.Active
        ? 'Now'
        : activeStatus?.startDate,
  };

  return (
    <InnerLink
      id={id}
      tabName={tabName}
      to="property-sale"
      transactionId={transactionId}
      activeClass="transaction-item_active"
      className="transaction-item-container">
      <div className="transaction-item">
        {activeStatus && (
          <div
            className={`transaction-item__current-status transaction-current-status transaction-current-status_${activeStatus?.status.toLowerCase()}`}>
            {activeStatus?.status}
          </div>
        )}

        {buyer && (
          <div className="transaction-item__enterprise-name" title={buyer}>
            {buyer}
          </div>
        )}

        <div
          className={`transaction-item__sqft-and-price_${activeStatus?.status.toLowerCase()}`}>
          {sqft && (
            <span className="lot-sqft">
              {withThousandsSeparators(sqft)} SQFT
            </span>
          )}
          <DotIcon />
          <span
            className="lot-price"
            title={price ? `$${withThousandsSeparators(price)}` : undefined}>
            {price ? `$${withThousandsSeparators(price)}` : 'n/a'}
          </span>
        </div>

        {statuses && (
          <div className="transaction-item__status-and-date">
            {prevStatus ? (
              <>
                {prevStatus.status}&nbsp;
                {moment(prevStatus.startDate).format('MM/DD/YYYY')}
                &nbsp;-&nbsp;
              </>
            ) : (
              ''
            )}

            {
              <>
                {currStatus.status}&nbsp;
                {currStatus.startDate === 'Now'
                  ? 'Now'
                  : moment(currStatus.startDate).format('MM/DD/YYYY')}
              </>
            }

            {prevStatus && (
              <>
                &nbsp;|&nbsp;
                {transactionItemDateDiff(
                  formattedDate(
                    prevStatus.startDate
                      ? new Date(prevStatus.startDate)
                      : new Date(),
                  ),
                  formattedDate(
                    currStatus.startDate && currStatus.startDate !== 'Now'
                      ? new Date(currStatus.startDate)
                      : new Date(),
                  ),
                )}
                &nbsp;days
              </>
            )}
          </div>
        )}
      </div>
    </InnerLink>
  );
};

export default SaleTransactionItem;
