import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const SearchCLoseIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.56361 0.434702C9.80368 0.674781 9.80368 1.06403 9.56361 1.30411L1.30432 9.56344C1.06425 9.80352 0.675004 9.80352 0.434927 9.56344C0.194849 9.32336 0.194849 8.93411 0.434926 8.69403L8.69421 0.434702C8.93428 0.194623 9.32353 0.194623 9.56361 0.434702Z"
      fill="#1F2531"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5653 9.56556C9.32522 9.80564 8.93598 9.80564 8.6959 9.56556L0.436622 1.30623C0.196544 1.06615 0.196545 0.676905 0.436622 0.436825C0.6767 0.196746 1.06594 0.196746 1.30602 0.436825L9.5653 8.69616C9.80538 8.93624 9.80538 9.32548 9.5653 9.56556Z"
      fill="#1F2531"
    />
  </svg>
);

SearchCLoseIcon.defaultProps = {
  color: '#1F2531',
};
