import React, { FC, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from 'react-router-dom'

/* components */
import Tabs from 'components/page/DatalistItemTabs'
import NotFoundPage from 'pages/Static/NotFound'

/* constants */
import { PageEnum, isPhone } from 'config/constants'
import { personsTabs } from 'config/constants/tabs'

/* types */
import { IStore } from 'controllers/store'
import { EntityEnum } from "@ternala/voltore-types/lib/constants";
import { getPersonAction } from "../../../controllers/person/actions";
import { selectEntity, unselectEntity } from "../../../controllers/showElement/actions";

interface Props extends RouteComponentProps<{ id?: string }> {
   pathname: string;
}

const PersonItemTabs: FC<Props> = props => {
   const dispatch = useDispatch();
   const {
      match: {
         params: { id }
      },
      pathname
   } = props
   
   const currentPersonId: number = Number(id)
   const involvementId: number = Number(pathname.split('/')[pathname.split('/').length - 1]);

   const backLink: string = (
      isPhone && !isNaN(involvementId) &&
      pathname.endsWith(`${personsTabs.enterpriseInvolvement.path}/${involvementId}`)
   )
      ? `/${PageEnum.PEOPLE}/${id}/${personsTabs.enterpriseInvolvement.path}`
      : `/${PageEnum.PEOPLE}`

   if (isNaN(currentPersonId)) {
      return <NotFoundPage />
   }

   useEffect(() => {
      dispatch(selectEntity({
         ids: [currentPersonId],
         type: EntityEnum.person,
      }))
      dispatch(
        getPersonAction.request({
           id: currentPersonId,
        }),
      );
      return () => {
         setTimeout(() => {
            dispatch(unselectEntity({
               ids: [currentPersonId],
               type: EntityEnum.person,
            }))
         }, 30)
      }
   }, [])

   return <>
      <Tabs
         page={PageEnum.PEOPLE}
         tabs={personsTabs}
         listItemId={id}
         backLink={backLink}
      />

      { props.children }
   </>
}

export default connect((store: IStore) => ({
   pathname: store.router.location.pathname,
}))(PersonItemTabs)