import React, { FC, ComponentProps } from 'react';

interface IProps extends ComponentProps<'svg'> {
  width?: number;
  height?: number;
  color?: string;
}

export const CloseDarkIcon: FC<IProps> = ({
  width,
  height,
  color,
  ...props
}) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4767 0.521633C11.7648 0.809722 11.7648 1.27681 11.4767 1.5649L1.56575 11.4759C1.27766 11.764 0.810578 11.764 0.52249 11.4759C0.234402 11.1878 0.234402 10.7207 0.52249 10.4327L10.4334 0.521633C10.7215 0.233543 11.1886 0.233543 11.4767 0.521633Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4784 11.4787C11.1903 11.7668 10.7232 11.7668 10.4351 11.4787L0.524152 1.56764C0.236065 1.27955 0.236065 0.812467 0.524153 0.524378C0.812241 0.236288 1.27932 0.236288 1.56741 0.524378L11.4784 10.4354C11.7665 10.7235 11.7665 11.1906 11.4784 11.4787Z"
      fill={color}
    />
  </svg>
);

CloseDarkIcon.defaultProps = {
  width: 12,
  height: 12,
  color: '#1F1F1F',
};
