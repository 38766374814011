import React, { FC, useContext, useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";

/* components */
import OptionsMenu from 'components/UI/controls/OptionsMenu';

/* utils */
import { getInitials } from 'utils/helper-functions';

/* types */
import { IStore } from 'controllers/store';
import {
  IPersonState,
  PersonUpdateRequestExpanded,
} from 'controllers/person/models.d';
import { PersonFullDTO, PersonUpdateResponse } from "@ternala/voltore-types";
import HistoryContext from 'context/History';
import Tags from 'components/Tags/Tags';
import { activityHistoryTypeEnum } from "@ternala/voltore-types/lib/constants";
import {
  updatePersonAction,
} from 'controllers/person/actions';
import PersonModal from 'components/modals/PersonModal';
import { CloseSmallIcon } from 'components/icons/CloseSmallIcon';
import Loader from 'components/Loader';
import { Helmet } from 'react-helmet';
import InfoMultiField from 'components/UI/controls/MultiField/InfoMultiField';
import { AddressField } from '../../../../components/views/address';
import { getMiddleImage, callbackTypeEnum, requestCallback } from 'utils';
import { ILoader } from "../../../../models";
import { TagEntityEnum } from "@ternala/voltore-types/lib/card";
import { capitalize } from "lodash";

interface Props {
  personId: number;
  onDelete: () => void;
  onClose?: () => void;
  personsState: IPersonState;
}

const PersonalContacts: FC<Props> = (props) => {
  const {
    onClose,
    personId,
    onDelete,
    personsState: { personData },
  } = props;

  /* Context (location and history) */
  const dispatch = useDispatch();
  const history = useContext(HistoryContext);
  const person: PersonFullDTO = personData[personId];

  const loader = useSelector<IStore, ILoader | undefined>((store) => store.person.state.loaders.find((loader) => loader.purposeId === personId))

  // State
  const [edit, setEdit] = useState<boolean>(false);

  return person ? (
    <>
      <div className="personal-contacts">
        <Helmet>
          <title>
            {person ? `${person?.firstName} ${person?.lastName}` : 'People'} |
            Voltore
            {process.env.REACT_APP_COMPANY_NAME
              ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        <header>
          <h2>Personal contacts</h2>​
          <div className="options">
            <OptionsMenu
              on={{
                edit: () => setEdit(true),
                delete: onDelete,
                ...(!onClose && {
                  history: () =>
                  {
                    history.setEntity({
                      id: person?.id,
                      type: activityHistoryTypeEnum.person,
                    })
                  },
                }),
              }}
            />
            {onClose ? (
              <div className="cross-icon" onClick={onClose}>
                <CloseSmallIcon />
              </div>
            ) : (
              ''
            )}
          </div>
        </header>
        ​
        <main className="personal-contacts__content">
          <div className="photo-container">
            {person?.images?.length ? (
              <img src={getMiddleImage(person.images)} alt="" />
            ) : (
              <div className="photo-container__initials">
                <span>
                  {getInitials(`${person?.firstName} ${person?.lastName}`)}
                </span>
              </div>
            )}
          </div>

          <h5 className="person-name">
            {`${person?.firstName}${person?.middleName ? " " + person?.middleName : ""} ${person?.lastName}`}{' '}
            {person?.nickName ? <span>({person?.nickName})</span> : ''}
          </h5>

          <InfoMultiField entityId={person.id} items={person?.phones} label="Phone(s)" />
          <InfoMultiField entityId={person.id} items={person?.emails} label="Email(s)" />

          <div className="data-field">
            <div
              className={`data-field__key_lg ${
                person?.gender ? '' : 'no-information'
              }`}>
              Gender
            </div>
            <div className="data-field__value">
              {person?.gender ? (
                capitalize(person?.gender)
              ) : (
                <span className="no-information">-</span>
              )}
            </div>
          </div>

          <AddressField
            className={'address-field'}
            label={'Home Mailing Address'}
            address={person?.mailingAddress}
          />
          <div className="data-field">
            <div
              className={`data-field__key_lg ${
                person?.description ? '' : 'no-information'
              }`}>
              Description
            </div>
            <div className="data-field__value">
              {person?.description ? (
                person?.description
              ) : (
                <span className="no-information">-</span>
              )}
            </div>
          </div>
        </main>
      </div>

      <Tags
        cards={person.cardTagConnects || []}
        tabName={'people'}
        entity={{
          id: personId,
          type: TagEntityEnum.Person,
        }}
        preview={!!onClose}
        loading={!!loader}
      />

      {edit && (
        <PersonModal
          personId={personId}
          onClose={() => setEdit(false)}
          onSubmit={(payload) => {
            dispatch(
              updatePersonAction.request({
                ...(payload as PersonUpdateRequestExpanded),
                callback: (res: false | PersonUpdateResponse) => {
                  requestCallback(dispatch, !!res, callbackTypeEnum.update)
                  if (payload.callback) payload.callback(res);
                },
              }),
            );
          }}
        />
      )}
    </>
  ) : onClose ? (
    <div className="preview-modal-loader">
      <Loader />
    </div>
  ) : (
    <div className="item-info__loader_info-tab">
      <Loader />
    </div>
  );
};

export default connect((store: IStore) => ({
  personsState: store.person,
}))(PersonalContacts);
