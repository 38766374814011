import React, { FC, useState, useEffect, useRef } from 'react';
import FormattedInput from 'react-number-format';

import Radio from 'components/UI/controls/Radio';

/* constants & utils */
import { SQFT_IN_ACRE, Unit } from 'config/constants';
import { parseStringWithSeparators } from 'utils/helper-functions';

/* styles */
import { Color } from './shared/styles';
import style from './shared/styles/control.module.scss';

interface Props {
  containerStyle?: { [key: string]: number | string };
  className?: string;
  name?: string;
  label?: string;
  units?: Array<{ type: Unit; label: string }>;
  returnValueIn?: Unit;
  max?: number;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  thousandSeparator?: boolean;
  isYearValue?: boolean;
  upperCaseLabel?: boolean;

  Min: {
    placeholder?: string;
    value?: string;
    onChange: (value: string) => void;
  };

  Max: {
    placeholder?: string;
    value?: string;
    onChange: (value: string) => void;
  };

  onUnitChange?: (unit?: Unit) => void;
  onError?: (error: boolean, message?: string) => void;

  darkTheme?: boolean;
  readonly?: boolean;
  required?: boolean;
  disabled?: boolean;
}

interface IStoredValue {
  unit?: Unit;
  value: string;
}

const MONTHS_IN_YEAR = 12;
let storedMinValue: IStoredValue = {
  unit: undefined,
  value: '',
};
let storedMaxValue: IStoredValue = {
  unit: undefined,
  value: '',
};

export const InputMinMax: FC<Props> = (props) => {
  const {
    label,
    units,
    returnValueIn,
    isYearValue,
    thousandSeparator,
    fixedDecimalScale,
    decimalScale,
    readonly,
    disabled,
    required,
    darkTheme,
    Min,
    Max,
    upperCaseLabel,
    onUnitChange,
    onError,
  } = props;

  if (!units && returnValueIn) {
    throw new Error(
      'useless prop: "returnValueIn". Pass this prop only in combination with "units" prop.',
    );
  }

  const [minValue, setMinValue] = useState<string>(String(Min.value) || '');
  const [maxValue, setMaxValue] = useState<string>(String(Max.value) || '');
  const [minPlaceholder, setMinPlaceholder] = useState<string>(
    Min.placeholder || '',
  );
  const [maxPlaceholder, setMaxPlaceholder] = useState<string>(
    Max.placeholder || '',
  );
  const [selectedUnit, setSelectedUnit] = useState<Unit | undefined>(
    units && units[0].type,
  );
  const [error, setError] = useState<string>('');

  const initialMinValue = useRef(Min.value);
  const initialMaxValue = useRef(Max.value);

  useEffect(
    () => () => {
      storedMinValue = { unit: undefined, value: '' };
      storedMaxValue = { unit: undefined, value: '' };
    },
    [],
  );

  useEffect(() => {
    if (Min.value === '') {
      setMinValue('');
    } else if (Min.value) {
      handleChangeMin(String(Min.value));
    }
    if (Max.value === '') {
      setMaxValue('');
    } else if (Max.value) {
      handleChangeMax(String(Max.value));
    }
  }, [Min.value, Max.value]);

  useEffect(() => {
    switch (selectedUnit) {
      case Unit.SQFT:
        if (Min.value && minValue && minValue === initialMinValue.current) {
          if (storedMinValue.unit === Unit.SQFT) {
            setMinValue(storedMinValue.value);
          }
          if (storedMinValue.unit === Unit.ACRES) {
            setMinValue(String(Number(storedMinValue.value) * SQFT_IN_ACRE));
          }
        } else {
          if (minValue === '') setMinValue('');
          else setMinValue(String(Number(minValue) * SQFT_IN_ACRE));
        }
        if (Max.value && maxValue && maxValue === initialMaxValue.current) {
          if (storedMaxValue.unit === Unit.SQFT) {
            setMaxValue(storedMaxValue.value);
          }
          if (storedMaxValue.unit === Unit.ACRES) {
            setMaxValue(String(Number(storedMaxValue.value) * SQFT_IN_ACRE));
          }
        } else {
          if (maxValue === '') setMaxValue('');
          else {
            setMaxValue(String(Number(maxValue) * SQFT_IN_ACRE));
          }
        }
        break;
      case Unit.ACRES:
        if (minValue === '') setMinValue('');
        else
          setMinValue(
            String(Number(minValue.replace(/,/g, '')) / SQFT_IN_ACRE),
          );
        if (maxValue === '') setMaxValue('');
        else
          setMaxValue(
            String(Number(maxValue.replace(/,/g, '')) / SQFT_IN_ACRE),
          );
        break;
      case Unit.MONTHLY_LEASE_RATE:
        if (Min.value && minValue && minValue === initialMinValue.current) {
          if (storedMinValue.unit === Unit.MONTHLY_LEASE_RATE) {
            setMinValue(storedMinValue.value);
          }
          if (storedMinValue.unit === Unit.ANNUAL_LEASE_RATE) {
            setMinValue(
              String(
                Number(storedMinValue.value.replace(/,/g, '')) / MONTHS_IN_YEAR,
              ),
            );
          }
        } else {
          if (minValue === '') setMinValue('');
          else {
            setMinValue(String(Number(minValue) / MONTHS_IN_YEAR));
          }
        }
        if (Max.value && maxValue && maxValue === initialMaxValue.current) {
          if (storedMaxValue.unit === Unit.MONTHLY_LEASE_RATE) {
            setMaxValue(storedMaxValue.value);
          }
          if (storedMaxValue.unit === Unit.ANNUAL_LEASE_RATE) {
            setMaxValue(String(Number(storedMaxValue.value) / MONTHS_IN_YEAR));
          }
        } else {
          if (maxValue === '') setMaxValue('');
          else {
            setMaxValue(
              String(Number(maxValue.replace(/,/g, '')) / MONTHS_IN_YEAR),
            );
          }
        }
        break;
      case Unit.ANNUAL_LEASE_RATE:
        if (minValue === '') setMinValue('');
        else
          setMinValue(
            String(Number(minValue.replace(/,/g, '')) * MONTHS_IN_YEAR),
          );

        if (maxValue === '') setMaxValue('');
        else
          setMaxValue(
            String(Number(maxValue.replace(/,/g, '')) * MONTHS_IN_YEAR),
          );
        break;
      default:
        if (minValue === '') setMinValue('');
        else setMinValue(minValue.replace(/,/g, ''));

        if (maxValue === '') setMaxValue('');
        else setMaxValue(maxValue.replace(/,/g, ''));
    }
    onUnitChange && onUnitChange(selectedUnit);
  }, [selectedUnit]);

  const handleChangeMin = (value: string) => {
    storedMinValue = {
      unit: selectedUnit,
      value,
    };
    setMinValue(value);

    if (storedMaxValue.value) {
      if (
        (selectedUnit === Unit.SQFT &&
          parseStringWithSeparators(storedMaxValue.value) <
            parseStringWithSeparators(value)) ||
        (selectedUnit === Unit.ACRES &&
          parseStringWithSeparators(storedMaxValue.value) / SQFT_IN_ACRE <
            parseStringWithSeparators(value)) ||
        (selectedUnit === Unit.MONTHLY_LEASE_RATE &&
          parseStringWithSeparators(storedMaxValue.value) <
            parseStringWithSeparators(value)) ||
        (selectedUnit === Unit.ACRES &&
          parseStringWithSeparators(storedMaxValue.value) / MONTHS_IN_YEAR <
            parseStringWithSeparators(value))
      ) {
        setError('Max value should be greater than min value');
        onError && onError(true);
      } else {
        setError('');
        onError && onError(false);
      }
    } else {
      setError('');
      onError && onError(false);
    }

    if (returnValueIn) transferValueToSpecified();
    else Min.onChange(value.replace(/,/g, ''));
  };

  const handleChangeMax = (value: string) => {
    storedMaxValue = {
      unit: selectedUnit,
      value,
    };
    setMaxValue(value);

    if (value) {
      if (
        (!selectedUnit &&
          parseStringWithSeparators(storedMinValue.value) >
            parseStringWithSeparators(value)) ||
        (selectedUnit === Unit.SQFT &&
          parseStringWithSeparators(storedMinValue.value) >
            parseStringWithSeparators(value)) ||
        (selectedUnit === Unit.ACRES &&
          parseStringWithSeparators(storedMinValue.value) / SQFT_IN_ACRE >
            parseStringWithSeparators(value)) ||
        (selectedUnit === Unit.MONTHLY_LEASE_RATE &&
          parseStringWithSeparators(storedMinValue.value) >
            parseStringWithSeparators(value)) ||
        (selectedUnit === Unit.ANNUAL_LEASE_RATE &&
          parseStringWithSeparators(storedMinValue.value) / MONTHS_IN_YEAR >
            parseStringWithSeparators(value))
      ) {
        setError('Max value should be greater than min value');
        onError && onError(true);
      } else {
        setError('');
        onError && onError(false);
      }
    } else {
      setError('');
      onError && onError(false);
    }

    if (returnValueIn) transferValueToSpecified();
    else Max.onChange(value.replace(/,/g, ''));
  };

  const transferValueToSpecified = (): void => {
    switch (selectedUnit) {
      case Unit.ACRES:
        if (minValue) Min.onChange(String(minNumberValue() * SQFT_IN_ACRE));
        if (maxValue) Max.onChange(String(maxNumberValue() * SQFT_IN_ACRE));
        break;
      case Unit.ANNUAL_LEASE_RATE:
        const min = minNumberValue() / MONTHS_IN_YEAR;
        const max = maxNumberValue() / MONTHS_IN_YEAR;
        if (minValue) Min.onChange(String(min));
        if (maxValue) Max.onChange(String(max));
        break;
      default:
        if (storedMinValue.value)
          Min.onChange(storedMinValue.value.replace(/,/g, ''));
        if (storedMaxValue.value)
          Max.onChange(storedMaxValue.value.replace(/,/g, ''));
    }
  };

  const validateYear = (year: string) => {
    setError('');
    onError?.(false);
    if (year.length < 4) {
      setError('4 digits required');
      onError?.(true, 'year is invalid');
    }
  };

  const minNumberValue = (): number =>
    Number(storedMinValue.value.replace(/,/g, ''));
  const maxNumberValue = (): number =>
    Number(storedMaxValue.value.replace(/,/g, ''));

  return (
    <div
      className={style.container}
      style={{ ...props.containerStyle, minWidth: '100%', maxWidth: '100%' }}>
      {label && (
        <label
          className={darkTheme ? style.label_dark : style.label}
          style={{ color: disabled ? Color.disabled : Color.label }}>
          {label}
          {required && <span className={style.required}>*</span>}
        </label>
      )}

      {units && Array.isArray(units) && (
        <div className={style.units} style={{ marginTop: label ? 15 : '' }}>
          {units.map((unit) => (
            <Radio
              key={unit.label}
              label={unit.label}
              value={unit.type}
              checked={selectedUnit === unit.type}
              onChange={(e) => setSelectedUnit(e.target.value as Unit)}
              darkTheme={darkTheme}
              upperCaseLabel={upperCaseLabel}
            />
          ))}
        </div>
      )}

      <div className="filters__controls-container_with-separator">
        <div>
          <FormattedInput
            className={darkTheme ? style.input_dark : style.input}
            style={{ borderColor: error && Color.error }}
            placeholder={minPlaceholder}
            name={props.name}
            value={minValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeMin(event?.target.value)
            }
            onFocus={() => setMinPlaceholder('')}
            onBlur={() => {
              setMinPlaceholder(Min.placeholder || '');
              if (isYearValue) validateYear(minValue);
            }}
            disabled={disabled}
            readOnly={readonly}
            thousandSeparator={isYearValue ? false : thousandSeparator}
            decimalScale={isYearValue ? 0 : decimalScale}
            fixedDecimalScale={fixedDecimalScale}
          />
        </div>
        <span className="filters__controls-container_with-separator dot">
          •
        </span>
        <div>
          <FormattedInput
            className={darkTheme ? style.input_dark : style.input}
            style={{ borderColor: error && Color.error }}
            placeholder={maxPlaceholder}
            name={props.name}
            value={maxValue}
            // @ts-ignore
            onChange={(event) => handleChangeMax(event?.target.value)}
            onFocus={() => setMaxPlaceholder('')}
            onBlur={() => {
              setMaxPlaceholder(Max.placeholder || '');
              if (isYearValue) validateYear(minValue);
            }}
            disabled={disabled}
            readOnly={readonly}
            thousandSeparator={isYearValue ? false : thousandSeparator}
            decimalScale={isYearValue ? 0 : decimalScale}
            fixedDecimalScale={fixedDecimalScale}
          />

          {error && <span className={style.error}>{error}</span>}
        </div>
      </div>
    </div>
  );
};

InputMinMax.defaultProps = {
  decimalScale: 6,
  fixedDecimalScale: false,
  thousandSeparator: true,
  upperCaseLabel: false,
  readonly: false,
  disabled: false,
  required: false,
};

export default InputMinMax;
