import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const SearchIcon: FC<Props> = ({ color, ...props }) => (
   <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M0 4.8158C0 2.1601 2.1601 0 4.81579 0C7.47149 0 9.63156 2.1601 9.63159 4.81577C9.63159 5.94562 9.2372 6.98237 8.58447 7.80296L11.8381 11.0566C12.054 11.2724 12.0539 11.6223 11.8382 11.8381L11.8381 11.8382C11.7303 11.9459 11.5884 12 11.4474 12C11.3064 12 11.1644 11.9459 11.0566 11.8381L7.80298 8.58448C6.98237 9.23721 5.94564 9.63159 4.81579 9.63159C2.1601 9.63159 0 7.47149 0 4.8158ZM11.3915 11.5032C11.3914 11.5031 11.3914 11.5031 11.3913 11.503L11.3915 11.5032ZM4.81579 1.10526C2.77026 1.10526 1.10528 2.77006 1.10528 4.8158C1.10528 6.86153 2.77026 8.52632 4.81579 8.52632C6.86133 8.52632 8.52631 6.86155 8.52631 4.8158C8.52631 2.77004 6.86133 1.10526 4.81579 1.10526Z"
         fill={color}
      />
   </svg>
)

SearchIcon.defaultProps = {
   color: "#1F2531"
}