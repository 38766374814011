import { ICardState } from './models';
import { concatWithUnique } from '../../utils/concatWithUnique';
import { CardFullDTO } from '@ternala/voltore-types/lib/modules/card/cardFull.dto';

export const addCards = (newCards: CardFullDTO[], state: ICardState): ICardState => {
  const cardData = Object.assign({}, state.cardData);
  const reminderData = Object.assign({}, state.reminderData);
  const newReminders: number[] = [];
  newCards.forEach((card) => {
    cardData[card.id] = card;

    card.reminders?.forEach((mainReminder) => {
      const cardId = '' + card.id + mainReminder?.id;
      reminderData[cardId] = {
        ...card,
        reminders: card.reminders?.map(reminder => {
          return {
            ...reminder,
            isMain: reminder.id === mainReminder.id
          };
        })
      };
      newReminders.push(Number(cardId));
    });
  });
  return {
    ...state,
    cardData: cardData,
    reminderData: reminderData,
    cards: concatWithUnique<number>(
      state.cards || [],
      newCards.map((card) => card.id),
    ),
    reminders: concatWithUnique<number>(state.reminders || [], newReminders),
    count: state.count ? state.count + newCards.length : newCards.length,
    remindersCount: state.remindersCount ? state.remindersCount + newReminders.length : newReminders.length,
  };
};
