
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

/* types */
import { IStore } from 'controllers/store';
import SaleTransactionInfoContent from './SaleTransactionInfoContent';

interface Props
  extends RouteComponentProps<{
    id?: string;
    tabName?: string;
    transactionId: string;
  }> {}

const SaleTransactionInfo: FC<Props> = (props) => {
  const {
    match: { params },
  } = props;
  return <SaleTransactionInfoContent params={params} shadow />;
};

export default connect((store: IStore) => ({}))(SaleTransactionInfo);
