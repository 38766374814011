/* eslint-disable */
import React from 'react';
import { ContentBlock, ContentState } from 'draft-js';

export const Time: React.FC<any> = ({ url, disabled, ...props }, ...args) => {
  const {
    data,
    title,
  } : {
    data?: {
      label: string;
      date: Date;
    };
    title?: string;
  } = props.contentState.getEntity(props.entityKey).getData();
  return <span className={'mention not-clicked'}>{props.children}</span>;
};

export function findTime(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'TIME'
    );
  }, callback);
}
