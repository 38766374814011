import React, { FC, useState, useEffect, ChangeEvent } from 'react'

/* components */
import PopupFullImage from 'components/modals/PopupFullImage'
import { CameraIcon } from 'components/icons/CameraIcon'
import { ViewIcon } from 'components/icons/ViewIcon'
import { TrashIcon } from 'components/icons/TrashIcon'

/* constants */
import { MAX_IMAGE_SIZE } from 'config'

/* styles */
import style from './InputImage.module.scss'

interface Props {
   imageUrl?: string
   firstName?: string
   lastName?: string
   onChange: (photo: File | null) => void
   onDelete: () => void
}

export const InputImage: FC<Props> = props => {
   const { firstName, lastName, imageUrl, onDelete } = props

   const [error, setError] = useState<string>('')
   const [preview, setPreview] = useState<string | undefined>()
   const [isFullImagePopupOpen, setFullImagePopupOpen] = useState<boolean>(false)

   useEffect(() => {
      imageUrl && setPreview(imageUrl)
   }, [imageUrl])

   const FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif']

   const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target
      if (files) {
         setPreview('')

         if (!FILE_TYPES.some(type => type === files[0].type)) {
            setError('Only images allowed')
            return
         }
         if (files[0].size > MAX_IMAGE_SIZE) {
            const maxImageSizeInMB = Math.round(MAX_IMAGE_SIZE / 1000000)
            setError(`Max image size is ${maxImageSizeInMB} mb`)
            return
         }
         const previewUrl = URL.createObjectURL(files[0])
         setPreview(previewUrl)
         setError('')
         props.onChange(files[0])
      }

      event.target.value = ''
   }

   return <>
      <div className={style.image_container}>
         <img className={style.preview} src={preview} alt="" />

         {!preview &&
            <div className={style.upload} style={{ width: '100%', height: '100%' }}>
               <input
                  type="file"
                  id="upload"
                  className="visually-hidden"
                  onChange={onChange}
                  accept={FILE_TYPES.join(', ')}
               />

               <label htmlFor="upload" className={style.upload_label}>
                  {(!imageUrl && firstName && lastName)
                     ? <span className={style.initials}>{ firstName[0].toUpperCase() + lastName[0].toUpperCase() }</span>
                     : <>
                        <div className={style.upload_icon}>
                           <CameraIcon color="white" />
                        </div>
                        <span className={style.upload_hint}>.jpg .jpeg .png</span>
                     </>
                  }
               </label>
            </div>
         }

         {preview &&
            <div className={style.preview__mask}>
               <div className={style.preview__controls}>
                  <div
                     onClick={() => setFullImagePopupOpen(true)}
                     className={style.controls__icon}
                     title="View"
                  >
                     <ViewIcon width={19} height={13} />
                  </div>

                  <div
                     className={style.controls__icon}
                     onClick={() => {
                        setPreview('')
                        onDelete()
                     }}
                     title="Remove"
                  >
                     <TrashIcon width={15} height={19} />
                  </div>
               </div>
            </div>
         }
         

         <div className={style.upload_error}>{ error }</div>
      </div>

      {isFullImagePopupOpen &&
         <PopupFullImage
            url={preview || ''}
            onClose={() => setFullImagePopupOpen(false)}
         />
      }
   </>
}

export default InputImage