import { all, put, takeEvery, call } from "redux-saga/effects";

//APIs
import {PersonApi} from '../transport/person.api';

// Actions
import {
  getPersonsAction,
  getPersonAction,
  createPersonAction,
  updatePersonAction,
  deletePersonAction,
  getPersonOwnedPropertiesAction, downloadPersonExportAction
} from '../actions';
import {addError, addLoader, removeLoader} from '../index';

// Utils
import uuid from 'utils/uuid';

// Constants
import {LoaderAction} from 'config/constants';
import {
    PersonCreateResponse,
    PersonDeleteResponse,
    PersonGetListResponse,
    PersonGetResponse, PersonUpdateResponse, PropertyOwnedDTO
} from "@ternala/voltore-types";
import {IError} from "../../model";
import { getAccessTokenSaga } from "../../auth/sagas/auth";
import { format } from 'date-fns';

export function* getPersonsSaga({
                                    payload,
                                }: ReturnType<typeof getPersonsAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, people are loading!',
            type: LoaderAction.person.getList,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: PersonGetListResponse | string| IError = yield PersonApi.getPersons(data, accessToken);
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                getPersonsAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    getPersonsAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to get persons!',
                    type: LoaderAction.person.getList,
                }),
            );
          if (typeof payload.callback === 'function') payload.callback(false);
        } else {
            yield put(
                getPersonsAction.success({
                    response: res,
                    isAll: payload.limit ? res.items.length < payload.limit : true,
                    searchParams: payload,
                }),
            );
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(res);
        }
    } catch (error: any) {
        console.error('error: ', error);
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            getPersonsAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                id: loadId,
                message: 'Failed to get people!',
                type: LoaderAction.person.getError,
            }),
        );
    }
}

export function* downloadPersonExportSaga({
                payload,
              }: ReturnType<typeof downloadPersonExportAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, people are loading!',
      type: LoaderAction.person.getList,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    const res: Blob| IError = yield PersonApi.downloadFile(accessToken);
    if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
      if (typeof res === "string") {
        downloadPersonExportAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          downloadPersonExportAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get persons!',
          type: LoaderAction.person.getList,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(false);
    } else {
      const hiddenElement = document.createElement('a');
      const url = window.URL || window.webkitURL;
      hiddenElement.href = url.createObjectURL(res as Blob | MediaSource);
      hiddenElement.target = '_blank';
      hiddenElement.download = `recipes_${format(new Date(), 'P p')}.xlsx`;
      hiddenElement.click();
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(res);
    }
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getPersonsAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to get people!',
        type: LoaderAction.person.getError,
      }),
    );
  }
}

export function* createPersonSaga({
                                      payload,
                                  }: ReturnType<typeof createPersonAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, person is creating!',
            type: LoaderAction.person.create,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        delete data.loadCallback;
        const res: PersonCreateResponse | string | IError = yield PersonApi.createPerson(data, accessToken);

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                createPersonAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    createPersonAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to create person!',
                    type: LoaderAction.person.create,
                }),
            );
          if (typeof payload.callback === 'function') payload.callback(false);
        } else {
            yield put(createPersonAction.success(res));
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(res);
        }
        if (typeof payload.loadCallback === 'function') payload.loadCallback();
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            createPersonAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                id: loadId,
                message: 'Failed to create person!',
                type: LoaderAction.person.getError,
            }),
        );
    }
}

export function* getPersonSaga({
                                   payload,
                               }: ReturnType<typeof getPersonAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, person is getting!',
            type: LoaderAction.person.getItem,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: PersonGetResponse | string | IError = yield PersonApi.getPerson(data, accessToken);

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                getPersonAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    getPersonAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to get person!',
                    type: LoaderAction.person.getItem,
                }),
            );
          if (typeof payload.callback === 'function') payload.callback(false);
        } else {
            yield put(getPersonAction.success(res));
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(res);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        console.error('error: ', error);
        yield put(
            getPersonAction.failure({
                key: String(payload.id),
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                key: String(payload.id),
                id: loadId,
                message: 'Failed to get person!',
                type: LoaderAction.person.getError,
            }),
        );
    }
}

export function* updatePersonSaga({
                                      payload,
                                  }: ReturnType<typeof updatePersonAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, person is updating!',
            type: LoaderAction.person.update,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: PersonUpdateResponse | string | IError = yield PersonApi.updatePerson(
            data,
            accessToken,
        );

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                updatePersonAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    updatePersonAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to update person!',
                    type: LoaderAction.person.update,
                }),
            );
          if (typeof payload.callback === 'function') payload.callback(false);
        } else {
            yield put(updatePersonAction.success(res));
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(res);
        }
    } catch (error: any) {
        console.error('error: ', error);
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            updatePersonAction.failure({
                key: String(payload.id),
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                key: String(payload.id),
                id: loadId,
                message: 'Failed to update person!',
                type: LoaderAction.person.getError,
            }),
        );
    }
}

export function* deletePersonSaga({
                                      payload,
                                  }: ReturnType<typeof deletePersonAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, person is deleting!',
            type: LoaderAction.person.delete,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: PersonDeleteResponse | string = yield PersonApi.deletePerson(data, accessToken);

        if (typeof res === "undefined" || typeof res === "string") {
            deletePersonAction.failure({
                code: res,
                message: res || "Something was wrong"
            });
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to delete person!',
                    type: LoaderAction.person.delete,
                }),
            );
        } else {
            yield put(
                deletePersonAction.success({
                    ...res,
                    id: payload.id,
                }),
            );
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true);
        }
    } catch (error: any) {
        console.error('error: ', error);
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            deletePersonAction.failure({
                key: String(payload.id),
                code: error.code || 403,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                key: String(payload.id),
                id: loadId,
                message: 'Failed to delete person!',
                type: LoaderAction.person.getError,
            }),
        );
    }
}

export function* getPersonOwnedPropertiesSaga({
                                                  payload,
                                              }: ReturnType<typeof getPersonOwnedPropertiesAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, owned properties is getting!',
            type: LoaderAction.person.getItem,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        const res: PropertyOwnedDTO[] | string | IError = yield PersonApi.getOwnedProperties(payload, accessToken);

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                getPersonOwnedPropertiesAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    getPersonOwnedPropertiesAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to get enterprise owned properties!',
                    type: LoaderAction.person.getItem,
                }),
            );
        } else {
            yield put(
                getPersonOwnedPropertiesAction.success({
                    response: res,
                    id: payload.id,
                }),
            );
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true);
        }
    } catch (error: any) {
        console.error('error: ', error);
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            getPersonOwnedPropertiesAction.failure({
                key: String(payload.id),
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                key: String(payload.id),
                id: loadId,
                message: 'Failed to get person!',
                type: LoaderAction.person.getItem,
            }),
        );
    }
}

export function* personActionSaga() {
    yield all([
        takeEvery(getPersonsAction.request, getPersonsSaga),
        takeEvery(getPersonAction.request, getPersonSaga),
        takeEvery(downloadPersonExportAction.request, downloadPersonExportSaga),
        takeEvery(createPersonAction.request, createPersonSaga),
        takeEvery(updatePersonAction.request, updatePersonSaga),
        takeEvery(deletePersonAction.request, deletePersonSaga),
        takeEvery(
            getPersonOwnedPropertiesAction.request,
            getPersonOwnedPropertiesSaga,
        ),
    ]);
}
