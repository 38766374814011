import {
  FavoriteCreateRequest,
  FavoriteCreateResponse,
} from '@ternala/voltore-types';
import { appendSearchParams } from 'utils/appendSearchParams';

import { Config } from '../../../config/api';
import { authHeader, handleErrors } from '../../../utils/API';
import {IError} from "../../model";

class API {
  public async changeStatus(
    createTagData: FavoriteCreateRequest,
    token: string,
  ): Promise<FavoriteCreateResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'favorite/change-status');

    url = appendSearchParams(url, createTagData);

    if (createTagData.hasOwnProperty('isFavorite')) {
      url.searchParams.append(
        'isFavorite',
        JSON.stringify(createTagData.isFavorite),
      );
    }
    if (createTagData.hasOwnProperty('itemId')) {
      url.searchParams.append('itemId', JSON.stringify(createTagData.itemId));
    }
    if (createTagData.hasOwnProperty('itemType')) {
      url.searchParams.append('itemType', String(createTagData.itemType));
    }

    return handleErrors(
      fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(createTagData),
      }),
    );
  }
}

export const FavoriteApi = new API();
