/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";

/* types */
import { CardEntityConnectDTO, TagDTO } from "@ternala/voltore-types";
import { TagCategoryDTO } from "@ternala/voltore-types/lib/modules/card/tag/category/tagCategory.dto";
import { loadTagsOptions } from "config/constants/select-options";

/* controllers */
import TagsContext from "context/Tags";
import { IStore } from "controllers/store";
import { createTagAction, setTagsAction } from "controllers/tag/actions";
import { ITagState } from "controllers/tag/models.d";
import { TagApi } from "controllers/tag/transport/tag.api";
import { createCardAction } from "controllers/card/actions";

/* components */
import Category from "./Controls/Category";
import TagItem from "./Controls/TagItem";
import TagAddButton from "../../components/icons/TagAddButton";
import TagAddButtonActive from "../../components/icons/TagAddButtonActive";
import PopupTag from "components/modals/PopupTag";
import PopupTagGroup from "components/modals/PopupTagGroup";
import SelectSearch from "components/UI/controls/SelectSearchCustom";
import { animated, config, useTransition } from "react-spring";
import { getAccessTokenUtil, callbackTypeEnum, requestCallback } from "utils";
import { TagEntityEnum } from "@ternala/voltore-types/lib/card";

interface IProps {
  tagState: ITagState;
  tabName:
    | 'properties'
    | 'sales'
    | 'people'
    | 'enterprises'
    | 'users'
    | 'leases';
  entity: {
    type: TagEntityEnum;
    id: number;
  };
  cards: CardEntityConnectDTO[];
  preview: boolean;
  loading?: boolean;
}

const Tags = ( { tagState, entity, loading, preview, cards } : IProps) => {

  // Functionality
  const creationRef = useRef<HTMLDivElement>(null);
  let tags = cards?.map((card) => card.tag as TagDTO) || [];

  /* animation */
  const transitions = useTransition(!loading, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  });
  const stateTags = tagState.entities[entity.type][entity.id];
  const dispatch = useDispatch();
  const tagsFilters = useContext(TagsContext);

  const [tagCreation, setTagCreation] = useState<boolean>();
  const [isTagCreationOpen, setIsTagCreationOpen] = useState<boolean>(false);
  const [isTagCategoryCreationOpen, setIsTagCategoryCreationOpen] =
    useState<boolean>(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (!creationRef?.current?.contains(event.target as Node))
      setTagCreation(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    /* add entity tags to the state */
    dispatch(
      setTagsAction({
        tags,
        entityType: entity.type,
        entityId: entity.id,
      }),
    );
  }, [cards]);

  function groupTags(items: TagDTO[]) {
    if (!items) return {};
    const groups: {
      [key: string]: TagCategoryDTO;
    } = {};
    items.forEach((tag) => {
      if (!tag) return;
      if (tag?.category) {
        const foundCategory = groups[tag.category?.id];
        if (foundCategory) {
          foundCategory.tags.push(tag);
        } else {
          groups[tag.category.id] = {
            ...tag.category,
            tags: [tag],
          };
        }
      }
    });
    return groups;
  }

  let categories = Object.values(groupTags(stateTags));

  return transitions(
    (styles, item) =>
      item && (
        <animated.div
          style={styles}
          className={`tags ${preview ? 'preview' : ''}`}>
          <label className="tags-title">
            Tags
            <span
              className="tags-title-icon"
              onClick={() => setTagCreation(!tagCreation)}>
              {tagCreation ? <TagAddButtonActive /> : <TagAddButton />}
            </span>
          </label>
          <>
            {tagCreation ? (
              <div className="tag-creation" ref={creationRef}>
                <SelectSearch
                  asyncOptions={async (searchParams) => {
                    const token = await getAccessTokenUtil(dispatch);
                    if (token) {
                      return await loadTagsOptions(
                        searchParams,
                        token)
                    }
                    return []
                  }
                  }
                  selectedOptions={stateTags.map((tag) => ({
                    label: tag?.title,
                    value: tag?.id,
                  }))}
                  onChange={(option) => {
                    if (option && option.value) {
                      dispatch(
                        createCardAction.request({
                          connects: [
                            {
                              tag: Number(option.value),
                              [entity.type]: entity.id,
                            },
                          ],
                          callback: () => setTagCreation(false),
                        }),
                      );
                    }
                  }}
                  creation={{
                    label: 'Create new',
                    onClick: () => {
                      setIsTagCreationOpen(true);
                    },
                  }}
                  tags={stateTags.map((tag) => tag?.id)}
                />
              </div>
            ) : (
              ''
            )}
            <div className="tags-container">
              {categories && categories.length ? (
                categories.map((category) => (
                  <div
                    key={category.id}
                    className={`tags-container_item two-row`}>
                    <div className="tags-container_category">
                      <Category category={category} />
                      <div className="tags-container_tag">
                        {category.tags.map((tag) => (
                          <TagItem
                            tag={tag}
                            key={tag.id}
                            entity={entity}
                            addTag={tagsFilters.addTag}
                            tags={stateTags}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <span className="no-elements">
                  Click on the button above to add a tag
                </span>
              )}
            </div>
            {isTagCreationOpen ? (
              <PopupTag
                setGroupCreation={setIsTagCategoryCreationOpen}
                onClose={() => {
                  setIsTagCreationOpen(false);
                }}
                onCreate={function (data, callback) {
                  dispatch(
                    createTagAction.request({
                      ...data,
                      callback: (status: boolean) => {
                        requestCallback(dispatch, status, callbackTypeEnum.create)
                        if (callback) callback(status);
                      },
                    }),
                  );
                }}
              />
            ) : (
              ''
            )}
            {isTagCategoryCreationOpen ? (
              <PopupTagGroup
                onClose={() => {
                  setIsTagCategoryCreationOpen(false);
                }}
                onCreate={async function (data) {
                  const token = await getAccessTokenUtil(dispatch);
                  if (token)
                    await TagApi.createTagCategory(data, token).then(
                      () => setIsTagCategoryCreationOpen(false),
                    );
                }}
              />
            ) : (
              ''
            )}
          </>
        </animated.div>
      ),
  );
};

export default connect((store: IStore) => ({
  tagState: store.tag,
}))(Tags);
