import {
  LeaseTransactionStatusEnum,
  SaleTransactionStatusEnum,
} from '@ternala/voltore-types/lib/constants';

export * from './country-codes';
export * from './note-creator';
export * from './select-options';
export * from './sorting';
export * from './tabs';

export const SQFT_IN_ACRE = 43560;
export const METERS_IN_DEGREE = 111320;

export const isPhone = document.body.clientWidth < 480;

export enum PageEnum {
  PROPERTIES = 'properties',
  ENTERPRISES = 'enterprises',
  PEOPLE = 'people',
  USERS = 'users',
  TAGS = 'tag groups',
  TAXONOMY = 'taxonomy',
  SETTINGS = 'settings',
}

export enum RequestActionEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum FilterTypeEnum {
  PERSONS = 'persons',
  ENTERPRISES = 'enterprises',
  PROPERTIES = 'property',
  SALES = 'sale',
  LEASES = 'lease',
}

export enum TaxonomyOrEntityType {
  SUITE = 'suite',
}

export enum LeaseTransactionViewTypeEnum {
  MAP = 'map',
  STACK = 'stack',
  LIST = 'list',
}

export enum LeaseStatusVisualization {
  AVAILABLE = 'available',
  LEASED = 'leased',
  WILL_BE_LEASED = 'will-be-leased',
  AVAILABLE_OVERLAP = 'available_overlap',
  LEASED_OVERLAP = 'leased_overlap',
  WILL_BE_LEASED_OVERLAP = 'will-be-leased_overlap',
  AVAILABLE_INACTIVE = 'available_inactive',
  LEASED_INACTIVE = 'leased_inactive',
  WILL_BE_LEASED_INACTIVE = 'will-be-leased_inactive',
  AVAILABLE_OVERLAP_INACTIVE = 'available_overlap_inactive',
  LEASED_OVERLAP_INACTIVE = 'leased_overlap_inactive',
  WILL_BE_LEASED_OVERLAP_INACTIVE = 'will-be-leased_overlap_inactive',
}

export enum Unit {
  SQFT = 'sqft',
  ACRES = 'acres',
  PRICE = '$',
  PRICE_PER_SQFT = '$/sqft',
  MONTHLY_LEASE_RATE = '$/sqft ',
  ANNUAL_LEASE_RATE = '$/sqft/year',
}

export const statusOrder = {
  [LeaseTransactionStatusEnum.Active]: 1,
  [LeaseTransactionStatusEnum.Pending]: 2,
  [LeaseTransactionStatusEnum.Cancelled]: 3,
  [LeaseTransactionStatusEnum.Leased]: 3,
  [SaleTransactionStatusEnum.Sold]: 3,
  [LeaseTransactionStatusEnum.Expired]: 3,
};

export const LoaderAction = {
  favorite: {
    change: 'change status',
  },
  user: {
    getList: 'get users',
    getItem: 'get user',
    create: 'create user',
    update: 'update user',
    delete: 'delete user',
  },
  taxonomy: {
    getList: 'get users',
    getItem: 'get user',
    create: 'create user',
    update: 'update user',
    delete: 'delete user',
  },
  taxonomyType: {
    getList: 'get users',
    getItem: 'get user',
    create: 'create user',
    update: 'update user',
    delete: 'delete user',
  },
  userRole: {
    getList: 'get user roles',
    getItem: 'get user role',
    create: 'create user role',
    update: 'update user role',
    delete: 'delete user role',
  },
  person: {
    getList: 'get people',
    getItem: 'get person',
    getError: 'get person action error',
    create: 'create person',
    update: 'update person',
    delete: 'delete person',
  },
  enterprise: {
    getList: 'get enterprises',
    getItem: 'get enterprise',
    getError: 'get enterprise action error',
    create: 'create enterprise',
    update: 'update enterprise',
    delete: 'delete enterprise',
  },
  involvement: {
    getList: 'get involvements',
    getItem: 'get involvement',
    getError: 'get involvement action error',
    create: 'create involvement',
    update: 'update involvement',
    delete: 'delete involvement',
  },
  property: {
    getList: 'get properties',
    getItem: 'get property',
    getError: 'get property action error',
    create: 'create property',
    update: 'update property',
    delete: 'delete property',
  },
  sale: {
    getList: 'get sale transactions',
    getItem: 'get sale transaction',
    getError: 'get sales action error',
    create: 'create sale transaction',
    update: 'update sale transaction',
    delete: 'delete sale transaction',
  },
  lease: {
    getList: 'get lease transactions',
    getItem: 'get lease transaction',
    getError: 'get lease action error',
    create: 'create lease transaction',
    update: 'update lease transaction',
    delete: 'delete lease transaction',
  },
  suite: {
    getItem: 'get suite',
    getError: 'get suite action error',
    create: 'create suite',
    update: 'update suite',
    delete: 'delete suite',
  },
  propertyFloor: {
    getItem: 'get propertyFloor',
    getError: 'get propertyFloor action error',
    create: 'create propertyFloor',
    update: 'update propertyFloor',
    delete: 'delete propertyFloor',
  },
  card: {
    getList: 'get cards',
    getItem: 'get card',
    create: 'create card',
    update: 'update card',
    delete: 'delete card',
  },
  reminder: {
    toggle: 'toggle reminder',
  },
  tag: {
    getList: 'get tags',
    create: {
      category: 'create tag category',
      tag: 'create tag',
    },
    update: {
      category: 'update tag category',
      tag: 'update tag',
    },
    delete: {
      category: 'delete tag category',
      tag: 'delete tag',
    },
    detach: 'detach tag',
    edit: 'edit tag',
  },
  history: {
    getList: 'get history',
  },
  export: {
    getList: 'get export templates',
  },
};
