import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   width?: number
   height?: number
   color?: string
}

export const NoteIcon: FC<Props> = ({ width, height, color, ...props }) => (
   <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M8.16621 4.97059C7.97011 4.97059 7.81115 5.12956 7.81115 5.32565V8.87617C7.81115 9.07227 7.65218 9.23123 7.45609 9.23123H1.06516C0.869061 9.23123 0.710099 9.07227 0.710099 8.87617V1.77512C0.710099 1.57902 0.869061 1.42006 1.06516 1.42006H5.32579C5.52189 1.42006 5.68085 1.2611 5.68085 1.065C5.68085 0.868903 5.52189 0.709961 5.32579 0.709961H1.06516C0.476887 0.709961 0 1.18685 0 1.77512V8.87617C0 9.46444 0.476887 9.94133 1.06516 9.94133H7.45611C8.04438 9.94133 8.52127 9.46444 8.52127 8.87617V5.32563C8.52127 5.12956 8.3623 4.97059 8.16621 4.97059Z"
         fill={color}
      />
      <path
         d="M9.54397 0.39815C9.28904 0.143178 8.94326 -3.59905e-05 8.58271 5.61728e-06C8.22195 -0.00103458 7.87582 0.142429 7.62159 0.398379L2.94482 5.07476C2.90602 5.11385 2.87675 5.16136 2.85925 5.21358L2.14915 7.3439C2.08718 7.52995 2.18777 7.731 2.37381 7.79295C2.40991 7.80497 2.44771 7.81111 2.48574 7.81115C2.52385 7.81109 2.56174 7.80497 2.59793 7.79305L4.72825 7.08296C4.78057 7.06548 4.82811 7.03606 4.86708 6.99704L9.54383 2.32028C10.0746 1.78953 10.0747 0.928961 9.54397 0.39815ZM9.04179 1.81858L4.4261 6.43427L3.04707 6.89476L3.50615 5.51751L8.12361 0.901833C8.37746 0.648483 8.78865 0.648899 9.042 0.902748C9.16301 1.02401 9.23121 1.18818 9.23173 1.3595C9.23217 1.53173 9.16378 1.697 9.04179 1.81858Z"
         fill={color}
      />
   </svg>
)

NoteIcon.defaultProps = {
   width: 10,
   height: 10,
   color: '#1F2531'
}
