import React, { useState } from "react";
import { a, useTrail } from "@react-spring/web";

const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    from: { opacity: 0, x: 20, height: 0 },
  });
  return (
    <div>
      {trail.map(({ ...style }, index) => (
        <a.div key={index} style={style}>
          <a.div style={{ height: '100vh', overflow: 'hidden' }}>
            {items[index]}
          </a.div>
        </a.div>
      ))}
    </div>
  );
};

export default Trail;
