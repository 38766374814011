import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { AddressShortDTO } from '@ternala/voltore-types/lib/modules/address/addressShort.dto';
import { generateAddressString } from '@ternala/voltore-types/lib/card';

import { SearchIcon } from 'components/icons/SearchIcon';
import { CloseIconWhite } from 'components/icons/CloseIconWhite';
import List from './List';
import { googleResultToAddress } from 'utils/googleResultToAddress';
import { TimesIcon } from '../../../../icons/TimesIcon';

interface IProps {
  styles?: React.CSSProperties;

  isLinked: boolean;
  isNeedCreation?: boolean;
  address?: AddressShortDTO;
  setAddress: (data: AddressShortDTO | undefined) => void;
}

interface IState {
  value: string;
  hasFocus: boolean;
}

class AutocompleteField extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { value: '', hasFocus: false };
  }

  onCloseHandler() {
    this.props.setAddress(undefined);
  }

  handleChange = (value: string) => {
    this.setState({ value });
  };

  handleSelect = (address: any) => {
    const { setAddress, isLinked } = this.props;
    geocodeByAddress(address)
      .then((results) => {
        setAddress(
          googleResultToAddress({
            ...results[0],
            isConnected: isLinked,
          }),
        );
        this.setState({
          hasFocus: false,
        });
        return getLatLng(results[0]);
      })
      .then((latLng) => console.log('Success', latLng))
      .catch((error) => console.error('Error', error));
  };

  errorHandle = (status: string, clearSuggestion: Function) => {
    console.log('err: ', {
      status,
    });
    if (status === 'ZERO_RESULTS') clearSuggestion();
  };

  render() {
    const { address, setAddress, isNeedCreation } = this.props;
    const { hasFocus, value } = this.state;
    const addressString = generateAddressString(address);

    return (
      <PlacesAutocomplete
        value={value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        highlightFirstSuggestion={true}
        onError={this.errorHandle}
        searchOptions={{
          componentRestrictions: {
            country: 'US',
          },
          types: ['address'],
        }}
        ref={(c) => {
          if (!c) return;
          // @ts-ignore
          c.handleInputOnBlur = () => {};
        }}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={'autocomplete-input'}>
            <span
              className={'googleBloc-searchbox' + (hasFocus ? ' focus' : '')}>
              {!address ? (
                <>

                  {/* @ts-ignore */}
                  <input
                    onFocus={() => this.setState({ hasFocus: true })}
                    {...getInputProps({
                      placeholder: 'Type here',
                      className: 'googleBloc-searchbox-input' + (suggestions.length === 0 && !loading ? " error" : ""),
                      onBlur: (e) => {
                        this.setState({ hasFocus: false });
                        e.preventDefault();
                        e.stopPropagation();
                      },
                    })}
                  />
                  <div className={'dropdown-indicator'}>
                    {value ? (
                      <TimesIcon
                        onClick={() => this.setState({ value: '' })}
                        style={{ marginLeft: '-15px' }}
                      />
                    ) : (
                      <SearchIcon style={{ marginLeft: '-15px' }} />
                    )}
                  </div>
                </>
              ) : (
                <div className="selectedg-address-wrapper">
                  <div className="selectedg-address">
                    <span className="selectedg-address-item">
                      <p className="selectedg-address-item-text">
                        {addressString}
                      </p>
                    </span>
                    <span
                      className="selectedg-address-close"
                      onClick={() => {
                        this.onCloseHandler();
                      }}>
                      <CloseIconWhite />
                    </span>
                  </div>
                </div>
              )}
            </span>
            <div className="list-options" style={this.props.styles}>
              <List
                loading={loading}
                suggestions={suggestions}
                isNeedCreation={isNeedCreation}
                getSuggestionItemProps={getSuggestionItemProps}
                setAddress={setAddress}
              />
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default AutocompleteField;
