import { all } from '@redux-saga/core/effects';
import { createReducer, ActionType } from 'typesafe-actions';

// Actions
import * as actions from './actions';

// Interfaces
import { IModalState } from './models';
import { modalsActionSaga } from './sagas/modals';

//Sagas
export type ModalsActionType = ActionType<typeof actions>;

/* Reducer */
const initialState: IModalState = {
  notifications: [],
};
export const modalsSaga = function* () {
  yield all([modalsActionSaga()]);
};

export const modalsReducer = createReducer<IModalState, ModalsActionType>(
  initialState,
)
  .handleAction(
    [actions.addNotification],
    (state: IModalState, { payload }): IModalState => {
      return {
        ...state,
        notifications: [...state.notifications, payload],
      };
    },
  )
  .handleAction(
    [actions.removeNotification],
    (state: IModalState, { payload }): IModalState => {
      return {
        ...state,
        notifications: state.notifications?.filter(
          (notifications) => notifications.id !== payload.id,
        ),
      };
    },
  );
