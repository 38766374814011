import React, { FC, useContext, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from 'react-router-dom';

/* components */
import Tabs from 'components/page/DatalistItemTabs';
import NotFoundPage from 'pages/Static/NotFound';

/* constants */
import { PageEnum, isPhone } from 'config/constants';
import { enterprisesTabs } from 'config/constants/tabs';

/* types */
import { IStore } from 'controllers/store';
import { RouterState } from 'connected-react-router';
import { EntityEnum } from "@ternala/voltore-types/lib/constants";
import { selectEntity, unselectEntity } from "../../../controllers/showElement/actions";

interface Props extends RouteComponentProps<{ id?: string }> {
  routerState: RouterState;
}

const EnterpriseItemTabs: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    match: {
      params: { id },
    },
    routerState: {
      location: { pathname },
    },
  } = props;

  const currentEnterpriseId: number = Number(id);
  const involvementId: number = Number(
    pathname.split('/')[pathname.split('/').length - 1],
  );
  const backLink: string =
    isPhone &&
    !isNaN(involvementId) &&
    pathname.endsWith(`${enterprisesTabs.people.path}/${involvementId}`)
      ? `/${PageEnum.ENTERPRISES}/${id}/${enterprisesTabs.people.path}`
      : `/${PageEnum.ENTERPRISES}`;

  if (isNaN(currentEnterpriseId)) {
    return <NotFoundPage />;
  }

  useEffect(() => {
    dispatch(selectEntity({
      ids: [currentEnterpriseId],
      type: EntityEnum.enterprise,
    }))
    return () => {
      setTimeout(() => {
        dispatch(unselectEntity({
          ids: [currentEnterpriseId],
          type: EntityEnum.enterprise,
        }))
      }, 30)
    }
  }, [])

  return (
    <>
      <Tabs
        page={PageEnum.ENTERPRISES}
        tabs={enterprisesTabs}
        listItemId={id}
        backLink={backLink}
      />
      {props.children}
    </>
  );
};

export default connect((store: IStore) => ({
  routerState: store.router,
}))(EnterpriseItemTabs);
