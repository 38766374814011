import { ComponentType, ComponentProps } from 'react';
import { RouteComponentProps } from 'react-router';
import ProtectedRoute, { RedirectToHome } from 'routing/ProtectedRoute';

/* static */
import NotFoundPage from 'pages/Static/NotFound';

/* users */
import UsersPage from 'pages/Users';
import UserInfo from 'pages/Users/UserInfo';

/* people */
import PeoplePage from 'pages/People';
import PersonItemTabs from 'pages/People/Tabs';
import PeopleTabsContent from 'pages/People/Tabs/Content';
import PersonInvolvementInfo from 'pages/People/Tabs/InvolvementHistory/InvolvementInfo';

/* enterprises */
import EnterprisePage from 'pages/Enterprise';
import EnterpriseTabs from 'pages/Enterprise/Tabs';
import EnterpriseTabsContent from 'pages/Enterprise/Tabs/Content';
import EnterpriseInvolvementInfo from 'pages/Enterprise/Tabs/People/PersonInfo';

/* properties */
import PropertiesPage from 'pages/Properties';
import PropertyTabs from 'pages/Properties/Tabs';
import PropertyTabsContent from 'pages/Properties/Tabs/Content';
import PropertySaleTransactionInfo from 'pages/Properties/Tabs/Sales/SaleTransactionInfo';
import PropertyLeaseTransactionInfo from 'pages/Properties/Tabs/Leases/LeaseTransactionInfo';

/* tags */
import TagPage from 'pages/Tags';
import CategoryInfo from 'pages/Tags/CategoryInfo';

/* taxonomy */
import TaxonomyPage from 'pages/Taxonomy';
import TaxonomyType from 'pages/Taxonomy/TaxonomyType';

/* settings */
import SettingsPage from "../pages/Setting";
import UserRolePage from "../pages/Setting/content/UserRole";

/* constants & utils */
import { isCordova } from 'utils/cordova';
import { UserTypeEnum } from '@ternala/voltore-types/lib/constants';

export interface IRoute {
  readonly name: Pages;
  readonly title?: string;
  readonly path: string;
  readonly isExact: boolean;
  readonly component: ComponentType<
    RouteComponentProps<any> & ComponentProps<any>
  >;
  childRoutes?: IRoute[];
}

export type Pages =
  | 'login'
  | 'properties'
  | 'properties-tab'
  | 'property'
  | 'property-sale'
  | 'property-lease'
  | 'property-tenant'
  | 'enterprises'
  | 'enterprises-tab'
  | 'enterprise'
  | 'enterprise-involvement'
  | 'people'
  | 'people-tab'
  | 'person'
  | 'person-involvement'
  | 'users'
  | 'user'
  | 'taxonomy'
  | 'taxonomy-type'
  | 'tag groups'
  | 'tag group'
  | 'analytics'
  | 'settings'
  | 'setting'
  | 'notFound';

class RoutingSchema {
  private schema: IRoute[] = [
    {
      name: 'users',
      path: '/users',
      title: 'Users',
      isExact: false,
      component: ProtectedRoute(UsersPage, UserTypeEnum.SA),
      childRoutes: [
        {
          name: 'user',
          path: '/users/:id',
          title: 'User',
          isExact: false,
          component: ProtectedRoute(UserInfo, UserTypeEnum.SA),
        },
      ],
    },
    {
      name: 'people',
      path: '/people',
      title: 'People',
      isExact: false,
      component: ProtectedRoute(PeoplePage, 'AUTHENTICATED_USERS'),
      childRoutes: [
        {
          name: 'person',
          path: '/people/:id',
          title: 'Person',
          isExact: false,
          component: ProtectedRoute(PersonItemTabs, 'AUTHENTICATED_USERS'),
          childRoutes: [
            {
              name: 'people-tab',
              path: '/people/:id/:tabName',
              title: 'Person tab',
              isExact: false,
              component: ProtectedRoute(
                PeopleTabsContent,
                'AUTHENTICATED_USERS',
              ),
              childRoutes: [
                {
                  name: 'person-involvement',
                  path: '/people/:id/:tabName/:involvementId',
                  title: 'Person involvement',
                  isExact: false,
                  component: ProtectedRoute(
                    PersonInvolvementInfo,
                    'AUTHENTICATED_USERS',
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'enterprises',
      path: '/enterprises',
      title: 'Enterprises',
      isExact: false,
      component: ProtectedRoute(EnterprisePage, 'AUTHENTICATED_USERS'),
      childRoutes: [
        {
          name: 'enterprise',
          path: '/enterprises/:id',
          title: 'Enterprise',
          isExact: false,
          component: ProtectedRoute(EnterpriseTabs, 'AUTHENTICATED_USERS'),
          childRoutes: [
            {
              name: 'enterprises-tab',
              path: '/enterprises/:id/:tabName',
              title: 'Enterprise tab',
              isExact: false,
              component: ProtectedRoute(
                EnterpriseTabsContent,
                'AUTHENTICATED_USERS',
              ),
              childRoutes: [
                {
                  name: 'enterprise-involvement',
                  path: '/enterprises/:id/:tabName/:involvementId',
                  title: 'Enterprise involvement',
                  isExact: false,
                  component: ProtectedRoute(
                    EnterpriseInvolvementInfo,
                    'AUTHENTICATED_USERS',
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'tag groups',
      path: '/tag-groups',
      title: 'Tags',
      isExact: false,
      component: ProtectedRoute(TagPage, 'AUTHENTICATED_USERS'),
      childRoutes: [
        {
          name: 'tag group',
          path: '/tag-groups/:id',
          title: 'Tag',
          isExact: false,
          component: ProtectedRoute(CategoryInfo, 'AUTHENTICATED_USERS'),
        },
      ],
    },
    {
      name: 'taxonomy',
      path: '/taxonomy',
      title: 'Taxonomy',
      isExact: false,
      component: ProtectedRoute(TaxonomyPage, 'AUTHENTICATED_USERS'),
      childRoutes: [
        {
          name: 'taxonomy-type',
          path: '/taxonomy/:id',
          title: 'Taxonomy type',
          isExact: false,
          component: ProtectedRoute(TaxonomyType, 'AUTHENTICATED_USERS'),
        },
      ],
    },
    {
      name: 'settings',
      path: '/settings',
      title: 'Settings',
      isExact: false,
      component: ProtectedRoute(SettingsPage, 'AUTHENTICATED_USERS'),
      childRoutes: [
        {
          name: 'setting',
          path: '/settings/:id',
          title: 'Setting',
          isExact: false,
          component: ProtectedRoute(UserRolePage, 'AUTHENTICATED_USERS'),
        },
      ],
    },
    {
      name: 'properties',
      path: '/properties',
      title: 'Properties',
      isExact: false,
      component: ProtectedRoute(PropertiesPage, 'AUTHENTICATED_USERS'),
      childRoutes: [
        {
          name: 'property',
          path: '/properties/:id',
          title: 'Property',
          isExact: false,
          component: ProtectedRoute(PropertyTabs, 'AUTHENTICATED_USERS'),
          childRoutes: [
            {
              name: 'properties-tab',
              path: '/properties/:id/:tabName',
              title: 'Property tab',
              isExact: false,
              component: ProtectedRoute(
                PropertyTabsContent,
                'AUTHENTICATED_USERS',
              ),
              childRoutes: [
                {
                  name: 'property-sale',
                  path: '/properties/:id/sales/:transactionId',
                  title: 'Property sale transaction',
                  isExact: false,
                  component: ProtectedRoute(
                    PropertySaleTransactionInfo,
                    'AUTHENTICATED_USERS',
                  ),
                },
                {
                  name: 'property-lease',
                  path: '/properties/:id/leases/:transactionId',
                  title: 'Property lease transaction',
                  isExact: false,
                  component: ProtectedRoute(
                    PropertyLeaseTransactionInfo,
                    'AUTHENTICATED_USERS',
                  ),
                },
                {
                  name: 'property-tenant',
                  path: '/properties/:id/tenants/:transactionId',
                  title: 'Property tenant transaction',
                  isExact: false,
                  component: ProtectedRoute(
                    PropertyLeaseTransactionInfo,
                    'AUTHENTICATED_USERS',
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'analytics',
      path: '/analytics',
      title: 'Analytics',
      isExact: true,
      component: ProtectedRoute(EnterprisePage, 'AUTHENTICATED_USERS'),
    },
    {
      name: 'notFound',
      path: '*',
      isExact: true,
      component: isCordova()
        ? ProtectedRoute(NotFoundPage, 'ANONYMOUS_USERS')
        : ProtectedRoute(RedirectToHome, 'ANONYMOUS_USERS'),
    },
  ];
  private findRouteByPath(path: string): IRoute | undefined {
    return this.schema.find(({ path: routePath }) => routePath === path);
  }

  // private findActiveRoute(path: string, exact?: boolean): IRoute | undefined {
  //   return this.schema.find(({ path: routePath }) =>
  //     matchPath(routePath, { path, exact }),
  //   );
  // }

  private findRouteInArray(
    routes: IRoute[],
    routeName: Pages,
  ): IRoute | undefined {
    for (let route of routes) {
      if (route.name === routeName) {
        return route;
      }
      if (route.childRoutes && route.childRoutes.length) {
        const foundRoute = this.findRouteInArray(route.childRoutes, routeName);
        if (foundRoute) {
          return foundRoute;
        }
      }
    }
  }

  private findRouteByName(name: Pages): IRoute | undefined {
    const route = this.findRouteInArray(this.schema, name);
    if (route) {
      return route;
    }
    return undefined;
  }

  public get getSchema() {
    return this.schema;
  }

  public getSchemaItem(name: Pages): IRoute | undefined {
    const route = this.findRouteByName(name);

    if (route && route.path) {
      return route;
    }
  }

  public getLink(name?: Pages): string {
    const route = name && this.findRouteByName(name);
    if (route && route.path) {
      return route.path;
    } else {
      return '/error';
    }
  }
  public getName(path: string): Pages | false {
    const route = this.findRouteByPath(path);

    if (route && route.path) {
      return route.name;
    } else {
      return false;
    }
  }
}

export default new RoutingSchema();
