import React, {} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import moment, { Moment } from 'moment';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { additionalTime } from '../config';
import { IAdditionalTime } from 'components/Windows/model.d';
import ActionsListItem from '../Actions/List/ActionsListItem';
import { PickerConsumer } from '../../Contexts';
import uuid from 'utils/uuid';
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

interface IState {
  date?: Moment;
  selectedTerm: IAdditionalTime | undefined;
  isPickerShow: boolean
}

interface IActionBlockProps {
  date?: Moment; // default: tomorrow date, when this props was changed we need to update inside values
  onChange?: (date: Moment) => void; // when some inside was changed
  submit?: (date: Moment) => void; // when clicked on 'Enter' button or selected from the list
}

class ReminderDatePicker extends React.Component<IActionBlockProps, IState> {
  // Constructor
  constructor(props: IActionBlockProps) {
    super(props);
    const custom = additionalTime.find((time) => time.period === 'custom');
    this.state = {
      date: this.props.date ? moment(this.props.date) : moment().add(1, 'day').set({hour:8,minute:0,second:0,millisecond:0}),
      selectedTerm: custom,
      isPickerShow: false,
    };
    document.addEventListener('keypress', this.handleButtonKeyPress);
    document.addEventListener('keyup', this.handleButtonKeyUp);
    document.addEventListener('keydown', this.handleButtonKeyDown);
  }

  selectedItem = React.createRef<HTMLDivElement>();
  scrollbar = React.createRef<Scrollbars>();

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleButtonKeyDown);
    document.removeEventListener('keyup', this.handleButtonKeyUp);
    document.removeEventListener('keypress', this.handleButtonKeyPress);
  }

  // Keyboard functionality
  private selectNext = () => {
    const { selectedTerm } = this.state;
    const maxLength = additionalTime.length;
    const currentIndex = additionalTime.findIndex(
      (item) => item.id === selectedTerm?.id,
    );
    const nextIndex =
      currentIndex === maxLength - 1 ? currentIndex : currentIndex + 1;

    this.setState({
      selectedTerm: additionalTime[nextIndex],
    });
  };

  private selectPrev = () => {
    const { selectedTerm } = this.state;
    const minLength = 0;
    const currentIndex = additionalTime.findIndex(
      (item) => item.id === selectedTerm?.id,
    );
    const prevIndex = currentIndex <= minLength ? minLength : currentIndex - 1;
    this.setState({
      selectedTerm: additionalTime[prevIndex],
    });
  };

  private handleButtonKeyPress = (e: KeyboardEvent) => {
    if (
      e.code === 'Enter' ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowRight' ||
      e.code === 'ArrowLeft'
    ) {
      e.preventDefault();
    }
  };

  private handleButtonKeyUp = (e: KeyboardEvent) => {
    if (
      e.code === 'Enter' ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowRight' ||
      e.code === 'ArrowLeft'
    ) {
      e.preventDefault();
    }
  };
  private handleButtonKeyDown = (e: KeyboardEvent) => {
    const { selectedTerm } = this.state;
    if (
      e.code === 'Enter' ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowRight' ||
      e.code === 'ArrowLeft'
    ) {
      e.preventDefault();
    }
    if (e.code === 'ArrowUp') {
      this.selectPrev();
    }
    if (e.code === 'ArrowDown') {
      this.selectNext();
    }
    if (e.code === 'Enter') {
      const time = additionalTime.find((date) => selectedTerm?.id === date.id);
      const term = time?.term;
      const period = time?.period;
      if (time && term && (period === 'week' || period === 'month')) {
        this.props.submit?.(moment().add(term, period));
      } else {
        if (this.state.date) {
          this.props.submit?.(this.state.date);
        }
      }
    }
    e.preventDefault()
  };

  // Changing date and time
  private clickItem = (selectedTerm: IAdditionalTime) => {
    if (selectedTerm) {
      const time = additionalTime.find((date) => selectedTerm?.id === date.id);
      if (
        time &&
        this.props.submit &&
        time.term &&
        (time.period === 'week' || time.period === 'month')
      ) {
        this.props.submit(moment().add(time.term, time.period));
      } else {
        if (this.state.date) {
          this.props.submit?.(this.state.date);
        }
      }
    }
  };

  // Use effects for date and time from props
  componentDidUpdate(
    prevProps: Readonly<IActionBlockProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ) {
    const { onChange } = this.props;
    const { date } = this.state;
    if (onChange) {
      if (this.props.date !== prevProps.date && this.props.date !== date) {
        this.setState({
          date: this.props.date,
        });
      }
    }
    if (this.scrollbar.current && this.selectedItem.current) {
      const scrollTop = this.scrollbar.current?.getScrollTop();
      const scrollHeight = this.scrollbar.current?.getClientHeight();
      const itemTop = this.selectedItem.current?.offsetTop;
      const itemHeight = this.selectedItem.current?.offsetHeight;

      if (itemTop < scrollTop) {
        this.scrollbar.current?.scrollTop(itemTop - 5);
      }
      if (itemTop + itemHeight > scrollTop + scrollHeight) {
        this.scrollbar.current?.scrollTop(
          itemTop + itemHeight - scrollHeight + 5,
        );
      }
    }
  }
  render() {
    const { isPickerShow } = this.state;
    const custom = additionalTime.find((time) => time.period === 'custom');
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Scrollbars
          autoHide
          renderThumbVertical={(props) => (
            <div {...props} className="page-scrollbar-y" />
          )}
          ref={this.scrollbar}
          className="actions-list__scrollbars">
          <PickerConsumer>
            {({ openPicker, closePicker }) => (
              <div className="note-creator__date-picker">
                {custom && <>
                  <ActionsListItem
                    key={custom.id}
                    index={custom.id}
                    isSelected={this.state.selectedTerm?.id === custom.id}
                    action={{
                      key: String(custom.id),
                      uuid: uuid(),
                      title: `Custom date & time`,
                      entityTitle: `Custom date & time`,
                    }}
                    onClick={() => {
                      this.setState({isPickerShow: true})
                      openPicker('date-time')
                    }}
                    ref={
                      this.state.selectedTerm?.id === custom.id
                        ? this.selectedItem
                        : null
                    }
                  />
                  <DateTimePicker
                    value={this.state.date ? this.state.date.toDate() : null}
                    format="yyyy/MM/dd hh:mm a"
                    open={isPickerShow}
                    className={"datepicker"}
                    onChange={(date: MaterialUiPickersDate) => {
                      const momentDate = moment(date);
                      this.setState((state) => {
                        state.date?.set({
                          date: momentDate.get('date'),
                          month: momentDate.get('month'),
                          year: momentDate.get('year'),
                        });
                      });
                      this.props.onChange?.(momentDate);
                    }}
                    onOpen={() => {
                      openPicker('date-time')
                    }}
                    onClose={() => {
                      closePicker('date-time');
                      this.setState({isPickerShow: false})
                    }}
                    onAccept={(date) => {
                      if(date){
                        this.props.submit?.(moment(date))
                      }
                    }}
                  />
                  </>}
                {additionalTime
                  .filter((time) => time.period !== 'custom')
                  .map((item, index) => (
                    <ActionsListItem
                      key={item.id}
                      index={index}
                      isSelected={this.state.selectedTerm?.id === item.id}
                      action={{
                        key: String(item.id),
                        uuid: uuid(),
                        title: `In ${item.term} ${
                          item.period === 'month' ? 'Month' : 'Week'
                        } `,
                        entityTitle: `In ${item.term} ${
                          item.period === 'month' ? 'Month' : 'Week'
                        } `,
                      }}
                      onClick={() => this.clickItem(item)}
                      ref={
                        this.state.selectedTerm?.id === item.id
                          ? this.selectedItem
                          : null
                      }
                    />
                  ))}
              </div>
            )}
          </PickerConsumer>
        </Scrollbars>
      </MuiPickersUtilsProvider>
    );
  }
}

// ReminderDatePicker.contextType = PickerConsumer

export default ReminderDatePicker;
