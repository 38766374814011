import { useState } from "react";
import { IControlEditor, IReminderEditor, ITagEditor, ReminderEditorStepEnum } from "../../model.d";
import uuid from "../../../../utils/uuid";

export enum EditorType {
  control = "Control",
  reminder = "Remind",
  tag = "Tag"
}

export const useEditors = <T extends IReminderEditor | ITagEditor | IControlEditor>(
  type: EditorType,
  openControl?: (searchValue?: string) => void,
): IUseEditors<T> => {
  const [editors, setEditors] = useState<T[]>([]);
  const newEditor = (searchValue?: string) => {
    if(type === EditorType.reminder) return {
      id: uuid(),
      step: ReminderEditorStepEnum.role,
      error: [],
    }
    if(type === EditorType.tag) return {
      id: uuid(),
      error: [],
    }
    if(type === EditorType.control) return {
      id: uuid(),
      searchValue: searchValue || "",
    }
  }
  return {
    set: (items: T[]) => setEditors(items),
    create: (searchValue?: string) => setEditors([
      ...editors as T[],
      newEditor(searchValue) as T
    ]),
    update: (editor: T) => setEditors(
      editors.map((e) => (e.id === editor.id ? editor : e))
    ),
    close: (id: string | number) => {
      setEditors(editors.filter((e) => e.id !== id));
      openControl?.(type);
    },
    items: editors
  };
};

export interface IUseEditors<T> {
  set: (items: T[]) => void;
  create: (searchValue?:string) => void;
  update: (editor: T) => void;
  close: (id: string | number) => void;
  items: T[];
}
