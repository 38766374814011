import { appName } from 'config';
import { createAsyncAction } from 'typesafe-actions';

import {
  CardCreateRequest,
  CardCreateResponse,
  CardDeleteRequest,
  CardDeleteResponse, CardDTO,
  CardEntityConnectDTO,
  CardGetListRequest,
  CardGetListResponse,
  CardGetRequest,
  CardGetResponse,
  CardUpdateRequest,
  CardUpdateResponse,
  ReminderToggleRequest,
  ReminderToggleResponse
} from "@ternala/voltore-types";

import { IException } from '../store';
import { CardFilterEnum } from './models';

/* Actions */
export const widgetName = 'card';

// ** Action
export const getCardsAction = createAsyncAction(
  `${appName}/${widgetName}/GET_CARDS_REQUEST`,
  `${appName}/${widgetName}/GET_CARDS_SUCCESS`,
  `${appName}/${widgetName}/GET_CARDS_FILED`,
)<
  CardGetListRequest & { callback?: Function; type?: CardFilterEnum },
  {
    response: CardGetListResponse;
    searchParams: CardGetListRequest & { type?: CardFilterEnum };
    isAll: boolean;
  },
  IException
>();

export const createCardAction = createAsyncAction(
  `${appName}/${widgetName}/CREATE_CARD_REQUEST`,
  `${appName}/${widgetName}/CREATE_CARD_SUCCESS`,
  `${appName}/${widgetName}/CREATE_CARD_FILED`,
)<
  CardCreateRequest & { callback?: Function },
  CardCreateResponse,
  IException
>();

export const getCardAction = createAsyncAction(
  `${appName}/${widgetName}/GET_CARD_REQUEST`,
  `${appName}/${widgetName}/GET_CARD_SUCCESS`,
  `${appName}/${widgetName}/GET_CARD_FILED`,
)<CardGetRequest & { callback?: Function }, CardGetResponse, IException>();

export const updateCardAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_CARD_REQUEST`,
  `${appName}/${widgetName}/UPDATE_CARD_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_CARD_FILED`,
)<
  CardUpdateRequest & { callback?: Function },
  CardUpdateResponse & { updateCardId: number },
  IException
>();

export const deleteCardAction = createAsyncAction(
  `${appName}/${widgetName}/DELETE_CARD_REQUEST`,
  `${appName}/${widgetName}/DELETE_CARD_SUCCESS`,
  `${appName}/${widgetName}/DELETE_CARD_FILED`,
)<
  CardDeleteRequest & {
    callback?: Function;
    tag?: CardEntityConnectDTO | undefined;
  },
  CardDeleteResponse & { id?: number, card?: CardDTO },
  IException
>();

export const toggleReminderAction = createAsyncAction(
  `${appName}/${widgetName}/TOGGLE_REMINDER_REQUEST`,
  `${appName}/${widgetName}/TOGGLE_REMINDER_SUCCESS`,
  `${appName}/${widgetName}/TOGGLE_REMINDER_FILED`,
)<
  ReminderToggleRequest & { callback?: Function },
  ReminderToggleResponse & { id?: number },
  IException
>();
