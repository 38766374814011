/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useRef, RefObject, ChangeEvent } from 'react'
import { getUniqueId } from 'utils/helper-functions'
import { Color } from './shared/styles'
import style from './shared/styles/control.module.scss'

interface Props {
   label?: string
   name?: string
   placeholder?: string
   value?: string
   onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
   disabled?: boolean
}

export const TextArea: FC<Props> = ({ label, placeholder, disabled, ...props }) => {
   const textareaRef = useRef() as RefObject<HTMLTextAreaElement>
   const textAreaId = `textarea-${getUniqueId()}`

   const onFocus = () => {
      if (textareaRef.current) {
         textareaRef.current.placeholder = ''
      }
   }

   const onBlur = () => {
      if (textareaRef.current && placeholder) {
         textareaRef.current.placeholder = placeholder
      }
   }

   return (
      <div className={style.container}>
         <label
            htmlFor={textAreaId}
            className={style.label}
            style={{ color: disabled ? Color.disabled : Color.label }}
         >
            { label }
         </label>

         <textarea
            id={textAreaId}
            ref={textareaRef}
            rows={6}
            style={{ resize: 'none' }}
            className={style.input}
            name={props.name}
            placeholder={placeholder || ""}
            value={props.value}
            onChange={props.onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
         />
      </div>
   )
}

export default TextArea