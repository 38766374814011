// packages from @ternala
import { Config } from "../../../config/api";
import { authHeader, handleErrors } from "../../../utils/API";

import {
  SaleTransactionGetRequest,
  SaleTransactionDeleteRequest,
  SaleTransactionGetListRequest,
  SaleTransactionCreateResponse,
  SaleTransactionGetResponse,
  SaleTransactionUpdateResponse,
  SaleTransactionDeleteResponse,
  SaleTransactionGetListResponse,
  SaleTransactionStatusHistoryFullDTO,
  UpdateSaleStatusDTO,
  UpdateActiveSaleStatusDTO,
  SaleTransactionFullExpandDTO,
  SaleTransactionShortExpandDTO,
  SaleTransactionGetLastByPropertyRequest
} from "@ternala/voltore-types";
import { SaleTransactionCreateRequestExpanded, SaleTransactionUpdateRequestExpanded } from "../models";

import { appendSearchParams } from "../../../utils/appendSearchParams";
import {IError} from "../../model";

class API {
  public async getSaleTransactions(getSalesData: SaleTransactionGetListRequest, token: string): Promise<SaleTransactionGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "sale-transaction/list")

    url = appendSearchParams(url, getSalesData);
    if(getSalesData.hasOwnProperty('property')) url.searchParams.append('property', String(getSalesData.property))
    if(getSalesData.hasOwnProperty('status')) url.searchParams.append('status', String(getSalesData.status))

    return handleErrors(
      fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        }
      })
    );
  }

  public async createSaleTransaction(createSaleData: SaleTransactionCreateRequestExpanded, token: string): Promise<SaleTransactionCreateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "sale-transaction/create")

    const data = new FormData();

    Object.entries(createSaleData).forEach(([key, val]) => {
      if(Array.isArray(val)){
        val.forEach(singleValue => {
          data.append(`${key}[]`, singleValue);
        })
      } else {
        data.append(key, val);
      }
    });

    return handleErrors(
      fetch(url.toString(), {
        method: "POST",
        headers: {
          ...authHeader(token)
        },
        body: data
      })
    );
  }

  public async getSaleTransaction(getSaleData: SaleTransactionGetRequest, token: string): Promise<SaleTransactionGetResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "sale-transaction/get")

    url.searchParams.append('id', String(getSaleData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: "GET",
        headers: {
          ...authHeader(token)
        }
      })
    );
  }

  public async updateSaleTransaction(updateSaleData: SaleTransactionUpdateRequestExpanded, token: string): Promise<SaleTransactionUpdateResponse | string | IError> {
    const url = new URL(Config.MAIN_SERVICE_ENDPOINT + "sale-transaction/update")

    const data = new FormData();

    Object.entries(updateSaleData).forEach(([key, val]) => {
      if(Array.isArray(val)){
        val.forEach(singleValue => {
          data.append(`${key}[]`, singleValue);
        })
      } else {
        data.append(key, val);
      }
    });

    return handleErrors(
      fetch(url.toString(), {
        method: "PUT",
        headers: {
          ...authHeader(token)
        },
        body: data
      })
    );
  }

  public async updateSaleTransactionStatus(updateSaleData: UpdateSaleStatusDTO, token: string): Promise<SaleTransactionStatusHistoryFullDTO | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "sale-transaction/status-update")

    return handleErrors(
      fetch(url.toString(), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        },
        body: JSON.stringify(updateSaleData)
      })
    );
  }

  public async updateSaleTransactionActiveStatus(updateSaleData: UpdateActiveSaleStatusDTO, token: string): Promise<SaleTransactionStatusHistoryFullDTO | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "sale-transaction/active-status-update")

    return handleErrors(
      fetch(url.toString(), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        },
        body: JSON.stringify(updateSaleData)
      })
    );
  }

  public async deleteSaleTransaction(deleteSaleData: SaleTransactionDeleteRequest, token: string): Promise<SaleTransactionDeleteResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "sale-transaction/delete")

    url.searchParams.append('id', String(deleteSaleData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: "DELETE",
        headers: {
          // "Content-Type": "application/json",
          ...authHeader(token)
        }
      })
    );
  }

  public async getLastSaleTransaction(id: number, token?: string): Promise<SaleTransactionShortExpandDTO | undefined> {
    const url = new URL(Config.MAIN_SERVICE_ENDPOINT + "sale-transaction/list")

    if (!token) {
      return;
    }

    if (isNaN(id)) {
      return;
    }

    url.searchParams.append("property", String(id));
    url.searchParams.append("limit", "1");
    url.searchParams.append("offset", "0");
    url.searchParams.append("sortType", "DESC");

    try {
      const response = await fetch(String(url), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(token)
        }
      });
      const data: SaleTransactionGetListResponse = await response.json();
      if (response.statusText === 'OK' || response.status === 200) {
        return data.items[0];
      }
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  public async getSaleTransactionLastByStatusHistory(getSaleData: SaleTransactionGetLastByPropertyRequest, token: string): Promise<SaleTransactionFullExpandDTO | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + "sale-transaction/get/last")

    url.searchParams.append('property', String(getSaleData.property));

    return handleErrors(
      fetch(url.toString(), {
        method: "GET",
        headers: {
          ...authHeader(token)
        }
      })
    );
  }
}
export const SaleApi = new API();
