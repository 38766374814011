import React, { FC, useState, useEffect, ChangeEvent, useRef } from "react";
import FormattedInput from "react-number-format";

import Radio from "components/UI/controls/Radio";

/* constants */
import { SQFT_IN_ACRE, Unit } from "config/constants";
import { getUniqueId } from "utils/helper-functions";

/* styles */
import { Color } from "./shared/styles";
import style from "./shared/styles/control.module.scss";

interface Props {
  width?: number | "50%" | "100%";
  className?: string;
  name?: string;
  label?: string;
  unit?: string;
  units?: Array<{ type: Unit; label: string }>;
  sqft?: number;
  max?: number;
  isYearValue?: boolean;
  thousandSeparator?: boolean;
  fixedDecimalScale?: boolean;
  decimalScale?: number;

  placeholder?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  darkTheme?: boolean;
  readonly?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  upperCaseLabel: boolean;
  onError?: (error: boolean, message?: string) => void;
}

export const InputNumber: FC<Props> = (props) => {
  const {
    width,
    label,
    unit,
    units,
    sqft,
    max,
    isYearValue,
    thousandSeparator,
    fixedDecimalScale,
    decimalScale,
    readonly,
    disabled,
    required,
    darkTheme,
    upperCaseLabel,
    onChange,
    onError,
    defaultValue
  } = props;
  const isInitialMount = useRef(true);
  const prepareValue = (value: string): string => {
    const numberValue = Number(String(value).replace(/,/g, ""));
    if (value === "") {
      return "";
    }

    switch (selectedUnit) {
      case Unit.SQFT:
        return String(Math.ceil(numberValue));
      case Unit.ACRES:
        return String(numberValue / SQFT_IN_ACRE);
      case Unit.PRICE:
        return value || "";
      case Unit.PRICE_PER_SQFT:
        return String(Number(numberValue) / Number(sqft));
      case Unit.MONTHLY_LEASE_RATE:
        return String(numberValue);
      case Unit.ANNUAL_LEASE_RATE:
        return String(numberValue * 12);
    }
    return value;
  };

  const transformValue = (value: string): string => {
    const numberValue = Number(String(value).replace(/,/g, ""));
    if (value === "") {
      return "";
    }

    switch (selectedUnit) {
      case Unit.SQFT:
        return String(Math.ceil(numberValue * SQFT_IN_ACRE));
      case Unit.ACRES:
        return String(numberValue / SQFT_IN_ACRE);
      case Unit.PRICE:
        return value || "";
      case Unit.PRICE_PER_SQFT:
        return String(Number(numberValue) / Number(sqft));
      case Unit.MONTHLY_LEASE_RATE:
        return String(numberValue / 12);
      case Unit.ANNUAL_LEASE_RATE:
        return String(numberValue * 12);
    }
    return value;
  };

  const backValue = (value: string): string => {
    const numberValue = Number(String(value).replace(/,/g, ""));
    if (value === "") {
      return "";
    }

    console.log('value: ', value)
    console.log('selectedUnit: ', selectedUnit)

    switch (selectedUnit) {
      case Unit.SQFT:
        return String(Math.ceil(numberValue));
      case Unit.ACRES:
        return String(numberValue * SQFT_IN_ACRE);
      case Unit.PRICE:
        return String(numberValue) || "";
      case Unit.PRICE_PER_SQFT:
        return String(Number(numberValue) * Number(sqft));
      case Unit.MONTHLY_LEASE_RATE:
        return String(numberValue);
      case Unit.ANNUAL_LEASE_RATE:
        return String(numberValue / 12);
    }
    return String(numberValue);
  };

  if (unit && units) {
    throw new Error(
      "only single measurement unit OR array of units can be specified."
    );
  }
  if (
    (units && sqft && !JSON.stringify(units).includes("sqft")) ||
    (!units && sqft)
  ) {
    throw new Error(
      "useless prop: \"propertySqft. Pass this prop only if calculation of price/sqft is required.\""
    );
  }

  const [selectedUnit, setSelectedUnit] = useState<string>(
    (units && units[0].type) || ""
  );
  const [value, setValue] = useState<string>(prepareValue(defaultValue || ""));

  const [placeholder, setPlaceholder] = useState<string>(
    props.placeholder || ""
  );
  const [error, setError] = useState<string | undefined>(props.error);

  useEffect(() => {
    console.log('change defaultValue');
    if (defaultValue) {
      setValue(prepareValue(defaultValue || ""));
    }
  }, [defaultValue]);

  useEffect(() => {
    console.log('transform');
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setValue(transformValue(value));
    }
  }, [selectedUnit]);

  const inputId = `numeric-input-${getUniqueId()}`;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value.startsWith(".")) {
      setValue("");
      return;
    }

    setValue(value);

    const newValue = backValue(value);
    console.log({newValue});
    console.log('Number(newValue): ', Number(newValue));
    onChange(Number(newValue) as unknown as string);

    // switch (selectedUnit) {
    //   case Unit.ACRES:
    //     onChange(String(Math.ceil(numberValue * SQFT_IN_ACRE)));
    //     break;
    //   case Unit.PRICE_PER_SQFT:
    //     onChange(String(numberValue * Number(sqft)));
    //     break;
    //   case Unit.ANNUAL_LEASE_RATE:
    //     onChange(String(numberValue / 12));
    //     break;
    //   case Unit.SQFT:
    //   case Unit.PRICE:
    //   case Unit.MONTHLY_LEASE_RATE:
    //   default:
    //     onChange(value.replace(/,/g, ""));
    // }
  };

  const validateYear = (year: string = value) => {
    setError("");
    onError && onError(false);
    if (year.length < 4) {
      setError("4 digits required");
      onError && onError(true, "year is invalid");
    }
  };

  return (
    <div
      className={style.container}
      style={{ minWidth: width, maxWidth: width }}>
      {label && (
        <label
          htmlFor={inputId}
          className={darkTheme ? style.label_dark : style.label}
          style={{
            color: disabled
              ? Color.disabled
              : error
                ? Color.error
                : Color.label
          }}>
          {label}
          {required && <span className={style.required}>*</span>}
        </label>
      )}

      {units && Array.isArray(units) && (
        <div className={style.units} style={{ marginTop: label ? 15 : "" }}>
          {units.map((unit) => (
            <Radio
              key={unit.label}
              label={unit.label}
              value={unit.type}
              checked={selectedUnit === unit.type}
              onChange={(e) => setSelectedUnit(e.target.value)}
              darkTheme={darkTheme}
              upperCaseLabel={upperCaseLabel}
            />
          ))}
        </div>
      )}

      <FormattedInput
        id={inputId}
        className={darkTheme ? style.input_dark : style.input}
        style={{ borderColor: error && Color.error }}
        placeholder={placeholder}
        name={props.name}
        value={value}
        onChange={handleChange}
        onFocus={() => setPlaceholder("")}
        onBlur={() => {
          setPlaceholder(props.placeholder || "");
          if (isYearValue) validateYear();
        }}
        disabled={disabled}
        readOnly={readonly}
        thousandSeparator={isYearValue ? false : thousandSeparator}
        decimalScale={isYearValue ? 0 : decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          if (max) {
            return floatValue ? floatValue <= max : formattedValue === "";
          }
          return floatValue ? floatValue > 0 : formattedValue === "";
        }}
      />
      {unit && (
        <span className={darkTheme ? style.unit_dark : style.unit}>{unit}</span>
      )}
      {units && (
        <span className={darkTheme ? style.unit_dark : style.unit}>
          {selectedUnit}
        </span>
      )}
      {typeof error === "string" && (
        <span className={style.error}>{error}</span>
      )}
    </div>
  );
};

InputNumber.defaultProps = {
  thousandSeparator: true,
  fixedDecimalScale: false,
  decimalScale: 6,
  readonly: false,
  disabled: false,
  required: false
};

export default InputNumber;
