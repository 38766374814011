import React, { FC } from 'react';
import Link from 'components/UI/controls/Link';

/* constants */
import { PageEnum } from 'config/constants';
import { propertiesTabs } from 'config/constants/tabs';

/* types */
import { PropertyShortDTO } from '@ternala/voltore-types';
import { formatAddress } from 'utils/helper-functions';
import { EntityEnum } from "@ternala/voltore-types/lib/constants";

interface Props {
  properties?: PropertyShortDTO[];
  title: string;
}

const PropertiesInTheSameGroup: FC<Props> = ({ properties, title }) => (
  <div className="properties-in-the-same-group">
    <h4>{title}</h4>

    {properties
      ?.filter(function (property, pos) {
        return properties.indexOf(property) === pos;
      })
      ?.map((property) => (
        <div className="properties-in-the-same-group__item" key={property.id}>
          <Link
            key={property.id}
            id={property.id}
            type={EntityEnum.property}
            fullPath={`/${PageEnum.PROPERTIES}/${property.id}/${propertiesTabs.property.path}`}
            containerClassName={'properties-in-the-same-group__item__link'}
            newTabIcon>
            {formatAddress({
              address: property?.address?.address,
              city: property?.address?.city,
              state: property?.address?.state,
              zipCode: property?.address?.zipCode,
            })}
          </Link>
          {/*<div className="item__address">*/}
          {/*  {formatAddress({*/}
          {/*    address: property?.address?.address,*/}
          {/*    city: property?.address?.city,*/}
          {/*    state: property?.address?.state,*/}
          {/*    zipCode: property?.address?.zipCode,*/}
          {/*  })}*/}
          {/*</div>*/}
        </div>
      ))}
  </div>
);

export default PropertiesInTheSameGroup;
