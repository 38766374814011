import React from "react";
import { EntityEnum } from "@ternala/voltore-types/lib/constants";

import { getMainImage } from "utils/getImage";

import { EnterpriseFullExpandedDTO } from "controllers/enterprise/models";

import { BriefcaseIcon } from "components/icons/BriefcaseIcon";
import InfoMultiField from "components/UI/controls/MultiField/InfoMultiField";
import Link from "components/UI/controls/Link";
import { AddressField } from "components/views/address";

import { PageEnum } from "config/constants";
import { enterprisesTabs } from "config/constants/tabs";

interface IProps {
  enterprise: EnterpriseFullExpandedDTO;
  className?: string;
}

export const EnterpriseInfo: React.FC<IProps> = ({ enterprise, className }) => {
  return (
    <div className={'enterprise-info__content ' + className}>
      <div className={'logo content__left'}>
        {enterprise?.images?.length ? (
          <img src={getMainImage(enterprise.images)?.url} alt="" />
        ) : (
          <BriefcaseIcon className="briefcase-icon" />
        )}
      </div>

      <div className="content__right">
        <div className="system-id">
          <span className="system-id__key">System id:</span>
          <span className="system-id__value">{enterprise?.id}</span>
        </div>

        <h3 className="enterprise-name">{enterprise?.title}</h3>

        <div className="data-field">
          <div
            className={`data-field__key_lg ${
              enterprise?.description ? '' : 'no-information'
            }`}>
            Description
          </div>
          {enterprise?.description ? (
            <div className="data-field__value">
              {enterprise?.description}
            </div>
          ) : (
            <span className="no-information">-</span>
          )}
        </div>

        <InfoMultiField
          entityId={enterprise?.id}
          items={enterprise?.phones}
          label="Phone(s)"
        />
        <InfoMultiField
          entityId={enterprise.id}
          items={enterprise?.emails}
          label="Email(s)"
        />

        <div className="data-field">
          <div
            className={`data-field__key_lg ${
              enterprise?.website ? '' : 'no-information'
            }`}>
            Website
          </div>
          {enterprise?.website ? (
            <div className="data-field__value">
              <Link website>{enterprise.website}</Link>
            </div>
          ) : (
            <span className="no-information">-</span>
          )}
        </div>

        <AddressField
          label={'Office address'}
          address={enterprise?.officeAddress}
        />

        <div className="data-field">
          <div
            className={`data-field__key_lg ${
              (enterprise?.parents?.length || 0) > 0 ? '' : 'no-information'
            }`}>
            Parent enterprise
          </div>
          {(enterprise?.parents?.length || 0) > 0 ? (
            <div className="data-field__value">
              {enterprise?.parents?.map((parent: any) => (
                <Link
                  style={{ marginBottom: 5 }}
                  key={Math.random()}
                  id={parent.id}
                  type={EntityEnum.enterprise}
                  fullPath={`/${PageEnum.ENTERPRISES}/${parent.id}/${enterprisesTabs.info.path}`}
                  newTabIcon>
                  {parent.title}
                </Link>
              ))}
            </div>
          ) : (
            <span className="no-information">-</span>
          )}
        </div>
      </div>
    </div>
  );
};
