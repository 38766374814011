import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const BriefcaseIcon: FC<Props> = ({ color, ...props }) => (
   <svg
      {...props}
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M23.2607 11.6309C22.9283 11.6309 22.7068 11.8524 22.7068 12.1847V19.3845C22.7068 19.7168 22.4853 19.9383 22.153 19.9383H2.21515C1.88284 19.9383 1.66133 19.7167 1.66133 19.3845V12.1847C1.66133 11.8524 1.43978 11.6309 1.10752 11.6309C0.775264 11.6309 0.553711 11.8524 0.553711 12.1847V19.3845C0.553711 20.326 1.27368 21.0459 2.21519 21.0459H22.153C23.0945 21.0459 23.8145 20.326 23.8145 19.3845V12.1847C23.8145 11.8524 23.593 11.6309 23.2607 11.6309Z"
         fill={color}
         fillOpacity="0.5"
      />
      <path
         d="M22.707 3.87695H1.66148C0.719965 3.87695 0 4.59692 0 5.53844V9.08295C0 9.85827 0.553812 10.5229 1.27382 10.689L9.96894 12.6274V14.3997C9.96894 14.732 10.1905 14.9535 10.5228 14.9535H13.8457C14.178 14.9535 14.3995 14.7319 14.3995 14.3997V12.6274L23.0946 10.689C23.8147 10.5229 24.3685 9.85827 24.3685 9.08291V5.53839C24.3685 4.59692 23.6485 3.87695 22.707 3.87695ZM13.2919 13.8458H11.0766V11.6306H13.2919V13.8458ZM23.2608 9.08291C23.2608 9.35981 23.0946 9.58136 22.8177 9.63672L14.3995 11.5198V11.0767C14.3995 10.7444 14.178 10.5229 13.8457 10.5229H10.5228C10.1904 10.5229 9.96894 10.7444 9.96894 11.0767V11.5198L1.55073 9.63676C1.27382 9.58136 1.10767 9.35986 1.10767 9.08295V5.53844C1.10767 5.20613 1.32922 4.98462 1.66148 4.98462H22.707C23.0393 4.98462 23.2608 5.20618 23.2608 5.53844V9.08291Z"
         fill={color}
         fillOpacity="0.5"
      />
      <path
         d="M14.9527 0H9.41441C8.47289 0 7.75293 0.719965 7.75293 1.66148V2.21529C7.75293 2.5476 7.97448 2.76911 8.30674 2.76911C8.639 2.76911 8.86055 2.54755 8.86055 2.21529V1.66148C8.86055 1.32918 9.08211 1.10767 9.41436 1.10767H14.9527C15.285 1.10767 15.5065 1.32922 15.5065 1.66148V2.21529C15.5065 2.5476 15.728 2.76911 16.0603 2.76911C16.3926 2.76911 16.6141 2.54755 16.6141 2.21529V1.66148C16.6142 0.719965 15.8942 0 14.9527 0Z"
         fill={color}
         fillOpacity="0.5"
      />
   </svg>
)

BriefcaseIcon.defaultProps = {
   color: "#1F2531"
}