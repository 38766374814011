import React, { FC, useState } from 'react'
import Button from 'components/UI/controls/Button'
import ChangePasswordPopup from 'components/modals/users/ChangePassword'
import style from './InputPassword.module.scss'

interface Props {
   className?: string
   label?: string
   value?: string
   required?: boolean
   onPasswordChanged: (password: string) => void
}

export const InputPassword: FC<Props> = ({ className, label, value, required, ...props }) => {
   const [isPasswordChangePopupOpen, setIsPasswordChangePopupOpen] = useState<boolean>(false)

   const setNewPassword = (password: string) => {
      props.onPasswordChanged(password)
   }

   return <>
      {isPasswordChangePopupOpen &&
         <ChangePasswordPopup
            onClose={() => setIsPasswordChangePopupOpen(false)}
            onSubmit={setNewPassword}
         />
      }

      <div className={style.container}>
         <div className={style.label}>
            { label }
            { required && <span className={style.required}>*</span> }
         </div>

         <div className={style.input_password_wrapper}>
            <input
               type="password"
               className={`${style.input} ${className || ''}`}
               value="xxxxxxxxxxx"
               disabled
            />

            <Button
               variant="dark"
               className={style.button_edit}
               onClick={() => setIsPasswordChangePopupOpen(true)}
            >
               Edit
            </Button>
         </div>
      </div>
   </>
}

export default InputPassword