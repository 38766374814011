import { all, put, takeEvery, call } from 'redux-saga/effects';

// APIs
import { UserRoleApi } from '../transport/userRole.api';

// Actions
import {
  createUserRoleAction,
  deleteUserRoleAction,
  getUserRoleAction,
  getUserRolesAction,
  updateUserRoleAction
} from '../actions';

import { addError, addLoader, removeLoader } from '../index';

// Utils
import uuid from 'utils/uuid';

// Constants
import { LoaderAction } from 'config/constants';
import {
  UserRoleCreateResponse,
  UserRoleDeleteResponse,
  UserRoleGetListResponse,
  UserRoleGetResponse, UserRoleUpdateResponse
} from '@ternala/voltore-types';
import { IError } from '../../model';
import { getAccessTokenSaga } from '../../auth/sagas/auth';

export function* getUserRolesSaga({
                                    payload
                                  }: ReturnType<typeof getUserRolesAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, users are loading!',
      type: LoaderAction.userRole.getList
    })
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: UserRoleGetListResponse | string | IError = yield UserRoleApi.getUserRoles(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getUserRolesAction.failure({
          code: res,
          message: res || 'Something was wrong'
        });
      } else {
        yield put(
          getUserRolesAction.failure({
            code: String(res.code),
            // @ts-ignore
            message: res.message || 'Something was wrong'
          })
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get user roles!',
          type: LoaderAction.userRole.getList
        })
      );
    } else {
      yield put(
        getUserRolesAction.success({
          response: res,
          searchParams: payload,
          isAll: payload.limit ? res.items.length < payload.limit : true
        })
      );
      yield put(
        removeLoader({
          id: loadId
        })
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    console.error('error: ', error);
    yield put(
      getUserRolesAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong'
      })
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to get users!',
        type: LoaderAction.userRole.getList
      })
    );
  }
}

export function* createUserRoleSaga({
                                      payload
                                    }: ReturnType<typeof createUserRoleAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, user is creating!',
      type: LoaderAction.userRole.create
    })
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: UserRoleCreateResponse | string | IError = yield UserRoleApi.createUserRole(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        createUserRoleAction.failure({
          code: res,
          message: res || 'Something was wrong'
        });
      } else {
        yield put(
          createUserRoleAction.failure({
            // @ts-ignore
            code: String(res.code),
            // @ts-ignore
            message: res.message || 'Something was wrong'
          })
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to create user!',
          type: LoaderAction.enterprise.create
        })
      );
      if (typeof payload.callback === 'function') payload.callback(false);
    } else {
      yield put(createUserRoleAction.success(res));
      yield put(
        removeLoader({
          id: loadId
        })
      );
      if (typeof payload.callback === 'function') payload.callback(res);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      createUserRoleAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong'
      })
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to create user!',
        type: LoaderAction.userRole.create
      })
    );
  }
}

export function* getUserRoleSaga({
                                   payload
                                 }: ReturnType<typeof getUserRoleAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, user is getting!',
      type: LoaderAction.userRole.getItem
    })
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: UserRoleGetResponse | string | IError = yield UserRoleApi.getUserRole(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getUserRoleAction.failure({
          code: res,
          message: res || 'Something was wrong'
        });
      } else {
        yield put(
          getUserRoleAction.failure({
            // @ts-ignore
            code: String(res.code),
            // @ts-ignore
            message: res.message || 'Something was wrong'
          })
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get userRole!',
          type: LoaderAction.userRole.getItem
        })
      );
    } else {
      yield put(getUserRoleAction.success(res));
      yield put(
        removeLoader({
          id: loadId
        })
      );
      if (typeof payload.callback === 'function') payload.callback(true, res.id);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    console.error('error: ', error);
    yield put(
      getUserRoleAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong'
      })
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to get user!',
        type: LoaderAction.userRole.getItem
      })
    );
  }
}

export function* updateUserRoleSaga({
                                      payload
                                    }: ReturnType<typeof updateUserRoleAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, user is updating!',
      type: LoaderAction.userRole.update
    })
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: UserRoleUpdateResponse | string | IError = yield UserRoleApi.updateUserRole(data, accessToken);
    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        updateUserRoleAction.failure({
          code: res,
          message: res || 'Something was wrong'
        });
      } else {
        yield put(
          updateUserRoleAction.failure({
            // @ts-ignore
            code: String(res.code),
            // @ts-ignore
            message: res.message || 'Something was wrong'
          })
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to update userRole!',
          type: LoaderAction.userRole.update
        })
      );
      if (typeof payload.callback === 'function') payload.callback(false);
    } else {
      yield put(updateUserRoleAction.success(res));
      yield put(
        removeLoader({
          id: loadId
        })
      );
      if (typeof payload.callback === 'function') payload.callback(res);
    }

  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    console.error('error: ', error);
    yield put(
      updateUserRoleAction.failure({
        key: String(payload.id),
        code: error.code || 400,
        message: error.message || error || 'Something was wrong'
      })
    );
    yield put(
      addError({
        key: String(payload.id),
        id: loadId,
        message: 'Failed to update user!',
        type: LoaderAction.userRole.update
      })
    );
  }
}

export function* deleteUserRoleSaga({
                                      payload
                                    }: ReturnType<typeof deleteUserRoleAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, user is deleting!',
      type: LoaderAction.userRole.delete
    })
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    const res: UserRoleDeleteResponse | string = yield UserRoleApi.deleteUserRole(payload, accessToken);
    if (typeof res === "undefined" || typeof res === 'string') {
      deleteUserRoleAction.failure({
        code: res as string,
        message: res as string || 'Something was wrong'
      });
      yield put(
        addError({
          id: loadId,
          message: 'Failed to delete userRole!',
          type: LoaderAction.userRole.delete
        })
      );
    } else {
      yield put(
        deleteUserRoleAction.success({
          ...res,
          id: payload.id
        })
      );
      yield put(
        removeLoader({
          id: loadId
        })
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    console.error('error: ', error);
    yield put(
      deleteUserRoleAction.failure({
        key: String(payload.id),
        code: error.code || 403,
        message: error.message || error || 'Something was wrong'
      })
    );
    yield put(
      addError({
        key: String(payload.id),
        id: loadId,
        message: 'Failed to delete user!',
        type: LoaderAction.userRole.delete
      })
    );
  }
}

export function* userRoleActionSaga() {
  yield all([
    takeEvery(getUserRolesAction.request, getUserRolesSaga),
    takeEvery(getUserRoleAction.request, getUserRoleSaga),
    takeEvery(createUserRoleAction.request, createUserRoleSaga),
    takeEvery(updateUserRoleAction.request, updateUserRoleSaga),
    takeEvery(deleteUserRoleAction.request, deleteUserRoleSaga)
  ]);
}
