import React, { FC } from 'react';
import { TagShortDTO, TagUpdateRequest } from '@ternala/voltore-types';
import { NoteIcon } from 'components/icons/NoteIcon';
import { TrashIconAlt } from 'components/icons/TrashIconAlt';
import TagItem from 'components/Tags/Controls/TagItem';

interface Props {
  tag: TagShortDTO;
  setIsEditTagOpen: (tag: TagUpdateRequest) => void;
  deleteTag: (id: number) => void;
  groupId: number;
}

const ListItem: FC<Props> = ({ deleteTag, setIsEditTagOpen, tag, groupId }) => {
  return (
    <div className="list-row-item">
      <div className="list-row-item-content">
        <div className="list-row">
          <TagItem tag={tag} />
        </div>
        <div className="list-row-controls">
          <div
            className="list-row-controls-edit"
            onClick={() =>
              setIsEditTagOpen({
                category: groupId,
                color:
                  tag.color.charAt(0) !== '#' ? `#${tag.color}` : tag.color,
                id: tag.id,
                title: tag.title,
              })
            }>
            <NoteIcon
              width={10}
              height={10}
              style={{ marginBottom: 0 }}
              color={'#ffffff'}
            />
            <span>edit</span>
          </div>
          <div
            className="list-row-controls-delete"
            onClick={() => deleteTag(tag.id)}>
            <TrashIconAlt fill={'#1F2531'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
