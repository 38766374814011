import React, { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import {
  TaxonomyDTO,
  TaxonomyTypeShortDTO,
} from '@ternala/voltore-types';
import { TaxonomyTypeSlug } from '@ternala/voltore-types/lib/constants';

/* UI components */
import Loader from 'components/Loader';
import ListItem from './Controls/ListItem';
import { GoBackIcon } from 'components/icons/GoBackIcon';
import { DownArrowIcon } from 'components/icons/DownArrowIcon';
import {taxonomyModalKey} from 'components/modals/TaxonomyModal';
import PopupProceed from 'components/modals/PopupProceed';
import CustomScrollbars from 'components/CustomScrollbars';

/* constants */
import { PageEnum } from 'config/constants';


/* types */
import { ITaxonomyState } from 'controllers/taxonomy/models';
import {
  deleteTaxonomyAction,
} from 'controllers/taxonomy/actions';
import { addNotification } from 'controllers/modals/actions';
import { NotificationTypeEnum } from 'controllers/modals/models.d';
import {addShowedElement} from "controllers/showElement/actions";
import {IModalElement, ShowElementEnum} from "controllers/showElement/models.d";
import { IStore } from "controllers/store";

// Utils
import uuid from 'utils/uuid';

interface Props extends RouteComponentProps<{ id?: string }> {
  taxonomyState: ITaxonomyState;
}

const TaxonomyType: FC<Props> = (props) => {
  const {
    match: {
      params: { id },
    },
    taxonomyState: { taxonomies, taxonomyTypes },
  } = props;

  const dispatch = useDispatch();

  // taxonomy type (main type)
  const [taxonomyType, setTaxonomyType] = useState<
    TaxonomyTypeShortDTO | undefined
  >(taxonomyTypes?.find((taxonomy) => Number(taxonomy.id) === Number(id)));

  // taxonomies (taxonomies from type)
  const [taxonomy, setTaxonomy] = useState<TaxonomyDTO[] | undefined>(
    taxonomyType
      ? taxonomies?.[taxonomyType?.slug as TaxonomyTypeSlug]
      : undefined,
  );

  useEffect(() => {
    setTaxonomyType(
      taxonomyTypes?.find((taxonomy) => Number(taxonomy.id) === Number(id)),
    );
    if (taxonomyType)
      setTaxonomy(taxonomies?.[taxonomyType?.slug as TaxonomyTypeSlug]);
  }, [taxonomyTypes, taxonomies]);

  // actions (taxonomies)
  const [deleteTaxonomy, setDeleteTaxonomy] = useState<number | undefined>(
    undefined,
  );

  return (
    <>
      <Helmet>
        <title>
          {taxonomyType?.title ? taxonomyType?.title : 'Taxonomy'} | Voltore
          {process.env.REACT_APP_COMPANY_NAME
            ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
            : ''}
        </title>
        <meta name="description" content="Voltore application" />
      </Helmet>
      {/*<div>This is my taxonomy page</div>*/}
      {taxonomyType && taxonomy ? (
        <CustomScrollbars id="taxonomy-info-container">
          <div id="tag-info">
            <div
              className="button-go-back"
              onClick={() => dispatch(push(`/${PageEnum.TAXONOMY}`))}
              title="Back to tags">
              <GoBackIcon />
            </div>
            <div className="group-header">
              <span className="group-title">{taxonomyType.title}</span>
            </div>
            <div className="header">
              <div className="header-title-container">
                <span className="title">Name</span>
                <DownArrowIcon />
              </div>
              <div className="add-new" onClick={() => {
                dispatch(
                    addShowedElement({
                      id: uuid(),
                      key: taxonomyModalKey,
                      type: ShowElementEnum.modal,
                      props: {
                        type: taxonomyType?.slug
                      },
                    } as IModalElement),
                );
              }}>
                <span>add new</span>
              </div>
            </div>

            <div className="tag-info__content">
              {taxonomy.length ? (
                taxonomy.map((taxonomy) => (
                  <ListItem
                    taxonomy={taxonomy}
                    editTaxonomy={(value) => {
                      dispatch(
                          addShowedElement({
                            id: uuid(),
                            key: taxonomyModalKey,
                            type: ShowElementEnum.modal,
                            props: {
                              type: taxonomyType?.slug,
                              id: value.id
                            },
                          } as IModalElement),
                      );
                    }}
                    deleteTaxonomy={(value) => setDeleteTaxonomy(value)}
                  />
                ))
              ) : (
                <span
                  className="no-items-specified"
                  style={{
                    marginLeft: ' 0px',
                    marginBottom: ' 0px',
                    marginTop: '15px',
                    marginRight: '0px',
                  }}>
                  No taxonomies are specified.
                </span>
              )}
              {}
            </div>
          </div>
        </CustomScrollbars>
      ) : (
        <div
          className="item-info__loader_info-tab"
          style={{ marginTop: '17px' }}>
          <Loader />
        </div>
      )}

      {deleteTaxonomy && taxonomyType ? (
        <PopupProceed
          entityId={deleteTaxonomy}
          entityType={'taxonomy'}
          setCreateEntity={(callback) => {
            dispatch(
              addShowedElement({
                id: uuid(),
                key: taxonomyModalKey,
                type: ShowElementEnum.modal,
                callback: (res: false | TaxonomyDTO) => {
                  callback?.(res)
                },
                props: {
                  type: taxonomyType?.slug,
                },
              } as IModalElement),
            );
          }}
          onClose={() => {
            setDeleteTaxonomy(undefined);
          }}
          type={taxonomyType?.slug as TaxonomyTypeSlug}
          onSubmit={(id, newTaxonomyId, callback) =>
            dispatch(
              deleteTaxonomyAction.request({
                id,
                newTaxonomyId,
                callback: (status: boolean) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Successfully deleted',
                        type: NotificationTypeEnum.success,
                        title: 'Successfully deleted',
                      }),
                    );
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (callback) callback(status);
                },
              }),
            )
          }
        />
      ) : (
        ''
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  taxonomyState: store.taxonomy,
}))(TaxonomyType);
