import { CardEntityEnum } from '@ternala/voltore-types/lib/constants';
import {
  CompositeDecorator,
  convertToRaw,
  EditorState,
  Modifier,
} from 'draft-js';
import { noteDecorators } from '../Draft/Decorators';
import {
  propertyToAction,
  IMentionData,
  enterpriseToAction,
  personToAction,
} from '@ternala/voltore-types/lib/card';
import { IDefaultStateData } from './index';

export const insertMention = (
  { id, title, data, entityType, involvementId, transactionId }: IMentionData,
  editorState: EditorState,
) => {
  const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection();

  const currentBlockKey = editorState.getSelection().getStartKey();
  const carriagePosition = editorState.getSelection().getFocusOffset();

  const currentText = currentContent.getBlockForKey(currentBlockKey).getText();

  const prevCarriageText = currentText.slice(0, carriagePosition);

  let targetSymbolPosition = prevCarriageText.indexOf('@');
  if (targetSymbolPosition < 0) targetSymbolPosition = 0;

  const newSelection = currentSelection.merge({
    anchorOffset: targetSymbolPosition,
    focusOffset: carriagePosition,
  });

  let stateWithEntity = editorState
    .getCurrentContent()
    .createEntity('MENTION', 'IMMUTABLE', {
      title,
      id,
      involvementId,
      transactionId,
      entityType,
    });

  const entityKey = stateWithEntity.getLastCreatedEntityKey();

  stateWithEntity = Modifier.replaceText(
    stateWithEntity,
    newSelection,
    title,
    undefined,
    entityKey,
  );

  stateWithEntity = Modifier.insertText(
    stateWithEntity,
    stateWithEntity.getSelectionAfter(),
    ' ',
  );

  const newEditorState = EditorState.push(
    editorState,
    stateWithEntity,
    'insert-fragment',
  );

  return EditorState.forceSelection(
    newEditorState,
    stateWithEntity.getSelectionAfter(),
  );
};

export const removeAction = (
  editorState: EditorState,
  targetSymbol = '@',
  needToLeaveTrigger: boolean = false,
) => {
  const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection();

  const currentBlockKey = editorState.getSelection().getStartKey();
  const carriagePosition = editorState.getSelection().getFocusOffset();

  const currentText = currentContent.getBlockForKey(currentBlockKey).getText();

  const prevCarriageText = currentText.slice(0, carriagePosition);

  const targetSymbolPosition = prevCarriageText.indexOf(targetSymbol);

  const newSelection = currentSelection.merge({
    anchorOffset: targetSymbolPosition + (needToLeaveTrigger ? 1 : 0),
    focusOffset: carriagePosition,
  });

  const newContent = Modifier.removeRange(
    currentContent,
    newSelection,
    'backward',
  );

  const newEditorState = EditorState.push(
    editorState,
    newContent,
    'insert-characters',
  );
  return EditorState.forceSelection(
    newEditorState,
    newContent.getSelectionAfter(),
  );
};

// export const insertAction = (text: string, editorState: EditorState) => {
//   const currentContent = editorState.getCurrentContent(),
//     currentSelection = editorState.getSelection();
//
//   const currentBlockKey = editorState.getSelection().getStartKey();
//   const carriagePosition = editorState.getSelection().getFocusOffset();
//
//   const currentText = currentContent.getBlockForKey(currentBlockKey).getText();
//
//   const prevCarriageText = currentText.slice(0, carriagePosition);
//
//   const targetSymbolPosition = prevCarriageText.indexOf('@');
//
//   const newSelection = currentSelection.merge({
//     anchorOffset: targetSymbolPosition + 1,
//     focusOffset: carriagePosition,
//   });
//
//   let newContent = Modifier.replaceText(currentContent, newSelection, text);
//
//   let newEditorState = EditorState.push(
//     editorState,
//     newContent,
//     'insert-characters',
//   );
//
//   const selectionForStyle = newContent.getSelectionAfter();
//
//   return EditorState.forceSelection(newEditorState, selectionForStyle);
// };

// export const openActionsMenu = (editorState: EditorState) => {
//   const currentContent = editorState.getCurrentContent();
//   const currentSelection = editorState.getSelection();
//
//   const newContent = Modifier.replaceText(
//     currentContent,
//     currentSelection,
//     '@',
//   );
//
//   const newEditorState = EditorState.push(
//     editorState,
//     newContent,
//     'insert-characters',
//   );
//   return EditorState.forceSelection(
//     newEditorState,
//     newContent.getSelectionAfter(),
//   );
// };

// export const openControlMenu = (editorState: EditorState) => {
//   const currentContent = editorState.getCurrentContent();
//   const currentSelection = editorState.getSelection();
//
//   const selection = editorState.getSelection();
//   const currentBlockKey = selection.getStartKey(); // Get current block key
//   const contentState = editorState.getCurrentContent();
//   const contentStateRaw = convertToRaw(contentState);
//   const currentBlockIndex = contentState
//     .getBlockMap()
//     .keySeq()
//     .findIndex((key) => key === currentBlockKey); // Get current block index
//
//   const currentBlock = contentStateRaw.blocks[currentBlockIndex];
//   const currentText = currentBlock.text;
//
//   const carriagePosition = selection.getFocusOffset(); // Get prev carriage position
//   const prevCarriageText = currentText.slice(0, carriagePosition); // Get prev carriage text from this block
//
//   let newText = '/';
//
//   if(carriagePosition !== 0 || !/[\s+]/.test(prevCarriageText.slice(-1))){
//     newText = " " + newText;
//   }
//
//
//   const newContent = Modifier.replaceText(
//     currentContent,
//     currentSelection,
//     newText,
//   );
//
//   const newEditorState = EditorState.push(
//     editorState,
//     newContent,
//     'insert-characters',
//   );
//   return EditorState.forceSelection(
//     newEditorState,
//     newContent.getSelectionAfter(),
//   );
// };

export const openMenu = (editorState: EditorState, targetSymbol: "@" | "/" = "@") => {
  const currentContent = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();

  const selection = editorState.getSelection();
  const currentBlockKey = selection.getStartKey(); // Get current block key
  const contentState = editorState.getCurrentContent();
  const contentStateRaw = convertToRaw(contentState);
  const currentBlockIndex = contentState
    .getBlockMap()
    .keySeq()
    .findIndex((key) => key === currentBlockKey); // Get current block index

  const currentBlock = contentStateRaw.blocks[currentBlockIndex];
  const currentText = currentBlock.text;

  const carriagePosition = selection.getFocusOffset(); // Get prev carriage position
  const prevCarriageText = currentText.slice(0, carriagePosition); // Get prev carriage text from this block

  let newText:string = targetSymbol;

  if(carriagePosition !== 0 || !/[\s+]/.test(prevCarriageText.slice(-1))){
    newText = " " + newText;
  }

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    newText,
  );

  const newEditorState = EditorState.push(
    editorState,
    newContent,
    'insert-characters',
  );
  return EditorState.forceSelection(
    newEditorState,
    newContent.getSelectionAfter(),
  );
};

export const generateDefaultContent = (
  props: IDefaultStateData,
): EditorState => {
  let editorState = EditorState.createEmpty(
    new CompositeDecorator(noteDecorators),
  );
  const {
    property,
    enterprise,
    person,
    propertyData,
    enterpriseData,
    personData,
  } = props;

  if (property) {
    const propertyItem = propertyData[property[0]];
    if (propertyItem) {
      const action = propertyToAction(propertyItem);
      editorState = insertMention(
        {
          id: propertyItem.id,
          title: action.entityTitle,
          data: propertyItem,
          entityType: CardEntityEnum.Property,
        },
        editorState,
      );
    }
  }
  if (enterprise) {
    const enterpriseItem = enterpriseData[enterprise[0]];
    if (enterpriseItem) {
      const action = enterpriseToAction(enterpriseItem);
      editorState = insertMention(
        {
          id: enterpriseItem.id,
          title: action.entityTitle,
          data: enterpriseItem,
          entityType: CardEntityEnum.Enterprise,
        },
        editorState,
      );
    }
  }
  if (person) {
    const personItem = personData[person[0]];
    if (personItem) {
      const action = personToAction(personItem);
      editorState = insertMention(
        {
          id: personItem.id,
          title: action.entityTitle,
          data: personItem,
          entityType: CardEntityEnum.Person,
        },
        editorState,
      );
    }
  }

  return editorState;
};

// export const entityToAction = (entity:  tagEntityType | TagDTO | UserRoleDTO): IActionElement => {
//   if('address' in entity || 'sqft' in entity){
//     return propertyToAction(entity)
//   }
//   if('title' in entity){
//     if('category' in entity){
//       return tagToAction(entity);
//     }
//     if(TaxonomyTypeSlug.enterpriseType in entity){
//       return enterpriseToAction(entity as EnterpriseDTO)
//     }
//     return roleToAction(entity as UserRoleDTO)
//   }
//   if('firstName' in entity  && 'lastName' in entity){
//     return personToAction(entity)
//   }
//   if('person' in entity && 'login' in entity){
//     return userToAction(entity)
//   }
//   if('person' in entity && 'enterprise' in entity){
//     return involvementToAction(entity)
//   }
//   if('capRate' in entity){
//     return saleTransactionToAction(entity as SaleTransactionFullDTO)
//   }
//   return leaseTransactionToAction(entity as LeaseTransactionFullDTO)
// }

export const getSearchValue = (editorState: EditorState) => {
  let isMenuOpen;
  let searchValue = '';
  let isControlMenuOpen;

  const selection = editorState.getSelection();
  const currentBlockKey = selection.getStartKey(); // Get current block key
  const contentState = editorState.getCurrentContent();
  const contentStateRaw = convertToRaw(contentState);
  const currentBlockIndex = contentState
    .getBlockMap()
    .keySeq()
    .findIndex((key) => key === currentBlockKey); // Get current block index

  const currentBlock = contentStateRaw.blocks[currentBlockIndex];
  const currentText = currentBlock.text; // Get text from current block
  const carriagePosition = selection.getFocusOffset(); // Get prev carriage position
  const prevCarriageText = currentText.slice(0, carriagePosition); // Get prev carriage text from this block

  const targetSymbolPosition = prevCarriageText.search(/[\s+]@|^@/);
  const controlSymbolPosition = prevCarriageText.search(/[\s+]\/|^\//);

  isMenuOpen = targetSymbolPosition !== -1;
  isControlMenuOpen = controlSymbolPosition !== -1;

  if (isMenuOpen) {
    const startPos =
      prevCarriageText[targetSymbolPosition] === '@'
        ? targetSymbolPosition + 1
        : targetSymbolPosition + 2;
    searchValue = prevCarriageText.slice(startPos);
  }
  if (isControlMenuOpen) {
    const startPos =
      prevCarriageText[controlSymbolPosition] === '/'
        ? controlSymbolPosition + 1
        : controlSymbolPosition + 2;
    searchValue = prevCarriageText.slice(startPos);
  }

  return {
    isMenuOpen,
    searchValue,
    isControlMenuOpen,
  };
};

export const generateMentionBlockName = (type: CardEntityEnum) => {
  switch (type) {
    case CardEntityEnum.Enterprise:
      return 'Enterprises';
    case CardEntityEnum.Property:
      return 'Properties';
    case CardEntityEnum.Sale_Transaction:
      return 'Sale Transactions';
    case CardEntityEnum.Lease_Transaction:
      return 'Lease Transactions';
    case CardEntityEnum.Person:
      return 'Persons';
    case CardEntityEnum.Involvement:
      return 'Involvements';
    case CardEntityEnum.Role:
      return 'User Roles';
  }
};

// export const generateEntityName = (type: CardEntityEnum) => {
//   switch(type){
//     case CardEntityEnum.Enterprise: return "Enterprise";
//     case CardEntityEnum.Property: return "Property";
//     case CardEntityEnum.Sale_Transaction: return "Sale";
//     case CardEntityEnum.Lease_Transaction: return "Lease";
//     case CardEntityEnum.Person: return "Person";
//     case CardEntityEnum.Involvement: return "Involvement";
//     case CardEntityEnum.Role: return "User";
//   }
// }
