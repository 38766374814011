import { ICardEntityData } from 'models';
import { EntityEnum, sortType } from "@ternala/voltore-types/lib/constants";

// return option for sorting
// export function createOption(card: ICardEntityData | undefined) {
//   switch (card?.type) {
//     case EntityEnum.leaseTransaction:
//       return {
//         label: 'This lease transaction',
//         value: 2,
//       };
//     case EntityEnum.saleTransaction:
//       return {
//         label: 'This sale transaction',
//         value: 3,
//       };
//     case EntityEnum.property:
//       return {
//         label: 'This property',
//         value: 4,
//       };
//     case EntityEnum.involvement:
//       return {
//         label: 'This involvement',
//         value: 5,
//       };
//     case EntityEnum.enterprise:
//       return {
//         label: 'This enterprise',
//         value: 6,
//       };
//     case EntityEnum.person:
//       return {
//         label: 'This person',
//         value: 6,
//       };
//     default:
//       return { label: 'None' };
//   }
// }

export function generateOptions(card: ICardEntityData | undefined) {
  const res = [
    {
      label: 'All entities',
      value: 1,
    },
  ];
  if (
    card?.type &&
    [EntityEnum.person, EntityEnum.enterprise, EntityEnum.property].includes(card.type)
  ) {
    return [
      ...res,
      {
        label: 'this entity',
        value: 2,
      },
      {
        label: 'ENTITY & OWNED PROPERTIES',
        value: 3,
      },
    ];
  }
  return res;
}

export const dateSortOptions = [
  {
    label: 'past to future',
    value: sortType.asc,
  },
  {
    label: 'future to past',
    value: sortType.desc,
  },
];

export const stateSortOptions = [
  {
    label: 'Done',
    value: 1,
  },
  {
    label: 'Not done',
    value: 2,
  },
];

export const identitySortOptions = [
  {
    label: 'For me',
    value: 1,
  },
  {
    label: 'For anyone',
    value: 2,
  },
  {
    label: 'Created by me',
    value: 3,
  },
];
