import {
  CardActionEnum,
  CardEntityEnum, EntityEnum
} from "@ternala/voltore-types/lib/constants";

import {
  TagEntityEnum
} from "@ternala/voltore-types/lib/card";
import { Pages } from '../../routing';
import {
  LeaseTransactionDTO,
  PropertyDTO,
  SaleTransactionDTO,
  UserRoleDTO,
  TagDTO,
  EnterpriseDTO,
  PersonDTO,
  InvolvementFullDTO,
  LeaseTransactionFullDTO,
  SaleTransactionFullDTO,
} from '@ternala/voltore-types';

import { CardFilterEnum } from "../../controllers/card/models";

export type noteEntityType =
  | PropertyDTO
  | SaleTransactionDTO
  | LeaseTransactionDTO
  | InvolvementFullDTO
  | UserRoleDTO;

export interface IActionElement {
  category?: string;
  title: string;
  subTitle?: string;
  image?: string;
  entityTitle: string;
  key: string;
  uuid: string;
  entityType?: CardEntityEnum;
  isDisabled?: boolean;
  data?: tagEntityType | noteEntityType | TagDTO;
}

export interface IEntity {
  type: TagEntityEnum;
  id: number;
}

export enum ActionListTypeEnum {
  Property = 'property',
  Enterprise = 'enterprise',
  Person = 'person',
  Sale_Transaction = 'saleTransaction',
  Lease_Transaction = 'leaseTransaction',
  Role = 'role',
  Involvement = 'involvement',
  Tag = 'tag',
  Actions = 'actions',
}

export enum NoteEntityEnum {
  Property = 'property',
  Sale_Transaction = 'saleTransaction',
  Lease_Transaction = 'leaseTransaction',
  Role = 'role',
  Enterprise = 'enterprise',
  Involvement = 'involvement',
  Person = 'person',
}

export type TagEntityType = Exclude<
  Exclude<
    Exclude<
      Exclude<ActionListTypeEnum, ActionListTypeEnum.Involvement>,
      ActionListTypeEnum.Role
    >,
    ActionListTypeEnum.Actions
  >,
  ActionListTypeEnum.Tag
>;

// export type TagActionType = Exclude<
//   Exclude<
//     Exclude<ActionListTypeEnum, ActionListTypeEnum.Involvement>,
//     ActionListTypeEnum.Role
//   >,
//   ActionListTypeEnum.Actions
// >;

export interface ICardActionElement extends Omit<IActionElement, 'key'> {
  key: CardActionEnum;
  to?: Pages;
  tabName?: string;
  entityType?: CardEntityEnum;
}

export enum ReminderEditorStepEnum {
  role = 'role',
  date = 'date',
  description = 'description',
}

export enum TagEditorStepEnum {
  Entity = 'entity',
  Tag = 'tag',
}

export interface IAdditionalTime {
  id: number;
  term?: number;
  period: 'week' | 'month' | 'custom';
}

export interface IDate {
  month?: number;
  day?: number;
  year?: number;
  hour?: number;
  minute?: number;
  meridiem?: meridiemType;
}

export type meridiemType = 'AM' | 'PM' | 'am' | 'pm' | 'Am' | 'Pm';

export interface ITagEditorShort
  extends Omit<ITagEditor, 'id', 'type', 'editorType'> {}

export interface IReminderEditor {
  id: string | number;
  role?: UserRoleDTO;
  date?: Date;
  description?: string;
  step: ReminderEditorStepEnum;
  error: string[];
}

export interface IReminderEditorShort
  extends Omit<IReminderEditor, 'id', 'editorType'> {}

export type IModal = {
  id?: number;
  inside?: boolean;
  type?: EntityEnum;
  modal?: CardFilterEnum;
  transactionId?: number;
};

// export interface IMentionData {
//   id?: number;
//   involvementId?: number;
//   transactionId?: number;
//   title: string;
//   data: any;
//   entityType?: CardEntityEnum;
// }

export type tagEntityType =
  | PropertyDTO
  | SaleTransactionFullDTO
  | LeaseTransactionFullDTO
  | EnterpriseDTO
  | PersonDTO;

export interface ITagEntityData {
  data?: tagEntityType;
  type?: TagEntityEnum;
}

export interface ITagEditor {
  id: string | number;
  entity?: ITagEntityData;
  tag?: TagDTO;
  step?: TagEditorStepEnum;
  error: string[];
}

export interface IControlEditor {
  id: string;
  searchValue: string;
}

export interface IFilter {
  label: string;
  key: CardEntityEnum | null;
}
