import React, { useState, useRef } from 'react';

// types
import { TagShortDTO } from '@ternala/voltore-types/lib/modules/card/tag/tagShort.dto';
import { IFiltrationTag } from 'models';
import { TagDTO } from '@ternala/voltore-types';

// components
import TagOptions from './TagOptions';
import { IEntity } from 'components/Windows/model.d';

interface IProps {
  entity?: IEntity;
  tags?: TagDTO[];
  tag: TagShortDTO;
  history?: boolean;
  removed?: boolean;
  addTag?: (tag: IFiltrationTag) => void;
}

const TagItem = ({ tag, entity, addTag, tags, removed, history }: IProps) => {

  const ref: any = useRef(null);
  let width: number = ref.current
    ? ref.current.offsetWidth < 98
      ? 98
      : ref.current.offsetWidth
    : 0;
  const [options, setOptions] = useState<boolean>(false);
  const color = () => {
    if (options) return '#fff';
    if (tag?.color)
      return tag.color.charAt(0) !== '#' ? `#${tag.color}` : tag.color;
    return '#1F2531';
  };
  return (
    <div
      ref={ref}
      className={`tag-item ${entity ? 'entity' : ''} ${
        entity && options ? 'options-tags' : ''
      } ${history ? (removed ? 'history-removed' : 'history') : ''}`}
      style={{
        backgroundColor: color(),
      }}
      onMouseEnter={() => entity && setOptions(true)}
      onMouseLeave={() => entity && setOptions(false)}>
      <div
        className={`tag-item-title ${entity && options ? 'options-tags' : ''} ${
          removed ? 'removed' : ''
        }`}>
        {tag?.title}
      </div>
      {entity && addTag && options ? (
        <TagOptions
          width={width}
          tag={tag}
          entity={entity}
          addTag={addTag}
          tags={tags || []}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default TagItem;
