import React, { ReactNode } from 'react';
import { connect } from 'react-redux';

/* types */
import { IStore } from 'controllers/store';
import { IEnterpriseState } from 'controllers/enterprise/models';

/* components */
import CustomScrollbars from 'components/CustomScrollbars';
import EnterpriseContacts from './EnterpriseContacts';

interface IProps {
  tabName: string;
  enterpriseId: number;
  children?: ReactNode;
  onClose?: () => void;
  enterpriseState: IEnterpriseState;
}

const EnterpriseInfo = (props: IProps) => {
  return (
    <CustomScrollbars id="property-info-container">
      <div
        className="item-info__tab enterprise-info-tab"
        style={{ flexDirection: 'column' }}>
        <EnterpriseContacts
          enterpriseId={props.enterpriseId}
        />
      </div>
    </CustomScrollbars>
  );
};

export default connect((store: IStore) => ({
  enterpriseState: store.enterprise,
}))(EnterpriseInfo);
