import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  width?: number;
  height?: number;
  color?: string;
}

export const CancelIcon: FC<Props> = ({ width, height, color, ...props }) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 7L18.6066 17.6066"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1406 7L7.53402 17.6066"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CancelIcon.defaultProps = {
  width: 25,
  height: 25,
  color: '#F10707',
};
