import { appName } from 'config';
import { createAsyncAction } from 'typesafe-actions';

import { IException } from '../store';
import {
  FavoriteCreateRequest,
  FavoriteCreateResponse,
} from '@ternala/voltore-types';

/* Actions */
export const widgetName = 'favorite';

// ** Action
export const changeStatusAction = createAsyncAction(
  `${appName}/${widgetName}/CHANGE_STATUS_REQUEST`,
  `${appName}/${widgetName}/CHANGE_STATUS_SUCCESS`,
  `${appName}/${widgetName}/CHANGE_STATUS_FILED`,
)<
  FavoriteCreateRequest & { callback?: Function, entityId?: number },
  FavoriteCreateResponse & { id: number, entityId?: number },
  IException
>();
