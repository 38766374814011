import React, { FC } from 'react';

import { IActionElement } from "../../model.d";
import ActionsListMention from './ActionListMention';
import { CardEntityEnum } from '@ternala/voltore-types/lib/constants';
import BackArrow from "../../../icons/NoteCreator/BackArrow";
import { IStore } from "../../../../controllers/store";
import { connect, useSelector } from "react-redux";
import { ISelectEntityData } from "../../../../controllers/showElement/models";

export interface IActionBlockProps {
  searchValue: string;
  onActionSelection: (value: IActionElement) => void;
  removeAction: () => void;
}

// const filters: IFilter[] = [
//   { label: 'all', key: null },
//   { label: 'property', key: CardEntityEnum.Property },
//   { label: 'enterprise', key: CardEntityEnum.Enterprise },
//   { label: 'person', key: CardEntityEnum.Person },
//   // { label: 'sale', key: CardEntityEnum.Sale_Transaction },
//   // { label: 'lease', key: CardEntityEnum.Lease_Transaction },
//   // { label: 'involvement', key: CardEntityEnum.Involvement },
//   // { label: 'role', key: CardEntityEnum.Role },
// ];

export const ActionBlock: FC<
  IActionBlockProps & { ref: React.Ref<HTMLDivElement> }
> = React.forwardRef(({ searchValue, onActionSelection, removeAction }, ref) => {
  // const [selectedFilter, setSelectedFilter] = useState<CardEntityEnum | null>(
  //   null,
  // );

  const selectedEntity = useSelector<IStore, ISelectEntityData | undefined>(store =>  store.showElement.selectedEntity)
  const clickItem = (selectedAction: IActionElement) => {
    if (selectedAction) {
      onActionSelection(selectedAction);
    }
  };

  return (
    <div className="note-creator__actions-list actions-list" ref={ref}>
      {selectedEntity || searchValue ? (
        <div className={'note-creator__actions-list_items'}>
          <header className="actions-list__header">
            <div className={'actions-list__header--row'}>
              <BackArrow
                className={'actions-list__header--back-arrow'}
                onClick={removeAction}
              />
              <h5 className={"heading"}>Mention Entity</h5>
            </div>
          </header>
          {/*<header className="actions-list__header with-tabs with-shadow">*/}
          {/*  <div className="tabs">*/}
          {/*    {filters.map((filter) => (*/}
          {/*      <div*/}
          {/*        key={filter.key}*/}
          {/*        className={"tab" + (filter.key === selectedFilter ? " selected": "")}*/}
          {/*        onClick={() => setSelectedFilter(filter.key)}>*/}
          {/*        {filter.label}*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*</header>*/}
          <ActionsListMention
            searchValue={searchValue}
            selectedEntity={selectedEntity}
            // type={selectedFilter || undefined}
            allEntityTypes={[
              CardEntityEnum.Property,
              CardEntityEnum.Person,
              CardEntityEnum.Enterprise,
            ]}
            onSelect={(entity) => {
              clickItem(entity);
            }}
            key={'actions_block_actions_list'}
          />
        </div>
      ) : (
        <div className="actions-list__alert">
          <span>Continue typing to find your</span>
          <ul>
            <li>Property</li>
            {/*<li>Lease or Sale transaction</li>*/}
            <li>Enterprise</li>
            <li>Person</li>
            {/*<li>User & Role</li>*/}
          </ul>
        </div>
      )}
    </div>
  );
});
