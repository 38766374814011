import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import { UserRoleDTO } from '@ternala/voltore-types';

/* draft modules */
import {
  CompositeDecorator,
  convertFromRaw,
  convertToRaw,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
} from 'draft-js';
import {
  Editor,
  RawDraftContentState,
  SyntheticKeyboardEvent,
} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Config
import {
  dateRegex,
  dateTimeDelimer,
  parseDate,
  roleDelimerSymbol,
  roleSymbol,
  startPhrase,
  styleMap,
  timeRegex,
  timeSymbol,
} from '../config';

//Custom components
import ActionBlock from "./ReminderActionsBlock";

// Utils
import {
  generateContent,
  hours12to24,
  insertUserRole,
  removeEntity,
  removeEntityHandler,
} from './utils';

//Interfaces
import {
  IDate,
  IReminderEditorShort,
  ReminderEditorStepEnum,
} from '../../model.d';

//Context
import { FocusToMainContext, PickerProvider, pickerType } from '../../Contexts';

//Decorators
import { decorators } from '../../Draft/Decorators';
import { moveFocusToEnd } from '../../Draft/utils';
import ActionBlockPortal from '../../../Hocs/ActionBlockPortal';
import { CardFilterEnum } from '../../../../controllers/card/models';

interface IProps {
  role?: UserRoleDTO;
  date?: Date;
  description?: string;
  step: ReminderEditorStepEnum;
  hasError: boolean;

  updateReminder: (reminder: IReminderEditorShort) => void;
  closeReminder: () => void;

  modal: CardFilterEnum;
}

let localValue = '';
let localStep: ReminderEditorStepEnum;

let descriptionLocalValue = '';

export const ReminderCreator: FC<IProps> = ({
  closeReminder,
  updateReminder,

  role,
  date,
  description,
  step,
  hasError,

  modal,
}) => {
  const content = generateContent({
    role,
    date,
    description,
  });

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      convertFromRaw(content),
      new CompositeDecorator(decorators),
    ),
  );
  const { moveFocusToMain } = useContext(FocusToMainContext);
  const [openedPickers, setOpenedPickers] = useState<pickerType[]>([]);
  const [hasFocus, setHasFocus] = useState<boolean>(true);
  const [hasFocusActions, setHasFocusActions] = useState<boolean>(false);
  // const [editedDate, setEditedDate] = useState<Moment>(
  //   moment(date) || moment().add(1, 'day'),
  // );

  const [value, setValue] = useState<string>('');
  const [roleValue, setRoleValue] = useState<string>('');
  const [descriptionValue, setDescription] = useState<string>('');
  const editorRef = useRef<Editor>(null);

  useEffect(() => {
    localStep = step;
  }, [step]);

  useEffect(() => {
    setTimeout(() => {
      editorRef.current?.focusEditor();
      setEditorState(moveFocusToEnd(editorState));
    }, 1);
  }, []);

  const onEditorValueChange = (contentStateRaw: RawDraftContentState) => {
    let newEditorState = editorState;

    let isRoleRemoved = false;

    /* actions filter value */
    let contentState = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    const currentBlockIndex = contentState
      .getBlockMap()
      .keySeq()
      .findIndex((key) => key === currentSelection.getStartKey());

    const currentBlock = contentStateRaw.blocks[currentBlockIndex];
    const currentText = currentBlock.text;

    let type: ReminderEditorStepEnum = ReminderEditorStepEnum.role;

    let newRole = role;
    if (
      currentText.indexOf(roleSymbol) === -1 &&
      currentText.indexOf(roleDelimerSymbol) === -1
    ) {
      newRole = undefined;
    } else {
      type = ReminderEditorStepEnum.date;
    }

    let newDate = date;
    if (currentText.indexOf(timeSymbol) === -1) {
      newDate = undefined;
    } else {
      type = ReminderEditorStepEnum.description;
    }

    let newDescription = description;
    if (currentText.indexOf(timeSymbol) === -1) newDescription = undefined;

    if (currentText.indexOf(startPhrase) === -1) closeReminder();
    if (
      currentText.indexOf(roleSymbol) === -1 ||
      currentText.indexOf(roleDelimerSymbol) === -1
    ) {
      const { entityMap } = contentStateRaw;
      if (currentText.indexOf(roleSymbol) !== -1) {
        contentState = removeEntity({
          block: currentBlock,
          selection: currentSelection,
          entityType: 'MENTION',
          entityMap,
          contentState,
        });
        isRoleRemoved = true;
      }
      if (currentText.indexOf(roleDelimerSymbol) !== -1) {
        contentState = removeEntity({
          block: currentBlock,
          selection: currentSelection,
          entityType: 'ROLE_DELIMER',
          entityMap,
          contentState,
        });
        isRoleRemoved = true;
      }
    }

    const currentTextAfterUpdate =
      convertToRaw(contentState)?.blocks?.[currentBlockIndex]?.text;

    let searchValue = currentTextAfterUpdate.slice(0);
    if (type === ReminderEditorStepEnum.role) {
      const index = currentTextAfterUpdate?.indexOf(roleDelimerSymbol);
      searchValue = currentTextAfterUpdate.slice(startPhrase.length);
      setRoleValue(
        index !== -1
          ? currentTextAfterUpdate.slice(startPhrase.length, index)
          : currentTextAfterUpdate.slice(startPhrase.length),
      );
    }
    if (type === ReminderEditorStepEnum.date) {
      searchValue = currentTextAfterUpdate.slice(
        currentTextAfterUpdate.indexOf(roleDelimerSymbol) +
          roleDelimerSymbol.length,
      );

      // const date: IDate = parseDate(searchValue.trimStart());

      // const newEditedDate = editedDate.clone();
      // if (date.month) {
      //   newEditedDate.month(date.month - 1);
      // }
      // if (date.day) {
      //   newEditedDate.date(date.day);
      // }
      // if (date.year) {
      //   newEditedDate.year(date.year);
      // }
      // if (date.hour) {
      //   newEditedDate.hour(date.hour);
      // }
      // if (date.minute) {
      //   newEditedDate.minute(date.minute);
      // }
      // if (date.meridiem) {
      //   if (date.hour) {
      //     newEditedDate.hour(
      //       hours12to24(
      //         date.hour,
      //         ['pm', 'Pm', 'PM'].indexOf(date.meridiem) !== -1,
      //       ),
      //     );
      //   }
      // }
      // setEditedDate(newEditedDate);
    }
    if (type === ReminderEditorStepEnum.description) {
      searchValue = currentTextAfterUpdate.slice(
        currentTextAfterUpdate.indexOf(timeSymbol) + timeSymbol.length,
      );
      newDescription = searchValue;

      setDescription(searchValue);
      descriptionLocalValue = searchValue;
    }

    if (
      isRoleRemoved ||
      newRole !== role ||
      newDate !== date ||
      newDescription !== description
    ) {
      updateReminder({
        description: newDescription,
        date: newDate,
        role: isRoleRemoved ? undefined : newRole,
      });
    }

    const newValue = searchValue.replace(/^\s*/, '').replace(/\s\s$/g, ' ');

    setValue(newValue);
    localValue = newValue;

    if (isRoleRemoved) {
      newEditorState = EditorState.push(
        newEditorState,
        contentState,
        'insert-fragment',
      );

      setEditorState(
        EditorState.forceSelection(
          newEditorState,
          contentState.getSelectionAfter(),
        ),
      );
    } else {
      setEditorState(newEditorState);
    }
  };

  const setRoleHandler = (role: UserRoleDTO) => {
    const newEditorState = insertUserRole(
      {
        action: 'user',
        id: role.user?.id,
        title: [
          role?.user?.person &&
            role.user.person.firstName + ' ' + role.user.person.lastName,
          role.title,
        ]
          .filter(Boolean)
          .join(' | '),
      },
      editorState,
    );

    setValue('');
    localValue = '';
    setEditorState(newEditorState);
    setTimeout(() => {
      updateReminder({
        description,
        date,
        role,
      });
    }, 1);
  };

  const setDateHandler = (date: Moment) => {
    setValue('');
    localValue = '';
    setDescription('');
    descriptionLocalValue = '';

    let currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const currentBlockKey = editorState.getSelection().getStartKey();
    const carriagePosition = editorState.getSelection().getFocusOffset();

    const currentText = currentContent
      .getBlockForKey(currentBlockKey)
      .getText();

    const prevCarriageText = currentText.slice(0, carriagePosition);
    const targetSymbolPosition = prevCarriageText.indexOf(roleDelimerSymbol);

    const startReplacePos = targetSymbolPosition + roleDelimerSymbol.length;
    if (currentText[startReplacePos] !== ' ') {
      currentContent = Modifier.insertText(
        currentContent,
        currentSelection.merge({
          anchorOffset: startReplacePos,
          focusOffset: startReplacePos,
        }),
        ' ',
      );
    }

    const newSelection = currentSelection.merge({
      anchorOffset: startReplacePos + 1,
      focusOffset:
        carriagePosition >= startReplacePos + 1
          ? carriagePosition
          : startReplacePos + 1,
    });
    const resTitle = moment(date).format('MM/DD/YYYY, hh:mm A') + timeSymbol;

    currentContent = currentContent.createEntity('TIME', 'IMMUTABLE', {
      data: {
        label: resTitle,
        date,
      },
      title: resTitle,
    });

    const entityKey = currentContent.getLastCreatedEntityKey();

    currentContent = Modifier.replaceText(
      currentContent,
      newSelection,
      resTitle,
      undefined,
      entityKey,
    );

    currentContent = Modifier.insertText(
      currentContent,
      currentContent.getSelectionAfter(),
      ' ',
    );

    const newEditorState = EditorState.push(
      editorState,
      currentContent,
      'insert-fragment',
    );

    setEditorState(
      EditorState.forceSelection(
        newEditorState,
        currentContent.getSelectionAfter(),
      ),
    );

    setTimeout(() => {
      updateReminder({
        description,
        date: date.toDate(),
        role,
      });
    }, 1);
  };

  const changeDateText = (date: Date) => {
    const dateText = moment(date).format('MM/DD/YYYY, hh:mm A');
    setValue(dateText);
    localValue = dateText;

    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const currentBlockKey = editorState.getSelection().getStartKey();
    const carriagePosition = editorState.getSelection().getFocusOffset();

    const currentText = currentContent
      .getBlockForKey(currentBlockKey)
      .getText();

    const prevCarriageText = currentText.slice(0, carriagePosition);
    const targetSymbolPosition = prevCarriageText.indexOf(roleDelimerSymbol);
    const newSelection = currentSelection.merge({
      anchorOffset: targetSymbolPosition + 1 + roleDelimerSymbol.length,
      focusOffset: carriagePosition,
    });
    const resTitle = dateText + timeSymbol;

    const stateWithText = Modifier.replaceText(
      currentContent,
      newSelection,
      resTitle,
      undefined,
    );

    const newEditorState = EditorState.push(
      editorState,
      stateWithText,
      'insert-fragment',
    );
    setEditorState(moveFocusToEnd(newEditorState));
    // setTimeout(() => {
    //   updateReminder({
    //     description,
    //     date,
    //     role,
    //   });
    // }, 1);
  };

  const changeDescriptionText = (description: string) => {
    setValue(description);
    localValue = description;
    setDescription(description);
    descriptionLocalValue = description;

    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const currentBlockKey = editorState.getSelection().getStartKey();
    const carriagePosition = editorState.getSelection().getFocusOffset();

    const currentText = currentContent
      .getBlockForKey(currentBlockKey)
      .getText();

    const prevCarriageText = currentText.slice(0, carriagePosition);
    const targetSymbolPosition = prevCarriageText.indexOf(timeSymbol);

    const newSelection = currentSelection.merge({
      anchorOffset: targetSymbolPosition + timeSymbol.length + 1,
      focusOffset: carriagePosition,
    });

    const stateWithText = Modifier.replaceText(
      currentContent,
      newSelection,
      description,
      undefined,
    );

    const newEditorState = EditorState.push(
      editorState,
      stateWithText,
      'insert-fragment',
    );

    setEditorState(newEditorState);

    setTimeout(() => {
      updateReminder({
        description,
        date,
        role,
      });
    }, 1);
  };

  const handleKeyCommand = (command: string): any => {
    if (command === 'split-block') {
      return 'handled';
    }
  };

  const myKeyBindingFn = (e: SyntheticKeyboardEvent): string | null => {
    let newValue = localValue;
    if (e.key !== 'Backspace') {
      newValue = localValue + e.key;
    }
    const [date, time] = newValue.split(dateTimeDelimer);
    if (
      localStep === ReminderEditorStepEnum.date &&
      ((date ? !dateRegex.test(date) : false) ||
        (time ? !timeRegex.test(time) : false)) &&
      e.key !== 'Backspace' &&
      e.key !== 'Enter'
    ) {
      if (e.code !== 'ArrowUp' && e.code !== 'ArrowDown') {
        e.preventDefault();
        e.stopPropagation();
        return null;
      }
    }
    if (e.key === 'Enter') {
      if (localStep === ReminderEditorStepEnum.description) {
        moveFocusToMain();
      }
      e.preventDefault();
      return null;
    }
    return getDefaultKeyBinding(e);
  };

  return (
    <div className={'reminder-editor' + (hasError ? ' error' : '')}>
      <PickerProvider
        value={{
          openedPickers,
          openPicker: (picker) => {
            setOpenedPickers([...openedPickers, picker]);
          },
          closePicker: (closedPicker) => {
            setOpenedPickers(
              openedPickers.filter((picker) => picker !== closedPicker),
            );
            // setEditorState(moveFocusToEnd(editorState))
          },
        }}>
        {(hasFocus || hasFocusActions || Boolean(openedPickers.length)) && (
          <ActionBlockPortal modal={modal}>
            <ActionBlock
              value={value}
              descriptionValue={descriptionValue}
              roleValue={roleValue}
              step={step}
              // date={editedDate}
              role={role}
              dateObj={date}
              description={description}
              closeEditor={closeReminder}
              onDateSelected={(date) => {
                if (date) {
                  setDateHandler(date);
                }
              }}
              onRemoveDate={() => {
                updateReminder({
                  description: undefined,
                  date: undefined,
                  role,
                });
                const res = removeEntityHandler('TIME', editorState);
                res && setEditorState(res);
              }}
              onDateChanged={(date) => {
                // date
                if (date) {
                  // setEditedDate(date);
                  changeDateText(date.toDate());
                }
              }}
              onDescriptionChanged={(description) =>
                changeDescriptionText(description)
              }
              onRemoveDescription={() => {
                updateReminder({
                  description: undefined,
                  date,
                  role,
                });
              }}
              onRoleSelected={(role) => setRoleHandler(role)}
              onRemoveRole={() => {
                updateReminder({
                  description: undefined,
                  date: undefined,
                  role: undefined,
                });
                setRoleValue('');
                const res = removeEntityHandler('MENTION', editorState);
                res && setEditorState(res);
              }}
              setHasFocus={setHasFocusActions}
              hasFocus={hasFocusActions}
            />
          </ActionBlockPortal>
        )}

        <Editor
          placeholder={
            'Leave a note, press @ to mention entity or trigger an action'
          }
          defaultEditorState={editorState}
          customDecorators={decorators}
          editorState={editorState}
          onEditorStateChange={(editorState) =>
            editorState.getSelection().getHasFocus()
              ? setEditorState(moveFocusToEnd(editorState))
              : setEditorState(editorState)
          }
          customStyleMap={styleMap}
          editorClassName="reminder-creator__input-field"
          onChange={onEditorValueChange}
          handleKeyCommand={handleKeyCommand}
          toolbarHidden={true}
          // @ts-ignore
          keyBindingFn={myKeyBindingFn}
          ref={editorRef}
          onBlur={() => setHasFocus(false)}
          onFocus={() => setHasFocus(true)}
        />
      </PickerProvider>
    </div>
  );
};

export default ReminderCreator;
