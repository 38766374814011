import { all } from 'redux-saga/effects';
import { ActionType, createReducer } from 'typesafe-actions';
import { omit } from 'lodash';
// Actions
import * as actions from './actions';
// Sagas
import { involvementActionSaga } from './sagas/involvement';
import { IInvolvementState } from './models';

export type InvolvementActionType = ActionType<typeof actions>;

export const involvementSaga = function* () {
  yield all([involvementActionSaga()]);
};

const initialState = {
  involvementData: {},
};

export const involvementReducer = createReducer<
  IInvolvementState,
  InvolvementActionType
>(initialState)
  .handleAction(
    [
      actions.getInvolvementAction.success,
      actions.createInvolvementAction.success,
      actions.updateInvolvementAction.success,
    ],
    (state: IInvolvementState, { payload }): IInvolvementState => ({
      ...state,
      involvementData: {
        ...state.involvementData,
        [payload.res.id]: {
          ...state.involvementData[payload.res.id],
          ...payload.res,
        },
      },
    }),
  )
  .handleAction(
    [actions.deleteInvolvementAction.success],
    (state: IInvolvementState, { payload }): IInvolvementState => ({
      ...state,
      involvementData: payload.id
        ? omit(state.involvementData, [payload.id])
        : state.involvementData,
    }),
  )
  .handleAction(
    [actions.getInvolvementsAction.success],
    (state: IInvolvementState, { payload }): IInvolvementState => {
      const involvementData = {
        ...state.involvementData,
      };

      payload.res.items.forEach((involvement) => {
        involvementData[involvement.id] = {
          ...involvementData[involvement.id],
          ...involvement,
        };
      });

      return {
        ...state,
        involvementData,
      };
    },
  );
