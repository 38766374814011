import {
  CardDTO,
  CardFullDTO,
  CardGetListRequest,
} from '@ternala/voltore-types';
import { defaultState } from '../based';

export interface ICardState extends defaultState {
  cards?: number[];
  reminders?: number[];
  cardData: {
    [key: string]: CardDTO | CardFullDTO;
  };
  reminderData: {
    [key: string]: CardDTO | CardFullDTO;
  };
  storedSearchParams: CardGetListRequest | null;
  storedSearchReminderParams:
    | (CardGetListRequest & { type?: CardFilterEnum })
    | null;
  remindersCount: number;
}

export enum CardFilterEnum {
  reminder = 'reminder',
  note = 'note',
  preview = 'preview',
}
