import { all, put, takeEvery, call } from "redux-saga/effects";

// APIs
import {HistoryApi} from '../transport/history.api';
import {getAccessToken} from '../../auth';

// Actions
import {getHistoryAction} from '../actions';
import {addError, addLoader, removeLoader} from '../index';

// Utils
import uuid from 'utils/uuid';

// Constants
import {LoaderAction} from 'config/constants';
import {getAccessTokenUtil} from "../../../utils/getAccessToken";
import {ActivityHistoryGetListResponse} from "@ternala/voltore-types";
import {IError} from "../../model";
import {getEnterprisesAction} from "../../enterprise/actions";
import { getAccessTokenSaga } from "../../auth/sagas/auth";

export function* getHistorySaga({
                                    payload,
                                }: ReturnType<typeof getHistoryAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, history is loading!',
            type: LoaderAction.history.getList,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: ActivityHistoryGetListResponse | string | IError = yield HistoryApi.getHistoryList(data, accessToken);

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                getHistoryAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    getHistoryAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to get history!',
                    type: LoaderAction.history.getList,
                }),
            );
        } else {
            yield put(getHistoryAction.success(res));
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback();
        }
    } catch (error: any) {
        console.error('error: ', error);
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            getHistoryAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                id: loadId,
                message: 'Failed to get history!',
                type: LoaderAction.history.getList,
            }),
        );
    }
}

export function* historyActionSaga() {
    yield all([takeEvery(getHistoryAction.request, getHistorySaga)]);
}
