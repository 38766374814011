import { Component } from 'react'
import ReactDOM from 'react-dom'

interface Props {
   children: JSX.Element | JSX.Element[]
}

const portalRoot = document.getElementById('portal-root')

export default class Portal extends Component<Props> {
   container = document.createElement('div');

   componentDidMount() {
      portalRoot?.append(this.container)
   }

   componentWillUnmount() {
      portalRoot?.removeChild(this.container)
   }

   render() {
      this.container.style.zIndex = '50';
      return ReactDOM.createPortal(
         this.props.children,
         this.container
      ) as JSX.Element
   }
}
