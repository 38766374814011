import {
  PhoneTypeEnum,
  SaleTransactionStatusEnum,
} from '@ternala/voltore-types/lib/constants';

interface IFormatAddress {
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string | number;
}

export function getUniqueId(): string {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, () => {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
}

export function arrayEquals(a: any[], b: any[]) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export function formatAddress({
  address,
  city,
  state,
  zipCode,
}: IFormatAddress) {
  return `${address ? address + ', ' : ''}
  ${city ? city + ', ' : ''}
  ${state ? state + ' ' : ''}
  ${zipCode ? zipCode : ''}`;
}

export function renderPhoneEnum(type: string) {
  switch (type) {
    case 'Home':
      return PhoneTypeEnum.HOME;
    case 'Mobile':
      return PhoneTypeEnum.MOBILE;
    case 'Office':
      return PhoneTypeEnum.OFFICE;
    case 'Landline':
      return PhoneTypeEnum.LANDLINE;
    case 'Other':
      return PhoneTypeEnum.OTHER;
    default:
      break;
  }
}

export function uniqueNumberId(): number {
  return Number(getUniqueId().replace(/[A-aZ-z]/g, ''));
}

type EnumType = { [s: number]: string };
export function mapEnum<T = any>(
  enumerable: EnumType,
  callback: Function,
): T[] {
  const enumMembers: any[] = Object.keys(enumerable).map(
    (key: any) => enumerable[key],
  );
  const enumValues: string[] = enumMembers.filter((v) => typeof v === 'string');
  return enumValues.map((m) => callback(m));
}

export function isEmpty(object: object) {
  if (Array.isArray(object)) {
    return object.length === 0;
  }
  if (object) {
    return Object.keys(object).length === 0;
  }
}

export function deepEqual(a: object, b: object): boolean {
  if (
    typeof a !== 'object' ||
    typeof b !== 'object' ||
    a === null ||
    b === null
  ) {
    return a === b;
  }
  if (Object.keys(a).length !== Object.keys(b).length) return false;

  for (const prop in a) {
    if (!(prop in b) || !deepEqual((a as any)[prop], (b as any)[prop]))
      return false;
  }
  return true;
}

/* formatting helpers */
export function capitalize(value: string): string {
  return value[0].toUpperCase() + value.slice(1).toLocaleLowerCase();
}

export function parsedPhone(phone: string): string {
  if (!phone) return '';
  return phone.replace(/[()\s-]/g, '');
}

export function phoneValidator(item: string): string {
  return ('(' + item.split('(')[1]).trim();
}

/* format phone number from string */
export function formatPhoneNumber(value: string) {
  var cleaned = ('' + value).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function maskPassword(password?: string) {
  if (password) {
    return password.replace(/\w/g, '*');
  }
  return '';
}

export function removeNull(array: (string | number | undefined)[]) {
  return array.filter((item) => item !== null);
}

// check array differences (by title and id)
export function arrayDifferences(newValue: any, oldValue: any) {
  const isSameValue = (leftValue: any, rightValue: any) => {
    if (!leftValue) return;
    if (!rightValue) return;
    if (typeof leftValue === 'string') return leftValue === rightValue;
    let title = leftValue.title === rightValue.title;
    let id = leftValue.id === rightValue.id;
    return title && id;
  };
  const onlyInLeft = (left: any, right: any, compareFunction: any) => {
    if (!Array.isArray(left)) return [];
    if (!Array.isArray(right)) return [];
    return left.filter(
      (leftValue: any) =>
        !right.some((rightValue: any) =>
          compareFunction(leftValue, rightValue),
        ),
    );
  };
  const newArray = onlyInLeft(newValue, oldValue || [], isSameValue);
  const oldArray = onlyInLeft(oldValue || [], newValue, isSameValue);
  return { oldArray, newArray };
}

export const objectDifferencesOneLevel = (newValue: any, oldValue?: any) => {
  if (!newValue) return [];
  const arr = Object.keys(newValue);
  const difference: any = [];
  arr.forEach((item) => {
    if (String((oldValue || [])[`${item}`]) !== String(newValue[`${item}`])) {
      difference.push({
        name: item,
        old: (oldValue || [])[`${item}`],
        new: newValue[`${item}`],
      });
    }
  });
  return difference;
};

export function getInitials(name?: string): string {
  if (name && name.indexOf(' ') !== -1) {
    const [firstName, lastName] = name.split(' ');
    return `${firstName[0]}${lastName[0]}`;
  }
  return '';
}

export function withThousandsSeparators(value: number | string): string {
  return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function parseStringWithSeparators(value: number | string): number {
  if (typeof value === 'number') {
    return value;
  }
  return Number(value.replace(/,/g, ''));
}

export function numberToString(value: number | undefined): string {
  if (value !== undefined && value !== null && !isNaN(Number(value))) {
    return String(value);
  }
  return '';
}

/* DATE helpers */
export function formattedDate(d: Date = new Date()): string {
  const date = new Date(d);
  return [date.getMonth() + 1, date.getDate(), date.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
}

export function normalizedDate(formattedDate: string): Date {
  const [year, month, day] = formattedDate.split('/');
  return new Date(`${year}-${month}-${day}`);
}

export function isAfterCurrentDate(date: Date): boolean {
  if (!date) return false;
  const today = new Date().getTime();
  const incomingDate = new Date(date).getTime();
  return incomingDate > today;
}

export function isStartDateAfterEndDate(date: {
  startDate: any;
  endDate: any;
}): boolean {
  if (date.startDate && !date.endDate) return false;

  const pureStartDate = new Date(date.startDate);
  const pureEndDate = new Date(date.endDate);
  pureStartDate.setHours(0, 0, 0, 0);
  pureEndDate.setHours(0, 0, 0, 0);

  return pureStartDate.getTime() > pureEndDate.getTime();
}

export function dateDiffInDays(startDate: string, endDate: string): number {
  const diffInMs = Number(new Date(endDate)) - Number(new Date(startDate));
  return (diffInMs / 8.64e7) | 0;
}

export function transactionItemDateDiff(
  startDate: string,
  endDate: string,
): number {
  const keywords = ['now', 'present', 'current', 'today'];
  let diffInMs = 0;
  keywords.includes(endDate.toLowerCase())
    ? (diffInMs = Number(new Date()) - Number(new Date(startDate)))
    : (diffInMs = Number(new Date(endDate)) - Number(new Date(startDate)));
  return (diffInMs / 8.64e7) | 0;
}

/* special */
export function computeDaysOnMarket(transaction: any): number {
  const latestActiveStatus = transaction?.historyStatuses?.find(
    (item: any) => item.status === SaleTransactionStatusEnum.Active,
  );
  const currentStatusDate =
    transaction?.historyStatuses && transaction.historyStatuses.length > 1
      ? transaction?.activeStatus?.startDate
      : new Date();
  return dateDiffInDays(
    formattedDate(
      latestActiveStatus?.startDate
        ? new Date(latestActiveStatus?.startDate)
        : new Date(),
    ),
    formattedDate(currentStatusDate ? new Date(currentStatusDate) : new Date()),
  );
}
