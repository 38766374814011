import React, { FC, useState, useEffect, ReactNode } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  EnterpriseFullDTO,
  EnterpriseFullWithTreeDTO,
} from '@ternala/voltore-types';

// Components
import CompanyInfo from 'components/CompanyInfo';
import Loader from 'components/Loader';
import CustomScrollbars from 'components/CustomScrollbars';

/* controllers */
import { getEnterpriseTreeAction } from 'controllers/enterprise/actions';

/* types & interfaces */
import { IStore } from 'controllers/store';
import { IEnterpriseState } from 'controllers/enterprise/models';

// Utils
import { getListImage } from "utils/getImage";

interface Props {
  enterpriseId: number;
  tabName: string;
  enterpriseData: IEnterpriseState['enterpriseData'];
  children?: ReactNode;
}

const OwnershipStructureTab: FC<Props> = ({
  enterpriseId,
  enterpriseData
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [hoveredEnterpriseId, setHoveredEnterpriseId] = useState<number | null>(
    null,
  );
  const [selectedEnterpriseId, setSelectedEnterpriseId] = useState<
    number | null
  >(null);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getEnterpriseTreeAction.request({
        id: enterpriseId,
        callback: () => setLoading(false),
      }),
    );
  }, []);

  const currentEnterprise = enterpriseData[enterpriseId];

  const generateItem = (
    item: EnterpriseFullDTO,
    title?: string,
  ): JSX.Element => (
    <div
      className={
        'ownership-item' +
        (item.id === currentEnterprise.id ? ' this-company' : ' company') +
        (hoveredEnterpriseId === item.id ? ' hovered' : '') +
        (selectedEnterpriseId === item.id ? ' selected' : '')
      }
      onMouseOver={() => setHoveredEnterpriseId(item.id)}
      onMouseOut={() => setHoveredEnterpriseId(null)}
      onClick={() => setSelectedEnterpriseId(item.id)}
      key={item.id}>
      {title ? (
        <span
          className={
            'ownership-item__title' +
            (hoveredEnterpriseId === item.id ? ' hovered' : '') +
            (selectedEnterpriseId === item.id ? ' selected' : '')
          }>
          {title}
        </span>
      ) : item.images?.length ? (
        <div
          className={
            'ownership-item__image' +
            (hoveredEnterpriseId === item.id ? ' hovered' : '') +
            (selectedEnterpriseId === item.id ? ' selected' : '')
          }>
          <div className="" />
          <img src={getListImage(item.images)} alt="company" />
        </div>
      ) : (
        <span
          className={
            'ownership-item__title-company' +
            (hoveredEnterpriseId === item.id ? ' hovered' : '') +
            (selectedEnterpriseId === item.id ? ' selected' : '')
          }>
          {item.title}
        </span>
      )}
    </div>
  );

  const generateBlock = (
    item: EnterpriseFullWithTreeDTO,
    property: 'parentsTree' | 'childrenTree',
  ): JSX.Element => {
    const res = [generateItem(item)];

    if (item[property]?.length) {
      if (property === 'parentsTree') {
        res.unshift(
          <div className="ownership-children parents-tree" key={item.id}>
            {item[property]?.map((subItem) => generateBlock(subItem, property))}
          </div>,
        );
      } else {
        res.push(
          <div className="ownership-children children-tree" key={item.id}>
            {item[property]?.map((subItem) => generateBlock(subItem, property))}
          </div>,
        );
      }
    }

    return <div className="ownership-block">{res}</div>;
  };

  return (
    <CustomScrollbars>
      <Helmet>
        <title>
          Ownership | Voltore
          {process.env.REACT_APP_COMPANY_NAME
            ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
            : ''}
        </title>
        <meta name="description" content="Voltore application" />
      </Helmet>
      {loading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        <div className="ownership-tab">
          {selectedEnterpriseId ? (
            <CompanyInfo id={selectedEnterpriseId} />
          ) : (
            ''
          )}

          <div className="ownership-structure">
            <div className="dynamic-area">
              {currentEnterprise?.parentsTree?.length ? (
                <div className="ownership-top">
                  {currentEnterprise?.parentsTree?.map((parent) => {
                    return generateBlock(parent, 'parentsTree');
                  })}
                </div>
              ) : (
                ''
              )}
              <div className="ownership-current">
                {currentEnterprise &&
                  generateItem(currentEnterprise, 'This enterprise')}
              </div>
              {currentEnterprise?.childrenTree?.length ? (
                <div className="ownership-bottom">
                  {currentEnterprise?.childrenTree?.map((child) => {
                    return generateBlock(child, 'childrenTree');
                  })}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </CustomScrollbars>
  );
};

export default connect((store: IStore) => ({
  enterpriseData: store.enterprise.enterpriseData,
}))(OwnershipStructureTab);
