import { IModal as IPreview } from 'components/Windows/model.d';

import { createContext } from 'react';

const initialState = {
  setPreview: (modal: IPreview | undefined) => {},
  preview: undefined as IPreview | undefined,
};

const PreviewContext = createContext(initialState);

export const PreviewProvider = PreviewContext.Provider;

export default PreviewContext;
