import { all, put, takeEvery, call } from 'redux-saga/effects';

// APIs
import { TaxonomyApi } from '../transport/taxonomy.api';

// Actions
import {
  getTaxonomiesAction,
  getTaxonomyAction,
  createTaxonomyAction,
  updateTaxonomyAction,
  deleteTaxonomyAction,
  getTaxonomiesByTypeAction,
  getTaxonomyTypeListAction,
} from '../actions';
import {
  TaxonomyCreateResponse,
  TaxonomyDeleteResponse,
  TaxonomyGetByTypeResponse,
  TaxonomyGetListResponse,
  TaxonomyGetResponse,
  TaxonomyUpdateResponse,
} from '@ternala/voltore-types';
import { IError } from '../../model';

import { addError, addLoader, removeLoader } from '../../enterprise';
import { LoaderAction } from '../../../config/constants';
import uuid from '../../../utils/uuid';
import { TaxonomyTypeGetListResponse } from '@ternala/voltore-types/lib/modules/taxonomy/type/response.dto';
import { getAccessTokenSaga } from '../../auth/sagas/auth';

// Utils
export function* getTaxonomiesSaga({
  payload,
}: ReturnType<typeof getTaxonomiesAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, taxonomies are loading!',
      type: LoaderAction.taxonomy.getList,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    const res: TaxonomyGetListResponse | string | IError =
      yield TaxonomyApi.getTaxonomies(payload, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getTaxonomiesAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getTaxonomiesAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get taxonomy!',
          type: LoaderAction.taxonomy.getList,
        }),
      );
    } else {
      yield put(
        getTaxonomiesAction.success({
          ...res,
        }),
      );
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getTaxonomiesAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
  }
}

export function* getTaxonomyTypeListActionSaga({
  payload,
}: ReturnType<typeof getTaxonomyTypeListAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, taxonomy type list is getting!',
      type: LoaderAction.taxonomyType.getList,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    const res =
      (yield TaxonomyApi.getTaxonomyTypeListAction(accessToken, payload)) as TaxonomyTypeGetListResponse | string | IError;
    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getTaxonomyTypeListAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getTaxonomyTypeListAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get taxonomy type list!',
          type: LoaderAction.taxonomyType.getList,
        }),
      );
    } else {
      yield put(getTaxonomyTypeListAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getTaxonomyTypeListAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to get taxonomy list!',
        type: LoaderAction.taxonomyType.getList,
      }),
    );
  }
}

export function* getTaxonomiesByTypeSaga({
  payload,
}: ReturnType<typeof getTaxonomiesByTypeAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, taxonomies are loading!',
      type: LoaderAction.taxonomy.getList,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    const res: TaxonomyGetByTypeResponse | string | IError =
      yield TaxonomyApi.getTaxonomiesByType(payload, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getTaxonomiesByTypeAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getTaxonomiesByTypeAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get taxonomies!',
          type: LoaderAction.taxonomy.getList,
        }),
      );
    } else {
      yield put(
        getTaxonomiesByTypeAction.success({
          ...res,
          slug: payload.type,
        }),
      );
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
    }
  } catch (error: any) {
    yield put(
      getTaxonomiesByTypeAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
  }
}

export function* createTaxonomySaga({
  payload,
}: ReturnType<typeof createTaxonomyAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, enterprise is creating!',
      type: LoaderAction.taxonomy.create,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    const res: TaxonomyCreateResponse | string | IError =
      yield TaxonomyApi.createTaxonomy(payload, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        createTaxonomyAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          createTaxonomyAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to create taxonomy!',
          type: LoaderAction.taxonomy.create,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(false);
    } else {
      yield put(createTaxonomyAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(res);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      createTaxonomyAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
  }
}

export function* getTaxonomySaga({
  payload,
}: ReturnType<typeof getTaxonomyAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, enterprise is getting!',
      type: LoaderAction.taxonomy.getItem,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    const res: TaxonomyGetResponse | string | IError =
      yield TaxonomyApi.getTaxonomy(payload, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getTaxonomyAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getTaxonomyAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get enterprise!',
          type: LoaderAction.taxonomy.getItem,
        }),
      );
    } else {
      yield put(getTaxonomyAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(true, res.id);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getTaxonomyAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
  }
}

export function* updateTaxonomySaga({
  payload,
}: ReturnType<typeof updateTaxonomyAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, taxonomy is updating!',
      type: LoaderAction.taxonomy.update,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    const res: TaxonomyUpdateResponse | string | IError =
      yield TaxonomyApi.updateTaxonomy(payload, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        updateTaxonomyAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          updateTaxonomyAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to update enterprise!',
          type: LoaderAction.taxonomy.update,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(false);
    } else {
      yield put(updateTaxonomyAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(res);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      updateTaxonomyAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
  }
}

export function* deleteTaxonomySaga({
  payload,
}: ReturnType<typeof deleteTaxonomyAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, taxonomy is deleting!',
      type: LoaderAction.taxonomy.delete,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: TaxonomyDeleteResponse | string =
      yield TaxonomyApi.deleteTaxonomy(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string') {
      deleteTaxonomyAction.failure({
        code: res,
        message: res || 'Something was wrong',
      });
      yield put(
        addError({
          id: loadId,
          message: 'Failed to delete enterprise!',
          type: LoaderAction.taxonomy.delete,
        }),
      );
    } else {
      yield put(deleteTaxonomyAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      deleteTaxonomyAction.failure({
        code: error.code || 403,
        message: error.message || error || 'Something was wrong',
      }),
    );
  }
}

export function* taxonomyActionSaga() {
  yield all([
    takeEvery(getTaxonomiesAction.request, getTaxonomiesSaga),
    takeEvery(getTaxonomyTypeListAction.request, getTaxonomyTypeListActionSaga),
    takeEvery(getTaxonomiesByTypeAction.request, getTaxonomiesByTypeSaga),
    takeEvery(getTaxonomyAction.request, getTaxonomySaga),
    takeEvery(createTaxonomyAction.request, createTaxonomySaga),
    takeEvery(updateTaxonomyAction.request, updateTaxonomySaga),
    takeEvery(deleteTaxonomyAction.request, deleteTaxonomySaga),
  ]);
}
