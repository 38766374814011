import React from 'react';

/* types*/
import { SaleTransactionOwnDTO } from '@ternala/voltore-types';

/* components */
import Link from 'components/UI/controls/Link';
import { PageEnum } from 'config/constants';
import { enterprisesTabs, propertiesTabs } from 'config/constants/tabs';
import { NoteEntityEnum } from 'components/Windows/model.d';
import { withThousandsSeparators } from 'utils/helper-functions';
import Moment from 'react-moment';
import { DATE_FORMAT } from 'config';
import { EntityEnum } from "@ternala/voltore-types/lib/constants";

interface IProps {
  propertiesTab?: boolean;
  transaction?: SaleTransactionOwnDTO;
  data: {
    sqft?: number;
    price?: number;
    status?: string;
    endDate?: string;
    startDate?: string;
    propertyId?: number;
  };
}

const SaleTransactionField = ({
  propertiesTab,
  transaction,
  data: { sqft, price, status, startDate, endDate, propertyId },
}: IProps) => {
  if (!transaction) return <div className=""></div>;
  return (
    <div className="owned-property-transaction">
      <div className="first-row">
        <Link
          newTabIcon
          id={propertyId}
          className="link"
          transactionId={transaction.id}
          type={EntityEnum.saleTransaction}
          fullPath={`/${PageEnum.PROPERTIES}/${propertyId}/${propertiesTabs.sales.path}/${transaction.id}`}>
          <div>
            {sqft && <span>{withThousandsSeparators(sqft)} SQFT</span>} |{' '}
            <span>{price ? `$${withThousandsSeparators(price)}` : 'n/a'}</span>
          </div>
        </Link>
      </div>
      <div className="second-row">
        {transaction.buyer ? (
          <Link
            newTabIcon
            className="link"
            id={transaction.buyer?.id}
            type={EntityEnum.enterprise}
            fullPath={`/${PageEnum.ENTERPRISES}/${transaction.buyer?.id}/${enterprisesTabs.info.path}`}>
            {transaction.buyer?.title}
          </Link>
        ) : (
          ''
        )}
      </div>
      <div className="third-row">
        <span className="status">{status}</span>
        <span className="transaction-date">
          {startDate ? <Moment format={DATE_FORMAT}>{startDate}</Moment> : ''}
        </span>
      </div>
    </div>
  );
};

export default SaleTransactionField;
