import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const CameraIcon: FC<Props> = ({ color, ...props }) => (
   <svg
      {...props}
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M16.8398 2.87723C16.4921 2.51295 16.0119 2.29769 15.4655 2.29769H12.7168V2.26458C12.7168 1.85062 12.5512 1.45322 12.2697 1.18829C11.9882 0.906794 11.6074 0.741211 11.1934 0.741211H6.22593C5.79541 0.741211 5.41457 0.906794 5.13308 1.18829C4.85159 1.46978 4.68601 1.85062 4.68601 2.26458V2.29769H1.95388C1.40746 2.29769 0.927266 2.51295 0.579541 2.87723C0.231817 3.22496 0 3.72171 0 4.25158V12.4976C0 13.044 0.215258 13.5242 0.579541 13.872C0.927266 14.2197 1.42402 14.4515 1.95388 14.4515H15.4655C16.0119 14.4515 16.4921 14.2362 16.8398 13.872C17.1875 13.5242 17.4194 13.0275 17.4194 12.4976V4.25158C17.4194 3.70515 17.2041 3.22496 16.8398 2.87723ZM16.5583 12.4976H16.5418C16.5418 12.7957 16.4259 13.0606 16.2272 13.2593C16.0285 13.458 15.7635 13.5739 15.4655 13.5739H1.95388C1.65583 13.5739 1.3909 13.458 1.1922 13.2593C0.993499 13.0606 0.877591 12.7957 0.877591 12.4976V4.25158C0.877591 3.95353 0.993499 3.68859 1.1922 3.48989C1.3909 3.29119 1.65583 3.17528 1.95388 3.17528H5.14964C5.39801 3.17528 5.59671 2.97658 5.59671 2.72821V2.24802C5.59671 2.06588 5.66295 1.90029 5.77886 1.78439C5.89476 1.66848 6.06035 1.60224 6.24249 1.60224H11.1934C11.3756 1.60224 11.5412 1.66848 11.6571 1.78439C11.773 1.90029 11.8392 2.06588 11.8392 2.24802V2.72821C11.8392 2.97658 12.0379 3.17528 12.2863 3.17528H15.482C15.7801 3.17528 16.045 3.29119 16.2437 3.48989C16.4424 3.68859 16.5583 3.95353 16.5583 4.25158V12.4976Z"
         fill={color}
      />
      <path
         d="M8.71007 4.30176C7.5841 4.30176 6.55749 4.76539 5.82892 5.49396C5.08379 6.23908 4.63672 7.24914 4.63672 8.37511C4.63672 9.50108 5.10035 10.5277 5.82892 11.2563C6.57404 12.0014 7.5841 12.4485 8.71007 12.4485C9.83604 12.4485 10.8627 11.9848 11.5912 11.2563C12.3363 10.5111 12.7834 9.50108 12.7834 8.37511C12.7834 7.24914 12.3198 6.22253 11.5912 5.49396C10.8627 4.76539 9.83604 4.30176 8.71007 4.30176ZM10.962 10.6436C10.3825 11.2066 9.58766 11.5709 8.71007 11.5709C7.83248 11.5709 7.03768 11.2066 6.45814 10.6436C5.87859 10.0641 5.53087 9.26926 5.53087 8.39167C5.53087 7.51408 5.89515 6.71928 6.45814 6.13973C7.03768 5.56019 7.83248 5.21247 8.71007 5.21247C9.58766 5.21247 10.3825 5.57675 10.962 6.13973C11.5415 6.71928 11.8893 7.51408 11.8893 8.39167C11.9058 9.26926 11.5415 10.0641 10.962 10.6436Z"
         fill={color}
      />
      <path
         d="M14.6043 5.90787C15.0524 5.90787 15.4157 5.54461 15.4157 5.09651C15.4157 4.64841 15.0524 4.28516 14.6043 4.28516C14.1562 4.28516 13.793 4.64841 13.793 5.09651C13.793 5.54461 14.1562 5.90787 14.6043 5.90787Z"
         fill={color}
      />
   </svg>
)

CameraIcon.defaultProps = {
   color: '#1F2531'
}