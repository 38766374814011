import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
  color?: string
}

export const InfoIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6" r="5.5" stroke={color}/>
    <path d="M5.66361 8.60722C5.66361 8.65522 5.68761 8.67122 5.72761 8.67122H6.27961C6.32761 8.67122 6.33561 8.65522 6.33561 8.61522V4.83122C6.33561 4.79122 6.31961 4.78322 6.28761 4.78322H5.71961C5.67961 4.78322 5.66361 4.79922 5.66361 4.83922V8.60722ZM5.99961 4.01522C6.24761 4.01522 6.40761 3.84722 6.40761 3.60722C6.40761 3.37522 6.26361 3.19922 6.00761 3.19922C5.76761 3.19922 5.59961 3.36722 5.59961 3.60722C5.59961 3.84722 5.75161 4.01522 5.99961 4.01522Z" fill={color}/>
  </svg>
)

InfoIcon.defaultProps = {
  color: "#C1C1C1"
}
