import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  width?: number;
  height?: number;
  color?: string;
}

export const CloseSmallIcon: FC<Props> = ({
  width,
  height,
  color,
  ...props
}) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L4 2.93934L6.46967 0.46967C6.76256 0.176777 7.23744 0.176777 7.53033 0.46967C7.82322 0.762563 7.82322 1.23744 7.53033 1.53033L5.06066 4L7.53033 6.46967C7.82322 6.76256 7.82322 7.23744 7.53033 7.53033C7.23744 7.82322 6.76256 7.82322 6.46967 7.53033L4 5.06066L1.53033 7.53033C1.23744 7.82322 0.762563 7.82322 0.46967 7.53033C0.176777 7.23744 0.176777 6.76256 0.46967 6.46967L2.93934 4L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z"
      fill={color}
    />
  </svg>
);

CloseSmallIcon.defaultProps = {
  width: 8,
  height: 8,
  color: '#1F2531',
};
