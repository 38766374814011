import React, { FC, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { push } from "connected-react-router";

/* modals & popups */
import EditUserInfoModal from "components/modals/users/EditUserInfo";
import PersonModal from "components/modals/PersonModal";
import PopupDelete from "components/modals/PopupDelete";

/* UI components */
import Loader from "components/Loader";
import OptionsMenu from "components/UI/controls/OptionsMenu";
import Link from "components/UI/controls/Link";
import { GoBackIcon } from "components/icons/GoBackIcon";

/* controllers */
import { deleteUserAction, getUserAction, updateUserAction } from "controllers/user/actions";
import { createPersonAction } from "controllers/person/actions";

/* constants */
import { PageEnum } from "config/constants";
import { personsTabs } from "config/constants/tabs";

/* types */
import { IStore } from "controllers/store";
import { IUserState } from "controllers/user/models";
import {userRoleModalKey} from "components/modals/UserRoleModal";
import { Helmet } from "react-helmet";
import { addNotification } from "controllers/modals/actions";
import { NotificationTypeEnum } from "controllers/modals/models.d";
import uuid from "@ternala/voltore-types/lib/card/utils/uuid";
import { callbackTypeEnum, requestCallback } from "utils";
import { EntityEnum } from "@ternala/voltore-types/lib/constants";
import {addShowedElement} from "../../controllers/showElement/actions";
import {IModalElement, ShowElementEnum} from "controllers/showElement/models.d";
import { PersonCreateResponse } from "@ternala/voltore-types";

interface Props extends RouteComponentProps<{ id?: string }> {
  userState: IUserState;
}

const UserInfo: FC<Props> = (props) => {
  const {
    userState: { userData },
    match: {
      params: { id },
    },
  } = props;
  const numberId = Number(id);
  const dispatch = useDispatch();
  const [isModalEditOpen, setIsModalEditOpen] = useState<boolean>(false);
  const [isPersonModalOpen, setIsPersonModalOpen] = useState<boolean>(false);
  const [isPopupDeleteOpen, setIsPopupDeleteOpen] = useState<boolean>(false);

  const currentUser = id ? userData[id] : undefined;

  useEffect(() => {
    dispatch(
      getUserAction.request({
        id: numberId,
      }),
    );
  }, []);

  return (
    <>
      {currentUser ? (
        <div id="user-info">
          <Helmet>
            <title>
              {currentUser?.person
                ? `${currentUser.person.firstName} ${currentUser.person.lastName}`
                : 'User'}{' '}
              | Voltore
              {process.env.REACT_APP_COMPANY_NAME
                ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
                : ''}
            </title>
            <meta name="description" content="Voltore application" />
          </Helmet>
          <div
            className="button-go-back"
            onClick={() => dispatch(push(`/${PageEnum.USERS}`))}
            title="Back to users">
            <GoBackIcon />
          </div>

          <header>
            <h2>Info</h2>
            <OptionsMenu
              on={{
                edit: () => setIsModalEditOpen(true),
                delete: () => setIsPopupDeleteOpen(true),
              }}
            />
          </header>

          <div className="user-info__content">
            <div className="column">
              <div className="data-field">
                <div className="data-field__key">Login</div>
                <div className="data-field__value">{currentUser?.login}</div>
              </div>

              {currentUser?.person && (
                <div className="data-field_with-link">
                  <div className="data-field__key">Person</div>

                  <Link
                    id={currentUser.person.id}
                    type={EntityEnum.person}
                    fullPath={`/${PageEnum.PEOPLE}/${currentUser.person.id}/${personsTabs.info.path}`}
                    newTabIcon>
                    {`${currentUser.person.firstName}${currentUser.person?.middleName ? " " + currentUser.person?.middleName : ""} ${
                      currentUser.person.lastName
                    } ${
                      currentUser.person.nickName
                        ? `(${currentUser.person.nickName})`
                        : ''
                    }`}
                  </Link>
                </div>
              )}
            </div>

            <div className="column">
              {currentUser?.roles && currentUser?.roles.length > 0 && (
                <div className="data-field">
                  <div className="data-field__key">User role</div>
                  <div className="data-field__value">
                    {currentUser?.roles.map((role) => role.title).join(', ')}
                  </div>
                </div>
              )}

              <div className="data-field">
                <div className="data-field__key">User type</div>
                <div className="data-field__value">
                  {currentUser?.type.title}
                </div>
              </div>

              <div className="data-field">
                <div className="data-field__key">Status</div>
                <div
                  className={`data-field__value ${
                    currentUser?.isActive ? 'active' : 'inactive'
                  }`}>
                  {currentUser?.isActive === true ? 'Active' : 'Inactive'}{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="item-info__loader_info-tab">
          <Loader />
        </div>
      )}

      {isModalEditOpen && (
        <EditUserInfoModal
          userId={Number(id)}
          onCreatePerson={() => setIsPersonModalOpen(true)}
          onCreateUserRole={() => {
            dispatch(
                addShowedElement({
                  id: uuid(),
                  key: userRoleModalKey,
                  type: ShowElementEnum.modal,
                  props: {},
                } as IModalElement),
            );
          }}
          onClose={() => setIsModalEditOpen(false)}
          onSubmit={(payload) =>
            dispatch(
              updateUserAction.request({
                ...payload,
                callback: (status: boolean) => {
                  requestCallback(dispatch, status, callbackTypeEnum.create)
                  if (payload.callback) payload.callback(status);
                },
              }),
            )
          }
        />
      )}

      {isPersonModalOpen && (
        <PersonModal
          onClose={() => setIsPersonModalOpen(false)}
          onSubmit={(payload) =>
            dispatch(
              createPersonAction.request({
                ...payload,
                callback: (res: false | PersonCreateResponse) => {
                  requestCallback(dispatch, !!res, callbackTypeEnum.create)
                  if (payload.callback) payload.callback(res);
                },
              }),
            )
          }
        />
      )}

      {isPopupDeleteOpen && (
        <PopupDelete
          onClose={() => setIsPopupDeleteOpen(false)}
          onSubmit={(callback) => {
            dispatch(
              deleteUserAction.request({
                id: Number(id),
                callback: (status: boolean) => {
                  if (status) {
                    // if delete was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Successfully deleted',
                        type: NotificationTypeEnum.success,
                        title: 'Successfully deleted',
                      }),
                    );
                    if (callback) callback();
                  } else {
                    // if delete was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                },
              }),
            );
            dispatch(push(`/${PageEnum.USERS}`));
          }}
        />
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  userState: store.user,
}))(UserInfo);
