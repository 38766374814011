import React, { useState } from 'react';

/* types */
import { EmailUpdateDTO, PhoneUpdateDTO } from '@ternala/voltore-types';

/* components */
import PopupPhone from 'components/modals/PopupPhone';
import PopupEmail from 'components/modals/PopupEmail';
import GenerateItem from './Field';

interface IProps {
  label: string;
  type: 'phone' | 'email';
  items: EmailUpdateDTO[] | PhoneUpdateDTO[];
  onDelete: (id: string | number) => void;
  onCreate: (
    value: (EmailUpdateDTO | PhoneUpdateDTO) & { uuid?: string },
  ) => void;
  changeFavorite: (id: string | number) => void;
  onUpdate: (
    value: (EmailUpdateDTO | PhoneUpdateDTO) & { uuid?: string },
  ) => void;
}

const MultiField = ({
  label,
  items,
  type,
  changeFavorite,
  onDelete,
  onUpdate,
  onCreate,
}: IProps) => {
  /* hooks */
  const [createPhone, setCreatePhone] = useState<boolean>(false);
  const [createEmail, setCreateEmail] = useState<boolean>(false);

  return (
    <>
      <div className="multi-field">
        <label className="multi-field-label">{label}</label>
        {items?.length ? (
          <div className="multi-field-items">
            {(items as any[])?.map((item: any, index) => (
              <GenerateItem
                item={item}
                key={index}
                items={items}
                onDelete={onDelete}
                onUpdate={onUpdate}
                isLast={index === items?.length - 1}
                changeFavorite={changeFavorite}
              />
            ))}
          </div>
        ) : (
          ''
        )}
        <button
          className="multi-field-button"
          type="button"
          onClick={() =>
            type === 'phone' ? setCreatePhone(true) : setCreateEmail(true)
          }>
          Add
        </button>
      </div>
      {createPhone ? (
        <PopupPhone
          phones={items as PhoneUpdateDTO[]}
          onClose={() => setCreatePhone(false)}
          onChange={(phone, callback) => {
            onCreate(phone);
            callback();
          }}
        />
      ) : (
        ''
      )}
      {createEmail ? (
        <PopupEmail
          emails={items as EmailUpdateDTO[]}
          onClose={() => setCreateEmail(false)}
          onChange={(email, callback) => {
            onCreate(email);
            callback();
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default MultiField;
