import React from 'react';

interface IPropsType {
  color: string
}
export const CheckMarkIcon = ({color} : IPropsType) => (
  <svg
    width='11'
    height='9'
    viewBox='0 0 11 9'
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.7071 0.792893C11.0976 1.18342 11.0976 1.81658 10.7071 2.20711L4 8.91421L0.292893 5.20711C-0.0976311 4.81658 -0.0976311 4.18342 0.292893 3.79289C0.683417 3.40237 1.31658 3.40237 1.70711 3.79289L4 6.08579L9.29289 0.792893C9.68342 0.402369 10.3166 0.402369 10.7071 0.792893Z'
      fill={color}
    />
  </svg>
);
