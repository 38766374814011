import React, { useEffect, useState } from 'react';
import { EntityEnum, SaleTransactionStatusEnum } from '@ternala/voltore-types/lib/constants';
import { Helmet } from 'react-helmet';
import { push } from 'connected-react-router';
import { connect, useDispatch } from 'react-redux';

/* controllers */
import {
  deleteSaleTransactionAction,
  getSaleTransactionAction,
  updateSaleTransactionAction,
  updateSaleTransactionActiveStatusAction,
} from 'controllers/property/actions/saleTransactions';
import { addNotification } from 'controllers/modals/actions';
import { NotificationTypeEnum } from 'controllers/modals/models.d';
import {
  IPropertyState,
  SaleTransactionUpdateRequestExpanded,
} from 'controllers/property/models.d';

/* functionality */
import uuid from 'utils/uuid';
import { enterprisesTabs, propertiesTabs } from 'config/constants/tabs';

/* components */
import Loader from 'components/Loader';
import Link from 'components/UI/controls/Link';
import NotFoundPage from 'pages/Static/NotFound';
import PopupDelete from 'components/modals/PopupDelete';
import OptionsMenu from 'components/UI/controls/OptionsMenu';
import PopupEditTransaction from 'components/modals/PopupEditTransaction';
import SaleTransactionModal from 'components/modals/properties/SaleTransactionModal';
import QuickChangeListingStatus from 'components/UI/controls/SelectListingStatusQuickChange';
import TransitionCard from 'components/UI/controls/TransitionCard';
import InvolvedPerson from '../InvolvedPerson';
import { CloseSmallIcon } from 'components/icons/CloseSmallIcon';

/* constants & utils */
import { PageEnum } from 'config/constants';
import {
  callbackTypeEnum,
  requestCallback,
  computeDaysOnMarket,
  withThousandsSeparators,
} from 'utils';

/* types */
import { IStore } from 'controllers/store';
import { IMAGE_TYPES } from "../../../../config";
import { FileIcon } from "../../../../components/icons/FileIcon";


interface IProps {
  onClose?: () => void;
  propertyState: IPropertyState;
  params: { id?: string; tabName?: string; transactionId: string };
}

const SaleTransactionContacts = ({
  onClose,
  propertyState: { propertyData, saleTransactionData },
  params,
}: IProps) => {
  const dispatch = useDispatch();
  const propertyId = Number(params?.id);
  const transactionId = Number(params?.transactionId);
  const property = propertyData[propertyId];
  console.log('transactionId: ', transactionId);
  const transaction = saleTransactionData[transactionId];
  const daysOnMarket = computeDaysOnMarket(transaction);

  const [loading, setLoading] = useState<boolean>(true);

  const [listingStatus, setListingStatus] = useState<
    SaleTransactionStatusEnum | undefined
  >(transaction?.activeStatus?.status);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getSaleTransactionAction.request({
        id: transactionId,
        property: propertyId,
        callback: () => setLoading(false),
      }),
    );
  }, []);

  useEffect(() => {
    if (transaction?.activeStatus) {
      setListingStatus(transaction.activeStatus.status);
    }
  }, [transaction?.activeStatus]);

  if (isNaN(transactionId)) {
    return <NotFoundPage />;
  }
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPopupDeleteOpen, setIsPopupDeleteOpen] = useState<boolean>(false);
  const [isPopupEditTransactionOpen, setIsPopupEditTransactionOpen] =
    useState<boolean>(false);
  const [listingStatusToUpdate, setListingStatusToUpdate] =
    useState<SaleTransactionStatusEnum>();

  const documents = transaction?.supplements?.filter(
    (supplement) =>
      !IMAGE_TYPES.some((type) => supplement.title?.endsWith(type)),
  );
  const images = transaction?.supplements?.filter((supplement) =>
    IMAGE_TYPES.some((type) => supplement.title?.endsWith(type)),
  );

  console.log('transaction: ', transaction);

  return transaction ? (
    <>
      <Helmet>
        <title>
          Sales {listingStatus ? `| Listing Status - ${listingStatus}` : ''}|
          Voltore{' '}
          {process.env.REACT_APP_COMPANY_NAME
            ? `| ${process.env.REACT_APP_COMPANY_NAME}`
            : ''}
        </title>
        <meta name="description" content="Voltore application" />
      </Helmet>
      <div
        id="transaction-info"
        className="transaction-info sale-transaction-info">
        <header>
          <h2>Transaction info</h2>

          <div className="options">
            <OptionsMenu
              on={{
                edit: () => setIsModalOpen(true),
                delete: () => setIsPopupDeleteOpen(true),
              }}
            />
            {onClose ? (
              <div className="cross-icon" onClick={onClose}>
                <CloseSmallIcon />
              </div>
            ) : (
              ''
            )}
          </div>
        </header>

        <div className="transaction-info__content">
          <div className="system-id">
            <span className="system-id__key">System id:</span>
            <span className="system-id__value">{transactionId}</span>
          </div>

          <div className="data-field">
            <div className={`data-field__key`}>Listing status</div>
            <div
              className="data-field__value"
              style={{
                lineHeight: '18px',
              }}>
              <QuickChangeListingStatus
                value={listingStatus}
                onChange={(status) => {
                  setListingStatusToUpdate(status as SaleTransactionStatusEnum);
                  setIsPopupEditTransactionOpen(true);
                }}
                statusHistory={transaction?.historyStatuses}
                statusType="sale"
                statusDate={transaction.activeStatus?.startDate}
              />
            </div>
          </div>

          <div className="data-field">
            <div
              className={`data-field__key ${
                transaction?.seller ? '' : 'no-information'
              }`}>
              Seller
            </div>

            {transaction?.seller ? (
              <Link
                id={transaction.seller.id}
                type={EntityEnum.enterprise}
                fullPath={`/${PageEnum.ENTERPRISES}/${transaction.seller.id}/${enterprisesTabs.info.title}`}
                newTabIcon>
                {transaction.seller.title}
              </Link>
            ) : (
              <span className="no-information">-</span>
            )}
          </div>

          <div className="data-field">
            <div
              className={`data-field__key ${
                transaction?.buyer ? '' : 'no-information'
              }`}>
              Buyer
            </div>

            {transaction?.buyer ? (
              <Link
                id={transaction.buyer.id}
                type={EntityEnum.enterprise}
                fullPath={`/${PageEnum.ENTERPRISES}/${transaction.buyer.id}/${enterprisesTabs.info.title}`}
                newTabIcon>
                {transaction.buyer.title}
              </Link>
            ) : (
              <span className="no-information">-</span>
            )}
          </div>

          <div className="data-field">
            <div
              className={`data-field__key ${
                transaction?.listPrice ? '' : 'no-information'
              }`}>
              List price
            </div>
            <div className="data-field__value">
              {transaction?.listPrice ? (
                `$${withThousandsSeparators(transaction.listPrice)}`
              ) : (
                <span className="no-information">-</span>
              )}
            </div>
          </div>

          <div className="data-field">
            <div
              className={`data-field__key ${
                transaction?.soldPrice ? '' : 'no-information'
              }`}>
              Sold price
            </div>
            <div className="data-field__value">
              {transaction?.soldPrice ? (
                `$${withThousandsSeparators(transaction.soldPrice)}`
              ) : (
                <span className="no-information">-</span>
              )}
            </div>
          </div>

          <div className="data-field">
            <div
              className={`data-field__key ${
                transaction?.capRate ? '' : 'no-information'
              }`}>
              Cap rate
            </div>
            <div className="data-field__value">
              {transaction?.capRate ? (
                `${transaction.capRate}%`
              ) : (
                <span className="no-information">-</span>
              )}
            </div>
          </div>

          <div className="data-field">
            <div
              className={`data-field__key ${
                transaction?.NOI ? '' : 'no-information'
              }`}>
              Noi
            </div>
            <div className="data-field__value">
              {transaction?.NOI ? (
                `$${withThousandsSeparators(transaction.NOI)}`
              ) : (
                <span className="no-information">-</span>
              )}
            </div>
          </div>

          <div className="data-field">
            <div
              className={`data-field__key ${
                transaction?.financing ? '' : 'no-information'
              }`}>
              Financing
            </div>
            <div className="data-field__value">
              {transaction?.financing ? (
                transaction.financing.title
              ) : (
                <span className="no-information">-</span>
              )}
            </div>
          </div>

          <div className="data-field">
            <div
              className={`data-field__key ${
                transaction?.leaseAtSale ? '' : 'no-information'
              }`}>
              % Leased at Sale
            </div>
            <div className="data-field__value">
              {transaction?.leaseAtSale ? (
                `${transaction.leaseAtSale}%`
              ) : (
                <span className="no-information">-</span>
              )}
            </div>
          </div>

          <div className="data-field">
            <div
              className={`data-field__key ${
                transaction?.saleType ? '' : 'no-information'
              }`}>
              Sale Type
            </div>
            <div className="data-field__value">
              {transaction?.saleType ? (
                transaction.saleType.title
              ) : (
                <span className="no-information">-</span>
              )}
            </div>
          </div>

          <div className="data-field">
            <div
              className={`data-field__key ${
                property?.sqft && transaction?.soldPrice ? '' : 'no-information'
              }`}>
              Building price/sqft
            </div>
            <div className="data-field__value">
              {property?.sqft && transaction?.soldPrice ? (
                `$${withThousandsSeparators(
                  Math.round(transaction.soldPrice / property.sqft),
                )}`
              ) : (
                <span className="no-information">-</span>
              )}
            </div>
          </div>

          <div className="data-field">
            <div
              className={`data-field__key ${
                property?.lotSqft ? '' : 'no-information'
              }`}>
              Lot price/sqft
            </div>
            <div className="data-field__value">
              {property?.lotSqft && transaction?.soldPrice ? (
                `$${withThousandsSeparators(Math.round(transaction.soldPrice / property.lotSqft))}`
              ) : (
                <span className="no-information">-</span>
              )}
            </div>
          </div>

          <div className="data-field">
            <div
              className={`data-field__key ${
                daysOnMarket > 0 ? '' : 'no-information'
              }`}>
              Days on market
            </div>
            <div className="data-field__value">
              {daysOnMarket > 0 ? (
                daysOnMarket
              ) : (
                <span className="no-information">-</span>
              )}
            </div>
          </div>

          {transaction?.supplements && transaction.supplements.length > 0 && (
            <>
              <h3>Supplements</h3>

              {/*{transaction.supplements.map((item) => (*/}
              {/*  <div className="supplement-item" key={item.id}>*/}
              {/*    <Link url={item.url} newTabIcon>*/}
              {/*      {item.title}*/}
              {/*    </Link>*/}
              {/*  </div>*/}
              {/*))}*/}
              {documents?.length ? (
                <div className="supplements-tab__documents-list">
                  {documents?.map((document) => (
                    <Link
                      className="document-supplement"
                      url={document.url}
                      key={document.id}
                      isNewTab={true}
                    >
                      <div className="document-supplement__image">
                        {document.preview ? <img src={document.preview } alt="" /> : <FileIcon />}
                      </div>
                      <div className="document-supplement__title">
                        {document.title}
                      </div>
                    </Link>
                  ))}
                </div>
              ) : ""}
              {images?.length ? (
                <div className="supplement-images">
                  {images?.map((image) => (
                    <div key={image.id}>
                      <Link url={image.url} className="supplement-image" isNewTab={true}>
                        <img src={image.url} alt="" />
                      </Link>
                      <div className="supplement-title">
                        <span>{image.title}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : ""}
            </>
          )}

          {transaction?.details ? (
            <>
              <h3>Details</h3>
              <div className="content-block__notes">{transaction.details}</div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      {/*<Tags*/}
      {/*  cards={transaction.cardTagConnects || []}*/}
      {/*  tabName={'sales'}*/}
      {/*  entity={{*/}
      {/*    id: transactionId,*/}
      {/*    type: TagEntityEnum.Sale_Transaction,*/}
      {/*  }}*/}
      {/*  preview={!!onClose}*/}
      {/*  loading={loading}*/}
      {/*/>*/}
      <TransitionCard
        condition={
          !!(
            transaction?.involvementsFromBuyerSide &&
            transaction.involvementsFromBuyerSide.length > 0
          )
        }>
        <>
          <h3>Involved | Buy Side</h3>
          {transaction?.involvementsFromBuyerSide?.map((item) => (
            <InvolvedPerson
              key={item.id}
              data={{
                person: item.person,
                enterprise: item.enterprise,
                positionOrRole: item.involvementType?.title,
              }}
            />
          ))}
        </>
      </TransitionCard>
      <TransitionCard
        condition={
          !!(
            transaction?.involvementsFromSellerSide &&
            transaction.involvementsFromSellerSide.length > 0
          )
        }>
        <>
          <h3>Involved | Sell Side</h3>
          {transaction?.involvementsFromSellerSide?.map((item) => (
            <InvolvedPerson
              key={item.id}
              data={{
                person: item.person,
                enterprise: item.enterprise,
                positionOrRole: item.involvementType?.title,
              }}
            />
          ))}
        </>
      </TransitionCard>
      {isPopupEditTransactionOpen && (
        <PopupEditTransaction
          onClose={() => setIsPopupEditTransactionOpen(false)}
          onSubmitNo={() => {
            if (listingStatusToUpdate) {
              dispatch(
                updateSaleTransactionActiveStatusAction.request({
                  property: propertyId,
                  idTransaction: transactionId,
                  status: listingStatusToUpdate,
                  startDate: new Date(),
                  callback: (status: boolean) => {
                    requestCallback(dispatch, status, callbackTypeEnum.update)
                  },
                }),
              );
            }
          }}
          onSubmitYes={(callback) => {
            if (listingStatusToUpdate) {
              dispatch(
                updateSaleTransactionActiveStatusAction.request({
                  property: propertyId,
                  idTransaction: transactionId,
                  status: listingStatusToUpdate,
                  startDate: new Date(),
                  callback: (status: boolean) => {
                    if (status) {
                      // if update was success - the additional popup appear
                      dispatch(
                        addNotification({
                          id: uuid(),
                          text: `Successfully updated`,
                          type: NotificationTypeEnum.success,
                          title: `Successfully updated`,
                        }),
                      );
                      if (callback) callback();
                    } else {
                      // if update was wrong - the additional popup appear
                      dispatch(
                        addNotification({
                          id: uuid(),
                          text: 'Something goes wrong',
                          type: NotificationTypeEnum.error,
                          title: 'Something goes wrong',
                        }),
                      );
                    }
                  },
                }),
              );
            }
            setIsModalOpen(true);
          }}
        />
      )}

      {isModalOpen && (
        <SaleTransactionModal
          property={{
            id: propertyId,
            transactionId,
          }}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(payload) => {
            dispatch(
              updateSaleTransactionAction.request({
                ...(payload as SaleTransactionUpdateRequestExpanded),
                callback: (status: boolean, err?: string) => {
                  requestCallback(dispatch, status, callbackTypeEnum.update, err)
                  if (payload.callback) payload.callback(status);
                },
              }),
            );
          }}
        />
      )}

      {isPopupDeleteOpen && (
        <PopupDelete
          onClose={() => setIsPopupDeleteOpen(false)}
          onSubmit={(callback) => {
            dispatch(
              deleteSaleTransactionAction.request({
                property: propertyId,
                id: transactionId,
                callback: (status: boolean) => {
                  if (status) {
                    // if delete was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully deleted`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully deleted`,
                      }),
                    );
                    if (callback) callback();
                  } else {
                    // if delete was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Failed to delete',
                        type: NotificationTypeEnum.error,
                        title: 'Failed to delete',
                      }),
                    );
                  }
                },
              }),
            );
            dispatch(
              push(
                `/${PageEnum.PROPERTIES}/${params.id}/${propertiesTabs.sales.path}`,
              ),
            );
          }}
        />
      )}
    </>
  ) : onClose ? (
    <div className="preview-modal-loader">
      <Loader />
    </div>
  ) : (
    <Loader />
  );
};

export default connect((store: IStore) => ({
  propertyState: store.property,
}))(SaleTransactionContacts);
