import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import {
  EnterpriseGetListFilters,
  ExportFileDTO
} from "@ternala/voltore-types";
import {
  ExportFileStatusEnum,
  ExportMainEntityEnum
} from "@ternala/voltore-types/lib/constants";

/* components */
import Button from "components/UI/controls/Button";

// utils
import { getAccessTokenUtil, withThousandsSeparators } from "utils";

// controllers
import { ExportApi } from "controllers/export/transport/export.api";
import { PersonsGetListFiltersExtended } from "controllers/person/models";
import { PropertyGetListFiltersExtended } from "controllers/property/models";
import { generatePersonName } from "pages/People/utils";
import DownloadExportIcon from "components/icons/DownloadExportIcon";
import SmallLoader from "../SmallLoader/SmallLoader";
import Loader from "../Loader";
import { CancelIcon } from "../icons/CancelIcon";

export const exportTemplateFilesModalKey = "ExportTemplateFilesModal";

export interface IExportTemplateFilesProps {
  closeModal: Function;
  id: number;
  entityType: ExportMainEntityEnum;
  filters: {
    persons?: PersonsGetListFiltersExtended;
    enterprises?: EnterpriseGetListFilters;
    property?: PropertyGetListFiltersExtended;
  };
}

const ExportTemplateFilesModalContent: FC<IExportTemplateFilesProps> = (
  props
) => {
  const intervalRef = useRef<number>();

  const dispatch = useDispatch();
  const [lockTwice, setLockTwice] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [items, setItems] = useState<ExportFileDTO[]>([]);
  const { closeModal, id } = props;

  useEffect(() => {
    setIsLoading(true);
    getFiles();
    clearInterval(intervalRef.current);
    intervalRef.current = window.setInterval(() => {
      getFiles();
    }, 10000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const getFiles = async () => {
    const token = await getAccessTokenUtil(dispatch).catch((e) => {
      setError(e);
    });
    if (token) {
      const res = await ExportApi.getFileList(id, token);
      if (typeof res === "string" || "message" in res) {
        setIsLoading(false);
        setError(typeof res === "string" ? res : res.message);
      } else {
        setIsLoading(false);
        setItems(res.items);
      }
    }
  };

  const generateNewFile = () => {
    if (lockTwice) {
      setError("You have already generated the export file");
      return;
    }
    setLockTwice(true);
    setIsLoading(true);
    getAccessTokenUtil(dispatch).then((token) => {
      ExportApi.downloadFile(
        id,
        props.filters || {},
        props.entityType,
        token
      ).then((res) => {
        if (typeof res === "string" || "message" in res) {
          setError(typeof res === "string" ? res : res.message);
          setLockTwice(false);
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        setItems([res, ...items]);
      });
    });
  };

  const generateIcon = (status: ExportFileStatusEnum) => {
    switch (status) {
      case ExportFileStatusEnum.generate:
        return <SmallLoader />;
      case ExportFileStatusEnum.canceled:
        return <CancelIcon />;
      default: {
        return <DownloadExportIcon color={"#1F1F1F"} />;
      }
    }
  };

  const generateItem = (item: ExportFileDTO) => (
    <a
      data-status={item.status}
      href={item.url}
      download
      className={"row" + (item.status === ExportFileStatusEnum.canceled ? " error" : "")}
      style={{
        pointerEvents:
          item.status === ExportFileStatusEnum.done || !item.status
            ? "all"
            : "none"
      }}>
      <div className={"column"}>
        {generateIcon(item.status)}
      </div>
      <div className={"column name"}>
        {item.user?.id === 1
          ? "Super Admin"
          : item.user?.person
            ? generatePersonName(item.user?.person)
            : "Without person"}
        {item.status === ExportFileStatusEnum.generate ? (
          <div className="subtext">
            File generation might take a while. You can close this window and
            reopen it later. Hopefully your file will be compiled by then :)
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={"column"}>
        {generateColumn(item.createdAt, item.status)}
        {item.status === ExportFileStatusEnum.generate ? (
          <strong>
            {item.alreadyDone ? withThousandsSeparators(item.alreadyDone) : ""} / {item.all ? withThousandsSeparators(item.all) : ''}
          </strong>
        ) : (
          ""
        )}
      </div>
    </a>
  );

  const generateColumn = (date: Date, status?: ExportFileStatusEnum) => {
    if (!status) {
      return moment(new Date(date)).format("L LT");
    }
    switch (status) {
      case ExportFileStatusEnum.generate:
        return "Processing";
      case ExportFileStatusEnum.canceled:
        return "Canceled";
      case ExportFileStatusEnum.done:
        return moment(new Date(date)).format("L LT");
    }
  };

  return (
    <form className="modal-content export-modal">
      <div
        className="modal__close"
        onClick={() => closeModal()}
        title="Close"
      />
      <div className="modal-title">Generated files</div>
      {isLoading ? (
        <div className="loader-full-modal">
          <Loader />
        </div>
      ) : !items.length ? (
        <div className="empty-message">
          Unfortunately, there are no items yet
        </div>
      ) : (
        <div className={"export-table"}>
          <div className={"table-head"}>
            <div className={"row"}>
              <div className={"column"} />
              <div className={"column"}>Created by</div>
              <div className={"column"}>Created at</div>
            </div>
          </div>
          <div className={"table-body"}>{items.map(generateItem)}</div>
        </div>
      )}

      {error ? <div className="error-message">{error}</div> : ""}

      <footer className="modal-footer" style={{ justifyContent: "flex-start" }}>
        <Button variant={"dark"} onClick={generateNewFile}>
          GENERATE NEW FILE
        </Button>
      </footer>
    </form>
  );
};

export default ExportTemplateFilesModalContent;
