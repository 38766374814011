import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const ViewIcon: FC<Props> = ({ width, height, fill, ...props }) => (
   <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M10.9301 4.13925C10.8318 4.00474 8.49039 0.845703 5.49995 0.845703C2.50951 0.845703 0.167965 4.00474 0.0698029 4.13912C-0.0232676 4.26673 -0.0232676 4.43981 0.0698029 4.56742C0.167965 4.70193 2.50951 7.86096 5.49995 7.86096C8.49039 7.86096 10.8318 4.7019 10.9301 4.56752C11.0233 4.43994 11.0233 4.26673 10.9301 4.13925ZM5.49995 7.13525C3.29717 7.13525 1.38933 5.03853 0.824573 4.35309C1.3886 3.66704 3.29245 1.57142 5.49995 1.57142C7.70262 1.57142 9.61033 3.66777 10.1753 4.35358C9.6113 5.03961 7.70746 7.13525 5.49995 7.13525Z"
         fill={fill}
      />
      <path
         d="M5.50005 2.17578C4.30032 2.17578 3.32422 3.15246 3.32422 4.35291C3.32422 5.55335 4.30032 6.53003 5.50005 6.53003C6.69977 6.53003 7.67587 5.55335 7.67587 4.35291C7.67587 3.15246 6.69977 2.17578 5.50005 2.17578ZM5.50005 5.80431C4.70018 5.80431 4.04951 5.15322 4.04951 4.35291C4.04951 3.55259 4.7002 2.9015 5.50005 2.9015C6.29989 2.9015 6.95058 3.55259 6.95058 4.35291C6.95058 5.15322 6.29991 5.80431 5.50005 5.80431Z"
         fill={fill}
      />
   </svg>
)

ViewIcon.defaultProps = {
   width: 11,
   height: 8,
   fill: 'white'
}