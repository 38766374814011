import React, { FC } from 'react';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';

// Actions
import { loginAction } from 'controllers/auth/actions';

// Custom interfaces
import { IField } from '../../models/form';
import { ISimpleState, IStore } from 'controllers/store';

// Utils
import { Form } from 'components/UI/Form';

// Images
import bgLogin from 'assets/img/login-bg.png';
import { Helmet } from 'react-helmet';
import CustomScrollbars from 'components/CustomScrollbars';

interface Props {
  loginAction: (data: { login: string; password: string }) => void;
  authState: ISimpleState | null;
}

type nameType = 'login' | 'password';

export interface ILoginField extends IField {
  name: nameType;
}

const listOfFields: ILoginField[] = [
  {
    title: 'Log in',
    name: 'login',
    type: 'text',
    placeholder: 'example@domain.com',
  },
  {
    title: 'Password',
    name: 'password',
    type: 'password',
    placeholder: 'Enter your password',
  },
];

const Login: FC<Props> = ({ loginAction, authState }) => {
  return (
    <CustomScrollbars style={{ height: '100vh' }}>
      <Helmet>
        <title>
          Login | Voltore
          {process.env.REACT_APP_COMPANY_NAME
            ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
            : ''}
        </title>
        <meta name="description" content="Voltore application" />
      </Helmet>
      <section className="section-login">
        <div className="left">
          <img src={bgLogin} alt="" className="bg" />
        </div>

        <div className="right">
          <div className="form__wrapper">
            <h4>Log in</h4>
            <h3 className="app-title">Welcome to voltore</h3>
            <Form
              onSubmit={(values) => {
                loginAction({
                  login: values.login || '',
                  password: values.password || '',
                });
              }}
              error={
                authState?.error
                  ? {
                      code: authState.code || '',
                      message: authState.message || '',
                    }
                  : undefined
              }
              submitBtn="Log in"
              listOfField={listOfFields}
            />
          </div>
        </div>
      </section>
    </CustomScrollbars>
  );
};

export default connect(
  (state: IStore) => ({
    authState: state.auth.state,
  }),
  {
    loginAction: loginAction.request,
  },
)(Login);
