import {INamespaceInterface} from "./models";

export const namespaces: INamespaceInterface[] = [
  {
    title: 'event',
    events: [
      // {
      //   eventName: "NewEvent",
      //   action: addEvent,
      // },
    ]
  },
  {
    title: "chat",
    events: [

    ]
  }
]