import {
  INotification,
  notificationKey,
} from 'components/elements/Notification';
import {
  IUserRoleModalProps,
  userRoleModalKey,
} from 'components/modals/UserRoleModal';
import {
  createUserInfoModalKey,
  ICreateUserInfoModalProps,
} from 'components/modals/users/CreateUserInfo';
import {
  IPersonModalProps,
  personModalKey,
} from 'components/modals/PersonModal';
import {
  IPropertyModalProps,
  propertyModalKey,
} from 'components/modals/properties/PropertyModal';
import {
  ITagCategoryModalProps,
  tagCategoryModalKey,
} from 'components/modals/PopupTagGroup';
import { ITagModalProps, tagModalKey } from 'components/modals/PopupTag';
import {
  ITaxonomyModalProps,
  taxonomyModalKey,
} from 'components/modals/PopupTaxonomy';
import { EntityEnum } from '@ternala/voltore-types/lib/constants/main';
import {
  ExportTemplateFilesModalKey, IExportTemplateFilesProps
} from "../../components/modals/ExportTemplateFilesContent";

export interface IShowElementState {
  isShow: boolean;
  showedElements: IShowElement[];
  selectedEntity?: ISelectEntityData;
}

export enum ShowElementEnum {
  modal = 'modal',
  notification = 'notification',
}

type modalKeys =
  | propertyModalKey
  | tagCategoryModalKey
  | tagModalKey
  | taxonomyModalKey
  | userRoleModalKey
  | createUserInfoModalKey
  | exportTemplateFilesModalKey
  | personModalKey;
type notificationKeys = notificationKey;

type modalProps =
  | IPropertyModalProps
  | ITagCategoryModalProps
  | ITagModalProps
  | ITaxonomyModalProps
  | IUserRoleModalProps
  | ICreateUserInfoModalProps
  | IExportTemplateFilesProps
  | IPersonModalProps;
type notificationProps = INotification;

export interface IDefaultElement {
  id: string;
  timeout?: number; // if you want to close this element after {timeout} milliseconds
  callback?: (res: any) => void;
}

export interface IModalElement extends IDefaultElement {
  type: ShowElementEnum.modal;
  props: modalProps;
  key: modalKeys;
}

export interface INotificationElement extends IDefaultElement {
  type: ShowElementEnum.notification;
  props: notificationProps;
  key: notificationKeys;
}

export type IShowElement = IModalElement | INotificationElement;

export interface ISelectEntityData {
  ids: number[];
  type: EntityEnum;
}
