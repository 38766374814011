/* eslint-disable @typescript-eslint/no-unused-vars */
import Geocode from "react-geocode";

import { Config } from "../../../../config/api";

/* constants */
import { LeaseStatusVisualization } from 'config/constants'

/* marker icons */
import marker from 'assets/icons/map/marker/marker.svg'
import propertyMarker from 'assets/icons/map/marker/primary.svg'

import leaseAvailableMarker from 'assets/icons/map/marker/leases/available.svg'
import leaseLeasedMarker from 'assets/icons/map/marker/leases/leased.svg'
import leaseWillBeLeasedMarker from 'assets/icons/map/marker/leases/will-be-leased.svg'

import leaseAvailableOverlapMarker from 'assets/icons/map/marker/leases/available_overlap.svg'
import leaseLeasedOverlapMarker from 'assets/icons/map/marker/leases/leased_overlap.svg'
import leaseWillBeLeasedOverlapMarker from 'assets/icons/map/marker/leases/will-be-leased_overlap.svg'

import leaseAvailableInactiveMarker from 'assets/icons/map/marker/leases/available_inactive.svg'
import leaseLeasedInactiveMarker from 'assets/icons/map/marker/leases/leased_inactive.svg'
import leaseWillBeLeasedInactiveMarker from 'assets/icons/map/marker/leases/will-be-leased_inactive.svg'

import leaseAvailableOverlapInactiveMarker from 'assets/icons/map/marker/leases/available_overlap_inactive.svg'
import leaseLeasedOverlapInactiveMarker from 'assets/icons/map/marker/leases/leased_overlap_inactive.svg'
import leaseWillBeLeasedOverlapInactiveMarker from 'assets/icons/map/marker/leases/will-be-leased_overlap_inactive.svg'

import leaseAvailableSelectedMarker from 'assets/icons/map/marker/leases/available_selected.svg'
import leaseLeasedSelectedMarker from 'assets/icons/map/marker/leases/leased_selected.svg'
import leaseWillBeLeasedSelectedMarker from 'assets/icons/map/marker/leases/will-be-leased_selected.svg'

import leaseAvailableOverlapSelectedMarker from 'assets/icons/map/marker/leases/available_overlap_selected.svg'
import leaseLeasedOverlapSelectedMarker from 'assets/icons/map/marker/leases/leased_overlap_selected.svg'
import leaseWillBeLeasedOverlapSelectedMarker from 'assets/icons/map/marker/leases/will-be-leased_overlap_selected.svg'

import zipCodeIcon from 'assets/icons/map/marker/zipCodeIcon.svg';
import countyIcon from 'assets/icons/map/marker/countyIcon.svg';

/* types */
import { LeaseTransactionFullExpandDTO } from '@ternala/voltore-types'
import { IPoint } from '@ternala/voltore-types/lib/modules/property/filters/area'

export interface IMarker {
   map?: google.maps.Map;
   coords: IPoint;
   leaseStatus?: LeaseStatusVisualization;
   transaction?: LeaseTransactionFullExpandDTO;
   selected?: boolean;
   count?: number;
   title?: string;
   defaultColor?: string;
   iconType?: "property" | "county" | "zipCode"
}

export class Marker {
   constructor({
      map,
      coords,
      leaseStatus,
      selected,
      count,
      title,
      defaultColor,
      iconType
   }: IMarker) {
      let color = (count || 0) > Math.max(10, 50) ? "#ff0000" : "#0000ff";

      const svg = window.btoa(`
        <svg fill="${defaultColor || color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
          <circle cx="120" cy="120" opacity=".6" r="70" />
          <circle cx="120" cy="120" opacity=".3" r="90" />
          <circle cx="120" cy="120" opacity=".2" r="110" />
        </svg>`);

      let icon = leaseStatus ? this.generateMarkerIcon(leaseStatus, selected) : propertyMarker;

      const iconBlock = (iconType === "county" ? {
         icon: countyIcon,
         title: title || `County with ${count} ${(count || 0) > 1 ? "properties" : "property"}`,
      } : (iconType === "zipCode" ? {
         icon: zipCodeIcon,
         title: title || `ZipCode with ${count} ${(count || 0) > 1 ? "properties" : "property"}`,
      } : {
         icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            scaledSize: new google.maps.Size(45, 45),
         },
      }));

      this.marker = new google.maps.Marker({
         position: {
            lat: Number(coords.latitude),
            lng: Number(coords.longitude)
         },
         optimized: true,
         visible: true,
         map,
         ...(count ? {
            ...iconBlock,
            label: {
               text: String(count),
               color: iconType === "zipCode" ? "rgba(0,0,0,0.9)" : "#000",
               fontSize: "12px",
            }
         } : {
            icon
         })
      })
   }

   marker: google.maps.Marker
   customMarker: StyledMarker = {
      url: marker,
      size: new google.maps.Size(20, 32),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(5, 25),
   }
   selected?: boolean

   generateMarkerIcon(leaseStatus: LeaseStatusVisualization, selected?: boolean) {
      switch (leaseStatus) {
         case LeaseStatusVisualization.AVAILABLE:
            return selected ? leaseAvailableSelectedMarker : leaseAvailableMarker
         case LeaseStatusVisualization.LEASED:
            return selected ? leaseLeasedSelectedMarker : leaseLeasedMarker
         case LeaseStatusVisualization.WILL_BE_LEASED:
            return selected ? leaseWillBeLeasedSelectedMarker : leaseWillBeLeasedMarker
         case LeaseStatusVisualization.AVAILABLE_OVERLAP:
            return selected ? leaseAvailableOverlapSelectedMarker : leaseAvailableOverlapMarker
         case LeaseStatusVisualization.LEASED_OVERLAP:
            return selected ? leaseLeasedOverlapSelectedMarker : leaseLeasedOverlapMarker
         case LeaseStatusVisualization.WILL_BE_LEASED_OVERLAP:
            return selected ? leaseWillBeLeasedOverlapSelectedMarker : leaseWillBeLeasedOverlapMarker
         case LeaseStatusVisualization.AVAILABLE_INACTIVE:
            return selected ? leaseAvailableSelectedMarker : leaseAvailableInactiveMarker
         case LeaseStatusVisualization.LEASED_INACTIVE:
            return selected ? leaseLeasedSelectedMarker : leaseLeasedInactiveMarker
         case LeaseStatusVisualization.WILL_BE_LEASED_INACTIVE:
            return selected ? leaseWillBeLeasedSelectedMarker : leaseWillBeLeasedInactiveMarker
         case LeaseStatusVisualization.AVAILABLE_OVERLAP_INACTIVE:
            return selected ? leaseAvailableOverlapSelectedMarker : leaseAvailableOverlapInactiveMarker
         case LeaseStatusVisualization.LEASED_OVERLAP_INACTIVE:
            return selected ? leaseLeasedOverlapSelectedMarker : leaseLeasedOverlapInactiveMarker
         case LeaseStatusVisualization.WILL_BE_LEASED_OVERLAP_INACTIVE:
            return selected ? leaseWillBeLeasedOverlapSelectedMarker : leaseWillBeLeasedOverlapInactiveMarker
         default:
            return propertyMarker
      }
   }
}

export class SingleMarker {
   constructor(
      map: google.maps.Map,
      onMarkerPlaced: (location: IPoint) => void,
      coords?: IPoint,
      dataSetter?: (value: any) => void
   ) {
      this.map = map

      const geocoder = new google.maps.Geocoder();

      google.maps.event.addListener(map, 'click', (event: any) => {
         this.clearMarkers()
         this.pinMarker(event.latLng)

         onMarkerPlaced({
            latitude: event.latLng.lat(),
            longitude: event.latLng.lng()
         })

         Geocode.setApiKey(Config.GOOGLE_GEO_API_KEY);
         Geocode.setLanguage("en");
         Geocode.setRegion("es");
         // @ts-ignore
         Geocode.setLocationType("ROOFTOP");


            Geocode.fromLatLng(String(event.latLng.lat()), String(event.latLng.lng())).then(
               (response) => {
                 const address = response.results[0].formatted_address;

                 if (dataSetter) {
                  dataSetter(response.results[0])
                 }
               },
               (error) => {
                 console.error(error);
               }
             );


      })

      if (coords?.latitude !== undefined && coords?.longitude !== undefined) {
         this.clearMarkers()
         const marker = new Marker({ map, coords }).marker
         this.markers.push(marker)
      }
   }

   private map: google.maps.Map
   private markers: google.maps.Marker[] = []

   public addNewMarker (coordinates: IPoint) {
      const icon: StyledMarker = {
         url: propertyMarker,
         anchor: new google.maps.Point(9, 8)
      }

      const position = coordinates

         this.clearMarkers();

         this.markers.push(new google.maps.Marker({
            //@ts-ignore
            position,
            optimized: false,
            visible: true,
            map: this.map,
            icon
         }))

   }

   private pinMarker(position: google.maps.LatLng): void {
      const icon: StyledMarker = {
         url: propertyMarker,
         anchor: new google.maps.Point(9, 8)
      }

      this.markers.push(new google.maps.Marker({
         position,
         optimized: false,
         visible: true,
         map: this.map,
         icon
      }))
   }

   private clearMarkers(): void {
      for (const marker in this.markers) {
         this.markers[marker].setMap(null)
      }
   }
}
