import React, { FC, useState, useEffect, MouseEvent, FormEvent } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  GenderEnum
} from '@ternala/voltore-types/lib/constants'

import {
  EmailUpdateDTO,
  PersonCreateResponse,
  PersonUpdateResponse,
  PhoneUpdateDTO,
  AddressShortDTO
} from '@ternala/voltore-types';

/* UI components */
import PopupTag from './PopupTag';
import Modal from 'components/Hocs/Portal';
import PopupTagGroup from './PopupTagGroup';
import Input from 'components/UI/controls/Input';
import Button from 'components/UI/controls/Button';
import Avatar from 'components/UI/controls/InputImage';
import TextArea from 'components/UI/controls/TextArea';
import CustomScrollbars from 'components/CustomScrollbars';
import MultiField from 'components/UI/controls/MultiField/MultiField';
import SelectMultiSearch from 'components/UI/controls/SelectMultiSearch';

/* constants & controllers*/
import { genderOptions, RequestActionEnum } from "config/constants";
import {
  createPersonAction,
  updatePersonAction,
} from 'controllers/person/actions';
import {
  IPersonState,
  PersonCreateRequestExpanded,
  PersonExpandedDTO,
  PersonUpdateRequestExpanded,
} from 'controllers/person/models';
import { IStore } from 'controllers/store';
import { ITagState, TagTypeEnum } from 'controllers/tag/models.d';
import { TagApi } from 'controllers/tag/transport/tag.api';

/* types */
import { OptionType } from 'models';
import { loadTagsOptions } from 'config/constants/select-options';


import { omit } from 'lodash';
import GoogleMapSearchAutoComplete from '../UI/controls/GoogleMapsSearch/AutoComplete';
import { removeShowedElement } from 'controllers/showElement/actions';

import {
  getAccessTokenUtil,
  callbackTypeEnum,
  requestCallback,
  getMainImage
} from "utils";
import Select from "../UI/controls/Select";

export const personModalKey = 'PersonModal';

export interface IPersonModalProps {
  showElementId?: string;
  isByShowElement?: boolean;
  id?: number;
  completeAction?: (person: PersonExpandedDTO) => void;

  personId?: number;
  personsState: IPersonState;
  loadPersons?: () => void;
  searchState?: any;
  onClose: () => void;

  onSubmit: (
    payload: (PersonCreateRequestExpanded | PersonUpdateRequestExpanded) & {
      callback?: Function;
    },
    requestAction: RequestActionEnum,
  ) => void;

  tagState: ITagState;
}

const PersonModal: FC<IPersonModalProps> = (props) => {
  const {
    personId,
    personsState: { personData },
    tagState: { entities },
    onClose,
    showElementId,
    isByShowElement,
    completeAction,
  } = props;

  const editablePerson = personId ? personData[personId] : undefined;

  const [photo, setPhoto] = useState<File | null>(null);
  const [isValidate, setIsValidate] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>(
    getMainImage(editablePerson?.images)?.url || '',
  );

  const [gender, setGender] = useState<GenderEnum | undefined>(
    editablePerson?.gender || undefined
  );

  const [firstName, setFirstName] = useState<string>(
    editablePerson?.firstName || '',
  );
  const [lastName, setLastName] = useState<string>(
    editablePerson?.lastName || '',
  );
  const [middleName, setMiddleName] = useState<string>(
    editablePerson?.middleName || '',
  );
  const [nickname, setNickname] = useState<string>(
    editablePerson?.nickName || '',
  );
  const [emails, setEmails] = useState<(EmailUpdateDTO & { uuid?: string })[]>(
    editablePerson?.emails || [],
  );

  const [email, setEmail] = useState<string>(
    ""
  );
  const [phones, setPhones] = useState<(PhoneUpdateDTO & { uuid?: string })[]>(
    editablePerson?.phones || [],
  );
  const [description, setDescription] = useState<string>(
    editablePerson?.description || '',
  );
  const [tags, setTags] = useState<OptionType[] | undefined>(
    entities[TagTypeEnum.person][Number(personId)]
      ?.filter((item) => item !== null)
      .map((item) => ({
        label: item.title,
        value: item.id,
      })) || [],
  );
  const [requestProcess, setRequestProcess] = useState<boolean>(false);
  const [isTagCreationOpen, setIsTagCreationOpen] = useState<boolean>(false);
  const [isTagCategoryCreationOpen, setIsTagCategoryCreationOpen] =
    useState<boolean>(false);
  const [address, setAddress] = useState<AddressShortDTO | undefined>(
    editablePerson?.mailingAddress,
  );

  let dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);
    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onCloseHandler();
    }
  };

  const onCloseHandler = () => {
    if (isByShowElement) {
      if (showElementId != null) {
        dispatch(removeShowedElement(showElementId));
      }
    } else {
      onClose?.();
    }
  };

  const isDisabled = !!(!firstName || !lastName || requestProcess || email);

  const submitData = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if(isDisabled){
      !isValidate && setIsValidate(true);
      return;
    }
    setRequestProcess(true);
    const createPayload = (): PersonCreateRequestExpanded => {
      const payload: PersonCreateRequestExpanded = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        nickName: nickname.trim() || '',
        gender
      };
      if (address && address.address && address.address !== ' ') {
        payload.mailingAddress = address;
      }
      if (middleName) {
        payload.middleName = middleName;
      }
      if (emails.length)
        payload.emails = emails.map((email) => omit(email, ['uuid']));
      if (phones.length)
        payload.phones = phones.map((phone) => omit(phone, ['uuid']));
      if (description) payload.description = description;
      if (photo) payload.image = photo;
      if (tags)
        payload.tags = tags
          ?.filter(
            (item) => item.label !== undefined && item.value !== undefined,
          )
          .map((item) => item.value as number);
      return payload;
    };

    if (editablePerson && personId) {
      const payload: ReturnType<typeof updatePersonAction.request>['payload'] =
        {
          ...createPayload(),
          id: Number(personId),
          callback: (res: false | PersonUpdateResponse) => {
            requestCallback(dispatch, Boolean(res), callbackTypeEnum.update);
            if (res) {
              completeAction?.(res);
              onCloseHandler();
            }
          },
        };
      if (!imageUrl) payload.isImageDeleted = true;
      console.log('payload:  ', payload);
      dispatch(
        updatePersonAction.request({
          ...payload,
          callback: (res: false | PersonUpdateResponse) => {
            requestCallback(dispatch, Boolean(res), callbackTypeEnum.update);
            if (res) {
              completeAction?.(res);
              onCloseHandler();
            }
          },
        }),
      );
    } else {
      dispatch(
        createPersonAction.request({
          ...createPayload(),
          callback: (res: false | PersonCreateResponse) => {
            requestCallback(dispatch, Boolean(res), callbackTypeEnum.create);
            if (res) {
              completeAction?.(res);
              onCloseHandler();
            }
          },
        }),
      );
    }
  };

  return (
    <>
      <Modal>
        <div className="modal modal-person">
          <div className="scroll-area">
            <CustomScrollbars
              style={{
                width: '700px',
                height: '100%',
              }}
              renderView={(props) => (
                <div
                  {...props}
                  className={'scroll-area'}
                  style={{
                    position: 'absolute',
                    inset: '0px',
                    overflowY: 'scroll',
                    marginRight: `${
                      navigator.platform === 'MacIntel' ? '-17px' : '-35px'
                    }`,
                    marginBottom: '-17px',
                  }}
                />
              )}>
              <form className="modal-content" onSubmit={submitData}>
                <div
                  className="modal__close"
                  onClick={onCloseHandler}
                  title="Close"
                />

                <div className="modal-title">
                  {personId ? 'edit' : 'create'} person
                </div>

                <Avatar
                  imageUrl={imageUrl}
                  firstName={editablePerson?.firstName}
                  lastName={editablePerson?.lastName}
                  onChange={(image: File | null) => setPhoto(image)}
                  onDelete={() => setImageUrl('')}
                />

                <div className="flex-container">
                  <div>
                    <Input
                      label="first name"
                      placeholder="Type here"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />

                    <Input
                      label="nickname"
                      placeholder="Type here"
                      value={nickname}
                      onChange={(e) => setNickname(e.target.value)}
                    />

                    <Input
                      label="middle name"
                      placeholder="Type here"
                      value={middleName}
                      onChange={(e) => setMiddleName(e.target.value)}
                    />

                    <Select
                      label="gender"
                      placeholder={"Select gender"}
                      options={genderOptions}
                      value={genderOptions.find(option => option.value === gender)}
                      onChange={(gender) => setGender(gender.value as GenderEnum)}
                    />

                    <MultiField
                      label="phone(s)"
                      type={'phone'}
                      items={phones}
                      onDelete={(id) =>
                        setPhones(
                          phones.filter((phone) =>
                            typeof id === 'string'
                              ? phone.uuid !== id
                              : phone.id !== id,
                          ),
                        )
                      }
                      onCreate={(phone) =>
                        setPhones([...(phones || []), phone as PhoneUpdateDTO])
                      }
                      changeFavorite={(id) => {
                        setPhones(
                          phones.map((phone) =>
                            phone.id === id || phone.uuid === id
                              ? { ...phone, isFavorite: !phone.isFavorite }
                              : phone,
                          ),
                        );
                      }}
                      onUpdate={(newPhone) => {
                        if ('phone' in newPhone) {
                          setPhones(
                            phones.map((phone) => {
                              if (
                                (phone.id && newPhone.id === phone.id) ||
                                (phone.uuid && newPhone.uuid === phone.uuid)
                              )
                                return newPhone;
                              return phone;
                            }),
                          );
                        }
                      }}
                    />

                    <MultiField
                      label="emails(s)"
                      type={'email'}
                      items={emails}
                      onDelete={(id) =>
                        setEmails(
                          emails.filter((emails) =>
                            typeof id === 'string'
                              ? emails.uuid !== id
                              : emails.id !== id,
                          ),
                        )
                      }
                      onCreate={(email) =>
                        setEmails([...(emails || []), email as EmailUpdateDTO])
                      }
                      changeFavorite={(id) => {
                        setEmails(
                          emails.map((email) =>
                            email.id === id || email.uuid === id
                              ? { ...email, isFavorite: !email.isFavorite }
                              : email,
                          ),
                        );
                      }}
                      onUpdate={(newEmail) => {
                        if ('email' in newEmail) {
                          setEmails(
                            emails.map((email) => {
                              if (
                                (email.id && newEmail.id === email.id) ||
                                (email.uuid && newEmail.uuid === email.uuid)
                              )
                                return newEmail;
                              return email;
                            }),
                          );
                        }
                      }}
                    />

                    {/*<InputMulti*/}
                    {/*  multiple*/}
                    {/*  type="email"*/}
                    {/*  label="emails(s)"*/}
                    {/*  placeholder="Type here"*/}
                    {/*  error={isValidate && email ? "You have unsaved changes here" : undefined}*/}
                    {/*  data={emails}*/}
                    {/*  value={email}*/}
                    {/*  onChangeValue={value => {*/}
                    {/*    setEmail(value)*/}
                    {/*    isValidate && setIsValidate(false);*/}
                    {/*  }}*/}
                    {/*  onUpdate={(emails) => setEmails(emails)}*/}
                    {/*/>*/}
                  </div>

                  <div>
                    <Input
                      label="last name"
                      placeholder="Type here"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />

                    <GoogleMapSearchAutoComplete
                      address={address}
                      setAddress={(address) => {
                        setAddress(address);
                      }}
                      withMap={false}
                      label="Home mailing address"
                      isRequired={false}
                      isNeedCreation={true}
                    />

                    <TextArea
                      label="description"
                      placeholder="Type here"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="tag-field">
                  <SelectMultiSearch
                    label="tag(s)"
                    asyncOptions={async (searchParams) => {
                      const token = await getAccessTokenUtil(dispatch);
                      if (token) {
                        return loadTagsOptions(searchParams, token);
                      }
                      return [];
                    }}
                    selectedOptions={tags?.filter(
                      (item) =>
                        item.label !== undefined && item.value !== undefined,
                    )}
                    onChange={(options) => setTags(options)}
                    creation={{
                      label: 'Create new tag',
                      onClick: () => setIsTagCreationOpen(true),
                    }}
                  />
                </div>
                <footer className="modal-footer">
                  <Button variant="light" onClick={onCloseHandler}>
                    Cancel
                  </Button>
                  <Button
                    variant="dark"
                    className={isDisabled ? " disabled" : ""}
                    onClick={submitData}>
                    Save
                  </Button>
                </footer>
              </form>
            </CustomScrollbars>
          </div>

          <div className="modal-overlay" onClick={onCloseHandler} />
        </div>
      </Modal>
      {isTagCreationOpen ? (
        <PopupTag
          setGroupCreation={setIsTagCategoryCreationOpen}
          onClose={() => {
            setIsTagCreationOpen(false);
          }}
          onCreate={async function (data) {
            const token = await getAccessTokenUtil(dispatch);
            let res: any;
            if (token) res = await TagApi.createTag(data, token);
            setTags([
              ...(tags || []),
              {
                label: `${res.title} - ${res.category.title}`,
                value: res.id,
              },
            ]);
            setIsTagCreationOpen(false);
          }}
        />
      ) : (
        ''
      )}
      {isTagCategoryCreationOpen ? (
        <PopupTagGroup
          onClose={() => {
            setIsTagCategoryCreationOpen(false);
          }}
          onCreate={async function (data) {
            const token = await getAccessTokenUtil(dispatch);
            if (token) await TagApi.createTagCategory(data, token);
            setIsTagCategoryCreationOpen(false);
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  tagState: store.tag,
  personsState: store.person,
  searchState: store.IGoogleSearchState.data,
}))(PersonModal);
