import { take, call, fork, cancel, all } from "redux-saga/effects";
import { connect } from "../connect";
import { endSocketConnection, startSocketConnection } from "../actions";
import { handleIO } from "./handleIO";
import {namespaces} from "../assets";
import {INamespaceInterface} from "../models";

export function* flow() {
  while (true) {
    yield take(startSocketConnection);
    yield all(namespaces.map((namespace) => call(addNewNamespace, namespace, "token")))
  }
}

export function* addNewNamespace(namespace: INamespaceInterface, token: string) {
  process.env.REACT_APP_SHOW_LOGS === "true" && console.log('add new namespace')
  const socket: NodeJS.Socket = yield call(connect, token, namespace.title);
  //@ts-ignore
  const task = yield fork(handleIO, socket, namespace.events);
  yield take(endSocketConnection);

  yield cancel(task);
  socket.emit("logout");
}
