import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import NotFoundPage from 'pages/Static/NotFound';
import PropertyItemInfoTab from './Info';
import TransactionSalesTab from './Sales';
import TransactionLeasesTab from './Leases';
import PropertySupplementsTab from './Supplements';
import PropertyOwnershipTab from './Ownership';

import { propertiesTabs } from 'config/constants/tabs';

interface Props
  extends RouteComponentProps<{
    id?: string;
    tabName?: string;
  }> {}

export const PersonTabsContent: FC<Props> = ({
  match: { params },
  ...props
}) => {
  const id = params?.id;
  const tabName = params?.tabName;

  const idNumber = Number(id);

  if (isNaN(idNumber)) {
    return <NotFoundPage />;
  }

  return (
    <>
      {tabName === propertiesTabs.property.path && (
        <PropertyItemInfoTab propertyId={idNumber} tabName={tabName}>
          {props.children}
        </PropertyItemInfoTab>
      )}

      {tabName === propertiesTabs.sales.path && (
        <TransactionSalesTab propertyId={idNumber} tabName={tabName}>
          {props.children}
        </TransactionSalesTab>
      )}

      {tabName === propertiesTabs.leases.path && (
        <TransactionLeasesTab propertyId={idNumber} tabName={tabName}>
          {props.children}
        </TransactionLeasesTab>
      )}

      {tabName === propertiesTabs.tenants.path && (
        <TransactionLeasesTab propertyId={idNumber} tabName={tabName}>
          {props.children}
        </TransactionLeasesTab>
      )}

      {tabName === propertiesTabs.supplements.path && (
        <PropertySupplementsTab propertyId={idNumber} />
      )}

      {tabName === propertiesTabs.ownership.path && (
        <PropertyOwnershipTab propertyId={idNumber} tabName={tabName}>
          {props.children}
        </PropertyOwnershipTab>
      )}
    </>
  );
};

export default PersonTabsContent;
