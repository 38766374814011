import {appName} from "config";
import {createAction, createAsyncAction} from "typesafe-actions";

import {widgetName} from "./widjet";
import {IException} from "controllers/store";

import {
  SaleTransactionGetRequest,
  SaleTransactionDeleteRequest,
  SaleTransactionGetListRequest,
  SaleTransactionCreateResponse,
  SaleTransactionGetResponse,
  SaleTransactionUpdateResponse,
  SaleTransactionDeleteResponse,
  SaleTransactionGetListResponse,
  UpdateSaleStatusDTO,
  SaleTransactionStatusHistoryFullDTO,
  UpdateActiveSaleStatusDTO,
  SaleTransactionGetLastByPropertyRequest
} from "@ternala/voltore-types";

import {
  SaleTransactionCreateRequestExpanded,
  SaleTransactionUpdateRequestExpanded,
  IResponsePropertiesAdditionalFields
} from "../models";

import {IError, ILoader} from "models";

// Sale Transaction actions
export const getSaleTransactionsAction = createAsyncAction(
  `${appName}/${widgetName}/GET_SALE_TRANSACTIONS_REQUEST`,
  `${appName}/${widgetName}/GET_SALE_TRANSACTIONS_SUCCESS`,
  `${appName}/${widgetName}/GET_SALE_TRANSACTIONS_FILED`
)<SaleTransactionGetListRequest & { callback?: Function } & IResponsePropertiesAdditionalFields & { callback?: Function }, { response: SaleTransactionGetListResponse, searchParams: SaleTransactionGetListRequest, additionalFields: IResponsePropertiesAdditionalFields, isAll: boolean }, IException>();

export const createSaleTransactionAction = createAsyncAction(
  `${appName}/${widgetName}/CREATE_SALE_TRANSACTION_REQUEST`,
  `${appName}/${widgetName}/CREATE_SALE_TRANSACTION_SUCCESS`,
  `${appName}/${widgetName}/CREATE_SALE_TRANSACTION_FILED`
)<(SaleTransactionCreateRequestExpanded & IResponsePropertiesAdditionalFields) & {callback?: Function}, { response: SaleTransactionCreateResponse, additionalFields: IResponsePropertiesAdditionalFields }, IException>();

export const getSaleTransactionAction = createAsyncAction(
  `${appName}/${widgetName}/GET_SALE_TRANSACTION_REQUEST`,
  `${appName}/${widgetName}/GET_SALE_TRANSACTION_SUCCESS`,
  `${appName}/${widgetName}/GET_SALE_TRANSACTION_FILED`
)<SaleTransactionGetRequest & { callback?: Function } & IResponsePropertiesAdditionalFields, { response: SaleTransactionGetResponse, additionalFields: IResponsePropertiesAdditionalFields }, IException>();

export const updateSaleTransactionAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_SALE_TRANSACTION_REQUEST`,
  `${appName}/${widgetName}/UPDATE_SALE_TRANSACTION_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_SALE_TRANSACTION_FILED`
)<(SaleTransactionUpdateRequestExpanded & IResponsePropertiesAdditionalFields) & {callback?: Function}, { response: SaleTransactionUpdateResponse, additionalFields: IResponsePropertiesAdditionalFields }, IException>();

export const updateSaleTransactionStatusAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_SALE_TRANSACTION_STATUS_REQUEST`,
  `${appName}/${widgetName}/UPDATE_SALE_TRANSACTION_STATUS_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_SALE_TRANSACTION_STATUS_FILED`
)<UpdateSaleStatusDTO & IResponsePropertiesAdditionalFields, SaleTransactionStatusHistoryFullDTO, IException>();

export const updateSaleTransactionActiveStatusAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_ACTIVE_SALE_TRANSACTION_STATUS_REQUEST`,
  `${appName}/${widgetName}/UPDATE_ACTIVE_SALE_TRANSACTION_STATUS_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_ACTIVE_SALE_TRANSACTION_STATUS_FILED`
)<(UpdateActiveSaleStatusDTO & IResponsePropertiesAdditionalFields) & {callback?: Function}, SaleTransactionStatusHistoryFullDTO, IException>();

export const deleteSaleTransactionAction = createAsyncAction(
  `${appName}/${widgetName}/DELETE_SALE_TRANSACTION_REQUEST`,
  `${appName}/${widgetName}/DELETE_SALE_TRANSACTION_SUCCESS`,
  `${appName}/${widgetName}/DELETE_SALE_TRANSACTION_FILED`
)<(SaleTransactionDeleteRequest & IResponsePropertiesAdditionalFields) & {callback?: Function}, { response: SaleTransactionDeleteResponse, additionalFields: IResponsePropertiesAdditionalFields } & { id?: number }, IException>();

export const getSaleTransactionLastByChangeStatusAction = createAsyncAction(
  `${appName}/${widgetName}/GET_SALE_TRANSACTION_LAST_BY_CHANGE_STATUS_REQUEST`,
  `${appName}/${widgetName}/GET_SALE_TRANSACTION_LAST_BY_CHANGE_STATUS_SUCCESS`,
  `${appName}/${widgetName}/GET_SALE_TRANSACTION_LAST_BY_CHANGE_STATUS_FILED`
)<SaleTransactionGetLastByPropertyRequest & IResponsePropertiesAdditionalFields, { response: SaleTransactionGetResponse, additionalFields: IResponsePropertiesAdditionalFields }, IException>();


export const addSaleTransactionLoader = createAction(`${widgetName}/ADD_SALE_TRANSACTION_LOADER`)<ILoader & { additionalFields: IResponsePropertiesAdditionalFields }>();
export const addSaleTransactionError = createAction(`${widgetName}/ADD_SALE_TRANSACTION_ERROR`)<IError & { id: string } & { additionalFields: IResponsePropertiesAdditionalFields }>();
export const removeSaleTransactionLoader = createAction(`${widgetName}/REMOVE_SALE_TRANSACTION_LOADER`)<{ id: string } & { additionalFields: IResponsePropertiesAdditionalFields }>();
export const removeSaleTransactionError = createAction(`${widgetName}/REMOVE_SALE_TRANSACTION_ERROR`)<{ target: string } & { additionalFields: IResponsePropertiesAdditionalFields }>();
  
  