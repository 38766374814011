import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const GoBackIcon: FC<Props> = ({ color, ...props }) => (
   <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M17 9.5C14.312 9.5 8.52 9.5 5 9.5M5 9.5L9.32 5M5 9.5L9.32 14"
         stroke={color}
         strokeWidth="1.5"
         strokeLinecap="round"
      />
   </svg>
)

GoBackIcon.defaultProps = {
   color: "#1F2531"
}