import React from 'react';

/* types */
import { ActivityHistoryDTO, HistoryDataItem } from "@ternala/voltore-types";
import { activityHistoryTypeEnum } from '@ternala/voltore-types/lib/constants';

/* constants */
import { arrayDifferences } from 'utils/helper-functions';
import { ReplacedObject, ReplacedField, ReplacedTag, GenerateReminderHistoryField } from "./CardElements";
import CardInfoBar from './CardInfoBar';

interface IProps {
  card: ActivityHistoryDTO;
  type?: activityHistoryTypeEnum;
}

const HistoryCard = ({ card, type }: IProps) => {
  let disabled: boolean = false;
  card.data.map((item) => {
    if (
      card.data.length === 1 &&
      (item.field === 'phones' || item.field === 'emails')
    ) {
      const differences = arrayDifferences(item.newValue, item.oldValue);
      if (
        differences.newArray.length === 0 &&
        differences.oldArray.length === 0
      )
        return (disabled = true);
    }
  });

  const generateElement = (element: HistoryDataItem, index: number) => {
    switch (element.field) {
      case 'cardTagConnects':
        return <ReplacedTag data={element} type={type} key={index} />;
      case 'tags':
        return <ReplacedTag data={element} type={type} key={index} />;
      case 'note':
        return <ReplacedField data={element} type={type} key={index} column={true} />;
      case 'reminders':{
        // @ts-ignore
        return <GenerateReminderHistoryField key={element['_id']} {...element} />;
      }
      case 'description':
        return (
          <ReplacedField data={element} column type={type} key={index} />
        );
      case 'taxonomies':
        return (
          <ReplacedField
            data={element}
            taxonomy
            column
            type={type}
            key={index}
          />
        );
      default:
        if (
          Array.isArray(element.newValue) ||
          typeof element.newValue === 'string' ||
          typeof element.newValue === 'number'
        )
          return <ReplacedField data={element} type={type} />;
        return <ReplacedObject data={element} type={type} />;
    }
  }

  if (disabled) return <></>;

  return (
    <div className="history-card activity">
      <CardInfoBar {...card}/>
      <div className="action-details">
        {card.data.map(generateElement)}
      </div>
    </div>
  );
};

export default HistoryCard;
