import { all, put, takeEvery, call } from "redux-saga/effects";

// APIs
import {SaleApi} from '../transport/saleTransactions.api';
import {getAccessToken} from '../../auth';

// Actions
import {
    getSaleTransactionsAction,
    getSaleTransactionAction,
    createSaleTransactionAction,
    updateSaleTransactionAction,
    deleteSaleTransactionAction,
    updateSaleTransactionStatusAction,
    addSaleTransactionLoader,
    addSaleTransactionError,
    removeSaleTransactionLoader,
    updateSaleTransactionActiveStatusAction,
    getSaleTransactionLastByChangeStatusAction,
} from '../actions/saleTransactions';

// Constants
import {LoaderAction} from 'config/constants';

// Utils
import uuid from 'utils/uuid';
import {getAccessTokenUtil} from "../../../utils/getAccessToken";
import {
    SaleTransactionCreateResponse, SaleTransactionDeleteResponse, SaleTransactionFullExpandDTO,
    SaleTransactionGetListResponse,
    SaleTransactionGetResponse, SaleTransactionStatusHistoryFullDTO, SaleTransactionUpdateResponse
} from "@ternala/voltore-types";
import {IError} from "../../model";
import {
    createEnterpriseAction, deleteEnterpriseAction,
    getEnterpriseAction,
    getEnterprisesAction,
    updateEnterpriseAction
} from "../../enterprise/actions";
import {addError} from "../../enterprise";
import { getAccessTokenSaga } from "../../auth/sagas/auth";

export function* getSaleTransactionsSaga({
                                             payload,
                                         }: ReturnType<typeof getSaleTransactionsAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();

    const additionalFields = {
        property: payload.property,
    };

    yield put(
        addSaleTransactionLoader({
            id: loadId,
            message: 'Please wait, sales are loading!',
            type: LoaderAction.sale.getList,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: SaleTransactionGetListResponse | string | IError = yield SaleApi.getSaleTransactions(data, accessToken);
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                getSaleTransactionsAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    getSaleTransactionsAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to get saleTransactions!',
                    type: LoaderAction.sale.getList,
                }),
            );
        } else {
            yield put(
                getSaleTransactionsAction.success({
                    response: res,
                    searchParams: payload,
                    additionalFields,
                    isAll: payload.limit ? res.items.length < payload.limit : true,
                }),
            );
            yield put(
                removeSaleTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function')
                payload.callback(res ? true : false);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            getSaleTransactionsAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addSaleTransactionError({
                id: loadId,
                message: 'Failed to get sales!',
                type: LoaderAction.sale.getList,
                additionalFields,
            }),
        );
    }
}

export function* createSaleTransactionSaga({
                                               payload,
                                           }: ReturnType<typeof createSaleTransactionAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();

    const additionalFields = {
        property: payload.property,
    };

    yield put(
        addSaleTransactionLoader({
            id: loadId,
            message: 'Please wait, sale is creating!',
            type: LoaderAction.sale.create,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: SaleTransactionCreateResponse | string | IError = yield SaleApi.createSaleTransaction(data, accessToken);

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                createSaleTransactionAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    createSaleTransactionAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to create sale!',
                    type: LoaderAction.sale.create,
                }),
            );
        } else {
            yield put(
                createSaleTransactionAction.success({
                    response: res,
                    additionalFields: {
                        property: payload.property,
                    },
                }),
            );
            yield put(
                removeSaleTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function')
            payload.callback(false, error.data.statuses as string);
        yield put(
            createSaleTransactionAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addSaleTransactionError({
                id: loadId,
                message: 'Failed to create sale!',
                type: LoaderAction.sale.create,
                additionalFields,
            }),
        );
    }
}

export function* getSaleTransactionSaga({
                                            payload,
                                        }: ReturnType<typeof getSaleTransactionAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();

    const additionalFields = {
        property: payload.property,
    };

    yield put(
        addSaleTransactionLoader({
            id: loadId,
            message: 'Please wait, sale is getting!',
            type: LoaderAction.sale.getItem,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: SaleTransactionGetResponse | string | IError = yield SaleApi.getSaleTransaction(data, accessToken);

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                getSaleTransactionAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    getSaleTransactionAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to get enterprise!',
                    type: LoaderAction.sale.getItem,
                }),
            );
        } else {
            yield put(
                getSaleTransactionAction.success({
                    response: res,
                    additionalFields: {
                        property: payload.property,
                    },
                }),
            );
            yield put(
                removeSaleTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function')
                payload.callback(res ? true : false);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            getSaleTransactionAction.failure({
                key: String(payload.id),
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addSaleTransactionError({
                id: loadId,
                message: 'Failed to get sale!',
                type: LoaderAction.sale.getItem,
                additionalFields,
            }),
        );
    }
}

export function* updateSaleTransactionSaga({
                                               payload,
                                           }: ReturnType<typeof updateSaleTransactionAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();

    const additionalFields = {
        property: payload.property,
    };

    yield put(
        addSaleTransactionLoader({
            id: loadId,
            message: 'Please wait, sale is updating!',
            type: LoaderAction.sale.update,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: SaleTransactionUpdateResponse | string | IError = yield SaleApi.updateSaleTransaction(data, accessToken);
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                updateSaleTransactionAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    updateSaleTransactionAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to update sale!',
                    type: LoaderAction.sale.update,
                }),
            );
        } else {
            yield put(
                updateSaleTransactionAction.success({
                    response: res,
                    additionalFields: {
                        property: payload.property,
                    },
                }),
            );
            yield put(
                removeSaleTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function')
            payload.callback(false, error.data.statuses as string);
        yield put(
            updateSaleTransactionAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addSaleTransactionError({
                id: loadId,
                message: 'Failed to update sale!',
                type: LoaderAction.sale.update,
                additionalFields,
            }),
        );
    }
}

export function* updateSaleTransactionStatusSaga({
                                                     payload,
                                                 }: ReturnType<typeof updateSaleTransactionStatusAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();

    const additionalFields = {
        property: payload.property,
    };

    yield put(
        addSaleTransactionLoader({
            id: loadId,
            message: 'Please wait, sale is updating!',
            type: LoaderAction.sale.update,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        const res: SaleTransactionStatusHistoryFullDTO | string | IError = yield SaleApi.updateSaleTransactionStatus(payload, accessToken);
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                updateSaleTransactionStatusAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    updateSaleTransactionStatusAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to update sale status!',
                    type: LoaderAction.sale.update,
                }),
            );
        } else {
            yield put(updateSaleTransactionStatusAction.success(res));
            yield put(
                removeSaleTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
        }
    } catch (error: any) {
        yield put(
            updateSaleTransactionStatusAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addSaleTransactionError({
                id: loadId,
                message: 'Failed to update sale!',
                type: LoaderAction.sale.update,
                additionalFields,
            }),
        );
    }
}

export function* updateSaleTransactionActiveStatusSaga({
                                                           payload,
                                                       }: ReturnType<typeof updateSaleTransactionActiveStatusAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();

    const additionalFields = {
        property: payload.property,
    };

    yield put(
        addSaleTransactionLoader({
            id: loadId,
            message: 'Please wait, sale is updating!',
            type: LoaderAction.sale.update,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res :SaleTransactionStatusHistoryFullDTO | string | IError = yield SaleApi.updateSaleTransactionActiveStatus(
            data,
            accessToken,
        );
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                updateSaleTransactionActiveStatusAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    updateSaleTransactionActiveStatusAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to update sale active status!',
                    type: LoaderAction.sale.update,
                }),
            );
        } else {
            yield put(updateSaleTransactionActiveStatusAction.success(res));
            yield put(
                removeSaleTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            updateSaleTransactionActiveStatusAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addSaleTransactionError({
                id: loadId,
                message: 'Failed to update sale!',
                type: LoaderAction.sale.update,
                additionalFields,
            }),
        );
    }
}

export function* deleteSaleSaga({
                                    payload,
                                }: ReturnType<typeof deleteSaleTransactionAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    const additionalFields = {
        property: payload.property,
    };
    yield put(
        addSaleTransactionLoader({
            id: loadId,
            message: 'Please wait, sale is deleting!',
            type: LoaderAction.sale.delete,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: SaleTransactionDeleteResponse | string = yield SaleApi.deleteSaleTransaction(data, accessToken);
        if (typeof res === "undefined" || typeof res === "string") {
            deleteEnterpriseAction.failure({
                code: res,
                message: res || "Something was wrong"
            });
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to delete enterprise!',
                    type: LoaderAction.enterprise.delete,
                }),
            );
        } else {
            yield put(
                deleteSaleTransactionAction.success({
                    response: res,
                    id: payload.id,
                    additionalFields: {
                        property: payload.property,
                    },
                }),
            );
            yield put(
                removeSaleTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function')
                payload.callback(res ? true : false);
        }
    } catch (error: any) {
        yield put(
            deleteSaleTransactionAction.failure({
                code: error.code || 403,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addSaleTransactionError({
                id: loadId,
                message: 'Failed to delete sale!',
                type: LoaderAction.sale.delete,
                additionalFields,
            }),
        );
    }
}

export function* getSaleLastByStatusActionSaga({
                                                   payload,
                                               }: ReturnType<typeof getSaleTransactionLastByChangeStatusAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();

    const additionalFields = {
        property: payload.property,
    };

    yield put(
        addSaleTransactionLoader({
            id: loadId,
            message: 'Please wait, sale is getting!',
            type: LoaderAction.sale.getItem,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        const res: SaleTransactionFullExpandDTO | string | IError = yield SaleApi.getSaleTransactionLastByStatusHistory(
            payload,
            accessToken,
        );
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                getSaleTransactionLastByChangeStatusAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    getSaleTransactionLastByChangeStatusAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to get sale!',
                    type: LoaderAction.enterprise.getItem,
                }),
            );
        } else {
            yield put(
                getSaleTransactionLastByChangeStatusAction.success({
                    response: res,
                    additionalFields: {
                        property: payload.property,
                    },
                }),
            );
            yield put(
                removeSaleTransactionLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
        }
    } catch (error: any) {
        yield put(
            getSaleTransactionLastByChangeStatusAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addSaleTransactionError({
                id: loadId,
                message: 'Failed to get sale!',
                type: LoaderAction.sale.getItem,
                additionalFields,
            }),
        );
    }
}

export function* saleTransactionActionSaga() {
    yield all([
        takeEvery(getSaleTransactionsAction.request, getSaleTransactionsSaga),
        takeEvery(getSaleTransactionAction.request, getSaleTransactionSaga),
        takeEvery(createSaleTransactionAction.request, createSaleTransactionSaga),
        takeEvery(updateSaleTransactionAction.request, updateSaleTransactionSaga),
        takeEvery(
            updateSaleTransactionStatusAction.request,
            updateSaleTransactionStatusSaga,
        ),
        takeEvery(
            updateSaleTransactionActiveStatusAction.request,
            updateSaleTransactionActiveStatusSaga,
        ),
        takeEvery(
            getSaleTransactionLastByChangeStatusAction.request,
            getSaleLastByStatusActionSaga,
        ),
        takeEvery(deleteSaleTransactionAction.request, deleteSaleSaga),
    ]);
}
