/* eslint-disable */
import React, { FC, useEffect, useState } from 'react';
import { AddressShortDTO } from '@ternala/voltore-types/lib/modules/address/addressShort.dto';

// actions
import PropertyLocationMap from 'pages/Properties/GoogleMaps/PropertyLocationMap';
import { googleResultToAddress } from 'utils/googleResultToAddress';
import { MapAddressLinker } from '../../MapAddressLinker';
import LocationSearchInput from './Field';

interface Props {
  styles?: React.CSSProperties;
  mainStyles?: React.CSSProperties;

  address?: AddressShortDTO;
  setAddress: (data: AddressShortDTO | undefined) => void;
  error?: boolean;

  isNeedCreation?: boolean;

  withMap?: boolean;

  label?: string;
  isRequired?: boolean;
}

const isLinkedLocal: { [key: string]: boolean } = {};
const addressLocal: { [key: string]: AddressShortDTO | undefined } = {};

export const GoogleMapSearchAutoComplete: FC<Props> = ({
  address,
  setAddress,
  withMap,
  error,
  label,
  isNeedCreation,
  ...props
}) => {
  const [isLinked, setIsLinked] = useState<boolean>(true);
  if (label) {
    if (!isLinkedLocal.hasOwnProperty(label)) {
      isLinkedLocal[label] = true;
    }
    if (!addressLocal.hasOwnProperty(label)) {
      addressLocal[label] = address;
    }
  }

  useEffect(() => {
    if (label && !addressLocal.hasOwnProperty(label)) {
      addressLocal[label] = address;
    }
  }, [address]);

  return (
    <div className="googleBloc" style={{ ...props.mainStyles }}>
      <span className="googleBloc-address">
        <h1 className="googleBloc-address-word">{label}</h1>
        &nbsp;
        {props.isRequired && <h1 className="googleBloc-address-star">*</h1>}
      </span>

      <LocationSearchInput
        address={address}
        isLinked={isLinked}
        isNeedCreation={isNeedCreation}
        setAddress={(address: AddressShortDTO | undefined) => {
          setAddress(address);
          if (label) addressLocal[label] = address;
        }}
        styles={props.styles}
      />

      {withMap ? (
        <>
          <MapAddressLinker
            linked={isLinked}
            functionality={() => {
              if (address) {
                const newAddress = {
                  ...address,
                  isConnected: !isLinked,
                };
                setAddress(newAddress);
                if (label) {
                  addressLocal[label] = newAddress;
                }
              }

              setIsLinked(!isLinked);
              if (label) {
                isLinkedLocal[label] = !isLinked;
              }
            }}
          />
          <PropertyLocationMap
            className="google-map_property"
            propertyCoords={
              address && {
                latitude: address.latitude || 0,
                longitude: address.longitude || 0,
              }
            }
            onMarkerPlaced={() => {}}
            isBottomLeftControls={true}
            dataSetter={(value) => {
              const addressHere = label ? addressLocal[label] : address;
              const isLinkedHere = label ? isLinkedLocal[label] : isLinked;

              const addressFromMap = googleResultToAddress({
                ...value,
                isConnected: isLinkedHere,
              });
              const newAddress = addressHere
                ? {
                    ...addressHere,
                    ...(isLinkedHere
                      ? addressFromMap
                      : {
                          latitude: addressFromMap.latitude,
                          longitude: addressFromMap.longitude,
                        }),
                  }
                : addressFromMap;

              setAddress(newAddress);
              if (label) {
                addressLocal[label] = newAddress;
              }
            }}
            tip="Please select an already existing property on the map, or drop a pin"
            error={error}
            linked={isLinked}
            isTip={true}
            required
          />
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default GoogleMapSearchAutoComplete;
