import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const LinkIcon: FC<Props> = (props) => (
   <svg
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M6.3326 2.55498L10.9594 2.55498C11.2433 2.55498 11.4735 2.7878 11.4735 3.075L11.4735 7.75515C11.4735 8.04234 11.2433 8.27516 10.9594 8.27516C10.6755 8.27516 10.4453 8.04234 10.4453 7.75515L10.4453 4.33043L6.18202 8.64287L5.45499 7.90746L9.71827 3.59502L6.3326 3.59502C6.04867 3.59502 5.81851 3.3622 5.81851 3.075C5.81851 2.7878 6.04867 2.55498 6.3326 2.55498Z"
         fill={props.color}
      />
      <path
         d="M5.12903 4.99902H1V12.999H9V8.19902"
         stroke={props.color}
         strokeLinecap="round"
      />
   </svg>
)

LinkIcon.defaultProps = {
   color: '#1F3560'
}
