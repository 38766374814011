import { omit } from 'lodash';
import { PropertyGetListFiltersByLeaseTransaction } from '@ternala/voltore-types/lib/modules/property/filters/leaseTransaction';

/* constants */
import { Unit } from 'config/constants';
import {
  LeaseTransactionStatusEnum,
  SaleTransactionStatusEnum,
} from '@ternala/voltore-types/lib/constants';

/* types */
import {
  PropertyGetListFiltersExtended,
} from 'controllers/property/models';

import { OptionType } from '../../models';
import { PropertyGetListFiltersByProperty } from "@ternala/voltore-types/lib/modules/property/filters/property";
import { PropertyGetListFiltersBySaleTransaction } from "@ternala/voltore-types/lib/modules/property/filters/saleTransaction";

const propertySimpleKeys = [
  'minSQFT',
  'maxSQFT',
  'minLotSQFT',
  'maxLotSQFT',
  'minYearBuild',
  'maxYearBuild',
] as const;
const propertyKeys = [
  'propertyType',
  'subPropertyType',
  'secondaryPropertyType',
  'buildingClass',
  'zoning',
] as const;
type propertyKeyType = typeof propertyKeys[number];
type propertySimpleKeyType = typeof propertySimpleKeys[number];


function generateDataImprove(
  data?: PropertyGetListFiltersExtended['property'],
  keys?: readonly propertyKeyType[],
  simpleKeys?: readonly propertySimpleKeyType[],
): PropertyGetListFiltersByProperty;
function generateDataImprove(
  data?: PropertyGetListFiltersExtended['sale'],
  keys?: readonly saleKeyType[],
  simpleKeys?: readonly saleSimpleKeyType[],
): PropertyGetListFiltersBySaleTransaction;
function generateDataImprove(
  data?: PropertyGetListFiltersExtended['lease'],
  keys?: readonly leaseKeyType[],
): PropertyGetListFiltersByLeaseTransaction;
function generateDataImprove(
  data?: any,
  keys?: any,
  simpleKeys?: any,
): any {
  const filters: typeof data = {};
  simpleKeys?.forEach((key: any) => {
    const value = data?.[key];
    if (value) {
      filters[key] = value as Date & number;
    }
  });

  keys?.forEach((key: any) => {
    filters[key] = (data?.[key] as OptionType[])?.map(({ value }) =>
      Number(value),
    );
  });

  return filters;
}

export const applyPropertyFilters = (
  filters?: PropertyGetListFiltersExtended,
): PropertyGetListFiltersByProperty => {
  return generateDataImprove(
    filters?.property,
    propertyKeys,
    propertySimpleKeys,
  );
};

const saleSimpleKeys = [
  'statusStartDate',
  'statusEndDate',
  'minCapRate',
  'minNOI',
  'maxNOI',
  'minLeaseAtSale',
  'maxLeaseAtSale',
] as const;
const saleKeys = [
  'seller',
  'sellerSideInvolvement',
  'buyer',
  'buyerSideInvolvement',
  'financing',
  'saleType',
] as const;
type saleKeyType = typeof saleKeys[number];
type saleSimpleKeyType = typeof saleSimpleKeys[number];

export const applySaleTransactionFilters = (
  filters?: PropertyGetListFiltersExtended,
): PropertyGetListFiltersBySaleTransaction => {
  const saleFilters: PropertyGetListFiltersBySaleTransaction =
  generateDataImprove(filters?.sale, saleKeys, saleSimpleKeys);

  if (filters?.sale?.status?.length) {
    saleFilters.status = filters.sale.status.map(
      (filter) => filter.value as SaleTransactionStatusEnum,
    );
  }

  /* input filters */
  if (filters?.sale?.listPriceUnit === Unit.PRICE) {
    if (filters?.sale?.minListPrice)
      saleFilters.minListPrice = filters.sale.minListPrice;
    if (filters?.sale?.maxListPrice)
      saleFilters.maxListPrice = filters.sale.maxListPrice;
  } else {
    if (filters?.sale?.minListPrice)
      saleFilters.minListPricePerSQFT = filters.sale.minListPrice;
    if (filters?.sale?.maxListPrice)
      saleFilters.maxListPricePerSQFT = filters.sale.maxListPrice;
  }
  if (filters?.sale?.soldPriceUnit === Unit.PRICE) {
    if (filters?.sale?.minSoldPrice)
      saleFilters.minSoldPrice = filters.sale.minSoldPrice;
    if (filters?.sale?.maxSoldPrice)
      saleFilters.maxSoldPrice = filters.sale.maxSoldPrice;
  } else {
    if (filters?.sale?.minSoldPrice)
      saleFilters.minSoldPricePerSQFT = filters.sale.minSoldPrice;
    if (filters?.sale?.maxSoldPrice)
      saleFilters.maxSoldPricePerSQFT = filters.sale.maxSoldPrice;
  }

  saleSimpleKeys.forEach((key) => {
    const value = filters?.sale?.[key];
    if (value) saleFilters[key] = value as Date & number;
  });

  saleKeys.forEach((key: saleKeyType) => {
    saleFilters[key] = (filters?.sale?.[key] as OptionType[])?.map(
      ({ value }) => Number(value),
    );
  });

  return saleFilters;
};

const leaseKeys = [
  'leaseType',
  'owner',
  'ownerSideInvolvement',
  'tenant',
  'tenantSideInvolvement',
] as const;
type leaseKeyType = typeof leaseKeys[number];

export const applyLeaseTransactionFilters = (
  filters?: PropertyGetListFiltersExtended,
): PropertyGetListFiltersByLeaseTransaction => {
  const leaseFilters: PropertyGetListFiltersByLeaseTransaction = {
    ...omit(filters?.lease, ['status', ...leaseKeys]),
    ...generateDataImprove(filters?.lease, leaseKeys)
  };

  if (filters?.lease?.status?.length) {
    leaseFilters.status = filters.lease.status.map(
      ({ value }) => value as LeaseTransactionStatusEnum,
    );
  }

  return leaseFilters;
};
