/* constants */
import { FIGURE_OPTIONS } from 'config/google-maps'

/* types */
import { ICircle, IPoint } from '@ternala/voltore-types/lib/modules/property/filters/area'
import { FilterType } from 'models'
import { getBoundsPolygon } from "../utils";

declare global {
   interface Document {
     mozCancelFullScreen?: () => Promise<void>
     msExitFullscreen?: () => Promise<void>
     webkitExitFullscreen?: () => Promise<void>
     isFullscreen?: (element: HTMLElement) => void
     fullScreenControl?: Element
     mozFullScreenElement?: Element
     msFullscreenElement?: Element
     webkitFullscreenElement?: Element
     onwebkitfullscreenchange?: any
     onmsfullscreenchange?: any
     onmozfullscreenchange?: any
   }
 
   interface Element {
     msRequestFullScreen?: () => Promise<void>
     mozRequestFullScreen?: () => Promise<void>
     webkitRequestFullScreen?: () => Promise<void>
   }
}

interface IAppendElement {
   tagName: string
   className: string
   container: HTMLElement
   attributes?: { [key: string]: string }
}

interface IPolygonCoords {
   lat: () => number
   lng: () => number
}

interface IDrawingControl {
   mapNode: HTMLDivElement
   map: google.maps.Map
   drawingManager?: google.maps.drawing.DrawingManager
   onClear: (type: FilterType) => void
   isFullScreen?: boolean
}

interface ICircleDrawingControl extends IDrawingControl {
   onComplete: (circle: ICircle) => void
   circle?: ICircle
}

interface IPolygonDrawingControl extends IDrawingControl {
   onComplete: (polygon: IPoint[]) => void
   polygon?: IPoint[]
}

let figure: any = null

abstract class MapControl {
   protected drawingControlWrapper: null | HTMLElement = null
   protected drawingControl: null | HTMLButtonElement = null

   appendElement({ tagName, className, container, attributes }: IAppendElement) {
      const element = document.createElement(tagName) as any
      element.className = className

      for (const attr in attributes) {
         element[attr] = attributes[attr]
      }

      container.append(element)
      return element as HTMLButtonElement
   }

   removeElement(element: Element, eventListener?: () => void) {
      if (eventListener) {
         element.removeEventListener('click', eventListener)
      }
      element.remove()
   }

   renderClearButton(figureType: 'circle' | 'polygon', callback?: Function) {
      if (this.drawingControlWrapper && this.drawingControl) {
         const buttonClassName = `drawing-control__button-clear drawing-control__button-clear_${figureType}`
         let buttonClear = document.querySelector(`.${buttonClassName}`)

         const onClear = () => {
            if (buttonClear) {
               this.removeElement(buttonClear, onClear)
            }
            if (figure) {
               figure.setMap(null)
               figure = null
            }
            callback?.()
         }

         if (buttonClear) {
            this.removeElement(buttonClear, onClear)
         }

         buttonClear = this.appendElement({
            tagName: 'button',
            className: buttonClassName,
            container: this.drawingControlWrapper,
            attributes: { textContent: 'clear' }
         })
         buttonClear.addEventListener('click', onClear)
      }
   }
}

export class ZoomControls extends MapControl {
   constructor(mapNode: HTMLDivElement, map: google.maps.Map, isFullScreen?: boolean) {
      super()

      const wrapperCssClass = isFullScreen ? 'zoom-controls-wrapper_full-screen' : 'zoom-controls-wrapper'
      this.element = this.appendElement({ tagName: 'div', className: wrapperCssClass, container: mapNode })
      const zoomInButton = this.appendElement({
         tagName: 'button',
         className: 'zoom-in-control',
         container: this.element,
         attributes: { type: 'button' }
      })
      const zoomOutButton = this.appendElement({
         tagName: 'button',
         className: 'zoom-out-control',
         container: this.element,
         attributes: { type: 'button' }
      })
   
      google.maps.event.addDomListener(zoomInButton, 'click', () => {
         map.setZoom(map.getZoom() + 1)
      })
   
      google.maps.event.addDomListener(zoomOutButton, 'click', () => {
         map.setZoom(map.getZoom() - 1)
      })
   }

   public element: HTMLElement
}

export class FullScreenControl extends MapControl {
   constructor(
      mapNode: HTMLDivElement,
      isFullscreen: boolean,
      toggleFullScreen: () => void
   ) {
      super()

      const buttonClassName = isFullscreen ? 'full-screen-control_exit' : 'full-screen-control_enter'
      this.element = this.appendElement({
         tagName: 'button',
         className: buttonClassName,
         container: mapNode,
         attributes: { type: 'button' }
      })

      google.maps.event.addDomListener(this.element, 'click', () => {
         toggleFullScreen()
      })
   }

   element: HTMLElement
}

export class CloseFullScreenControl extends MapControl {
   constructor(
      mapNode: HTMLDivElement,
      isFullScreen: boolean,
      toggleFullScreen: () => void,
   ) {
      super()

      if (isFullScreen) {
         this.element = this.appendElement({
            tagName: 'button',
            className: 'close-full-screen-control',
            container: mapNode,
            attributes: { type: 'button' }
         })

         google.maps.event.addDomListener(this.element, 'click', () => {
            toggleFullScreen()
         })
      }
   }

   public element: HTMLElement | undefined
}

export class StreetViewControl extends MapControl {
   constructor(
      mapNode: HTMLDivElement,
      map: google.maps.Map,
      isFullScreen?: boolean
   ) {
      super()

      const wrapperCssClass = isFullScreen ? 'street-view-control_full-screen' : 'street-view-control'
      this.element = this.appendElement({ tagName: 'button', className: wrapperCssClass, container: mapNode })

      this.panorama = new google.maps.StreetViewPanorama(
         document.querySelector('.map__street-view-panorama') as HTMLElement,
         // document.getElementById("pano") as HTMLElement,
         {
            position: { lat: 42.345573, lng: -71.098326 },
            addressControlOptions: {
               position: google.maps.ControlPosition.BOTTOM_CENTER,
            },
            linksControl: false,
            panControl: false,
            enableCloseButton: false,
            pov: {
               heading: 34,
               pitch: 10,
           },
         }
      )
      map.setStreetView(this.panorama)

      const pano = map.getStreetView()
      pano.setPosition({ lat: 40.729884, lng: -73.990988 })
      pano.setPov(
         /** @type {google.maps.StreetViewPov} */ {
            heading: 265,
            pitch: 0,
         }
      );

      this.panorama.addListener("pano_changed", () => panochanged())
      pano.addListener('pano_changed', () => panochanged())
      this.panorama.addListener('links_changed', () => linksChanged(this.panorama))
      pano.addListener('links_changed', () => linksChanged(pano))
      this.panorama.addListener('position_changed', () => positionChanged(this.panorama))
      pano.addListener('position_changed', () => positionChanged(pano))
      this.panorama.addListener('pov_changed', () => povChanged(this.panorama))
      pano.addListener('pov_changed', () => povChanged(pano))

      const panochanged = () => {
         const panoCell = document.getElementById("pano-cell") as HTMLElement;
         panoCell.innerHTML = this.panorama.getPano();
      }

      const linksChanged = (inputpanorama: any) => {
         const linksTable = document.getElementById("links_table") as HTMLElement;

         while (linksTable.hasChildNodes()) {
            linksTable.removeChild(linksTable.lastChild as ChildNode);
         }
         const links = inputpanorama.getLinks();

         for (const i in links) {
            const row = document.createElement("tr");
            linksTable.appendChild(row);
            const labelCell = document.createElement("td");
            labelCell.innerHTML = "<b>Link: " + i + "</b>";
            const valueCell = document.createElement("td");
            valueCell.innerHTML = links[i].description as string;
            linksTable.appendChild(labelCell);
            linksTable.appendChild(valueCell);
         }
      }

      const positionChanged = (inputpanorama: any) => {
         const positionCell = document.getElementById(
            "position-cell"
          ) as HTMLElement;
          (positionCell.firstChild as HTMLElement).nodeValue =
         inputpanorama.getPosition() + "";
      }

      const povChanged = (inputpanorama: any) => {
         const headingCell = document.getElementById("heading-cell") as HTMLElement;
            const pitchCell = document.getElementById("pitch-cell") as HTMLElement;
            (headingCell.firstChild as HTMLElement).nodeValue =
            inputpanorama.getPov().heading + "";
            (pitchCell.firstChild as HTMLElement).nodeValue =
            inputpanorama.getPov().pitch + "";
      }
      
      google.maps.event.addDomListener(this.element, 'click', () => {
         map.setStreetView(this.panorama)
         this.toggleStreetView(map)
      })
   }

   public element: HTMLElement
   private panorama: google.maps.StreetViewPanorama

   toggleStreetView(map: google.maps.Map) {
      const toggle = this.panorama.getVisible()
      map.setStreetView(this.panorama)
    
      if (!toggle) {
        this.panorama.setVisible(true)
      } else {
        this.panorama.setVisible(false)
      }
   }
}

export class CircleDrawingControl extends MapControl {
   constructor({
      mapNode,
      map,
      drawingManager,
      onComplete,
      onClear,
      circle,
      isFullScreen
   }: ICircleDrawingControl) {
      super()

      const wrapperClassName = isFullScreen ? 'drawing-control-wrapper_full-screen' : 'drawing-control-wrapper'
      this.drawingControlWrapper = this.appendElement({
         tagName: 'div',
         className: wrapperClassName,
         container: mapNode
      }) 
      this.drawingControl = this.appendElement({
         tagName: 'button',
         className: 'drawing-control_circle',
         container: this.drawingControlWrapper
      })
      this.appendElement({
         tagName: 'span',
         className: 'drawing-control__tooltip drawing-control__tooltip_circle',
         container: this.drawingControl,
         attributes: {
            textContent: 'Draw radius'
         }
      })
      this.element = this.drawingControlWrapper

      // render circle from existing coords (not used for now)
      if (circle && circle.hasOwnProperty('center') && circle.hasOwnProperty('radius')) {
         figure = this.createCircle(circle)
         figure.setMap(map)

         this.renderClearButton('circle', () => {
            drawingManager?.setDrawingMode(null)
            onClear('circle')
         })
      } else {
         if (figure) {
            figure.setMap(null)
            figure = null
         }
      }
      
      google.maps.event.addDomListener(this.drawingControl, 'click', () => {
         drawingManager?.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE)
      })

      if (drawingManager) {
         google.maps.event.addListener(
           drawingManager,
           'circlecomplete',
           (circle: any) => {
             if (figure) {
               figure.setMap(null);
               figure = null;
             }
             const buttonClear = document.querySelector(
               '.drawing-control__button-clear',
             );
             buttonClear && this.removeElement(buttonClear);

             const circleData = {
               radius: circle.getRadius(),
               center: {
                 latitude: circle.getCenter().lat(),
                 longitude: circle.getCenter().lng(),
               },
             };

             figure = circle;
             this.renderClearButton('circle', () => {
               drawingManager.setDrawingMode(null);
               onClear('circle');
             });
             onComplete(circleData);
           },
         );
      }
   }

   public element: HTMLElement

   createCircle(circle: ICircle) {
      const drawnCircle: google.maps.Circle = new google.maps.Circle({
         ...FIGURE_OPTIONS,
         center: {
            lat: circle.center.latitude,
            lng: circle.center.longitude
         },
         radius: circle.radius
      })
      figure = drawnCircle
      return drawnCircle
   }
}

export class PolygonDrawingControl extends MapControl {
   constructor({
      mapNode,
      map,
      drawingManager,
      onComplete,
      onClear,
      polygon,
      isFullScreen
   }: IPolygonDrawingControl) {
      super()

      const wrapperClassName = isFullScreen ? 'drawing-control-wrapper_polygon_full-screen' : 'drawing-control-wrapper_polygon'
      this.drawingControlWrapper = this.appendElement({
         tagName: 'div',
         className: wrapperClassName,
         container: mapNode
      })
      this.drawingControl = this.appendElement({
         tagName: 'button',
         className: 'drawing-control_polygon',
         container: this.drawingControlWrapper
      })
      this.appendElement({
         tagName: 'span',
         className: 'drawing-control__tooltip drawing-control__tooltip_polygon',
         container: this.drawingControl,
         attributes: {
            textContent: 'Draw shape'
         }
      })
      this.element = this.drawingControlWrapper

      // render shape from existing coords (not used for now)
      if (polygon && Array.isArray(polygon)) {
         const drawnPolygon = this.createPolygon(polygon)
         drawnPolygon.setMap(map)

         this.renderClearButton('polygon', () => {
            drawingManager?.setDrawingMode(null)
            onClear('polygon')
         })
      } else {
         if (figure) {
            figure.setMap(null)
            figure = null
         }
      }

      google.maps.event.addDomListener(this.drawingControl, 'click', () => {
         drawingManager?.setDrawingMode(google.maps.drawing.OverlayType.POLYGON)
      })

      if (drawingManager) {
         google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon: any) => {
            if (figure) {
               figure.setMap(null)
               figure = null
            }
            const buttonClear = document.querySelector('.drawing-control__button-clear')
            buttonClear && this.removeElement(buttonClear)

            const polygonCoords = (Object.values(polygon.getPath())[0] as IPolygonCoords[])
            .map(coord => ({
               latitude: coord.lat(),
               longitude: coord.lng()
            }))
            
            figure = polygon
            this.renderClearButton('polygon', () => {
               drawingManager.setDrawingMode(null)
               onClear('polygon')
            })
            onComplete(polygonCoords)
         })
      }
   }

   public element: HTMLElement

   createPolygon(polygon: IPoint[]) {
      const drawnPolygonCoords: google.maps.LatLngLiteral[] = polygon.map(point => ({
         lat: point.latitude,
         lng: point.longitude
      }))
      drawnPolygonCoords.push({
         lat: polygon[0].latitude,
         lng: polygon[0].longitude
      })
  
      const drawnPolygon: google.maps.Polyline = new google.maps.Polyline({
         path: drawnPolygonCoords,
         ...FIGURE_OPTIONS
      })
      figure = drawnPolygon
      return drawnPolygon
   }
}

export class DragMapControl extends MapControl {
   constructor(
      mapNode: HTMLDivElement,
      map: google.maps.Map,
      onMapDragEnd: (bounds: IPoint[]) => void
   ) {
      super()

      // const dragMapControlWrapper = this.appendElement({ tagName: 'div', className: 'drag-map-control-wrapper', container: mapNode })
      // const dragMapControlCheckbox = this.appendElement({
      //    tagName: 'input',
      //    className: 'checkbox',
      //    container: dragMapControlWrapper,
      //    attributes: { id: 'drag-map-control', type: 'checkbox' }
      // })
      // this.appendElement({
      //    tagName: 'label',
      //    className: 'checkbox-label-base',
      //    container: dragMapControlWrapper,
      //    attributes: {
      //       htmlFor: 'drag-map-control',
      //       textContent: 'Search as I move the map'
      //    }
      // })
      // this.element = dragMapControlWrapper
      //
      // dragMapControlCheckbox.addEventListener('change', (event: any) => {
      //    this.searchOnDrag = event.target.checked
      // })

      google.maps.event.addDomListener(map, 'dragend', () => {
         if (this.searchOnDrag) {
            const bounds = map.getBounds()
            if (bounds) {
               onMapDragEnd(getBoundsPolygon(bounds))
            }
         }
      })
   }

   // public element: HTMLElement
   private searchOnDrag = true


}