import React, { FC } from "react";
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

/* components */
import NotFoundPage from 'pages/Static/NotFound';
import PersonItemInfoTab from './Info';
import InvolvementHistoryTab from './InvolvementHistory';
import PropertiesTab from '../../../components/page/OwnedProperties';

/* constants */
import { personsTabs } from 'config/constants/tabs';

/* types */
import { IStore } from 'controllers/store';
import { IPersonState } from 'controllers/person/models';
import { EntityEnum } from "@ternala/voltore-types/lib/constants";

interface Props
  extends RouteComponentProps<{
    id?: string;
    tabName?: string;
  }> {
  personsState: IPersonState;
}

export const PersonTabsContent: FC<Props> = ({
  match: { params },
  ...props
}) => {
  const id = params?.id;
  const tabName = params?.tabName;
  const idNumber = Number(id);

  if (isNaN(idNumber)) {
    return <NotFoundPage />;
  }

  return (
    <div className="tabs__content">
      {tabName === personsTabs.info.path && (
        <PersonItemInfoTab personId={idNumber}>
          {props.children}
        </PersonItemInfoTab>
      )}

      {tabName === personsTabs.enterpriseInvolvement.path && (
        <InvolvementHistoryTab personId={idNumber} tabName={tabName}>
          {props.children}
        </InvolvementHistoryTab>
      )}

      {tabName === personsTabs.properties.path && (
        <PropertiesTab id={idNumber} type={EntityEnum.person}>
          {props.children}
        </PropertiesTab>
      )}
    </div>
  );
};

export default connect((store: IStore) => ({
  personsState: store.person,
}))(PersonTabsContent);
