import { createReducer, ActionType } from "typesafe-actions";
import { all } from "redux-saga/effects";

// Actions
import * as actions from "./actions";
import { showElementActionSaga } from './sagas/showElement';

// Interfaces
import { IShowElementState } from "./models";
import { checkCommonItems } from "../../utils/checkCommonItems";

export type ShowElementActionType = ActionType<typeof actions>;

export const showElementSaga = function* () {
  yield all([
    showElementActionSaga()
  ]);
};

/* Reducer */
const initialState: IShowElementState = {
  isShow: false,
  showedElements: [],
};

export const showElementReducer = createReducer<IShowElementState, ShowElementActionType>(
  initialState
)
  .handleAction(
    actions.setIsShow,
    (state: IShowElementState, { payload }): IShowElementState => ({
      ...state,
      isShow: payload
    })
  )
  .handleAction(
    actions.addShowedElement,
    (state: IShowElementState, { payload }): IShowElementState => ({
      ...state,
      showedElements: [...state.showedElements, payload]
    })
  )
  .handleAction(
    actions.removeShowedElement,
    (state: IShowElementState, { payload }): IShowElementState => ({
      ...state,
      showedElements: state.showedElements.filter(el => el.id !== payload)
    })
  )
  .handleAction(
    actions.selectEntity,
    (state: IShowElementState, { payload }): IShowElementState => ({
      ...state,
      selectedEntity: payload
    })
  )
  .handleAction(
    actions.unselectEntity,
    (state: IShowElementState, { payload }): IShowElementState => {
      if(state.selectedEntity?.type === payload.type && checkCommonItems(state.selectedEntity.ids, payload.ids)){
        return ({
          ...state,
          selectedEntity: undefined
        })
      }
      return state
    }
  );


/* Selectors */

