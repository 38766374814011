
import React, { useEffect } from 'react';

/* UI components */
import Modal from 'components/Hocs/Portal';
import Button from 'components/UI/controls/Button';

interface IProps {
  id: number;
  hasTags: boolean;
  onClose: () => void;
  onSubmit: (id: number, callback?: Function) => void;
}

const PopupDeleteGroup = ({ id, hasTags, onClose, onSubmit }: IProps) => {
  /* keydown functionality */
  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onClose();
  };

  return (
    <Modal>
      <div className="modal modal_centered">
        <div className="scroll-area">
          <form
            className="modal-content"
            onSubmit={() => onSubmit(id, () => onClose())}
            style={{ paddingBottom: hasTags ? '10px' : '0px' }}>
            <div className="modal__close" onClick={onClose} title="Close" />

            <div className="modal-title-delete">
              {hasTags ? 'Cannot delete group.' : 'Delete group'}
            </div>
            {hasTags ? (
              <div className="modal-subtitle-delete">
                <p>
                  You can't delete this entity because there's at least one
                  child entity of it.
                </p>
                <p>
                  Please delete all of the child entities to be able to delete
                  this entity.
                </p>
              </div>
            ) : (
              <div className="popup-delete__content">
                Are you sure you want to delete this data?
                <br />
                All data will be lost.
              </div>
            )}

            {!hasTags ? (
              <footer className="modal-footer">
                <Button variant="light" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="dark"
                  onClick={() => onSubmit(id, () => onClose())}>
                  Delete
                </Button>
              </footer>
            ) : (
              ''
            )}
          </form>
        </div>

        <div className="modal-overlay" onClick={onClose} />
      </div>
    </Modal>
  );
};

export default PopupDeleteGroup;
