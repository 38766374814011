import { TagApi } from 'controllers/tag/transport/tag.api';
import { PersonApi } from 'controllers/person/transport/person.api';
import { EnterpriseApi } from 'controllers/enterprise/transport/enterprise.api';
import { InvolvementApi } from 'controllers/involvement/transport/involvement.api';
import { PropertyApi } from 'controllers/property/transport/property.api';

/* enum */
import {
  EnterpriseTypeByTransaction, GenderEnum,
  LeaseTransactionStatusEnum,
  personSortFields,
  PhoneTypeEnum,
  SaleTransactionStatusEnum,
  sortType,
  states,
  TaxonomyTypeSlug,
  UserTypeEnum
} from "@ternala/voltore-types/lib/constants";
import { mapEnum } from 'utils/helper-functions';

import { OptionType } from 'models';
import { UserRoleApi } from 'controllers/user/transport/userRole.api';
import { TaxonomyApi } from 'controllers/taxonomy/transport/taxonomy.api';
import { propertyToAction } from '@ternala/voltore-types/lib/card';
import { AsyncOptions } from 'components/UI/controls/SelectSearchCustom';
import { CountyApi } from '../../controllers/county/transport/county.api';
import { PersonDTO } from "@ternala/voltore-types";

export enum ListingStatusEnum {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  SOLD = 'Sold',
  LEASED = 'Leased',
  // CANCELED = 'Canceled',
  CANCELLED = 'Cancelled',
  EXPIRED = 'Expired',
  // STARRED = 'Starred'
}

export const userTypeOptions = [
  { label: 'User', value: UserTypeEnum.SU },
  { label: 'Super Admin', value: UserTypeEnum.SA },
];

export const genderOptions = [
  { label: 'Male', value: GenderEnum.Male },
  { label: 'Female', value: GenderEnum.Female },
];


export const userStatusOptions = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
];

export const loadPersonsFormInvolvementsOptions = async (
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await PersonApi.getPersons(
      {
        ...searchParams,
        sortField: personSortFields.firstName,
        sortType: sortType.asc,
      },
      token,
    );
    if (typeof data === 'string' || 'message' in data) {
      return [];
    }
    const personsOptions = data?.items.map((item) => ({
      value: item.id,
      label: `${item.firstName}${
        item.middleName ? ' ' + item.middleName : ''
      } ${item.lastName} ${item.nickName ? `(${item.nickName})` : ''}`,
    }));
    return personsOptions || [];
  }
  return [];
};

export const loadCountiesOptions = async (
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await CountyApi.getCounties(
      { ...searchParams, sortType: sortType.asc },
      token,
    );
    if (typeof data === 'string' || 'message' in data) {
      return [];
    }
    const options = data?.items.map((item) => {
      const code = item.stateCode;
      const state = code && code in states ? (states as any)[code] : '';
      return {
        value: item.id,
        label: item.title + (state ? ` - ${state?.full}` : ''),
        title: item.title
      };
    });
    return options || [];
  }
  return [];
};

export const generatePersonLabel = (item: PersonDTO) => `${item.firstName}${
  item.middleName ? ' ' + item.middleName : ''
} ${item.lastName} ${item.nickName ? `(${item.nickName})` : ''}`

export const loadPersonsOptions = async (
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await PersonApi.getPersonsWithoutConnectedUser(
      searchParams,
      token,
    );
    const personsOptions = data?.items.map((item) => ({
      value: item.id,
      label: generatePersonLabel(item),
    }));
    return personsOptions || [];
  }
  return [];
};

export const loadUserRoleOptions = async (
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await UserRoleApi.getUserRoleWithoutConnectedUser(
      searchParams,
      token,
    );
    const userRoleOptions = data?.items.map((item) => ({
      value: item.id,
      label: item.title,
    }));
    return userRoleOptions || [];
  }
  return [];
};

export const loadEnterprisesOptionsWithPagination = async (
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await EnterpriseApi.getEnterprises(searchParams, token);
    if(typeof data === "string" || "message" in data) return [];
    const enterprisesOptions = data?.items.map((item) => ({
      value: item.id,
      label: item.title,
      title: item.title,
      type: item.enterpriseType?.title,
    }));
    return enterprisesOptions || [];
  }
  return [];
};

export const loadEnterprisesOptions = async (
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await EnterpriseApi.getAllEnterprises(searchParams, token);
    const enterprisesOptions = data?.items.map((item) => ({
      value: item.id,
      label: item.title,
      title: item.title,
      type: item.enterpriseType?.title,
    }));
    return enterprisesOptions || [];
  }
  return [];
};

export const loadFloorsOptions = async (
  property: number,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await PropertyApi.getProperty({ id: property }, token);
    if (typeof data === 'string' || 'message' in data) return [];

    const floorOptions = data?.floors.map((item) => ({
      value: item.id,
      label: item.title,
      title: item.title,
      orderNumber: item.position,
    }));

    return floorOptions || [];
  }
  return [];
};

export const loadEnterpriseInvolvementsOptions = async (
  searchParams: AsyncOptions,
  enterpriseId: number,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await InvolvementApi.getAllInvolvementsByEnterpriseId(
      token,
      enterpriseId,
      searchParams,
    );
    const involvementsOptions = data?.items.map((item) => ({
      value: item.id,
      label: `${item.person?.firstName}${
        item.person?.middleName ? ' ' + item.person?.middleName : ''
      } ${item.person?.lastName}${
        item.person?.nickName ? ` (${item.person?.nickName})` : ''
      } - ${item.involvementType?.title}`,
      firstName: item.person?.firstName,
      lastName: item.person?.lastName,
      involvementType: item.involvementType?.title,
      enterprise: item.enterprise?.title,
    }));
    return involvementsOptions || [];
  }
  return [];
};

export const loadEnterprisesOptionsByType = async (
  type: EnterpriseTypeByTransaction,
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await EnterpriseApi.getEnterprisesByType(
      { type },
      searchParams,
      token,
    );
    const enterprisesOptions = data?.items.map((item) => ({
      value: item.id,
      label: item.title,
    }));
    return enterprisesOptions || [];
  }
  return [];
};

export const loadInvolvementsOptionsByType = async (
  type: EnterpriseTypeByTransaction,
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await InvolvementApi.getInvolvementsByType(
      { type },
      searchParams,
      token,
    );
    const involvementsOptions = data?.items.map((item) => ({
      value: item.id,
      label: `${item.person?.firstName} ${item.person?.lastName} - ${item.involvementType?.title}`,
      firstName: item.person?.firstName,
      lastName: item.person?.lastName,
      involvementType: item.involvementType?.title,
      phones: item.phones,
      emails: item.emails,
    }));
    return involvementsOptions || [];
  }
  return [];
};

export const loadPropertiesOptions = async (
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await PropertyApi.getAllProperties(token, searchParams);
    const propertiesOptions = data?.items.map((item) => {
      const action = propertyToAction(item);
      return {
        value: item.id,
        label: action.entityTitle,
        title: action.entityTitle,
      };
    });
    return propertiesOptions || [];
  }
  return [];
};

export const loadTagCategoriesOptions = async (
  searchParams: AsyncOptions,
  entityId?: number,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await TagApi.getTagCategoryList(token, searchParams);
    const tagCategoriesOptions = (data && 'items' in data ? data?.items : [])
      .filter((groups) => groups.id !== entityId)
      .map((item) => ({
        value: item.id,
        label: item.title,
        title: item.title,
      }));
    return tagCategoriesOptions || [];
  }
  return [];
};

export const loadTagsOptions = async (
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await TagApi.getTagList({}, token, searchParams);
    if (typeof data === 'string') return [];
    const tagsOptions = data?.items.map((item) => ({
      value: item.id,
      label: `${item.title} ${item.category ? `- ${item.category.title}` : ''}`,
      title: `${item.title} ${item.category ? `- ${item.category.title}` : ''}`,
    }));
    return tagsOptions || [];
  }
  return [];
};

export const loadTagsByGroupOptions = async (
  tagId: number,
  groupId: number,
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await TagApi.getTagCategoryList(token, searchParams);
    if (!data || typeof data === 'string' || 'message' in data) return [];
    const group = data?.items.find((group) => group.id === groupId);
    const tagsOptions = group
      ? group.tags
          .filter((tag) => tag.id !== tagId)
          .map((item) => ({
            value: item.id,
            label: item.title,
            title: item.title,
          }))
      : [];
    return tagsOptions || [];
  }
  return [];
};

export const loadTaxonomyTypesOptions = async (
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await TaxonomyApi.getTaxonomyTypeListAction(token);
    if (typeof data === 'string' || 'message' in data) return [];
    const taxonomyTypesOptions = data?.items.map((item) => ({
      value: item.id,
      label: `${item.title} - ${item.title}`,
      title: `${item.title} - ${item.title}`,
    }));
    return taxonomyTypesOptions || [];
  }
  return [];
};

export const loadUserRoles = async (
  userId: number,
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await UserRoleApi.getUserRoles(searchParams, token);
    if (typeof data === 'string' || 'message' in data) return [];
    const userRoleOptions = data?.items
      .filter((user) => user.id !== userId)
      .map((item) => ({
        value: item.id,
        label: item.title,
        title: item.title,
      }));
    return userRoleOptions || [];
  }
  return [];
};

export const loadTaxonomiesByTypeOptions = async (
  type: TaxonomyTypeSlug,
  taxonomyId: number,
  searchParams: AsyncOptions,
  token?: string,
): Promise<OptionType[]> => {
  if (token) {
    const data = await TaxonomyApi.getTaxonomiesByType(
      { type },
      token,
      searchParams,
    );
    if (typeof data === 'string' || 'message' in data) return [];
    const taxonomiesOptions = data?.items
      .filter((taxonomy) => taxonomy.id !== taxonomyId)
      .map((item) => ({
        value: item.id,
        label: item.title,
        title: item.title,
      }));
    return taxonomiesOptions || [];
  }
  return [];
};

export const salesFilterOptions: OptionType[] = [
  { label: 'All statuses', value: '' },
  ...mapEnum(ListingStatusEnum, (item: ListingStatusEnum) => ({
    label: item,
    value: item,
  })).filter((item) => item.value !== ListingStatusEnum.LEASED),
];

export const leasesFilterOptions: OptionType[] = [
  { label: 'All statuses', value: '' },
  ...mapEnum(ListingStatusEnum, (item: ListingStatusEnum) => ({
    label: item,
    value: item,
  })).filter(
    (item) =>
      item.value !== ListingStatusEnum.SOLD &&
      item.value !== ListingStatusEnum.LEASED,
  ),
];

export const saleTransactionStatusOptions = mapEnum(
  SaleTransactionStatusEnum,
  (item: SaleTransactionStatusEnum) => ({ label: item, value: item }),
);

export const leaseTransactionStatusOptions = mapEnum(
  LeaseTransactionStatusEnum,
  (item: LeaseTransactionStatusEnum) => ({ label: item, value: item }),
);

function capitalize(item: string) {
  return item.toUpperCase();
}

export const phoneTypesOptions = mapEnum(
  PhoneTypeEnum,
  (item: PhoneTypeEnum) => ({
    label: capitalize(item),
    value: capitalize(item),
  }),
);
