import React from "react";
import { IControlEditor, IReminderEditor, ITagEditor, ReminderEditorStepEnum, TagEditorStepEnum } from "../model.d";
import ReminderCreator from "./Reminder";
import { CardFilterEnum } from "../../../controllers/card/models";
import { IUseEditors } from "./hooks/useTags";
import TagCreator from "./Tag";
import ControlInput from "./Control";

export const generateReminders = (reminderControl: IUseEditors<IReminderEditor>, modal: CardFilterEnum) => {
  return reminderControl.items.map((editor) => {
    const { role, date } = editor;
    const step = role
      ? date
        ? ReminderEditorStepEnum.description
        : ReminderEditorStepEnum.date
      : ReminderEditorStepEnum.role;
    return (
      <ReminderCreator
        {...editor}
        step={step}
        modal={modal}
        hasError={Boolean(editor.error.length)}
        updateReminder={(data) => {
          reminderControl.update({
            id: editor.id,
            step,
            error: [],
            ...data
          });
        }}
        closeReminder={() => {
          reminderControl.close(editor.id);
        }}
      />
    );
  });
};

export const generateTags = (manager: IUseEditors<ITagEditor>, modal: CardFilterEnum) => {
  return manager.items.map((editor) => {
    const { entity, tag } = editor;
    const step = entity
      ? tag
        ? undefined
        : TagEditorStepEnum.Tag
      : TagEditorStepEnum.Entity;
    return (
      <TagCreator
        key={editor.id}
        {...editor}
        step={step}
        modal={modal}
        hasError={Boolean(editor.error.length)}
        updateTagEditor={(data) =>
          manager.update({
            id: editor.id,
            error: [],
            ...data
          })
        }
        closeEditor={() => manager.close(editor.id)}
      />
    );
  });
};

export const generateControls = (
  { manager, modal, createReminder, createTag }: {
    manager: IUseEditors<IControlEditor>,
    modal: CardFilterEnum,
    createReminder: () => void,
    createTag: () => void
  }) => {
  return manager.items.map((e) => (
    <ControlInput
      id={e.id}
      key={e.id}
      searchValue={e.searchValue}
      setSearchValue={(val) =>
        manager.update({
          ...e,
          searchValue: val
        })
      }
      closeControl={() => manager.close(e.id)}
      openTagEditor={createTag}
      openReminderEditor={createReminder}
      modal={modal}
    />
  ));
};
