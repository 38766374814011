import React from 'react';

/* types */
import { PropertyOwnedDTO } from '@ternala/voltore-types';
import { EntityEnum, TaxonomyTypeSlug } from "@ternala/voltore-types/lib/constants";
import { PropertyRiverIcon } from 'components/icons/PropertyRiverIcon';

/* components */
import Link from 'components/UI/controls/Link';
import { PageEnum } from 'config/constants';
import { propertiesTabs } from 'config/constants/tabs';
import { formatAddress } from 'utils/helper-functions';
import { NoteEntityEnum } from 'components/Windows/model.d';
import { getListImage, getMainImage } from "../../../../utils/getImage";

interface IPropertyType {
  property: any;
  type: TaxonomyTypeSlug;
}

interface IProps {
  property: PropertyOwnedDTO;
}

const PropertyField = ({ property }: IProps) => {
  function type({ property, type }: IPropertyType) {
    if (property[TaxonomyTypeSlug[type].length]) return null;
    return property[TaxonomyTypeSlug[type]]
      ?.map((element: any) => element.title)
      .join(', ');
  }
  const propertyType = type({
    property,
    type: TaxonomyTypeSlug.propertyType,
  });
  const subPropertyType = type({
    property,
    type: TaxonomyTypeSlug.subPropertyType,
  });
  const secondaryPropertyType = type({
    property,
    type: TaxonomyTypeSlug.secondaryPropertyType,
  });
  const types = [propertyType, subPropertyType, secondaryPropertyType]
    .filter((type) => type !== null && type !== '')
    .join(' | ');

  return (
    <div className="owned-properties-property">
      <div className="image">
        {property.images?.length ? (
          <img alt={getMainImage(property.images)?.title} src={getListImage(property.images)} />
        ) : (
          <PropertyRiverIcon />
        )}
      </div>
      <div className="additional-information">
        {/*<span className="title">{property.title}</span>*/}
        <Link
          id={property.id}
          className="address"
          type={EntityEnum.property}
          fullPath={`/${PageEnum.PROPERTIES}/${property.id}/${propertiesTabs.property.path}`}
          newTabIcon>
          {formatAddress({
            address: property.address?.address,
            city: property.address?.city,
            state: property.address?.state,
            zipCode: property.address?.zipCode,
          })}
        </Link>
        <span className="types">{types}</span>
      </div>
    </div>
  );
};

export default PropertyField;
