import React, { FC } from 'react';
import Moment from 'react-moment';
import { InnerLink } from 'routing/InnerLink';

import { DATE_FORMAT } from 'config';
import { DotIcon } from 'components/icons/DotIcon';

interface Props {
  id: number | string;
  tabName?: string;
  involvementId?: number | string;
  user: {
    owner: boolean;
    decisionMaker: boolean;
    position: string;
    employeeName: string;
    startDate?: Date;
    endDate?: Date;
    nickname?: string;
  };
}

const InvolvementsListItem: FC<Props> = ({
  id,
  tabName,
  involvementId,
  user,
}) => (
  <InnerLink
    className="enteprise-involvements-list__item"
    activeClass="enteprise-involvements-list__item_active"
    to="enterprise-involvement"
    id={id}
    tabName={tabName}
    involvementId={involvementId}>
    <div className="item-summary">
      {user.decisionMaker && !user.owner && (
        <div className="item-summary__post">Decision Maker</div>
      )}

      {user.owner && !user.decisionMaker && (
        <div className="item-summary__post">Principal</div>
      )}

      {user.owner && user.decisionMaker && (
        <div className="item-summary__post">Principal | Decision Maker</div>
      )}

      <div className="item-summary__employee employee">
        <span className="employee-position">{user.position}</span>
        <DotIcon />
        <span className="employee-name">
          {user.employeeName} {user.nickname ? `(${user.nickname})` : ''}
        </span>
      </div>

      <div className="item-summary__date">
        {user.startDate ? (
          <>
            <Moment format={DATE_FORMAT}>{user.startDate}</Moment>
            &nbsp;-&nbsp;
          </>
        ) : (
          'N\\A - '
        )}

        {user.endDate ? (
          <Moment format={DATE_FORMAT}>{user.endDate}</Moment>
        ) : (
          'Present'
        )}
      </div>
    </div>
  </InnerLink>
);

export default InvolvementsListItem;
