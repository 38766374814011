import { Config } from 'config/api';
import { authHeader, handleErrors } from 'utils/API';

import { TaxonomyTypeSlug } from '@ternala/voltore-types/lib/constants';
import { appendSearchParams } from 'utils/appendSearchParams';

import {
  EnterpriseCreateResponse,
  EnterpriseDeleteRequest,
  EnterpriseDeleteResponse,
  EnterpriseFullExpandedDTO,
  EnterpriseFullWithTreeDTO,
  EnterpriseGetListByTypeByTransactionRequest,
  EnterpriseGetListRequest,
  EnterpriseGetListResponse,
  EnterpriseGetRequest,
  EnterpriseGetResponse,
  EnterpriseUpdateResponse,
  PropertyOwnedDTO,
} from '@ternala/voltore-types';

import {
  EnterpriseCreateRequestExpanded,
  EnterpriseUpdateRequestExpanded,
} from '../models';
import { AsyncOptions } from 'components/UI/controls/SelectSearchCustom';
import { IError } from '../../model';

class API {
  public prepareGetListLink(url: URL, data: EnterpriseGetListRequest) {
    if (data.hasOwnProperty('selectedTags')) {
      data.selectedTags?.forEach((tag) => {
        url.searchParams.append('selectedTags[]', `${tag}`);
      });
    }

    if (data.hasOwnProperty('unselectedTags')) {
      data.unselectedTags?.forEach((tag) => {
        url.searchParams.append('unselectedTags[]', `${tag}`);
      });
    }

    if (Array.isArray(data[TaxonomyTypeSlug.enterpriseType])) {
      data[TaxonomyTypeSlug.enterpriseType]?.forEach((item) => {
        url.searchParams.append(
          `${TaxonomyTypeSlug.enterpriseType}[]`,
          String(item),
        );
      });
    }

    if (Array.isArray(data[TaxonomyTypeSlug.involvementType])) {
      data[TaxonomyTypeSlug.involvementType]?.forEach((item) => {
        url.searchParams.append(
          `${TaxonomyTypeSlug.involvementType}[]`,
          String(item),
        );
      });
    }

    if (Array.isArray(data.parents)) {
      data.parents?.forEach((item) => {
        url.searchParams.append(`parents[]`, String(item));
      });
    }

    if (Array.isArray(data.persons)) {
      data.persons?.forEach((item) => {
        url.searchParams.append(`persons[]`, String(item));
      });
    }

    if (data.hasOwnProperty('owner'))
      url.searchParams.append('owner', String(data.owner));
    if (data.hasOwnProperty('requireChildren'))
      url.searchParams.append('requireChildren', String(data.requireChildren));
    if (data.hasOwnProperty('decisionMaker'))
      url.searchParams.append('decisionMaker', String(data.decisionMaker));

    return url;
  }

  public async getEnterprises(
    getEnterpriseData: EnterpriseGetListRequest,
    token: string,
  ): Promise<EnterpriseGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'enterprise/list');

    url = appendSearchParams(url, getEnterpriseData);

    url = this.prepareGetListLink(url, getEnterpriseData);

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async createEnterprise(
    createEnterpriseData: EnterpriseCreateRequestExpanded,
    token: string,
  ): Promise<EnterpriseCreateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'enterprise/create');

    const data = new FormData();

    Object.entries(createEnterpriseData).forEach(([key, val]) => {
      if (key === 'officeAddress') {
        for (let key1 in val) {
          data.append(`${key}[${key1}]`, val[key1]);
        }
        return;
      }

      if (key === 'phones') {
        val.map((item: any) => {
          return data.append(`phones[]`, JSON.stringify(item));
        });
        return;
      }

      if (key === 'emails') {
        val.map((item: any) => {
          return data.append(`emails[]`, JSON.stringify(item));
        });
        return;
      }

      if (Array.isArray(val)) {
        val.forEach((singleValue) => {
          data.append(`${key}[]`, singleValue);
        });
        return;
      }

      if (key === 'phones' || key === 'emails') {
        val.map((item: any) => {
          return data.append(`${key}[]`, JSON.stringify(item));
        });
        return;
      }

      return data.append(key, val);
    });

    return handleErrors(
      fetch(url.toString(), {
        method: 'POST',
        headers: {
          // "Content-Type": "application/json",
          ...authHeader(token),
        },
        body: data,
      }),
    );
  }

  public async getEnterprise(
    getEnterpriseData: EnterpriseGetRequest,
    token: string,
  ): Promise<EnterpriseGetResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'enterprise/get');

    url.searchParams.append('id', String(getEnterpriseData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async updateEnterprise(
    updateEnterpriseData: EnterpriseUpdateRequestExpanded,
    token: string,
  ): Promise<EnterpriseUpdateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'enterprise/update');

    const data = new FormData();

    Object.entries(updateEnterpriseData).forEach(([key, val]) => {
      if (key === 'phones' || key === 'emails') {
        val.map((item: any) => {
          return data.append(`${key}[]`, JSON.stringify(item));
        });
        return;
      }

      if (key === 'officeAddress') {
        for (let key1 in val) {
          data.append(`${key}[${key1}]`, val[key1]);
        }
        return;
      }

      if (Array.isArray(val) && key !== 'phones') {
        val.forEach((singleValue) => {
          data.append(`${key}[]`, singleValue);
        });
        return;
      }

      return data.append(key, val);
    });

    return handleErrors(
      fetch(url.toString(), {
        method: 'PUT',
        headers: {
          // "Content-Type": "application/json",
          ...authHeader(token),
        },
        body: data,
      }),
    );
  }

  public async deleteEnterprise(
    deleteEnterpriseData: EnterpriseDeleteRequest,
    token: string,
  ): Promise<EnterpriseDeleteResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'enterprise/delete');

    url.searchParams.append('id', String(deleteEnterpriseData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async getAllEnterprises(
    searchParams: AsyncOptions,
    token: string,
  ): Promise<EnterpriseGetListResponse | null> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'enterprise/list');
    url = appendSearchParams(url, searchParams);

    try {
      const response = await fetch(String(url), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      });
      const data = await response.json();
      if (response.statusText === 'OK' || response.status === 200) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getEnterprisesByType(
    getEnterprisesData: EnterpriseGetListByTypeByTransactionRequest,
    searchParams: AsyncOptions,
    token: string,
  ): Promise<EnterpriseGetListResponse> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'enterprise/list-by-type');
    url = appendSearchParams(url, searchParams);
    if (getEnterprisesData.hasOwnProperty('type'))
      url.searchParams.append('type', String(getEnterprisesData.type));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async getEnterpriseTree(
    getEnterpriseData: EnterpriseGetRequest,
    token: string,
  ): Promise<EnterpriseFullWithTreeDTO | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'enterprise/get-tree');

    url.searchParams.append('id', String(getEnterpriseData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async getOwnedProperties(
    getOwnedPropertiesData: { id: number },
    token: string,
  ): Promise<PropertyOwnedDTO[] | string | IError> {
    let url = new URL(
      Config.MAIN_SERVICE_ENDPOINT + 'enterprise/owned-properties',
    );

    url.searchParams.append('id', String(getOwnedPropertiesData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async getEnterpriseOwnershipInfo(
    getEnterpriseData: EnterpriseGetRequest,
    token: string,
  ): Promise<EnterpriseFullExpandedDTO | string> {
    let url = new URL(
      Config.MAIN_SERVICE_ENDPOINT + 'enterprise/get-ownership-info',
    );

    url.searchParams.append('id', String(getEnterpriseData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }
}

export const EnterpriseApi = new API();
