import React, { FC, useState } from "react";

/* UI components */
import Modal from 'components/Hocs/Portal';
import Input from 'components/UI/controls/Input';
import Button from 'components/UI/controls/Button';

/* types */
import { PhoneUpdateDTO } from '@ternala/voltore-types';
import InputMulti from 'components/UI/controls/InputMulti';
import { phoneTypesOptions } from 'config/constants/select-options';
import { PhoneTypeEnum } from '@ternala/voltore-types/lib/constants';
import SelectSearch from 'components/UI/controls/SelectSearchCustom';
import uuid from 'utils/uuid';

interface IProps {
  onClose: () => void;
  data?: PhoneUpdateDTO & { uuid?: string };
  phones?: PhoneUpdateDTO[];
  onChange: (
    phone: PhoneUpdateDTO & { uuid?: string },
    callback: Function,
  ) => void;
}

const PopupPhone: FC<IProps> = ({ onClose, onChange, data, phones }) => {
  const [error, setError] = useState<string>('');
  const [type, setType] = useState<PhoneTypeEnum>(
    data ? data.slug : PhoneTypeEnum.HOME,
  );
  const [phone, setPhone] = useState<string | undefined>(
    data ? data.phone : undefined,
  );
  const [label, setLabel] = useState<string | undefined>(
    data ? data.label : undefined,
  );
  const submitData = () => {
    if (error || !type || !phone) return;
    const payload: PhoneUpdateDTO & { uuid?: string } = {
      phone,
      label,
      slug: type,
      id: data?.id,
      uuid: data?.uuid || uuid(),
      isFavorite: data?.isFavorite || false,
    };
    onChange(payload, () => onClose());
  };

  return (
    <Modal>
      <div className="modal modal_centered">
        <div className="scroll-area">
          <form className="modal-content" onSubmit={submitData}>
            <div className="modal__close" onClick={onClose} title="Close" />
            <div className="modal-title">{data ? 'Edit' : 'Create'} phone</div>
            <div
              className="items-container"
              style={{
                flexDirection: 'column',
                marginBottom: '25px',
              }}>
              <SelectSearch
                label="type"
                options={phoneTypesOptions}
                value={{ label: type, value: type }}
                onChange={(option) => setType(option?.label as PhoneTypeEnum)}
                uppercase
                required
              />
              <InputMulti
                required
                type="phone"
                label="Phone"
                placeholder="Phone number"
                name="phone"
                items={phones?.filter(
                  //@ts-ignore
                  (phone) => phone.id !== data?.id || phone.uuid !== data?.uuid,
                )}
                isTypePhones
                data={[].map((value: any) => value)}
                value={phone || ''}
                error={error}
                onChange={(e) => setPhone(e.target.value)}
                onError={(error) => setError(error)}
              />
              <Input
                label="label"
                placeholder="Type here"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </div>

            <footer className="modal-footer">
              <Button variant="light" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="dark"
                onClick={submitData}
                disabled={!type || phone === ('' || undefined) || error !== ''}>
                Save
              </Button>
            </footer>
          </form>
        </div>

        <div className="modal-overlay" onClick={onClose} />
      </div>
    </Modal>
  );
};

export default PopupPhone;
