import {
  ReminderToggleRequest,
  ReminderToggleResponse,
} from '@ternala/voltore-types';

import { Config } from '../../../config/api';
import { authHeader, handleErrors } from '../../../utils/API';
import { IError } from "../../model";

class API {
  public async toggleReminder(
    data: ReminderToggleRequest,
    token: string,
  ): Promise<ReminderToggleResponse | string | IError> {
    let url = new URL(
      Config.MAIN_SERVICE_ENDPOINT + `card/reminder/${data.id}`,
    );

    return handleErrors(
      fetch(url.toString(), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }
}
export const ReminderApi = new API();
