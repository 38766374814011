export enum Color {
   label = '#627D7A',
   main = '#FFF',
   mainDark = '#1F2531',
   default = '#999EA0',
   active = '#1F1F1F',
   disabled = '#C1C1C1',
   selection = '#3B545A',
   underlineDefault = 'rgba(27, 47, 52, 0.1)',
   underlineDisabled = 'rgba(193, 193, 193, 0.1)',
   underlineDarkDefault = 'rgba(255, 255, 255, 0.1)',
   error = '#FF0000',
   link = '#1F3560',
   optionHover = '#1F2531',
   creationDefault = '#1F2531',
   creationDisabled = '#D1D5D6',
   plusIconHover = '#132226',
   plusIconInvalid = '#D1D5D6',
   buttonDisabled = "#D1D5D6",

   listingStatusActive = '#53A956',
   listingStatusPending = '#D3C013',
   listingStatusSold = '#CF4628',
   listingStatusLeased = '#CF4628',
   listingStatusCanceled = '#939495',
   listingStatusExpired = '#49627E'
}

export type ControlStateType = {
   label: string
   value: string
   isFocused: boolean,
   isDisabled: boolean,
   borderStyle?: boolean
}

export const sharedStyles = {
   container: (base: any) => ({
      ...base,
      marginTop: -100
   }),
   indicatorsContainer: () => ({
      marginBottom: -10
   }),
   control: (base: any, state: ControlStateType) => ({
      ...base,
      minHeight: 35,
      cursor: 'pointer',
      border: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
      /* underline color */
      boxShadow: `0 1px 0 0 ${stateColor({ ...state, borderStyle: true })}`
   }),
   valueContainer: (base: any) => ({
      ...base,
      padding: 0,
      marginLeft: -2,
      marginBottom: -10
   }),
   placeholder: (base: any, state: ControlStateType) => ({
      ...base,
      color: stateColor(state)
   }),
   menu: (base: any) => ({
      ...base,
      marginTop: 5,
      position: 'absolute',
      zIndex: 2,
      backgroundColor: Color.main,
      boxShadow: '0 4px 10px rgba(33, 33, 33, 0.13)'
   }),
   option: (base: any, state: ControlStateType) => ({
      ...base,
      ':active': {
         backgroundColor: Color.selection
      },
      WebkitTapHighlightColor: Color.selection,
      cursor: 'pointer',
      color: state.isFocused && Color.main,
      backgroundColor: state.isFocused ? Color.optionHover : Color.main
   }),
   noOptionsMessage: (base: any) => ({
      ...base,
      color: Color.default,
      fontSize: 14,
      height: 26,
      boxShadow: '0px 4px 10px rgba(33, 33, 33, 0.13)',
      borderRadius: 1,
      paddingLeft: 10,
      display: 'flex',
      alignItems: 'center'
   })
}

export const stateColor = (control: ControlStateType, singleValueSelect?: boolean): string => {
   if (control.isDisabled || (control.isDisabled && singleValueSelect)) {
      return control.borderStyle ? Color.underlineDisabled : Color.disabled
   } else if (control.isFocused || singleValueSelect) {
      return Color.active
   } else {
      return control.borderStyle ? Color.underlineDefault : Color.default
   }
}
