import { activityHistoryTypeEnum } from '@ternala/voltore-types/lib/constants';
import { IHistoryData } from 'models';
import { createContext } from 'react';

const initialState = {
  entity: {
    id: undefined as number | undefined,
    type: undefined as activityHistoryTypeEnum | undefined,
  },
  setEntity: (data: IHistoryData) => {},
};

const HistoryContext = createContext(initialState);

export const HistoryProvider = HistoryContext.Provider;

export default HistoryContext;
