export const appName = "Voltore App"
export const enableSocketLog = false

export const LOADED_ITEMS_LIMIT = 100
export const ITEMS_LOADER_HEIGHT = 75

export const DATE_FORMAT = 'MM/DD/YYYY'
export const MAX_IMAGE_SIZE = 100000000;
export const MAX_DOCUMENT_SIZE = 100000000;
export const PHONE_NUMBER_LENGTH = 10
export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const errorMessages = {
   required: 'Required',
   email: 'Email is invalid',
   password: 'Password requires letters, numbers and other special symbols in it. It’s case sensitive',
   minlength: 'Password is too short',
   maxlength: 'Password is too long',
   pattern: 'Value does\'nt match a provided RegExp',
   passwordsMismatch: 'Password and confirmed password should match'
}

export const IMAGE_TYPES = ['jpg', 'jpeg', 'png'];
