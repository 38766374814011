import React, { FC, FormEvent, MouseEvent, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

/* components */
import InvolvementModal from 'components/modals/InvolvementModal';
import EnterpriseModal from 'components/modals/EnterpriseModal';
import PopupCreateFloors from 'components/modals/properties/PopupCreateFloors';
import PopupCreateTaxonomy from 'components/modals/PopupCreateTaxonomy';
import Modal from 'components/Hocs/Portal';

import InputNumber from 'components/UI/controls/InputNumber';
import Textarea from 'components/UI/controls/TextArea';
import SelectSearch from 'components/UI/controls/SelectSearchNew';
import SelectMultiSearch from 'components/UI/controls/SelectMultiSearch';
import SelectListingStatus from 'components/UI/controls/SelectListingStatus';
import DatePicker from 'components/UI/controls/DatePicker';
import Attachments from 'components/UI/controls/Attachments';
import Button from 'components/UI/controls/Button';
import PropertyLocationMap from 'pages/Properties/GoogleMaps/PropertyLocationMap';

/* controllers */
import {
  getPropertyAction,
} from 'controllers/property/actions/propertyActions';
import {
  createPropertySuiteAction,
  updatePropertyFloorListAction,
} from 'controllers/property/actions/additional';
import { createEnterpriseAction } from 'controllers/enterprise/actions';
import { createInvolvementAction } from 'controllers/involvement/actions';
import {
  createTaxonomyAction,
  getTaxonomiesByTypeAction,
} from 'controllers/taxonomy/actions';

/* constants & utils */
import {
  RequestActionEnum,
  statusOrder,
  TaxonomyOrEntityType,
  Unit,
} from 'config/constants';
import {
  leaseTransactionStatusOptions,
  loadEnterpriseInvolvementsOptions,
  loadEnterprisesOptions,
  loadPropertiesOptions,
  loadTagsOptions,
} from 'config/constants/select-options';
import {
  LeaseTransactionStatusEnum,
  TaxonomyTypeSlug,
} from '@ternala/voltore-types/lib/constants';
import { formatAddress, numberToString } from 'utils/helper-functions';

/* types */
import { IStore } from 'controllers/store';
import {
  IPropertyState,
  LeaseTransactionCreateRequestExpanded,
  LeaseTransactionUpdateRequestExpanded,
} from 'controllers/property/models';
import { IEnterpriseState } from 'controllers/enterprise/models';
import { ITaxonomyState } from 'controllers/taxonomy/models';
import {
  EnterpriseShortDTO,
  LeaseTransactionStatusHistoryDTO, PropertyShortDTO,
  TaxonomyCreateResponse
} from "@ternala/voltore-types";
import { IPoint } from '@ternala/voltore-types/lib/modules/property/filters/area';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { INewTaxonomy, OptionType } from 'models';
import {
  createLeaseTransactionAction,
  updateLeaseTransactionAction,
} from 'controllers/property/actions';
import PopupTagGroup from '../PopupTagGroup';
import PopupTag from '../PopupTag';
import { TagApi } from 'controllers/tag/transport/tag.api';
import { addNotification } from 'controllers/modals/actions';
import { NotificationTypeEnum } from 'controllers/modals/models.d';
import uuid from 'utils/uuid';
import CustomScrollbars from 'components/CustomScrollbars';
import { propertyToAction } from '@ternala/voltore-types/lib/card';
import { getAccessTokenUtil } from "utils";
import { addShowedElement } from "../../../controllers/showElement/actions";
import { propertyModalKey } from "./PropertyModalConent";
import { IModalElement, ShowElementEnum } from "../../../controllers/showElement/models.d";

interface Props {
  property: {
    id: number;
    transactionId?: number;
    defaultOwner?: EnterpriseShortDTO;
  };
  propertyState: IPropertyState;
  enterpriseState: IEnterpriseState;
  taxonomyState: ITaxonomyState;
  onClose: () => void;
  onSubmit: (
    payload: (
      | LeaseTransactionCreateRequestExpanded
      | LeaseTransactionUpdateRequestExpanded
    ) & { callback?: Function },
    requestAction: RequestActionEnum,
  ) => void;
}

const LeaseTransactionModal: FC<Props> = (props) => {
  const {
    property,
    propertyState: { leaseTransactionData, propertyData },
    taxonomyState: { taxonomies },
    onClose,
    onSubmit,
  } = props;

  const currentProperty = propertyData[property.id];
  const editableTransaction = property.transactionId
    ? leaseTransactionData[property.transactionId]
    : undefined;

  const dispatch = useDispatch();

  const [tags, setTags] = useState<OptionType[] | undefined>(
    editableTransaction?.cardTagConnects?.map((card) => ({
      label: card.tag?.title,
      value: card.tag?.id,
    })) || [],
  );
  const [isTagCreationOpen, setIsTagCreationOpen] = useState<boolean>(false);
  const [isTagCategoryCreationOpen, setIsTagCategoryCreationOpen] =
    useState<boolean>(false);
  const [isInvolvementModalOpen, setIsInvolvementModalOpen] = useState<
    'tenants' | 'landlords' | undefined
  >(undefined);
  const [isEnterpriseModalOpen, setIsEnterpriseModalOpen] = useState<
    'landlord' | 'tenant' | undefined
  >(undefined);
  const [isCreateFloorsPopupOpen, setIsCreateFloorsPopupOpen] =
    useState<boolean>(false);
  const [isCreateSuitePopupOpen, setIsCreateSuitePopupOpen] =
    useState<boolean>(false);
  const [isCreateTaxonomyPopupOpen, setIsCreateTaxonomyPopupOpen] =
    useState<boolean>(false);
  const [creatableTaxonomy, setCreatableTaxonomy] = useState<INewTaxonomy>();

  const [propertyName, setPropertyName] = useState<OptionType | undefined>({
    label: propertyToAction(currentProperty)?.entityTitle,
    value: currentProperty?.id,
  });

  const [listingStatus, setListingStatus] = useState<
    LeaseTransactionStatusHistoryDTO[]
  >(editableTransaction?.historyStatuses || []);

  const [requestProcess, setRequestProcess] = useState<boolean>(false);

  const [leaseCommencement, setLeaseCommencement] =
    useState<MaterialUiPickersDate>(
      editableTransaction?.leaseCommencement || null,
    );
  const [leaseExpiration, setLeaseExpiration] = useState<MaterialUiPickersDate>(
    editableTransaction?.leaseExpiration || null,
  );
  const [leasablePropertySqft, setLeasablePropertySqft] = useState<string>(
    numberToString(editableTransaction?.leasableSQFT),
  );
  const [leasableLotSqft, setLeasableLotSqft] = useState<string>(
    numberToString(editableTransaction?.leasableLotSQFT),
  );
  const [landlord, setLandlord] = useState<OptionType | undefined>(
    editableTransaction?.owner || property.defaultOwner
      ? {
          label: editableTransaction?.owner
            ? editableTransaction?.owner?.title
            : property.defaultOwner?.title,
          value: editableTransaction
            ? editableTransaction?.owner?.id
            : property.defaultOwner?.id,
        }
      : undefined,
  );
  const [tenant, setTenant] = useState<OptionType | undefined>(
    editableTransaction?.tenant && {
      label: editableTransaction.tenant.title,
      value: editableTransaction.tenant.id,
    },
  );
  const [offeredLeaseRatePerSqft, setOfferedLeaseRatePerSqft] =
    useState<string>(numberToString(editableTransaction?.offeredRate));
  const [actualLeaseRatePerSqft, setActualLeaseRatePerSqft] = useState<string>(
    numberToString(editableTransaction?.actualRate),
  );
  const [leaseType, setLeaseType] = useState<OptionType | undefined>(
    editableTransaction?.[TaxonomyTypeSlug.leaseType] && {
      label: editableTransaction?.[TaxonomyTypeSlug.leaseType]?.title,
      value: editableTransaction?.[TaxonomyTypeSlug.leaseType]?.id,
    },
  );
  const [leaseExpense, setLeaseExpense] = useState<string>(
    numberToString(editableTransaction?.leaseExpense),
  );

  const [leaseLocation, setLeaseLocation] = useState<IPoint | undefined>(
    editableTransaction?.latitude && editableTransaction?.longitude
      ? {
          latitude: editableTransaction?.latitude,
          longitude: editableTransaction?.longitude,
        }
      : undefined,
  );

  const [floors, setFloors] = useState<OptionType[] | undefined>(
    editableTransaction?.floors?.map((item) => ({
      label: item.title,
      value: item.id,
    })) || [],
  );
  const [suites, setSuites] = useState<OptionType[] | undefined>(
    editableTransaction?.suites?.map((item) => ({
      label: item.title,
      value: item.id,
    })) || [],
  );
  const [involvedFromLandlordsSide, setInvolvedFromLandlordsSide] = useState<
    OptionType[] | undefined
  >(
    editableTransaction?.ownerInvolved?.map((item) => ({
      label: `${item.person?.firstName} ${item.person?.lastName}`,
      value: item.id,
    })) || [],
  );
  const [involvedFromTenantsSide, setInvolvedFromTenantsSide] = useState<
    OptionType[] | undefined
  >(
    editableTransaction?.tenantInvolved?.map((item) => ({
      label: `${item.person?.firstName} ${item.person?.lastName}`,
      value: item.id,
    })) || [],
  );
  const [details, setDetails] = useState<string>(
    editableTransaction?.details || '',
  );

  const [oldSupplements, setOldSupplements] = useState<number[]>(
    editableTransaction?.supplements?.map((supplement) => supplement.id) || [],
  );
  const [listingStatusError, setListingStatusError] = useState<
    string | undefined
  >(undefined);
  const [newSupplements, setNewSupplements] = useState<File[]>([]);

  useEffect(() => {
    dispatch(getPropertyAction.request({ id: property.id }));
    dispatch(
      getTaxonomiesByTypeAction.request({ type: TaxonomyTypeSlug.leaseType }),
    );
  }, []);

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  /* listing status validation */
  const validation = (arr: any[]) => {
    for (let i = 0; i < arr.length; i++) {
      if (i === 0) continue;
      const status = statusOrder[arr[i].status as LeaseTransactionStatusEnum];
      const prevStatus =
        statusOrder[arr[i - 1].status as LeaseTransactionStatusEnum];
      if (prevStatus >= status)
        setListingStatusError(
          'Order of transaction statuses is incorrect. Please see the instruction',
        );
      else setListingStatusError(undefined);
    }
  };

  const submitData = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    validation(listingStatus);
    if (listingStatusError) return;

    setRequestProcess(true);
    if (editableTransaction && property.transactionId) {
      const payload: ReturnType<
        typeof updateLeaseTransactionAction.request
      >['payload'] = {
        ...createPayload(),
        id: editableTransaction.id,
        oldSupplements,
        callback: (status: boolean) => {
          if (status) onClose();
          setRequestProcess(false);
        },
      };
      onSubmit(payload, RequestActionEnum.UPDATE);
    } else {
      const payload: ReturnType<
        typeof createLeaseTransactionAction.request
      >['payload'] = {
        ...createPayload(),
        callback: (status: boolean) => {
          if (status) onClose();
          setRequestProcess(false);
        },
      };
      onSubmit(payload, RequestActionEnum.CREATE);
    }
  };

  const createPayload = (): LeaseTransactionCreateRequestExpanded => {
    const payload: LeaseTransactionCreateRequestExpanded = {
      tenant: Number(tenant?.value),
      property: Number(currentProperty?.id),
      statuses: listingStatus.map((status) => JSON.stringify(status)),
    };

    if (propertyName?.value) payload.property = Number(propertyName.value);
    if (leaseCommencement) payload.leaseCommencement = leaseCommencement;
    if (leaseExpiration) payload.leaseExpiration = leaseExpiration;
    if (leasablePropertySqft)
      payload.leasableSQFT = Number(leasablePropertySqft);
    if (leasableLotSqft) payload.leasableLotSQFT = Number(leasableLotSqft);
    if (landlord?.value) payload.owner = Number(landlord.value);
    if (tenant?.value) payload.tenant = Number(tenant.value);
    if (offeredLeaseRatePerSqft)
      payload.offeredRate = Number(offeredLeaseRatePerSqft);
    if (actualLeaseRatePerSqft)
      payload.actualRate = Number(actualLeaseRatePerSqft);
    if (leaseType?.value)
      payload[TaxonomyTypeSlug.leaseType] = Number(leaseType.value);
    if (leaseExpense) payload.leaseExpense = Number(leaseExpense);
    if (floors?.length)
      payload.floors = floors.map((item) => Number(item.value));
    if (suites?.length)
      payload.suites = suites.map((item) => Number(item.value));
    if (involvedFromLandlordsSide?.length)
      payload.ownerInvolved = involvedFromLandlordsSide.map((item) =>
        Number(item.value),
      );
    if (involvedFromTenantsSide?.length)
      payload.tenantInvolved = involvedFromTenantsSide.map((item) =>
        Number(item.value),
      );
    if (newSupplements.length) payload.supplements = newSupplements;
    if (details) payload.details = details;
    if (leaseLocation?.latitude) payload.latitude = leaseLocation.latitude;
    if (leaseLocation?.longitude) payload.longitude = leaseLocation.longitude;
    if (tags) payload.tags = tags.map((item) => item.value as number);

    return payload;
  };

  return (
    <>
      <Modal>
        <div className="modal modal-lease-transaction">
          <div className="scroll-area">
            <CustomScrollbars
              style={{
                width: '700px',
                height: '100%',
              }}
              renderView={(props) => (
                <div
                  {...props}
                  className={'scroll-area'}
                  style={{
                    position: 'absolute',
                    inset: '0px',
                    overflowY: 'scroll',
                    marginRight: `${
                      navigator.platform === 'MacIntel' ? '-17px' : '-35px'
                    }`,
                    marginBottom: '-17px',
                  }}
                />
              )}>
              <form className="modal-content" onSubmit={submitData}>
                <div className="modal__close" onClick={onClose} title="Close" />

                <div className="modal-title">
                  {editableTransaction ? 'edit' : 'create'} lease transaction
                </div>

                <SelectSearch
                  label="property"
                  asyncOptions={async (searchParams) => {
                    const token = await getAccessTokenUtil(dispatch);
                    if (token) {
                      return await loadPropertiesOptions(searchParams, token);
                    }
                    return [];
                  }}
                  searchByFields={['title', 'address']}
                  value={propertyName && [propertyName]}
                  onChange={(options) => setPropertyName(options?.[0])}
                  creation={{
                    label: 'Create new property',
                    onClick: () => {
                      addShowedElement({
                        id: uuid(),
                        key: propertyModalKey,
                        type: ShowElementEnum.modal,
                        props: {},
                        callback: (payload: PropertyShortDTO) => {
                          setPropertyName({
                            label: formatAddress({
                              address: payload.address.address,
                              city: payload.address.city,
                              state: payload.address.state,
                              zipCode: payload.address.zipCode,
                            }),
                            value: payload.id,
                          });
                        },
                      } as IModalElement);
                    },
                  }}
                  required
                  fullWidth
                  readonly={!!editableTransaction}
                />

                <div className="flex-container">
                  <div>
                    <SelectListingStatus
                      transactionType="lease"
                      containerStyle={{ marginBottom: 50 }}
                      options={leaseTransactionStatusOptions}
                      history={listingStatus}
                      onUpdate={(status) => setListingStatus(status as any)}
                      setActiveStatus={!property.transactionId}
                      setError={setListingStatusError}
                      error={listingStatusError}
                      required
                    />
                  </div>

                  <div>
                    <DatePicker
                      label="lease commencement"
                      value={leaseCommencement}
                      onChange={(date: MaterialUiPickersDate) =>
                        setLeaseCommencement(date)
                      }
                    />

                    <DatePicker
                      label="lease expiration"
                      value={leaseExpiration}
                      onChange={(date: MaterialUiPickersDate) =>
                        setLeaseExpiration(date)
                      }
                      disableFuture={false}
                    />
                  </div>
                </div>

                <div className="flex-container">
                  <InputNumber
                    label="leasable Building SQFT"
                    unit="sqft"
                    placeholder="Enter numbers only"
                    defaultValue={leasablePropertySqft}
                    onChange={(sqft) => setLeasablePropertySqft(sqft)}
                    max={10000000000}
                    upperCaseLabel
                  />

                  <InputNumber
                    label="leasable lot sqft"
                    unit="sqft"
                    placeholder="Enter numbers only"
                    defaultValue={leasableLotSqft}
                    onChange={(sqft) => setLeasableLotSqft(sqft)}
                    max={10000000000}
                    upperCaseLabel
                  />
                </div>

                <div className="flex-container">
                  <SelectSearch
                    label="landlord"
                    asyncOptions={async (searchParams) => {
                      {
                        const token = await getAccessTokenUtil(dispatch);
                        if (token) {
                          return await loadEnterprisesOptions(
                            searchParams,
                            token,
                          );
                        }
                        return [];
                      }
                    }}
                    searchByFields={['title', 'type']}
                    value={landlord && [landlord]}
                    onChange={(options) => setLandlord(options?.[0])}
                    creation={{
                      label: 'Create new landlord',
                      onClick: () => setIsEnterpriseModalOpen('landlord'),
                    }}
                  />

                  <SelectSearch
                    label="tenant"
                    asyncOptions={async (searchParams) => {
                      {
                        const token = await getAccessTokenUtil(dispatch);
                        if (token) {
                          return await loadEnterprisesOptions(
                            searchParams,
                            token,
                          );
                        }
                        return [];
                      }
                    }}
                    searchByFields={['title', 'type']}
                    value={tenant && [tenant]}
                    onChange={(options) => setTenant(options?.[0])}
                    required
                    creation={{
                      label: 'Create new tenant',
                      onClick: () => setIsEnterpriseModalOpen('tenant'),
                    }}
                  />
                </div>

                <div className="flex-container">
                  <div>
                    <InputNumber
                      units={[
                        {
                          type: Unit.MONTHLY_LEASE_RATE,
                          label: 'offered monthly lease rate/sqft',
                        },
                        {
                          type: Unit.ANNUAL_LEASE_RATE,
                          label: 'offered annual lease rate/sqft',
                        },
                      ]}
                      sqft={currentProperty?.sqft}
                      placeholder="Enter numbers only"
                      defaultValue={offeredLeaseRatePerSqft}
                      onChange={(value) => setOfferedLeaseRatePerSqft(value)}
                      max={1000000000}
                      upperCaseLabel
                    />

                    <SelectSearch
                      label="lease type"
                      options={taxonomies?.[TaxonomyTypeSlug.leaseType]?.map(
                        (taxonomy) => ({
                          label: taxonomy.title,
                          value: taxonomy.id,
                        }),
                      )}
                      value={leaseType && [leaseType]}
                      onChange={(options) => setLeaseType(options?.[0])}
                      creation={{
                        label: 'Create new lease type',
                        onClick: () => {
                          setCreatableTaxonomy({
                            title: 'Create lease type',
                            type: TaxonomyTypeSlug.leaseType,
                          });
                          setIsCreateTaxonomyPopupOpen(true);
                        },
                      }}
                    />
                  </div>
                  <div>
                    <InputNumber
                      units={[
                        {
                          type: Unit.MONTHLY_LEASE_RATE,
                          label: 'actual monthly lese rate/sqft',
                        },
                        {
                          type: Unit.ANNUAL_LEASE_RATE,
                          label: 'actual annual lease rate/sqft',
                        },
                      ]}
                      sqft={currentProperty?.sqft}
                      placeholder="Enter numbers only"
                      defaultValue={actualLeaseRatePerSqft}
                      onChange={(value) => setActualLeaseRatePerSqft(value)}
                      max={1000000000}
                      upperCaseLabel
                    />

                    <InputNumber
                      label="lease expense"
                      unit="$"
                      placeholder="Enter numbers only"
                      defaultValue={leaseExpense}
                      onChange={(leaseExpense) => setLeaseExpense(leaseExpense)}
                      max={10000000000}
                      upperCaseLabel
                    />
                  </div>
                </div>

                <PropertyLocationMap
                  className="google-map_lease"
                  propertyCoords={leaseLocation}
                  onMarkerPlaced={(coords: IPoint) => setLeaseLocation(coords)}
                  tip="Please select an already existing business on the map, or drop a pin"
                />

                <div className="tag-field">
                  <SelectMultiSearch
                    label="tag(s)"
                    asyncOptions={async (searchParams) => {
                      {
                        const token = await getAccessTokenUtil(dispatch);
                        if (token) {
                          return await loadTagsOptions(searchParams, token);
                        }
                        return [];
                      }
                    }}
                    selectedOptions={tags}
                    onChange={(options) => setTags(options)}
                    creation={{
                      label: 'Create new tag',
                      onClick: () => {
                        setIsTagCreationOpen(true);
                      },
                    }}
                  />
                </div>

                <div className="flex-container">
                  <SelectMultiSearch
                    label="floor"
                    selectedOptions={floors}
                    options={currentProperty.floors?.map((item) => ({
                      label: item.title,
                      value: item.id,
                    }))}
                    onChange={(options) => setFloors(options)}
                    creation={{
                      label: 'Create new floor',
                      onClick: () => setIsCreateFloorsPopupOpen(true),
                    }}
                  />

                  <SelectMultiSearch
                    label="suite"
                    selectedOptions={suites}
                    options={currentProperty?.suites?.map((item) => ({
                      label: item.title,
                      value: item.id,
                    }))}
                    onChange={(options) => setSuites(options)}
                    creation={{
                      label: 'Create new suite',
                      onClick: () => setIsCreateSuitePopupOpen(true),
                    }}
                  />
                </div>

                <div className="flex-container">
                  <div>
                    <SelectMultiSearch
                      label="Involved from landlord's side"
                      selectedOptions={involvedFromLandlordsSide}
                      asyncOptions={async (searchParams) => {
                        {
                          const token = await getAccessTokenUtil(dispatch);
                          if (token) {
                            return await loadEnterpriseInvolvementsOptions(
                              searchParams,
                              Number(landlord?.value),
                              token,
                            );
                          }
                          return [];
                        }
                      }}
                      searchByFields={[
                        'firstName',
                        'lastName',
                        'involvementType',
                        'enterprise',
                      ]}
                      onChange={(options) =>
                        setInvolvedFromLandlordsSide(options)
                      }
                      // isDisabled={!landlord?.value}
                      creation={{
                        label: 'Create new involvement',
                        onClick: () => setIsInvolvementModalOpen('landlords'),
                      }}
                    />

                    <Attachments
                      label="supplements"
                      buttonText="click to upload .pdf .doc and images"
                      fileTypes={['pdf', 'doc', 'docx', 'images']}
                      attachments={editableTransaction?.supplements}
                      onChange={(oldSupplements, newSupplements) => {
                        setOldSupplements(oldSupplements);
                        setNewSupplements(newSupplements);
                      }}
                    />
                  </div>

                  <div>
                    <SelectMultiSearch
                      label="Involved from tenant's side"
                      selectedOptions={involvedFromTenantsSide}
                      asyncOptions={async (searchParams) => {
                        {
                          const token = await getAccessTokenUtil(dispatch);
                          if (token) {
                            return await loadEnterpriseInvolvementsOptions(
                              searchParams,
                              Number(tenant?.value),
                              token,
                            );
                          }
                          return [];
                        }
                      }}
                      searchByFields={[
                        'firstName',
                        'lastName',
                        'involvementType',
                        'enterprise',
                      ]}
                      onChange={(options) =>
                        setInvolvedFromTenantsSide(options)
                      }
                      // isDisabled={!tenant?.value}
                      creation={{
                        label: 'Create new involvement',
                        onClick: () => setIsInvolvementModalOpen('tenants'),
                      }}
                    />

                    <Textarea
                      label="details"
                      placeholder="Type here"
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                    />
                  </div>
                </div>

                <footer className="modal-footer">
                  <Button variant="light" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="dark"
                    onClick={submitData}
                    disabled={
                      !property ||
                      !listingStatus.length ||
                      !tenant?.value ||
                      listingStatusError !== undefined ||
                      requestProcess
                    }>
                    Save
                  </Button>
                </footer>
              </form>
            </CustomScrollbars>
          </div>

          <div className="modal-overlay" onClick={onClose} />
        </div>
      </Modal>

      {isInvolvementModalOpen && (
        <InvolvementModal
          onClose={() => setIsInvolvementModalOpen(undefined)}
          onSubmit={(payload) => {
            dispatch(
              createInvolvementAction.request({
                ...payload,
                callback: (status: boolean, label: string, value: number) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`,
                      }),
                    );
                    const option: OptionType = {
                      label,
                      value,
                    };
                    isInvolvementModalOpen === 'landlords'
                      ? setInvolvedFromLandlordsSide([
                          ...(involvedFromLandlordsSide || []),
                          option,
                        ])
                      : setInvolvedFromTenantsSide([
                          ...(involvedFromTenantsSide || []),
                          option,
                        ]);
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (payload.callback) payload.callback(status);
                },
                additionalFields: {
                  person: payload.person,
                  enterprise: payload.enterprise,
                },
              }),
            );
            setIsInvolvementModalOpen(undefined);
          }}
        />
      )}

      {isEnterpriseModalOpen !== undefined && (
        <EnterpriseModal
          onClose={() => setIsEnterpriseModalOpen(undefined)}
          onSubmit={(payload) => {
            dispatch(
              createEnterpriseAction.request({
                ...payload,
                callback: (status: boolean, id: number) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`,
                      }),
                    );
                    isEnterpriseModalOpen === 'landlord'
                      ? setLandlord({
                          label: payload.title,
                          value: id,
                        })
                      : setTenant({
                          label: payload.title,
                          value: id,
                        });
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (payload.callback) payload.callback(status);
                },
              }),
            );
            setIsEnterpriseModalOpen(undefined);
          }}
        />
      )}

      {isCreateTaxonomyPopupOpen && (
        <PopupCreateTaxonomy
          title={creatableTaxonomy?.title}
          onClose={() => setIsCreateTaxonomyPopupOpen(false)}
          onSubmit={(title, callback) => {
            dispatch(
              createTaxonomyAction.request({
                title,
                type: creatableTaxonomy?.type as TaxonomyTypeSlug,
                callback: (res: false | TaxonomyCreateResponse) => {
                  if (res) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`,
                      }),
                    );
                    setLeaseType({
                      label: title,
                      value: res.id,
                    });
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (callback) callback(res);
                },
              }),
            );
            setIsCreateTaxonomyPopupOpen(false);
          }}
        />
      )}
      {isTagCreationOpen ? (
        <PopupTag
          setGroupCreation={setIsTagCategoryCreationOpen}
          onClose={() => {
            setIsTagCreationOpen(false);
          }}
          onCreate={async function (data) {
            const token = await getAccessTokenUtil(dispatch);
            let res: any;
            if (token) res = await TagApi.createTag(data, token);
            setTags([
              ...(tags || []),
              {
                label: `${res.title} - ${res.category.title}`,
                value: res.id,
              },
            ]);
            setIsTagCreationOpen(false);
          }}
        />
      ) : (
        ''
      )}
      {isTagCategoryCreationOpen ? (
        <PopupTagGroup
          onClose={() => {
            setIsTagCategoryCreationOpen(false);
          }}
          onCreate={async function (data) {
            const token = await getAccessTokenUtil(dispatch);
            if (token) await TagApi.createTagCategory(data, token);
            setIsTagCategoryCreationOpen(false);
          }}
        />
      ) : (
        ''
      )}
      {isCreateSuitePopupOpen && (
        <PopupCreateTaxonomy
          title="Create suite"
          type={TaxonomyOrEntityType.SUITE}
          existing={currentProperty?.suites}
          onClose={() => setIsCreateSuitePopupOpen(false)}
          onSubmit={(title, callback) => {
            dispatch(
              createPropertySuiteAction.request({
                property: property.id,
                title,
                callback: (status: boolean, id: number) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`,
                      }),
                    );
                    setSuites([
                      ...(suites || []),
                      {
                        label: title,
                        value: id,
                      },
                    ]);
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (callback) callback(status);
                },
              }),
            );
            setIsCreateSuitePopupOpen(false);
          }}
        />
      )}

      {isCreateFloorsPopupOpen && (
        <PopupCreateFloors
          propertyFloors={currentProperty?.floors}
          onClose={() => setIsCreateFloorsPopupOpen(false)}
          onSubmit={(floors, callback) => {
            dispatch(
              updatePropertyFloorListAction.request({
                property: property.id,
                floors,
                callback: (status: boolean) => {
                  if (status) {
                    // if create was success - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: `Successfully created`,
                        type: NotificationTypeEnum.success,
                        title: `Successfully created`,
                      }),
                    );
                  } else {
                    // if create was wrong - the additional popup appear
                    dispatch(
                      addNotification({
                        id: uuid(),
                        text: 'Something goes wrong',
                        type: NotificationTypeEnum.error,
                        title: 'Something goes wrong',
                      }),
                    );
                  }
                  if (callback) callback(status);
                },
              }),
            );
            setIsCreateFloorsPopupOpen(false);
          }}
        />
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  propertyState: store.property,
  enterpriseState: store.enterprise,
  taxonomyState: store.taxonomy,
}))(LeaseTransactionModal);
