import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const TimesIcon: FC<Props> = ({ color, ...props }) => (
   <svg
      {...props}
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M7.651 0.347782C7.84308 0.539857 7.84308 0.851272 7.651 1.04335L1.04318 7.65121C0.851102 7.84329 0.539689 7.84329 0.347615 7.65121C0.155541 7.45914 0.155542 7.14772 0.347615 6.95565L6.95544 0.347782C7.14752 0.155707 7.45893 0.155707 7.651 0.347782Z"
         fill={color}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M7.65271 7.65247C7.46063 7.84454 7.14922 7.84454 6.95715 7.65247L0.349321 1.0446C0.157247 0.85253 0.157247 0.541115 0.349321 0.34904C0.541394 0.156965 0.852808 0.156965 1.04488 0.34904L7.65271 6.95691C7.84478 7.14898 7.84478 7.46039 7.65271 7.65247Z"
         fill={color}
      />
   </svg>
)

TimesIcon.defaultProps = {
   color: 'white'
}