import {
  ActivityHistoryGetListRequest,
  ActivityHistoryGetListResponse,
} from '@ternala/voltore-types';

import { Config } from '../../../config/api';
import { authHeader, handleErrors } from '../../../utils/API';
import { appendSearchParams } from '../../../utils/appendSearchParams';
import {IError} from "../../model";

class API {
  public async getHistoryList(
    getHistoryListData: ActivityHistoryGetListRequest,
    token: string,
  ): Promise<ActivityHistoryGetListResponse | string | IError> {
    let url = new URL(
      Config.HISTORY_SERVICE_ENDPOINT + 'activity-history/list',
    );
    url = appendSearchParams(url, getHistoryListData);
    if (getHistoryListData.entityId)
      url.searchParams.append('entityId', `${getHistoryListData.entityId}`);
    if (getHistoryListData.entityName)
      url.searchParams.append('entityName', `${getHistoryListData.entityName}`);

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }
}

export const HistoryApi = new API();
