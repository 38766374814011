import React, { RefObject, useRef } from 'react';
import style from './SelectSearch.module.scss';
import { ItemsLoadType, OptionType } from 'models';
import { ITEMS_LOADER_HEIGHT } from "config";
import { Scrollbars } from 'react-custom-scrollbars';
import Loader from 'components/Loader';

interface IProps {
  onLoadMore?: (loadType: ItemsLoadType) => void;
  selectOption: (option: OptionType) => void;
  filteredOptions: OptionType[];
  loading: boolean;
  allItemsLoaded?: boolean;
  menuHeightMin?: number;
  menuHeight?: number;
  tags?: number[];
}

const Datalist = ({
  onLoadMore,
  selectOption,
  filteredOptions,
  allItemsLoaded,
  menuHeightMin,
  menuHeight,
  loading,
  tags,
}: IProps) => {
  const scrollbar = useRef() as RefObject<Scrollbars>;

  const handleScroll = (e: any) => {
    if (!onLoadMore || loading || allItemsLoaded) return;
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (clientHeight + scrollTop >= scrollHeight  - ITEMS_LOADER_HEIGHT)
      onLoadMore('more');
  };

  return (
    <Scrollbars
      autoHeight
      ref={scrollbar}
      onScroll={handleScroll}
      autoHeightMin={menuHeightMin}
      autoHeightMax={menuHeight}
      renderThumbVertical={(props) => (
        <div {...props} className={style.scrollthumby} />
      )}>
      <>
        {filteredOptions?.map((option) => {
          if (option.value && !tags?.includes(Number(option.value))) {
            return !option.value ? (
              <div className={style.no_options_message} key="empty">
                No results
              </div>
            ) : (
              <div
                className={style.option}
                key={String(option.value)}
                onClick={() => selectOption(option)}>
                {option.label}
              </div>
            );
          }
        })}

        {loading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              margin: '10px 0',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Loader />
          </div>
        ) : (
          ''
        )}
      </>
    </Scrollbars>
  );
};

export default Datalist;
