import {
  UserRoleCreateRequest,
  UserRoleCreateResponse,
  UserRoleDeleteRequest,
  UserRoleDeleteResponse,
  UserRoleGetListRequest,
  UserRoleGetListResponse,
  UserRoleGetRequest,
  UserRoleGetResponse, UserRoleUpdateRequest, UserRoleUpdateResponse,
} from '@ternala/voltore-types';
import { AsyncOptions } from 'components/UI/controls/SelectSearchCustom';

import { Config } from '../../../config/api';
import { authHeader, handleErrors } from '../../../utils/API';

import { appendSearchParams } from '../../../utils/appendSearchParams';
import {IError} from "../../model";

class API {
  public async getUserRoles(
    getUserRoleData: UserRoleGetListRequest,
    token: string,
  ): Promise<UserRoleGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'user-role/list');

    url = appendSearchParams(url, getUserRoleData);

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async createUserRole(
    createUserRoleData: UserRoleCreateRequest,
    token: string,
  ): Promise<UserRoleCreateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'user-role/create');

    return handleErrors(
      fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(createUserRoleData),
      }),
    );
  }

  public async getUserRole(
    getUserRoleData: UserRoleGetRequest,
    token: string,
  ): Promise<UserRoleGetResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'user-role/get');

    url.searchParams.append('id', String(getUserRoleData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async updateUserRole(
    updateUserRoleData: UserRoleUpdateRequest,
    token: string,
  ): Promise<UserRoleUpdateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'user-role/update');

    return handleErrors(
      fetch(url.toString(), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(updateUserRoleData),
      }),
    );
  }

  public async deleteUserRole(
    deleteUserRoleData: UserRoleDeleteRequest,
    token: string,
  ): Promise<UserRoleDeleteResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'user-role/delete');

    return handleErrors(
      fetch(url.toString(), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(deleteUserRoleData)
      }),
    );
  }

  public async getUserRoleWithoutConnectedUser(
    searchParams: AsyncOptions,
    token: string,
  ): Promise<UserRoleGetListResponse | null> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'user-role/list');
    url = appendSearchParams(url, searchParams);
    url.searchParams.append('withoutConnectedUser', 'true');

    try {
      const response = await fetch(String(url), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      });
      const data = await response.json();
      if (response.statusText === 'OK' || response.status === 200) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
export const UserRoleApi = new API();
