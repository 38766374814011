import React from 'react';

// Types
import {
  INotification,
  NotificationTypeEnum,
} from 'controllers/modals/models.d';
import { CloseIconModals } from 'components/icons/CloseIconModals';
import { removeNotification } from 'controllers/modals/actions';
import { useDispatch } from 'react-redux';

interface IProps {
  notification: INotification;
}

const Notification = ({ notification }: IProps) => {
  const dispatch = useDispatch();
  return (
    <>
      <div
        className="notification"
        style={{
          backgroundColor:
            notification.type === NotificationTypeEnum.success
              ? '#53A956'
              : '#CF4628',
        }}
        onClick={() => dispatch(removeNotification({ id: notification.id }))}
      >
        <span className="notification-title">{notification.title}</span>
        <div
          className="notification-cross-icon">
          <CloseIconModals />
        </div>
      </div>
      <div className="indicator-progress" />
    </>
  );
};

export default Notification;
