import React, { useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { push } from "connected-react-router";
import { connect, useDispatch, useSelector } from "react-redux";

import { IStore } from "controllers/store";
// Actions
import {
  ISetAuthenticatedStatus,
  loginByTokenAction,
  setAuthenticatedStatusAction,
  setAuthStateAction
} from "controllers/auth/actions";
import { getTaxonomiesAction } from "controllers/taxonomy/actions";

// Context
import { FullScreenContext } from "context/FullScreen";

// Routing schema
import RoutingSchema, { IRoute } from "routing";
import { RedirectToHome } from "./ProtectedRoute";
import { getSavedAccess } from "utils/manageAccess";
import { IAuthData } from "controllers/auth/models";
import { getAuthState, getAuthStatus } from "controllers/auth";

// Pages
import LoginPage from "pages/Login";
import FullScreenLoader from "components/UI/FullScreenLoader";
import Navigation from "components/Navigation";
import Windows from "components/Windows";
import { IFiltrationTag, IHistoryData } from "models";
import { IModalState } from "controllers/modals/models";
import { HistoryProvider } from "context/History";
import { TagsProvider, TagTypeEnum } from "context/Tags";
import { IModal } from "components/Windows/model";
import { PreviewProvider } from "context/Modal";
import { ShowElement } from "../components/ShowElement";

const generateRoutes = (routes: IRoute[]) => {
  return routes.map(({ component: Component, ...route }) => (
    <Route
      exact={route.isExact}
      key={route.name}
      path={route.path}
      render={(props) => {
        return (
          <Component
            key={route.name + Object.values(props.match.params).join(",")}
            {...props}>
            {route.childRoutes ? (
              <Switch>{generateRoutes(route.childRoutes)}</Switch>
            ) : (
              <></>
            )}
          </Component>
        );
      }}
    />
  ));
};

// Render all routes
const Routes = generateRoutes(RoutingSchema.getSchema);

interface IProps {
  modalsState: IModalState;
  location: any;
  setAuthenticatedStatusAction: (status: ISetAuthenticatedStatus) => void;
  push: (path: string) => void;
  loginByTokenAction: (data: IAuthData) => void;
}

const Routing: React.FC<IProps> = ({ location, modalsState, ...props }) => {
  const dispatch = useDispatch();

  const authState = useSelector(getAuthState);
  const authStatus = useSelector(getAuthStatus);
  const { isFullScreen } = useContext(FullScreenContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [entity, setEntity] = useState<IHistoryData>({
    id: undefined,
    type: undefined
  });
  const [tagsFilters, setTagsFilters] = useState<IFiltrationTag[] | undefined>(
    undefined
  );
  const [excludedTagsFilters, setExcludedTagsFilters] = useState<IFiltrationTag[] | undefined>(
    undefined
  );
  const [modal, setModal] = useState<IModal | undefined>(undefined);
  if ((window as any).FirebasePlugin) {
    (window as any).FirebasePlugin.onMessageReceived(
      (message: any) => {
        props.push(message.link);
      },
      (error: Error) => {
        console.error(error);
      }
    );
  }

  useEffect(() => {
    const authData = getSavedAccess();

    if (authData) {
      props.loginByTokenAction(authData);
      setAuthStateAction({ isLoading: true });
      setIsLoading(false);
    } else {
      props.push(RoutingSchema.getLink("login"));
      props.setAuthenticatedStatusAction({ status: false });
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (authStatus) {
      dispatch(getTaxonomiesAction.request({}));
    }
  }, [authStatus]);

  if (authState?.isLoading || isLoading) return <FullScreenLoader />;

  const addTag = (tag: IFiltrationTag, type: TagTypeEnum = TagTypeEnum.Tags) => {
    type === TagTypeEnum.Tags
      ? setTagsFilters([...(tagsFilters || []), tag])
      : setExcludedTagsFilters([...(excludedTagsFilters || []), tag]);
  };
  const addTags = (tags: IFiltrationTag[], type: TagTypeEnum = TagTypeEnum.Tags) => {
    type === TagTypeEnum.Tags
      ? setTagsFilters(tags)
      : setExcludedTagsFilters(tags);
  }
  const removeTag = (tag: IFiltrationTag, type: TagTypeEnum = TagTypeEnum.Tags) => {
    type === TagTypeEnum.Tags
      ? setTagsFilters(tagsFilters?.filter((item) => item.id !== tag.id))
      : setExcludedTagsFilters(excludedTagsFilters?.filter((item) => item.id !== tag.id));
  };
  const removeTags = (callback?: Function, type: TagTypeEnum = TagTypeEnum.Tags) => {
    type === TagTypeEnum.Tags
      ? setTagsFilters(undefined)
      : setExcludedTagsFilters(undefined);
    if (callback) callback();
  };
  if (authStatus) {
    return (
      <main className="main-layout">
        {!isFullScreen && <Navigation />}
        <div className="main" id={"main"}>
          <PreviewProvider
            value={{
              preview: modal,
              setPreview: (newModal) =>
                setModal(newModal ? { ...modal, ...newModal } : newModal)
            }}>
            <HistoryProvider
              value={{
                entity,
                setEntity: (...props) => {
                  setEntity(...props);
                }
              }}>
              <TagsProvider
                value={{
                  addTag,
                  addTags,
                  setTags: (tags, type: TagTypeEnum = TagTypeEnum.Tags) => {
                    type === TagTypeEnum.Tags
                      ? setTagsFilters(tags)
                      : setExcludedTagsFilters(tags);
                  },
                  removeTag,
                  removeTags,
                  tags: tagsFilters,
                  excludedTags: excludedTagsFilters
                }}>
                <Switch>
                  {Routes}
                  <RedirectToHome />
                </Switch>
                <Windows />
                <ShowElement additionalNotifications={modalsState.notifications} />
                {/*{modalsState.notifications && (*/}
                {/*  <Notifications notifications={modalsState.notifications} />*/}
                {/*)}*/}
              </TagsProvider>
            </HistoryProvider>
          </PreviewProvider>
        </div>
      </main>
    );
  }

  return <LoginPage />;
};

export default connect(
  (state: IStore) => ({
    location: state.router.location,
    modalsState: state.modals
  }),
  {
    setAuthenticatedStatusAction,
    push,
    loginByTokenAction
  }
)(Routing);
