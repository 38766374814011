import React, { FC, ComponentProps } from "react";

interface Props extends ComponentProps<"svg"> {
  color?: string;
}

export const PropertyRiverIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    {...props}
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.969 19.4062V10.7812H14.5945L15.1695 5.03125H12.9378C12.9378 2.65291 11.0036 0.71875 8.62528 0.71875C6.24693 0.71875 4.31277 2.65291 4.31277 5.03125H2.08105L3.51856 19.4062L3.5186 20.8438H17.969V19.4062ZM16.5315 19.4062H10.7815V12.2188H16.5315V19.4062ZM8.62528 2.15625C10.2108 2.15625 11.5003 3.44569 11.5003 5.03125C11.5003 6.61681 10.2108 7.90625 8.62528 7.90625C7.03971 7.90625 5.75027 6.61681 5.75027 5.03125C5.75027 3.44569 7.03971 2.15625 8.62528 2.15625ZM3.66949 6.46875H4.56434C5.15802 8.14128 6.75149 9.34375 8.62528 9.34375C10.4991 9.34375 12.0925 8.14128 12.6862 6.46875H13.5811L13.1498 10.7812H9.34403V19.4062H4.96324L3.66949 6.46875Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M43.8438 19.4062V10.7812H38.0938V2.15625H28.0312V19.4062V20.8438H43.8438V19.4062ZM42.4063 19.4062H35.2188V12.2188H42.4063V19.4062ZM29.4688 3.59375H36.6563V10.7812H33.7813V19.4062H29.4688V3.59375Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M9.94097 30L3 35.3985V37.1875H4.4375V44.375H17.375V37.1875H18.8125V35.3985L11.8715 30H9.94097ZM13.0625 42.9375H8.75V40.0625H13.0625V42.9375ZM15.9375 42.9375H14.5V38.625H7.3125V42.9375H5.875V37.1875H15.9375V42.9375ZM4.88959 35.75L10.434 31.4375H11.3778L16.9222 35.75H4.88959Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M12.2188 13.6562H15.0938V15.0938H12.2188V13.6562Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M12.2188 16.5312H15.0938V17.9688H12.2188V16.5312Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M6.46875 10.7812H7.90625V12.2188H6.46875V10.7812Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M6.46875 13.6562H7.90625V15.0938H6.46875V13.6562Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M6.46875 16.5312H7.90625V17.9688H6.46875V16.5312Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M40.25 40.9688H44.9219L41.1901 32.0117C40.9098 31.3404 40.2586 30.9062 39.5313 30.9062C38.8039 30.9062 38.1527 31.3404 37.8724 32.0117L36.8956 34.3562L34.7207 29.1367C34.4411 28.4654 33.7899 28.0312 33.0625 28.0312C32.3351 28.0312 31.6839 28.4654 31.4036 29.1367L27.6719 38.0938H32.3438V43.8438H28.0313V45.2812H45.2813V43.8438H40.25V40.9688ZM39.1992 32.5651C39.3113 32.297 39.7512 32.297 39.8633 32.5651L42.7656 39.5312H36.2969L36.8956 38.0938H38.4531L37.6747 36.2257L39.1992 32.5651ZM32.7304 29.6901C32.8426 29.422 33.2824 29.422 33.3946 29.6901L36.2969 36.6562H29.8281L32.7304 29.6901ZM33.7813 38.0938H35.3388L34.1406 40.9688H38.8125V43.8438H33.7813V38.0938Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M36.6562 13.6562H38.0938V15.0938H36.6562V13.6562Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M36.6562 16.5312H38.0938V17.9688H36.6562V16.5312Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M39.5312 13.6562H40.9688V15.0938H39.5312V13.6562Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M39.5312 16.5312H40.9688V17.9688H39.5312V16.5312Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M33.7812 5.03125H35.2188V6.46875H33.7812V5.03125Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M30.9062 5.03125H32.3438V6.46875H30.9062V5.03125Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M30.9062 7.90625H32.3438V9.34375H30.9062V7.90625Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M33.7812 7.90625H35.2188V9.34375H33.7812V7.90625Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M30.9062 16.5312H32.3438V17.9688H30.9062V16.5312Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M30.9062 13.6562H32.3438V15.0938H30.9062V13.6562Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      d="M30.9062 10.7812H32.3438V12.2188H30.9062V10.7812Z"
      fill={color}
      fillOpacity="0.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5 25.5C22.0523 25.5 22.5 25.9477 22.5 26.5V45C22.5 45.2761 22.7239 45.5 23 45.5C23.2761 45.5 23.5 45.2761 23.5 45V26.5C23.5 25.9477 23.9477 25.5 24.5 25.5H45C45.2761 25.5 45.5 25.2761 45.5 25C45.5 24.7239 45.2761 24.5 45 24.5H24.5C23.9477 24.5 23.5 24.0523 23.5 23.5V1.5C23.5 1.22386 23.2761 1 23 1C22.7239 1 22.5 1.22386 22.5 1.5V23.5C22.5 24.0523 22.0523 24.5 21.5 24.5H1.5C1.22386 24.5 1 24.7239 1 25C1 25.2761 1.22386 25.5 1.5 25.5H21.5Z"
      fill="#8D9799"
      fillOpacity="0.24"
    />
  </svg>
);

PropertyRiverIcon.defaultProps = {
  color: "#1F2531"
};
