import React, { RefObject, useState, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import { ITEMS_LOADER_HEIGHT } from 'config';

import { ScrollIcon } from '../icons/ScrollIcon';

interface IProps {
  onLoadMore: Function;
  children: React.ReactNode;
  isCanLoad: boolean;
}

const ScrollWrapper = (props: IProps) => {
  const { onLoadMore, isCanLoad, children } = props;
  // Use state
  const elementRef = useRef() as RefObject<any>;
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isShowScrollToTop, setIsShowScrollToTop] = useState<boolean>(false);

  const onScrollbarScroll = () => {
    // Load more items
    const { getClientHeight, getScrollHeight, getScrollTop, scrollToBottom } =
      elementRef.current as Scrollbars;
    if (getScrollTop()) {
      !isShowScrollToTop && setIsShowScrollToTop(true);
    } else {
      isShowScrollToTop && setIsShowScrollToTop(false);
    }
    if (
      isCanLoad &&
      !isLoadingMore &&
      getClientHeight() + getScrollTop() >=
        getScrollHeight() - ITEMS_LOADER_HEIGHT
    ) {
      setIsLoadingMore(true);
      scrollToBottom();
      onLoadMore('more', () => setIsLoadingMore(false));
    }
  };

  return (
    <>
      <Scrollbars
        autoHide
        renderView={(props) => (
          <div
            {...props}
            style={{
              position: 'absolute',
              inset: '0px',
              overflow: 'scroll',
              marginRight: '-5px',
              marginBottom: '-5px',
              paddingTop: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          />
        )}
        onScroll={onScrollbarScroll}
        ref={elementRef}>
        {children}
      </Scrollbars>
      <div
        className={'scroll-to-top-button' + (isShowScrollToTop ? ' show' : '')}
        onClick={() => elementRef.current?.scrollToTop()}>
        <ScrollIcon />
      </div>
    </>
  );
};

export default ScrollWrapper;
