import React from 'react';

export const RemindersIcon = () => (
  <svg
    width='15'
    height='15'
    viewBox='0 0 15 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.3736 10.5707C12.4286 9.77188 11.8868 8.60435 11.8868 7.36748V5.625C11.8868 3.42567 10.253 1.60503 8.13676 1.30005V0.624962C8.13676 0.27935 7.85672 0 7.51168 0C7.16676 0 6.88672 0.27935 6.88672 0.624962V1.30005C4.76979 1.60503 3.13672 3.42567 3.13672 5.625V7.36748C3.13672 8.60435 2.59484 9.77188 1.64418 10.5756C1.40111 10.7838 1.26172 11.0862 1.26172 11.4062C1.26172 12.0094 1.75233 12.5 2.35543 12.5H12.6679C13.2711 12.5 13.7618 12.0094 13.7618 11.4062C13.7618 11.0862 13.6224 10.7838 13.3736 10.5707Z'
      fill='white'
    />
    <path
      d='M7.5111 15C8.64304 15 9.58992 14.1931 9.80736 13.125H5.21484C5.4324 14.1931 6.37928 15 7.5111 15Z'
      fill='white'
    />
  </svg>
);
