import React, { FC } from 'react'
import Button from 'components/UI/controls/Button'

interface Props {
  onClick: (data: React.MouseEvent) => void;
}

export const ButtonCancelEditNote: FC<Props> = props => {
   return (
      <Button onClick={props.onClick} className="note-creator__button-cancel-edit-note">
        <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12.4323 0.565119C12.7444 0.877225 12.7444 1.38325 12.4323 1.69536L1.69511 12.4326C1.38301 12.7447 0.876985 12.7447 0.56488 12.4326C0.252775 12.1205 0.252776 11.6145 0.56488 11.3024L11.3021 0.565119C11.6142 0.253012 12.1202 0.253012 12.4323 0.565119Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M12.4349 12.4352C12.1228 12.7473 11.6168 12.7473 11.3047 12.4352L0.567461 1.69798C0.255357 1.38587 0.255357 0.879846 0.567462 0.567739C0.879566 0.255633 1.38559 0.255633 1.69769 0.567739L12.4349 11.305C12.747 11.6171 12.747 12.1231 12.4349 12.4352Z" />
        </svg>
      </Button>
   )
};

export default ButtonCancelEditNote;