const stylesWithoutBusinessLabels = [
   {
      featureType: "landscape.natural" as MapFeatureType,
      elementType: "geometry.fill" as MapElementType,
      stylers: [{ visibility: "on" }, { color: "#c2ccca" }]
   },
   {
      featureType: "poi" as MapFeatureType,
      elementType: "geometry.fill" as MapElementType,
      stylers: [{ visibility: "on" }, { color: "#a5c1bb" }]
   },
   {
      featureType: "poi" as MapFeatureType,
      elementType: "labels" as MapElementType,
      stylers: [{ visibility: "off" }]
   },
   {
      featureType: "poi.business" as MapFeatureType,
      elementType: "labels" as MapElementType,
      stylers: [{ visibility: "on" }]
   },
   {
      featureType: "road" as MapFeatureType,
      elementType: "geometry" as MapElementType,
      stylers: [{ lightness: 100 }, { visibility: "simplified" }]
   },
   {
      featureType: "road" as MapFeatureType,
      elementType: "labels" as MapElementType,
      stylers: [{ visibility: "on" }]
   },
   {
      featureType: "transit.line" as MapFeatureType,
      elementType: "geometry" as MapElementType,
      stylers: [{ visibility: "on" }, { lightness: 700 }]
   },
   {
      featureType: "water" as MapFeatureType,
      elementType: "all" as MapElementType,
      stylers: [{ color: "#637370" }]
   },
   {
      featureType: 'transit' as MapFeatureType,
      elementType: 'labels.icon' as MapElementType,
      stylers: [{visibility: 'on'}]
   }
]

export default stylesWithoutBusinessLabels