import { all } from 'redux-saga/effects';
import { ActionType, createReducer, getType } from 'typesafe-actions';
import { omit } from 'lodash';

// Actions
import * as actions from './actions';
import { widgetName } from './actions';

// Interfaces
import { IStore, LoaderActionType } from 'controllers/store';
import { ICardState } from './models';

//Sagas
import { cardActionSaga } from './sagas/card';
import { concatWithUnique } from 'utils/concatWithUnique';
import { generateLoaderActions } from '../based';
import { reminderActionSaga } from './sagas/reminder';
import {
  CardCreateResponse,
  CardUpdateResponse,
  ReminderDTO,
} from '@ternala/voltore-types';
import { addCards } from './utils';

export type CardActionType = ActionType<typeof actions>;

export const cardSaga = function* () {
  yield all([cardActionSaga(), reminderActionSaga()]);
};

/* Reducer */
const initialState: ICardState = {
  state: {
    errors: [],
    loaders: [],
  },
  remindersCount: 0,
  cardData: {},
  reminderData: {},
  storedSearchParams: null,
  storedSearchReminderParams: null,
};

type ActionTypes = CardActionType | LoaderActionType;

const loaderActions = generateLoaderActions<ICardState, ActionTypes>(
  widgetName,
);

export const loaderHandlers = loaderActions.handlers;

export const cardReducer = createReducer<ICardState, ActionTypes>(
  initialState,
  {
    ...loaderHandlers,
    [getType(actions.createCardAction.success)]: (
      state: ICardState,
      action,
    ): ICardState => {
      const payload = action.payload as CardCreateResponse;
      return addCards(payload.items, state);
    },
    [getType(actions.updateCardAction.success)]: (
      state: ICardState,
      action,
    ): ICardState => {
      const payload = action.payload as CardUpdateResponse & {
        updateCardId: number;
      };
      return addCards(payload.items, state);
    },
  },
)
  .handleAction(
    [actions.getCardsAction.success],
    (state: ICardState, { payload }): ICardState => {
      const { searchParams } = payload;
      const { type } = searchParams;

      const storedSearchParams =
        type === 'reminder'
          ? state.storedSearchReminderParams
          : state.storedSearchParams;

      let newCardList;
      let newReminderList;
      if (
        JSON.stringify(omit(storedSearchParams, ['limit', 'offset'])) ===
        JSON.stringify(omit(searchParams, ['limit', 'offset']))
      ) {
        if (type === "reminder") {
          newReminderList = concatWithUnique<number>(
            (type === 'reminder' ? state.reminders : state.cards) || [],
            payload.response.items.map((card) => {
              const foundMainReminder = card.reminders?.find(
                (reminder: ReminderDTO & { isMain?: boolean }) => reminder.isMain,
              );
              return Number(card.id + String(foundMainReminder?.id || '0'));
            })
          )
        } else {
          newCardList = concatWithUnique<number>(state.cards || [], payload.response.items.map(card => card.id))
        }
      } else {
        if (type === "reminder") {
          newReminderList = payload.response.items.map((card) => {
              const foundMainReminder = card.reminders?.find(
                (reminder: ReminderDTO & { isMain?: boolean }) => reminder.isMain,
              );
              return Number(card.id + String(foundMainReminder?.id || '0'));
            });
        } else {
          newCardList = payload.response.items.map(card => card.id);
        }
      }

      const data = Object.assign({}, type === 'reminder' ? state.reminderData : state.cardData);

      payload.response.items.forEach((card) => {
        const foundMainReminder = card.reminders?.find(
          (reminder: ReminderDTO & { isMain?: boolean }) => reminder.isMain,
        );
        data[type === 'reminder' ? card.id + String(foundMainReminder?.id || '0') : card.id] = {
          ...data[card.id],
          ...card,
        };
      });

      return {
        ...state,
        ...(type === 'reminder'
          ? {
              reminderData: data,
              isAll: payload.isAll,
              remindersCount: payload.response.counts,
              storedSearchReminderParams: searchParams,
              reminders: newReminderList,
            }
          : {
              cardData: data,
              isAll: payload.isAll,
              count: payload.response.counts,
              storedSearchParams: searchParams,
              cards: newCardList,
            }),
      };
    },
  )
  .handleAction(
    [actions.getCardAction.success],
    (state: ICardState, { payload }): ICardState => addCards([payload], state),
  )
  .handleAction(
    [actions.toggleReminderAction.success],
    (state: ICardState, { payload }): ICardState => {
      const updatedCard = state.cardData[payload.card?.id];
      const reminderData = Object.assign({}, state.reminderData);

      for(let reminderDataId in reminderData){
        if(reminderDataId.indexOf(String(payload.card.id)) !== 0) continue;
        if(reminderDataId === String(payload.card.id)) continue;
        const modifiedCard = reminderData[reminderDataId];
        modifiedCard.reminders = modifiedCard.reminders?.map((reminder) => {
          if (reminder.id === payload.id) {
            return {
              ...reminder,
              ...payload,
            };
          }
          return reminder;
        });
      }

      return {
        ...state,
        cardData: {
          ...state.cardData,
          [payload.card.id]: {
            ...updatedCard,
            reminders: updatedCard?.reminders?.map((reminder) => {
              if (reminder.id === payload.id) {
                return {
                  ...reminder,
                  ...payload,
                };
              }
              return reminder;
            }),
          },
        },
        reminderData,
      };
    },
  )
  .handleAction(
    [actions.deleteCardAction.success],
    (state: ICardState, { payload }): ICardState => {
      return {
        ...state,
        cardData: payload.id
          ? omit(state.cardData, payload.id)
          : state.cardData,
        reminderData: payload.id
          ? omit(state.reminderData, payload.id)
          : state.reminderData,

        cards: state.cards?.filter((card) => card !== payload.id),
        reminders: state.reminders?.filter((card) => card !== payload.id),
      };
    },
  );

/* Loader actions */
export const addLoader = loaderActions.actions.addLoader;
export const addError = loaderActions.actions.addError;
export const removeError = loaderActions.actions.removeError;
export const removeLoader = loaderActions.actions.removeLoader;

/* Selectors */
export const getCardsList = (state: IStore) => state.card.cards;
export const getCardsData = (state: IStore) => state.card.cardData;
export const getReminderData = (state: IStore) => state.card.reminderData;
export const getCardsCount = (state: IStore) => state.card.count;
