import React from 'react';

interface IProps {
  color?: string;
  className?: string;
}

const DownloadExportIcon: React.FC<IProps> = ({ color, className }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M18.289 18.2773H6.70384C6.3168 18.2773 6 18.5939 6 18.9812C6 19.3683 6.3168 19.6851 6.70384 19.6851H18.2962C18.6832 19.6851 19 19.3683 19 18.9812C19 18.5939 18.6832 18.2773 18.289 18.2773Z"
        fill={color}
      />
      <path
        d="M11.9821 15.7499C12.1159 15.8906 12.2989 15.9751 12.4958 15.9751C12.693 15.9751 12.876 15.8906 13.0096 15.7499L17.1342 11.3227C17.4016 11.0411 17.3805 10.5907 17.099 10.3303C16.8174 10.0629 16.367 10.0838 16.1066 10.3655L13.1997 13.4834V5.79759C13.1997 5.41032 12.8829 5.09375 12.4958 5.09375C12.1088 5.09375 11.792 5.41032 11.792 5.79759V13.4834L8.89213 10.3655C8.62468 10.084 8.18117 10.0629 7.89972 10.3303C7.61827 10.5978 7.59715 11.0413 7.86459 11.3227L11.9821 15.7499Z"
        fill={color}
      />
    </svg>
  );
};

DownloadExportIcon.defaultProps = {
  color: '#fff',
};

export default DownloadExportIcon;
