import React, { useContext, useState } from 'react';
import { CardDTO, CardFullDTO, ReminderDTO } from '@ternala/voltore-types';
import { activityHistoryTypeEnum } from '@ternala/voltore-types/lib/constants';
import Moment from 'react-moment';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { CardEntityConnectDTO } from '@ternala/voltore-types/lib/modules/card/cardEntityConnect/cardEntityConnect.dto';
import {
  enterpriseToAction,
  personToAction,
  propertyToAction,
} from '@ternala/voltore-types/lib/card';

//Custom components
import OptionsMenu from 'components/UI/controls/OptionsMenu';

//Actions
import { deleteCardAction } from 'controllers/card/actions';
import { CardFilterEnum } from 'controllers/card/models.d';

//Custom interfaces
import HistoryContext from 'context/History';
import RollerCloseIcon from 'components/icons/Notes/RollerCloseIcon';
import RollerOpenIcon from 'components/icons/Notes/RollerOpenIcon';

interface IPropsType {
  modal: CardFilterEnum;
  card: CardDTO | CardFullDTO | undefined;

  setEditedCard: (card: CardDTO | null) => void;
  editedCard: CardDTO | null;

  children: React.ReactNode;
}

const CardWrapper = ({
  modal,
  card,
  setEditedCard,
  editedCard,
  children,
}: IPropsType) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(true);

  // Context
  const history = useContext(HistoryContext);
  const cardTagConnect = card?.cardTagConnect;

  function returnEntity(card: CardEntityConnectDTO) {
    if (card?.['property']) return propertyToAction(card.property).entityTitle;
    if (card?.['person']) return personToAction(card.person).entityTitle;
    if (card?.['enterprise'])
      return enterpriseToAction(card.enterprise).entityTitle;
    return undefined;
  }

  const foundReminders = card?.reminders?.filter(
    (reminder: ReminderDTO & { isMain?: boolean }) => !reminder.isMain,
  );

  const isCanOpen =
    modal === CardFilterEnum.reminder && (card?.note || foundReminders?.length);

  return (
    <div
      data-id={card?.id}
      className={`card ${card?.event ? '' : 'template'} ${
        card?.id === editedCard?.id ? 'edited' : ''
      } ${isCanOpen && !isOpen ? '' : 'open'}`}
      style={{
        ...(isCanOpen && isHidden
          ? {
              overflow: 'hidden',
            }
          : {}),
      }}>
      {modal === CardFilterEnum.note || modal === CardFilterEnum.reminder ? (
        card?.note || card?.reminders || cardTagConnect ? (
          <>
            {isCanOpen ? (
              <div
                className={'roller-line'}
                onClick={() => {
                  setIsOpen(true);
                  setTimeout(() => setIsHidden(true), 500);
                }}>
                <div className="roller-icon">
                  <RollerOpenIcon />
                </div>
              </div>
            ) : (
              ''
            )}
            {isOpen && isCanOpen ? (
              <div
                className="roller-icon-hide"
                onClick={() => {
                  setIsOpen(false);
                  setIsHidden(true);
                }}>
                <RollerCloseIcon />
              </div>
            ) : (
              ''
            )}
            {children}
            <div className="control-panel__wrapper">
              <div className={`control-panel`}>
                <span className="time">
                  Created at{' '}
                  <span className="time-main">
                    {moment(card?.createdAt).format('L')},{' '}
                    <Moment format="LT">{card?.createdAt}</Moment>
                  </span>
                </span>
                {cardTagConnect ? (
                  <div className="tag-added">
                    <span className={'tag-added-title'}>Added to</span>
                    <span className="entity">
                      {returnEntity(cardTagConnect)}
                    </span>
                  </div>
                ) : (
                  ''
                )}
                <div className="control-panel__right">
                  <OptionsMenu
                    card
                    on={{
                      edit: () => setEditedCard(card || null),
                      delete: () =>
                        dispatch(
                          deleteCardAction.request({
                            id: Number(card?.id),
                            tag: card?.cardTagConnect,
                          }),
                        ),
                      history: () => {
                        history.setEntity({
                          id: Number(card?.id),
                          type: activityHistoryTypeEnum.card,
                        })
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )
      ) : (
        ''
      )}
    </div>
  );
};

export default CardWrapper;
