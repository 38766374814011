// @ts-ignore

import {
  ContentState, convertToRaw,
  DraftEntityMutability, DraftInlineStyleType,
  EditorState,
  Modifier,
  RawDraftContentBlock,
  RawDraftContentState, SelectionState
} from "draft-js";
import { UserRoleDTO } from '@ternala/voltore-types';
import moment from "moment";
import { roleDelimerSymbol, roleSymbol, startPhrase, timeSymbol } from "../config";
import { Pages } from "../../../../routing";

export const hours12to24 = (h:number, pm:boolean) => {
  return h === 12 ? pm ? 12 : 0 : pm ? h + 12 : h;
}

export const generateContent = ({
  role,
  date,
  description,
}: {
  role?: UserRoleDTO;
  date?: Date;
  description?: string;
}): RawDraftContentState => {
  let text = `${startPhrase} `;
  let roleLength = 0;
  let roleOffset = 0;
  let timeLength = 0;
  let timeOffset = 0;

  let content = {
    entityMap: {
      0: {
        type: 'ACTION',
        mutability: 'IMMUTABLE' as DraftEntityMutability,
        data: {
          title: text,
        },
      },
    },
    blocks: [
      {
        key: '9k5h7',
        text,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [{
          length: text.length,
          offset: 0,
          style: "CREATE_ENTITY" as DraftInlineStyleType
        }],
        entityRanges: [
          {
            offset: 0,
            length: text.length - 1,
            key: 0,
          },
        ],
        data: {},
      },
    ],
  }

  if(role){
    const roleText = `${role?.user?.person?.firstName} ${role?.user?.person?.lastName} | ${role.title}` + roleSymbol;
    roleLength = roleText.length;
    roleOffset = text.length + 1;
    text += ` ${roleText} at${roleDelimerSymbol} `
    // @ts-ignore
    content.entityMap[1] = {
      type: 'MENTION',
      mutability: 'IMMUTABLE' as DraftEntityMutability,
      data: {
        title: roleText,
        action: role,
        id: role.id
      },
    }
    content.blocks[0].entityRanges.push({
      offset: roleOffset,
      length: roleLength,
      key: 1
    })

    if(date){
      const timeText = `${moment(date).format('MM/DD/YYYY, h:mm A')}${timeSymbol} `;
      timeLength = timeText.length
      timeOffset = text.length;
      text += `${timeText} `

      // @ts-ignore
      content.entityMap[2] = {
        type: 'TIME',
        mutability: 'IMMUTABLE' as DraftEntityMutability,
        data: {
          title: roleText,
          action: role,
          id: role.id
        },
      }
      content.blocks[0].entityRanges.push({
        offset: timeOffset,
        length: timeLength,
        key: 2
      })
      if(description){
        text += `${description}`
      }
    }
  }
  content.blocks[0].text = text;

  return content;
};

export const insertUserRole = (
  {
    action,
    id,
    title,
  }: {
    action: Pages;
    id?: number;
    title: string;
  },
  editorState: EditorState,
) => {
  const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection();

  const currentBlockKey = editorState.getSelection().getStartKey();
  const carriagePosition = editorState.getSelection().getFocusOffset();

  const currentText = currentContent
    .getBlockForKey(currentBlockKey)
    .getText();

  const prevCarriageText = currentText.slice(0, carriagePosition);

  const targetSymbolPosition = prevCarriageText.indexOf(startPhrase);

  const newSelection = currentSelection.merge({
    anchorOffset: targetSymbolPosition + startPhrase.length,
    focusOffset: carriagePosition,
  });
  const resTitle = title + roleSymbol;

  const stateWithEntity = editorState
    .getCurrentContent()
    .createEntity('MENTION', 'IMMUTABLE', {
      action,
      title: resTitle,
      id,
    });

  const entityKey = stateWithEntity.getLastCreatedEntityKey();

  const stateWithText = Modifier.replaceText(
    stateWithEntity,
    newSelection,
    resTitle,
    undefined,
    entityKey,
  );

  const atPhrase = ` at${roleDelimerSymbol} `;

  const stateWithRoleEntity = stateWithText.createEntity(
    'ROLE_DELIMER',
    'IMMUTABLE',
    {},
  );

  const resContentStateWithAt = Modifier.insertText(
    stateWithText,
    stateWithText.getSelectionAfter(),
    atPhrase,
    undefined,
    stateWithRoleEntity.getLastCreatedEntityKey(),
  );

  const newEditorState = EditorState.push(
    editorState,
    resContentStateWithAt,
    'insert-fragment',
  );

  return EditorState.forceSelection(
    newEditorState,
    resContentStateWithAt.getSelectionAfter(),
  );
};

export const removeEntity = ({
                               block,
                               contentState,
                               entityMap,
                               selection,
                               entityType,
                             }: {
  block: RawDraftContentBlock;
  contentState: ContentState;
  selection: SelectionState;
  entityMap: RawDraftContentState['entityMap'];
  entityType: string;

}) => {
  const foundDelimer = Object.entries(entityMap).find(
    ([, entity]) => entity.type === entityType,
  );
  const purposeRange = block.entityRanges.find(
    (range) => String(range.key) === foundDelimer?.[0],
  );
  if (purposeRange) {
    const delimerSelection = selection.merge({
      anchorOffset: purposeRange.offset,
      focusOffset: purposeRange.offset + purposeRange.length,
    });
    contentState = Modifier.removeRange(
      contentState,
      delimerSelection,
      'forward',
    );
  }
  return contentState;
};

export const removeEntityHandler = (entityType: 'MENTION' | 'TIME', editorState: EditorState): EditorState | undefined => {
  let contentState = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();
  const carriagePosition = editorState.getSelection().getFocusOffset();

  const currentBlockIndex = contentState
    .getBlockMap()
    .keySeq()
    .findIndex((key) => key === currentSelection.getStartKey());
  const contentStateRaw = convertToRaw(contentState);

  const currentBlock = contentStateRaw.blocks[currentBlockIndex];

  const { entityMap } = contentStateRaw;

  const foundDelimer = Object.entries(entityMap).find(
    ([, entity]) => entity.type === entityType,
  );
  const purposeRange = currentBlock.entityRanges.find(
    (range) => String(range.key) === foundDelimer?.[0],
  );
  if (purposeRange) {
    const delimerSelection = currentSelection.merge({
      anchorOffset: purposeRange.offset,
      focusOffset: carriagePosition,
    });
    const newContentState = Modifier.removeRange(
      contentState,
      delimerSelection,
      'forward',
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'remove-range',
    );

    return EditorState.forceSelection(
      newEditorState,
      newContentState.getSelectionAfter(),
    )
  }
};
