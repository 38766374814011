export const API_KEY = process.env.REACT_APP_GEO_KEY;
export const API_KEY_WITH_DRAWING = `${API_KEY}&libraries=drawing,places&language=en&region=EG`;
// AIzaSyCYERMlvBbqa1T-X3qtoy0H8yAdGhns8pE
export const DEFAULT_LOCATION = {
   center: { lat: 47.6062095, lng: -122.3320708},
}

export const MAP_OPTIONS = {
   ...DEFAULT_LOCATION,
   mapTypeControlOptions: {
      mapTypeIds: ["roadmap", "satellite", "hybrid", "terrain", "styled_map_bl"],
   },
   zoom: 8,
   maxZoom: 18,
   disableDefaultUI: true
}

export const FIGURE_OPTIONS = {
   strokeWeight: 2,
   strokeColor: '#1B2F34',
   fillColor: '#1B2F34',
   fillOpacity: 0.05
}
