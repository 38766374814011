import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const NavigationPropertiesIcon: FC<Props> = ({ color, ...props }) => (
   <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M12.9955 2.38462C10.2767 2.38462 8.06787 4.59842 8.06787 7.33527C8.06787 8.12736 8.25252 8.87407 8.58021 9.53616L8.79269 9.96548L3.38462 15.7591V17.6154H6.05882V15.1074H7.93213V13.3632L9.70424 11.1389L10.221 11.4391C10.9828 11.8816 12.1338 12.2859 12.9955 12.2859C15.7142 12.2859 17.9231 10.0721 17.9231 7.33527C17.9231 4.59842 15.7142 2.38462 12.9955 2.38462ZM6.68326 7.33527C6.68326 3.83907 9.50667 1 12.9955 1C16.4843 1 19.3077 3.83907 19.3077 7.33527C19.3077 10.8315 16.4843 13.6705 12.9955 13.6705C12.0259 13.6705 10.9178 13.3252 10.0586 12.9162L9.31674 13.8473V16.492H7.44344V19H2V15.2133L7.14044 9.70639C6.84545 8.97327 6.68326 8.17257 6.68326 7.33527Z"
         fill={color}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M14.1152 7.23009C14.6887 7.23009 15.1537 6.76516 15.1537 6.19163C15.1537 5.6181 14.6887 5.15317 14.1152 5.15317C13.5417 5.15317 13.0768 5.6181 13.0768 6.19163C13.0768 6.76516 13.5417 7.23009 14.1152 7.23009ZM14.1152 8.61471C15.4534 8.61471 16.5383 7.52986 16.5383 6.19163C16.5383 4.8534 15.4534 3.76855 14.1152 3.76855C12.777 3.76855 11.6921 4.8534 11.6921 6.19163C11.6921 7.52986 12.777 8.61471 14.1152 8.61471Z"
         fill={color}
      />
   </svg>
)

NavigationPropertiesIcon.defaultProps = {
   color: "white"
}