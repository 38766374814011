import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const MarkerPinIcon: FC<Props> = ({ color, width = 13, height = 13, ...props }) => (
   <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M7.93937 15.9978C5.97146 15.9838 4.01741 15.8715 2.13265 15.2962C1.49516 15.0998 0.871524 14.8612 0.372617 14.3701C-0.112431 13.893 -0.12629 13.2756 0.3449 12.7985C0.996251 12.125 1.85548 11.8444 2.72857 11.5918C3.39378 11.3954 4.07284 11.2831 4.76577 11.1989C5.07066 11.1568 5.29239 11.3112 5.33397 11.5637C5.37554 11.8303 5.2231 12.0408 4.90436 12.0829C3.97583 12.2232 3.03345 12.3776 2.14651 12.7284C1.77233 12.8827 1.39815 13.0511 1.09326 13.3177C0.885383 13.5001 0.857666 13.6545 1.10712 13.8369C1.68918 14.2579 2.35439 14.4262 3.03345 14.5806C5.23696 15.0717 7.45432 15.1559 9.6994 15.0156C11.1407 14.9314 12.5681 14.763 13.9401 14.3C14.2727 14.1877 14.6053 14.0474 14.8964 13.8229C15.1181 13.6545 15.1181 13.5001 14.9102 13.3177C14.5222 12.9669 14.051 12.7845 13.5659 12.6161C12.8453 12.3636 12.0969 12.2092 11.3486 12.111C11.2931 12.111 11.2377 12.097 11.1823 12.0829C10.7942 12.0128 10.6418 11.8444 10.6834 11.5497C10.7249 11.255 10.9467 11.1288 11.3347 11.1849C12.5127 11.3252 13.6768 11.5778 14.7439 12.111C15.0765 12.2794 15.3814 12.4758 15.6447 12.7565C16.1298 13.2756 16.1159 13.865 15.617 14.3561C15.2428 14.7209 14.7716 14.9454 14.2727 15.1278C13.1779 15.5207 12.0554 15.7172 10.9051 15.8294C9.92114 15.9557 8.92333 16.0119 7.93937 15.9978Z"
         fill={color}
         fillOpacity="0.6"
      />
      <path
         d="M13.0395 4.36546C12.4435 0.80136 8.85418 -0.840373 5.88846 0.422498C2.50698 1.86778 2.03579 6.18961 3.65723 8.57504C5.01537 10.5676 6.40122 12.5461 7.77322 14.5386C7.89794 14.721 7.96724 14.7771 8.11968 14.5526C9.40852 12.6864 10.6696 10.8342 11.9724 8.99599C12.7207 7.9436 13.1503 6.79298 13.1226 5.48802C13.1226 5.10915 13.1087 4.74433 13.0395 4.36546ZM11.3071 8.35053C10.2262 9.86597 9.17293 11.4095 8.10582 12.953C8.05039 13.0232 7.99495 13.0933 7.92566 13.1775C6.71997 11.4375 5.48656 9.73968 4.3363 7.9857C3.08903 6.04929 3.54637 3.24291 5.25096 1.86778C7.80093 -0.180874 11.5427 1.20829 12.1387 4.43562C12.3881 5.83881 12.1664 7.15781 11.3071 8.35053Z"
         fill={color}
         fillOpacity="0.6"
      />
      <path
         d="M8.03657 3.11637C6.94175 3.10234 5.99937 4.01441 5.98551 5.09487C5.97165 6.21742 6.87245 7.15756 7.98114 7.15756C9.08982 7.17159 9.99062 6.27355 10.0045 5.16503C10.0183 4.05651 9.13139 3.1304 8.03657 3.11637ZM7.98114 6.24549C7.38522 6.23145 6.87246 5.71227 6.88631 5.12293C6.90017 4.51956 7.41294 4.01441 7.995 4.01441C8.60477 4.01441 9.10368 4.51956 9.08982 5.151C9.08982 5.7684 8.59091 6.25952 7.98114 6.24549Z"
         fill={color}
         fillOpacity="0.6"
      />
   </svg>
)

MarkerPinIcon.defaultProps = {
   color: "#1F2531",
   width: 16,
   height: 16
}