import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

/* components */
import DataList from 'components/page/DataList';

/* constants */
import { PageEnum } from 'config/constants';

/* types */
import { IStore } from 'controllers/store';
import { RouterState } from 'connected-react-router';
import { settingsList } from "./config";

interface Props {
  routerState: RouterState;
}

const SettingsPage: FC<Props> = (props) => {
  const {
    routerState: {
      location: { pathname },
    },
    children,
  } = props;

  const items = settingsList?.map((item) => ({
    image: '',
    id: item?.key,
    description: item?.title,
  }));

  return (
    <>
      <div className="setting-page">
        <Helmet>
          <title>
            Settings | Voltore
            {process.env.REACT_APP_COMPANY_NAME
              ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        <div className="page-layout__content">
          <DataList
            items={items}
            itemsCount={settingsList?.length}
            itemsLoading={false}
            pageTitle={PageEnum.SETTINGS}
            singleItem="setting"
            checkSuffixByActive={false}
          />
          <div className="setting-page__content">
            {pathname === `/${PageEnum.SETTINGS}` && (
              <div className="empty-container-wrapper">
                <div className="empty-container" />
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((store: IStore) => ({
  routerState: store.router,
}))(SettingsPage);
