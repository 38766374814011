import React, { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

/* UI components */
import Modal from 'components/Hocs/Portal';
import Input from 'components/UI/controls/Input';
import Button from 'components/UI/controls/Button';
import {
  UserRoleCreateRequest,
  UserRoleCreateResponse,
  UserRoleDTO,
  UserRoleUpdateRequest,
  UserRoleUpdateResponse,
} from '@ternala/voltore-types';

/* types */
import { IStore } from 'controllers/store';
import { IUserState } from 'controllers/user/models';
import {
  createUserRoleAction,
  updateUserRoleAction,
} from 'controllers/user/actions';
import { removeShowedElement } from '../../controllers/showElement/actions';
import { callbackTypeEnum, requestCallback } from 'utils';

export const userRoleModalKey = 'UserRoleModal';

export interface IUserRoleModalProps {
  showElementId: string;
  isByShowElement?: boolean;
  id?: number;
  completeAction?: (userRole: UserRoleDTO) => void;

  userState: IUserState;
}

const UserRoleModal: FC<IUserRoleModalProps> = (props) => {
  const { id, userState, showElementId, completeAction } = props;

  const editableUserRole = id ? userState.userRoleData[id] : undefined;

  const [requestProcess, setRequestProcess] = useState<boolean>(false);
  const [title, setTitle] = useState<string | undefined>(
    editableUserRole ? editableUserRole.title : undefined,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onCloseHandler();
  };

  const onCloseHandler = () => {
    dispatch(removeShowedElement(showElementId));
  };

  const submitData = () => {
    if (title) {
      setRequestProcess(true);
      if (editableUserRole) {
        const payload: UserRoleUpdateRequest = {
          id: editableUserRole.id,
          title,
        };
        dispatch(
          updateUserRoleAction.request({
            ...payload,
            callback: (res: false | UserRoleUpdateResponse) => {
              requestCallback(dispatch, !!res, callbackTypeEnum.update);
              if (res) completeAction?.(res);
              onCloseHandler();
              setRequestProcess(false);
            },
          }),
        );
      } else {
        const payload: UserRoleCreateRequest = {
          title,
        };
        dispatch(
          createUserRoleAction.request({
            ...payload,
            callback: (res: false | UserRoleCreateResponse) => {
              requestCallback(dispatch, Boolean(res), callbackTypeEnum.create);
              if (res) completeAction?.(res);
              onCloseHandler();
              setRequestProcess(false);
            },
          }),
        );
      }
    }
  };

  return (
    <Modal>
      <div className="modal modal_centered">
        <div className="scroll-area">
          <form className="modal-content" onSubmit={submitData}>
            <div
              className="modal__close"
              onClick={onCloseHandler}
              title="Close"
            />

            <div className="modal-title">
              {id ? 'edit' : 'create'} user role
            </div>
            <div className="flex-container">
              <Input
                label="title"
                placeholder="Enter here"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>

            <footer className="modal-footer">
              <Button variant="light" onClick={onCloseHandler}>
                Cancel
              </Button>
              <Button
                variant="dark"
                onClick={submitData}
                disabled={!title || requestProcess}>
                Save
              </Button>
            </footer>
          </form>
        </div>

        <div className="modal-overlay" onClick={onCloseHandler} />
      </div>
    </Modal>
  );
};

export default connect((store: IStore) => ({
  userState: store.user,
}))(UserRoleModal);
