import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const ReminderClockIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.13782 7.05993L6.46469 5.80509V3.24895C6.46469 2.99193 6.25694 2.78418 5.99992 2.78418C5.74291 2.78418 5.53516 2.99193 5.53516 3.24895V6.03749C5.53516 6.18388 5.60395 6.32193 5.72106 6.4093L7.58008 7.80358C7.66373 7.86632 7.76135 7.89653 7.85847 7.89653C8.00022 7.89653 8.13965 7.83285 8.23075 7.71016C8.38509 7.50517 8.34325 7.21377 8.13782 7.05993Z"
      fill={color}
    />
    <path
      d="M6 0C2.6914 0 0 2.6914 0 6C0 9.3086 2.6914 12 6 12C9.3086 12 12 9.3086 12 6C12 2.6914 9.3086 0 6 0ZM6 11.0705C3.20449 11.0705 0.929508 8.79551 0.929508 6C0.929508 3.20449 3.20449 0.929508 6 0.929508C8.79598 0.929508 11.0705 3.20449 11.0705 6C11.0705 8.79551 8.79551 11.0705 6 11.0705Z"
      fill={color}
    />
  </svg>
);

ReminderClockIcon.defaultProps = {
  color: 'black',
};
