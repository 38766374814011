
import React, {
  FC,
  useState,
  useEffect,
  ChangeEvent,
  MouseEvent,
  FormEvent,
} from 'react';
import { FormGroup, FormControl, Validators } from 'lib/validation';

import Modal from 'components/Hocs/Portal';
import Input from 'components/UI/controls/Input';
import Button from 'components/UI/controls/Button';

import { errorMessages } from 'config';

const createForm = () => {
  return new FormGroup({
    password: new FormControl(
      {
        type: 'password',
        label: 'Password',
        placeholder: 'At least 8 characters required',
        required: true,
        //   value: '',
      },
      [Validators.required, Validators.minLength(8), Validators.maxLength(50)],
      errorMessages,
    ),
    passwordConfirm: new FormControl(
      {
        type: 'password',
        label: 'Confirm password',
        placeholder: 'At least 8 characters required',
        required: true,
      },
      [Validators.required, Validators.minLength(8), Validators.maxLength(50)],
      errorMessages,
    ),
  });
};

interface Props {
  onClose: () => void;
  onSubmit: (password: string) => void;
}

const ChangePassword: FC<Props> = ({ onClose, onSubmit }) => {
  const [form, setForm] = useState<FormGroup>(createForm());

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  const submitPassword = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    form.validateConfirmation('password', 'passwordConfirm');
    form.validate();
    setForm({ ...form });

    if (
      form.controls.password.value &&
      form.controls.passwordConfirm.value &&
      form.controls.password.value === form.controls.passwordConfirm.value &&
      form.valid
    ) {
      onSubmit(form.controls.password.value);
      onClose();
    }
  };

  return (
    <Modal>
      <div className="modal popup popup-password-change">
        <div className="scroll-area">
          <div className="modal-content">
            <div className="modal__close" onClick={onClose} title="Close" />

            <div className="modal-title">Change Password</div>

            {Object.keys(form.controls).map((controlName: string) => {
              const control = form.controls[controlName];
              return (
                <Input
                  key={controlName}
                  name="new-password"
                  type={control.type}
                  label={control.label}
                  placeholder={control.placeholder}
                  value={control.value}
                  autocomplete="new-password"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    control.update(event.target.value);
                    setForm({ ...form });
                  }}
                  errorMessages={control.errorMessages}
                  required={control.required}
                />
              );
            })}

            <footer className="modal-footer">
              <Button variant="light" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="dark" onClick={submitPassword}>
                Save
              </Button>
            </footer>
          </div>
        </div>

        <div className="modal-overlay" onClick={onClose} />
      </div>
    </Modal>
  );
};

export default ChangePassword;
