import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const DropdownIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    {...props}
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.01914 1.06238C1.26062 0.796749 1.67171 0.777173 1.93734 1.01865L7.0001 5.62116L12.0629 1.01865C12.3285 0.777173 12.7396 0.796749 12.9811 1.06238C13.2225 1.328 13.203 1.7391 12.9373 1.98057L7.0001 7.37806L1.06286 1.98057C0.797237 1.7391 0.777662 1.328 1.01914 1.06238Z"
      fill={color}
    />
  </svg>
);

DropdownIcon.defaultProps = {
  color: '#1F2531',
};
