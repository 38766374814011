import { appName } from 'config';
import { createAsyncAction } from 'typesafe-actions';

import {
  ActivityHistoryGetListRequest,
  ActivityHistoryGetListResponse,
} from '@ternala/voltore-types';

import { IException } from '../store';

/* Actions */
export const widgetName = 'history';

// ** Action
export const getHistoryAction = createAsyncAction(
  `${appName}/${widgetName}/GET_HISTORY_REQUEST`,
  `${appName}/${widgetName}/GET_HISTORY_SUCCESS`,
  `${appName}/${widgetName}/GET_HISTORY_FILED`,
)<
  ActivityHistoryGetListRequest & { callback?: Function },
  ActivityHistoryGetListResponse,
  IException
>();
