import React, { FC, useState, useEffect } from 'react';
import Moment from 'react-moment';

/* components */
import Button from 'components/UI/controls/Button';
import { ClockIcon } from 'components/icons/ClockIcon';

/* constants & utils */
import { DATE_FORMAT } from 'config';
import {
  SaleTransactionStatusEnum,
  LeaseTransactionStatusEnum,
} from '@ternala/voltore-types/lib/constants';
import { ListingStatusEnum } from 'config/constants/select-options';
import { mapEnum, capitalize } from 'utils/helper-functions';

/* styles */
import { Color } from 'components/UI/controls/shared/styles';
import style from './QuickChangeListingStatus.module.scss';

/* types */
import {
  SaleTransactionStatusHistoryDTO,
  LeaseTransactionStatusHistoryDTO,
} from '@ternala/voltore-types';
import moment from 'moment';

interface Props {
  value?: SaleTransactionStatusEnum | LeaseTransactionStatusEnum;
  onChange: (
    value: SaleTransactionStatusEnum | LeaseTransactionStatusEnum,
  ) => void;
  statusHistory?: (
    | SaleTransactionStatusHistoryDTO
    | LeaseTransactionStatusHistoryDTO
  )[];
  statusType: 'sale' | 'lease';
  statusDate?: Date;
}

export const QuickChangeListingStatus: FC<Props> = ({
  value,
  statusHistory,
  statusType,
  statusDate,
  ...props
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, []);

  let options: string[] = [];

  if (statusType === 'sale') {
    options = mapEnum(
      SaleTransactionStatusEnum,
      (item: SaleTransactionStatusEnum) => item,
    );
  }
  if (statusType === 'lease') {
    options = mapEnum(
      LeaseTransactionStatusEnum,
      (item: LeaseTransactionStatusEnum) => item,
    );
  }

  const onChange = async (newValue: string) => {
    setIsMenuOpen(false);
    props.onChange(
      newValue as SaleTransactionStatusEnum | LeaseTransactionStatusEnum,
    );
  };

  const onClickOutside = (event: MouseEvent) => {
    if (event.target instanceof HTMLElement) {
      if (!event.target.className.includes('QuickChangeListingStatus')) {
        setIsMenuOpen(false);
        setIsHistoryOpen(false);
      }
    }
  };

  return (
    <div className={style.container}>
      {statusDate ? moment(statusDate).format('L') : ''}&nbsp;&nbsp;
      <div
        className={style.control_wrapper}
        style={{
          backgroundColor: ListingStatusColorMap[capitalize(String(value))],
        }}
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
          setIsHistoryOpen(false);
        }}>
        <div className={style.value}>{value}</div>

        <div className={style.dropdown_indicator} />
      </div>
      {isMenuOpen && (
        <div className={style.menu}>
          {options
            .filter((option) => option !== value)
            .map((option) => (
              <div
                className={style.option}
                onClick={() => onChange(option)}
                key={option}>
                {option}
              </div>
            ))}
        </div>
      )}
      <div className={style.status_history_container}>
        <Button
          className={`${style.toggle_status_history} ${
            isHistoryOpen ? style.toggle_status_history_active : ''
          }`}
          variant="toggle-light"
          onClick={() => {
            setIsHistoryOpen(!isHistoryOpen);
            setIsMenuOpen(false);
          }}>
          <ClockIcon />
        </Button>

        {statusHistory?.length && isHistoryOpen && (
          <div className={style.status_history}>
            {[...statusHistory]
              .sort(
                (a, b) =>
                  Number(new Date(a.startDate)) - Number(new Date(b.startDate)),
              )
              .map((item) => (
                <div className={style.status_history_item} key={item.status}>
                  <div className={style.status_history_row}>
                    <div
                      className={style.item_status}
                      style={{
                        color: ListingStatusColorMap[capitalize(item.status)],
                      }}>
                      {item.status}
                    </div>
                    <div className={style.item_date}>
                      <Moment format={DATE_FORMAT}>{item.startDate}</Moment>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

const ListingStatusColorMap: { [key: string]: Color } = {
  [ListingStatusEnum.ACTIVE]: Color.listingStatusActive,
  [ListingStatusEnum.PENDING]: Color.listingStatusPending,
  [ListingStatusEnum.SOLD]: Color.listingStatusSold,
  [ListingStatusEnum.LEASED]: Color.listingStatusLeased,
  [ListingStatusEnum.CANCELLED]: Color.listingStatusCanceled,
  [ListingStatusEnum.EXPIRED]: Color.listingStatusExpired,
};

export default QuickChangeListingStatus;
