import { all } from 'redux-saga/effects';
import { createReducer, ActionType } from 'typesafe-actions';

// Actions
import * as actions from './actions';

// Interfaces
import { IStore } from 'controllers/store';
import { ITaxonomyState } from './models';

//Sagas
import { taxonomyActionSaga } from './sagas/taxonomy';
import { TaxonomyTypeSlug } from '@ternala/voltore-types/lib/constants';

export type TaxonomyActionType = ActionType<typeof actions>;

export const taxonomySaga = function* () {
  yield all([taxonomyActionSaga()]);
};

/* Reducer */
const initialState: ITaxonomyState = {
  state: null,
};

export const taxonomyReducer = createReducer<
  ITaxonomyState,
  TaxonomyActionType
>(initialState)
  .handleAction(
    [actions.getTaxonomiesAction.success],
    (state: ITaxonomyState, { payload }): ITaxonomyState => {
      return { ...state, taxonomies: payload };
    },
  )
  .handleAction(
    [actions.getTaxonomiesByTypeAction.success],
    (state: ITaxonomyState, { payload }): ITaxonomyState => {
      return {
        ...state,
        taxonomies: {
          ...state.taxonomies,
          [payload.slug]: payload.items,
        },
      };
    },
  )
  .handleAction(
    [actions.getTaxonomyTypeListAction.success],
    (state: ITaxonomyState, { payload }): ITaxonomyState => {
      return {
        ...state,
        taxonomyTypes: payload.items,
      };
    },
  )
  .handleAction(
    [actions.createTaxonomyAction.success],
    (state: ITaxonomyState, { payload }): ITaxonomyState => {
      const taxonomies = Object.assign({}, state.taxonomies);
      if (Array.isArray(taxonomies[payload.type.slug as TaxonomyTypeSlug])) {
        taxonomies[payload.type.slug as TaxonomyTypeSlug]?.push(payload);
      } else {
        taxonomies[payload.type.slug as TaxonomyTypeSlug] = [payload];
      }
      return { ...state, taxonomies };
    },
  )
  .handleAction(
    [actions.updateTaxonomyAction.success, actions.getTaxonomyAction.success],
    (state: ITaxonomyState, { payload }): ITaxonomyState => {
      const taxonomies = Object.assign({}, state.taxonomies);
      let taxonomiesType;
      if (Array.isArray(taxonomies[payload.type.slug as TaxonomyTypeSlug])) {
        let isHave = false;
        taxonomiesType = taxonomies[payload.type.slug as TaxonomyTypeSlug]?.map((taxonomy) => {
          if (taxonomy.id === payload.id) {
            isHave = true
            return {
              ...taxonomy,
              ...payload,
            };
          }
          return taxonomy;
        });
        if(!isHave){
          taxonomiesType = [ ...(taxonomiesType || []), payload]
        }
      } else {
        taxonomiesType = [payload];
      }
      return {
        ...state,
        taxonomies: {
          ...taxonomies,
          [payload.type.slug]: taxonomiesType,
        },
      };
    },
  )
  .handleAction(
    [actions.deleteTaxonomyAction.success],
    (state: ITaxonomyState, { payload }): ITaxonomyState => {
      const taxonomies = Object.assign({}, state.taxonomies);
      for (let key in taxonomies) {
        // @ts-ignore
        taxonomies[key] = taxonomies[key].filter((taxonomy) => taxonomy.id !== payload.id);
      }
      return {
        ...state,
        taxonomies,
      };
    },
  );

/* Selectors */
export const getTaxonomiesList = (state: IStore) => state.taxonomy.taxonomies;
