import {
  sortType,
  propertySortFields,
  personSortFields,
  saleTransactionSortFields,
  leaseTransactionSortFields,
  enterpriseSortFields,
  involvementSortFields,
  userSortFields,
  tagSortFields,
  cardSortFields,
  taxonomyTypeSortFields,
} from '@ternala/voltore-types/lib/constants';
import { OptionType } from 'models';
import { mapEnum } from 'utils/helper-functions';

export type sortFieldType =
  | propertySortFields
  | personSortFields
  | saleTransactionSortFields
  | leaseTransactionSortFields
  | enterpriseSortFields
  | involvementSortFields
  | userSortFields
  | tagSortFields
  | cardSortFields
  | taxonomyTypeSortFields;

export const Sorting: {
  [key: string]: {
    sortField: sortFieldType;
    sortType: sortType;
  };
} = {};

const sortFields = [
  ...mapEnum<propertySortFields>(
    propertySortFields,
    (item: propertySortFields) => item,
  ),
  ...mapEnum<personSortFields>(
    personSortFields,
    (item: personSortFields) => item,
  ),
  ...mapEnum<saleTransactionSortFields>(
    saleTransactionSortFields,
    (item: saleTransactionSortFields) => item,
  ),
  ...mapEnum<leaseTransactionSortFields>(
    leaseTransactionSortFields,
    (item: leaseTransactionSortFields) => item,
  ),
  ...mapEnum<enterpriseSortFields>(
    enterpriseSortFields,
    (item: enterpriseSortFields) => item,
  ),
  ...mapEnum<involvementSortFields>(
    involvementSortFields,
    (item: involvementSortFields) => item,
  ),
  ...mapEnum<userSortFields>(userSortFields, (item: userSortFields) => item),
];

sortFields.forEach((item) => {
  Sorting[item + '_' + sortType.asc] = {
    sortField: item,
    sortType: sortType.asc,
  };
  Sorting[item + '_' + sortType.desc] = {
    sortField: item,
    sortType: sortType.desc,
  };
});

export const usersSortOptions: OptionType[] = [
  {
    label: 'by last name (a to z)',
    value: userSortFields.title + '_' + sortType.asc,
  },
  {
    label: 'by last name (z to a)',
    value: userSortFields.title + '_' + sortType.desc,
  },
];

export const personsSortOptions: OptionType[] = [
  {
    label: 'by last name (a to z)',
    value: personSortFields.lastName + '_' + sortType.asc,
  },
  {
    label: 'by last name (z to a)',
    value: personSortFields.lastName + '_' + sortType.desc,
  },
];

export const enterprisesSortOptions: OptionType[] = [
  {
    label: 'by name (a to z)',
    value: enterpriseSortFields.title + '_' + sortType.asc,
  },
  {
    label: 'by name (z to a)',
    value: enterpriseSortFields.title + '_' + sortType.desc,
  },
];

export const tagsSortOptions: OptionType[] = [
  {
    label: 'by title (a to z)',
    value: tagSortFields.title + '_' + sortType.asc,
  },
  {
    label: 'by title (z to a)',
    value: tagSortFields.title + '_' + sortType.desc,
  },
];

export const taxonomyTypeSortOptions: OptionType[] = [
  {
    label: 'by title (a to z)',
    value: taxonomyTypeSortFields.title + '_' + sortType.asc,
  },
  {
    label: 'by title (z to a)',
    value: taxonomyTypeSortFields.title + '_' + sortType.desc,
  },
];

export const taxonomySortOptions: OptionType[] = [
  {
    label: 'by title (a to z)',
    value: enterpriseSortFields.title + '_' + sortType.asc,
  },
  {
    label: 'by title (z to a)',
    value: enterpriseSortFields.title + '_' + sortType.desc,
  },
];

export const propertiesSortOptions: OptionType[] = [
  {
    label: 'date added (new to old)',
    value: propertySortFields.createdAt + '_' + sortType.asc,
  },
  {
    label: 'date added (old to new)',
    value: propertySortFields.createdAt + '_' + sortType.desc,
  },
];

export const cardsSortOptions: OptionType[] = [
  {
    label: 'Past to Future',
    value: cardSortFields.createdAt + '_' + sortType.asc,
  },
  {
    label: 'Future to Past',
    value: cardSortFields.createdAt + '_' + sortType.desc,
  },
];

export const deletedOptions: OptionType[] = [
  {
    label: "Active",
    value: ""
  },
  {
    label: "Deleted",
    value: true
  }
]