import React, { FC, useState, useRef, RefObject } from 'react';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';

import LeaseTransactionItem from '../LeaseTransactionItem';
import Loader from 'components/Loader';

/* constants */
import { ITEMS_LOADER_HEIGHT } from 'config';

/* types */
import { IStore } from 'controllers/store';
import {
  IPropertyState,
  PropertyFullExpandDTO,
} from 'controllers/property/models';

interface Props {
  currentProperty: PropertyFullExpandDTO;
  loadTransactions: (data: {
    callback?: Function;
    limit?: number;
    offset?: number;
  }) => void;
  propertyState: IPropertyState;
  propertyId: number;
  tabName: string;
}

const LeaseTransactionsListView: FC<Props> = (props) => {
  const {
    tabName,
    propertyId,
    currentProperty,
    propertyState: { leaseTransactionData },
    loadTransactions,
  } = props;

  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const transactionsRef = useRef() as RefObject<Scrollbars>;

  const transactionIds = currentProperty?.leaseTransactionState?.items;
  const newTransactionIds = currentProperty?.leaseTransactionState?.newItems;
  const transactions =
    transactionIds &&
    Object.values(transactionIds.map((id) => leaseTransactionData[id]));
  const newTransactionsLease =
    newTransactionIds &&
    Object.values(newTransactionIds.map((id) => leaseTransactionData[id]))?.filter(
        (transaction) =>
          transaction.activeStatus?.status !== 'Leased' &&
          tabName === 'leases',
      );

  const newTransactionsTenant = newTransactionIds &&
    Object.values(newTransactionIds.map((id) => leaseTransactionData[id]))?.filter(
      (transaction) =>
        transaction.activeStatus?.status === 'Leased' &&
        tabName === 'tenants'
    )
  const loadMoreTransactions = () => {
    const { getClientHeight, getScrollHeight, getScrollTop, scrollToBottom } =
      transactionsRef.current as any;

    if (
      !currentProperty?.leaseTransactionState?.isAll &&
      !loadingMore &&
      getClientHeight() + getScrollTop() >=
        getScrollHeight() - ITEMS_LOADER_HEIGHT
    ) {
      scrollToBottom();
      setLoadingMore(true);
      loadTransactions({ callback: () => setLoadingMore(false) });
    }
  };

  return (
    <Scrollbars
      autoHide
      ref={transactionsRef}
      renderThumbVertical={(props) => (
        <div {...props} className="page-scrollbar-y" />
      )}
      onScroll={loadMoreTransactions}
      className="transaction-items-container">
      <div className="transaction-items items-list">
        {newTransactionsTenant?.length ? (
          <div className="new-items">
            {newTransactionsTenant?.map(
              (transaction) => (
                  <LeaseTransactionItem
                    key={transaction.id}
                    id={propertyId}
                    tabName={tabName}
                    transactionId={transaction.id}
                    data={{
                      tenant: transaction.tenant?.title,
                      sqft: transaction.leasableSQFT,
                      price: transaction.actualRate || transaction.offeredRate,
                      statuses: transaction.historyStatuses,
                      activeStatus: transaction.activeStatus,
                      commencement: transaction.leaseCommencement,
                      expiration: transaction.leaseExpiration,
                    }}
                  />
                ),
            )}
            <div className="new-items-dash">
              <span>New</span>
            </div>
          </div>
        ) : (
          ''
        )}

        {transactions?.map(
          (transaction) =>
            transaction.activeStatus?.status === 'Leased' &&
            tabName === 'tenants' && (
              <LeaseTransactionItem
                key={transaction.id}
                id={propertyId}
                tabName={tabName}
                transactionId={transaction.id}
                data={{
                  tenant: transaction.tenant?.title,
                  sqft: transaction.leasableSQFT,
                  price: transaction.actualRate || transaction.offeredRate,
                  statuses: transaction.historyStatuses,
                  activeStatus: transaction.activeStatus,
                  commencement: transaction.leaseCommencement,
                  expiration: transaction.leaseExpiration,
                }}
              />
            ),
        )}

        {newTransactionsLease?.length ? (
          <div className="new-items">
            {newTransactionsLease.map((transaction) => (
                <LeaseTransactionItem
                  key={transaction.id}
                  id={propertyId}
                  tabName={tabName}
                  transactionId={transaction.id}
                  data={{
                    tenant: transaction.tenant?.title,
                    sqft: transaction.leasableSQFT,
                    price: transaction.actualRate || transaction.offeredRate,
                    statuses: transaction.historyStatuses,
                    activeStatus: transaction.activeStatus,
                    commencement: transaction.leaseCommencement,
                    expiration: transaction.leaseExpiration,
                  }}
                />
              ))}
            <div className="new-items-dash">
              <span>New</span>
            </div>
          </div>
        ) : (
          ''
        )}

        {transactions
          ?.filter(
            (transaction) =>
              transaction.activeStatus?.status !== 'Leased' &&
              tabName === 'leases',
          )
          .map((transaction) => (
            <LeaseTransactionItem
              key={transaction.id}
              id={propertyId}
              tabName={tabName}
              transactionId={transaction.id}
              data={{
                tenant: transaction.tenant?.title,
                sqft: transaction.leasableSQFT,
                price: transaction.actualRate || transaction.offeredRate,
                statuses: transaction.historyStatuses,
                activeStatus: transaction.activeStatus,
                commencement: transaction.leaseCommencement,
                expiration: transaction.leaseExpiration,
              }}
            />
          ))}

        {loadingMore ? (
          <div className="items-list__loader">
            <Loader />
          </div>
        ) : (
          ''
        )}
      </div>
    </Scrollbars>
  );
};

export default connect((store: IStore) => ({
  propertyState: store.property,
}))(LeaseTransactionsListView);
