import React, { FC, ReactNode, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

/* components */
import PersonalContacts from './PersonalContacts';
import PersonModal from 'components/modals/PersonModal';
import PopupDelete from 'components/modals/PopupDelete';

/* controllers */
import {
  deletePersonAction,
  updatePersonAction,
} from 'controllers/person/actions';
import { getInvolvementsAction } from 'controllers/involvement/actions';

/* constants */
import { PageEnum } from 'config/constants';

/* types */
import { IStore } from 'controllers/store';
import { PersonUpdateRequestExpanded } from 'controllers/person/models';
import CustomScrollbars from 'components/CustomScrollbars';
import {
  callbackTypeEnum,
  requestCallback,
} from "utils";
import { PersonUpdateResponse } from "@ternala/voltore-types";

interface Props {
  personId: number;
  children?: ReactNode;
}

const PersonItemInfoTab: FC<Props> = ({ personId }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPopupDeleteOpen, setIsPopupDeleteOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      getInvolvementsAction.request({
        person: personId,
        timeMarker: 'current',
        additionalFields: {
          person: personId,
          timeMarker: 'current',
        },
      }),
    );
  }, []);

  return (
    <>
      <CustomScrollbars id="person-info-container">
        <div className="item-info__tab info-tab person-info-tab">
          <div>
            <PersonalContacts
              personId={personId}
              onDelete={() => setIsPopupDeleteOpen(true)}
            />
          </div>
        </div>
      </CustomScrollbars>

      {isModalOpen && (
        <PersonModal
          personId={personId}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(payload) => {
            dispatch(
              updatePersonAction.request({
                ...(payload as PersonUpdateRequestExpanded),
                callback: (res: false | PersonUpdateResponse) => {
                  requestCallback(dispatch, !!res, callbackTypeEnum.update)
                  if (payload.callback) payload.callback(res);
                },
              }),
            );
          }}
        />
      )}

      {isPopupDeleteOpen && (
        <PopupDelete
          onClose={() => setIsPopupDeleteOpen(false)}
          onSubmit={() => {
            dispatch(
              deletePersonAction.request({
                id: personId,
                callback: (status: boolean) => {
                  requestCallback(dispatch, status, callbackTypeEnum.delete)
                },
              }),
            );
            dispatch(push(`/${PageEnum.PEOPLE}`));
          }}
        />
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  involvementState: store.involvement,
}))(PersonItemInfoTab);
