import React from 'react';
import { useDispatch } from 'react-redux';

// types
import { IFiltrationTag } from 'models';
import { TagDTO, TagShortDTO } from '@ternala/voltore-types';

// components
import DetachIcon from 'components/icons/DetachIcon';
import TagFilterIcon from 'components/icons/TagFilterIcon';
import { IEntity } from 'components/Windows/model.d';

// controllers
import { detachTagAction } from 'controllers/tag/actions';

interface IProps {
  width: number;
  tags: TagDTO[];
  entity: IEntity;
  tag: TagShortDTO;
  addTag: (tag: IFiltrationTag) => void;
}

const TagOptions = ({ tag, width, entity, addTag, tags }: IProps) => {
  const dispatch = useDispatch();
  const filter = () => {
    const found = tags.some((item) => item.id === tag.id);
    if (!found) return;
    addTag({
      fullTitle: tag.title,
      id: tag.id,
    });
  };

  return (
    <div className="tag-container">
      <div className="tag-options" style={{ width: `${width}px` }}>
        <div className="tag-option" onClick={() => filter()}>
          <div className="tag-option-icon">
            <TagFilterIcon />
          </div>
          <span>filter</span>
        </div>
        <div
          className="tag-option"
          onClick={() =>
            dispatch(detachTagAction.request({ id: tag?.id, entity }))
          }>
          <div className="tag-option-icon">
            <DetachIcon />
          </div>
          <span>detach</span>
        </div>
      </div>
    </div>
  );
};
export default TagOptions;
