import { NotesIcon } from 'components/icons/Notes/NotesIcon';
import { RemindersIcon } from 'components/icons/Notes/RemindesIcon';
import React from 'react';

interface IProps {
  icon: 'notes' | 'reminders';
  onClick: () => void;
}

const Button = ({ icon, onClick }: IProps) => {
  return (
    <div className='notes-button' onClick={onClick}>
      <div className='notes-button-row'>
        <div className='notes-button-item overlap'>
          <div className='tooltip'>
            <div className='tooltip-icon' >
              {icon === 'notes' ? <NotesIcon /> : <RemindersIcon />}
            </div>
            <div className='tooltip-content'>
              <span className='tooltip-content-placeholder'>
                {icon === 'notes'
                  ? 'Open/Create Notes'
                  : 'Open/Create Reminders'}
              </span>
              {/*<span className='tooltip-content-symbol'>*/}
              {/*  {icon === 'notes' ? 'N' : 'R'}*/}
              {/*</span>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Button;
