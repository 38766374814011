/* eslint-disable */
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import {TagEntityEnum} from '@ternala/voltore-types/lib/card'
/* draft modules */
import {
  CompositeDecorator,
  convertFromRaw,
  convertToRaw,
  EditorState,
  getDefaultKeyBinding,
  Modifier
} from "draft-js";
import { Editor, RawDraftContentState, SyntheticKeyboardEvent } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Config
import { entitySymbol, generateStartPhrase, styleMap, tagSymbol } from "../config";

//Custom components
import ActionBlock from "./TagActionsBlock";

// Utils
import { generateContent, generateEntityName, generateTagName, insertDraftEntity } from "./utils";

//Interfaces
import { ITagEditorShort, ITagEntityData, TagEditorStepEnum, TagEntityType } from "../../model.d";

//Context
import { FocusToMainContext } from "../../Contexts";

//Decorators
import { tagDecorators } from "../../Draft/Decorators";
import { TagDTO } from "@ternala/voltore-types/lib/modules/card/tag/tag.dto";
import { moveFocusToEnd } from "../../Draft/utils";
import PopupTag from "../../../modals/PopupTag";
import { TagApi } from "../../../../controllers/tag/transport/tag.api";
import PopupTagGroup from "../../../modals/PopupTagGroup";
import { IStore } from "../../../../controllers/store";
import { connect, useDispatch } from "react-redux";
import ActionBlockPortal from "../../../Hocs/ActionBlockPortal";
import { CardFilterEnum } from "../../../../controllers/card/models";
import { getAccessTokenUtil } from "utils";

interface IProps {
  updateTagEditor: (editor: ITagEditorShort) => void;
  closeEditor: () => void;
  hasError: boolean;

  tag?: TagDTO;
  entity?: ITagEntityData;
  step?: TagEditorStepEnum;

  store: IStore;
  modal: CardFilterEnum;
}

export const TagCreator: FC<IProps> = ({
  closeEditor,
  updateTagEditor,

  entity,
  tag,
  step,

  hasError,

  modal
}) => {
  const editorRef = useRef<Editor>(null);

  const dispatch = useDispatch()
  const startPhrase = generateStartPhrase();

  const content = generateContent({
    entity: entity?.data,
    tag,
  });
  const { moveFocusToMain } = useContext(FocusToMainContext);

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      convertFromRaw(content),
      new CompositeDecorator(tagDecorators),
    ),
  );

  const [isTagCategoryCreationOpen, setIsTagCategoryCreationOpen] =
    useState<boolean>(false);
  const [hasFocus, setHasFocus] = useState<boolean>(true);
  const [hasFocusActions, setHasFocusActions] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const [isCreatepreviewContextOpen, setIsCreatepreviewContextOpen] =
    useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      editorRef.current?.focusEditor();
      setEditorState(moveFocusToEnd(editorState));
    }, 1);
  }, []);

  const onEditorValueChange = (contentStateRaw: RawDraftContentState) => {
    let newEditorState = editorState;

    /* actions filter value */
    let contentState = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    const currentBlockIndex = contentState
      .getBlockMap()
      .keySeq()
      .findIndex((key) => key === currentSelection.getStartKey());

    const currentBlock = contentStateRaw.blocks[currentBlockIndex];
    const currentText = currentBlock.text;

    let newEntity = entity;
    let newTag = tag;

    let newType: TagEditorStepEnum | undefined = TagEditorStepEnum.Entity;

    if (currentText.indexOf(entitySymbol) === -1) {
      newEntity = undefined;
    } else {
      newType = TagEditorStepEnum.Tag;
    }

    if (currentText.indexOf(tagSymbol) === -1) {
      newTag = undefined;
    } else {
      newType = undefined;
    }

    if (currentText.indexOf(startPhrase) === -1) closeEditor();

    let searchValue = currentText;
    if (newType !== TagEditorStepEnum.Tag)
      searchValue = currentText.slice(startPhrase.length);

    if (newType === TagEditorStepEnum.Tag)
      searchValue = currentText.slice(
        currentText.indexOf(entitySymbol) + entitySymbol.length,
      );

    if (!newType)
      searchValue = currentText.slice(
        currentText.indexOf(tagSymbol) + tagSymbol.length,
      );

    setValue(searchValue.replace(/^\s*/, '').replace(/\s\s$/g, ' '));

    if (newEntity !== entity || newTag !== tag) {
      updateTagEditor({
        entity: newEntity,
        tag: newTag,
      });
    }

    setEditorState(newEditorState);
  };

  const setEntityHandler = (entity: ITagEntityData) => {
    if(entity.type && entity.data){
      const newEditorState = insertDraftEntity(
        {
          cardEntityType: entity.type,
          id: entity.data?.id,
          title: generateEntityName(entity.data) + entitySymbol,
          data: entity,
          startPhrase,
        },
        editorState,
        startPhrase,
        'ENTITY',
      );
      setEditorState(newEditorState);
      setValue('');
      setTimeout(() => {
        updateTagEditor({
          entity,
          tag,
        });
      }, 1);
    }
  };

  const setTagHandler = (tag: TagDTO) => {
    const newEditorState = insertDraftEntity(
      {
        cardEntityType: TagEditorStepEnum.Tag,
        id: tag.id,
        title: generateTagName(tag) + tagSymbol,
        data: tag,
        startPhrase,
      },
      editorState,
      entitySymbol,
      'TAG',
    );

    setValue('');
    setEditorState(newEditorState);
    setTimeout(() => {
      updateTagEditor({
        tag,
        entity,
      });
    }, 1);
    setTimeout(moveFocusToMain, 200);
  };

  const handleKeyCommand = (command: string): any => {
    if (command === 'split-block') {
      return 'handled';
    }
  };
  const myKeyBindingFn = (e: SyntheticKeyboardEvent): string | null => {
    if (e.key === 'Enter') {
      e.preventDefault();
      return null;
    }
    return getDefaultKeyBinding(e);
  };

  const removeEntityHandler = (entityType: 'ENTITY' | 'TAG') => {
    let contentState = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    const carriagePosition = editorState.getSelection().getFocusOffset();

    const currentBlockIndex = contentState
      .getBlockMap()
      .keySeq()
      .findIndex((key) => key === currentSelection.getStartKey());
    const contentStateRaw = convertToRaw(contentState);

    const currentBlock = contentStateRaw.blocks[currentBlockIndex];

    const { entityMap } = contentStateRaw;

    const foundDelimer = Object.entries(entityMap).find(
      ([, entity]) => entity.type === entityType,
    );
    const purposeRange = currentBlock.entityRanges.find(
      (range) => String(range.key) === foundDelimer?.[0],
    );
    if (purposeRange) {
      const delimerSelection = currentSelection.merge({
        anchorOffset: purposeRange.offset,
        focusOffset: carriagePosition,
      });
      const newContentState = Modifier.removeRange(
        contentState,
        delimerSelection,
        'forward',
      );

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'remove-range',
      );

      setEditorState(
        EditorState.forceSelection(
          newEditorState,
          newContentState.getSelectionAfter(),
        ),
      );
    }
  };

  return (
    <div className={'tag-editor' + (hasError ? ' error' : '')}>
      {(hasFocus || hasFocusActions || isCreatepreviewContextOpen) && step && (
        <ActionBlockPortal modal={modal}>
          <ActionBlock
            value={value}
            step={step}
            selectedTag={tag}
            onSelectTag={(data) => {
              setTagHandler(data);
            }}
            onRemoveTag={() => {
              updateTagEditor({
                entity: undefined,
                tag: undefined,
              });
              removeEntityHandler('ENTITY');
            }}
            selectedEntity={entity?.data}
            entityType={entity?.type as unknown as TagEntityType}
            onSelectEntity={(action) => {
              if (
                action.data &&
                action.entityType &&
                !('category' in action.data) &&
                !('user' in action.data) &&
                !('decisionMaker' in action.data) &&
                Object.values(TagEntityEnum).includes(
                  action.entityType as unknown as TagEntityEnum,
                )
              ) {
                setEntityHandler({
                  type: action.entityType as unknown as TagEntityEnum,
                  data: action.data,
                });
              }
            }}
            onRemoveEntity={() => {
              step === TagEditorStepEnum.Entity
                ? closeEditor()
                : updateTagEditor({
                    entity: undefined,
                    tag: undefined,
                  });
              removeEntityHandler('ENTITY');
            }}
            setHasFocus={setHasFocusActions}
            hasFocus={hasFocusActions}
            setIsCreatepreviewContextOpen={setIsCreatepreviewContextOpen}
            setIsCreateTagCategoryModalOpen={setIsCreatepreviewContextOpen}
          />
        </ActionBlockPortal>
      )}
      <Editor
        placeholder={
          'Leave a note, press @ to mention entity or trigger an action'
        }
        defaultEditorState={editorState}
        customDecorators={tagDecorators}
        editorState={editorState}
        onEditorStateChange={(editorState) =>
          editorState.getSelection().getHasFocus()
            ? setEditorState(moveFocusToEnd(editorState))
            : setEditorState(editorState)
        }
        editorClassName="tag-creator__input-field"
        customStyleMap={styleMap}
        onChange={onEditorValueChange}
        handleKeyCommand={handleKeyCommand}
        toolbarHidden={true}
        // @ts-ignore
        keyBindingFn={myKeyBindingFn}
        ref={editorRef}
        onBlur={() => setHasFocus(false)}
        onFocus={() => setHasFocus(true)}
      />
      {isCreatepreviewContextOpen && step === TagEditorStepEnum.Tag ? (
        <PopupTag
          setGroupCreation={setIsTagCategoryCreationOpen}
          onClose={() => {
            setIsCreatepreviewContextOpen(false);
            editorRef.current?.focusEditor();
          }}
          onCreate={async function (data) {
            const token = await getAccessTokenUtil(dispatch);
            if (token) await TagApi.createTag(data, token);
          }}
        />
      ) : (
        ''
      )}
      {isTagCategoryCreationOpen ? (
        <PopupTagGroup
          onClose={() => {
            setIsTagCategoryCreationOpen(false);
          }}
          onCreate={async function (data) {
            const token = await getAccessTokenUtil(dispatch);
            if (token)
              await TagApi.createTagCategory(data, token);
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default connect((store: IStore) => ({
  store,
}))(TagCreator);
