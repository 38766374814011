import { all } from 'redux-saga/effects';
import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { gSerchReducer, gSearchSaga } from './googleMaps';
import { authReducer, authSaga } from './auth';
import { socketReducer, socketSaga } from './socket';
import { userReducer, userSaga } from './user';
import { personReducer, personSaga } from './person';
import { enterpriseReducer, enterpriseSaga } from './enterprise';
import { propertyReducer, propertySaga } from './property';
import { involvementReducer, involvementSaga } from './involvement';
import { taxonomyReducer, taxonomySaga } from './taxonomy';
import { cardReducer, cardSaga } from './card';
import { tagReducer, tagSaga } from './tag';
import { modalsReducer, modalsSaga } from './modals';
import { historyReducer, historySaga } from './history';
import { showElementReducer, showElementSaga } from "./showElement";
import { favoriteReducer, favoriteSaga } from './favorite';
import { exportSaga, exportReducer } from "./export";

export const rootSaga = function* () {
  yield all([
    authSaga(),
    socketSaga(),
    userSaga(),
    personSaga(),
    enterpriseSaga(),
    involvementSaga(),
    propertySaga(),
    taxonomySaga(),
    cardSaga(),
    tagSaga(),
    favoriteSaga(),
    historySaga(),
    modalsSaga(),
    gSearchSaga(),
    showElementSaga(),
    exportSaga()
  ]);
};

export const rootReducer = (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    socketState: socketReducer,
    user: userReducer,
    person: personReducer,
    enterprise: enterpriseReducer,
    involvement: involvementReducer,
    property: propertyReducer,
    taxonomy: taxonomyReducer,
    card: cardReducer,
    tag: tagReducer,
    export: exportReducer,
    favorite: favoriteReducer,
    history: historyReducer,
    modals: modalsReducer,
    IGoogleSearchState: gSerchReducer,
    showElement: showElementReducer,
  });
