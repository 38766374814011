import { ActionType, createReducer } from "typesafe-actions";
import { omit } from "lodash";
import { all } from "redux-saga/effects";

import { IExportState } from "./model";
import { generateLoaderActions } from "../based";
import { concatWithUnique } from "utils";
import { exportActionSaga } from "./sagas/export";
import { widgetName } from "./actions";
import * as actions from './actions';

export type ActionTypes = ActionType<typeof actions>;

export const exportSaga = function* () {
  yield all([exportActionSaga()]);
};

const loaderActions = generateLoaderActions<IExportState, ActionTypes>(
  widgetName,
);

export const loaderHandlers = loaderActions.handlers;

const initialState: IExportState = {
  state: {
    errors: [],
    loaders: [],
  },
  templates: [],
  templateData: {}
};

export const exportReducer = createReducer<IExportState, ActionTypes>(
  initialState,
  loaderHandlers,
)
  .handleAction(
    [actions.getExportTemplatesAction.success],
    (state: IExportState, { payload }): IExportState => {
      const storedSearchParams = state.storedSearchParams;
      const { searchParams } = payload;
      const templateData = {
        ...state.templateData,
      };

      payload.response.items.forEach((template) => {
        templateData[template.id] = {
          ...templateData[template.id],
          ...template,
        };
      });


      let templates;
      if (
        JSON.stringify(omit(storedSearchParams, ['limit', 'offset'])) ===
        JSON.stringify(omit(searchParams, ['limit', 'offset']))
      ) {
        templates = concatWithUnique<number>(
          state.templates || [],
          payload.response.items.map(({id}) => id),
        );
      } else {
        templates = concatWithUnique<number>(
          [],
          payload.response.items.map(({id}) => id),
        );
      }
      return {
        ...state,
        storedSearchParams: searchParams,
        count: payload.response.counts,
        templates,
        isAll: payload.isAll,
        templateData
      };
    },
  )

/* Loader actions */
export const { addLoader, addError, removeError, removeLoader } = loaderActions.actions;

