import io from 'socket.io-client';

import { enableSocketLog } from 'config';

import { Config } from 'config/api';

export const connect = (token: string, namespace: string) => {
  const socket = io(Config.WS_ENDPOINT + namespace, {
    transports: ['websocket'],
    query: {
      'x-azure-auth': token,
    },
    forceNew: true,
  });

  return new Promise((resolve) => {
    socket.on('connect', () => {
      resolve(socket);
    });

    if (enableSocketLog) {
      (function () {
        var emit = socket.emit;
        // @ts-ignore
        socket.emit = function (event: string, ...args: any[]) {
          process.env.REACT_APP_SHOW_LOGS === 'true' &&
            console.log(
              `socket namespace ${namespace}: `,
              Array.prototype.slice.call(arguments),
            );
          emit.apply(socket, [event, ...args]);
        };
      })();
    }
  });
};
