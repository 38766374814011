
import React, { FC, useEffect, MouseEvent } from 'react'
import Modal from 'components/Hocs/Portal'

interface Props {
   url: string
   onClose: () => void
}

const PopupFullImage: FC<Props> = ({ url, onClose }) => {
   useEffect(() => {
      document.body.classList.add('no-scroll')

      return () => {
         document.body.removeAttribute('class')
      }
   }, [])

   const onClick = (event: MouseEvent<HTMLDivElement>) => {
      if (event.target instanceof HTMLDivElement) {
         if (!event.target.classList.contains('modal-content') && event.target.tagName !== 'IMG') {
            onClose()
         }
      }
   }

   return (
      <Modal>
         <div className="modal popup-full-image">
            <div className="popup-full-image__close" onClick={onClose} />

            <div className="scroll-area" onClick={onClick}>
               <div className="modal-content">
                  <div className="preview-container">
                     <img src={url} alt="building" />
                  </div>
               </div>
            </div>

            <div className="modal-overlay" onClick={onClose} />
         </div>
      </Modal>
   )
}

export default PopupFullImage