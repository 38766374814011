import React, { FC, useState, useEffect, useContext } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { RouterState } from 'connected-react-router';
import { omit } from 'lodash';

/* components */
import DataList from 'components/page/DataList';
import Filters from 'pages/Enterprise/Filters';
import EnterpriseModal from 'components/modals/EnterpriseModal';

/* controllers */
import {
  getEnterprisesAction,
  createEnterpriseAction,
} from 'controllers/enterprise/actions';
import { addNotification } from 'controllers/modals/actions';

/* constants */
import { LOADED_ITEMS_LIMIT } from 'config';
import { PageEnum } from 'config/constants';
import { Sorting, enterprisesSortOptions } from 'config/constants/sorting';
import {
  TaxonomyTypeSlug,
  sortType,
  enterpriseSortFields,
} from '@ternala/voltore-types/lib/constants';

/* types */
import { IStore } from 'controllers/store';
import {
  IEnterpriseState,
  EnterpriseGetListFiltersExtended,
} from 'controllers/enterprise/models';
import { EnterpriseGetListRequest } from '@ternala/voltore-types';
import { OptionType, ItemsLoadType, sortingTypeKeys } from 'models';
import uuid from 'utils/uuid';
import { getListImage } from "utils/getImage";

import { NotificationTypeEnum } from 'controllers/modals/models.d';
import TagsContext, { TagTypeEnum } from "context/Tags";

interface Props {
  routerState: RouterState;
  enterpriseState: IEnterpriseState;
}

const EnterprisePage: FC<Props> = (props) => {
  const {
    routerState: {
      location: { pathname },
    },
    enterpriseState: {
      enterprises,
      newEnterprises,
      count,
      isAll,
      storedSearchParams,
      enterpriseData,
    },
    children,
  } = props;

  const dispatch = useDispatch();
  const tagsFilters = useContext(TagsContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEnterpriseModalOpen, setIsEnterpriseModalOpen] =
    useState<boolean>(false);
  const [filtersOpened, setFiltersOpened] = useState<boolean>(false);
  const [filters, setFilters] = useState<EnterpriseGetListFiltersExtended>();

  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [sortValue, setSortValue] = useState<OptionType>(
    enterprisesSortOptions[0],
  );
  const [sortingType, setSortingType] = useState<sortType>(sortType.asc);
  const [sortField, setSortField] = useState<enterpriseSortFields | undefined>(
    enterpriseSortFields.title,
  );

  useEffect(() => {
    setIsLoading(true);
    loadEnterprises('start', () => setIsLoading(false));
  }, [sortingType, sortField, filters]);
  useEffect(() => {
    setIsLoading(true);
    loadEnterprises('start', () => setIsLoading(false));
    return () => {
      tagsFilters.removeTags();
      tagsFilters.removeTags(() => {}, TagTypeEnum.ExcludedTags);
    };
  }, []);

  useEffect(() => {
    if (!tagsFilters?.tags) return;
    setFilters({
      ...filters,
      selectedTags: tagsFilters?.tags?.map((tag) => tag.id),
    });
  }, [tagsFilters?.tags]);
  useEffect(() => {
    if (searchQuery === '') {
      setIsLoading(true);
      loadEnterprises('start', () => setIsLoading(false));
    }
  }, [searchQuery]);
  useEffect(() => {
    if (!filters?.selectedTags?.length) tagsFilters?.removeTags();
    if (!filters?.unselectedTags?.length) tagsFilters.removeTags(() => {}, TagTypeEnum.ExcludedTags);
  }, [filters?.selectedTags, filters?.unselectedTags]);

  const loadEnterprises = (
    loadType: ItemsLoadType = 'start',
    callback?: Function,
  ) => {
    const searchParams: EnterpriseGetListRequest = {
      limit: LOADED_ITEMS_LIMIT,
      offset: loadType === 'more' ? enterprises?.length : 0,
      query: searchQuery,
      sortType: sortingType,
      sortField: sortField,
    };

    if (filters?.enterpriseTypeFilter?.length) {
      searchParams[TaxonomyTypeSlug.enterpriseType] =
        filters.enterpriseTypeFilter.map((filter) => filter.value);
    }
    if (filters?.parentEnterprisesFilter?.length) {
      searchParams.parents = filters.parentEnterprisesFilter.map(
        (filter) => filter.value,
      );
    }
    if (filters?.personsFilter?.length) {
      searchParams.persons = filters.personsFilter.map(
        (filter) => filter.value,
      );
    }

    if (filters?.selectedTags?.length) {
      searchParams.selectedTags = tagsFilters?.tags?.map((tag) => tag.id);
    }
    if (filters?.unselectedTags?.length) {
      searchParams.unselectedTags = filters.unselectedTags;
    }
    if (filters?.involvementTypeFilter?.length) {
      searchParams[TaxonomyTypeSlug.involvementType] =
        filters.involvementTypeFilter.map((filter) => filter.value);
    }
    if (filters?.owner) {
      searchParams.owner = true;
    }
    if (filters?.decisionMaker) {
      searchParams.decisionMaker = true;
    }
    if (
      JSON.stringify(omit(storedSearchParams, ['limit', 'offset'])) !==
      JSON.stringify(omit(searchParams, ['limit', 'offset']))
    ) {
      searchParams.offset = 0;
    }

    dispatch(getEnterprisesAction.request({ ...searchParams, callback }));
  };

  const onSort = (sortOption: OptionType) => {
    const sorting = Sorting[sortOption.value as sortingTypeKeys];
    setSortValue(sortOption);
    setSortField(sorting.sortField as enterpriseSortFields);
    setSortingType(sorting.sortType);
  };

  const enterprisesListItems = enterprises
    ?.map((id) => enterpriseData[id])
    ?.filter((enterprise) => enterprise !== undefined)
    ?.map((enterprise) => ({
      id: enterprise.id,
      title: enterprise[TaxonomyTypeSlug.enterpriseType]?.title,
      description: enterprise.title,
      image: getListImage(enterprise.images) || '',
    }));

  const newEnterprisesListItems = newEnterprises
    ?.map((id) => enterpriseData[id])
    ?.map((enterprise) => ({
      id: enterprise.id,
      title: enterprise[TaxonomyTypeSlug.enterpriseType]?.title,
      description: enterprise.title,
      image: getListImage(enterprise.images)  || '',
    }));
  return (
    <>
      <div className="enterprise-page">
        <Helmet>
          <title>
            Enterprises | Voltore{' '}
            {process.env.REACT_APP_COMPANY_NAME
              ? `| ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        <div className="page-layout__content">
          <DataList
            setIsLoading={setIsLoading}
            items={enterprisesListItems}
            newItems={newEnterprisesListItems || []}
            itemsCount={count}
            itemsLoading={isLoading}
            allItemsLoaded={isAll}
            activeTab="info"
            pageTitle={PageEnum.ENTERPRISES}
            singleItem="enterprise"
            onLoadMore={loadEnterprises}
            onSort={onSort}
            sortValue={sortValue}
            sortOptions={enterprisesSortOptions}
            checkSuffixByActive={false}
            listControls={{
              searchPlaceholder: 'Search by Company Name, System ID',
              searchQuery,
              clearSearchQuery: () => setSearchQuery(''),
              onSearch: (query) => setSearchQuery(query),
              onAddNew: () => setIsEnterpriseModalOpen(true),
            }}
            Filters={{
              isOpen: filtersOpened,
              toggle: () => setFiltersOpened(!filtersOpened),
              applied: { enterprises: filters },
              resetFilter: () => setFilters({}),
              onClear: () => setFilters({}),
            }}
          />

          <div className="item-info">
            {pathname === `/${PageEnum.ENTERPRISES}` && (
              <div className="empty-container-wrapper">
                <div className="empty-container" />
              </div>
            )}

            {children}
          </div>

          {isEnterpriseModalOpen && (
            <EnterpriseModal
              loadEnterprises={loadEnterprises}
              onClose={() => setIsEnterpriseModalOpen(false)}
              onSubmit={(payload) =>
                dispatch(
                  createEnterpriseAction.request({
                    ...payload,
                    callback: (status: boolean) => {
                      if (status) {
                        // if create was success - the additional popup appear
                        dispatch(
                          addNotification({
                            id: uuid(),
                            text: 'Successfully created',
                            type: NotificationTypeEnum.success,
                            title: 'Successfully created',
                          }),
                        );
                      } else {
                        // if create was wrong - the additional popup appear
                        dispatch(
                          addNotification({
                            id: uuid(),
                            text: 'Something goes wrong',
                            type: NotificationTypeEnum.error,
                            title: 'Something goes wrong',
                          }),
                        );
                      }
                      if (payload.callback) payload.callback(status);
                    },
                  }),
                )
              }
            />
          )}

          {filtersOpened && (
            <Filters
              appliedFilters={filters}
              onFiltersUpdated={(filters) => setFilters(filters.enterprises)}
              onClose={() => setFiltersOpened(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default connect((store: IStore) => ({
  routerState: store.router,
  enterpriseState: store.enterprise,
}))(EnterprisePage);
