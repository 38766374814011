import React, { FC, useState, useEffect } from 'react';

/* UI components */
import Modal from 'components/Hocs/Portal';
import Input from 'components/UI/controls/Input';
import Button from 'components/UI/controls/Button';

/* types */
import {
  TagCategoryUpdateRequest,
  TagCategoryCreateRequest,
} from '@ternala/voltore-types/lib/modules/card/tag/category/request.dto';
import { TagCategoryDTO } from '@ternala/voltore-types';
import { useDispatch, useSelector } from 'react-redux';
import { getGroups } from '../../controllers/tag/selectors';
import { createTagCategoryAction, updateTagCategoryAction } from "../../controllers/tag/actions";
import { removeShowedElement } from '../../controllers/showElement/actions';

export const tagCategoryModalKey = 'TagCategoryModal';

export interface ITagCategoryModalProps {
  showElementId?: string;
  isByShowElement?: boolean;
  id?: number;
  completeAction?: (category: TagCategoryDTO | boolean) => void;

  group?: TagCategoryUpdateRequest;
  groups?: TagCategoryDTO[];

  onClose?: () => void;
  onCreate?: (title: TagCategoryCreateRequest, callback: Function) => void;
  onUpdate?: (title: TagCategoryUpdateRequest, callback: Function) => void;
}

const TagGroupModal: FC<ITagCategoryModalProps> = (props) => {
  const dispatch = useDispatch();
  const {
    onClose,
    onCreate,
    onUpdate,
    completeAction,
    id,
    showElementId,
    isByShowElement
  } = props;
  let group = props.group;

  const [error, setError] = useState<string>('');
  const [requestProcess, setRequestProcess] = useState<boolean>(false);
  const [groupTitle, setGroupTitle] = useState<string | undefined>(
    group ? group.title : undefined,
  );

  const groups = useSelector(getGroups);
  if (id) {
    group = groups.find(group => group.id === id);
  }

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);
    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  useEffect(() => {
    if (!!groups?.find(
      (item) => item.title === groupTitle && group?.id !== item.id,
    )?.id) {
      setError('Tag with this name is already created');
    } else {
      setError('');
    }
  }, [groupTitle]);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onCloseHandle();
  };

  const onCloseHandle = () => {
    if (isByShowElement) {
      if (showElementId != null) {
        dispatch(removeShowedElement(showElementId));
      }
    } else {
      onClose?.();
    }
  };

  const submitData = () => {
    setRequestProcess(true);
    if (groupTitle) {
      if (group) {
        if(isByShowElement){
          dispatch(
            updateTagCategoryAction.request({
              id: group.id,
              title: groupTitle,
              callback: (status: boolean) => {
                if (status) onCloseHandle();
                completeAction?.(status);
                setRequestProcess(false);
              },
            }),
          );
        } else {
          onUpdate?.({ id: group.id, title: groupTitle }, (status: boolean) => {
            if (status) onCloseHandle();
            setRequestProcess(false);
          });
        }
      } else {
        if(isByShowElement){
          dispatch(
            createTagCategoryAction.request({
              title: groupTitle,
              callback: (status: boolean) => {
                if (status) onCloseHandle();
                completeAction?.(status);
                setRequestProcess(false);
              },
            }),
          );
        } else {
          onCreate?.({ title: groupTitle }, (status: boolean) => {
            if (status) onCloseHandle();
            setRequestProcess(false);
          });
        }
      }
    }
  };

  return (
    <Modal>
      <div className="modal modal_centered">
        <div className="scroll-area">
          <form className="modal-content" onSubmit={submitData}>
            <div className="modal__close" onClick={onCloseHandle} title="Close" />

            <div className="modal-title">{group ? 'Edit' : 'Create'} group</div>
            <div
              className="items-container"
              style={{
                flexDirection: 'column',
              }}>
              <Input
                label="title"
                placeholder="Type here"
                value={groupTitle}
                onChange={(e) => setGroupTitle(e.target.value)}
                error={error}
                required
              />
            </div>

            <footer className="modal-footer">
              <Button variant="light" onClick={onCloseHandle}>
                Cancel
              </Button>
              <Button
                variant="dark"
                onClick={submitData}
                disabled={!groupTitle || requestProcess || error !== ''}>
                Save
              </Button>
            </footer>
          </form>
        </div>

        <div className="modal-overlay" onClick={onCloseHandle} />
      </div>
    </Modal>
  );
};

export default TagGroupModal;
