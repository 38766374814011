import React, { Component, createRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, ListRowProps } from "react-virtualized";
import { animated, config, useTransition } from "react-spring";

//Config
import { ITEMS_LOADER_HEIGHT } from "config";

// Components
import Loader from "components/Loader";
import DataListItem from "./DataListItem";

//Types
import { IDataListItem, ItemsLoadType } from "models/index";
import { Pages } from "routing";
import { singleItemType } from "./index";

// @ts-ignore
const listStyle: React.CSSProperties = { overflowX: false, overflowY: false };

const Notification: React.FC<any> = ({ condition, scrollToTop }) => {
  const transitions = useTransition(condition, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  });
  return transitions((styles, item) =>
    item ? (
      <animated.div
        style={styles}
        className="new-items-notification"
        onClick={scrollToTop}>
        <svg
          width="35"
          height="8"
          viewBox="0 0 35 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.3182 8C1.3902 8 1.4142 7.964 1.4142 7.892V3.884C1.4142 2.792 1.4022 2.264 1.3542 1.652H1.3662C1.8342 2.816 4.0302 6.692 4.6542 7.916C4.6902 7.976 4.7262 8 4.8102 8H6.3342C6.4182 8 6.4542 7.976 6.4542 7.892V0.224C6.4542 0.151999 6.4302 0.116 6.3462 0.116H5.1942C5.1222 0.116 5.0862 0.151999 5.0862 0.211999V4.208C5.0862 5.336 5.1102 5.732 5.1222 6.068H5.1102C4.6902 5.168 2.8542 1.784 1.9662 0.2C1.9302 0.14 1.9182 0.116 1.8222 0.116H0.130203C0.0702032 0.116 0.0462031 0.14 0.0462031 0.2V7.892C0.0462031 7.988 0.0822032 8 0.154203 8H1.3182ZM13.4096 6.788C13.4216 6.728 13.3976 6.692 13.3256 6.692H9.68961V4.628H12.8096C12.8576 4.628 12.8936 4.616 12.8936 4.556V3.416C12.8936 3.344 12.8696 3.332 12.8096 3.332H9.68961V1.424H13.1216C13.1936 1.424 13.2176 1.376 13.2056 1.316L13.0736 0.2C13.0616 0.128 13.0376 0.116 12.9656 0.116H8.33361C8.26161 0.116 8.23761 0.151999 8.23761 0.211999V7.904C8.23761 7.964 8.26161 8 8.34561 8H13.1216C13.1936 8 13.2176 7.988 13.2296 7.904L13.4096 6.788ZM19.9247 0.211999C19.9007 0.14 19.8887 0.116 19.8287 0.116H18.4367C18.3767 0.116 18.3287 0.14 18.3167 0.211999C17.3927 4.136 17.0327 5.456 16.8887 6.236H16.8767C16.7807 5.576 16.5647 4.484 15.5447 0.2C15.5327 0.116 15.4847 0.116 15.4247 0.116H14.0687C14.0087 0.116 13.9847 0.14 13.9967 0.211999L16.0007 7.904C16.0367 7.964 16.0607 8 16.1327 8H17.4047C17.4887 8 17.5127 7.952 17.5247 7.892C17.9087 6.428 18.8447 2.96 19.0607 1.748H19.0727C19.3127 2.936 20.1167 6.368 20.4767 7.928C20.5007 7.976 20.5127 8 20.5967 8H21.8927C21.9647 8 21.9887 7.988 22.0127 7.928L24.0407 0.224C24.0527 0.151999 24.0287 0.116 23.9567 0.116H22.6967C22.6247 0.116 22.5887 0.151999 22.5767 0.211999C21.7367 3.98 21.4247 5.576 21.3047 6.248H21.2927C20.9687 4.604 20.3927 2.192 19.9247 0.211999ZM30.4125 8H31.2885V2.216L33.2325 4.484H34.2885L30.8565 0.644H30.8445L27.4125 4.484H28.4565L30.4125 2.216V8Z"
            fill="white"
          />
        </svg>
      </animated.div>
    ) : (
      ''
    ),
  );
};

export interface IProps{
  items: IDataListItem[];
  newItems?: IDataListItem[];
  activeTab?: string;
  pageTitle: Pages;
  singleItem: singleItemType;
  onLoadMore?: (loadType: ItemsLoadType, callback?: Function) => void;
  allItemsLoaded?: boolean;
  checkSuffixByActive?: boolean
}

class ScrollList extends Component<IProps, any> {
  private cache: CellMeasurerCache;
  constructor(props: any) {
    super(props);
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 85,
    });
  }

  list = createRef<List>();
  scrollbar = createRef<Scrollbars>();

  state = {
    isLoadingMore: false,
    allowNotification: false,
  };

  scrollToTop = () => {
    this.scrollbar?.current?.scrollToTop();
  };

  handleScroll = (e: any) => {
    const { scrollTop, scrollLeft, clientHeight, scrollHeight } = e.target;
    // @ts-ignore
    const { Grid } = this.list.current;
    Grid.handleScrollEvent({ scrollTop, scrollLeft });

    if (scrollTop === 0) {
      this.setState(() => ({ allowNotification: false }));
    } else {
      this.setState(() => ({ allowNotification: true }));
    }
    if (this.props.allItemsLoaded) return;
    if (this.state.isLoadingMore) return;
    if (clientHeight + scrollTop >= scrollHeight  - ITEMS_LOADER_HEIGHT) {
      this.setState(() => ({ isLoadingMore: true }));
      this.props.onLoadMore?.('more', () => {
          this.setState(() => ({ isLoadingMore: false }));
      });
    }
  };

  renderRow = ({ index, parent, key, style }: ListRowProps) => {
    const { items } = this.props;
    const item = items[index];
    return (
      <CellMeasurer
        key={key}
        columnIndex={0}
        parent={parent}
        rowIndex={index}
        cache={this.cache}>
        <div style={style}>
          <DataListItem
            id={item.id}
            key={item.id}
            tag={item.tag}
            itemTitle={item.title}
            page={this.props.pageTitle}
            initials={item.initials}
            imageUrl={item.image || ''}
            activeTab={this.props.activeTab}
            itemName={this.props.singleItem}
            itemDescription={item.description}
            checkSuffixByActive={this.props.checkSuffixByActive}
          />
          {!this.props.allItemsLoaded &&
            this.props.allItemsLoaded !== undefined &&
            this.state.isLoadingMore &&
            items.length === index + 1 && (
              <div className="items-list__loader" style={{ height: '60px' }}>
                <Loader />
              </div>
            )}
        </div>
      </CellMeasurer>
    );
  };

  render() {
    const { items, newItems, onLoadMore } = this.props;
    return (
      <div className="data-list__items items-list" id="data-list-scrollable">
        <Notification
          condition={this.state.allowNotification && newItems?.length}
          scrollToTop={this.scrollToTop}
        />
        <AutoSizer>
          {({ height, width }) => (
            <Scrollbars
              autoHide
              ref={this.scrollbar}
              onScroll={onLoadMore ? this.handleScroll : undefined}
              style={{ height, width }}
              renderThumbVertical={(props) => (
                <div {...props} className="page-scrollbar-y" />
              )}
              renderTrackHorizontal={(props) => (
                <div {...props} className="page-scrollbar-x" />
              )}>
              {newItems?.length ? (
                <div className="new-items">
                  {newItems.map((item) => (
                    <DataListItem
                      id={item.id}
                      tag={item.tag}
                      key={item.id}
                      initials={item.initials}
                      itemTitle={item.title}
                      page={this.props.pageTitle}
                      imageUrl={item.image || ''}
                      activeTab={this.props.activeTab}
                      itemName={this.props.singleItem}
                      itemDescription={item.description}
                      checkSuffixByActive={this.props.checkSuffixByActive}
                    />
                  ))}
                  <div className="new-items-dash">
                    <span>New</span>
                  </div>
                </div>
              ) : (
                ''
              )}
              <List
                data={items}
                width={width}
                height={height}
                ref={this.list}
                style={listStyle}
                rowCount={items.length}
                rowRenderer={this.renderRow}
                rowHeight={this.cache.rowHeight}
                deferredMeasurementCache={this.cache}
              />
            </Scrollbars>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default ScrollList;
