import {
  TagCategoryCreateRequest,
  TagCategoryCreateResponse,
  TagCategoryGetListResponse,
  TagCreateResponse,
  TagCreateRequest,
  TagDetachRequest,
  TagDetachResponse,
  TagCategoryGetListRequest,
  TagDeleteRequest,
  TagUpdateRequest,
  TagUpdateResponse,
  TagCategoryUpdateRequest,
  TagCategoryUpdateResponse,
  TagCategoryDeleteRequest,
  TagCategoryDeleteResponse,
  TagDeleteResponse,
} from '@ternala/voltore-types';

import { Config } from '../../../config/api';
import { authHeader, handleErrors } from '../../../utils/API';

import { TagGetListRequest, TagGetListResponse } from '@ternala/voltore-types';

import { appendSearchParams } from 'utils';
import { AsyncOptions } from 'components/UI/controls/SelectSearchCustom';
import {IError} from "../../model";

class API {
  public async createTag(
    createTagData: TagCreateRequest,
    token: string,
  ): Promise<TagCreateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'tag/create');

    return handleErrors(
      fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(createTagData),
      }),
    );
  }

  public async getTagList(
    getTagListData: TagGetListRequest,
    token: string,
    searchParams?: AsyncOptions,
  ): Promise<TagGetListResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'tag/list');
    url = appendSearchParams(url, getTagListData);
    if (searchParams) url = appendSearchParams(url, searchParams);
    if (getTagListData.property)
      url.searchParams.append('property', `${getTagListData.property}`);
    // if (getTagListData.saleTransaction)
    //   url.searchParams.append(
    //     'saleTransaction',
    //     `${getTagListData.saleTransaction}`,
    //   );
    // if (getTagListData.leaseTransaction)
    //   url.searchParams.append(
    //     'leaseTransaction',
    //     `${getTagListData.leaseTransaction}`,
    //   );
    if (getTagListData.enterprise)
      url.searchParams.append('enterprise', `${getTagListData.enterprise}`);
    if (getTagListData.person)
      url.searchParams.append('person', `${getTagListData.person}`);

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async detachTag(
    detachTagData: TagDetachRequest,
    token: string,
  ): Promise<TagDetachResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'tag/detach');
    url = appendSearchParams(url, detachTagData);

    if (detachTagData.id) url.searchParams.append('id', `${detachTagData.id}`);
    if (detachTagData.property)
      url.searchParams.append('property', `${detachTagData.property}`);
    // if (detachTagData.saleTransaction)
    //   url.searchParams.append(
    //     'saleTransaction',
    //     `${detachTagData.saleTransaction}`,
    //   );
    // if (detachTagData.leaseTransaction)
    //   url.searchParams.append(
    //     'leaseTransaction',
    //     `${detachTagData.leaseTransaction}`,
    //   );
    if (detachTagData.enterprise)
      url.searchParams.append('enterprise', `${detachTagData.enterprise}`);
    if (detachTagData.person)
      url.searchParams.append('person', `${detachTagData.person}`);

    return handleErrors(
      fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(detachTagData),
      }),
    );
  }

  public async deleteTag(
    deleteTagData: TagDeleteRequest,
    token: string,
  ): Promise<TagDeleteResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'tag/delete');
    url = appendSearchParams(url, deleteTagData);
    if (deleteTagData.id) url.searchParams.append('id', `${deleteTagData.id}`);

    return handleErrors(
      fetch(url.toString(), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(deleteTagData),
      }),
    );
  }

  public async deleteTagCategory(
    deleteTagCategoryData: TagCategoryDeleteRequest,
    token: string,
  ): Promise<TagCategoryDeleteResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'tag-category/delete');
    url = appendSearchParams(url, deleteTagCategoryData);
    if (deleteTagCategoryData.id)
      url.searchParams.append('id', `${deleteTagCategoryData.id}`);

    return handleErrors(
      fetch(url.toString(), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(deleteTagCategoryData),
      }),
    );
  }

  public async createTagCategory(
    createTagCategoryData: TagCategoryCreateRequest,
    token: string,
  ): Promise<TagCategoryCreateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'tag-category/create');

    return handleErrors(
      fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(createTagCategoryData),
      }),
    );
  }

  public async getTagCategoryList(
    token: string,
    getTagCategoryListData?: TagCategoryGetListRequest,
    searchParams?: AsyncOptions,
  ): Promise<TagCategoryGetListResponse | null | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'tag-category/list');
    if (searchParams) url = appendSearchParams(url, searchParams);
    if (getTagCategoryListData)
      url = appendSearchParams(url, getTagCategoryListData);

    try {
      const response = await fetch(String(url), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      });
      const data = await response.json();
      if (response.statusText === 'OK' || response.status === 200) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async updateTag(
    updateTagData: TagUpdateRequest,
    token: string,
  ): Promise<TagUpdateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'tag/update');

    return handleErrors(
      fetch(url.toString(), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(updateTagData),
      }),
    );
  }

  public async updateTagCategory(
    updateTagData: TagCategoryUpdateRequest,
    token: string,
  ): Promise<TagCategoryUpdateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'tag-category/update');

    return handleErrors(
      fetch(url.toString(), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(updateTagData),
      }),
    );
  }
}

export const TagApi = new API();
