import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
   fill?: string
}

export const PlusIcon: FC<Props> = ({ fill, color, ...props }) => (
   <svg
      {...props}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path 
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9.46211 5C9.69683 5 9.88711 5.19028 9.88711 5.425V13.5C9.88711 13.7347 9.69683 13.925 9.46211 13.925C9.22739 13.925 9.03711 13.7347 9.03711 13.5V5.425C9.03711 5.19028 9.22739 5 9.46211 5Z"
         fill={color}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M13.9248 9.46211C13.9248 9.69683 13.7345 9.88711 13.4998 9.88711L5.4248 9.88711C5.19008 9.88711 4.9998 9.69683 4.9998 9.46211C4.9998 9.22739 5.19008 9.03711 5.4248 9.03711L13.4998 9.03711C13.7345 9.03711 13.9248 9.22739 13.9248 9.46211Z"
         fill={color}
      />
   </svg>
)

PlusIcon.defaultProps = {
   color: '#1F2531',
   fill: 'white'
}