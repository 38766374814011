import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const ScrollIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    {...props}
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.87 5.73449C11.6842 5.92941 11.368 5.94378 11.1637 5.76658L6 1.28848L0.836332 5.76658C0.632004 5.94378 0.31578 5.92941 0.130026 5.73449C-0.0557279 5.53957 -0.0406694 5.23791 0.163659 5.06071L6 -0.000752473L11.8363 5.06071C12.0407 5.23791 12.0557 5.53957 11.87 5.73449Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 13.9999L5.5 0.644531L6.5 0.644531L6.5 13.9999L5.5 13.9999Z"
      fill={color}
    />
  </svg>
);

ScrollIcon.defaultProps = {
  color: '#1F2531',
};
