import { all } from 'redux-saga/effects';
import { createReducer, ActionType } from 'typesafe-actions';

// Actions
import * as actions from './actions';

// Interfaces
import { LoaderActionType } from 'controllers/store';
import { generateLoaderActions } from 'controllers/based';
import { favoriteActionSaga } from './sagas/favorite';
import { IFavoriteState } from './models';

//Sagas
export type FavoriteActionType = ActionType<typeof actions>;
export const favoriteSaga = function* () {
  yield all([favoriteActionSaga()]);
};

/* Reducer */
const initialState: IFavoriteState = {
  state: {
    errors: [],
    loaders: [],
  },
  favoriteData: {},
};

type ActionTypes = FavoriteActionType | LoaderActionType;

const loaderActions = generateLoaderActions<IFavoriteState, ActionTypes>(
  actions.widgetName,
);

export const loaderHandlers = loaderActions.handlers;

export const favoriteReducer = createReducer<IFavoriteState, ActionTypes>(
  initialState,
).handleAction(
  [actions.changeStatusAction.success],
  (state: IFavoriteState, { payload }): IFavoriteState => {
    const favorite = state.favoriteData[payload.id];
    return {
      ...state,
      favoriteData: {
        ...state.favoriteData,
        [payload.id]: {
          ...favorite,
          isFavorite: payload.status,
        },
      },
    };
  },
);

/* Loader actions */
export const addLoader = loaderActions.actions.addLoader;
export const addError = loaderActions.actions.addError;
export const removeError = loaderActions.actions.removeError;
export const removeLoader = loaderActions.actions.removeLoader;
