import {
  PropertyFullDTO,
  PropertyGetListRequest,
} from '@ternala/voltore-types';
import { IPoint, PropertyGetListFiltersByArea } from "@ternala/voltore-types/lib/modules/property/filters/area";
import {
  applyLeaseTransactionFilters,
  applyPropertyFilters,
  applySaleTransactionFilters,
} from './applyFilters';
import { isEmpty, omit } from 'lodash';
import { PropertyGetListFiltersExtended } from '../../controllers/property/models';
import {
  propertySortFields,
  sortType,
} from '@ternala/voltore-types/lib/constants';

export const propertyToPropertyData = (
  property: PropertyFullDTO,
): IPropertyMapData => {
  return {
    id: property.id,
    image: property.image?.url,
    propertyTypes: property.propertyType?.map((item) => item.title).join(', '),
    address: property?.address?.address || '',
    coords: {
      latitude: Number(property?.address?.latitude),
      longitude: Number(property?.address?.longitude),
    },
  };
};

export const getBoundsPolygon = (
  bounds: google.maps.LatLngBounds,
): IPoint[] => {
  const coordsNorthWest = {
    latitude: bounds.getNorthEast().lat(),
    longitude: bounds.getSouthWest().lng(),
  };
  const coordsNorthEast = {
    latitude: bounds.getNorthEast().lat(),
    longitude: bounds.getNorthEast().lng(),
  };
  const coordsSouthWest = {
    latitude: bounds.getSouthWest().lat(),
    longitude: bounds.getSouthWest().lng(),
  };
  const coordsSouthEast = {
    latitude: bounds.getSouthWest().lat(),
    longitude: bounds.getNorthEast().lng(),
  };
  return [coordsNorthWest, coordsNorthEast, coordsSouthEast, coordsSouthWest];
};

export const preparePropertyFilters = ({
  filters,
  searchQuery,
  sortingType,
  sortField,
  area,
  data,
}: {
  filters?: PropertyGetListFiltersExtended;
  searchQuery?: string;
  sortingType?: sortType;
  area?: PropertyGetListFiltersByArea;
  sortField?: propertySortFields;
  data?: { limit: number; offset: number } | { bounds: IPoint[] };
}) => {
  let searchParams: PropertyGetListRequest = {
    ...(data && 'limit' in data ? data : {}),
    query: searchQuery,
    sortType: sortingType,
    sortField,
  };

  const propertyFilters = applyPropertyFilters(filters);
  const saleTransactionFilters = applySaleTransactionFilters(filters);
  const leaseTransactionFilters = applyLeaseTransactionFilters(filters);

  if (filters?.property?.searchQuery)
    searchParams.query = filters.property.searchQuery;
  if (filters?.selectedTags?.length)
    searchParams.selectedTags = filters?.selectedTags.map((tag) => tag.id);
  if (filters?.excludedTags?.length)
    searchParams.unselectedTags = filters?.excludedTags.map((tag) => tag.id);
  if (!isEmpty(propertyFilters)) searchParams.property = propertyFilters;
  if (!isEmpty(saleTransactionFilters))
    searchParams.saleTransaction = saleTransactionFilters;
  if (!isEmpty(leaseTransactionFilters))
    searchParams.leaseTransaction = leaseTransactionFilters;
  if (!isEmpty(area)) {
    searchParams.area = area;
  }
  if (data && 'bounds' in data && !area) {
    searchParams.area = {
      polygon: data.bounds,
    };
    searchParams = omit(searchParams, ['limit', 'offset']);
  }
  return searchParams;
};
