import { appName } from "config";
import {createAction} from "typesafe-actions";
import { ISelectEntityData, IShowElement } from "./models";

/* Actions */
export const widgetName = "showElement";

export interface ISetAuthenticatedStatus {
  status: boolean;
}

// ** Action
export const setIsShow = createAction(
  `${appName}/${widgetName}/SET_IS_SHOW`
)<boolean>();

export const addShowedElement = createAction(
  `${appName}/${widgetName}/ADD_SHOW_ELEMENT`
)<IShowElement>();

export const removeShowedElement = createAction(
  `${appName}/${widgetName}/REMOVE_SHOW_ELEMENT`
)<string>();

export const selectEntity = createAction(
  `${appName}/${widgetName}/SELECT_ENTITY`
)<ISelectEntityData>();

export const unselectEntity = createAction(
  `${appName}/${widgetName}/UNSELECT_ENTITY`
)<ISelectEntityData>();