import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
  width?: number;
  height?: number;
}

export const RollerCloseIcon: FC<Props> = ({
  width,
  height,
  color,
  ...props
}) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 11.4121L8.25 16.9121L7.25 16.9121L7.25 12.4121L2.75 12.4121L2.75 11.4121L8.25 11.4121Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4375 8.27539L11.4375 2.77539L12.4375 2.77539L12.4375 7.27539L16.9375 7.27539L16.9375 8.27539L11.4375 8.27539Z"
      fill={color}
    />
  </svg>
);

RollerCloseIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#1F2531',
};

export default RollerCloseIcon;
