import { appName } from "config";
import { createAsyncAction } from "typesafe-actions";

import {
  CreateSuiteDTO,
  SuiteFullDTO,
  PropertyUpdateFloorsList, 
  PropertyFullDTO
} from "@ternala/voltore-types";
import { IException } from "controllers/store";

import {IResponsePropertiesAdditionalFields} from "../models"

import { widgetName } from "./widjet"

// Additional entities
export const createPropertySuiteAction = createAsyncAction(
  `${appName}/${widgetName}/CREATE_SUITE_REQUEST`,
  `${appName}/${widgetName}/CREATE_SUITE_SUCCESS`,
  `${appName}/${widgetName}/CREATE_SUITE_FILED`
)<(CreateSuiteDTO & IResponsePropertiesAdditionalFields) & { callback?: Function }, {response: SuiteFullDTO, additionalFields: IResponsePropertiesAdditionalFields}, IException>();

export const updatePropertyFloorListAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_FLOOR_LIST_REQUEST`,
  `${appName}/${widgetName}/UPDATE_FLOOR_LIST_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_FLOOR_LIST_FILED`
)<(PropertyUpdateFloorsList & IResponsePropertiesAdditionalFields) & { callback?: Function }, {response: PropertyFullDTO, additionalFields: IResponsePropertiesAdditionalFields}, IException>();


