import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

interface IProps {
  ref?: any;
  style?: any;
  id?: string;
  onScroll?: any;
  autoHeight?: any;
  className?: string;
  autoHeightMin?: any;
  autoHeightMax?: any;
  children: React.ReactNode;
  renderView?: React.FunctionComponent<any>;
  renderThumbVertical?: React.FunctionComponent<any>;
}

const CustomScrollbars = (props: IProps) => {
  return (
    <Scrollbars
      autoHide
      {...props}
      ref={props.ref}
      renderThumbVertical={
        props.renderThumbVertical
          ? props.renderThumbVertical
          : (props) => (
              <div
                {...props}
                className="page-scrollbar-y"
                style={{ marginRight: '-5px', marginBottom: '-5px' }}
              />
            )
      }
      renderTrackHorizontal={(props) => (
        <div {...props} className="page-scrollbar-x" />
      )}>
      {props.children}
    </Scrollbars>
  );
};

export default CustomScrollbars;
