import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  width?: number;
  height?: number;
  color?: string;
}

export const HistoryIconAlt: FC<Props> = ({
  width,
  height,
  color,
  ...props
}) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.81729 5.86426L6.81641 7.89136C6.81641 7.96833 6.84854 8.0419 6.90569 8.09622L8.43093 9.54608L8.86186 9.13636L7.4259 7.77138L7.42678 5.86426H6.81729Z'
      fill={color}
    />
    <path
      d='M7.25285 3C5.00319 3 3.14132 4.68028 2.82949 6.85715H1.5L3.09801 8.46429L4.69602 6.85715H3.47535C3.78111 5.03585 5.35618 3.64286 7.25285 3.64286C9.3677 3.64286 11.0881 5.37305 11.0881 7.50001C11.0881 9.62697 9.36772 11.3572 7.25285 11.3572C6.06402 11.3572 4.96164 10.816 4.22848 9.87242L3.72473 10.2679C4.57991 11.3684 5.86581 12 7.25285 12C9.72008 12 11.7273 9.98102 11.7273 7.50001C11.7273 5.01899 9.72008 3.00002 7.25285 3Z'
      fill={color}
    />
  </svg>
);

HistoryIconAlt.defaultProps = {
  width: 9,
  height: 11,
  color: '#1F2531',
};
