import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {}

export const TrashIcon: FC<Props> = ({ width, height, fill, ...props }) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.97208 3.31019L8.72878 2.58044C8.63613 2.30251 8.37711 2.11576 8.08437 2.11576H6.03951V1.44961C6.03951 1.11666 5.76897 0.845703 5.4363 0.845703H3.5637C3.23112 0.845703 2.96049 1.11666 2.96049 1.44961V2.11576H0.915714C0.622894 2.11576 0.363871 2.30251 0.271225 2.58044L0.0279234 3.31019C-0.0274794 3.47633 0.000600341 3.6603 0.102915 3.80238C0.205229 3.94446 0.370765 4.02934 0.545801 4.02934H0.800116L1.35986 10.9551C1.40148 11.469 1.8373 11.8716 2.35224 11.8716H6.76134C7.2762 11.8716 7.7121 11.469 7.75363 10.955L8.31338 4.02934H8.4542C8.62923 4.02934 8.79477 3.94446 8.89709 3.80246C8.9994 3.66038 9.02748 3.47633 8.97208 3.31019ZM3.60616 1.49175H5.39384V2.11576H3.60616V1.49175ZM7.11007 10.9029C7.09544 11.0839 6.94226 11.2255 6.76134 11.2255H2.35224C2.17132 11.2255 2.01814 11.0839 2.00351 10.9029L1.44788 4.02934H7.66561L7.11007 10.9029ZM0.684266 3.38329L0.883767 2.78486C0.888307 2.77106 0.90117 2.76181 0.915714 2.76181H8.08437C8.09891 2.76181 8.11169 2.77106 8.11632 2.78486L8.31582 3.38329H0.684266Z"
      fill={fill}
    />
  </svg>
);

TrashIcon.defaultProps = {
  width: 9,
  height: 12,
  fill: 'white',
};
