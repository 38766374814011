
import React, { FC, useState, useEffect, useRef } from 'react';

import Portal from 'components/Hocs/Portal';
import Button from 'components/UI/controls/Button';
import { SmallOptionMenuIcon } from 'components/icons/SmallOptionMenuIcon';

import style from './OptionsMenu.module.scss';
import { DotsIcon } from "../../../icons/DotsIcon";
import { NoteIcon } from "../../../icons/NoteIcon";
import { TrashIconAlt } from "../../../icons/TrashIconAlt";
import { HistoryIconAlt } from "../../../icons/HistoryIconAlt";

interface Props {
  on: {
    edit: () => void;
    delete?: () => void;
    history?: () => void;
  };
  disabled?: boolean;
  small?: boolean;
  card?: boolean;
}

enum Option {
  EDIT = 'edit',
  DELETE = 'delete',
  HISTORY = 'history',
  CLOSE = 'close',
}

function useOutsideAlerter(ref: any, setIsMenuOpen: Function) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export const OptionsMenu: FC<Props> = ({ on, disabled, small, card }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const options: string[] = [];
  if (Boolean(on.edit)) options.push(Option.EDIT);
  if (Boolean(on.delete)) options.push(Option.DELETE);
  if (Boolean(on.history)) options.push(Option.HISTORY);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsMenuOpen);

  const Menu = (): JSX.Element => (
    <div
      className={'option-list ' + style.options}
      style={{ top: card ? '5' : 'auto' }}>
      {options.includes(Option.EDIT) && (
        <div
          className={style.option + " option-menu-item"}
          onClick={() => {
            setIsMenuOpen(false);
            on.edit();
          }}>
          <div className={style.option_icon + " option-menu-item__icon"}>
            <NoteIcon width={13} height={13} style={{ marginBottom: 2 }} />
          </div>
          <span>edit</span>
        </div>
      )}

      {options.includes(Option.DELETE) && (
        <div
          className={style.option + " option-menu-item"}
          onClick={() => {
            setIsMenuOpen(false);
            on.delete && on.delete();
          }}>
          <div className={style.option_icon + " option-menu-item__icon"}>
            <TrashIconAlt width={12} height={14} />
          </div>
          <span>delete</span>
        </div>
      )}

      {options.includes(Option.HISTORY) && (
        <div
          className={style.option + " option-menu-item"}
          onClick={() => {
            setIsMenuOpen(false);
            on.history && on.history();
          }}>
          <div className={style.option_icon + " option-menu-item__icon"}>
            <HistoryIconAlt width={20} height={20} />
          </div>
          <span>history</span>
        </div>
      )}

      <div className={style.option_close} onClick={() => setIsMenuOpen(false)}>
        <div className={style.close_icon}>&times;</div>
        <span>close</span>
      </div>

      <div
        className={style.backdrop_overlay}
        onClick={() => setIsMenuOpen(false)}
      />
    </div>
  );

  return (
    <div
      ref={wrapperRef}
      className={
        "option-menu " +
        (card
          ? `${style.container_card} ${
              isMenuOpen ? '' : style.container_card_close
            }`
          : style.container)
      }>
      {small || card ? (
        ''
      ) : (
        <Button
          className={`${style.toggle_options_menu} ${
            isMenuOpen ? style.toggle_options_menu_active : ''
          }`}
          variant={'toggle'}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          disabled={disabled}
        />
      )}

      {small ? (
        <div
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          style={{ cursor: 'pointer' }}>
          <SmallOptionMenuIcon />
        </div>
      ) : card ? (
        <div
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          style={{ cursor: 'pointer' }}
          className={"option-menu-button" + (isMenuOpen ? " open" : "")}
        >
          <DotsIcon />
        </div>
      ) : (
        <div
          className={style.button__dots}
          onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <div className={style.button__dot} />
          <div className={style.button__dot} />
          <div className={style.button__dot} />
        </div>
      )}

      {isMenuOpen && (
        <>
          <div className={style.options_container_phone}>
            <Portal>
              <Menu />
            </Portal>
          </div>

          <div className={style.options_container}>
            <Menu />
          </div>
        </>
      )}
    </div>
  );
};

export default OptionsMenu;
