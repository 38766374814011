import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const BackIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    {...props}
    width="30" height="30" viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 14.5C19.312 14.5 13.52 14.5 10 14.5M10 14.5L14.32 10M10 14.5L14.32 19"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

BackIcon.defaultProps = {
  color: '#1F2531',
};

export default BackIcon;
