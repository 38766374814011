import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

/* api */
import { EnterpriseApi } from '../controllers/enterprise/transport/enterprise.api';

/* components */
import Link from 'components/UI/controls/Link';
import Loader from './Loader';

/* constants */
import { EntityEnum, TaxonomyTypeSlug } from "@ternala/voltore-types/lib/constants";
import { PageEnum } from 'config/constants';
import { enterprisesTabs, personsTabs } from 'config/constants/tabs';

/* types */
import { EnterpriseFullExpandedDTO } from '@ternala/voltore-types/lib/modules/enterprise/response.dto';
import { getPersonName } from "../utils/getName";
import { getAccessTokenUtil } from "../utils/getAccessToken";

interface Props {
  id: number | null;
}

const CompanyInfo: FC<Props> = ({ id }) => {
  const dispatch = useDispatch();

  const [enterprise, setEnterprise] =
    useState<EnterpriseFullExpandedDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    (async function getEnterprise() {
      if (typeof id !== 'number') return;
      const token = await getAccessTokenUtil(dispatch);

      if (!token) {
        setError('Something goes wrong');
        return;
      }

      const enterprise = await EnterpriseApi.getEnterpriseOwnershipInfo(
        { id },
        token,
      );

      if (typeof enterprise === 'string') {
        setError(enterprise);
        return;
      }

      setEnterprise(enterprise);
      setLoading(false);
    })();
  }, [id]);

  if (error) return <div className="company-info">{error}</div>;

  if (typeof id !== 'number' || !enterprise)
    return (
      <div className="company-info">
        <div className="item-info__loader">
          <Loader />
        </div>
      </div>
    );

  const owners =
    enterprise.involvements?.filter((involvement) => involvement.owner) || [];
  const decisionMakers =
    enterprise.involvements?.filter(
      (involvement) => involvement.decisionMaker,
    ) || [];

  return (
    <div className="company-info">
      {loading ? (
        <div className="item-info__loader">
          <Loader />
        </div>
      ) : (
        <>
          <header className="company-info__header">
            <div>
              <Link
                id={enterprise.id}
                className="company-name"
                type={EntityEnum.enterprise}
                fullPath={`/${PageEnum.ENTERPRISES}/${enterprise.id}/${enterprisesTabs.info.path}`}
                newTabIcon>
                {enterprise.title}
              </Link>
              <div className="company-type">
                {enterprise?.[TaxonomyTypeSlug.enterpriseType]?.title}
              </div>
            </div>
          </header>

          <hr />

          <div className="company-info__stats">
            <div className="stat">
              <div className="stat__number">
                {enterprise?.childrenEnterprises?.length}
              </div>
              <div className="stat__description">Enterprises Owned</div>
            </div>

            <div className="stat">
              <div className="stat__number">
                {enterprise?.ownedProperties?.length}
              </div>
              <div className="stat__description">Properties Owned</div>
            </div>
          </div>

          <hr />

          <div className="company-info__people">
            {Array.isArray(owners) && owners.length > 0 ? (
              <>
                <h4 className="people__heading">Principals</h4>
                <div className="people__owners">
                  {owners.map((owner) => (
                    <div className="person" key={owner.id}>
                      <div className="person__position">
                        {owner[TaxonomyTypeSlug.involvementType].title}
                      </div>
                      <Link
                        id={owner.person?.id}
                        type={EntityEnum.person}
                        fullPath={`/${PageEnum.PEOPLE}/${owner.person?.id}/${personsTabs.info.path}`}
                        newTabIcon>
                        {`${owner.person?.firstName || ''} ${
                          owner.person?.lastName || ''
                        }`}
                      </Link>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <h4 className="people__heading">Principals</h4>
                <div className="">n/a</div>
              </>
            )}

            {Array.isArray(decisionMakers) && decisionMakers.length > 0 ? (
              <>
                <h4 className="people__heading">Decision Makers</h4>
                <div className="people__decision-makers">
                  {decisionMakers.map((decisionMaker) => (
                    <div className="person" key={decisionMaker.id}>
                      <div className="person__position">
                        {decisionMaker[TaxonomyTypeSlug.involvementType].title}
                      </div>
                      <Link
                        id={decisionMaker.person?.id}
                        type={EntityEnum.person}
                        fullPath={`/${PageEnum.PEOPLE}/${decisionMaker.person?.id}/${personsTabs.info.path}`}
                        newTabIcon>
                        {getPersonName(decisionMaker?.person)}
                      </Link>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <h4 className="people__heading">Decision Makers</h4>
                <div className="">n/a</div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyInfo;
