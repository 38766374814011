import React, { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

/* components */
import DataList from 'components/page/DataList';

/* constants */
import {
  Sorting,
  taxonomySortOptions,
  taxonomyTypeSortOptions,
} from 'config/constants/sorting';
import {
  sortType,
  taxonomyTypeSortFields,
} from '@ternala/voltore-types/lib/constants';
import { PageEnum } from 'config/constants';

/* types */
import { IStore } from 'controllers/store';
import { ITaxonomyState } from 'controllers/taxonomy/models';
import { TaxonomyGetListRequest } from '@ternala/voltore-types';
import { ItemsLoadType, OptionType, sortingTypeKeys } from 'models';
import { getTaxonomyTypeListAction } from 'controllers/taxonomy/actions';
import { Helmet } from 'react-helmet';
import { useLocation } from "react-router-dom";

interface Props {
  taxonomyState: ITaxonomyState;
}

export const TaxonomyPage: FC<Props> = (props) => {
  const {
    taxonomyState: { taxonomyTypes },
    children,
  } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [sortValue, setSortValue] = useState<OptionType>(
    taxonomySortOptions[0],
  );
  const [sortingType, setSortingType] = useState<sortType>(sortType.asc);
  const [sortField, setSortField] = useState<
    taxonomyTypeSortFields | undefined
  >(taxonomyTypeSortFields.title);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    loadTaxonomyTypes('start', () => setLoading(false));
  }, [sortingType, sortField]);

  // useEffect(() => {
    // if (searchQuery === '') {
    //   setLoading(true);
    //   loadTaxonomyTypes('start', () => setLoading(false));
    // }
  // }, [searchQuery]);

  useEffect(() => {
    setLoading(true);
    loadTaxonomyTypes('start', () => setLoading(false));
  }, []);

  const loadTaxonomyTypes = (
    loadType: ItemsLoadType = 'start',
    callback?: Function,
  ) => {
    const searchParams: TaxonomyGetListRequest = {
      query: searchQuery,
      sortType: sortingType,
      sortField: sortField,
    };
    dispatch(getTaxonomyTypeListAction.request({ ...searchParams, callback }));
  };

  const onSort = (sortOption: OptionType) => {
    const sorting = Sorting[sortOption.value as sortingTypeKeys];
    setSortValue(sortOption);
    setSortField(sorting.sortField as taxonomyTypeSortFields);
    setSortingType(sorting.sortType);
  };

  const taxonomyGroupsListItems = taxonomyTypes?.map((item) => ({
    image: '',
    id: item?.id,
    tag: item?.entity,
    title: item?.entity
      ?.replace(/([A-Z])/g, ' $1')
      ?.replace(/^./, function (str) {
        return str.toUpperCase();
      }),
    description: item?.title,
  }));

  return (
    <>
      <div className="taxonomy-page">
        <Helmet>
          <title>
            Taxonomy | Voltore
            {process.env.REACT_APP_COMPANY_NAME
              ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        <div className="page-layout__content">
          <DataList
            setIsLoading={setLoading}
            items={taxonomyTypes ? taxonomyGroupsListItems : undefined}
            itemsCount={taxonomyTypes?.length}
            itemsLoading={loading}
            activeTab="type"
            pageTitle={PageEnum.TAXONOMY}
            singleItem="taxonomy-type"
            onLoadMore={loadTaxonomyTypes}
            onSort={onSort}
            sortValue={sortValue}
            sortOptions={taxonomyTypeSortOptions}
            checkSuffixByActive={false}
            listControls={{
              searchPlaceholder: 'Search by Taxonomy Title or Value',
              searchQuery,
              clearSearchQuery: () => setSearchQuery(''),
              onSearch: (query) => setSearchQuery(query),
            }}
          />
          <div className="taxonomy-page__content">
            {pathname === `/${PageEnum.TAXONOMY}` && (
              <div className="empty-container-wrapper">
                <div className="empty-container" />
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((store: IStore) => ({
  taxonomyState: store.taxonomy,
}))(TaxonomyPage);
