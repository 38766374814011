import React, { FC, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

/* components */
import Filters from "components/page/Filters";
import Input from "components/UI/controls/Input";
import InputMinMax from "components/UI/controls/InputMinMax";
import SelectMultiSearch from "components/UI/controls/SelectMultiSearch";
import DatePicker from "components/UI/controls/DatePicker";
import PopupTagGroup from "components/modals/PopupTagGroup";
import PopupTag from "components/modals/PopupTag";

/* controllers */
import { getTaxonomiesByTypeAction } from "controllers/taxonomy/actions";

/* constants & utils */
import { PageEnum, Unit } from "config/constants";
import {
  leaseTransactionStatusOptions,
  loadEnterprisesOptionsByType,
  loadInvolvementsOptionsByType,
  loadTagsOptions,
  saleTransactionStatusOptions
} from "config/constants/select-options";
import {
  EnterpriseTypeByTransaction,
  ExportMainEntityEnum,
  TaxonomyTypeSlug,
  UserTypeEnum
} from "@ternala/voltore-types/lib/constants";
import { mapEnum, numberToString } from "utils/helper-functions";

/* types */
import { IStore } from "controllers/store";
import { ITaxonomyState } from "controllers/taxonomy/models";
import {
  PropertyGetListFiltersByLeaseTransactionExtended,
  PropertyGetListFiltersByPropertyExtended,
  PropertyGetListFiltersBySaleTransactionExtended,
  PropertyGetListFiltersExtended
} from "controllers/property/models";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { IFiltrationTag, OptionType } from "models";
import { TagApi } from "controllers/tag/transport/tag.api";
import TagsContext, { TagTypeEnum } from "context/Tags";
import { getAccessTokenUtil } from "utils";
import ExportTab from "../../components/page/Tabs/Export/ExportTab";
import { PropertyGetListRequest } from "@ternala/voltore-types";

interface Props {
  className?: string;
  taxonomyState: ITaxonomyState;
  appliedFilters?: PropertyGetListFiltersExtended;
  onFiltersUpdated: (filters: PropertyGetListFiltersExtended) => void;
  onClose: () => void;
  isFullScreen?: boolean;
  getFilters: () => PropertyGetListRequest;
  userType?: UserTypeEnum;
}

enum FiltersTab {
  PROPERTY = "Property",
  SALE = "Sale",
  LEASE = "Lease",
  TAG = "Tag",
}

enum AdditionalTabsEnum {
  EXPORT = "Export",
}

const PropertiesPageFilters: FC<Props> = (props) => {
  const {
    taxonomyState: { taxonomies },
    appliedFilters,
    onFiltersUpdated,
    onClose,
    isFullScreen,
    getFilters
    // userType
  } = props;

  const dispatch = useDispatch();

  // Context (filters)
  const tagsFilters = useContext(TagsContext);

  const [activeTab, setActiveTab] = useState<FiltersTab | AdditionalTabsEnum>(
    FiltersTab.PROPERTY
  );
  const [validationError, setValidationError] = useState<boolean>(false);
  /* PROPERTY tab */
  const [searchQuery, setSearchQuery] = useState<string>(
    appliedFilters?.property?.searchQuery || ""
  );
  const [propertySqftMin, setPropertySqftMin] = useState<string>(
    numberToString(appliedFilters?.property?.minSQFT)
  );
  const [propertySqftMax, setPropertySqftMax] = useState<string>(
    numberToString(appliedFilters?.property?.maxSQFT)
  );
  const [lotSqftMin, setLotSqftMin] = useState<string>(
    numberToString(appliedFilters?.property?.minLotSQFT)
  );
  const [lotSqftMax, setLotSqftMax] = useState<string>(
    numberToString(appliedFilters?.property?.maxLotSQFT)
  );
  const [propertyTypes, setPropertyTypes] = useState<OptionType[] | undefined>(
    appliedFilters?.property?.propertyType || []
  );
  const [subPropertyTypes, setSubPropertyTypes] = useState<
    OptionType[] | undefined
  >(appliedFilters?.property?.subPropertyType || []);
  const [secondaryTypes, setSecondaryTypes] = useState<
    OptionType[] | undefined
  >(appliedFilters?.property?.secondaryPropertyType || []);
  const [buildingClasses, setBuildingClasses] = useState<
    OptionType[] | undefined
  >(appliedFilters?.property?.buildingClass || []);

  const [zoning, setZoning] = useState<OptionType[] | undefined>(
    appliedFilters?.property?.zoning || []
  );
  const [yearBuiltFrom, setYearBuiltFrom] = useState<string>(
    numberToString(appliedFilters?.property?.minYearBuild)
  );
  const [yearBuiltTo, setYearBuiltTo] = useState<string>(
    numberToString(appliedFilters?.property?.maxYearBuild)
  );

  /* SALE tab */
  const [saleStatuses, setSaleStatuses] = useState<OptionType[] | undefined>(
    appliedFilters?.sale?.status || []
  );
  const [saleStatusDateFrom, setSaleStatusDateFrom] =
    useState<MaterialUiPickersDate>(
      appliedFilters?.sale?.statusStartDate || null
    );
  const [saleStatusDateTo, setSaleStatusDateTo] =
    useState<MaterialUiPickersDate>(
      appliedFilters?.sale?.statusEndDate || null
    );

  const [sellerEnterprises, setSellerEnterprises] = useState<
    OptionType[] | undefined
  >(appliedFilters?.sale?.seller || []);
  const [involvementsFromSellersSide, setInvolvementsFromSellersSide] =
    useState<OptionType[] | undefined>(
      appliedFilters?.sale?.sellerSideInvolvement || []
    );
  const [buyerEnterprises, setBuyerEnterprises] = useState<
    OptionType[] | undefined
  >(appliedFilters?.sale?.buyer || []);
  const [involvementsFromBuyersSide, setInvolvementsFromBuyersSide] = useState<
    OptionType[] | undefined
  >(appliedFilters?.sale?.buyerSideInvolvement || []);

  const [listPriceMin, setListPriceMin] = useState<string>(
    numberToString(appliedFilters?.sale?.minListPrice)
  );
  const [listPriceMax, setListPriceMax] = useState<string>(
    numberToString(appliedFilters?.sale?.maxListPrice)
  );
  const [listPriceUnit, setListPriceUnit] = useState<Unit>();
  const [soldPriceMin, setSoldPriceMin] = useState<string>(
    numberToString(appliedFilters?.sale?.minSoldPrice)
  );
  const [soldPriceMax, setSoldPriceMax] = useState<string>(
    numberToString(appliedFilters?.sale?.maxSoldPrice)
  );
  const [soldPriceUnit, setSoldPriceUnit] = useState<Unit>();
  const [capRateMin, setCapRateMin] = useState<string>(
    numberToString(appliedFilters?.sale?.minCapRate)
  );
  const [capRateMax, setCapRateMax] = useState<string>(
    numberToString(appliedFilters?.sale?.maxCapRate)
  );
  const [NOIMin, setNOIMin] = useState<string>(
    numberToString(appliedFilters?.sale?.minNOI)
  );
  const [NOIMax, setNOIMax] = useState<string>(
    numberToString(appliedFilters?.sale?.maxNOI)
  );
  const [percentLeasedAtSaleMin, setPercentLeasedAtSaleMin] = useState<string>(
    numberToString(appliedFilters?.sale?.minLeaseAtSale)
  );
  const [percentLeasedAtSaleMax, setPercentLeasedAtSaleMax] = useState<string>(
    numberToString(appliedFilters?.sale?.maxLeaseAtSale)
  );
  const [financing, setFinancing] = useState<OptionType[] | undefined>(
    appliedFilters?.sale?.financing || []
  );
  const [saleType, setSaleType] = useState<OptionType[] | undefined>(
    appliedFilters?.sale?.saleType || []
  );

  /* LEASE tab */
  const [leaseStatuses, setLeaseStatuses] = useState<OptionType[] | undefined>(
    appliedFilters?.lease?.status || []
  );
  const [leaseStatusDateFrom, setLeaseStatusDateFrom] =
    useState<MaterialUiPickersDate>(
      appliedFilters?.lease?.statusStartDate || null
    );
  const [leaseStatusDateTo, setLeaseStatusDateTo] =
    useState<MaterialUiPickersDate>(
      appliedFilters?.lease?.statusEndDate || null
    );
  const [leaseCommencementFrom, setLeaseCommencementFrom] =
    useState<MaterialUiPickersDate>(
      appliedFilters?.lease?.minLeaseCommencement || null
    );
  const [leaseCommencementTo, setLeaseCommencementTo] =
    useState<MaterialUiPickersDate>(
      appliedFilters?.lease?.maxLeaseCommencement || null
    );
  const [leaseExpirationFrom, setLeaseExpirationFrom] =
    useState<MaterialUiPickersDate>(
      appliedFilters?.lease?.minLeaseExpiration || null
    );
  const [leaseExpirationTo, setLeaseExpirationTo] =
    useState<MaterialUiPickersDate>(
      appliedFilters?.lease?.maxLeaseExpiration || null
    );
  const [leasablePropertySqftMin, setLeasablePropertySqftMin] =
    useState<string>(numberToString(appliedFilters?.lease?.minLeasableSQFT));
  const [leasablePropertySqftMax, setLeasablePropertySqftMax] =
    useState<string>(numberToString(appliedFilters?.lease?.maxLeasableSQFT));
  const [leasableLotSqftMin, setLeasableLotSqftMin] = useState<string>(
    numberToString(appliedFilters?.lease?.minLeasableLotSQFT)
  );
  const [leasableLotSqftMax, setLeasableLotSqftMax] = useState<string>(
    numberToString(appliedFilters?.lease?.maxLeasableLotSQFT)
  );

  const [landlordEnterprises, setLandlordEnterprises] = useState<
    OptionType[] | undefined
  >(appliedFilters?.lease?.owner || []);
  const [involvementsFromLandlordsSide, setInvolvementsFromLandlordsSide] =
    useState<OptionType[] | undefined>(
      appliedFilters?.lease?.ownerSideInvolvement || []
    );
  const [tenantEnterprises, setTenantEnterprises] = useState<
    OptionType[] | undefined
  >(appliedFilters?.lease?.tenant || []);
  const [involvementsFromTenantsSide, setInvolvementsFromTenantsSide] =
    useState<OptionType[] | undefined>(
      appliedFilters?.lease?.tenantSideInvolvement || []
    );

  const [offeredLeaseRateMin, setOfferedLeaseRateMin] = useState<string>(
    numberToString(appliedFilters?.lease?.minOfferedRate)
  );
  const [offeredLeaseRateMax, setOfferedLeaseRateMax] = useState<string>(
    numberToString(appliedFilters?.lease?.maxOfferedRate)
  );
  const [actualLeaseRateMin, setActualLeaseRateMin] = useState<string>(
    numberToString(appliedFilters?.lease?.minActualRate)
  );
  const [actualLeaseRateMax, setActualLeaseRateMax] = useState<string>(
    numberToString(appliedFilters?.lease?.maxActualRate)
  );
  const [leaseType, setLeaseType] = useState<OptionType[] | undefined>(
    appliedFilters?.lease?.leaseType || []
  );
  const [leaseExpenseMin, setLeaseExpenseMin] = useState<string>(
    numberToString(appliedFilters?.lease?.minLeaseExpense)
  );
  const [leaseExpenseMax, setLeaseExpenseMax] = useState<string>(
    numberToString(appliedFilters?.lease?.maxLeaseExpense)
  );

  useEffect(() => {
    fetchTaxonomies();
  }, []);

  /* TAG tab */
  const [selectedTags, setSelectedTags] = useState<OptionType[] | undefined>(
    tagsFilters?.tags?.map((tag) => ({
      label: tag?.fullTitle,
      value: tag?.id
    })) || []
  );

  const [excludedTags, setExcludedTags] = useState<OptionType[] | undefined>(
    tagsFilters?.excludedTags?.map((tag) => ({
      label: tag?.fullTitle,
      value: tag?.id
    })) || []);

  useEffect(() => {
    setSelectedTags(
      tagsFilters?.tags?.map((tag) => ({
        label: tag?.fullTitle,
        value: tag?.id
      })) || []
    );
  }, [tagsFilters?.tags]);

  useEffect(() => {
    setExcludedTags(
      tagsFilters?.excludedTags?.map((tag) => ({
        label: tag?.fullTitle,
        value: tag?.id
      })) || []
    );
  }, [tagsFilters?.excludedTags]);

  const [isTagCreationOpen, setIsTagCreationOpen] = useState<boolean>(false);
  const [isTagCategoryCreationOpen, setIsTagCategoryCreationOpen] =
    useState<boolean>(false);

  const fetchTaxonomies = (): void => {
    /* PROPERTY tab */
    dispatch(
      getTaxonomiesByTypeAction.request({
        type: TaxonomyTypeSlug.propertyType
      })
    );
    dispatch(
      getTaxonomiesByTypeAction.request({
        type: TaxonomyTypeSlug.subPropertyType
      })
    );
    dispatch(
      getTaxonomiesByTypeAction.request({
        type: TaxonomyTypeSlug.secondaryPropertyType
      })
    );

    dispatch(
      getTaxonomiesByTypeAction.request({
        type: TaxonomyTypeSlug.buildingClass
      })
    );
    dispatch(
      getTaxonomiesByTypeAction.request({ type: TaxonomyTypeSlug.zoning })
    );
    /* SALE tab */
    dispatch(
      getTaxonomiesByTypeAction.request({ type: TaxonomyTypeSlug.financing })
    );
    dispatch(
      getTaxonomiesByTypeAction.request({ type: TaxonomyTypeSlug.saleType })
    );
    /* LEASE tab */
    dispatch(
      getTaxonomiesByTypeAction.request({ type: TaxonomyTypeSlug.leaseType })
    );
  };

  const clearAllFilters = (): void => {
    clearPropertyTabFilters();
    clearSaleTabFilters();
    clearLeaseTabFilters();
    clearTagTabFilters();

    onFiltersUpdated({
      property: {},
      sale: {},
      lease: {}
      // tag: {},
    });
  };

  const clearPropertyTabFilters = (): void => {
    setSearchQuery("");
    setPropertySqftMin("");
    setPropertySqftMax("");
    setLotSqftMin("");
    setLotSqftMax("");
    setPropertyTypes([]);
    setSubPropertyTypes([]);
    setSecondaryTypes([]);
    setBuildingClasses([]);
    setZoning([]);
    setYearBuiltFrom("");
    setYearBuiltTo("");
  };

  const clearSaleTabFilters = (): void => {
    setSaleStatuses([]);
    setSaleStatusDateFrom(null);
    setSaleStatusDateTo(null);
    setSellerEnterprises([]);
    setInvolvementsFromSellersSide([]);
    setBuyerEnterprises([]);
    setInvolvementsFromBuyersSide([]);
    setListPriceMin("");
    setListPriceMax("");
    setSoldPriceMin("");
    setSoldPriceMax("");
    setCapRateMin("");
    setCapRateMax("");
    setNOIMin("");
    setNOIMax("");
    setPercentLeasedAtSaleMin("");
    setPercentLeasedAtSaleMax("");
    setFinancing([]);
    setSaleType([]);
  };

  const clearLeaseTabFilters = (): void => {
    setLeaseStatuses([]);
    setLeaseStatusDateFrom(null);
    setLeaseStatusDateTo(null);
    setLeaseCommencementFrom(null);
    setLeaseCommencementTo(null);
    setLeaseExpirationFrom(null);
    setLeaseExpirationTo(null);
    setLeasablePropertySqftMin("");
    setLeasablePropertySqftMax("");
    setLeasableLotSqftMin("");
    setLeasableLotSqftMax("");
    setLandlordEnterprises([]);
    setInvolvementsFromLandlordsSide([]);
    setTenantEnterprises([]);
    setInvolvementsFromTenantsSide([]);
    setOfferedLeaseRateMin("");
    setOfferedLeaseRateMax("");
    setActualLeaseRateMin("");
    setActualLeaseRateMax("");
    setLeaseType([]);
    setLeaseExpenseMin("");
    setLeaseExpenseMax("");
  };

  const clearTagTabFilters = (): void => {
    tagsFilters.removeTags();
    tagsFilters.removeTags(() => {
    }, TagTypeEnum.ExcludedTags);
  };

  const applyFilters = (): void => {
    const formatTags: IFiltrationTag[] | undefined = selectedTags?.map((tag) => {
      const newTag: IFiltrationTag = {
        fullTitle: String(tag.label),
        id: Number(tag.value)
      };
      return newTag;
    });

    const excludedTagsFormat: IFiltrationTag[] | undefined = excludedTags?.map((tag) => {
      const newTag: IFiltrationTag = {
        fullTitle: String(tag.label),
        id: Number(tag.value)
      };
      return newTag;
    });

    if (formatTags !== tagsFilters?.tags)
      tagsFilters.setTags(formatTags as IFiltrationTag[]);

    if (excludedTagsFormat !== tagsFilters?.excludedTags)
      tagsFilters.setTags(excludedTagsFormat as IFiltrationTag[], TagTypeEnum.ExcludedTags);


    const appliedFilters: PropertyGetListFiltersExtended = {
      property: applyPropertyFilters(),
      sale: applySaleFilters(),
      lease: applyLeaseFilters(),
      selectedTags: formatTags,
      excludedTags: excludedTagsFormat
    };

    onFiltersUpdated(appliedFilters);
    if (!isFullScreen) onClose();
  };

  // const prepareFilters = () => {
  //   const formatTags: IFiltrationTag[] | undefined = tags?.map((tag) => {
  //     const newTag: IFiltrationTag = {
  //       fullTitle: String(tag.label),
  //       id: Number(tag.value),
  //     };
  //     return newTag;
  //   });
  //   if (formatTags !== tagsFilters?.tags)
  //     tagsFilters.addTags(formatTags as IFiltrationTag[]);
  //   return {
  //     property: {
  //       property: applyPropertyFilters(),
  //       sale: applySaleFilters(),
  //       lease: applyLeaseFilters(),
  //       selectedTags: tagsFilters?.tags,
  //     } as PropertyGetListFiltersExtended,
  //   };
  // };

  const applyPropertyFilters = (): PropertyGetListFiltersByPropertyExtended => {
    const propertyFilters: PropertyGetListFiltersByPropertyExtended = {};

    /* input filters */
    if (searchQuery) propertyFilters.searchQuery = searchQuery;
    if (propertySqftMin) propertyFilters.minSQFT = Number(propertySqftMin);
    if (propertySqftMax) propertyFilters.maxSQFT = Number(propertySqftMax);
    if (lotSqftMin) propertyFilters.minLotSQFT = Number(lotSqftMin);
    if (lotSqftMax) propertyFilters.maxLotSQFT = Number(lotSqftMax);
    if (yearBuiltFrom) propertyFilters.minYearBuild = Number(yearBuiltFrom);
    if (yearBuiltTo) propertyFilters.maxYearBuild = Number(yearBuiltTo);

    /* taxonomy filters */
    if (propertyTypes?.length) propertyFilters.propertyType = propertyTypes;
    if (subPropertyTypes?.length)
      propertyFilters.subPropertyType = subPropertyTypes;
    if (secondaryTypes?.length)
      propertyFilters.secondaryPropertyType = secondaryTypes;
    if (buildingClasses?.length)
      propertyFilters.buildingClass = buildingClasses;
    if (zoning?.length) propertyFilters.zoning = zoning;

    return propertyFilters;
  };

  const applySaleFilters =
    (): PropertyGetListFiltersBySaleTransactionExtended => {
      const saleFilters: PropertyGetListFiltersBySaleTransactionExtended = {};

      if (saleStatuses?.length) saleFilters.status = saleStatuses;
      if (saleStatusDateFrom) saleFilters.statusStartDate = saleStatusDateFrom;
      if (saleStatusDateTo) saleFilters.statusEndDate = saleStatusDateTo;

      /* enterprises & involvements */
      if (sellerEnterprises?.length) saleFilters.seller = sellerEnterprises;
      if (involvementsFromSellersSide?.length)
        saleFilters.sellerSideInvolvement = involvementsFromSellersSide;
      if (buyerEnterprises?.length) saleFilters.buyer = buyerEnterprises;
      if (involvementsFromBuyersSide?.length)
        saleFilters.buyerSideInvolvement = involvementsFromBuyersSide;

      /* input filters */
      if (listPriceUnit) saleFilters.listPriceUnit = listPriceUnit;
      if (soldPriceUnit) saleFilters.soldPriceUnit = soldPriceUnit;
      if (listPriceMin) saleFilters.minListPrice = Number(listPriceMin);
      if (listPriceMax) saleFilters.maxListPrice = Number(listPriceMax);
      if (soldPriceMin) saleFilters.minSoldPrice = Number(soldPriceMin);
      if (soldPriceMax) saleFilters.maxSoldPrice = Number(soldPriceMax);
      if (capRateMin) saleFilters.minCapRate = Number(capRateMin);
      if (capRateMax) saleFilters.maxCapRate = Number(capRateMax);
      if (NOIMin) saleFilters.minNOI = Number(NOIMin);
      if (NOIMax) saleFilters.maxNOI = Number(NOIMax);
      if (percentLeasedAtSaleMin)
        saleFilters.minLeaseAtSale = Number(percentLeasedAtSaleMin);
      if (percentLeasedAtSaleMax)
        saleFilters.maxLeaseAtSale = Number(percentLeasedAtSaleMax);

      /* taxonomy */
      if (financing?.length) saleFilters.financing = financing;
      if (saleType?.length) saleFilters.saleType = saleType;

      return saleFilters;
    };

  const applyLeaseFilters =
    (): PropertyGetListFiltersByLeaseTransactionExtended => {
      const leaseFilters: PropertyGetListFiltersByLeaseTransactionExtended = {};

      if (leaseStatuses?.length) leaseFilters.status = leaseStatuses;
      if (leaseStatusDateFrom)
        leaseFilters.statusStartDate = leaseStatusDateFrom;
      if (leaseStatusDateTo) leaseFilters.statusEndDate = leaseStatusDateTo;
      if (leaseCommencementFrom)
        leaseFilters.minLeaseCommencement = leaseCommencementFrom;
      if (leaseCommencementTo)
        leaseFilters.maxLeaseCommencement = leaseCommencementTo;
      if (leaseExpirationFrom)
        leaseFilters.minLeaseExpiration = leaseExpirationFrom;
      if (leaseExpirationTo)
        leaseFilters.maxLeaseExpiration = leaseExpirationTo;
      if (leasableLotSqftMin)
        leaseFilters.minLeasableLotSQFT = Number(leasableLotSqftMin);
      if (leasableLotSqftMax)
        leaseFilters.maxLeasableLotSQFT = Number(leasableLotSqftMax);
      if (leasablePropertySqftMin)
        leaseFilters.minLeasableSQFT = Number(leasablePropertySqftMin);
      if (leasablePropertySqftMax)
        leaseFilters.maxLeasableSQFT = Number(leasablePropertySqftMax);
      if (offeredLeaseRateMin)
        leaseFilters.minOfferedRate = Number(offeredLeaseRateMin);
      if (offeredLeaseRateMax)
        leaseFilters.maxOfferedRate = Number(offeredLeaseRateMax);
      if (actualLeaseRateMin)
        leaseFilters.minActualRate = Number(actualLeaseRateMin);
      if (actualLeaseRateMax)
        leaseFilters.maxActualRate = Number(actualLeaseRateMax);
      if (leaseExpenseMin)
        leaseFilters.minLeaseExpense = Number(leaseExpenseMin);
      if (leaseExpenseMax)
        leaseFilters.maxLeaseExpense = Number(leaseExpenseMax);

      if (leaseType?.length) leaseFilters.leaseType = leaseType;
      if (landlordEnterprises?.length) leaseFilters.owner = landlordEnterprises;
      if (involvementsFromLandlordsSide?.length)
        leaseFilters.ownerSideInvolvement = involvementsFromLandlordsSide;
      if (tenantEnterprises?.length) leaseFilters.tenant = tenantEnterprises;
      if (involvementsFromTenantsSide?.length)
        leaseFilters.tenantSideInvolvement = involvementsFromTenantsSide;

      return leaseFilters;
    };

  const propertiesFiltersTabs = mapEnum(FiltersTab, (tab: FiltersTab) => ({
    label: tab,
    onClick: () => setActiveTab(tab)
  }));

  const rightTabs = mapEnum(AdditionalTabsEnum, (tab: FiltersTab) => ({
    label: tab,
    onClick: () => setActiveTab(tab)
  }));

  return (
    <Filters
      className={props.className}
      page={PageEnum.PROPERTIES}
      tabs={propertiesFiltersTabs}
      rightTabs={rightTabs}
      activeTab={activeTab}
      onClear={clearAllFilters}
      onApply={applyFilters}
      onClose={onClose}
      disableApply={validationError}
      isFullScreen={isFullScreen}>
      {activeTab === FiltersTab.PROPERTY && (
        <>
          {isFullScreen && (
            <Input
              label="search"
              placeholder="Search by Area, Address, Property Name, Parcel #"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              width="100%"
              darkTheme
              search
            />
          )}

          <InputMinMax
            label="Building SQFT"
            Min={{
              placeholder: "Min",
              value: propertySqftMin,
              onChange: (sqft) => setPropertySqftMin(sqft)
            }}
            Max={{
              placeholder: "Max",
              value: propertySqftMax,
              onChange: (sqft) => setPropertySqftMax(sqft)
            }}
            onError={(hasError) => setValidationError(hasError)}
            darkTheme
          />

          <InputMinMax
            label="lot sqft"
            Min={{
              placeholder: "Min",
              value: lotSqftMin,
              onChange: (sqft) => setLotSqftMin(sqft)
            }}
            Max={{
              placeholder: "Max",
              value: lotSqftMax,
              onChange: (sqft) => setLotSqftMax(sqft)
            }}
            units={[
              { type: Unit.SQFT, label: "SQFT" },
              { type: Unit.ACRES, label: "Acres" }
            ]}
            returnValueIn={Unit.SQFT}
            onError={(hasError) => setValidationError(hasError)}
            darkTheme
          />

          <SelectMultiSearch
            label="property type"
            selectedOptions={propertyTypes}
            options={taxonomies?.[TaxonomyTypeSlug.propertyType]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id
              })
            )}
            onChange={(data) => setPropertyTypes(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="sub-property type"
            selectedOptions={subPropertyTypes}
            options={taxonomies?.[TaxonomyTypeSlug.subPropertyType]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id
              })
            )}
            onChange={(data) => setSubPropertyTypes(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="secondary type"
            selectedOptions={secondaryTypes}
            options={taxonomies?.[TaxonomyTypeSlug.secondaryPropertyType]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id
              })
            )}
            onChange={(data) => setSecondaryTypes(data)}
            width="100%"
            darkTheme
          />

          <div className="filters__controls-container">
            <SelectMultiSearch
              label="building class"
              selectedOptions={buildingClasses}
              options={taxonomies?.[TaxonomyTypeSlug.buildingClass]?.map(
                (taxonomy) => ({
                  label: taxonomy.title,
                  value: taxonomy.id
                })
              )}
              onChange={(data) => setBuildingClasses(data)}
              width="50%"
              darkTheme
            />

            <SelectMultiSearch
              label="zoning"
              selectedOptions={zoning}
              options={taxonomies?.[TaxonomyTypeSlug.zoning]?.map(
                (taxonomy) => ({
                  label: taxonomy.title,
                  value: taxonomy.id
                })
              )}
              maxOptions={5}
              onChange={(data) => setZoning(data)}
              width={document.body.clientWidth > 1024 ? 185 : "49.5%"}
              darkTheme
            />
          </div>

          <InputMinMax
            label="year built"
            Min={{
              placeholder: "From",
              value: yearBuiltFrom,
              onChange: (year) => setYearBuiltFrom(year)
            }}
            Max={{
              placeholder: "To",
              value: yearBuiltTo,
              onChange: (year) => setYearBuiltTo(year)
            }}
            onError={(hasError) => setValidationError(hasError)}
            max={10000}
            containerStyle={{ marginBottom: 80 }}
            isYearValue
            upperCaseLabel
            darkTheme
          />
        </>
      )}

      {activeTab === FiltersTab.SALE && (
        <>
          <SelectMultiSearch
            label="status"
            selectedOptions={saleStatuses}
            options={saleTransactionStatusOptions}
            onChange={(data) => setSaleStatuses(data)}
            width="100%"
            darkTheme
          />

          <div
            className="filters__controls-container_with-separator"
            style={{ marginBottom: 15 }}>
            <DatePicker
              className="filters__date-picker"
              containerClass="filters__date-picker-container"
              label="status date"
              placeholder="From"
              value={saleStatusDateFrom}
              onChange={(date: MaterialUiPickersDate) =>
                setSaleStatusDateFrom(date)
              }
              darkTheme
            />

            <DatePicker
              className="filters__date-picker"
              containerClass="filters__date-picker-container"
              placeholder="To"
              value={saleStatusDateTo}
              onChange={(date: MaterialUiPickersDate) =>
                setSaleStatusDateTo(date)
              }
              darkTheme
            />
          </div>

          <SelectMultiSearch
            label="seller enterprise"
            selectedOptions={sellerEnterprises}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadEnterprisesOptionsByType(
                  EnterpriseTypeByTransaction.seller,
                  searchParams,
                  token
                );
              }
              return [];
            }}
            onChange={(data) => setSellerEnterprises(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="people from seller's side &amp; their involvement"
            placeholder="Select one or multiple, search by involvement, name, phone, email"
            selectedOptions={involvementsFromSellersSide}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadInvolvementsOptionsByType(
                  EnterpriseTypeByTransaction.seller,
                  searchParams,
                  token
                );
              }
              return [];
            }}
            searchByFields={["label", "phones", "emails"]}
            onChange={(data) => setInvolvementsFromSellersSide(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="buyer enterprise"
            selectedOptions={buyerEnterprises}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadEnterprisesOptionsByType(
                  EnterpriseTypeByTransaction.buyer,
                  searchParams,
                  token
                );
              }
              return [];
            }}
            onChange={(data) => setBuyerEnterprises(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="people from buyer's side &amp; their involvement"
            placeholder="Select one or multiple, search by involvement, name, phone, email"
            selectedOptions={involvementsFromBuyersSide}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadInvolvementsOptionsByType(
                  EnterpriseTypeByTransaction.buyer,
                  searchParams,
                  token
                );
              }
              return [];
            }}
            searchByFields={["label", "phones", "emails"]}
            onChange={(data) => setInvolvementsFromBuyersSide(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="financing"
            selectedOptions={financing}
            options={taxonomies?.[TaxonomyTypeSlug.financing]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id
              })
            )}
            onChange={(data) => setFinancing(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="sale type"
            selectedOptions={saleType}
            options={taxonomies?.[TaxonomyTypeSlug.saleType]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id
              })
            )}
            onChange={(data) => setSaleType(data)}
            width="100%"
            darkTheme
            containerStyle={{ marginBottom: 35 }}
          />

          <InputMinMax
            label="list price"
            Min={{
              placeholder: "Min",
              value: listPriceMin,
              onChange: (sqft) => setListPriceMin(sqft)
            }}
            Max={{
              placeholder: "Max",
              value: listPriceMax,
              onChange: (sqft) => setListPriceMax(sqft)
            }}
            units={[
              { type: Unit.PRICE, label: "Price" },
              { type: Unit.PRICE_PER_SQFT, label: "Price/SQFT" }
            ]}
            onUnitChange={(unit) => setListPriceUnit(unit)}
            onError={(hasError) => setValidationError(hasError)}
            containerStyle={{ marginTop: 25 }}
            darkTheme
          />

          <InputMinMax
            label="sold price"
            Min={{
              placeholder: "Min",
              value: soldPriceMin,
              onChange: (sqft) => setSoldPriceMin(sqft)
            }}
            Max={{
              placeholder: "Max",
              value: soldPriceMax,
              onChange: (sqft) => setSoldPriceMax(sqft)
            }}
            units={[
              { type: Unit.PRICE, label: "Price" },
              { type: Unit.PRICE_PER_SQFT, label: "Price/SQFT" }
            ]}
            onUnitChange={(unit) => setSoldPriceUnit(unit)}
            onError={(hasError) => setValidationError(hasError)}
            containerStyle={{ marginBottom: 35 }}
            darkTheme
          />

          <InputMinMax
            label="cap rate"
            Min={{
              placeholder: "Min",
              value: capRateMin,
              onChange: (value) => setCapRateMin(value)
            }}
            Max={{
              placeholder: "Max",
              value: capRateMax,
              onChange: (value) => setCapRateMax(value)
            }}
            onError={(hasError) => setValidationError(hasError)}
            darkTheme
          />

          <InputMinMax
            label="noi"
            Min={{
              placeholder: "Min",
              value: NOIMin,
              onChange: (value) => setNOIMin(value)
            }}
            Max={{
              placeholder: "Max",
              value: NOIMax,
              onChange: (value) => setNOIMax(value)
            }}
            onError={(hasError) => setValidationError(hasError)}
            darkTheme
          />

          <InputMinMax
            label="% leased at sale"
            Min={{
              placeholder: "Min",
              value: percentLeasedAtSaleMin,
              onChange: (value) => setPercentLeasedAtSaleMin(value)
            }}
            Max={{
              placeholder: "Max",
              value: percentLeasedAtSaleMax,
              onChange: (value) => setPercentLeasedAtSaleMax(value)
            }}
            onError={(hasError) => setValidationError(hasError)}
            containerStyle={{ marginBottom: 80 }}
            darkTheme
          />
        </>
      )}

      {activeTab === FiltersTab.LEASE && (
        <>
          <SelectMultiSearch
            label="status"
            selectedOptions={leaseStatuses}
            options={leaseTransactionStatusOptions}
            onChange={(data) => setLeaseStatuses(data)}
            width="100%"
            darkTheme
          />

          <div className="filters__controls-container_with-separator">
            <DatePicker
              className="filters__date-picker"
              containerClass="filters__date-picker-container"
              label="status date"
              placeholder="From"
              value={leaseStatusDateFrom}
              onChange={(date: MaterialUiPickersDate) =>
                setLeaseStatusDateFrom(date)
              }
              darkTheme
            />

            <DatePicker
              className="filters__date-picker"
              containerClass="filters__date-picker-container"
              placeholder="To"
              value={leaseStatusDateTo}
              onChange={(date: MaterialUiPickersDate) =>
                setLeaseStatusDateTo(date)
              }
              darkTheme
            />
          </div>

          <div className="filters__controls-container_with-separator">
            <DatePicker
              className="filters__date-picker"
              containerClass="filters__date-picker-container"
              label="lease commencement"
              placeholder="From"
              value={leaseCommencementFrom}
              onChange={(date: MaterialUiPickersDate) =>
                setLeaseCommencementFrom(date)
              }
              darkTheme
            />

            <DatePicker
              className="filters__date-picker"
              containerClass="filters__date-picker-container"
              placeholder="To"
              value={leaseCommencementTo}
              onChange={(date: MaterialUiPickersDate) =>
                setLeaseCommencementTo(date)
              }
              darkTheme
            />
          </div>

          <div
            className="filters__controls-container_with-separator"
            style={{ marginBottom: 15 }}>
            <DatePicker
              className="filters__date-picker"
              containerClass="filters__date-picker-container"
              label="lease expiration"
              placeholder="From"
              value={leaseExpirationFrom}
              onChange={(date: MaterialUiPickersDate) =>
                setLeaseExpirationFrom(date)
              }
              darkTheme
            />

            <DatePicker
              className="filters__date-picker"
              containerClass="filters__date-picker-container"
              placeholder="To"
              value={leaseExpirationTo}
              onChange={(date: MaterialUiPickersDate) =>
                setLeaseExpirationTo(date)
              }
              darkTheme
            />
          </div>

          <InputMinMax
            label="leasable Building SQFT"
            Min={{
              placeholder: "Min",
              value: leasablePropertySqftMin,
              onChange: (price) => setLeasablePropertySqftMin(price)
            }}
            Max={{
              placeholder: "Max",
              value: leasablePropertySqftMax,
              onChange: (price) => setLeasablePropertySqftMax(price)
            }}
            onError={(hasError) => setValidationError(hasError)}
            darkTheme
          />

          <InputMinMax
            label="leasable lot sqft"
            Min={{
              placeholder: "Min",
              value: leasableLotSqftMin,
              onChange: (price) => setLeasableLotSqftMin(price)
            }}
            Max={{
              placeholder: "Max",
              value: leasableLotSqftMax,
              onChange: (price) => setLeasableLotSqftMax(price)
            }}
            onError={(hasError) => setValidationError(hasError)}
            darkTheme
          />

          <SelectMultiSearch
            label="landlord"
            selectedOptions={landlordEnterprises}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadEnterprisesOptionsByType(
                  EnterpriseTypeByTransaction.owner,
                  searchParams,
                  token
                );
              }
              return [];
            }}
            onChange={(data) => setLandlordEnterprises(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="people from landlord's side &amp; their involvement"
            placeholder="Select one or multiple, search by involvement, name, phone, email"
            selectedOptions={involvementsFromLandlordsSide}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadInvolvementsOptionsByType(
                  EnterpriseTypeByTransaction.owner,
                  searchParams,
                  token
                );
              }
              return [];
            }}
            searchByFields={["label", "phones", "emails"]}
            onChange={(data) => setInvolvementsFromLandlordsSide(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="tenant"
            selectedOptions={tenantEnterprises}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadEnterprisesOptionsByType(
                  EnterpriseTypeByTransaction.tenant,
                  searchParams,
                  token
                );
              }
              return [];
            }}
            onChange={(data) => setTenantEnterprises(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="people from tenant's side &amp; their involvement"
            placeholder="Select one or multiple, search by involvement, name, phone, email"
            selectedOptions={involvementsFromTenantsSide}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadInvolvementsOptionsByType(
                  EnterpriseTypeByTransaction.tenant,
                  searchParams,
                  token
                );
              }
              return [];
            }}
            searchByFields={["label", "phones", "emails"]}
            onChange={(data) => setInvolvementsFromTenantsSide(data)}
            width="100%"
            darkTheme
          />

          <InputMinMax
            label="offered lease rate"
            Min={{
              placeholder: "Min",
              value: offeredLeaseRateMin,
              onChange: (rate) => setOfferedLeaseRateMin(rate)
            }}
            Max={{
              placeholder: "Max",
              value: offeredLeaseRateMax,
              onChange: (rate) => setOfferedLeaseRateMax(rate)
            }}
            units={[
              { type: Unit.MONTHLY_LEASE_RATE, label: "Monthly/SQFT" },
              { type: Unit.ANNUAL_LEASE_RATE, label: "Annual/SQFT" }
            ]}
            returnValueIn={Unit.MONTHLY_LEASE_RATE}
            onError={(hasError) => setValidationError(hasError)}
            darkTheme
          />

          <InputMinMax
            label="actual lease rate"
            Min={{
              placeholder: "Min",
              value: actualLeaseRateMin,
              onChange: (rate) => setActualLeaseRateMin(rate)
            }}
            Max={{
              placeholder: "Max",
              value: actualLeaseRateMax,
              onChange: (rate) => setActualLeaseRateMax(rate)
            }}
            units={[
              { type: Unit.MONTHLY_LEASE_RATE, label: "Monthly/SQFT" },
              { type: Unit.ANNUAL_LEASE_RATE, label: "Annual/SQFT" }
            ]}
            returnValueIn={Unit.MONTHLY_LEASE_RATE}
            onError={(hasError) => setValidationError(hasError)}
            containerStyle={{ marginBottom: 35 }}
            darkTheme
          />

          <SelectMultiSearch
            label="lease type"
            selectedOptions={leaseType}
            options={taxonomies?.[TaxonomyTypeSlug.leaseType]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id
              })
            )}
            onChange={(data) => setLeaseType(data)}
            width="100%"
            darkTheme
          />

          <InputMinMax
            label="lease expense"
            Min={{
              placeholder: "Min",
              value: leaseExpenseMin,
              onChange: (price) => setLeaseExpenseMin(price)
            }}
            Max={{
              placeholder: "Max",
              value: leaseExpenseMax,
              onChange: (price) => setLeaseExpenseMax(price)
            }}
            onError={(hasError) => setValidationError(hasError)}
            containerStyle={{ marginBottom: 80 }}
            upperCaseLabel
            darkTheme
          />
        </>
      )}
      {activeTab === FiltersTab.TAG && (
        <>
          <SelectMultiSearch
            label="tag(s)"
            selectedOptions={selectedTags}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadTagsOptions(searchParams, token);
              }
              return [];
            }}
            creation={{
              label: "Create new tag",
              onClick: () => {
                setIsTagCreationOpen(true);
              }
            }}
            onChange={(data) => setSelectedTags(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="exclude tag(s)"
            selectedOptions={excludedTags}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadTagsOptions(searchParams, token);
              }
              return [];
            }}
            creation={{
              label: "Create new tag",
              onClick: () => {
                setIsTagCreationOpen(true);
              }
            }}
            onChange={(data) => {
              setExcludedTags(data);
            }}
            width="100%"
            darkTheme
          />

          {isTagCreationOpen ? (
            <PopupTag
              setGroupCreation={setIsTagCategoryCreationOpen}
              onClose={() => {
                setIsTagCreationOpen(false);
              }}
              onCreate={async function(data) {
                const token = await getAccessTokenUtil(dispatch);
                if (token) await TagApi.createTag(data, token);
              }}
            />
          ) : (
            ""
          )}
          {isTagCategoryCreationOpen ? (
            <PopupTagGroup
              onClose={() => {
                setIsTagCategoryCreationOpen(false);
              }}
              onCreate={async function(data) {
                const token = await getAccessTokenUtil(dispatch);
                if (token) await TagApi.createTagCategory(data, token);
              }}
            />
          ) : (
            ""
          )}
        </>
      )}

      {activeTab === AdditionalTabsEnum.EXPORT && (
        <ExportTab
          entityType={ExportMainEntityEnum.property}
          getFilters={() => {
            return {
              property: getFilters() as unknown as PropertyGetListFiltersExtended
            };
          }}
        />
      )}
    </Filters>
  );
};

export default connect((store: IStore) => ({
  taxonomyState: store.taxonomy,
  userType: store.auth.account?.type.slug
}))(PropertiesPageFilters);
