/* eslint-disable */ 
import moment from 'moment';
import {
  IAdditionalTime,
  IDate,
  meridiemType
} from "../model.d";
import { CardActionEnum, CardEntityEnum } from "@ternala/voltore-types/lib/constants";

export const mentionSymbol = '@';
export const controlSymbol = '/';

export const startPhrase = '/Remind:';
export const roleSymbol = '\uFEFF';
export const roleDelimerSymbol = '\u2060';
export const timeSymbol = '\u200D ';
export const dateTimeDelimer = ', ';

export const defaultDate = moment().add(1, 'day').toDate();

//  /^((0?[1-9]|1[012])|(0?[1-9]|1[012])[\/\-]|(0?[1-9]|1[012])[\/\-](0)|(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])|(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]|(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-][0-9]{0,4}|(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-][0-9]{0,4},)$/;

export const dateRegex =
  /^((0?[1-9]|1[012])|(0?[1-9]|1[012])[/-]|(0?[1-9]|1[012])[/-](0)|(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])|(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-]|(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-][0-9]{0,4}|(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-][0-9]{0,4},)$/;
export const timeRegex =
  /^((1[0-2]|0?[1-9])|((1[0-2]|0?[1-9]):)|((1[0-2]|0?[1-9]):([0-5][0-9]))|((1[0-2]|0?[1-9]):([0-5][0-9]) )|((1[0-2]|0?[1-9]):([0-5]))|((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp]))|((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm])))$/;
export const timeAllowedSymbols = [
  'Digit0',
  'Digit1',
  'Digit2',
  'Digit3',
  'Digit4',
  'Digit5',
  'Digit6',
  'Digit7',
  'Digit9',
  'Slash',
  'Space',
  'Comma',
];

export const limitMention = 30;

export const additionalTime: IAdditionalTime[] = [
  {
    id: 1,
    period: 'custom',
  },
  {
    id: 2,
    term: 1,
    period: 'week',
  },
  {
    id: 3,
    term: 2,
    period: 'week',
  },
  {
    id: 4,
    term: 1,
    period: 'month',
  },
  {
    id: 5,
    term: 3,
    period: 'month',
  },
  {
    id: 6,
    term: 6,
    period: 'month',
  },
];

export const parseDate = (str: string): IDate => {
  const [date, timeWithTz] = str
    .replace(', ', ' ')
    .replace(' ', '_')
    .split('_');
  const [month, day, year] = date ? date.split('/') : [];
  const [time, meridiem] = timeWithTz ? timeWithTz.split(' ') : [];
  const [hour, minute] = time ? time.split(':') : [];
  return {
    month: month ? Number(month) : undefined,
    day: day ? Number(day) : undefined,
    year: year ? Number(year) : undefined,
    hour: hour ? Number(hour) : undefined,
    minute: minute ? Number(minute) : undefined,
    meridiem: meridiem as meridiemType,
  };
};

export const generateStartPhrase = () => "/Tag:"

export const entitySymbol = '\uFEFF';
export const tagSymbol = '\u2060';

export const styleMap = {
  CREATE_ENTITY: {
    color: '#4265AB',
  },
};

export const mentionActions = [
  CardActionEnum.MENTION_PROPERTY,
  CardActionEnum.MENTION_USER_ROLE,
  CardActionEnum.MENTION_INVOLVEMENT,
  CardActionEnum.MENTION_SALE_TRANSACTION,
  CardActionEnum.MENTION_LEASE_TRANSACTION,
  CardActionEnum.MENTION_ENTERPRISE,
]

export const mentionEntityOrder = [
  CardEntityEnum.Property,
  CardEntityEnum.Sale_Transaction,
  CardEntityEnum.Lease_Transaction,
  CardEntityEnum.Enterprise,
  CardEntityEnum.Person,
  CardEntityEnum.Involvement,
  CardEntityEnum.Role,
]