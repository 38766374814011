import React, { FC, ComponentProps } from 'react';

interface Props extends ComponentProps<'svg'> {
  color?: string;
}

export const TagFilterIcon: FC<Props> = ({ color, ...props }) => (
  <svg
    width="9"
    height="10"
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.97398 0.564431C8.92895 0.458313 8.83282 0.390625 8.72724 0.390625H0.272787C0.167206 0.390625 0.0711182 0.458313 0.0260564 0.564431C-0.0189691 0.670549 -0.00485773 0.795983 0.0623168 0.886503L3.31233 5.26636V9.30499C3.31233 9.40986 3.3611 9.50725 3.44126 9.56253C3.4852 9.5928 3.53506 9.60807 3.5851 9.60807C3.62646 9.60807 3.66795 9.59761 3.70621 9.57655L5.53226 8.57094C5.62503 8.51986 5.68373 8.41483 5.68388 8.29978L5.68744 5.26648L8.93764 0.886463C9.00485 0.795983 9.019 0.670509 8.97398 0.564431ZM5.20438 4.9647C5.1642 5.01885 5.14216 5.08686 5.14208 5.15709L5.13859 8.11123L3.85791 8.81651V5.1575C3.85791 5.08714 3.83591 5.01897 3.79561 4.9647L0.851208 0.996743H8.14879L5.20438 4.9647Z"
      fill="#1F2531"
    />
  </svg>
);

TagFilterIcon.defaultProps = {
  color: '#1F2531',
};

export default TagFilterIcon;
