import React, { FC } from 'react';

export const TagAddButtonActive: FC = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginBottom: '-2px' }}>
    <circle cx="9.5" cy="9.5" r="9" fill="white" stroke="#1F2531" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.46406 5C9.69878 5 9.88906 5.19028 9.88906 5.425V13.5C9.88906 13.7347 9.69878 13.925 9.46406 13.925C9.22934 13.925 9.03906 13.7347 9.03906 13.5V5.425C9.03906 5.19028 9.22934 5 9.46406 5Z"
      fill="#1F2531"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9258 9.46211C13.9258 9.69683 13.7355 9.88711 13.5008 9.88711L5.42578 9.88711C5.19106 9.88711 5.00078 9.69683 5.00078 9.46211C5.00078 9.22739 5.19106 9.03711 5.42578 9.03711L13.5008 9.03711C13.7355 9.03711 13.9258 9.22739 13.9258 9.46211Z"
      fill="#1F2531"
    />
  </svg>
);

export default TagAddButtonActive;
