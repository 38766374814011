import React, { FC } from 'react';
import Moment from 'react-moment';

/* constants & utils */
import { DATE_FORMAT } from 'config';
import { SaleTransactionStatusEnum } from '@ternala/voltore-types/lib/constants';
import {
  withThousandsSeparators,
  formattedDate,
  transactionItemDateDiff,
} from 'utils/helper-functions';

/* types */
import { CreateSaleStatusDTO } from '@ternala/voltore-types';
import { DotIcon } from 'components/icons/DotIcon';

interface Props {
  data: {
    buyer?: string;
    sqft?: number;
    price?: number;
    statuses?: CreateSaleStatusDTO[];
    activeStatus?: CreateSaleStatusDTO;
  };
}

const SaleTransactionItem: FC<Props> = (props) => {
  const {
    data: { buyer, sqft, price, statuses, activeStatus },
  } = props;

  const prevStatus = statuses?.find(
    (item) => item.status === SaleTransactionStatusEnum.Active,
  );

  const currStatus = {
    status:
      activeStatus?.status === SaleTransactionStatusEnum.Active
        ? ''
        : activeStatus?.status,
        startDate:
      activeStatus?.status === SaleTransactionStatusEnum.Active
        ? 'Now'
        : activeStatus?.startDate,
  };

  return (
    <div
      className="transaction-item-container"
      >
      <div className="transaction-item">
        {activeStatus && (
          <div
            className={`transaction-item__current-status transaction-current-status transaction-current-status_${activeStatus?.status.toLowerCase()}`}>
            {activeStatus?.status}
          </div>
        )}

        {buyer && (
          <div className="transaction-item__enterprise-name" title={buyer}>
            {buyer}
          </div>
        )}

        <div
          className={`transaction-item__sqft-and-price_${activeStatus?.status.toLowerCase()}`}>
          {sqft && (
            <span className="lot-sqft">
              {withThousandsSeparators(sqft)} SQFT
            </span>
          )}
          <DotIcon />
          <span
            className="lot-price"
            title={price ? `$${withThousandsSeparators(price)}` : undefined}>
            {price ? `$${withThousandsSeparators(price)}` : 'n/a'}
          </span>
        </div>

        {statuses && (
          <div className="transaction-item__status-and-date">
            {prevStatus ? (
              <>
                {prevStatus.status}&nbsp;
                <Moment format={DATE_FORMAT}>{prevStatus.startDate}</Moment>
                &nbsp;-&nbsp;
              </>
            ) : (
              ''
            )}

            {
              <>
                {currStatus.status}&nbsp;
                {currStatus.startDate === 'Now' ? (
                  'Now'
                ) : (
                  <Moment format={DATE_FORMAT}>{currStatus.startDate}</Moment>
                )}
              </>
            }

            {prevStatus && (
              <>
                &nbsp;|&nbsp;
                {transactionItemDateDiff(
                  formattedDate(
                    prevStatus.startDate
                      ? new Date(prevStatus.startDate)
                      : new Date(),
                  ),
                  formattedDate(
                    currStatus.startDate && currStatus.startDate !== 'Now'
                      ? new Date(currStatus.startDate)
                      : new Date(),
                  ),
                )}
                &nbsp;days
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SaleTransactionItem;
