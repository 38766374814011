import React, { ComponentProps } from 'react';

interface IProps extends ComponentProps<'svg'> {
  width?: number;
  height?: number;
  color?: string;
}

export const SmallOptionMenuIcon = ({ width, height, color }: IProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.6" cx="19.25" cy="8.25" r="1.25" fill={color} />
      <circle opacity="0.6" cx="14.25" cy="8.25" r="1.25" fill={color} />
      <circle opacity="0.6" cx="9.25" cy="8.25" r="1.25" fill={color} />
    </svg>
  );
};

SmallOptionMenuIcon.defaultProps = {
  width: 29,
  height: 16,
  color: '#1F2531',
};
