import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { LinkIcon } from 'components/icons/LinkIcon';
import schema, { Pages } from './index';
import { Color } from 'components/UI/controls/shared/styles';

interface Props {
  className?: string;
  to?: Pages;
  fullPath?: string;
  orderNumber?: string;
  id?: string;
  slug?: string;
  onClick?: () => void;
  query?: string;
  backFlag?: boolean;
  targetBlank?: boolean;
  children?: React.ReactNode | string;
  newTabIcon?: boolean;
  enter?: Function;
  leave?: Function;
}

export const InternalLink: FC<Props> = ({
  to,
  fullPath,
  id,
  slug,
  orderNumber,
  children,
  className,
  onClick,
  newTabIcon,
  query,
  targetBlank,
  enter,
  leave,
}) => {
  let link: string =
    to && (id || orderNumber || slug)
      ? generatePath(schema.getLink(to), { id, orderNumber, slug })
      : schema.getLink(to) || '/error';

  if (query) {
    link = link + '?' + query;
  }

  if (!to && fullPath) {
    link = fullPath;
  }

  const onClickFunction: () => void = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <>
      <Link
        to={link}
        className={className || ''}
        onClick={onClickFunction}
        onMouseEnter={() => enter && enter()}
        target={targetBlank ? "_blank" : ""}
        onMouseLeave={() => leave && leave()}>
        {children}
      </Link>
      {newTabIcon ? (
        <Link to={link} onClick={onClickFunction} target={'_blank'}>
          <LinkIcon color={Color.link} />
        </Link>
      ) : (
        ''
      )}
    </>
  );
};

export default connect()(InternalLink);
