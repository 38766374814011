import React, { Component } from 'react';

import { IFiltersTab } from 'models';
import Button from 'components/UI/controls/Button';
import CustomScrollbars from 'components/CustomScrollbars';
import { FiltersOpenArrow } from 'components/icons/FiltersOpenArrow';

interface Props {
  className?: string;
  page: string;
  tabs?: IFiltersTab[];
  rightTabs?: IFiltersTab[];
  activeTab?: string;
  onClear: () => void;
  onApply: () => void;
  onClose: () => void;
  disableApply?: boolean;
  isFullScreen?: boolean;
  children: (false | JSX.Element)[];
}

class Filters extends Component<Props> {
  render() {
    const { isFullScreen, className } = this.props;

    return (
      <div
        className={
          'page__filters filters' +
          (isFullScreen ? ' filters_full-screen' : '') +
          (className ? ` ${className}` : '')
        }>
        <FiltersOpenArrow className="filters-open-arrow" />

        <div className="filters__content">
          <header className="filters__header">
            <ul className="filters__tabs">
              {!this.props.tabs && (
                <li className="filters__tab">{this.props.page}</li>
              )}

              {this.props.tabs?.map((tab) => (
                  <li
                    key={tab.label}
                    className={`filters__tab${
                      tab.label === this.props.activeTab
                        ? ' filters__tab_active'
                        : ''
                    }`}
                    onClick={tab.onClick}>
                    {tab.label}
                  </li>
                ))}
            </ul>
            <div className="filters__header--right">
              <ul className="filters__tabs">
                {this.props.rightTabs?.map((tab) => (
                    <li
                      key={tab.label}
                      className={`filters__tab${
                        tab.label === this.props.activeTab
                          ? ' filters__tab_active'
                          : ''
                      }`}
                      onClick={tab.onClick}>
                      {tab.label}
                    </li>
                  ))}
              </ul>

              {!this.props.isFullScreen && (
                <div
                  className="filters__button-close"
                  onClick={this.props.onClose}
                  title="Close"
                />
              )}
            </div>
          </header>

          <CustomScrollbars className="filters__scrollbars">
            <>{this.props.children}</>
          </CustomScrollbars>
        </div>

        <footer className="filters__footer">
          <div className="filters__buttons">
            <Button variant="light-sm" onClick={this.props.onClear}>
              Clear all
            </Button>
            <Button
              variant="dark-sm"
              onClick={this.props.onApply}
              disabled={this.props.disableApply}>
              Apply
            </Button>
          </div>
        </footer>
      </div>
    );
  }
}

export default Filters;
