/* eslint-disable */
import { PayloadAction } from 'typesafe-actions/dist/type-helpers';

import { RouterState } from 'connected-react-router';
import { IAuthState } from './auth/models.d';
import { ISocketState } from './socket/models.d';
import { IPersonState } from './person/models.d';
import { IPropertyState } from './property/models.d';
import { IEnterpriseState } from './enterprise/models.d';
import { IUserState } from './user/models.d';
import { IInvolvementState } from './involvement/models.d';
import { ITaxonomyState } from './taxonomy/models.d';
import { IError, ILoader } from '../models';
import { ICardState } from './card/models';
import { ITagState } from './tag/models.d';
import { IModalState } from './modals/models';
import { IHistoryState } from './history/models';
import { IGoogleSearchState } from './googleMaps/models';
import { IShowElementState } from "./showElement/models";
import { IExportState } from "./export/model";

export interface IStore {
  auth: IAuthState;
  router: RouterState;
  socketState: ISocketState;
  app: IState;
  user: IUserState;
  property: IPropertyState;
  person: IPersonState;
  enterprise: IEnterpriseState;
  involvement: IInvolvementState;
  taxonomy: ITaxonomyState;
  card: ICardState;
  showElement: IShowElementState;
  tag: ITagState;
  history: IHistoryState;
  modals: IModalState;
  export: IExportState;
  IGoogleSearchState: IGoogleSearchState;
}

export interface ISimpleState {
  code?: number;
  isLoading: boolean;
  message?: string;
  error?: boolean;
}

export interface IException {
  code: string;
  message: string;
  name?: string;
  key?:string;
  data?: Record<string, any>;
}

export interface IState {
  loaders: ILoader[];
  errors: IError[];
}

export interface IInvolvementState {
  items: number[];
  isAll?: boolean;
  count?: number;
}

export enum involvementLoaderType {
  allInvolvements = 'allInvolvements',
  currentInvolvements = 'currentInvolvements',
  pastInvolvements = 'pastInvolvements',
  createInvolvement = 'createInvolvement',
  updateInvolvement = 'updateInvolvement',
  deleteInvolvement = 'deleteInvolvement',
  getInvolvement = 'getInvolvement',
}

export type LoaderActionType =
  | PayloadAction<string, ILoader>
  | PayloadAction<string, IError & { id: string }>
  | PayloadAction<string, { id: string }>
  | PayloadAction<string, { target: string }>;
