import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import * as serviceWorker from './serviceWorker';
import Routing from 'routing/Routing';
import store from 'store';
import history from 'historyApi';
import { startJs } from './startJS';
import { Config } from './config/api';

/* context providers */
import GoogleMapsApiProvider from 'context/GoogleMapsAPI';
import FullScreenProvider from 'context/FullScreen';

/* styles */
import 'assets/scss/main.scss';
import 'swiper/css';

Config.init({
  MAIN_SERVICE_HOST: process.env.REACT_APP_MAIN_SERVICE_HOST || '',
  AUTH_SERVICE_HOST: process.env.REACT_APP_AUTH_SERVICE_HOST || '',
  WS_API_HOST: process.env.REACT_APP_WS_API_HOST || '',
  GOOGLE_GEO_API_KEY: process.env.REACT_APP_GEO_KEY || '',
  HISTORY_SERVICE_HOST: process.env.REACT_APP_HISTORY_SERVICE_HOST || '',
});

const startApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GoogleMapsApiProvider>
          <FullScreenProvider>
            <Routing />
          </FullScreenProvider>
        </GoogleMapsApiProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
  );
};

startJs();

if (!(window as any).cordova) {
  startApp();
} else {
  document.addEventListener('deviceready', startApp, false);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
