import { IDataListItem } from "../../models";
import { PersonDTO } from "@ternala/voltore-types";
import { getListImage } from "../../utils";

export const generatePersonName = (person: PersonDTO): string =>
  `${person?.firstName} ${
    person.middleName ? `${person.middleName} ` : ''
  }${person?.lastName} ${person?.nickName ? `(${person?.nickName})` : ''}`


export const personToDataListItem = (person: PersonDTO): IDataListItem => ({
  id: person?.id,
  title: undefined,
  description: generatePersonName(person),
  image: getListImage(person?.images) || '',
  initials: `${person.firstName?.[0] || ""}${person.lastName[0]?.[0] || ""}`,
})
