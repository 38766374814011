import React, { FC } from 'react';
import { connect } from 'react-redux';

import { PointerLinked } from '../../icons/PointerLinked';
import { PointerUnlinked } from '../../icons/PointerUnliked';

// interface
import { IStore } from 'controllers/store';
import { IGoogleApiSearchResponse } from "../../../controllers/googleMaps/models";

interface Props {
  linked: boolean;
  functionality: (data: any) => void;
  searchStateData?: IGoogleApiSearchResponse;
}

export const MapAddressLinker: FC<Props> = ({ ...props }) => {
  return (
    <div className="mapaddress-linker">
      {props.linked ? (
        <div
          className="mapaddress-linker-unlinked"
          onClick={() => {
            props.functionality(props.searchStateData?.results);
          }}>
          <div className="mapaddress-linker-unlinked-imgcontainer">
            <PointerLinked />
          </div>
          <span className="mapaddress-linker-text">
            Pin on the map is linked to the address field. Click on <br /> the
            button to unlink.
          </span>
        </div>
      ) : (
        <div
          className="mapaddress-linker-linked"
          onClick={() => {
            props.functionality(props.searchStateData?.results);
          }}>
          <div className="mapaddress-linker-linked-imgcontainer">
            <PointerUnlinked />
          </div>
          <span className="mapaddress-linker-text">
            Pin on the map is NOT linked to the address field. Click on <br />{' '}
            the button to set pin as per the address field input.
          </span>
        </div>
      )}
    </div>
  );
};

// export default MapAddressLinker;
export default connect((store: IStore) => ({
  searchStateData: store.IGoogleSearchState.data,
}))(MapAddressLinker);
