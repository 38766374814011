import React, {
  FC,
  useState,
  useEffect,
  ChangeEvent,
  MouseEvent,
  FormEvent,
} from 'react';
import { connect, useDispatch } from 'react-redux';

import { FormControl, Validators } from 'lib/validation';

/* UI components */
import Modal from 'components/Hocs/Portal';
import Input from 'components/UI/controls/Input';
import InputPassword from 'components/UI/controls/InputPassword';
import Select from 'components/UI/controls/Select';
import SelectSearch from 'components/UI/controls/SelectSearchCustom';
import Button from 'components/UI/controls/Button';

/* types & interfaces */
import { IStore } from 'controllers/store';
import { IUserState } from 'controllers/user/models';
import { UserUpdateRequest } from '@ternala/voltore-types';

/* constants */
import { errorMessages } from 'config';
import {
  userTypeOptions,
  userStatusOptions,
  loadUserRoleOptions,
} from 'config/constants/select-options';
import { UserTypeEnum } from '@ternala/voltore-types/lib/constants';
import { loadPersonsOptions } from 'config/constants/select-options';
import { OptionType } from 'models';
import SelectMulti from 'components/UI/controls/SelectMultiSearch';
import { updateUserAction } from 'controllers/user/actions';
import CustomScrollbars from 'components/CustomScrollbars';
import { getAccessTokenUtil } from 'utils';

interface Props {
  userId?: number;
  user: IUserState;
  onCreatePerson: () => void;
  onCreateUserRole: () => void;
  onClose: () => void;
  onSubmit: (payload: UserUpdateRequest & { callback?: Function }) => void;
}

const EditUserInfoModal: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    userId,
    user: { users },
    onCreatePerson,
    onCreateUserRole,
    onClose,
    onSubmit,
  } = props;

  const activeUser = users?.find((item) => item.id === Number(userId));

  const [requestProcess, setRequestProcess] = useState<boolean>(false);
  const [login, setLogin] = useState<FormControl>(
    new FormControl(
      {},
      [Validators.required, Validators.maxLength(100)],
      errorMessages,
    ),
  );
  const [password, setPassword] = useState<string>('');
  const [userType, setUserType] = useState<OptionType>({
    label: activeUser?.type.title,
    value: activeUser?.type.slug,
  });
  const [status, setStatus] = useState<OptionType>({
    label: activeUser?.isActive ? 'Active' : 'Inactive',
    value: !!activeUser?.isActive,
  });
  const [person, setPerson] = useState<OptionType | undefined>(
    activeUser?.person
      ? {
          label: `${activeUser?.person.firstName} ${activeUser?.person.lastName}`,
          value: activeUser?.person.id,
        }
      : undefined,
  );
  const [roles, setRoles] = useState<OptionType[] | undefined>(
    activeUser?.roles
      ? activeUser?.roles.map((role) => ({
          label: `${role.title}`,
          value: role.id,
        }))
      : undefined,
  );

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  useEffect(() => {
    if (activeUser) {
      login.value = activeUser.login;
      setLogin({ ...login });
    }
  }, [activeUser]);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  const submitData = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>,
  ) => {
    login.validate();
    event.preventDefault();
    setLogin({ ...login });
    setRequestProcess(true);
    if (login.invalid) return;
    const payload: ReturnType<typeof updateUserAction.request>['payload'] = {
      // const payload: UserUpdateRequest = {
      id: Number(activeUser?.id),
      person: Number(person?.value),
      login: login.value.trim(),
      type: userType.value as UserTypeEnum,
      isActive: Boolean(status?.value),
      roles: roles?.map((role) => Number(role?.value)) || [],
      callback: (status: boolean) => {
        if (status) onClose();
        setRequestProcess(false);
      },
    };
    if (password) payload.password = password;
    onSubmit(payload);
  };

  return (
    <Modal>
      <div className="modal">
        <div className="scroll-area">
          <CustomScrollbars
            style={{
              width: '700px',
              height: '100%',
            }}
            renderView={(props) => (
              <div
                {...props}
                className={'scroll-area'}
                style={{
                  position: 'absolute',
                  inset: '0px',
                  overflowY: 'scroll',
                  marginRight: `${
                    navigator.platform === 'MacIntel' ? '-17px' : '-35px'
                  }`,
                  marginBottom: '-17px',
                }}></div>
            )}>
            <form className="modal-content" onSubmit={submitData}>
              <div className="modal__close" onClick={onClose} title="Close" />

              <div className="modal-title">Edit user info</div>

              <div className="flex-container">
                <div>
                  <Input
                    type="email"
                    label="login"
                    placeholder="example@gmail.com"
                    value={login.value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      login.update(event.target.value);
                      setLogin({ ...login });
                    }}
                    onBlur={() => login.validate()}
                    errorMessages={login.errorMessages}
                    required
                  />

                  <InputPassword
                    label="password"
                    value={password}
                    onPasswordChanged={(password) => setPassword(password)}
                  />

                  <Select
                    label="user type"
                    options={userTypeOptions}
                    value={userType}
                    onChange={(userType) => setUserType(userType)}
                    required
                  />

                  <Select
                    label="status"
                    options={userStatusOptions}
                    value={status}
                    onChange={(status) => setStatus(status)}
                  />
                </div>

                <div>
                  <SelectSearch
                    label="CONNECTED PERSON"
                    asyncOptions={async (searchParams) => {
                      const token = await getAccessTokenUtil(dispatch);
                      if (token) {
                        return loadPersonsOptions(searchParams, token);
                      }
                      return [];
                    }}
                    value={person}
                    onChange={(option) => setPerson(option)}
                    creation={{
                      label: 'Create new person',
                      onClick: () => onCreatePerson(),
                    }}
                    required
                  />
                  <SelectMulti
                    label="user role"
                    asyncOptions={async (searchParams) => {
                      const token = await getAccessTokenUtil(dispatch);
                      if (token) {
                        return loadUserRoleOptions(searchParams, token);
                      }
                      return [];
                    }}
                    placeholder="Select one, multiple or create new"
                    selectedOptions={roles}
                    onChange={(selections) => setRoles(selections)}
                    creation={{
                      label: 'Create new user role',
                      onClick: () => onCreateUserRole(),
                    }}
                    required
                  />
                </div>
              </div>

              <footer className="modal-footer">
                <Button variant="light" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="dark"
                  onClick={submitData}
                  type="submit"
                  disabled={!person || requestProcess}>
                  Save
                </Button>
              </footer>
            </form>
          </CustomScrollbars>
        </div>
        <div className="modal-overlay" onClick={onClose} />
      </div>
    </Modal>
  );
};

export default connect((store: IStore) => ({
  store,
  user: store.user,
}))(EditUserInfoModal);
