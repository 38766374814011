/* eslint-disable */
import { Unauthorized, BadRequest } from './Exceptions';
import jwt_decode from 'jwt-decode';

export function isJWTTokenExpired(token: string) {
  const payload = jwt_decode(token) as { exp: number };
  const accessTokenExpDate = payload.exp - 60 * 5;
  const nowTime = Math.floor(new Date().getTime() / 1000);
  return accessTokenExpDate <= nowTime;
}

export async function handleErrors<T = {}>(
  fetch: Promise<Response>,
): Promise<T> {
  try {
    const res = await fetch;
    if (!res.ok) {
      const error = await res.json();
      if (error?.statusCode) {
        if (error.statusCode === 422) {
          throw error;
        }

        if (error.statusCode === 403 || error.statusCode === 401) {
          // throw error;
        } else {
          throw new BadRequest(error?.message);
        }
      } else if (error?.code || error?.message || error?.userMessage) {
        if (error.status === 422) {
          throw {
            code: error.status,
            data: error.response,
          };
        }
        if (error.code === 'Unauthorized') {
          throw new Unauthorized(error?.message || error?.userMessage);
        } else {
          throw new BadRequest(error?.message || error?.userMessage);
        }
      } else {
        throw new Error(`Request filed with code: ${res.status}`);
      }
    }

    const data = await res.json();

    return data as T;
  } catch (error) {
    throw error;
  }
}

export function authHeader(token: string) {
  return {
    authorization: `Bearer ${token}`,
  };
}

export function refreshHeader(token: string) {
  return {
    'refresh-token': `Bearer ${token}`,
  };
}
