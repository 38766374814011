import React, { FC } from 'react'
import { connect, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import { Navigation, A11y  } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

/* components */
import { InnerLink } from 'routing/InnerLink'
import { GoBackIcon } from 'components/icons/GoBackIcon'

/* types */
import { Pages } from 'routing'
import { RouterState } from 'connected-react-router'
import { IStore } from 'controllers/store'
import { enterprisesTabs, PageTabsType, personsTabs, propertiesTabs } from 'config/constants/tabs'

interface Props {
   routerState: RouterState
   page: Pages
   tabs: PageTabsType
   listItemId?: string
   backLink: string
   useSwiper?: boolean
}

const DatalistItemTabs: FC<Props> = (props) => {
   const {
      routerState: {
         location: {
            pathname
         }
      },
      page,
      tabs,
      listItemId,
      backLink,
      useSwiper,
   } = props

   const dispatch = useDispatch()

   const tabNames = [
      personsTabs.enterpriseInvolvement.path,
      enterprisesTabs.people.path,
      propertiesTabs.sales.path,
      propertiesTabs.leases.path,
      propertiesTabs.tenants.path
   ]
   const tabsListItemId: number = Number(pathname.split('/')[pathname.split('/').length - 1])
   const hideMenu: boolean = !isNaN(tabsListItemId)
      ? tabNames.some(tabName => pathname.endsWith(`${tabName}/${tabsListItemId}`))
      : false

   const tabElements = Object.values(tabs).map(tab => (
     <SwiperSlide className={'slide tab'} key={tab.path}>
      <InnerLink
        className={"tab__link"}
         activeClass="tab__link_active"
         to={`${page}-tab` as Pages}
         id={listItemId}
         tabName={tab.path}
         key={tab.path}
      >
         <div className="tab__link">
            { tab.title }
            <div className="tab__underline" />
         </div>
      </InnerLink>
     </SwiperSlide>
   ))

   return (
      <div className={
         "data-item__tabs tabs-menu" +
         (hideMenu ? " data-item__tabs_third-screen" : "")
      }>
         {backLink && (
            <div
               className="tabs-menu__backlink"
               onClick={() => dispatch(push(backLink))}
               title="Back"
            >
               <GoBackIcon />
            </div>
         )}

         {useSwiper ? (
            <Swiper
               modules={[Navigation, A11y]}
               slidesPerView="auto"
               slideClass="slide"
               freeMode={true}
               updateOnWindowResize={true}
               updateOnImagesReady={true}
               navigation={{
                  prevEl: 'tabs-swipe-backwards',
                  nextEl: 'tabs-swipe-forwards'
               }}
            >
               { tabElements }
            </Swiper>
         ) : (
               <div className={hideMenu ? " tabs__swiper_hidden" : ""}>
                  { tabElements }
               </div>
            )
         }
      </div>
   )
}

export default connect((store: IStore) => ({
   routerState: store.router
}))(DatalistItemTabs)
