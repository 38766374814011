import {appName} from "config";
import {createAsyncAction} from "typesafe-actions";

import {
  TaxonomyCreateResponse,
  UserCreateRequest,
  UserCreateResponse,
  UserDeleteRequest,
  UserDeleteResponse,
  UserGetListRequest,
  UserGetListResponse,
  UserGetRequest,
  UserGetResponse, UserRoleCreateRequest,
  UserRoleCreateResponse, UserRoleDeleteRequest, UserRoleDeleteResponse,
  UserRoleGetListRequest,
  UserRoleGetListResponse, UserRoleGetRequest,
  UserRoleGetResponse, UserRoleUpdateRequest,
  UserRoleUpdateResponse,
  UserUpdateRequest,
  UserUpdateResponse
} from "@ternala/voltore-types";

import {IException} from "../store";

/* Actions */
export const widgetName = "user";

// ** Action
export const getUsersAction = createAsyncAction(
  `${appName}/${widgetName}/GET_USERS_REQUEST`,
  `${appName}/${widgetName}/GET_USERS_SUCCESS`,
  `${appName}/${widgetName}/GET_USERS_FILED`
)<UserGetListRequest & { callback?: Function }, {response: UserGetListResponse, searchParams: UserGetListRequest, isAll: boolean }, IException>();

export const createUserAction = createAsyncAction(
  `${appName}/${widgetName}/CREATE_USER_REQUEST`,
  `${appName}/${widgetName}/CREATE_USER_SUCCESS`,
  `${appName}/${widgetName}/CREATE_USER_FILED`
)<UserCreateRequest & {callback?: (user: UserCreateResponse | false) => void}, UserCreateResponse, IException>();

export const getUserAction = createAsyncAction(
  `${appName}/${widgetName}/GET_USER_REQUEST`,
  `${appName}/${widgetName}/GET_USER_SUCCESS`,
  `${appName}/${widgetName}/GET_USER_FILED`
)<UserGetRequest & { callback?: Function }, UserGetResponse, IException>();

export const updateUserAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_USER_REQUEST`,
  `${appName}/${widgetName}/UPDATE_USER_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_USER_FILED`
)<UserUpdateRequest & { callback?: Function}, UserUpdateResponse, IException>();

export const deleteUserAction = createAsyncAction(
  `${appName}/${widgetName}/DELETE_USER_REQUEST`,
  `${appName}/${widgetName}/DELETE_USER_SUCCESS`,
  `${appName}/${widgetName}/DELETE_USER_FILED`
)<UserDeleteRequest & { callback?: Function }, UserDeleteResponse & {id?: number}, IException>();

// User role
export const getUserRolesAction = createAsyncAction(
  `${appName}/${widgetName}/GET_USER_ROLES_REQUEST`,
  `${appName}/${widgetName}/GET_USER_ROLES_SUCCESS`,
  `${appName}/${widgetName}/GET_USER_ROLES_FILED`
)<UserRoleGetListRequest & { callback?: Function }, {response: UserRoleGetListResponse, searchParams: UserRoleGetListRequest, isAll: boolean }, IException>();

export const createUserRoleAction = createAsyncAction(
  `${appName}/${widgetName}/CREATE_USER_ROLE_REQUEST`,
  `${appName}/${widgetName}/CREATE_USER_ROLE_SUCCESS`,
  `${appName}/${widgetName}/CREATE_USER_ROLE_FILED`
)<UserRoleCreateRequest & {callback?: (userRole: UserRoleCreateResponse | false) => void}, UserRoleCreateResponse, IException>();

export const getUserRoleAction = createAsyncAction(
  `${appName}/${widgetName}/GET_USER_ROLE_REQUEST`,
  `${appName}/${widgetName}/GET_USER_ROLE_SUCCESS`,
  `${appName}/${widgetName}/GET_USER_ROLE_FILED`
)<UserRoleGetRequest & { callback?: Function }, UserRoleGetResponse, IException>();

export const updateUserRoleAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_USER_ROLE_REQUEST`,
  `${appName}/${widgetName}/UPDATE_USER_ROLE_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_USER_ROLE_FILED`
)<UserRoleUpdateRequest & {callback?: (userRole: UserRoleUpdateResponse | false) => void}, UserRoleUpdateResponse, IException>();

export const deleteUserRoleAction = createAsyncAction(
  `${appName}/${widgetName}/DELETE_USER_ROLE_REQUEST`,
  `${appName}/${widgetName}/DELETE_USER_ROLE_SUCCESS`,
  `${appName}/${widgetName}/DELETE_USER_ROLE_FILED`
)<UserRoleDeleteRequest & {callback?: Function}, UserRoleDeleteResponse & {id?: number}, IException>();