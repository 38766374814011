import { createAsyncAction } from "typesafe-actions";
import { appName } from "../../config";
import { ExportGetListResponse } from "@ternala/voltore-types";
import { ExportGetListRequest } from "@ternala/voltore-types/lib/modules/export/request.dto";
import { IException } from "../store";

export const widgetName = 'export';


export const getExportTemplatesAction = createAsyncAction(
  `${appName}/${widgetName}/GET_EXPORT_TEMPLATES_REQUEST`,
  `${appName}/${widgetName}/GET_EXPORT_TEMPLATES_SUCCESS`,
  `${appName}/${widgetName}/GET_EXPORT_TEMPLATES_FILED`,
)<
  ExportGetListRequest & { callback?: Function },
  {
    response: ExportGetListResponse;
    searchParams: ExportGetListRequest;
    isAll: boolean;
  },
  IException
  >();
