import {
  EnterpriseGetListRequest, ExportFileDTO, ExportGetFileListResponse,
  ExportGetListResponse, PersonGetListRequest, PropertyGetListRequest
} from "@ternala/voltore-types";
import { ExportGetListRequest } from "@ternala/voltore-types/lib/modules/export/request.dto";
import { ExportMainEntityEnum } from "@ternala/voltore-types/lib/constants";

import { Config } from 'config/api';
import { authHeader, handleErrors } from 'utils/API';

import { appendSearchParams } from 'utils/appendSearchParams';

import { IError } from "../../model";

import { EnterpriseApi } from "../../enterprise/transport/enterprise.api";
import { PropertyApi } from "../../property/transport/property.api";

class API {
  public async getEnterprises(
    getExportData: ExportGetListRequest,
    token: string,
  ): Promise<ExportGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'export/template/list');

    url = appendSearchParams(url, getExportData);

    if (getExportData.hasOwnProperty('entityType'))
      url.searchParams.append(
        'entityType',
        String(getExportData.entityType),
      );

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async getFileList(
    id: number,
    token: string,
  ): Promise<ExportGetFileListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + `export/download/list/${id}`);

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async downloadFile(
    templateId: number,
    downloadFileData: {
      persons?: PersonGetListRequest;
      enterprises?: EnterpriseGetListRequest;
      properties?: PropertyGetListRequest;
    },
    entityType: ExportMainEntityEnum,
    token: string,
  ): Promise<ExportFileDTO | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + `export/template/download/${templateId}`);
    switch (entityType) {
      case ExportMainEntityEnum.person: {
        url.searchParams.append(
          'person',
          JSON.stringify(downloadFileData.persons),
        );
      }
      break;
      case ExportMainEntityEnum.property: {
        url = PropertyApi.prepareGetListLink(url, downloadFileData as PropertyGetListRequest)
      }
        break;
      case ExportMainEntityEnum.enterprise: {
        url = EnterpriseApi.prepareGetListLink(url, downloadFileData as EnterpriseGetListRequest)
      }
        break;
    }

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }
}
export const ExportApi = new API();
