import React, { createRef, useEffect, useState } from 'react';
import { CompositeDecorator, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import { noteDecorators } from '../Draft/Decorators';
import { NoteDTO } from '@ternala/voltore-types';

interface IProps {
  note?: NoteDTO;
}

const CardNote: React.FC<IProps> = ({ note }) => {
  let noteText: string | undefined | JSX.Element = note?.text;

  const insideRef = createRef<HTMLDivElement>();
  const [height, setHeight] = useState<number>(0);

  try {
    if (noteText) {
      const res = JSON.parse(noteText);
      if (noteText) {
        const content = convertFromRaw(res);
        try {
          noteText = content.hasText() ? (
            <Editor
              readOnly={true}
              editorState={EditorState.createWithContent(
                content,
                new CompositeDecorator(noteDecorators),
              )}
              toolbarHidden={true}
              customDecorators={noteDecorators}
            />
          ) : (
            ''
          );
        } catch (e) {
          console.error('e: ', e);
          noteText = ''
        }

      }
    }
  } catch (e) {
    console.error({e})
    console.error('Has problem with card: ', note?.id);
  }

  useEffect(() => {
    const offsetHeight = insideRef.current?.offsetHeight;
    offsetHeight && height !== offsetHeight && setHeight(offsetHeight);
  }, [noteText]);

  return (
    <div
      className={'card--value__wrapper'}
      style={{ maxHeight: height || 'initial' }}>
      {note ? <div className={'card--value value'} ref={insideRef}>
        {noteText}
      </div> : ""}
    </div>
  );
};

export default CardNote;
