import { IFiltrationTag } from 'models';
import { createContext } from 'react';

export enum TagTypeEnum {
  Tags = 'tags',
  ExcludedTags = 'excludedTags',
}

const initialState = {
  addTag: (tag: IFiltrationTag, type: TagTypeEnum = TagTypeEnum.Tags) => {},
  addTags: (tags: IFiltrationTag[], type: TagTypeEnum = TagTypeEnum.Tags) => {},
  setTags: (tags: IFiltrationTag[], type: TagTypeEnum = TagTypeEnum.Tags) => {},
  removeTag: (tag: IFiltrationTag, type: TagTypeEnum = TagTypeEnum.Tags) => {},
  removeTags: (callback?: Function, type: TagTypeEnum = TagTypeEnum.Tags) => {},
  tags: undefined as IFiltrationTag[] | undefined,
  excludedTags: undefined as IFiltrationTag[] | undefined,
};

const TagsContext = createContext(initialState);

export const TagsProvider = TagsContext.Provider;

export default TagsContext;
