import { all, takeEvery, delay, put } from 'redux-saga/effects';

// Actions
import { addNotification, removeNotification } from '../actions';

export function* addNotificationSaga({
  payload,
}: ReturnType<typeof addNotification>) {
  yield delay(5000) 
  yield put(removeNotification({ id: payload.id }))
}

export function* modalsActionSaga() {
  yield all([takeEvery(addNotification, addNotificationSaga)]);
}
