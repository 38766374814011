import { all } from 'redux-saga/effects';
import { createReducer, ActionType, getType } from 'typesafe-actions';

// Actions
import * as actions from './actions';
import * as cardActions from '../card/actions';

// Types
import {
  TagCategoryDeleteResponse,
  TagDetachResponse,
  TagUpdateResponse,
} from '@ternala/voltore-types';
import { LoaderActionType } from 'controllers/store';

// Interfaces
import { ITagState } from './models.d';
import { generateLoaderActions } from 'controllers/based';
import { tagActionSaga } from './sagas/tag';
import { TagEntityEnum } from "@ternala/voltore-types/lib/card";

//Sagas
export type TagActionType = ActionType<typeof actions> | ActionType<typeof cardActions>;
export const tagSaga = function* () {
  yield all([tagActionSaga()]);
};

/* Reducer */
const initialState: ITagState = {
  state: {
    errors: [],
    loaders: [],
  },
  tags: [],
  entities: {
    property: {},
    person: {},
    enterprise: {},
  },
  groups: [],
  storedSearchParams: null,
};

type ActionTypes = TagActionType | LoaderActionType;

const loaderActions = generateLoaderActions<ITagState, ActionTypes>(
  actions.widgetName,
);

export const loaderHandlers = loaderActions.handlers;

export const tagReducer = createReducer<ITagState, ActionTypes>(initialState, {
  ...loaderHandlers,
  [getType(actions.updateTagAction.success)]: (
    state: ITagState,
    action: any,
  ): ITagState => {
    const payload = action.payload as TagUpdateResponse & { groupId?: number };
    return {
      ...state,
      groups: state.groups.map((group) => {
        let tags = group.tags;
        if (group.id === payload.groupId) {
          tags = tags.filter((tag) => tag.id !== payload.id);
        }
        if (group.id === payload.category.id) {
          tags = [...tags, payload];
        }
        return { ...group, tags };
      }),
    };
  },
  [getType(actions.detachTagAction.success)]: (
    state: ITagState,
    action: any,
  ): ITagState => {
    const payload = action.payload as TagDetachResponse & {
      id: number;
      entity: { id: number; type: TagEntityEnum };
    };
    const type = state.entities[payload.entity.type] || {};
    return {
      ...state,
      entities: {
        ...state.entities,
        [payload.entity.type]: {
          ...type,
          [payload.entity.id]: type[payload.entity.id].filter(
            (tag) => tag?.id !== payload.id,
          ),
        },
      },
    };
  },
  [getType(actions.deleteTagCategoryAction.success)]: (
    state: ITagState,
    action: any,
  ): ITagState => {
    const payload = action.payload as TagCategoryDeleteResponse & {
      id?: number;
      newTagCategoryId?: number;
    };
    const newGroup = !!state.newGroups?.find((group) => group.id === payload.id)
      ?.id;
    if (!payload.id) return { ...state };
    return {
      ...state,
      ...(newGroup
        ? {
            newGroups: state.newGroups?.filter(
              (group) => group.id !== payload.id,
            ),
          }
        : {
            groups: state.groups.filter((group) => group.id !== payload.id),
          }),
    };
  },
})
  .handleAction(
    [actions.addTagAction],
    (state: ITagState, { payload }): ITagState => {
      const type = state.entities[payload.entityType] || {};
      const entity = type?.[payload.entityId] || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.entityType]: {
            ...type,
            [Number(payload.entityId)]: [...entity, payload.tag],
          },
        },
      };
    },
  )
  .handleAction(
    [actions.deleteTagLocallyAction],
    (state: ITagState, { payload }): ITagState => {
      const type = state.entities[payload.entityType] || {};
      const entity = type?.[payload.entityId] || [];

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.entityType]: {
            ...type,
            [Number(payload.entityId)]: entity
              .filter((item) => item !== null)
              .filter((item) => item.id !== payload.tagId),
          },
        },
      };
    },
  )
  .handleAction(
    [actions.setTagsAction],
    (state: ITagState, { payload }): ITagState => {
      const type = state.entities[payload.entityType] || {};
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.entityType]: {
            ...type,
            [payload.entityId]: payload.tags,
          },
        },
      };
    },
  )
  .handleAction(
    [actions.updateTagCategoryAction.success],
    (state: ITagState, { payload }): ITagState => {
      return {
        ...state,
        groups: state.groups.map((group) =>
          group.id === payload.id ? { ...group, title: payload.title } : group,
        ),
      };
    },
  )
  .handleAction(
    [actions.deleteTagAction.success],
    (state: ITagState, { payload }): ITagState => {
      const newGroups = state.groups.map((group) => {
        if (group.id === payload.groupId)
          return {
            ...group,
            tags: group.tags.filter((tag) => tag.id !== payload.id),
          };
        return group;
      });
      return { ...state, groups: newGroups };
    },
  )
  .handleAction(
    [actions.createTagAction.success],
    (state: ITagState, { payload }): ITagState => {
      if (payload.response) {
        const newGroups = state.groups.slice();
        const newTags = state.tags.slice();
        const findGroup = newGroups.find(
          (group) => group.id === payload.response.category.id,
        );
        if (findGroup) {
          findGroup.tags.push(payload.response);
        } else {
          newGroups.push({
            ...payload.response.category,
            tags: [payload.response],
          });
        }
        newTags.push(payload.response);
        return {
          ...state,
          groups: newGroups,
          tags: newTags,
        };
      }
      return state;
    },
  )
  .handleAction(
    [actions.createTagCategoryAction.success],
    (state: ITagState, { payload }): ITagState => {
      return {
        ...state,
        newGroups: [...(state.newGroups || []), payload],
      };
    },
  )
  .handleAction(
    [actions.getTagCategoriesAction.success],
    (state: ITagState, { payload }): ITagState => {
      return {
        ...state,
        isAll: payload.isAll,
        groups: payload.response.items,
        storedSearchParams: payload.searchParams,
      };
    },
  )
  .handleAction(
    [cardActions.deleteCardAction.success],
    (state: ITagState, { payload }): ITagState => {
      const entities = Object.assign({}, state.entities);
      if(payload.card && 'cardTagConnect' in payload.card){
        const connect = payload.card.cardTagConnect;
        for(const key in connect){
          if(Object.values(TagEntityEnum).includes(key as TagEntityEnum)){
            let localKey = key as TagEntityEnum;
            const itemID = connect[localKey]?.id
            if(itemID && connect.tag){
              if(Array.isArray(entities[localKey][itemID])){
                entities[localKey][itemID] = entities[localKey][itemID].filter(tag => tag.id !== connect.tag?.id)
              }
            }
          }
        }
      }
      return {
        ...state,
        entities
      }
    },
  )
  .handleAction([
    cardActions.createCardAction.success
  ], (state: ITagState, { payload }) => {
    const cards = payload.items;
    const entities = Object.assign({}, state.entities);
    cards.forEach(card => {
      if('cardTagConnect' in card){
        const connect = card.cardTagConnect;
        for(const key in connect){
          if(Object.values(TagEntityEnum).includes(key as TagEntityEnum)){
            let localKey = key as TagEntityEnum;
            const itemID = connect[localKey]?.id
            if(itemID && connect.tag){
              if(Array.isArray(entities[localKey][itemID])){
                entities[localKey][itemID] = [...entities[localKey][itemID], connect.tag]
              } else {
                entities[localKey][itemID] = [connect.tag]
              }
            }
          }
        }
      }
    })
    return {
      ...state,
      entities
    }
  } );

/* Loader actions */
export const addLoader = loaderActions.actions.addLoader;
export const addError = loaderActions.actions.addError;
export const removeError = loaderActions.actions.removeError;
export const removeLoader = loaderActions.actions.removeLoader;
