import React, { FC } from "react";
import Loader from "../Loader";

export const FullScreenLoader:FC = () => {
  return (
    <div className="full-screen-layout">
      <Loader />
    </div>
  )
}

export default FullScreenLoader;