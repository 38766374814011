import { all, put, takeEvery, call } from "redux-saga/effects";

// APIs
import {getAccessToken} from '../../auth';

// Constants
import {LoaderAction} from 'config/constants';

// Actions
import {
    createPropertySuiteAction,
    updatePropertyFloorListAction,
} from '../actions';

import uuid from 'utils/uuid';
import {addError, addLoader, removeLoader} from '../index';
import {PropertyApi} from '../transport/property.api';
import {getAccessTokenUtil} from "../../../utils/getAccessToken";
import {PropertyFullDTO, SuiteFullDTO} from "@ternala/voltore-types";
import {IError} from "../../model";
import {createEnterpriseAction, updateEnterpriseAction} from "../../enterprise/actions";
import { getAccessTokenSaga } from "../../auth/sagas/auth";

// Utils
export function* createSuiteSaga({
                                     payload,
                                 }: ReturnType<typeof createPropertySuiteAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();

    const additionalFields = {
        property: payload.property,
    };

    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, suite is creating!',
            type: LoaderAction.suite.create,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not autorized');
        const res: SuiteFullDTO | string | IError = yield PropertyApi.createPropertySuite(payload, accessToken);

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                createPropertySuiteAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    createPropertySuiteAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to create suite!',
                    type: LoaderAction.suite.create,
                }),
            );
        } else {
            yield put(
                createPropertySuiteAction.success({
                    response: res,
                    additionalFields: {
                        property: payload.property,
                    },
                }),
            );
            yield put(
                removeLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            createPropertySuiteAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                id: loadId,
                message: 'Failed to create suite!',
                type: LoaderAction.suite.create,
                additionalFields,
            }),
        );
    }
}

export function* updatePropertyFloorListSaga({
                                                 payload,
                                             }: ReturnType<typeof updatePropertyFloorListAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();

    const additionalFields = {
        property: payload.property,
    };

    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, property floor is updating!',
            type: LoaderAction.propertyFloor.update,
            additionalFields,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not autorized');
        const res: PropertyFullDTO | string | IError = yield PropertyApi.updatePropertyFloorList(payload, accessToken);
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                updatePropertyFloorListAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    updatePropertyFloorListAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to update enterprise!',
                    type: LoaderAction.propertyFloor.update,
                }),
            );
        } else {
            yield put(
                updatePropertyFloorListAction.success({
                    response: res,
                    additionalFields: {
                        property: payload.property,
                    },
                }),
            );
            yield put(
                removeLoader({
                    id: loadId,
                    additionalFields,
                }),
            );
            if (typeof payload.callback === 'function')
                payload.callback(true, res.taxes, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            updatePropertyFloorListAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                id: loadId,
                message: 'Failed to update property floor!',
                type: LoaderAction.propertyFloor.update,
                additionalFields,
            }),
        );
    }
}

export function* additionalSaga() {
    yield all([
        takeEvery(createPropertySuiteAction.request, createSuiteSaga),
        takeEvery(
            updatePropertyFloorListAction.request,
            updatePropertyFloorListSaga,
        ),
    ]);
}
