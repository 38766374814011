
import React, {
  FC,
  useState,
  useEffect,
  useRef,
  RefObject,
  ChangeEvent,
  KeyboardEvent,
} from 'react';
import FormattedInput from 'react-number-format';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

/* utils */
import {
  parseStringWithSeparators,
  uniqueNumberId,
} from 'utils/helper-functions';

/* icons */
import { PlusIcon } from 'components/icons/PlusIcon';
import { BurgerIcon } from 'components/icons/BurgerIcon';
import { TimesIcon } from 'components/icons/TimesIcon';

/* styles */
import { Color } from '../shared/styles';
import style from './InputFloors.module.scss';

/* types */
import { FloorDTO } from '@ternala/voltore-types';
import { IFloor } from 'models';

interface Props {
  label: string;
  name?: string;
  placeholder?: string;
  data?: FloorDTO[];
  onUpdate: (values: FloorDTO[]) => void;
  isDisabled?: boolean;
}

export const InputFloors: FC<Props> = ({
  label,
  placeholder,
  data,
  isDisabled,
  ...props
}) => {
  const [value, setValue] = useState<string>('');
  const [valueExists, setValueExists] = useState<boolean>(false);
  const [floors, setFloors] = useState<IFloor[]>([]);

  const inputRef = useRef() as RefObject<HTMLInputElement>;
  const inputId = `input-${uniqueNumberId()}`;

  useEffect(() => {
    data &&
      setFloors(
        data
          .map((floor) => ({
            id: floor.id,
            title: floor.title,
            position: floor.position,
            sqft: String(floor.sqft),
          }))
          .sort((floor1, floor2) => {
            if (floor1.position < floor2.position) return -1;
            if (floor1.position > floor2.position) return 1;
            if (floor1.id < floor2.id) return -1;
            if (floor1.id > floor2.id) return 1;
            return 0;
          }),
      );
  }, []);

  const onFocus = () => {
    if (inputRef.current) {
      inputRef.current.placeholder = '';
    }
  };

  const onBlur = () => {
    if (inputRef.current && placeholder) {
      inputRef.current.placeholder = placeholder;
    }
  };

  const onKeyPress = (event: KeyboardEvent) => {
    const { value } = event.target as HTMLInputElement;

    if (event.key === 'Enter' && value !== '') {
      updateItems(value);
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
  };

  const serializedFloors = (parsedFloors: IFloor[]): FloorDTO[] => {
    return parsedFloors.map((floor, i) => ({
      id: floor.id,
      title: floor.title,
      position: i,
      sqft: floor.sqft ? parseStringWithSeparators(floor.sqft) : undefined,
    }));
  };

  const updateItems = (value: string) => {
    if (!value) return;

    // eslint-disable-next-line eqeqeq
    const currentValueExists = floors.some((floor) => floor.title == value);

    if (currentValueExists) {
      setValueExists(true);
    } else {
      const updatedFloors = [...floors];
      updatedFloors.push({
        id: uniqueNumberId(),
        title: value,
        position: 0,
        sqft: undefined,
      });
      setFloors(updatedFloors);
      props.onUpdate(serializedFloors(updatedFloors));

      setValue('');
      setValueExists(false);
      inputRef.current?.blur();
    }
  };

  const updateFloorSqft = (
    event: ChangeEvent<HTMLInputElement>,
    floor: IFloor,
  ) => {
    const { value } = event.target;

    const updatedFloors = [...floors];
    const floorToUpdate = updatedFloors.find(
      (floorToUpdate) => floorToUpdate.title === floor.title,
    );
    if (floorToUpdate) floorToUpdate.sqft = value;

    setFloors(updatedFloors);
    props.onUpdate(serializedFloors(updatedFloors));
  };

  const removeElement = (current: number | string) => {
    const updatedFloors = floors.filter((floor) => floor.title !== current);
    setFloors(updatedFloors);
    props.onUpdate(serializedFloors(updatedFloors));
  };

  const onDragStart = () =>
    // dragStart: DragStart
    {};

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    if (sourceIndex === destinationIndex) return;

    const updatedFloors = reorder(floors, sourceIndex, destinationIndex);
    setFloors(updatedFloors);
    props.onUpdate(serializedFloors(updatedFloors));
  };

  const reorder = (
    list: IFloor[],
    startIndex: number,
    endIndex: number,
  ): IFloor[] => {
    const result: IFloor[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <div className={style.container}>
      <label
        htmlFor={inputId}
        className={style.label}
        style={{ color: valueExists ? Color.error : Color.label }}>
        {label}
      </label>

      <div className={style.container}>
        <input
          type="text"
          id={inputId}
          ref={inputRef}
          style={{ borderColor: valueExists ? Color.error : '' }}
          className={style.input}
          name={props.name}
          placeholder={placeholder || ''}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          onChange={onChange}
          disabled={isDisabled}
        />

        <div className={style.plus_icon} onClick={() => updateItems(value)}>
          <PlusIcon color={Color.mainDark} fill={Color.mainDark} />
        </div>

        {valueExists && (
          <div className={style.error}>Such value already exists</div>
        )}

        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Droppable droppableId="droppable">
            {(provided: any) => (
              <div
                className={style.building}
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {floors.map((floor, index) => (
                  <Draggable
                    draggableId={String(floor.id)}
                    index={index}
                    key={floor.id}>
                    {(provided: any) => (
                      <div
                        className={style.floor_container}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <div className={style.floor_index}>
                          <div className={style.floor_index__label}>
                            <BurgerIcon className={style.floor_drag_icon} />
                            <span className={style.floor_title}>
                              {floor.title}
                            </span>
                          </div>

                          <div
                            className={style.remove_floor}
                            onClick={() => removeElement(String(floor.title))}
                            title="Remove floor">
                            <TimesIcon />
                          </div>
                        </div>

                        <FormattedInput
                          placeholder="sqft"
                          className={style.floor_square}
                          value={floor.sqft}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            updateFloorSqft(event, floor)
                          }
                          thousandSeparator
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

InputFloors.defaultProps = {
  isDisabled: false,
};

export default InputFloors;
