import React, { useContext, useEffect, useState } from 'react';
import PreviewContext from 'context/Modal';
import { CardFilterEnum } from 'controllers/card/models';
import { EntityEnum } from "@ternala/voltore-types/lib/constants";

interface IProps {
  id: number;
  type: EntityEnum;
  title: string;
  modal: CardFilterEnum;
  transactionId?: number;
  className?:string
}

let timeout: any;
const PreviewLink = ({ id, type, title, transactionId, className }: IProps) => {
  const previewContext = useContext(PreviewContext);
  const [inside, setInside] = useState<boolean>(false);

  useEffect(() => {
    if (!inside) {
      clearTimeout(timeout);
      previewContext.setPreview({
        inside: false,
      });
    } else {
      timeout = setTimeout(function () {
        previewContext.setPreview({
          type,
          inside,
          id: Number(id),
          modal: previewContext.preview?.modal,
          transactionId: Number(transactionId),
        });
      }, 500);
    }
  }, [inside]);

  return (
    <div
      className={"preview-link" + (className ? ` ${className}` : "")}
      onMouseEnter={() =>
        id && id !== previewContext.preview?.id && setInside(true)
      }
      onMouseLeave={() => setInside && setInside(false)}>
      <span>{title}</span>
    </div>
  );
};

export default PreviewLink;
