
import React, { useEffect, useState } from 'react';

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
}

const DescriptionTextarea = ({ value, onChange }: IProps) => {
  const [textareaValue, setTextareaValue] = useState<string | undefined>(
    value ? value : undefined,
  );
  const handleChange = (event: any) => {
    setTextareaValue(event.target.value);
    onChange?.(event.target.value);
  };

  useEffect(() => {
    if(value !== textareaValue){
      setTextareaValue(value)
    }
  }, [value]);
  return (
    <div className="note-creator__description">
      <textarea
        className="textarea"
        id="note-text-area"
        name="textarea"
        placeholder="Remind to..."
        value={textareaValue}
        onChange={handleChange}
      />
    </div>
  );
};

export default DescriptionTextarea;
