import React, { useContext, useState } from 'react';

// Types
import { CardFilterEnum } from 'controllers/card/models.d';

// Components
import History from './History';
import Preview from './Preview';
import Button from './Controls/Button';
import NotesFunctionalModal from './Modals/NotesFunctionalModal';

// Context
import HistoryContext from 'context/History';
import PreviewContext  from 'context/Modal';

const Windows = () => {
  const historyContext = useContext(HistoryContext);
  const previewContext = useContext(PreviewContext);
  const [notesModal, setNotesModal] = useState<boolean>(false);
  const [remindersModal, setRemindersModal] = useState<boolean>(false);
  const modals = () => {
    let title: string = '';
    if (notesModal || remindersModal || historyContext.entity.id)
      title = 'one-modal';
    if (
      (notesModal && remindersModal) ||
      (notesModal && historyContext.entity.id) ||
      (remindersModal && historyContext.entity.id)
    )
      title = 'two-modals';
    if (notesModal && remindersModal && historyContext.entity.id)
      title = 'three-modals';
    return title;
  };

  return (
    <div
      className={`windows ${
        notesModal ||
        remindersModal ||
        (notesModal && remindersModal) ||
        historyContext.entity.id ||
        historyContext.entity.type ||
        (historyContext.entity.id && historyContext.entity.type)
          ? ''
          : 'fixed-height'
      } ${previewContext?.preview?.id !== undefined ? 'shadow' : ''} ${
        notesModal && remindersModal
          ? historyContext.entity.id
            ? 'three-modals'
            : 'two-modals'
          : ''
      }`}>
      <div
        className={`notes-buttons ${
          notesModal ||
          remindersModal ||
          (notesModal && remindersModal) ||
          historyContext.entity.id ||
          historyContext.entity.type ||
          (historyContext.entity.id && historyContext.entity.type)
            ? 'window-open'
            : ''
        }`}>
        {!notesModal ? (
          <Button icon={'notes'} onClick={() => !notesModal && setNotesModal(true)} />
        ) : (
          ''
        )}
        {!remindersModal ? (
          <Button icon={'reminders'} onClick={() => !remindersModal && setRemindersModal(true)} />
        ) : (
          ''
        )}
      </div>
      <div className={`general-windows ${modals()}`}>
        {previewContext?.preview?.id !== undefined && (
          <Preview class={modals()} />
        )}
        {notesModal && (
          <NotesFunctionalModal
            modal={CardFilterEnum.note}
            onClose={() => setNotesModal(false)}
          />
        )}
        {remindersModal && (
          <NotesFunctionalModal
            modal={CardFilterEnum.reminder}
            onClose={() => setRemindersModal(false)}
          />
        )}
        {historyContext.entity.type &&
          historyContext.entity.id &&
          historyContext.entity && (
            <History
              entity={historyContext.entity}
              onClose={() =>
                historyContext.setEntity({ id: undefined, type: undefined })
              }
            />
          )}
      </div>
    </div>
  );
};

export default Windows;
export * from './model.d';
