import React, { FC, ChangeEvent } from 'react';
import { isEmpty, omit } from 'lodash';

/* components */
import Button from 'components/UI/controls/Button';
import Input from 'components/UI/controls/Input';

/* i§ */
import { MarkerPinIcon } from 'components/icons/MarkerPinIcon';
import { BurgerIcon } from 'components/icons/BurgerIcon';
import { FiltersIcon } from 'components/icons/FiltersIcon';

/* constants */
import { FilterTypeEnum } from 'config/constants';
import { Color } from 'components/UI/controls/shared/styles';

/* types */
import { PersonsGetListFiltersExtended } from 'controllers/person/models';
import { EnterpriseGetListFiltersExtended } from 'controllers/enterprise/models';
import {
  PropertyGetListFiltersByPropertyExtended,
  PropertyGetListFiltersBySaleTransactionExtended,
  PropertyGetListFiltersByLeaseTransactionExtended,
} from 'controllers/property/models';
import { CloseSmallIcon } from 'components/icons/CloseSmallIcon';

interface Props {
  pageTitle: string;
  searchPlaceholder: string;
  searchValue?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onAddNew?: () => void;
  onSearch?: () => void;
  Filters?: {
    isOpen: boolean;
    applied?: {
      persons?: PersonsGetListFiltersExtended;
      enterprises?: EnterpriseGetListFiltersExtended;
      property?: PropertyGetListFiltersByPropertyExtended;
      sale?: PropertyGetListFiltersBySaleTransactionExtended;
      lease?: PropertyGetListFiltersByLeaseTransactionExtended;
      circle?: object;
      polygon?: object;
      bounds?: object;
    };
    toggle: (isFullScreen: boolean) => void;
    resetFilter: (filterType?: FilterTypeEnum) => void;
    onClear: () => void;
  };
  Map?: {
    isOpen: boolean;
    toggle: () => void;
  };
  callback?: Function;
}

const DataListControls: FC<Props> = (props) => {
  const {
    pageTitle,
    searchPlaceholder,
    searchValue,
    onSearch,
    onChange,
    Filters,
    Map,
    callback,
  } = props;

  return (
    <header className="datalist__header">
      <div className="first-row">
        <h1 className="first-row__heading">{pageTitle}</h1>
        {props.onAddNew && (
          <Button
            className="button-add-new"
            variant="light"
            onClick={props.onAddNew}>
            Add new
          </Button>
        )}
      </div>

      <div className="second-row">
        <Input
          className="second-row__search"
          placeholder={searchPlaceholder}
          value={searchValue}
          onChange={onChange}
          onEnter={() => onSearch && onSearch()}
          callback={() => callback && callback()}
          search
        />

        {Map && (
          <Button
            className="toggle-button toggle-button_map"
            variant="toggle-light"
            onClick={Map.toggle}>
            {!Map.isOpen ? (
              <MarkerPinIcon />
            ) : (
              <BurgerIcon color={Color.mainDark} width={20} height={12} />
            )}
          </Button>
        )}

        {Filters && !Filters?.isOpen ? (
          <Button
            className="toggle-button toggle-button_filters"
            variant="toggle-light"
            onClick={() => Filters?.toggle(false)}>
            <FiltersIcon />
          </Button>
        ) : Filters?.isOpen ? (
          <Button
            className="toggle-button close-filters-button"
            variant="toggle-dark"
            onClick={() => Filters?.toggle(false)}
          />
        ) : null}
      </div>

      {Filters?.applied &&
        Object.values(Filters.applied).some(
          (filterType) =>
            !isEmpty(omit(filterType, ['listPriceUnit', 'soldPriceUnit'])),
        ) && (
          <div className="search-filters">
            <ul className="applied-filters-list">
              {Object.entries(Filters.applied)
                .filter(([, appliedFilters]) => !isEmpty(appliedFilters))
                .map(([filterType, appliedFilters]) => {
                  if (appliedFilters) {
                    let filtersCount = Object.keys(appliedFilters).length;
                    if (filterType === 'bounds') return null;
                    if (filterType === 'sale') {
                      filtersCount = Object.keys(appliedFilters).filter(
                        (filterKey) => {
                          return (
                            filterKey !== 'listPriceUnit' &&
                            filterKey !== 'soldPriceUnit'
                          );
                        },
                      ).length;
                    }

                    if (
                      filterType === 'circle' ||
                      filterType === 'polygon' ||
                      filterType === 'bounds'
                    ) {
                      filterType = 'Map';
                      filtersCount = 1;
                    }

                    if (
                        filterType === 'selectedTags'
                    ) {
                      filterType = 'selected-tags';
                    }
                    if (
                        filterType === 'excludedTags'
                    ) {
                      filterType = 'excluded-tags';
                    }

                    return filtersCount > 0 ? (
                      <div className="filters-element" key={filterType}>
                        <li
                          className="applied-filters-list__item">
                          <span className="applied-filters-list__item-title">
                            {filterType}
                          </span>
                          <span className="applied-filters-list__item-line" />
                          <span className="applied-filters-list__item-count">
                            {filtersCount}
                          </span>
                          <div
                            className="applied-filters-list__item-close"
                            onClick={() =>
                              Filters.resetFilter(filterType as FilterTypeEnum)
                            }>
                            <CloseSmallIcon />
                          </div>
                        </li>
                      </div>
                    ) : null;
                  }
                  return null;
                })}
            </ul>
          </div>
        )}
    </header>
  );
};

export default DataListControls;
