import React, { FC, ComponentProps } from 'react'

interface Props extends ComponentProps<'svg'> {
   color?: string
}

export const ClockIcon: FC<Props> = ({ color, ...props }) => (
   <svg
      {...props}
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M5.98098 3.18164L5.97998 5.43398C5.97998 5.5195 6.01611 5.60124 6.08038 5.6616L7.79539 7.27255L8.27995 6.81731L6.66531 5.30066L6.6663 3.18164H5.98098Z"
         fill={color}
         fillOpacity="0.6"
      />
      <path
         d="M6.46867 0C3.93909 0 1.84555 1.86698 1.49492 4.28572H0L1.79685 6.07143L3.5937 4.28572H2.22114C2.56494 2.26205 4.336 0.714293 6.46867 0.714293C8.84667 0.714293 10.7811 2.63672 10.7811 5.00001C10.7811 7.3633 8.84669 9.28573 6.46867 9.28573C5.13191 9.28573 3.89237 8.68445 3.06798 7.63603L2.50155 8.07548C3.46314 9.29827 4.90904 10 6.46867 10C9.2429 10 11.4998 7.75669 11.4998 5.00001C11.4998 2.24333 9.2429 2.07366e-05 6.46867 0Z"
         fill={color}
         fillOpacity="0.6"
      />
   </svg>
)

ClockIcon.defaultProps = {
   color: "#1F2531"
}