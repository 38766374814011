import React, { Component } from 'react';
import { UserRoleDTO } from '@ternala/voltore-types';
import { CardEntityEnum } from "@ternala/voltore-types/lib/constants";
import { Moment } from 'moment';

// Interfaces
import { ReminderEditorStepEnum } from "../../model.d";

//Custom components
import BackArrow from '../../../icons/NoteCreator/BackArrow';
import ReminderDatePicker from '../Controls/ReminderDatePicker';
import DescriptionTextarea from '../Controls/DescriptionTextarea';

//Contexts
import { FocusToMainContext } from '../../Contexts';
import ActionListMention from "../Actions/ActionListMention";

export interface IActionBlockProps {
  step: ReminderEditorStepEnum;

  value: string;
  descriptionValue: string;
  roleValue: string;
  date?: Moment;

  role?: UserRoleDTO;
  dateObj?: Date;
  description?: string;

  closeEditor: () => void;

  //Focus props
  setHasFocus: (isHasFocus: boolean) => void;
  hasFocus: boolean;

  //Role props
  onRoleSelected: (role: UserRoleDTO) => void;
  onRemoveRole: () => void;

  //Date props
  onDateSelected: (date: Moment) => void;
  onDateChanged: (date: Moment) => void;
  onRemoveDate: () => void;

  //Description props
  onDescriptionChanged: (val: string) => void;
  onRemoveDescription: () => void;
}

interface IState {
  date?: Date;
}

class ActionBlockComponent extends Component<IActionBlockProps, IState> {
  private wrapperRef = React.createRef<HTMLDivElement>();
  constructor(props: IActionBlockProps) {
    super(props);
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.handleButtonKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleButtonKeyDown);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (this.wrapperRef?.current?.contains(event.target)) {
      if (!this.props.hasFocus) {
        this.props.setHasFocus(true);
      }
    } else {
      if (this.props.hasFocus) {
        this.props.setHasFocus(false);
      }
    }
  };

  private handleButtonKeyDown = (e: KeyboardEvent) => {
    const { step, value } = this.props;
    if (e.code === 'Enter') {
      if (step === ReminderEditorStepEnum.description && value) {
        let { moveFocusToMain } = this.context;
        moveFocusToMain();
      }
    }
  };

  private generateActionList = () => {
    let { onRoleSelected, roleValue } = this.props;
    return (
      <>
        <ActionListMention
          searchValue={roleValue.trim()}
          type={CardEntityEnum.Role}
          onSelect={(action) =>  onRoleSelected(action.data as UserRoleDTO)}
        />
        {roleValue.length === 0 ? <div className="actions-list__alert">
          <span>Continue typing user name or role to add</span>
          <ul>
            <li>Reminder (Works as Task)</li>
          </ul>
        </div>: ""}
      </>

    );
  };

  private generateTimeBlock = () => {
    const { date, onDateChanged, onDateSelected } = this.props;
    return (
      <ReminderDatePicker
        date={date}
        onChange={onDateChanged}
        submit={onDateSelected}
      />
    );
  };

  private generateDescriptionBlock = () => {
    const { descriptionValue, onDescriptionChanged } = this.props;
    return <DescriptionTextarea value={descriptionValue} onChange={onDescriptionChanged} />;
  };

  private generateContent() {
    switch (this.props.step) {
      case ReminderEditorStepEnum.role: {
        return this.generateActionList();
      }
      case ReminderEditorStepEnum.date: {
        return this.generateTimeBlock();
      }
      case ReminderEditorStepEnum.description: {
        return this.generateDescriptionBlock();
      }
      default: {
        return this.generateActionList();
      }
    }
  }

  render() {
    return (
      <div className="note-creator__actions-list_items" ref={this.wrapperRef}>
        <header className="actions-list__header">
          {this.props.role ? (
            this.props.dateObj ? (
              <div className={'actions-list__header--row'}>
                <BackArrow
                  className={'actions-list__header--back-arrow'}
                  onClick={this.props.onRemoveDate}
                />
                <h5 className={"heading"}>Remind</h5>
              </div>
            ) : (
              <div className={'actions-list__header--row'}>
                <BackArrow
                  className={'actions-list__header--back-arrow'}
                  onClick={this.props.onRemoveRole}
                />
                <h5 className={"heading"}>Specify due date</h5>
              </div>
            )
          ) : (
            <div className={'actions-list__header--row'}>
              <BackArrow
                className={'actions-list__header--back-arrow'}
                onClick={this.props.closeEditor}
              />
              <h5 className={"heading"}>Choose Assignee</h5>
            </div>
          )}
        </header>
        {this.generateContent()}
      </div>
    );
  }
}

ActionBlockComponent.contextType = FocusToMainContext;

export default ActionBlockComponent;
