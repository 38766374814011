/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
import React, { Component, RefObject } from 'react'

/* config */
import { MAP_OPTIONS } from 'config/google-maps'

/* context */
import { GoogleMapsAPIContext } from 'context/GoogleMapsAPI'

/* styles */
import stylesWithBusinessLabels from './styles/with-business-labels'
import stylesWithoutBusinessLabels from './styles/without-business-labels'

/* controls */
import { ZoomControls } from './map-controls'
import { SingleMarker } from './markers'

/* types */
import { IPoint } from '@ternala/voltore-types/lib/modules/property/filters/area'

interface Props {
   containerStyle?: { [key: string]: number | string }
   className?: string
   propertyCoords?: IPoint  
   width?: number | '100%'
   height?: number
   tip?: string
   required?: boolean
   error?: boolean
   linked?: boolean
   isTip?:boolean
   isBottomLeftControls?: boolean
   onMarkerPlaced: (coords: IPoint) => void
   dataSetter?: (value: any) => void
}

export class PropertyLocationMap extends Component<Props> {
   constructor(props: Props) {
      super(props)
      this.mapRef = React.createRef()
      this.marker = null
   }

   static contextType = GoogleMapsAPIContext
   private mapsAPI: typeof google.maps | undefined
   private map: google.maps.Map | undefined
   private mapRef: RefObject<HTMLDivElement>
   public marker: any

   componentDidMount() {
      this.initializeMap();
   }

   componentDidUpdate(
     prevProps: Readonly<Props>,
     prevState: Readonly<{}>,
     snapshot?: any,
   ) {

      if(prevProps.propertyCoords?.longitude !== this.props.propertyCoords?.longitude ||
        prevProps.propertyCoords?.latitude !== this.props.propertyCoords?.latitude || 
        prevProps.linked !== this.props.linked && this.props.linked !== false 
        ){
         if(this.map && this.props.propertyCoords){

            this.marker.addNewMarker({
               lat: this.props.propertyCoords.latitude,
               lng: this.props.propertyCoords.longitude
            }, this.props.linked)


            if (this.props.linked) {
               this.map.setCenter({
                  lat: this.props.propertyCoords.latitude,
                  lng: this.props.propertyCoords.longitude
               })
            }
         }
      }
   }

   async initializeMap() {
      const { GoogleMaps } = this.context

      if (GoogleMaps && this.mapRef.current) {
         this.mapsAPI = GoogleMaps
         this.map = new GoogleMaps.Map(this.mapRef.current, {...MAP_OPTIONS})

         if (this.map && this.props.propertyCoords) {
            const bounds = new GoogleMaps.LatLngBounds()
            bounds.extend({
               lat: this.props.propertyCoords.latitude,
               lng: this.props.propertyCoords.longitude
            })
            this.map.fitBounds(bounds)
         }

         this.applyMapStyles()
      }
   }

   applyMapStyles() {
      if (this.mapsAPI && this.map) {
         /* without business labels */
         const styles = new this.mapsAPI.StyledMapType(stylesWithoutBusinessLabels, { name: 'Styled Map' })
         /* with business labels */
         const stylesBL = new this.mapsAPI.StyledMapType(stylesWithBusinessLabels, { name: 'Styled Map BL' })
         this.map.mapTypes.set('styled_map_bl', styles)
         this.map.setMapTypeId('styled_map_bl')
         this.initializeMapControls()
      }
      
   }

   initializeMapControls() {
      if (this.mapsAPI && this.map && this.mapRef.current) {
         this.marker = new SingleMarker(this.map, this.props.onMarkerPlaced, this.props.propertyCoords, this.props.dataSetter)

         const zoomControls = new ZoomControls(this.mapRef.current, this.map).element

         if (this.props.isBottomLeftControls) {
            zoomControls.setAttribute('style', `
               position: absolute;
               bottom: 12px;
            `)
         } else {
            this.map.controls[this.mapsAPI.ControlPosition.LEFT_BOTTOM].push(zoomControls)
         }
         
      }
   }

   render() {

      const { containerStyle, className, width, height, tip, required, error } = this.props

      return (
         <div className="property-location-map" style={{ ...containerStyle }}>
            <div ref={this.mapRef} className={className || ''} style={{ width, height }} />
            {this.props.isTip && <div className={"property-location-map__tip" + (error ? " property-location-map__tip_error" : "")}>
               { tip }
               { required && <span> *</span> }
            </div>}
         </div>
      )
   }
}

export default PropertyLocationMap