import { all } from 'redux-saga/effects';
import { createReducer, ActionType } from 'typesafe-actions';

// Actions
import * as actions from './actions';

// Interfaces
import { LoaderActionType } from 'controllers/store';
import { IHistoryState } from './models';
import { generateLoaderActions } from 'controllers/based';
import { historyActionSaga } from './sagas/history';

//Sagas
export type HistoryАctionType = ActionType<typeof actions>;
export const historySaga = function* () {
  yield all([historyActionSaga()]);
};

/* Reducer */
const initialState: IHistoryState = {
  state: {
    errors: [],
    loaders: [],
  },
  history: [],
};

type ActionTypes = HistoryАctionType | LoaderActionType;

const loaderActions = generateLoaderActions<IHistoryState, ActionTypes>(
  actions.widgetName,
);

export const loaderHandlers = loaderActions.handlers;

export const historyReducer = createReducer<IHistoryState, ActionTypes>(
  initialState,
  {
    ...loaderHandlers,
  },
).handleAction(
  [actions.getHistoryAction.success],
  (state: IHistoryState, { payload }): IHistoryState => {
    return {
      ...state,
      history: payload.items,
    };
  },
);

/* Loader actions */
export const addLoader = loaderActions.actions.addLoader;
export const addError = loaderActions.actions.addError;
export const removeError = loaderActions.actions.removeError;
export const removeLoader = loaderActions.actions.removeLoader;
