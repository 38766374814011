import React from 'react';

export const NotesIcon = () => (
  <svg
    width='15'
    height='15'
    viewBox='0 0 15 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.49219 1.85534C3.49219 2.16188 3.74086 2.41055 4.0474 2.41055C4.35394 2.41055 4.60261 2.16188 4.60261 1.85534V0.674805H7.21484V1.85534C7.21484 2.16187 7.46351 2.41055 7.77005 2.41055C8.07658 2.41055 8.32525 2.16187 8.32525 1.85534V0.674805H10.2617V1.85534C10.2617 2.16187 10.5104 2.41055 10.8169 2.41055C11.1235 2.41055 11.3721 2.16187 11.3721 1.85534V0.674805H12.9992C13.7172 0.674805 14.2992 1.25683 14.2992 1.9748V12.9748C14.2992 13.6928 13.7172 14.2748 12.9992 14.2748H1.99922C1.28125 14.2748 0.699219 13.6928 0.699219 12.9748V1.9748C0.699219 1.25683 1.28125 0.674805 1.99922 0.674805H3.49219V1.85534ZM3.77604 10.9954H11.224C11.4108 10.9954 11.5625 10.8438 11.5625 10.6569C11.5625 10.47 11.4108 10.3184 11.224 10.3184H3.77604C3.58917 10.3184 3.4375 10.47 3.4375 10.6569C3.4375 10.8438 3.58917 10.9954 3.77604 10.9954ZM11.224 8.28742H3.77604C3.58917 8.28742 3.4375 8.13576 3.4375 7.94889C3.4375 7.76202 3.58917 7.61035 3.77604 7.61035H11.224C11.4108 7.61035 11.5625 7.76202 11.5625 7.94889C11.5625 8.13576 11.4108 8.28742 11.224 8.28742ZM3.77604 5.57846H7.83854C8.02542 5.57846 8.17708 5.42679 8.17708 5.23991C8.17708 5.05304 8.02542 4.90137 7.83854 4.90137H3.77604C3.58917 4.90137 3.4375 5.05304 3.4375 5.23991C3.4375 5.42679 3.58917 5.57846 3.77604 5.57846Z'
      fill='white'
    />
  </svg>
);
