import { all, put, takeEvery, call } from 'redux-saga/effects';

//APIs
import { InvolvementApi } from '../transport/involvement.api';

// Actions
import {
  getInvolvementsAction,
  getInvolvementAction,
  createInvolvementAction,
  updateInvolvementAction,
  deleteInvolvementAction,
  addInvolvementLoader,
  addInvolvementError,
  removeInvolvementLoader,
} from '../actions';
import { involvementLoaderType } from '../../store.d';

// Utils
import uuid from 'utils/uuid';

// Constants
import { LoaderAction } from 'config/constants';
import {
  InvolvementCreateResponse,
  InvolvementDeleteResponse,
  InvolvementGetListResponse,
  InvolvementGetResponse,
  InvolvementUpdateResponse,
} from '@ternala/voltore-types';
import { IError } from '../../model';
import { addError } from '../../enterprise';
import { getAccessTokenSaga } from '../../auth/sagas/auth';

export function* getInvolvementsSaga({
  payload,
}: ReturnType<typeof getInvolvementsAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();

  const { additionalFields } = payload;

  let type;
  switch (additionalFields.timeMarker) {
    case 'past':
      type = involvementLoaderType.pastInvolvements;
      break;
    case 'current':
      type = involvementLoaderType.pastInvolvements;
      break;
    default:
      type = involvementLoaderType.pastInvolvements;
  }

  yield put(
    addInvolvementLoader({
      id: loadId,
      message: 'Please wait, involvements are loading!',
      type: LoaderAction.involvement.getList,
      additionalFields,
    }),
  );

  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: InvolvementGetListResponse | string | IError =
      yield InvolvementApi.getInvolvements(
        {
          ...additionalFields,
          ...data,
        },
        accessToken,
      );

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getInvolvementsAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getInvolvementsAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get involvements!',
          type: LoaderAction.involvement.getList,
        }),
      );
    } else {
      yield put(
        getInvolvementsAction.success({
          res,
          isAll: payload.limit ? res.items.length < payload.limit : true,
          additionalFields,
          searchParams: payload,
        }),
      );
      yield put(
        removeInvolvementLoader({
          id: loadId,
          additionalFields,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback();
    }
  } catch (error: any) {
    console.error('error: ', error);
    yield put(
      getInvolvementsAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addInvolvementError({
        id: loadId,
        message: 'Failed to get involvements!',
        type: LoaderAction.involvement.getError,
        additionalFields,
      }),
    );
  }
}

export function* createInvolvementSaga({
  payload,
}: ReturnType<typeof createInvolvementAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const { additionalFields } = payload;
  const loadId = uuid();
  yield put(
    addInvolvementLoader({
      id: loadId,
      message: 'Please wait, involvements are loading!',
      type: LoaderAction.involvement.create,
      additionalFields,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: InvolvementCreateResponse | string | IError =
      yield InvolvementApi.createInvolvement(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getInvolvementsAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getInvolvementsAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get involvements!',
          type: LoaderAction.involvement.create,
        }),
      );
    } else {
      yield put(
        createInvolvementAction.success({
          res,
          additionalFields,
        }),
      );
      yield put(
        removeInvolvementLoader({
          id: loadId,
          additionalFields,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(
          true,
          `${res.person?.firstName} ${res.person?.lastName} - ${res.involvementType?.title}`,
          res.id,
        );
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      createInvolvementAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addInvolvementError({
        id: loadId,
        message: 'Failed to create involvement!',
        type: LoaderAction.involvement.create,
        additionalFields,
      }),
    );
  }
}

export function* getInvolvementSaga({
  payload,
}: ReturnType<typeof getInvolvementAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const { additionalFields } = payload;
  const loadId = uuid();
  yield put(
    addInvolvementLoader({
      id: loadId,
      message: 'Please wait, involvement is getting!',
      type: LoaderAction.involvement.getItem,
      additionalFields,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: InvolvementGetResponse | string | IError =
      yield InvolvementApi.getInvolvement(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getInvolvementAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getInvolvementAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to create involvement!',
          type: LoaderAction.involvement.getItem,
        }),
      );
    } else {
      yield put(
        getInvolvementAction.success({
          res,
          additionalFields,
        }),
      );
      yield put(
        removeInvolvementLoader({
          id: loadId,
          additionalFields,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback();
    }
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getInvolvementAction.failure({
        key: String(payload.id),
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addInvolvementError({
        key: String(payload.id),
        id: loadId,
        message: 'Failed to get involvement!',
        type: LoaderAction.involvement.getError,
        additionalFields,
      }),
    );
  }
}

export function* updateInvolvementSaga({
  payload,
}: ReturnType<typeof updateInvolvementAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const { additionalFields } = payload;
  const loadId = uuid();
  yield put(
    addInvolvementLoader({
      id: loadId,
      message: 'Please wait, involvement is updating!',
      type: LoaderAction.involvement.update,
      additionalFields,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: InvolvementUpdateResponse | string | IError =
      yield InvolvementApi.updateInvolvement(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        updateInvolvementAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          updateInvolvementAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to update involvement!',
          type: LoaderAction.involvement.update,
        }),
      );
    } else {
      yield put(
        updateInvolvementAction.success({
          res,
          additionalFields,
        }),
      );
      yield put(
        removeInvolvementLoader({
          id: loadId,
          additionalFields,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      updateInvolvementAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addInvolvementError({
        id: loadId,
        message: 'Failed to update involvement!',
        type: LoaderAction.involvement.getError,
        additionalFields,
      }),
    );
  }
}

export function* deleteInvolvementSaga({
  payload,
}: ReturnType<typeof deleteInvolvementAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const { additionalFields } = payload;
  const loadId = uuid();
  yield put(
    addInvolvementLoader({
      id: loadId,
      message: 'Please wait, involvement is deleting!',
      type: LoaderAction.involvement.delete,
      additionalFields,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: InvolvementDeleteResponse | string =
      yield InvolvementApi.deleteInvolvement(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string') {
      deleteInvolvementAction.failure({
        code: res,
        message: res || 'Something was wrong',
      });
      yield put(
        addError({
          id: loadId,
          message: 'Failed to delete involvement!',
          type: LoaderAction.involvement.delete,
        }),
      );
    } else {
      yield put(
        deleteInvolvementAction.success({
          res,
          id: payload.id,
          additionalFields,
        }),
      );
      yield put(
        removeInvolvementLoader({
          id: loadId,
          additionalFields,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      deleteInvolvementAction.failure({
        code: error.code || 403,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addInvolvementError({
        id: loadId,
        message: 'Failed to delete involvement!',
        type: LoaderAction.involvement.getError,
        additionalFields,
      }),
    );
  }
}

export function* involvementActionSaga() {
  yield all([
    takeEvery(getInvolvementsAction.request, getInvolvementsSaga),
    takeEvery(getInvolvementAction.request, getInvolvementSaga),
    takeEvery(createInvolvementAction.request, createInvolvementSaga),
    takeEvery(updateInvolvementAction.request, updateInvolvementSaga),
    takeEvery(deleteInvolvementAction.request, deleteInvolvementSaga),
  ]);
}
