import React, { FC } from 'react';

export const CloseIconModals: FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4748 0.521657C11.7629 0.80976 11.7629 1.27687 11.4748 1.56497L1.56336 11.4765C1.27525 11.7646 0.80815 11.7646 0.520048 11.4765C0.231947 11.1884 0.231947 10.7212 0.520049 10.4331L10.4315 0.521657C10.7196 0.233554 11.1867 0.233554 11.4748 0.521657Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4783 11.4787C11.1902 11.7668 10.7231 11.7668 10.435 11.4787L0.523616 1.5672C0.235514 1.2791 0.235514 0.811993 0.523615 0.523889C0.811717 0.235786 1.27882 0.235787 1.56692 0.52389L11.4783 10.4354C11.7664 10.7235 11.7664 11.1906 11.4783 11.4787Z"
      fill="white"
    />
  </svg>
);
