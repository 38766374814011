import { all, put, takeEvery } from "redux-saga/effects";

//APIs
import { GoogleSearchAPI } from "../transport/googleMaps.api";

// actions 
import { searchRequestAction } from '../actions';

import  {IGoogleApiSearchResponse} from '../models';
  export function*  googleSearchSaga({
      payload,
    }: ReturnType<typeof searchRequestAction.request>) {

  try {
    const res: IGoogleApiSearchResponse = yield (GoogleSearchAPI.getListOfGeoObjectsByApi(payload));
    
    if (res.status === 'OK') {
        yield put(searchRequestAction.success(res))
    } else {
      yield put(searchRequestAction.failure(res));
    }
  } catch (error) {
    yield put(searchRequestAction.failure({results: [], status: 'NOT OK'}));
  }
}


export function* googleSearchSagas() {
  yield all([
    takeEvery(searchRequestAction.request, googleSearchSaga)
  ]);
}
