import { appName } from 'config';
import { createAsyncAction } from 'typesafe-actions';
import { IException } from './models';
import {
  TaxonomyCreateRequest,
  TaxonomyCreateResponse,
  TaxonomyDeleteRequest,
  TaxonomyDeleteResponse,
  TaxonomyGetByTypeRequest,
  TaxonomyGetByTypeResponse,
  TaxonomyGetListRequest,
  TaxonomyGetListResponse,
  TaxonomyGetRequest,
  TaxonomyGetResponse,
  TaxonomyUpdateRequest,
  TaxonomyUpdateResponse,
} from '@ternala/voltore-types';
import { TaxonomyTypeSlug } from '@ternala/voltore-types/lib/constants';
import { TaxonomyTypeGetListRequest } from '@ternala/voltore-types/lib/modules/taxonomy/type/request.dto';
import { TaxonomyTypeGetListResponse } from '@ternala/voltore-types/lib/modules/taxonomy/type/response.dto';

/* Actions */
export const widgetName = 'taxonomy';

// ** Action
export const getTaxonomiesAction = createAsyncAction(
  `${appName}/${widgetName}/GET_ALL_TAXONOMIES_REQUEST`,
  `${appName}/${widgetName}/GET_ALL_TAXONOMIES_SUCCESS`,
  `${appName}/${widgetName}/GET_ALL_TAXONOMIES_FILED`,
)<TaxonomyGetListRequest & { callback?: Function }, TaxonomyGetListResponse, IException>();

export const getTaxonomyTypeListAction = createAsyncAction(
  `${appName}/${widgetName}/GET_TAXONOMY_TYPE_LIST_REQUEST`,
  `${appName}/${widgetName}/GET_TAXONOMY_TYPE_LIST_SUCCESS`,
  `${appName}/${widgetName}/GET_TAXONOMY_TYPE_LIST_FILED`,
)<
  TaxonomyTypeGetListRequest & { callback?: Function },
  TaxonomyTypeGetListResponse,
  IException
>();

export const getTaxonomiesByTypeAction = createAsyncAction(
  `${appName}/${widgetName}/GET_TAXONOMIES_REQUEST`,
  `${appName}/${widgetName}/GET_TAXONOMIES_SUCCESS`,
  `${appName}/${widgetName}/GET_TAXONOMIES_FILED`,
)<
  TaxonomyGetByTypeRequest,
  TaxonomyGetByTypeResponse & { slug: TaxonomyTypeSlug },
  IException
>();

export const createTaxonomyAction = createAsyncAction(
  `${appName}/${widgetName}/CREATE_TAXONOMY_REQUEST`,
  `${appName}/${widgetName}/CREATE_TAXONOMY_SUCCESS`,
  `${appName}/${widgetName}/CREATE_TAXONOMY_FILED`,
)<
  TaxonomyCreateRequest & { callback?: (taxonomy: TaxonomyCreateResponse | false) => void },
  TaxonomyCreateResponse,
  IException
>();

export const getTaxonomyAction = createAsyncAction(
  `${appName}/${widgetName}/GET_TAXONOMY_REQUEST`,
  `${appName}/${widgetName}/GET_TAXONOMY_SUCCESS`,
  `${appName}/${widgetName}/GET_TAXONOMY_FILED`,
)<TaxonomyGetRequest & { callback?: Function }, TaxonomyGetResponse, IException>();

export const updateTaxonomyAction = createAsyncAction(
  `${appName}/${widgetName}/UPDATE_TAXONOMY_REQUEST`,
  `${appName}/${widgetName}/UPDATE_TAXONOMY_SUCCESS`,
  `${appName}/${widgetName}/UPDATE_TAXONOMY_FILED`,
)<
  TaxonomyUpdateRequest & { callback?: (taxonomy: TaxonomyUpdateResponse | false) => void},
  TaxonomyUpdateResponse,
  IException
>();

export const deleteTaxonomyAction = createAsyncAction(
  `${appName}/${widgetName}/DELETE_TAXONOMY_REQUEST`,
  `${appName}/${widgetName}/DELETE_TAXONOMY_SUCCESS`,
  `${appName}/${widgetName}/DELETE_TAXONOMY_FILED`,
)<
  TaxonomyDeleteRequest & { callback?: Function },
  TaxonomyDeleteResponse & { id?: number },
  IException
>();
