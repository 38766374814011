export interface ISettingItem {
  title: string;
  key: string;
}

export type settingPages = 'user-role'

export const settingsList: ISettingItem[] = [
  {
    title: "User role",
    key: "user-role"
  }
]