import { all } from 'redux-saga/effects';
import { createReducer, ActionType, getType } from 'typesafe-actions';
import { omit } from 'lodash';

// Actions
import * as actions from './actions';

// Interfaces
import { LoaderActionType } from 'controllers/store';
import { IPropertyState } from './models';

//Sagas
import { propertyActionSaga } from './sagas/property';
import { saleTransactionActionSaga } from './sagas/saleTransactions';
import { leaseTransactionActionSaga } from './sagas/leaseTransaction';
import { concatWithUnique } from 'utils/concatWithUnique';
import { widgetName } from './actions';
import { generateLoaderActions } from '../based';
import { IError, ILoader } from 'models';
import { additionalSaga } from './sagas/additional';
import { SuiteDTO } from '@ternala/voltore-types';

export type PropertyActionType = ActionType<typeof actions>;

export const propertySaga = function* () {
  yield all([
    propertyActionSaga(),
    saleTransactionActionSaga(),
    leaseTransactionActionSaga(),
    additionalSaga(),
  ]);
};

/* Reducer */
const initialState: IPropertyState = {
  properties: [],
  newProperties: [],
  state: {
    errors: [],
    loaders: [],
  },
  propertyData: {},
  saleTransactionData: {},
  leaseTransactionData: {},
  storedSearchParams: null,
  searchParams: null,
};

type ActionTypes = PropertyActionType | LoaderActionType;

const loaderActions = generateLoaderActions<IPropertyState, ActionTypes>(
  widgetName,
);

export const loaderHandlers = loaderActions.handlers;

export const propertyReducer = createReducer<IPropertyState, ActionTypes>(
  initialState,
  {
    ...loaderHandlers,
    [getType(actions.addSaleTransactionLoader)]: (
      state: IPropertyState,
      action: any,
    ) => ({
      ...state,
      propertyData: {
        ...state.propertyData,
        [action.payload.additionalFields.property]: {
          ...state.propertyData[action.payload.additionalFields.property],
          saleTransactionState: {
            ...(state.propertyData[action.payload.additionalFields.property]
              ?.saleTransactionState || {}),
            state: {
              loaders: [
                ...(state.propertyData[action.payload.additionalFields.property]
                  ?.saleTransactionState?.state?.loaders || []),
                action.payload,
              ],
              errors:
                state.propertyData[
                  action.payload.additionalFields.property
                ]?.saleTransactionState?.state?.errors.filter(
                  (error: IError) => error.type !== action.payload.type,
                ) || [],
            },
          },
        },
      },
    }),
    [getType(actions.addSaleTransactionError)]: (
      state: IPropertyState,
      action: any,
    ) => ({
      ...state,
      propertyData: {
        ...state.propertyData,
        [action.payload.additionalFields.property]: {
          ...state.propertyData[action.payload.additionalFields.property],
          saleTransactionState: {
            ...(state.propertyData[action.payload.additionalFields.property]
              ?.saleTransactionState || {}),
            state: {
              loaders: [
                ...(state.propertyData[action.payload.additionalFields.property]
                  ?.saleTransactionState?.state?.loaders || []),
              ].filter((loader: ILoader) => loader.id !== action.payload.id),
              errors: [
                ...(state.propertyData[action.payload.additionalFields.property]
                  ?.saleTransactionState?.state?.errors || []),
                action.payload,
              ],
            },
          },
        },
      },
    }),
    [getType(actions.removeSaleTransactionLoader)]: (
      state: IPropertyState,
      action: any,
    ) => ({
      ...state,
      propertyData: {
        ...state.propertyData,
        [action.payload.additionalFields.property]: {
          ...state.propertyData[action.payload.additionalFields.property],
          saleTransactionState: {
            ...(state.propertyData[action.payload.additionalFields.property]
              ?.saleTransactionState || {}),
            state: {
              loaders: [
                ...(state.propertyData[action.payload.additionalFields.property]
                  ?.saleTransactionState?.state?.loaders || []),
              ].filter((loader: ILoader) => loader.id !== action.payload.id),
              errors:
                state.propertyData[action.payload.additionalFields.property]
                  ?.saleTransactionState?.state?.errors || [],
            },
          },
        },
      },
    }),
    [getType(actions.removeSaleTransactionError)]: (
      state: IPropertyState,
      action: any,
    ) => ({
      ...state,
      propertyData: {
        ...state.propertyData,
        [action.payload.additionalFields.property]: {
          ...state.propertyData[action.payload.additionalFields.property],
          saleTransactionState: {
            ...(state.propertyData[action.payload.additionalFields.property]
              ?.saleTransactionState || {}),
            state: {
              loaders: [
                ...(state.propertyData[action.payload.additionalFields.property]
                  ?.saleTransactionState?.state?.loaders || []),
              ],
              errors:
                state.propertyData[
                  action.payload.additionalFields.property
                ]?.saleTransactionState?.state?.errors.filter(
                  (error: IError) => error.type !== action.payload.type,
                ) || [],
            },
          },
        },
      },
    }),

    [getType(actions.addLeaseTransactionLoader)]: (
      state: IPropertyState,
      action: any,
    ) => ({
      ...state,
      propertyData: {
        ...state.propertyData,
        [action.payload.additionalFields.property]: {
          ...state.propertyData[action.payload.additionalFields.property],
          leaseTransactionState: {
            ...(state.propertyData[action.payload.additionalFields.property]
              ?.leaseTransactionState || {}),
            state: {
              loaders: [
                ...(state.propertyData[action.payload.additionalFields.property]
                  ?.leaseTransactionState?.state?.loaders || []),
                action.payload,
              ],
              errors:
                state.propertyData[
                  action.payload.additionalFields.property
                ]?.leaseTransactionState?.state?.errors.filter(
                  (error: IError) => error.type !== action.payload.type,
                ) || [],
            },
          },
        },
      },
    }),
    [getType(actions.addLeaseTransactionError)]: (
      state: IPropertyState,
      action: any,
    ) => ({
      ...state,
      propertyData: {
        ...state.propertyData,
        [action.payload.additionalFields.property]: {
          ...state.propertyData[action.payload.additionalFields.property],
          leaseTransactionState: {
            ...(state.propertyData[action.payload.additionalFields.property]
              ?.leaseTransactionState || {}),
            state: {
              loaders: [
                ...(state.propertyData[action.payload.additionalFields.property]
                  ?.leaseTransactionState?.state?.loaders || []),
              ].filter((loader: ILoader) => loader.id !== action.payload.id),
              errors: [
                ...(state.propertyData[action.payload.additionalFields.property]
                  ?.leaseTransactionState?.state?.errors || []),
                action.payload,
              ],
            },
          },
        },
      },
    }),
    [getType(actions.removeLeaseTransactionLoader)]: (
      state: IPropertyState,
      action: any,
    ) => ({
      ...state,
      propertyData: {
        ...state.propertyData,
        [action.payload.additionalFields.property]: {
          ...state.propertyData[action.payload.additionalFields.property],
          leaseTransactionState: {
            ...(state.propertyData[action.payload.additionalFields.property]
              ?.leaseTransactionState || {}),
            state: {
              loaders: [
                ...(state.propertyData[action.payload.additionalFields.property]
                  ?.leaseTransactionState?.state?.loaders || []),
              ].filter((loader: ILoader) => loader.id !== action.payload.id),
              errors:
                state.propertyData[action.payload.additionalFields.property]
                  ?.leaseTransactionState?.state?.errors || [],
            },
          },
        },
      },
    }),
    [getType(actions.removeLeaseTransactionError)]: (
      state: IPropertyState,
      action: any,
    ) => ({
      ...state,
      propertyData: {
        ...state.propertyData,
        [action.payload.additionalFields.property]: {
          ...state.propertyData[action.payload.additionalFields.property],
          leaseTransactionState: {
            ...(state.propertyData[action.payload.additionalFields.property]
              ?.leaseTransactionState || {}),
            state: {
              loaders: [
                ...(state.propertyData[action.payload.additionalFields.property]
                  ?.leaseTransactionState?.state?.loaders || []),
              ],
              errors:
                state.propertyData[
                  action.payload.additionalFields.property
                ]?.leaseTransactionState?.state?.errors.filter(
                  (error: IError) => error.type !== action.payload.type,
                ) || [],
            },
          },
        },
      },
    }),

    [getType(actions.createPropertySuiteAction.success)]: (
      state: IPropertyState,
      action: any,
    ) => ({
      ...state,
      propertyData: {
        ...state.propertyData,
        [action.payload.additionalFields.property]: {
          ...state.propertyData[action.payload.additionalFields.property],
          suites: [
            ...state.propertyData[action.payload.additionalFields.property]
              ?.suites,
            omit(action.payload.response, ['payload']) as SuiteDTO,
          ],
        },
      },
    }),
    [getType(actions.updatePropertyFloorListAction.success)]: (
      state: IPropertyState,
      action: any,
    ) => ({
      ...state,
      propertyData: {
        ...state.propertyData,
        [action.payload.additionalFields.property]: {
          ...state.propertyData[action.payload.additionalFields.property],
          floors: [...action.payload.response.floors],
        },
      },
    }),

    [getType(actions.createLeaseTransactionAction.success)]: (
      state: IPropertyState,
      action,
    ): IPropertyState => {
      const { payload } = action as ReturnType<
        typeof actions.createLeaseTransactionAction.success
      >;
      return {
        ...state,
        leaseTransactionData: {
          ...state.leaseTransactionData,
          [payload.response.id]: {
            ...state.leaseTransactionData[payload.response.id],
            ...payload.response,
          },
        },
        propertyData: {
          ...state.propertyData,
          ...(payload.additionalFields.property &&
          state.propertyData[payload.additionalFields.property]
            ? {
                [payload.additionalFields.property]: {
                  ...state.propertyData[payload.additionalFields.property],
                  leaseTransactionState: {
                    ...state.propertyData[payload.additionalFields.property]
                      .leaseTransactionState,
                    newItems: [
                      payload.response.id,
                      ...(state.propertyData[payload.additionalFields.property]
                        .leaseTransactionState?.newItems || []),
                    ],
                  },
                },
              }
            : {}),
        },
      };
    },
    [getType(actions.deleteLeaseTransactionAction.success)]: (
      state: IPropertyState,
      { payload }: any,
    ): IPropertyState => {
      return {
        ...state,
        leaseTransactionData: omit(state.leaseTransactionData, [payload.id]),
        propertyData: {
          ...state.propertyData,
          [payload.additionalFields.property]: {
            ...state.propertyData[payload.additionalFields.property],
            leaseTransactionState: {
              ...(state.propertyData[payload.additionalFields.property]
                ?.leaseTransactionState || {}),
              items: state.propertyData[
                payload.additionalFields.property
              ]?.leaseTransactionState?.items?.filter(
                (transaction) => transaction !== payload.id,
              ),
              newItems: state.propertyData[
                payload.additionalFields.property
              ]?.leaseTransactionState?.newItems?.filter(
                (transaction) => transaction !== payload.id,
              ),
            },
          },
        },
      };
    },

    [getType(actions.createSaleTransactionAction.success)]: (
      state: IPropertyState,
      action,
    ): IPropertyState => {
      const { payload } = action as ReturnType<
        typeof actions.createSaleTransactionAction.success
      >;
      return {
        ...state,
        saleTransactionData: {
          ...state.saleTransactionData,
          [payload.response.id]: {
            ...state.saleTransactionData[payload.response.id],
            ...payload.response,
          },
        },
        propertyData: {
          ...state.propertyData,
          ...(payload.additionalFields.property &&
          state.propertyData[payload.additionalFields.property]
            ? {
                [payload.additionalFields.property]: {
                  ...state.propertyData[payload.additionalFields.property],
                  saleTransactionState: {
                    ...state.propertyData[payload.additionalFields.property]
                      .saleTransactionState,
                    newItems: [
                      payload.response.id,
                      ...(state.propertyData[payload.additionalFields.property]
                        .saleTransactionState?.newItems || []),
                    ],
                  },
                },
              }
            : {}),
        },
      };
    },

    [getType(actions.deleteSaleTransactionAction.success)]: (
      state: IPropertyState,
      { payload }: any,
    ): IPropertyState => {
      return {
        ...state,
        saleTransactionData: omit(state.saleTransactionData, [payload.id]),
        propertyData: {
          ...state.propertyData,
          [payload.additionalFields.property]: {
            ...state.propertyData[payload.additionalFields.property],
            saleTransactionState: {
              ...(state.propertyData[payload.additionalFields.property]
                ?.saleTransactionState || {}),
              items: state.propertyData[
                payload.additionalFields.property
              ]?.saleTransactionState?.items?.filter(
                (transaction) => transaction !== payload.id,
              ),
              newItems: state.propertyData[
                payload.additionalFields.property
              ]?.saleTransactionState?.newItems?.filter(
                (transaction) => transaction !== payload.id,
              ),
            },
          },
        },
      };
    },
    [getType(actions.updateSaleTransactionStatusAction.success)]: (
      state: IPropertyState,
      { payload }: any,
    ): IPropertyState => {
      return {
        ...state,
        saleTransactionData: {
          ...state.saleTransactionData,
          [payload.transaction.id]: {
            ...state.saleTransactionData[payload.transaction.id],
            historyStatuses: state.saleTransactionData[
              payload.transaction.id
            ]?.historyStatuses?.map((status) => {
              if (status.id === payload.id) {
                return payload;
              }
              return undefined;
            }),
            activeStatus:
              state.saleTransactionData[payload.transaction.id]?.activeStatus
                ?.id === payload.id
                ? payload
                : state.saleTransactionData[payload.transaction.id]
                    .activeStatus,
          },
        },
      };
    },
    [getType(actions.updateLeaseTransactionStatusAction.success)]: (
      state: IPropertyState,
      { payload }: any,
    ): IPropertyState => {
      return {
        ...state,
        leaseTransactionData: {
          ...state.leaseTransactionData,
          [payload.transaction.id]: {
            ...state.leaseTransactionData[payload.transaction.id],
            historyStatuses: state.leaseTransactionData[
              payload.transaction.id
            ]?.historyStatuses?.map((status) => {
              if (status.id === payload.id) {
                return payload;
              }
              return undefined;
            }),
            activeStatus:
              state.leaseTransactionData[payload.transaction.id]?.activeStatus
                ?.id === payload.id
                ? payload
                : state.leaseTransactionData[payload.transaction.id]
                    .activeStatus,
          },
        },
      };
    },
    [getType(actions.updateSaleTransactionActiveStatusAction.success)]: (
      state: IPropertyState,
      { payload }: any,
    ): IPropertyState => {
      return {
        ...state,
        saleTransactionData: {
          ...state.saleTransactionData,
          [payload.transaction.id]: {
            ...state.saleTransactionData[payload.transaction.id],
            historyStatuses: [
              ...(state.saleTransactionData[payload.transaction.id]
                ?.historyStatuses || []),
              payload,
            ],
            activeStatus: payload,
          },
        },
      };
    },
    [getType(actions.updateLeaseTransactionActiveStatusAction.success)]: (
      state: IPropertyState,
      { payload }: any,
    ): IPropertyState => {
      return {
        ...state,
        leaseTransactionData: {
          ...state.leaseTransactionData,
          [payload.transaction.id]: {
            ...state.leaseTransactionData[payload.transaction.id],
            historyStatuses: [
              ...(state.leaseTransactionData[payload.transaction.id]
                ?.historyStatuses || []),
              payload,
            ],
            activeStatus: payload,
          },
        },
      };
    },
    [getType(actions.getSaleTransactionLastByChangeStatusAction.success)]: (
      state: IPropertyState,
      { payload }: any,
    ): IPropertyState => {
      return {
        ...state,
        propertyData: {
          ...state.propertyData,
          [payload.additionalFields.property]: {
            ...state.propertyData[payload.additionalFields.property],
            lastSaleTransaction: payload.response,
          },
        },
      };
    },
    [getType(actions.setSearchParams)]: (
      state: IPropertyState,
      { payload }: any,
    ): IPropertyState => {
      const newState = Object.assign({}, state);
      return {
        ...newState,
        searchParams: {
          query: payload.query,
          sortField: payload.sortField,
          area: payload.area,
          property: payload.property,
          saleTransaction: payload.saleTransaction,
          leaseTransaction: payload.leaseTransaction,
          selectedTags: payload.tags,
        },
      };
    },
    [getType(actions.getMapItemsAction.success)]: (
      state: IPropertyState,
      { payload }: any,
    ): IPropertyState => {
      const propertyData = Object.assign({}, state.propertyData);

      payload.response?.properties?.forEach((property: any) => {
        propertyData[property.id] = {
          ...propertyData[property.id],
          ...property,
        };
      });

      return {
        ...state,
        mapItems: {
          ...payload.response,
        },
        propertyData,
      };
    },
  },
)
  .handleAction(
    [actions.getPropertiesAction.success],
    (state: IPropertyState, { payload }): IPropertyState => {
      const storedSearchParams = state.storedSearchParams;
      const { searchParams } = payload;

      const propertyData = {
        ...state.propertyData,
      };

      payload.response.items.forEach((property) => {
        propertyData[property.id] = {
          ...propertyData[property.id],
          ...property,
          address: {
            ...propertyData[property.id]?.address,
            ...property?.address,
          },
        };
      });

      let newPropertyList;
      if (
        JSON.stringify(omit(storedSearchParams, ['limit', 'offset'])) ===
        JSON.stringify(omit(searchParams, ['limit', 'offset']))
      ) {
        newPropertyList = concatWithUnique<number>(
          state.properties || [],
          payload.response.items.map(({ id }) => id),
        );
      } else {
        newPropertyList = concatWithUnique<number>(
          [],
          payload.response.items.map(({ id }) => id),
        );
      }

      return {
        ...state,
        propertyData,
        isAll: payload.isAll,
        properties: newPropertyList,
        count: payload.response.counts,
        storedSearchParams: searchParams,
      };
    },
  )
  .handleAction(
    [actions.createPropertyAction.success],
    (state: IPropertyState, { payload }): IPropertyState => ({
      ...state,
      newProperties: [...(state.newProperties || []), payload.id],
      propertyData: {
        ...state.propertyData,
        [payload.id]: {
          ...{
            saleTransactionState:
              state.propertyData[payload.id]?.saleTransactionState,
            leaseTransactionState:
              state.propertyData[payload.id]?.leaseTransactionState,
          },
          ...payload,
        },
      },
    }),
  )
  .handleAction(
    [actions.setPropertyTagsAction],
    (state: IPropertyState, { payload }): IPropertyState => {
      return {
        ...state,
        propertyData: {
          ...state.propertyData,
          [payload.entityId]: {
            ...state.propertyData[payload.entityId],
            cardTagConnects: payload.tags,
          },
        },
      };
    },
  )
  .handleAction(
    [actions.getPropertyAction.success, actions.getPropertyTreeAction.success],
    (state: IPropertyState, { payload }): IPropertyState => {
      return {
        ...state,
        propertyData: {
          ...state.propertyData,
          [payload.id]: {
            ...state.propertyData[payload.id],
            ...payload,
          },
        },
      };
    },
  )
  .handleAction(
    [actions.updatePropertyAction.success],
    (state: IPropertyState, { payload }): IPropertyState => {
      return {
        ...state,
        propertyData: {
          ...state.propertyData,
          [payload.id]: {
            ...{
              saleTransactionState:
                state.propertyData[payload.id]?.saleTransactionState,
              leaseTransactionState:
                state.propertyData[payload.id]?.leaseTransactionState,
            },
            ...payload,
          },
        },
      };
    },
  )
  .handleAction(
    [actions.deletePropertyAction.success],
    (state: IPropertyState, { payload }): IPropertyState => {
      return {
        ...state,
        newProperties: state.newProperties?.filter(
          (property) => property !== payload.id,
        ),
        properties: state.properties?.filter(
          (property) => property !== payload.id,
        ),
        count: (state.count || 1) - 1,
        propertyData: payload.id
          ? omit(state.propertyData, [payload.id])
          : state.propertyData,
      };
    },
  )
  .handleAction(
    [actions.getSaleTransactionsAction.success],
    (state: IPropertyState, { payload }): IPropertyState => {
      const { searchParams } = payload;
      const saleTransactionData = {
        ...state.saleTransactionData,
      };

      payload.response.items.forEach((item) => {
        saleTransactionData[item.id] = {
          ...saleTransactionData[item.id],
          ...item,
        };
      });

      return {
        ...state,
        propertyData: {
          ...state.propertyData,
          ...(payload.additionalFields.property
            ? {
                [payload.additionalFields.property]: {
                  ...state.propertyData[payload.additionalFields.property],
                  saleTransactionState: {
                    ...(state.propertyData[payload.additionalFields.property]
                      ?.saleTransactionState || {}),
                    items:
                      JSON.stringify(
                        omit(
                          state.propertyData[payload.additionalFields.property]
                            ?.saleTransactionState?.storedSearchParams,
                          ['limit', 'offset'],
                        ),
                      ) ===
                      JSON.stringify(omit(searchParams, ['limit', 'offset']))
                        ? concatWithUnique<number>(
                            state.propertyData[
                              payload.additionalFields.property
                            ]?.saleTransactionState?.items || [],
                            payload.response.items.map((item) => item.id),
                          )
                        : concatWithUnique<number>(
                            [],
                            payload.response.items.map((item) => item.id),
                          ),
                    count: payload.response.counts,
                    isAll: payload.isAll,
                    storedSearchParams: searchParams,
                  },
                },
              }
            : {}),
        },
        saleTransactionData,
      };
    },
  )
  .handleAction(
    [
      actions.getSaleTransactionAction.success,
      actions.updateSaleTransactionAction.success,
    ],
    (state: IPropertyState, { payload }): IPropertyState => {
      return {
        ...state,
        saleTransactionData: {
          ...state.saleTransactionData,
          [payload.response.id]: {
            // ...state.saleTransactionData[payload.response.id],
            ...payload.response,
          },
        },
      };
    },
  )
  .handleAction(
    [actions.getLeaseTransactionsAction.request],
    (state: IPropertyState, { payload }): IPropertyState => {
      return {
        ...state,
        propertyData: {
          ...state.propertyData,
          ...(payload.property
            ? {
                [payload.property]: {
                  ...state.propertyData[payload.property],
                  leaseTransactionState: {
                    ...(state.propertyData[payload.property]
                      ?.leaseTransactionState || {}),
                    requestSearchParams: payload,
                  },
                },
              }
            : {}),
        },
      };
    },
  )
  .handleAction(
    [actions.getLeaseTransactionsAction.success],
    (state: IPropertyState, { payload }): IPropertyState => {
      if (
        JSON.stringify(
          payload.additionalFields.property &&
            state.propertyData[payload.additionalFields.property]
              ?.leaseTransactionState?.requestSearchParams,
        ) !== JSON.stringify(payload.searchParams)
      )
        return state;
      const { searchParams } = payload;
      const leaseTransactionData = {
        ...state.leaseTransactionData,
      };

      payload.response.items.forEach((item) => {
        leaseTransactionData[item.id] = {
          ...leaseTransactionData[item.id],
          ...item,
        };
      });

      return {
        ...state,
        propertyData: {
          ...state.propertyData,
          ...(payload.additionalFields.property
            ? {
                [payload.additionalFields.property]: {
                  ...state.propertyData[payload.additionalFields.property],
                  leaseTransactionState: {
                    ...(state.propertyData[payload.additionalFields.property]
                      ?.leaseTransactionState || {}),
                    newItems: searchParams.offset
                      ? state.propertyData[payload.additionalFields.property]
                          ?.leaseTransactionState?.newItems
                      : [],
                    items:
                      JSON.stringify(
                        omit(
                          state.propertyData[payload.additionalFields.property]
                            ?.leaseTransactionState?.storedSearchParams,
                          ['limit', 'offset'],
                        ),
                      ) ===
                      JSON.stringify(omit(searchParams, ['limit', 'offset']))
                        ? concatWithUnique<number>(
                            state.propertyData[
                              payload.additionalFields.property
                            ]?.leaseTransactionState?.items || [],
                            payload.response.items.map((item) => item.id),
                          )
                        : concatWithUnique<number>(
                            [],
                            payload.response.items.map((item) => item.id),
                          ),
                    count: payload.response.counts,
                    isAll: payload.isAll,
                    storedSearchParams: searchParams,
                  },
                },
              }
            : {}),
        },
        leaseTransactionData,
      };
    },
  )
  .handleAction(
    [
      actions.getLeaseTransactionAction.success,
      actions.updateLeaseTransactionAction.success,
    ],
    (state: IPropertyState, { payload }): IPropertyState => {
      return {
        ...state,
        leaseTransactionData: {
          ...state.leaseTransactionData,
          [payload.response.id]: {
            // ...state.leaseTransactionData[payload.response.id],
            ...payload.response,
          },
        },
      };
    },
  );

/* Loader actions */
export const addLoader = loaderActions.actions.addLoader;
export const addError = loaderActions.actions.addError;
export const removeError = loaderActions.actions.removeError;
export const removeLoader = loaderActions.actions.removeLoader;
