import { all, put, takeEvery, call } from 'redux-saga/effects';

// APIs
import { EnterpriseApi } from '../transport/enterprise.api';

// Actions
import {
  getEnterprisesAction,
  getEnterpriseAction,
  createEnterpriseAction,
  updateEnterpriseAction,
  deleteEnterpriseAction,
  getEnterpriseTreeAction,
  getEnterpriseOwnedPropertiesAction,
} from '../actions';

import { addError, addLoader, removeLoader } from '../index';

// Utils
import uuid from '../../../utils/uuid';

// Constants
import { LoaderAction } from 'config/constants';
import {
  EnterpriseCreateResponse,
  EnterpriseDeleteResponse,
  EnterpriseFullWithTreeDTO,
  EnterpriseGetListResponse,
  EnterpriseGetResponse,
  EnterpriseUpdateResponse,
  PropertyOwnedDTO,
} from '@ternala/voltore-types';
import { IError } from '../../model';
import { getAccessTokenSaga } from "../../auth/sagas/auth";

export function* getEnterprisesSaga({
  payload,
}: ReturnType<typeof getEnterprisesAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, enterprises are loading!',
      type: LoaderAction.enterprise.getList,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: EnterpriseGetListResponse | string | IError =
      yield EnterpriseApi.getEnterprises(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getEnterprisesAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getEnterprisesAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get enterprises!',
          type: LoaderAction.enterprise.getList,
        }),
      );
    } else {
      yield put(
        getEnterprisesAction.success({
          response: res,
          searchParams: payload,
          isAll: payload.limit ? res.items.length < payload.limit : true,
        }),
      );
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback();
    }
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getEnterprisesAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to get enterprises!',
        type: LoaderAction.enterprise.getList,
      }),
    );
  }
}

export function* createEnterpriseSaga({
  payload,
}: ReturnType<typeof createEnterpriseAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, enterprise is creating!',
      type: LoaderAction.enterprise.create,
    }),
  );

  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    delete data.loadCallback;
    const res: EnterpriseCreateResponse | string | IError =
      yield EnterpriseApi.createEnterprise(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        createEnterpriseAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          createEnterpriseAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to create enterprise!',
          type: LoaderAction.enterprise.create,
        }),
      );
    } else {
      yield put(createEnterpriseAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(true, res.id);
    }
    if (typeof payload.loadCallback === 'function') payload.loadCallback();
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      createEnterpriseAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to create enterprise!',
        type: LoaderAction.enterprise.create,
      }),
    );
  }
}

export function* getEnterpriseSaga({
  payload,
}: ReturnType<typeof getEnterpriseAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, enterprise is getting!',
      type: LoaderAction.enterprise.getItem,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: EnterpriseGetResponse | string | IError =
      yield EnterpriseApi.getEnterprise(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getEnterpriseAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getEnterpriseAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get enterprise!',
          type: LoaderAction.enterprise.getItem,
        }),
      );
    } else {
      yield put(getEnterpriseAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(true, res.id);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getEnterpriseAction.failure({
        key: String(payload.id),
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        key: String(payload.id),
        id: loadId,
        message: 'Failed to get enterprise!',
        type: LoaderAction.enterprise.getItem,
      }),
    );
  }
}

export function* updateEnterpriseSaga({
  payload,
}: ReturnType<typeof updateEnterpriseAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, enterprise is updating!',
      type: LoaderAction.enterprise.update,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: EnterpriseUpdateResponse | string | IError =
      yield EnterpriseApi.updateEnterprise(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        updateEnterpriseAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          updateEnterpriseAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to update enterprise!',
          type: LoaderAction.enterprise.update,
        }),
      );
    } else {
      yield put(updateEnterpriseAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(true, res.id);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    if (error.code === 422) {
      if (typeof payload.callback === 'function')
        payload.callback({
          hasError: true,
          data: error.data,
        });
      yield put(
        updateEnterpriseAction.failure({
          code: error.code,
          data: error.data,
          message: 'Validation error!',
        }),
      );
    } else {
      yield put(
        updateEnterpriseAction.failure({
          key: String(payload.id),
          code: error.code || 400,
          message: error.message || error || 'Something was wrong',
        }),
      );
    }
    yield put(
      addError({
        key: String(payload.id),
        id: loadId,
        message: 'Failed to update enterprise!',
        type: LoaderAction.enterprise.update,
      }),
    );
  }
}

export function* deleteEnterpriseSaga({
  payload,
}: ReturnType<typeof deleteEnterpriseAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, enterprise is deleting!',
      type: LoaderAction.enterprise.delete,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: EnterpriseDeleteResponse | string =
      yield EnterpriseApi.deleteEnterprise(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string') {
      deleteEnterpriseAction.failure({
        code: res,
        message: res || 'Something was wrong',
      });
      yield put(
        addError({
          id: loadId,
          message: 'Failed to delete enterprise!',
          type: LoaderAction.enterprise.delete,
        }),
      );
    } else {
      yield put(deleteEnterpriseAction.success({
        ...res,
        id: payload.id
      }));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback(true);
    }
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      deleteEnterpriseAction.failure({
        key: String(payload.id),
        code: error.code || 403,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        key: String(payload.id),
        id: loadId,
        message: 'Failed to delete enterprise!',
        type: LoaderAction.enterprise.delete,
      }),
    );
  }
}

export function* getEnterpriseTreeSaga({
  payload,
}: ReturnType<typeof getEnterpriseTreeAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, enterprise tree is getting!',
      type: LoaderAction.enterprise.getItem,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    const res: EnterpriseFullWithTreeDTO | string | IError =
      yield EnterpriseApi.getEnterpriseTree(payload, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getEnterpriseTreeAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getEnterpriseTreeAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get enterprise tree!',
          type: LoaderAction.enterprise.getItem,
        }),
      );
    } else {
      yield put(getEnterpriseTreeAction.success(res));
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function')
        payload.callback(true, res.id);
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    console.error('error: ', error);
    yield put(
      getEnterpriseTreeAction.failure({
        key: String(payload.id),
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        key: String(payload.id),
        id: loadId,
        message: 'Failed to get enterprise tree!',
        type: LoaderAction.enterprise.getItem,
      }),
    );
  }
}

export function* getEnterpriseOwnedPropertiesSaga({
  payload,
}: ReturnType<typeof getEnterpriseOwnedPropertiesAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, owned properties is getting!',
      type: LoaderAction.enterprise.getItem,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    const res: PropertyOwnedDTO[] | string | IError =
      yield EnterpriseApi.getOwnedProperties(payload, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getEnterpriseOwnedPropertiesAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getEnterpriseOwnedPropertiesAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get enterprise owned properties!',
          type: LoaderAction.enterprise.getItem,
        }),
      );
    } else {
      yield put(
        getEnterpriseOwnedPropertiesAction.success({
          response: res,
          id: payload.id,
        }),
      );
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
    }
    if (typeof payload.callback === 'function') payload.callback(true);
  } catch (error: any) {
    console.error('error: ', error);
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getEnterpriseOwnedPropertiesAction.failure({
        key: String(payload.id),
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        key: String(payload.id),
        id: loadId,
        message: 'Failed to get enterprise owned properties!',
        type: LoaderAction.enterprise.getItem,
      }),
    );
  }
}

export function* enterpriseActionSaga() {
  yield all([
    takeEvery(getEnterprisesAction.request, getEnterprisesSaga),
    takeEvery(getEnterpriseAction.request, getEnterpriseSaga),
    takeEvery(getEnterpriseTreeAction.request, getEnterpriseTreeSaga),
    takeEvery(createEnterpriseAction.request, createEnterpriseSaga),
    takeEvery(updateEnterpriseAction.request, updateEnterpriseSaga),
    takeEvery(deleteEnterpriseAction.request, deleteEnterpriseSaga),
    takeEvery(
      getEnterpriseOwnedPropertiesAction.request,
      getEnterpriseOwnedPropertiesSaga,
    ),
  ]);
}
