import React from 'react';
import { CardDTO, CardFullDTO, ReminderDTO } from '@ternala/voltore-types';

import ReminderCard from './ReminderCard';

//Actions
import { CardFilterEnum } from 'controllers/card/models.d';

//Custom interfaces
import Tag from './Tag';

import CardNote from './CardNote';
import CardWrapper from "./CardWrapper";

interface IPropsType {
  modal: CardFilterEnum;
  card: CardDTO | CardFullDTO | undefined;

  setEditedCard: (card: CardDTO | null) => void;
  editedCard: CardDTO | null;
}

const Card = ({ modal, card, setEditedCard, editedCard }: IPropsType) => {
  // Context
  const cardTagConnect = card?.cardTagConnect;

  return (
    <CardWrapper modal={modal} card={card} setEditedCard={setEditedCard} editedCard={editedCard}>
      <CardNote note={card?.note}/>
      {cardTagConnect?.tag ? <Tag card={cardTagConnect} /> : ''}
      {card?.reminders?.map(
        (reminder: ReminderDTO & { isMain?: boolean }) => {
          return (
            <ReminderCard
              isMain={reminder.isMain}
              modal={modal}
              reminder={reminder}
              key={reminder.id}
            />
          )
        },
      )}
    </CardWrapper>
  );
};

export default Card;
