import React, { FC, useContext, useEffect, useState } from 'react';

import { InternalLink } from 'routing/Link';
import { LinkIcon } from 'components/icons/LinkIcon';

/* styles */
import { Color } from "../shared/styles";
import style from './Link.module.scss';
import PreviewContext from 'context/Modal';
import { CardFilterEnum } from 'controllers/card/models.d';
import { EntityEnum } from "@ternala/voltore-types/lib/constants";

interface Props {
  className?: string;
  containerClassName?: string;
  style?: { [prop: string]: number | string };
  label?: string;
  tel?: string | boolean;
  mailto?: string | boolean;
  website?: string | boolean;
  url?: string;
  fullPath?: string;
  disabled?: boolean;
  newTabIcon?: boolean;
  isNewTab?: boolean;
  children?: React.ReactNode | string;
  id?: number;
  type?: EntityEnum;
  transactionId?: number;
}

let timeout: any;
export const StyledLink: FC<Props> = (props) => {
  const {
    className,
    containerClassName,
    tel,
    mailto,
    website,
    url,
    fullPath,
    disabled,
    isNewTab,
    newTabIcon,
    children,
    id,
    type,
    transactionId,
  } = props;
  const previewContext = useContext(PreviewContext);
  const [inside, setInside] = useState<boolean | undefined>(undefined);
  let href: string = '';
  if (typeof children === 'string') {
    if (tel) {
      href = typeof tel === 'string' ? `tel:${tel}` : `tel:${children}`;
    }
    if (mailto) {
      href =
        typeof mailto === 'string'
          ? `${mailto}:${children}`
          : `mailto:${children}`;
    }
    if (website) {
      href =
        typeof website === 'string'
          ? `http://${website}`
          : `http://${children}`;
    }
    if (url) {
      href = url;
    }
  }

  if (typeof children === 'object') {
    if (tel) {
      href = typeof tel === 'string' ? `tel:${tel}` : `tel:${children}`;
    }
    if(mailto){
      href = `mailto:${mailto}`;
    }
    if (url) {
      href = url;
    }
  }

  useEffect(() => {
    if (inside === false) {
      clearTimeout(timeout);
      previewContext.preview?.inside &&
        previewContext.setPreview({
          inside: false,
        });
    }
    if (inside === true) {
      timeout = setTimeout(function () {
        previewContext.setPreview({
          type,
          inside,
          id: Number(id),
          modal: CardFilterEnum.preview,
          transactionId: Number(transactionId),
        });
      }, 500);
    }
  }, [inside]);

  return (
    <div
      className={`${style.link_container} ${containerClassName ? containerClassName : ""}`}
      style={{ ...props.style, border: disabled ? 'none' : '' }}>
      {(tel || mailto || website || url) && (
        <a
          className={
            disabled
              ? style.link_disabled
              : style.link + (className ? ` ${className}` : '')
          }
          href={href}
          target={website || mailto || tel || isNewTab ? '_blank' : undefined}
          rel="noreferrer"
          title={href}>
          {children}
        </a>
      )}

      {fullPath && (
        <InternalLink
          className={
            disabled
              ? style.link_disabled
              : style.link + (className ? ` ${className}` : '')
          }
          fullPath={disabled ? undefined : fullPath}
          newTabIcon
          targetBlank={!!(newTabIcon || isNewTab)}
          children={children}
          enter={() => {
            id && id !== previewContext.preview?.id && setInside(true);
          }}
          leave={() => {
            setInside(false);
          }}
        />
      )}


      {url && newTabIcon && (
        <a
          className={disabled ? style.link_icon_disabled : style.link_icon}
          href={href}
          target="_blank"
          rel="noreferrer">
          <LinkIcon color={Color.link} />
        </a>
      )}
    </div>
  );
};

export default StyledLink;
