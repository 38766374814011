
import React, { FC, useEffect } from 'react';
import Modal from 'components/Hocs/Portal';
import Button from 'components/UI/controls/Button';

interface Props {
  onClose: () => void;
  onSubmit: (callback: Function) => void;
  width?: number;
}

const PopupDelete: FC<Props> = ({ onClose, onSubmit }) => {
  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  return (
    <Modal>
      <div className="modal popup popup-delete">
        <div className="scroll-area">
          <div className="modal-content">
            <div className="vertical-center-phone">
              <h2 className="popup-delete__title">Delete</h2>

              <div className="popup-delete__content">
                Are you sure you want to delete this data?
                <br />
                All data will be lost.
              </div>
            </div>

            <footer className="modal-footer delete">
              <Button variant="light" onClick={onClose}>
                No
              </Button>
              <Button
                variant="dark"
                onClick={() => {
                  onSubmit(onClose);
                }}>
                Yes
              </Button>
            </footer>
          </div>
        </div>

        <div className="modal-overlay" onClick={onClose} />
      </div>
    </Modal>
  );
};

PopupDelete.defaultProps = {
  width: 350,
};

export default PopupDelete;
