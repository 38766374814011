import React from 'react';
import TagItem from 'components/Tags/Controls/TagItem';
import { CardEntityConnectDTO } from '@ternala/voltore-types';
import { formatAddress } from 'utils/helper-functions';
interface IProps {
  card?: CardEntityConnectDTO;
}

const Tag = ({ card }: IProps) => {
  const tag = card?.tag;
  return (
    <div className="tag-card">
      {tag && (
        <div className="tags-container">
          <TagItem tag={tag} key={tag.id} />
          {tag?.category ? (
            <div className="category">from {tag?.category.title} Group</div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
};

export default Tag;
