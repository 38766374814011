import { Config } from '../../../config/api';
import { handleErrors } from '../../../utils/API';

import { IGoogleApiSearchResponse } from '../models';

class API {
  public async getListOfGeoObjectsByApi(
    query: string,
  ): Promise<IGoogleApiSearchResponse> {
    let googleRequestURL = new URL(
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      encodeURIComponent(query) +
      '&key=' +
      Config.GOOGLE_GEO_API_KEY +
      '&region=US&language=en',
    );
    return handleErrors(
      fetch(googleRequestURL.toString(), {
        method: 'GET',
      }),
    );
  }
}

export const GoogleSearchAPI = new API();
