import { all, put, takeEvery, call } from "redux-saga/effects";

// APIs
import {UserApi} from '../transport/user.api';
import {getAccessToken} from '../../auth';

// Actions
import {
    getUsersAction,
    getUserAction,
    createUserAction,
    updateUserAction,
    deleteUserAction,
} from '../actions';

import {addError, addLoader, removeLoader} from '../index';

// Utils
import uuid from 'utils/uuid';

// Constants
import {LoaderAction} from 'config/constants';
import {getAccessTokenUtil} from "../../../utils/getAccessToken";
import {
    UserCreateResponse,
    UserDeleteResponse,
    UserGetListResponse,
    UserGetResponse,
    UserUpdateResponse
} from "@ternala/voltore-types";
import {IError} from "../../model";
import {
    createEnterpriseAction, deleteEnterpriseAction,
    getEnterpriseAction,
    getEnterprisesAction,
    updateEnterpriseAction
} from "../../enterprise/actions";
import { getAccessTokenSaga } from "../../auth/sagas/auth";

export function* getUsersSaga({
                                  payload,
                              }: ReturnType<typeof getUsersAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, users are loading!',
            type: LoaderAction.user.getList,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: UserGetListResponse | string | IError = yield UserApi.getUsers(data, accessToken);

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                getUsersAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    getUsersAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to get users!',
                    type: LoaderAction.user.getList,
                }),
            );
        } else {
            yield put(
                getUsersAction.success({
                    response: res,
                    searchParams: payload,
                    isAll: payload.limit ? res.items.length < payload.limit : true,
                }),
            );
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback();
        }
    } catch (error: any) {
        console.error('error: ', error);
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            getUsersAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                id: loadId,
                message: 'Failed to get users!',
                type: LoaderAction.user.getList,
            }),
        );
    }
}

export function* createUserSaga({
                                    payload,
                                }: ReturnType<typeof createUserAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, user is creating!',
            type: LoaderAction.user.create,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: UserCreateResponse | string | IError = yield UserApi.createUser(data, accessToken);

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                createUserAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    createUserAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to create user!',
                    type: LoaderAction.user.create,
                }),
            );
        } else {
            yield put(createUserAction.success(res));
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(res);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            createUserAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                id: loadId,
                message: 'Failed to create user!',
                type: LoaderAction.user.create,
            }),
        );
    }
}

export function* getUserSaga({
                                 payload,
                             }: ReturnType<typeof getUserAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, user is getting!',
            type: LoaderAction.user.getItem,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: UserGetResponse | string | IError = yield UserApi.getUser(data, accessToken);

        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                getUserAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    getUserAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to get user!',
                    type: LoaderAction.user.getItem,
                }),
            );
        } else {
            yield put(getUserAction.success(res));
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        console.error('error: ', error);
        yield put(
            getUserAction.failure({
                key: String(payload.id),
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                key: String(payload.id),
                id: loadId,
                message: 'Failed to get user!',
                type: LoaderAction.user.getItem,
            }),
        );
    }
}

export function* updateUserSaga({
                                    payload,
                                }: ReturnType<typeof updateUserAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, user is updating!',
            type: LoaderAction.user.update,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: UserUpdateResponse | string| IError = yield UserApi.updateUser(data, accessToken);
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                updateUserAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    updateUserAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to update user!',
                    type: LoaderAction.enterprise.update,
                }),
            );
        } else {
            yield put(updateUserAction.success(res));
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            updateUserAction.failure({
                key: String(payload.id),
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                key: String(payload.id),
                id: loadId,
                message: 'Failed to update user!',
                type: LoaderAction.user.update,
            }),
        );
    }
}

export function* deleteUserSaga({
                                    payload,
                                }: ReturnType<typeof deleteUserAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, user is deleting!',
            type: LoaderAction.user.delete,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: UserDeleteResponse | string = yield UserApi.deleteUser(data, accessToken);
        if (typeof res === "string" || typeof res === "undefined") {
            deleteUserAction.failure({
                code: res,
                message: res || "Something was wrong"
            });
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to delete user!',
                    type: LoaderAction.user.delete,
                }),
            );
        } else {
            yield put(
                deleteUserAction.success({
                    ...res,
                    id: payload.id,
                }),
            );
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            deleteUserAction.failure({
                key: String(payload.id),
                code: error.code || 403,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                key: String(payload.id),
                id: loadId,
                message: 'Failed to delete user!',
                type: LoaderAction.user.delete,
            }),
        );
    }
}

export function* userActionSaga() {
    yield all([
        takeEvery(getUsersAction.request, getUsersSaga),
        takeEvery(getUserAction.request, getUserSaga),
        takeEvery(createUserAction.request, createUserSaga),
        takeEvery(updateUserAction.request, updateUserSaga),
        takeEvery(deleteUserAction.request, deleteUserSaga),
    ]);
}
