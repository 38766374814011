import React, { FC } from 'react';
import Link from 'components/UI/controls/Link';

/* constants & utils */
import { PageEnum } from 'config/constants';
import { enterprisesTabs, personsTabs } from 'config/constants/tabs';

/* types */
import {
  PersonShortDTO,
  EnterpriseShortDTO,
} from '@ternala/voltore-types';
import { TaxonomyShortDTO } from '@ternala/voltore-types/lib/modules/taxonomy/taxonomyShort.dto';
import { getListImage } from "../../../utils/getImage";
import { EntityEnum } from "@ternala/voltore-types/lib/constants";

interface Props {
  data: {
    positionOrRole?: string;
    person?: PersonShortDTO;
    enterprise?: EnterpriseShortDTO;
    involvementType?: TaxonomyShortDTO;
  };
}

const InvolvedPerson: FC<Props> = ({ data }) => {
  const { person, enterprise, positionOrRole } = data;
  return person ? (
    <div className={`${person && 'involved-person'}`} key={person?.id}>
      <div className="general-data">
        {person?.images?.length && (
          <div className="involved-person-picture person">
            <img src={getListImage(person?.images)} alt="" />
          </div>
        )}

        <div className="involved-person-info">
          <span className="person-info-type">{positionOrRole}</span>

          <div className="person-info-name">
            <Link
              id={person.id}
              type={EntityEnum.person}
              fullPath={`/${PageEnum.PEOPLE}/${person.id}/${personsTabs.info.title}`}
              newTabIcon>
              {`${person.firstName}${person?.middleName ? " " + person?.middleName : ""} ${person.lastName} ${
                person.nickName ? `(${person.nickName})` : ''
              }`}
            </Link>
          </div>
          <div className="person-info-enterprise">
            <Link
              id={enterprise?.id}
              type={EntityEnum.enterprise}
              fullPath={`/${PageEnum.ENTERPRISES}/${enterprise?.id}/${enterprisesTabs.info.title}`}
              newTabIcon>
              {enterprise?.title}
            </Link>
          </div>
        </div>
      </div>
      {person && enterprise?.images?.length && (
        <div className="involved-person-picture enterprise">
          <a
            href={`/${PageEnum.ENTERPRISES}/${enterprise?.id}/${enterprisesTabs.info.title}`}>
            <img src={getListImage(enterprise?.images)} alt="" />
          </a>
        </div>
      )}
    </div>
  ) : null;
};

export default InvolvedPerson;
