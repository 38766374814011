import React, { FC, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

/* components */
import Tabs from 'components/page/DatalistItemTabs';
import NotFoundPage from 'pages/Static/NotFound';

/* constants */
import { isPhone, PageEnum } from 'config/constants';
import { propertiesTabs } from 'config/constants/tabs';

/* types */
import { IStore } from 'controllers/store';
import { RouterState } from 'connected-react-router';
import { EntityEnum } from '@ternala/voltore-types/lib/constants';
import { getPropertyAction } from '../../../controllers/property/actions';
import { selectEntity, unselectEntity } from "../../../controllers/showElement/actions";
import { ISelectEntityData } from "../../../controllers/showElement/models";

interface Props
  extends RouteComponentProps<{
    id: string;
    tabName: string;
  }> {
  routerState: RouterState;
}

const PropertyItemTabs: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    match: {
      params: { id },
    },
    routerState: {
      location: { pathname },
    },
  } = props;
  const currentPropertyId: number = Number(id);
  const transactionId: number = Number(
    pathname.split('/')[pathname.split('/').length - 1],
  );
  let backLink: string = `/${PageEnum.PROPERTIES}`;

  if (isPhone && !isNaN(transactionId)) {
    if (pathname.endsWith(`${propertiesTabs.sales.path}/${transactionId}`)) {
      backLink = `/${PageEnum.PROPERTIES}/${id}/${propertiesTabs.sales.path}`;
    }
    if (pathname.endsWith(`${propertiesTabs.leases.path}/${transactionId}`)) {
      backLink = `/${PageEnum.PROPERTIES}/${id}/${propertiesTabs.leases.path}`;
    }
  }

  if (isNaN(currentPropertyId)) {
    return <NotFoundPage />;
  }
  useEffect(() => {
    dispatch(selectEntity({
      ids: [currentPropertyId],
      type: EntityEnum.property,
    }))
    dispatch(
      getPropertyAction.request({
        id: currentPropertyId,
      }),
    );
    return () => {
      setTimeout(() => {
        dispatch(unselectEntity({
          ids: [currentPropertyId],
          type: EntityEnum.property,
        }))
      }, 30)
    }
  }, [currentPropertyId])

  return (
    <>
      <Tabs
        page={PageEnum.PROPERTIES}
        tabs={propertiesTabs}
        listItemId={id}
        backLink={backLink}
        useSwiper
      />

      {props.children}
    </>
  );
};

export default connect((store: IStore) => ({
  routerState: store.router,
}))(PropertyItemTabs);
