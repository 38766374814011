import { createContext } from 'react';

export type pickerType = 'time' | 'date' | 'date-time';

const pickerInitialState: {
  openedPickers: pickerType[];
  openPicker: (picker: pickerType) => void;
  closePicker: (picker: pickerType) => void;
}
 = {
  openedPickers: [],
  openPicker: (picker: pickerType) => {},
  closePicker: (picker: pickerType) => {},
};

export const PickerContext = createContext(pickerInitialState);

export const PickerProvider = PickerContext.Provider;
export const PickerConsumer = PickerContext.Consumer;
const countActionsInitialState: {
  countActions: number | undefined;
  setCount: (count: number) => void;
}
  = {
  countActions: undefined,
  setCount: count => {}
};
export const CountActionsContext = createContext(countActionsInitialState);
export const CountActionsProvider = CountActionsContext.Provider;
export const CountActionsConsumer = CountActionsContext.Consumer;


const focusToMainInitialState: {
  moveFocusToMain: () => void;
} = {
  moveFocusToMain: () => {},
};

export const FocusToMainContext = createContext(focusToMainInitialState);

export const FocusToMainProvider = FocusToMainContext.Provider;
export const FocusToMainConsumer = FocusToMainContext.Consumer;
