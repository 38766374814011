import React from 'react';
import { ICardActionElement } from '../../../model';
import { InnerLink } from '../../../../../routing/InnerLink';
import { ContentBlock, ContentState } from 'draft-js';

export const TagEntity: React.FC<any> = (
  { url, disabled, ...props },
  ...args
) => {
  const {
    action,
    id,
  }: {
    action?: ICardActionElement;
    id?: number;
    endText?: string;
    title?: string;
  } = props.contentState.getEntity(props.entityKey).getData();
  if (action?.to) {
    return (
      <InnerLink
        to={action.to}
        id={id}
        tabName={action.tabName}
        className={'mention'}>
        {props.children}
      </InnerLink>
    );
  } else {
    return <span className={'mention not-clicked'}>{props.children}</span>;
  }
};

export function findTagEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'ENTITY'
    );
  }, callback);
}
