import React, { FC } from 'react';

/* utils */
import { getInitials } from 'utils/helper-functions';

/* types */
import { PersonFullDTO } from '@ternala/voltore-types';
import { EntityEnum } from '@ternala/voltore-types/lib/constants';

import { getMiddleImage } from 'utils';
import Link from 'components/UI/controls/Link';
import { AddressField } from 'components/views/address';
import Loader from 'components/Loader';
import InfoMultiField from 'components/UI/controls/MultiField/InfoMultiField';

import schema from 'routing/index';
import {generatePath, matchPath} from "react-router-dom";
import { capitalize } from "lodash";

interface Props {
  person?: PersonFullDTO;
  className?: string;
  addLink?: boolean;
}

const PersonInfoContent: FC<Props> = (props) => {
  const { person, className } = props;

  const name = (
    <>
      {`${person?.firstName}${
        person?.middleName ? ' ' + person?.middleName : ''
      } ${person?.lastName}`}{' '}
      {person?.nickName ? <span>({person?.nickName})</span> : ''}
    </>
  );

  const link = person ? generatePath(schema.getLink('people-tab'), { id: person.id, tabName: "info" }) : "";

  /* Context (location and history) */
  return person ? (
    <main className={'personal-contacts__content ' + className}>
      <div className="photo-container">
        {person?.images?.length ? (
          <img src={getMiddleImage(person.images)} alt="" />
        ) : (
          <div className="photo-container__initials">
            <span>
              {getInitials(`${person?.firstName} ${person?.lastName}`)}
            </span>
          </div>
        )}
      </div>

      <h5 className="person-name">
        {props.addLink ? (
          <Link
            id={person.id}
            type={EntityEnum.person}
            fullPath={generatePath(schema.getLink('people-tab'), { id: person.id, tabName: 'info' })}
            newTabIcon>
            {name}
          </Link>
        ) : (
          name
        )}
      </h5>

      <InfoMultiField
        entityId={person.id}
        items={person?.phones}
        label="Phone(s)"
      />

      <InfoMultiField
        entityId={person.id}
        items={person?.emails}
        label="Email(s)"
      />

      <div className="data-field">
        <div
          className={`data-field__key_lg ${
            person?.gender ? '' : 'no-information'
          }`}>
          Gender
        </div>
        <div className="data-field__value">
          {person?.gender ? (
            capitalize(person?.gender)
          ) : (
            <span className="no-information">-</span>
          )}
        </div>
      </div>

      <AddressField
        className={'address-field'}
        label={'Home Mailing Address'}
        address={person?.mailingAddress}
      />

      <div className="data-field">
        <div
          className={`data-field__key_lg ${
            person?.description ? '' : 'no-information'
          }`}>
          Description
        </div>
        <div className="data-field__value">
          {person?.description ? (
            person?.description
          ) : (
            <span className="no-information">-</span>
          )}
        </div>
      </div>
    </main>
  ) : (
    <div className="item-info__loader_info-tab">
      <Loader />
    </div>
  );
};

export default PersonInfoContent;
