import React from "react";
import MaskedInput from "react-text-mask";
import Select from "react-select";
import Input from 'components/UI/controls/Input'

import { IField, IFieldOption } from "models/form";
import { ValueType } from "react-select/src/types";
import { OptionType } from "models";

interface IProps {
  field: IField;
  value: any;
  error?: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
  setValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const Field: React.FC<IProps> = ({
  field,
  value,
  error,
  handleChange,
  handleBlur,
  setValue,
}) => {
  switch (field.type) {
    case "email": {
      return (
        <input
          type="email"
          className="input"
          onBlur={handleBlur}
          name={field.name}
          placeholder={field.placeholder || ""}
          onChange={handleChange}
          value={value}
          readOnly={field.readonly}
        />
      );
    }
    case "phone": {
      return (
        <MaskedInput
          mask={["+","4", "6", " ", /\d/, /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
          ]}
          onBlur={handleBlur}
          name={field.name}
          onChange={handleChange}
          value={value}
          className={"input"}
          placeholder={field.placeholder || ""}
          readOnly={field.readonly}
        />
      );
    }
    case "select": {
      if (field.options && Array.isArray(field.options)) {
        return (
          <Select
            name={field.name}
            options={field.options}
            onBlur={handleBlur}
            placeholde={field.placeholder}
            onChange={(selectedOption:ValueType<OptionType, false>) => {
              if (selectedOption && selectedOption.hasOwnProperty("value")) {
                const value = (selectedOption as IFieldOption).value;
                setValue(field.name, value, false);
              }
            }}
            value={field.options.find((option) => option.value === value)}
            readOnly={field.readonly}
          />
        );
      }
      return <div />;
    }
    case "text":
    default: {
      return (
        <Input
          label={field.title}
          type={field.type}
          name={field.name}
          placeholder={field.placeholder}
          value={value}
          error={error}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={field.readonly}
        />
      );
    }
  }
};
