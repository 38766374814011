
import React, { FC, useState, useEffect, MouseEvent } from 'react';
import Modal from 'components/Hocs/Portal';
import InputFloors from 'components/UI/controls/InputFloors';
import Button from 'components/UI/controls/Button';

/* types */
import { CreateFloorDTO, FloorDTO } from '@ternala/voltore-types';

interface Props {
  propertyFloors?: FloorDTO[];
  onClose: () => void;
  onSubmit: (floors: CreateFloorDTO[], callback: Function) => void;
}

const PopupCreateFloors: FC<Props> = ({
  propertyFloors,
  onClose,
  onSubmit,
}) => {
  const [floors, setFloors] = useState<FloorDTO[]>(propertyFloors || []);
  const [requestProcess, setRequestProcess] = useState<boolean>(false);

  useEffect(() => {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', closeOnEscPress);

    return () => {
      document.body.removeAttribute('class');
      document.removeEventListener('keydown', closeOnEscPress);
    };
  }, []);

  const closeOnEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  const submitData = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setRequestProcess(true);
    onSubmit(floors, (status: boolean) => {
      if (status) onClose();
      setRequestProcess(false);
    });
  };

  return (
    <Modal>
      <div className="modal popup popup-create-floors">
        <div className="scroll-area">
          <div className="modal-content">
            <div className="modal__close" onClick={onClose} title="Close" />

            <div className="modal-title">Create floors</div>

            <InputFloors
              label="floors"
              data={floors}
              onUpdate={(floors) => setFloors(floors)}
            />

            <footer className="modal-footer">
              <Button variant="light" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="dark"
                disabled={requestProcess}
                onClick={submitData}>
                Save
              </Button>
            </footer>
          </div>
        </div>

        <div className="modal-overlay" onClick={onClose} />
      </div>
    </Modal>
  );
};

export default PopupCreateFloors;
