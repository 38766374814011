import React, { FC, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { isEmpty, omit } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  ICircle,
  IPoint,
  PropertyGetListFiltersByArea
} from '@ternala/voltore-types/lib/modules/property/filters/area';

/* components */
import DataListControls from 'components/page/DataList/DataListControls';
import DataList from 'components/page/DataList';
import Filters from 'pages/Properties/Filters';
import Map from 'pages/Properties/GoogleMaps/MapWithSearch';

/* controllers & context */
import { getPropertiesAction } from 'controllers/property/actions';
import { FullScreenContext } from 'context/FullScreen';
import TagsContext, { TagTypeEnum } from 'context/Tags';
import { addShowedElement } from 'controllers/showElement/actions';

/* constants */
import { LOADED_ITEMS_LIMIT } from 'config';
import { METERS_IN_DEGREE, PageEnum } from 'config/constants';
import { propertiesTabs } from 'config/constants/tabs';
import { propertiesSortOptions, Sorting } from 'config/constants/sorting';
import { propertySortFields, sortType, TaxonomyTypeSlug } from '@ternala/voltore-types/lib/constants';

/* types */
import { IStore } from 'controllers/store';
import { IPropertyState, PropertyFullExpandDTO, PropertyGetListFiltersExtended } from 'controllers/property/models';
import { FilterType, ItemsLoadType, OptionType, sortingTypeKeys } from 'models';

import { IModalElement, ShowElementEnum } from 'controllers/showElement/models.d';

/* utils */
import { preparePropertyFilters } from './utils';
import uuid from 'utils/uuid';
import { formatAddress } from 'utils/helper-functions';
import { getListImage, getListImageOne } from '../../utils/getImage';
import { propertyModalKey } from '../../components/modals/properties/PropertyModalConent';

interface IPropertyType {
  property: any;
  type: TaxonomyTypeSlug;
}

interface Props {
  propertyState: IPropertyState;
}

const PropertiesPage: FC<Props> = (props) => {
  const {
    propertyState: {
      properties,
      count,
      isAll,
      storedSearchParams,
      propertyData,
      newProperties
    },
    children
  } = props;

  const {pathname} = useLocation();

  const dispatch = useDispatch();
  const fullScreenState = useContext(FullScreenContext);
  const tagsFilters = useContext(TagsContext);
  const {isFullScreen} = fullScreenState;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filtersOpened, setFiltersOpened] = useState<boolean>(false);
  const [isMapOpen, setIsMapOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<PropertyGetListFiltersExtended>();
  const [area, setArea] = useState<PropertyGetListFiltersByArea>();

  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [sortValue, setSortValue] = useState<OptionType>(
    propertiesSortOptions[0]
  );
  const [sortingType, setSortingType] = useState<sortType>(sortType.asc);
  const [sortField, setSortField] = useState<propertySortFields | undefined>(
    propertySortFields.createdAt
  );
  useEffect(() => {
    setIsLoading(true);
    loadProperties('start', () => setIsLoading(false));
  }, [sortingType, sortField, filters, area]);

  useEffect(() => {
    setFiltersOpened(isFullScreen);
  }, [isFullScreen]);

  useEffect(() => {
    if (searchQuery === '') {
      setIsLoading(true);
      loadProperties('start', () => setIsLoading(false));
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!tagsFilters?.tags) return;
    if (!tagsFilters?.tags.find((tag, i) => filters?.selectedTags?.[i].id !== tag.id)) return;

    setFilters({
      ...filters,
      selectedTags: tagsFilters?.tags
    });
  }, [tagsFilters?.tags]);

  useEffect(() => {
    return () => {
      tagsFilters.removeTags();
      tagsFilters.removeTags(() => {
      }, TagTypeEnum.ExcludedTags);
    };
  }, []);

  useEffect(() => {
    if (!filters?.selectedTags?.length) tagsFilters?.removeTags();
    if (!filters?.excludedTags?.length) tagsFilters.removeTags(() => {
    }, TagTypeEnum.ExcludedTags);
  }, [filters?.selectedTags, filters?.excludedTags]);

  useEffect(() => {
    const updatedFilters: PropertyGetListFiltersExtended = {...filters};
    area?.circle
      ? (updatedFilters.circle = area.circle)
      : (updatedFilters.circle = {});
    area?.polygon
      ? (updatedFilters.polygon = area.polygon)
      : (updatedFilters.polygon = {});
    if (!isEmpty(area?.circle) || !isEmpty(area?.polygon)) {
      setFilters(updatedFilters);
    }
  }, [area]);

  const getFilters = (
    data?: { limit: number; offset: number } | { bounds: IPoint[] }
  ) => {
    let searchParams = preparePropertyFilters({
      filters,
      searchQuery,
      sortingType,
      sortField,
      area,
      data
    });
    if (
      JSON.stringify(omit(storedSearchParams, ['limit', 'offset'])) !==
      JSON.stringify(omit(searchParams, ['limit', 'offset']))
    ) {
      searchParams.offset = 0;
    }
    return searchParams;
  };

  const loadProperties = (
    loadType: ItemsLoadType = 'start',
    callback?: Function
  ) => {
    console.log('load properties')

    if (isMapOpen) return;
    const payload = getFilters({
      limit: LOADED_ITEMS_LIMIT,
      offset: loadType === 'more' ? properties?.length || 0 : 0
    });
    dispatch(getPropertiesAction.request({...payload, callback}));
  };

  const onCircleComplete = (figure: ICircle) => {
    const circle = {
      ...figure,
      radius: figure.radius / METERS_IN_DEGREE
    };
    setArea({circle});
  };

  const resetFilter = (filterType?: FilterType) => {
    if (filterType === 'circle' || filterType === 'polygon') {
      setArea(undefined);
      setFilters({
        ...filters,
        circle: {},
        polygon: {}
      });
      return;
    }
    if (filterType === 'Map') {
      setArea(undefined);
      setFilters({
        ...filters,
        circle: {},
        polygon: {}
      });
      setArea(undefined);
      return;
    }
    if (filterType === 'selected-tags') {
      setFilters({
        ...filters,
        selectedTags: []
      });
      return;
    }
    if (filterType === 'excluded-tags') {
      setFilters({
        ...filters,
        excludedTags: []
      });
      return;
    }
    setFilters({
      ...filters,
      [String(filterType)]: {}
    });
  };

  const onSort = (sortOption: OptionType) => {
    const sorting = Sorting[sortOption.value as sortingTypeKeys];
    setSortValue(sortOption);
    setSortField(sorting.sortField as propertySortFields);
    setSortingType(sorting.sortType);
  };

  function type({property, type}: IPropertyType) {
    if (property[TaxonomyTypeSlug[type].length]) return null;
    return property[TaxonomyTypeSlug[type]]
      ?.map((element: any) => element.title)
      .join(', ');
  }

  const prepareProperty = (property: PropertyFullExpandDTO) => {
    const propertyType = type({
      property,
      type: TaxonomyTypeSlug.propertyType
    });
    const subPropertyType = type({
      property,
      type: TaxonomyTypeSlug.subPropertyType
    });
    const secondaryPropertyType = type({
      property,
      type: TaxonomyTypeSlug.secondaryPropertyType
    });
    const types = [propertyType, subPropertyType, secondaryPropertyType]
      .filter((type) => type !== null && type !== '')
      .join(' | ');
    return {
      id: property.id,
      // tag: property.title,
      title: types,
      description:
        formatAddress({
          address: property.address?.address,
          city: property.address?.city,
          state: property.address?.state,
          zipCode: property.address?.zipCode
        }) || '',
      image: getListImageOne(property.image) || getListImage(property.images) || ''
    };
  };

  const propertiesListItems = properties
    ?.map((id) => propertyData[id])
    .map(prepareProperty);

  const newPropertiesListItems = newProperties
    ?.map((id) => propertyData[id])
    ?.map(prepareProperty);

  return (
    <div
      className={
        'properties-page' +
        (isFullScreen ? ' properties-page_full-screen-filters' : '')
      }>
      <Helmet>
        <title>
          Properties | Voltore{' '}
          {process.env.REACT_APP_COMPANY_NAME
            ? `| ${process.env.REACT_APP_COMPANY_NAME}`
            : ''}
        </title>
        <meta name="description" content="Voltore application"/>
      </Helmet>
      <div className="page-layout__content">
        <div
          className={
            'properties-list-container' +
            (pathname !== `/${PageEnum.PROPERTIES}`
              ? ' data-list_hidden'
              : '') +
            (isFullScreen ? ' properties-list-container_full-screen' : '')
          }>
          {!isFullScreen && (
            <DataListControls
              onSearch={() => {
                setIsLoading(true);
                loadProperties('start', () => setIsLoading(false));
              }}
              pageTitle={PageEnum.PROPERTIES}
              searchPlaceholder="Search by Address, Property group"
              searchValue={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              callback={() => setSearchQuery('')}
              onAddNew={() => {
                dispatch(
                  addShowedElement({
                    id: uuid(),
                    key: propertyModalKey,
                    type: ShowElementEnum.modal,
                    props: {}
                  } as IModalElement)
                );
              }}
              Filters={{
                isOpen: filtersOpened,
                applied: filters,
                toggle: () => setFiltersOpened(!filtersOpened),
                resetFilter,
                onClear: () =>
                  setFilters({
                    property: {},
                    sale: {},
                    lease: {},
                    circle: {},
                    polygon: {},
                    selectedTags: [],
                    excludedTags: []
                  })
              }}
              Map={{
                isOpen: isMapOpen,
                toggle: () => {
                  if (isMapOpen) {
                    resetFilter('Map');
                  }
                  setIsMapOpen(!isMapOpen);
                }
              }}
            />
          )}
          <div
            className={
              'properties_wrapper' +
              (isFullScreen ? ' properties_wrapper_fullscreen' : '')
            }>
            <div
              className={
                'properties-list__map-container' +
                (!isMapOpen ? ' map_hide' : '') +
                (filters &&
                Object.values(filters).some((filter) => !isEmpty(filter))
                  ? ' properties-list__map-container_filters-applied'
                  : '')
              }>
              <Map
                className={
                  'properties-list__map' +
                  (isFullScreen
                    ? ' properties-list__map_expanded'
                    : ' properties-list__map_collapsed')
                }
                onMarkerClick={(propertyId) =>
                  dispatch(
                    push(
                      `/${PageEnum.PROPERTIES}/${propertyId}/${propertiesTabs.property.path}`
                    )
                  )
                }
                filters={filters}
                searchQuery={searchQuery}
                isFullScreen={isFullScreen}
                toggleFullScreen={() => fullScreenState.toggle()}
                figure={area}
                isShow={isMapOpen}
                onFigureComplete={(figure) =>
                  Array.isArray(figure)
                    ? setArea({polygon: figure})
                    : onCircleComplete(figure)
                }
                onFigureClear={(filter) => resetFilter(filter)}
              />
            </div>

            <DataList
              itemsLoading={isLoading}
              items={propertiesListItems}
              newItems={newPropertiesListItems || []}
              itemsCount={count}
              allItemsLoaded={isAll}
              activeTab="property"
              pageTitle={PageEnum.PROPERTIES}
              singleItem="property"
              onLoadMore={loadProperties}
              onSort={onSort}
              sortValue={sortValue}
              sortOptions={propertiesSortOptions}
              isFullScreen={isFullScreen}
              checkSuffixByActive={false}
              isHidden={isMapOpen}
            />
          </div>
        </div>

        {!isFullScreen && (
          <div className="item-info">
            {pathname === `/${PageEnum.PROPERTIES}` && (
              <div className="empty-container-wrapper">
                <div className="empty-container"/>
              </div>
            )}

            {children}
          </div>
        )}

        {filtersOpened && (
          <Filters
            isFullScreen={isFullScreen}
            appliedFilters={filters}
            getFilters={getFilters}
            onFiltersUpdated={(updatedFilters) => {
              setFilters({...filters, ...updatedFilters});
            }}
            onClose={() => setFiltersOpened(false)}
          />
        )}
      </div>
    </div>
  );
};

export default connect((store: IStore) => ({
  propertyState: store.property
}))(PropertiesPage);
