import { connectedMention, findMentions } from "./Mention";
import { findTime, Time } from './Time';
import { findTagEntities, TagEntity } from './TagEntity';
import { findTags, Tag } from './Tag';
import { Action, findActions } from "./Action";

export const noteDecorators = [
  {
    strategy: findMentions,
    component: connectedMention,
  },
  {
    strategy: findActions,
    component: Action,
  },
];

export const decorators = [
  {
    strategy: findMentions,
    component: connectedMention,
  },
  {
    strategy: findTime,
    component: Time,
  },
];

export const tagDecorators = [
  {
    strategy: findTagEntities,
    component: TagEntity,
  },
  {
    strategy: findTags,
    component: Tag,
  },
];
