import React, { FC } from "react";
import { RouteComponentProps } from 'react-router-dom';

import NotFoundPage from 'pages/Static/NotFound';
import InfoTab from './Info/index';
import PersonsTab from './People';
import OwnershipStructureTab from './OwnershipStructure';

import { enterprisesTabs } from 'config/constants/tabs';
import PropertiesTab from '../../../components/page/OwnedProperties';
import { EntityEnum } from "@ternala/voltore-types/lib/constants";

interface Props
  extends RouteComponentProps<{
    id?: string;
    tabName?: string;
  }> {}

export const EnterpriseTabsContent: FC<Props> = ({
  match: { params },
  ...props
}) => {
  const id = params?.id;
  const tabName = params?.tabName;
  const idNumber = Number(id);

  if (isNaN(idNumber)) {
    return <NotFoundPage />;
  }

  return (
    <>
      {tabName === enterprisesTabs.info.path && (
        <InfoTab enterpriseId={idNumber} tabName={tabName}>
          {props.children}
        </InfoTab>
      )}

      {tabName === enterprisesTabs.people.path && (
        <PersonsTab enterpriseId={idNumber} tabName={tabName}>
          {props.children}
        </PersonsTab>
      )}

      {tabName === enterprisesTabs.ownership.path && (
        <OwnershipStructureTab enterpriseId={idNumber} tabName={tabName}>
          {props.children}
        </OwnershipStructureTab>
      )}

      {tabName === enterprisesTabs.properties.path && (
        <PropertiesTab id={idNumber} type={EntityEnum.enterprise}>
          {props.children}
        </PropertiesTab>
      )}
    </>
  );
};

export default EnterpriseTabsContent;
