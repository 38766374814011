import React, { FC } from 'react'
import { InfoIcon } from 'components/icons/InfoIcon'

interface Props {
   mapView?: boolean
}

const LeaseTransactionViewLegend: FC<Props> = ({ mapView }) => (
   <div className="transaction-stack-legend legend">
      <div className="legend-row">
         <div className="legend-item leased">
            <div className="icon"/>
            <span>Leased</span>
            <div className="tooltip">
               <div className="tooltip-icon">
                  <InfoIcon />
               </div>
               <div className="tooltip-content">
                  Transaction or suit with the latest transaction currently in “Leased” status
               </div>
            </div>
         </div>

         <div className="legend-item available">
            <div className="icon"/>
            <span>Available</span>
            <div className="tooltip">
               <div className="tooltip-icon">
                  <InfoIcon />
               </div>
               <div className="tooltip-content">
                  Suit with the latest transaction that is having one of the following statuses:
                  <br />
                  - Active
                  <br />
                  - Pending (now or in the future)
                  <br />
                  - Canceled
                  <br />
                  - Expired
               </div>
            </div>
         </div>

         <div className="legend-item will-be-leased">
            <div className="icon"/>
            <span>Will be leased</span>
            <div className="tooltip">
               <div className="tooltip-icon">
                  <InfoIcon />
               </div>
               <div className="tooltip-content">
                  Transaction or suit with the latest transaction with any current status and have “Leased” status set for the future date
               </div>
            </div>
         </div>

         <div className="legend-item overlap">
            <div className="icon"/>
            <span>Overlap</span>
            <div className="tooltip">
               <div className="tooltip-icon">
                  <InfoIcon />
               </div>
               <div className="tooltip-content">
                  A suite with more than one transaction
               </div>
            </div>
         </div>
      </div>

      {/* {!mapView && <p>* Square footage of the space in each cell</p>} */}
   </div>
)

export default LeaseTransactionViewLegend