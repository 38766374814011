import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";

/* components */
import Filters from "components/page/Filters";
import SelectMultiSearch from "components/UI/controls/SelectMultiSearch";
import Checkbox from "components/UI/controls/Checkbox";
import PopupTagGroup from "components/modals/PopupTagGroup";
import PopupTag from "components/modals/PopupTag";

/* controllers */
import { getTaxonomiesByTypeAction } from "controllers/taxonomy/actions";

/* constants */
import { loadEnterprisesOptions, loadPersonsOptions, loadTagsOptions } from "config/constants/select-options";
import { TaxonomyTypeSlug } from "@ternala/voltore-types/lib/constants";

/* types */
import { IStore } from "controllers/store";
import { ITaxonomyState } from "controllers/taxonomy/models";
import { EnterpriseGetListFiltersExtended } from "controllers/enterprise/models";
import { IFiltrationTag, OptionType } from "models";
import { arrayDifferences, mapEnum } from "utils/helper-functions";
import { PageEnum } from "config/constants";
import { TagApi } from "controllers/tag/transport/tag.api";
import TagsContext, { TagTypeEnum } from "context/Tags";
import { getAccessTokenUtil } from "utils";

interface Props {
  taxonomyState: ITaxonomyState;
  appliedFilters?: EnterpriseGetListFiltersExtended;
  onFiltersUpdated: (filters: {
    enterprises: EnterpriseGetListFiltersExtended;
  }) => void;
  onClose: () => void;
}

enum FiltersTab {
  ENTERPRISE = "Enterprise",
  TAG = "Tag",
}

const EnterprisePageFilters: FC<Props> = (props) => {
  const {
    taxonomyState: { taxonomies },
    appliedFilters,
    onFiltersUpdated,
    onClose
  } = props;

  const dispatch = useDispatch();
  const tagsFilters = useContext(TagsContext);

  const [activeTab, setActiveTab] = useState<FiltersTab>(FiltersTab.ENTERPRISE);
  const [enterpriseTypes, setEnterpriseTypes] = useState<
    OptionType[] | undefined
  >(appliedFilters?.enterpriseTypeFilter || []);
  const [involvementTypes, setInvolvementTypes] = useState<
    OptionType[] | undefined
  >(appliedFilters?.involvementTypeFilter || []);
  const [parentEnterprises, setParentEnterprises] = useState<
    OptionType[] | undefined
  >(appliedFilters?.parentEnterprisesFilter || []);
  const [persons, setPersons] = useState<OptionType[] | undefined>(
    appliedFilters?.personsFilter || []
  );
  const [isPrincipal, setIsPrincipal] = useState<boolean>(
    appliedFilters?.owner || false
  );
  const [isDecisionMaker, setIsDecisionMaker] = useState<boolean>(
    appliedFilters?.decisionMaker || false
  );

  /* TAG tab */
  const [selectedTags, setSelectedTags] = useState<OptionType[] | undefined>(
    tagsFilters?.tags?.map((tag) => ({
      label: tag?.fullTitle,
      value: tag?.id
    })) || []
  );

  const [excludedTags, setExcludedTags] = useState<OptionType[] | undefined>(tagsFilters?.excludedTags?.map((tag) => ({
    label: tag?.fullTitle,
    value: tag?.id
  })) || []);

  useEffect(() => {
    setSelectedTags(
      tagsFilters?.tags?.map((tag) => ({
        label: tag?.fullTitle,
        value: tag?.id
      })) || []
    );
  }, [tagsFilters?.tags]);

  useEffect(() => {
    setExcludedTags(
      tagsFilters?.excludedTags?.map((tag) => ({
        label: tag?.fullTitle,
        value: tag?.id
      })) || []
    );
  }, [tagsFilters?.excludedTags]);

  const [isTagCreationOpen, setIsTagCreationOpen] = useState<boolean>(false);
  const [isTagCategoryCreationOpen, setIsTagCategoryCreationOpen] =
    useState<boolean>(false);

  // custom hook for getting previous value
  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  // the App where the hook is used
  function CheckTaxonomy() {
    const prevCount = usePrevious(taxonomies);
    const { oldArray, newArray } = arrayDifferences(prevCount, taxonomies);
    return !!(oldArray.length || newArray.length);
  }

  if (CheckTaxonomy())
    useEffect(() => {
      dispatch(
        getTaxonomiesByTypeAction.request({
          type: TaxonomyTypeSlug.enterpriseType
        })
      );
      dispatch(
        getTaxonomiesByTypeAction.request({
          type: TaxonomyTypeSlug.involvementType
        })
      );
    }, []);

  const clearAllFilters = () => {
    setEnterpriseTypes([]);
    setParentEnterprises([]);
    setPersons([]);
    setInvolvementTypes([]);
    setIsPrincipal(false);
    setIsDecisionMaker(false);
    tagsFilters.removeTags();
    tagsFilters.removeTags(() => {}, TagTypeEnum.ExcludedTags);
    onFiltersUpdated({ enterprises: {} });
  };

  const applyFilters = () => {
    const formatTags: IFiltrationTag[] | undefined = selectedTags?.map((tag) => {
      const newTag: IFiltrationTag = {
        fullTitle: String(tag.label),
        id: Number(tag.value)
      };
      return newTag;
    });
    const excludedTagsFormat: IFiltrationTag[] | undefined = excludedTags?.map((tag) => {
      const newTag: IFiltrationTag = {
        fullTitle: String(tag.label),
        id: Number(tag.value)
      };
      return newTag;
    });

    if (formatTags !== tagsFilters?.tags)
      tagsFilters.setTags(formatTags as IFiltrationTag[]);
    if (excludedTagsFormat !== tagsFilters?.excludedTags)
      tagsFilters.setTags(excludedTagsFormat as IFiltrationTag[], TagTypeEnum.ExcludedTags);

    const appliedFilters = {
      enterprises: {
        ...(formatTags?.length && {
          selectedTags: formatTags.map((tag) => tag.id)
        }),
        ...(excludedTags?.length && {
          unselectedTags: (excludedTags || []).map(({ value }) => value)
        })
      } as EnterpriseGetListFiltersExtended
    };

    if (enterpriseTypes?.length) {
      appliedFilters.enterprises.enterpriseTypeFilter = enterpriseTypes;
    }
    if (involvementTypes?.length) {
      appliedFilters.enterprises.involvementTypeFilter = involvementTypes;
    }
    if (parentEnterprises?.length) {
      appliedFilters.enterprises.parentEnterprisesFilter = parentEnterprises;
    }
    if (persons?.length) {
      appliedFilters.enterprises.personsFilter = persons;
    }

    if (isPrincipal) appliedFilters.enterprises.owner = true;
    if (isDecisionMaker) appliedFilters.enterprises.decisionMaker = true;

    onFiltersUpdated(appliedFilters);
    onClose();
  };

  const enterpriseFiltersTabs = mapEnum(FiltersTab, (tab: FiltersTab) => ({
    label: tab,
    onClick: () => setActiveTab(tab)
  }));

  return (
    <Filters
      page={PageEnum.ENTERPRISES}
      tabs={enterpriseFiltersTabs}
      activeTab={activeTab}
      onClear={clearAllFilters}
      onApply={applyFilters}
      onClose={onClose}>
      {activeTab === FiltersTab.ENTERPRISE && (
        <>
          <SelectMultiSearch
            label="type"
            selectedOptions={enterpriseTypes}
            options={taxonomies?.[TaxonomyTypeSlug.enterpriseType]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id
              })
            )}
            onChange={(data) => setEnterpriseTypes(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="parent enterprise"
            selectedOptions={parentEnterprises}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadEnterprisesOptions(searchParams, token);
              }
              return [];
            }}
            onChange={(data) => setParentEnterprises(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="person"
            selectedOptions={persons}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadPersonsOptions(searchParams, token);
              }
              return [];
            }}
            onChange={(data) => setPersons(data)}
            width="100%"
            darkTheme
          />

          <SelectMultiSearch
            label="position or role"
            selectedOptions={involvementTypes}
            options={taxonomies?.[TaxonomyTypeSlug.involvementType]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id
              })
            )}
            onChange={(data) => setInvolvementTypes(data)}
            width="100%"
            darkTheme
          />

          <Checkbox
            label="Principal"
            checked={isPrincipal}
            onChange={() => setIsPrincipal(!isPrincipal)}
            darkTheme
          />

          <Checkbox
            label="Decision Maker"
            checked={isDecisionMaker}
            onChange={() => setIsDecisionMaker(!isDecisionMaker)}
            darkTheme
          />
        </>
      )}
      {activeTab === FiltersTab.TAG && (
        <>
          <SelectMultiSearch
            label="tag(s)"
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadTagsOptions(searchParams, token);
              }
              return [];
            }}
            selectedOptions={selectedTags}
            onChange={(options) => options && setSelectedTags(options)}
            creation={{
              label: "Create new tag",
              onClick: () => {
                setIsTagCreationOpen(true);
              }
            }}
            width="100%"
            darkTheme
          />
          <SelectMultiSearch
            label="Exclude tag(s)"
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadTagsOptions(searchParams, token);
              }
              return [];
            }}
            selectedOptions={excludedTags}
            onChange={(options) => options && setExcludedTags(options)}
            creation={{
              label: "Create new tag",
              onClick: () => {
                setIsTagCreationOpen(true);
              }
            }}
            width="100%"
            darkTheme
          />
          {isTagCreationOpen ? (
            <PopupTag
              setGroupCreation={setIsTagCategoryCreationOpen}
              onClose={() => {
                setIsTagCreationOpen(false);
              }}
              onCreate={async function(data) {
                const token = await getAccessTokenUtil(dispatch);
                if (token) return await TagApi.createTag(data, token);
              }}
            />
          ) : (
            ""
          )}
          {isTagCategoryCreationOpen ? (
            <PopupTagGroup
              onClose={() => {
                setIsTagCategoryCreationOpen(false);
              }}
              onCreate={async function(data) {
                const token = await getAccessTokenUtil(dispatch);
                if (token) return await TagApi.createTagCategory(data, token);
              }}
            />
          ) : (
            ""
          )}
        </>
      )}
    </Filters>
  );
};

export default connect((store: IStore) => ({
  taxonomyState: store.taxonomy
}))(EnterprisePageFilters);
