export const dataReturner = (dataArr: any, key: string): string => {
  let data = '';
  if (dataArr) {
    dataArr.forEach((item: any) => {
      item.types.forEach((elem: any) => {
        if (elem === key) {
          if(['street_number', 'route', 'administrative_area_level_1'].indexOf(key) === -1){
            data = item.long_name;
          } else{
            data = item.short_name;
          }
        }
      });
    });
  }
  return data;
};