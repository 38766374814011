import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {DeviceDTO} from "@ternala/voltore-types/lib/modules/device.dto";
import {getFCMToken} from "./getFCMToken";

export const getCredentials = async (): Promise<DeviceDTO> => {
  const fp = await FingerprintJS.load();

  const result = await fp.get();

  const visitorId = result.visitorId;

  let platform;

  if((window as any).cordova){
    //@ts-ignore
    if(device){
      //@ts-ignore
      platform = device?.platform;
      if(platform){
        platform = platform.toLowerCase();
      }
    }
  }

  const fcmToken = await getFCMToken();

  return {
    platform: platform || "web",
    fingerprint: visitorId,
    FCMToken: fcmToken
  }
}