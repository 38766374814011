import React, { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { omit } from 'lodash';

/* components */
import DataList from 'components/page/DataList';
import CreateUserInfoModal, {createUserInfoModalKey} from 'components/modals/users/CreateUserInfo';
import PersonModal from 'components/modals/PersonModal';

/* controllers */
import {
  getUsersAction,
  createUserAction,
  createUserRoleAction, updateUserRoleAction,
} from 'controllers/user/actions';
import { createPersonAction } from 'controllers/person/actions';

/* constants */
import { LOADED_ITEMS_LIMIT } from 'config';
import { PageEnum } from 'config/constants';
import { Sorting, usersSortOptions } from 'config/constants/sorting';
import { sortType, userSortFields } from '@ternala/voltore-types/lib/constants';
import { Helmet } from 'react-helmet';

/* types */
import { IStore } from 'controllers/store';
import { RouterState } from 'connected-react-router';
import { IUserState } from 'controllers/user/models';
import { ItemsLoadType, OptionType, sortingTypeKeys } from 'models';
import UserRoleModal, {userRoleModalKey} from 'components/modals/UserRoleModal';
import { getListImage } from "../../utils/getImage";
import {addShowedElement} from "../../controllers/showElement/actions";
import uuid from "../../utils/uuid";
import {taxonomyModalKey} from "../../components/modals/TaxonomyModal";
import {IModalElement, ShowElementEnum} from "controllers/showElement/models.d";
import { generatePersonName } from "../People/utils";

interface Props {
  routerState: RouterState;
  userState: IUserState;
}

const UsersPage: FC<Props> = (props) => {
  const {
    routerState: {
      location: { pathname },
    },
    userState: { users, count, isAll, storedSearchParams },
    children,
  } = props;

  const dispatch = useDispatch();

  const [isModalCreateOpen, setIsModalCreateOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isPersonModalOpen, setIsPersonModalOpen] = useState<boolean>(false);
  const [isUserRoleModalOpen, setIsUserRoleModalOpen] =
    useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [sortValue, setSortValue] = useState<OptionType>(usersSortOptions[0]);
  const [sortingType, setSortingType] = useState<sortType>(sortType.asc);
  const [sortField, setSortField] = useState<userSortFields | undefined>(
    userSortFields.title,
  );

  useEffect(() => {
    setLoading(true);
    loadUsers('start', () => setLoading(false));
  }, [sortingType, sortField]);

  useEffect(() => {
    if (searchQuery === '') {
      setLoading(true);
      loadUsers('start', () => setLoading(false));
    }
  }, [searchQuery]);

  useEffect(() => {
    setLoading(true);
    loadUsers('start', () => setLoading(false));
  }, []);

  const loadUsers = (
    loadType: ItemsLoadType = 'start',
    callback?: Function,
  ) => {
    const searchParams = {
      limit: LOADED_ITEMS_LIMIT,
      offset: loadType === 'more' ? users?.length : 0,
      query: searchQuery,
      sortType: sortingType,
      sortField: sortField,
    };

    if (
      JSON.stringify(omit(storedSearchParams, ['limit', 'offset'])) !==
      JSON.stringify(omit(searchParams, ['limit', 'offset']))
    ) {
      searchParams.offset = 0;
    }

    if (searchQuery === '' || undefined) {
      searchParams.query = undefined;
    }

    dispatch(getUsersAction.request({ ...searchParams, callback }));
  };

  const onSort = (sortOption: OptionType) => {
    const sorting = Sorting[sortOption.value as sortingTypeKeys];
    setSortValue(sortOption);
    setSortField(sorting.sortField as userSortFields);
    setSortingType(sorting.sortType);
  };

  const usersListItems = users?.map((user) => {
    const userListItem = {
      id: user.id,
      title: '' as string,
      description: '',
      image: '',
    };
    if (user.person) {
      userListItem.title = user.roles?.map((role) => role.title).join(' | ');
      userListItem.description = generatePersonName(user.person);
      userListItem.image = getListImage(user.person?.images) || '';
    } else {
      userListItem.description = user.login;
    }
    return userListItem;
  });

  return (
    <>
      <div className="users-page">
        <Helmet>
          <title>
            Users | Voltore
            {process.env.REACT_APP_COMPANY_NAME
              ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        <div className="page-layout__content">
          <DataList
            setIsLoading={setLoading}
            itemsLoading={loading}
            items={usersListItems}
            itemsCount={count}
            allItemsLoaded={isAll}
            pageTitle={PageEnum.USERS}
            singleItem="user"
            onLoadMore={loadUsers}
            onSort={onSort}
            sortValue={sortValue}
            sortOptions={usersSortOptions}
            listControls={{
              searchPlaceholder: 'Search by Person Name, Login, User Role',
              searchQuery,
              clearSearchQuery: () => setSearchQuery(''),
              onSearch: (query) => setSearchQuery(query),
              onAddNew: () => {
                dispatch(
                    addShowedElement({
                      id: uuid(),
                      key: createUserInfoModalKey,
                      type: ShowElementEnum.modal,
                      props: {},
                    } as IModalElement),
                );
              },
            }}
          />
          <div className="users-page__content">
            {pathname === `/${PageEnum.USERS}` && (
              <div className="empty-container-wrapper_user">
                <div className="empty-container" />
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
      {/*{isPersonModalOpen && (*/}
      {/*  <PersonModal*/}
      {/*    loadPersons={loadUsers}*/}
      {/*    onClose={() => setIsPersonModalOpen(false)}*/}
      {/*    onSubmit={(payload) => dispatch(createPersonAction.request(payload))}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

export default connect((store: IStore) => ({
  routerState: store.router,
  userState: store.user,
}))(UsersPage);
