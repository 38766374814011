import { all, put, takeEvery, call } from 'redux-saga/effects';
import {
  ExportGetListResponse,
} from "@ternala/voltore-types";

// APIs
import { ExportApi } from '../transport/export.api';

// Actions
import { addError, addLoader, removeLoader } from '../index';

// Utils
import {uuid} from 'utils';

// Constants
import { LoaderAction } from 'config/constants';

import { IError } from '../../model';
import { getAccessTokenSaga } from "../../auth/sagas/auth";
import { getExportTemplatesAction } from "../actions";

export function* getExportTemplatesSaga({
  payload,
}: ReturnType<typeof getExportTemplatesAction.request>) {
  const accessToken: string | undefined = yield call(getAccessTokenSaga);
  const loadId = uuid();
  yield put(
    addLoader({
      id: loadId,
      message: 'Please wait, export templates are loading!',
      type: LoaderAction.enterprise.getList,
    }),
  );
  try {
    if (!accessToken) throw new Error('Not authorized');
    let data = Object.assign({}, payload);
    delete data.callback;
    const res: ExportGetListResponse | string | IError =
      yield ExportApi.getEnterprises(data, accessToken);

    if (typeof res === "undefined" || typeof res === 'string' || 'message' in res) {
      if (typeof res === 'string') {
        getExportTemplatesAction.failure({
          code: res,
          message: res || 'Something was wrong',
        });
      } else {
        yield put(
          getExportTemplatesAction.failure({
            code: String(res.code),
            message: res.message || 'Something was wrong',
          }),
        );
      }
      yield put(
        addError({
          id: loadId,
          message: 'Failed to get enterprises!',
          type: LoaderAction.export.getList,
        }),
      );
    } else {
      yield put(
        getExportTemplatesAction.success({
          response: res,
          searchParams: payload,
          isAll: payload.limit ? res.items.length < payload.limit : true,
        }),
      );
      yield put(
        removeLoader({
          id: loadId,
        }),
      );
      if (typeof payload.callback === 'function') payload.callback();
    }
  } catch (error: any) {
    if (typeof payload.callback === 'function') payload.callback(false);
    yield put(
      getExportTemplatesAction.failure({
        code: error.code || 400,
        message: error.message || error || 'Something was wrong',
      }),
    );
    yield put(
      addError({
        id: loadId,
        message: 'Failed to get export templates!',
        type: LoaderAction.export.getList,
      }),
    );
  }
}

export function* exportActionSaga() {
  yield all([
    takeEvery(getExportTemplatesAction.request, getExportTemplatesSaga),
  ]);
}
