import { all, put, takeEvery, call } from "redux-saga/effects";

// APIs
import {TagApi} from './../transport/tag.api';
import {getAccessToken} from '../../auth';

// Actions
import {
    createTagAction,
    createTagCategoryAction,
    deleteTagAction,
    deleteTagCategoryAction,
    detachTagAction,
    getTagCategoriesAction,
    updateTagAction,
    updateTagCategoryAction,
} from '../actions';
import {addError, addLoader, removeLoader} from '../index';

// Utils
import uuid from 'utils/uuid';

// Constants
import {LoaderAction} from 'config/constants';
import {omit} from 'lodash';
import {getAccessTokenUtil} from "../../../utils/getAccessToken";
import {
    TagCategoryCreateResponse,
    TagCategoryDeleteResponse,
    TagCategoryGetListResponse, TagCategoryUpdateResponse, TagCreateResponse,
    TagDeleteResponse,
    TagDetachResponse, TagUpdateResponse
} from "@ternala/voltore-types";
import {IError} from "../../model";
import {createEnterpriseAction, getEnterprisesAction, updateEnterpriseAction} from "../../enterprise/actions";
import {changeStatusAction} from "../../favorite/actions";
import { getAccessTokenSaga } from "../../auth/sagas/auth";

export function* getTagCategoriesSaga({
                                          payload,
                                      }: ReturnType<typeof getTagCategoriesAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, tag categories are loading!',
            type: LoaderAction.tag.getList,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: TagCategoryGetListResponse | null | IError = yield TagApi.getTagCategoryList(
            accessToken,
            data,
        );
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                getTagCategoriesAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    getTagCategoriesAction.failure({
                        code: String(res?.code),
                        message: res?.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to get tag categories!',
                    type: LoaderAction.tag.getList,
                }),
            )
        } else {
            yield put(
                getTagCategoriesAction.success({
                    response: res,
                    searchParams: payload,
                    isAll: payload.limit ? res.items.length < payload.limit : true,
                }),
            );
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true);
        }
    } catch (error: any) {
        console.error('error: ', error);
        yield put(
            getTagCategoriesAction.failure({
                code: error.code || 400,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                id: loadId,
                message: 'Failed to get tag categories!',
                type: LoaderAction.tag.getList,
            }),
        );
    }
}

export function* detachTagSaga({
                                   payload,
                               }: ReturnType<typeof detachTagAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, tag is detaching!',
            type: LoaderAction.tag.detach,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        if (payload.entity?.id) {
            const res: TagDetachResponse | string = yield TagApi.detachTag(
                {id: payload.id, [payload.entity?.type]: payload.entity?.id},
                accessToken,
            );
            if (typeof res === "undefined" || typeof res === "string") {
                detachTagAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
                yield put(
                    addError({
                        id: loadId,
                        message: 'Failed to detach tag!',
                        type: LoaderAction.tag.detach,
                    }),
                );
            } else {
                yield put(
                    detachTagAction.success({
                        ...res,
                        id: payload.id,
                        entity: payload.entity,
                    }),
                );
                yield put(
                    removeLoader({
                        id: loadId,
                    }),
                );
                if (typeof payload.callback === 'function') payload.callback(true);
            }
        }
    } catch (error: any) {
        console.error('error: ', error);
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            detachTagAction.failure({
                key: String(payload.id),
                code: error.code || 403,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                key: String(payload.id),
                id: loadId,
                message: 'Failed to detach atg!',
                type: LoaderAction.tag.detach,
            }),
        );
    }
}

export function* deleteTagSaga({
                                   payload,
                               }: ReturnType<typeof deleteTagAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, tag is deleting!',
            type: LoaderAction.tag.delete.tag,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        const res :TagDeleteResponse | string = yield TagApi.deleteTag(payload, accessToken);
        if (typeof res === "undefined" || typeof res === "string") {
            deleteTagAction.failure({
                code: res,
                message: res || 'Something was wrong',
            });
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to delete tag!',
                    type: LoaderAction.favorite.change,
                }),
            );
        } else {
            yield put(
                deleteTagAction.success({
                    ...res,
                    id: payload.id,
                    groupId: payload.groupId,
                }),
            );
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            deleteTagAction.failure({
                key: String(payload.id),
                code: error.code || 403,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                key: String(payload.id),
                id: loadId,
                message: 'Failed to delete tag!',
                type: LoaderAction.tag.detach,
            }),
        );
    }
}

export function* deleteTagCategorySaga({
                                           payload,
                                       }: ReturnType<typeof deleteTagCategoryAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, tag category is deleting!',
            type: LoaderAction.tag.delete.category,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        const res :TagCategoryDeleteResponse | string = yield TagApi.deleteTagCategory(payload, accessToken);
        if (typeof res === "undefined" || typeof res === "string") {
            deleteTagCategoryAction.failure({
                code: res,
                message: res || 'Something was wrong',
            });
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to change favorite status!',
                    type: LoaderAction.favorite.change,
                }),
            );
        } else {
            yield put(
                deleteTagCategoryAction.success({
                    ...res,
                    id: payload.id,
                }),
            );
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true);
        }
    } catch (error :any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            deleteTagCategoryAction.failure({
                key: String(payload.id),
                code: error.code || 403,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                key: String(payload.id),
                id: loadId,
                message: 'Failed to delete tag category!',
                type: LoaderAction.tag.detach,
            }),
        );
    }
}

export function* createTagSaga({
                                   payload,
                               }: ReturnType<typeof createTagAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, tag is creating!',
            type: LoaderAction.tag.create.tag,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: TagCreateResponse | string | IError = yield TagApi.createTag(
            data,
            accessToken,
        );
        if (typeof res === "undefined" || typeof res === "string" || ('message' in res || 'code' in res)) {
            if (typeof res === "string") {
                createTagAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
              if('message' in res){
                yield put(
                  createTagAction.failure({
                    code: String(res.code),
                    message: res.message || 'Something was wrong',
                  }),
                );
              }
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to create tag!',
                    type: LoaderAction.tag.create.tag,
                }),
            );
        } else {
            yield put(createTagAction.success({response: res}));
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            createTagAction.failure({
                code: error.code || 403,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                id: loadId,
                message: 'Failed to create tag!',
                type: LoaderAction.tag.create.tag,
            }),
        );
    }
}

export function* createTagCategorySaga({
                                           payload,
                                       }: ReturnType<typeof createTagCategoryAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, tag category is creating!',
            type: LoaderAction.tag.create.category,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res :TagCategoryCreateResponse | string | IError = yield TagApi.createTagCategory(
            data,
            accessToken,
        );
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                createTagCategoryAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    createTagCategoryAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to create tag!',
                    type: LoaderAction.tag.create.category,
                }),
            );
        } else {
            yield put(createTagCategoryAction.success(res));
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            createTagCategoryAction.failure({
                code: error.code || 403,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                id: loadId,
                message: 'Failed to create tag category!',
                type: LoaderAction.tag.create.category,
            }),
        );
    }
}

export function* updateTagSaga({
                                   payload,
                               }: ReturnType<typeof updateTagAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, tag is updating!',
            type: LoaderAction.tag.update.tag,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: TagUpdateResponse | string | IError = yield TagApi.updateTag(
            data,
            accessToken,
        );
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                updateTagAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    updateTagAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to update tag!',
                    type: LoaderAction.tag.update.tag,
                }),
            );
        } else {
            yield put(updateTagAction.success({...res, groupId: payload.groupId}));
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            updateTagAction.failure({
                key: String(payload.id),
                code: error.code || 403,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                key: String(payload.id),
                id: loadId,
                message: 'Failed to update tag!',
                type: LoaderAction.tag.update.tag,
            }),
        );
    }
}

export function* updateTagCategorySaga({
                                           payload,
                                       }: ReturnType<typeof updateTagCategoryAction.request>) {
    const accessToken: string | undefined = yield call(getAccessTokenSaga);
    const loadId = uuid();
    yield put(
        addLoader({
            id: loadId,
            message: 'Please wait, tag category is updating!',
            type: LoaderAction.tag.update.category,
        }),
    );
    try {
        if (!accessToken) throw new Error('Not authorized');
        let data = Object.assign({}, payload);
        delete data.callback;
        const res: TagCategoryUpdateResponse | string | IError = yield TagApi.updateTagCategory(
            data,
            accessToken,
        );
        if (typeof res === "undefined" || typeof res === "string" || 'message' in res) {
            if (typeof res === "string") {
                updateTagCategoryAction.failure({
                    code: res,
                    message: res || 'Something was wrong',
                });
            } else {
                yield put(
                    updateTagCategoryAction.failure({
                        code: String(res.code),
                        message: res.message || 'Something was wrong',
                    }),
                );
            }
            yield put(
                addError({
                    id: loadId,
                    message: 'Failed to update tag category!',
                    type: LoaderAction.tag.update.tag,
                }),
            );
        } else {
            yield put(updateTagCategoryAction.success(res));
            yield put(
                removeLoader({
                    id: loadId,
                }),
            );
            if (typeof payload.callback === 'function') payload.callback(true, res.id);
        }
    } catch (error: any) {
        if (typeof payload.callback === 'function') payload.callback(false);
        yield put(
            updateTagCategoryAction.failure({
                key: String(payload.id),
                code: error.code || 403,
                message: error.message || error || 'Something was wrong',
            }),
        );
        yield put(
            addError({
                key: String(payload.id),
                id: loadId,
                message: 'Failed to update tag category!',
                type: LoaderAction.tag.update.category,
            }),
        );
    }
}

export function* tagActionSaga() {
    yield all([
        takeEvery(getTagCategoriesAction.request, getTagCategoriesSaga),
        takeEvery(detachTagAction.request, detachTagSaga),
        takeEvery(deleteTagAction.request, deleteTagSaga),
        takeEvery(deleteTagCategoryAction.request, deleteTagCategorySaga),
        takeEvery(createTagAction.request, createTagSaga),
        takeEvery(updateTagAction.request, updateTagSaga),
        takeEvery(createTagCategoryAction.request, createTagCategorySaga),
        takeEvery(updateTagCategoryAction.request, updateTagCategorySaga),
    ]);
}
