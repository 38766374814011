import React, { FC } from 'react';
import 'assets/scss/components/_loader.scss';

const Loader: FC<any> = ({customStyle}) => (
   <div>
      <div className="loader-wrapper" style={{...customStyle}}>
         <div className="loader">
            <div />
         </div>
      </div>
   </div>
)

export default Loader;
