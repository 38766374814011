import React from 'react';
import { useSelector } from 'react-redux';

// controllers
import {
  getModals,
  getNotifications,
} from '../controllers/showElement/selectors';
import {
  IModalElement,
  INotificationElement,
} from '../controllers/showElement/models';

// Notifications
import Notification, { notificationKey } from './elements/Notification';
import SimpleNotification from 'components/Notifications/Controls/Notification';

// Modals
import TagGroupModal, { tagCategoryModalKey } from './modals/PopupTagGroup';
import TagModal, { tagModalKey } from './modals/PopupTag';
import { animated, useTransition } from 'react-spring';
import { config } from '@react-spring/core';
import { INotification } from '../controllers/modals/models';
import TaxonomyModal, { taxonomyModalKey } from './modals/TaxonomyModal';
import UserRoleModal, { userRoleModalKey } from './modals/UserRoleModal';
import CreateUserInfoModal, {
  createUserInfoModalKey,
} from './modals/users/CreateUserInfo';
import PersonModal, { personModalKey } from './modals/PersonModal';
import AddressModal, {
  addressModalKey,
} from './modals/properties/AddressModal';
import ExportTemplateFilesContent, {
  exportTemplateFilesModalKey,
} from './modals/ExportTemplateFilesContent';
import { BaseModal } from './modals/Modal';
import PropertyModalContent, {
  propertyModalKey,
} from './modals/properties/PropertyModalConent';

interface IProps {
  additionalNotifications?: INotification[];
}

export const ShowElement: React.FC<IProps> = ({ additionalNotifications }) => {
  const notifications = useSelector(getNotifications);
  const modals = useSelector(getModals);

  const transitions = useTransition(notifications, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  });
  const transitions2 = useTransition(additionalNotifications, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  });

  const generateModal = (el: IModalElement) => {
    switch (el.key) {
      case propertyModalKey:
        return (
          <BaseModal showElementId={el.id}>
            {(closeModal) => (
              <PropertyModalContent
                {...el.props}
                closeModal={closeModal}
                completeAction={el.callback}
              />
            )}
          </BaseModal>
          // <PropertyModal {...el.props} isByShowElement={true} completeAction={el.callback} showElementId={el.id}/>
        );
      case exportTemplateFilesModalKey:
        return (
          <BaseModal showElementId={el.id}>
            {(closeModal) => (
              <ExportTemplateFilesContent
                {...el.props}
                closeModal={closeModal}
                completeAction={el.callback}
              />
            )}
          </BaseModal>
        );
      case taxonomyModalKey:
        return (
          <TaxonomyModal
            {...el.props}
            completeAction={el.callback}
            showElementId={el.id}
          />
        );
      case userRoleModalKey:
        return (
          <UserRoleModal
            {...el.props}
            completeAction={el.callback}
            showElementId={el.id}
          />
        );
      case createUserInfoModalKey:
        return (
          <CreateUserInfoModal
            {...el.props}
            isByShowElement={true}
            completeAction={el.callback}
            showElementId={el.id}
          />
        );
      case personModalKey:
        return (
          <PersonModal
            {...el.props}
            isByShowElement={true}
            completeAction={el.callback}
            showElementId={el.id}
          />
        );
      case tagCategoryModalKey:
        return (
          <TagGroupModal
            {...el.props}
            isByShowElement={true}
            completeAction={el.callback}
            showElementId={el.id}
          />
        );
      case tagModalKey:
        return (
          <TagModal
            {...el.props}
            isByShowElement={true}
            completeAction={el.callback}
            showElementId={el.id}
          />
        );
      case addressModalKey:
        return (
          <AddressModal
            {...el.props}
            isByShowElement={true}
            completeAction={el.callback}
            showElementId={el.id}
          />
        );
      default:
        <></>;
    }
  };

  const generateNotification = (el: INotificationElement) => {
    switch (el.key) {
      case notificationKey:
        return <Notification {...el.props} id={el.id} />;
    }
  };

  return (
    <>
      <div className={'notifications'}>
        {transitions(({ opacity }, el) => (
          <animated.div
            style={{
              opacity: opacity,
            }}>
            {generateNotification(el)}
          </animated.div>
        ))}
        {transitions2(({ opacity }, notification) => (
          <animated.div
            style={{
              opacity: opacity,
            }}>
            {notification && <SimpleNotification notification={notification} />}
          </animated.div>
        ))}
      </div>
      <div className={'modals'}>{modals.map((el) => generateModal(el))}</div>
    </>
  );
};
