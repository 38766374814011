import React, { FC, ReactNode, useEffect, useState } from 'react';
// import Scrollbars from 'react-custom-scrollbars'
import { connect, useDispatch } from 'react-redux';
import { push, RouterState } from 'connected-react-router';
import { omit } from 'lodash';

/* components */
import LeaseTransactionModal from 'components/modals/properties/LeaseTransactionModal';
import LeaseTransactionItemsView from './LeaseTransactionItemsView';
import LeaseTransactionItem from './LeaseTransactionItem';
import Loader from 'components/Loader';
import SelectSort from 'components/UI/controls/SelectSort';
import Button from 'components/UI/controls/Button';

/* icons */
import { StackIcon } from 'components/icons/StackIcon';
import { MarkerPinIcon } from 'components/icons/MarkerPinIcon';
import { BurgerIcon } from 'components/icons/BurgerIcon';

/* controllers */
import { getPropertyAction } from 'controllers/property/actions';
import {
  createLeaseTransactionAction,
  getLeaseTransactionsAction,
} from 'controllers/property/actions/leaseTransationActions';

/* constants */
import { LOADED_ITEMS_LIMIT } from 'config';
import { LeaseTransactionViewTypeEnum, PageEnum } from 'config/constants';
import { propertiesTabs } from 'config/constants/tabs';
import { leasesFilterOptions } from 'config/constants/select-options';
import { LeaseTransactionStatusEnum, sortType } from "@ternala/voltore-types/lib/constants";
import { Color } from 'components/UI/controls/shared/styles';

/* utils */
import uuid from 'utils/uuid';
import { checkCommonItems } from 'utils/checkCommonItems';
import { concatWithUnique } from 'utils/concatWithUnique';

/* types */
import {
  LeaseTransactionFullExpandDTO,
  LeaseTransactionGetListRequest,
  LeaseTransactionStatusHistoryDTO,
  SuiteDTO,
} from '@ternala/voltore-types';
import { IStore } from 'controllers/store';
import { IPropertyState } from 'controllers/property/models';
import { IView, OptionType } from 'models';
import { mapEnum } from 'utils/helper-functions';
import { Helmet } from 'react-helmet';
import CustomScrollbars from 'components/CustomScrollbars';
import {
  callbackTypeEnum,
  requestCallback,
} from 'utils/requestCallback';

interface Props {
  propertyId: number;
  tabName: string;
  routerState: RouterState;
  propertyState: IPropertyState;
  children?: ReactNode;
}

const views: IView[] = [
  {
    icon: MarkerPinIcon,
    slug: LeaseTransactionViewTypeEnum.MAP,
  },
  {
    icon: StackIcon,
    slug: LeaseTransactionViewTypeEnum.STACK,
  },
  {
    icon: () => <BurgerIcon color={Color.mainDark} width={12} height={8} />,
    slug: LeaseTransactionViewTypeEnum.LIST,
  },
];

export interface IFloor {
  items: IStackItem[];
  title: string;
}

export interface IStackItem {
  id: string;
  suites: SuiteDTO[];
  items: LeaseTransactionFullExpandDTO[];
}

const TransactionLeasesTab: FC<Props> = (props) => {
  const {
    propertyId,
    tabName,
    routerState: {
      location: { pathname },
    },
    propertyState: { propertyData, leaseTransactionData },
    children,
  } = props;

  const currentProperty = propertyData[propertyId];
  const leaseTransactions = currentProperty?.leaseTransactionState?.items || [];

  const dispatch = useDispatch();

  const [selectedView, setSelectedView] =
    useState<LeaseTransactionViewTypeEnum>(LeaseTransactionViewTypeEnum.LIST);
  const [selectedLeaseTransactions, setSelectedLeaseTransactions] = useState<
    LeaseTransactionFullExpandDTO[] | null
  >(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [transactionsFilter, setTransactionsFilter] = useState<OptionType>(
    leasesFilterOptions[0],
  );
  const [transactionsVisualizationFilter, setTransactionsVisualizationFilter] =
    useState<OptionType>(leasesFilterOptions[0]);

  const [floors, setFloors] = useState<IFloor[]>([]);
  const [NAFloor, setNAFloor] = useState<IFloor>({
    title: 'n/a',
    items: [],
  });

  useEffect(() => {
    dispatch(getPropertyAction.request({ id: propertyId }));
    dispatch(
      push(
        tabName === propertiesTabs.tenants.title
          ? `/${PageEnum.PROPERTIES}/${propertyId}/${propertiesTabs.tenants.path}`
          : `/${PageEnum.PROPERTIES}/${propertyId}/${propertiesTabs.leases.path}`,
      ),
    );
    setLoading(true);
    loadTransactions({ callback: () => setLoading(false) });
  }, [transactionsFilter]);

  useEffect(() => {
    if (selectedView === LeaseTransactionViewTypeEnum.LIST) {
      setSelectedLeaseTransactions(null);
    } else {
      setTransactionsFilter(leasesFilterOptions[0]);
    }
  }, [selectedView]);

  useEffect(() => {
    splitLeases();
  }, []);

  useEffect(() => {
    splitLeases();
  }, [currentProperty?.leaseTransactionState?.items]);

  const loadTransactions = ({
    callback,
    limit = LOADED_ITEMS_LIMIT,
    offset,
  }: {
    callback?: Function;
    limit?: number;
    offset?: number;
  }) => {
    const searchParams: LeaseTransactionGetListRequest = {
      property: propertyId,
      sortType: sortType.desc,
      limit: limit,
      offset: offset || Object.keys(leaseTransactionData).length || 0,
    };

    if (tabName === propertiesTabs.tenants.title) {
      searchParams.statuses = [
        LeaseTransactionStatusEnum.Leased,
      ] as LeaseTransactionStatusEnum[];
    } else {
      if (
        transactionsFilter.value &&
        transactionsFilter.value !== LeaseTransactionStatusEnum.Leased &&
        typeof transactionsFilter.value === 'string'
      ) {
        searchParams.statuses = [
          transactionsFilter.value,
        ] as LeaseTransactionStatusEnum[];
      } else {
        searchParams.statuses = mapEnum(
          LeaseTransactionStatusEnum,
          (status: LeaseTransactionStatusEnum) => status,
        ).filter(
          (status) => status !== LeaseTransactionStatusEnum.Leased,
        ) as LeaseTransactionStatusEnum[];
      }
    }

    if (
      JSON.stringify(
        omit(currentProperty?.leaseTransactionState?.storedSearchParams, [
          'limit',
          'offset',
        ]),
      ) !== JSON.stringify(omit(searchParams, ['limit', 'offset']))
    ) {
      searchParams.offset = 0;
    }

    dispatch(getLeaseTransactionsAction.request({ ...searchParams, callback }));
  };

  const sortStatuses = (statuses: LeaseTransactionStatusHistoryDTO[]) => {
    return statuses.sort((status1, status2) => {
      let priority1 = 0;
      let priority2 = 0;

      if (new Date(status1.startDate) > new Date()) {
        if (status1.status === LeaseTransactionStatusEnum.Leased) {
          priority1 = 1;
        } else {
          priority1 = -1;
        }
      }
      if (new Date(status2.startDate) > new Date()) {
        if (status2.status === LeaseTransactionStatusEnum.Leased) {
          priority2 = 1;
        } else {
          priority2 = -1;
        }
      }
      if (priority1 > priority2) return -1;
      if (priority1 < priority2) return 1;

      if (status1.startDate > status2.startDate) return -1;
      if (status1.startDate < status2.startDate) return 1;

      return 0;
    });
  };

  const splitLeases = () => {
    const leaseTransactionsSplit: IFloor[] = [];
    let leaseTransactionsNAItems: IFloor = {
      title: 'n/a',
      items: [],
    };

    currentProperty?.floors
      ?.sort((floor1, floor2) => {
        if (floor1.position < floor2.position) return -1;
        if (floor1.position > floor2.position) return 1;
        return 0;
      })
      ?.forEach((floor) => {
        leaseTransactionsSplit.push({
          items: [],
          title: floor.title,
        });
      });

    leaseTransactions
      .map((id) => leaseTransactionData[id])
      .slice()
      .sort((lease1, lease2) => {
        lease1.historyStatuses = sortStatuses(lease1.historyStatuses || []);
        lease2.historyStatuses = sortStatuses(lease2.historyStatuses || []);

        if (
          (lease1.historyStatuses[0]?.startDate || 0) >
          (lease2.historyStatuses[0]?.startDate || 0)
        )
          return -1;
        if (
          (lease1.historyStatuses[0]?.startDate || 0) <
          (lease2.historyStatuses[0]?.startDate || 0)
        )
          return 1;
        return 0;
      })
      .forEach((leaseTransaction) => {
        (leaseTransaction?.floors?.length
          ? leaseTransaction.floors
          : [{ title: 'n/a' }]
        ).forEach((floor) => {
          let foundFloor = leaseTransactionsSplit.find(
            (floorItem) => floorItem.title === floor.title,
          );
          if (floor.title === 'n/a')
            foundFloor = {
              title: 'n/a',
              items: [...leaseTransactionsNAItems.items],
            };
          if (!foundFloor) {
            const length = leaseTransactionsSplit.push({
              items: [],
              title: floor.title,
            });
            foundFloor = leaseTransactionsSplit[length - 1];
          }
          const leaseTransactionSuites = leaseTransaction?.suites || [];

          if (leaseTransactionSuites) {
            let fountStackItem = foundFloor.items.find((stackItem) =>
              checkCommonItems(
                stackItem.suites.map((suite) => suite.id),
                leaseTransactionSuites.map((suite) => suite.id),
              ),
            );

            if (!fountStackItem) {
              const length = foundFloor.items.push({
                id: uuid(),
                suites: leaseTransactionSuites,
                items: [],
              });
              fountStackItem = foundFloor.items[length - 1];
            }

            fountStackItem.suites = concatWithUnique<SuiteDTO>(
              leaseTransactionSuites,
              fountStackItem.suites,
              'id',
              true,
            );

            fountStackItem.items.push(leaseTransaction);
          } else {
            foundFloor.items.push({
              id: uuid(),
              suites: [],
              items: [leaseTransaction],
            });
          }
          if (floor.title === 'n/a') leaseTransactionsNAItems = foundFloor;
        });
      });
    setNAFloor(leaseTransactionsNAItems);
    setFloors(leaseTransactionsSplit);
  };

  return (
    <>
      <div className="item-info__tab properties__leases-tab leases-tab items-container">
        <Helmet>
          <title>
            {tabName === propertiesTabs.tenants.title ? 'Tenants' : 'Leases'} |
            Voltore{' '}
            {process.env.REACT_APP_COMPANY_NAME
              ? `| ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        <div
          className={
            'leases-tab__transactions transactions' +
            (!pathname.endsWith(propertiesTabs.leases.path)
              ? ' leases-tab__transactions_hidden'
              : '')
          }>
          <header className="transactions__top-controls">
            <div>
              {views
                .filter((view) => view.slug !== selectedView)
                .map(({ icon: Icon, slug }) => {
                  return (
                    <Button
                      key={slug}
                      className="toggle-button_sm"
                      variant="toggle-light"
                      onClick={() => setSelectedView(slug)}>
                      <Icon />
                    </Button>
                  );
                })}

              {tabName === propertiesTabs.leases.title && (
                <SelectSort
                  style={{ marginLeft: 5 }}
                  value={
                    selectedView === LeaseTransactionViewTypeEnum.LIST
                      ? transactionsFilter
                      : transactionsVisualizationFilter
                  }
                  onChange={(option) => {
                    selectedView === LeaseTransactionViewTypeEnum.LIST
                      ? setTransactionsFilter(option)
                      : setTransactionsVisualizationFilter(option);
                  }}
                  options={leasesFilterOptions}
                />
              )}
            </div>

            <Button
              className="button-add-new"
              variant="light"
              onClick={() => setIsModalOpen(true)}>
              Add new
            </Button>
          </header>

          {loading ? (
            <div className="sale-transactions-container__loader">
              <Loader />
            </div>
          ) : (
            currentProperty && (
              <LeaseTransactionItemsView
                tabName={tabName}
                viewType={selectedView}
                propertyId={propertyId}
                currentProperty={currentProperty}
                transactionsVisualizationFilter={
                  transactionsVisualizationFilter
                }
                loadTransactions={loadTransactions}
                leaseTransactionsSplitted={floors}
                leaseTransactionsNA={NAFloor}
                selectedLeaseTransactions={selectedLeaseTransactions}
                setSelectedLeaseTransactions={setSelectedLeaseTransactions}
              />
            )
          )}
        </div>

        <div className="leases-tab__transactions-column">
          <CustomScrollbars>
            <>
              {selectedLeaseTransactions &&
                selectedLeaseTransactions.length > 1 &&
                selectedLeaseTransactions.map(
                  (transaction: LeaseTransactionFullExpandDTO) => (
                    <LeaseTransactionItem
                      key={transaction.id}
                      id={propertyId}
                      tabName={tabName}
                      transactionId={transaction.id}
                      data={{
                        tenant: transaction.tenant?.title,
                        sqft: transaction.leasableSQFT,
                        price:
                          transaction.actualRate || transaction.offeredRate,
                        statuses: transaction.historyStatuses,
                        activeStatus: transaction.activeStatus,
                        commencement: transaction.leaseCommencement,
                        expiration: transaction.leaseExpiration,
                      }}
                    />
                  ),
                )}
              {children}
              {pathname.endsWith(propertiesTabs.leases.path) &&
                !selectedLeaseTransactions && (
                  <div className="empty-container" />
                )}
            </>
          </CustomScrollbars>
        </div>
      </div>

      {isModalOpen && (
        <LeaseTransactionModal
          property={{ id: propertyId, defaultOwner: currentProperty.owner }}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(payload) =>
            dispatch(
              createLeaseTransactionAction.request({
                ...payload,
                callback: (status: boolean, err?: string) => {
                  requestCallback(dispatch, status, callbackTypeEnum.create, err)
                  if (payload.callback) payload.callback(status);
                },
              }),
            )
          }
        />
      )}
    </>
  );
};

export default connect((store: IStore) => ({
  routerState: store.router,
  propertyState: store.property,
}))(TransactionLeasesTab);
