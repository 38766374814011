import { all } from 'redux-saga/effects';
import { createReducer, ActionType } from 'typesafe-actions';
import { omit } from 'lodash';

// Actions
import * as actions from './actions';
import * as involvementActions from '../involvement/actions';
import * as favoriteActions from '../favorite/actions';

// Interfaces
import { IStore, LoaderActionType } from 'controllers/store';
import { EnterpriseFullExpandedDTO, IEnterpriseState } from './models';
import { IError, ILoader } from 'models';

// Sagas
import { enterpriseActionSaga } from './sagas/enterprise';
import { concatWithUnique } from 'utils';
import { widgetName } from './actions';
import { generateLoaderActions } from '../based';

export type EnterpriseActionType = ActionType<typeof actions>;
export type InvolvementActionType = ActionType<typeof involvementActions>;
export type FavoriteActionType = ActionType<typeof favoriteActions>;

export const enterpriseSaga = function* () {
  yield all([enterpriseActionSaga()]);
};

/* Reducer */
const initialState: IEnterpriseState = {
  state: {
    errors: [],
    loaders: [],
  },
  storedSearchParams: null,
  enterpriseData: {},
};

type ActionTypes =
  | EnterpriseActionType
  | LoaderActionType
  | InvolvementActionType
  | FavoriteActionType;

const loaderActions = generateLoaderActions<IEnterpriseState, ActionTypes>(
  widgetName,
);

export const loaderHandlers = loaderActions.handlers;

export const enterpriseReducer = createReducer<IEnterpriseState, ActionTypes>(
  initialState,
  loaderHandlers,
)
  // favorite actions
  .handleAction(
    [favoriteActions.changeStatusAction.success],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      let entity;
      if(payload.entityId){
        entity = Object.assign({}, state.enterpriseData[payload.entityId]);
      }
      if(entity && payload.entityId){
        entity.emails = entity.emails?.map(item => {
          if(item.id === payload.id){
            return {
              ...item,
              isFavorite: payload.status
            }
          }
          return item
        })
        entity.phones = entity.phones?.map(item => {
          if(item.id === payload.id){
            return {
              ...item,
              isFavorite: payload.status
            }
          }
          return item
        })
        return {
          ...state,
          enterpriseData: {
            ...state.enterpriseData,
            [payload.entityId]: entity
          }
        }
      }
      return state;
    },
  )
  // get list action reducer
  .handleAction(
    [actions.getEnterprisesAction.success],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      const storedSearchParams = state.storedSearchParams;
      const { searchParams } = payload;

      const enterpriseData = {
        ...state.enterpriseData,
      };

      payload.response.items.forEach((enterprise) => {
        enterpriseData[enterprise.id] = {
          ...enterpriseData[enterprise.id],
          ...enterprise,
        };
      });

      let newItemsList;
      if (
        JSON.stringify(omit(storedSearchParams, ['limit', 'offset'])) ===
        JSON.stringify(omit(searchParams, ['limit', 'offset']))
      ) {
        newItemsList = concatWithUnique<number>(
          state.enterprises || [],
          payload.response.items.map((item) => item.id),
        );
      } else {
        newItemsList = concatWithUnique<number>(
          [],
          payload.response.items.map((item) => item.id),
        );
      }
      return {
        ...state,
        storedSearchParams: searchParams,
        count: payload.response.counts,
        enterprises: newItemsList,
        enterpriseData,
        isAll: payload.isAll,
      };
    },
  )
  // create action reducer
  .handleAction(
    [actions.createEnterpriseAction.success],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      return {
        ...state,
        enterpriseData: {
          ...state.enterpriseData,
          [payload.id]: payload,
        },
        newEnterprises: [...(state.newEnterprises || []), payload.id],
      };
    },
  )
  // get action reducer
  .handleAction(
    [
      actions.getEnterpriseAction.success,
      actions.getEnterpriseTreeAction.success,
    ],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      return {
        ...state,
        enterprises: concatWithUnique<number>(state.enterprises || [], [
          payload.id,
        ]),
        enterpriseData: {
          ...state.enterpriseData,
          [payload.id]: {
            ...{
              propertiesInvolved: state.enterpriseData[payload.id]?.propertiesInvolved,
              involvementState:  state.enterpriseData[payload.id]?.involvementState,
            },
            ...payload
          },
        },
      };
    },
  )
  .handleAction(
    [actions.updateEnterpriseAction.success],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      return {
        ...state,
        enterprises: concatWithUnique<number>(state.enterprises || [], [
          payload.id,
        ]),
        enterpriseData: {
          ...state.enterpriseData,
          [payload.id]: {
            ...payload,
          },
        },
      };
    },
  )
  // get owned properties
  .handleAction(
    [actions.getEnterpriseOwnedPropertiesAction.success],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      return {
        ...state,
        enterprises: concatWithUnique<number>(state.enterprises || [], [
          payload.id,
        ]),
        enterpriseData: {
          ...state.enterpriseData,
          [payload.id]: {
            ...state.enterpriseData[payload.id],
            propertiesInvolved: payload.response,
          },
        },
      };
    },
  )
  // delete action reducer
  .handleAction(
    [actions.deleteEnterpriseAction.success],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      return {
        ...state,
        newEnterprises: state.newEnterprises?.filter(
          enterprise => enterprise !== payload.id
        ),
        enterprises: state.enterprises?.filter(
          enterprise => enterprise !== payload.id,
        ),
        enterpriseData: payload.id
          ? omit(state.enterpriseData, [payload.id])
          : state.enterpriseData,
      };
    },
  )
  // work with involvements
  .handleAction(
    [involvementActions.getInvolvementsAction.success],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      if (payload.additionalFields.enterprise) {
        return {
          ...state,
          enterpriseData: {
            ...state.enterpriseData,
            ...{
              [payload.additionalFields.enterprise]: {
                ...(state.enterpriseData?.[
                  payload.additionalFields.enterprise
                ] || {}),
                involvementState: {
                  ...(state.enterpriseData?.[
                    payload.additionalFields.enterprise
                  ]?.involvementState || {}),
                  ...(payload.additionalFields.timeMarker === 'current'
                    ? {
                        currentInvolvements: {
                          items: payload.searchParams?.offset
                            ? concatWithUnique<number>(
                                state.enterpriseData?.[
                                  payload.additionalFields.enterprise
                                ].involvementState?.currentInvolvements
                                  ?.items || [],
                                payload.res.items.map((item) => item.id),
                              )
                            : payload.res.items.map((item) => item.id),
                          isAll: payload.isAll,
                          count: payload.res.counts,
                        },
                      }
                    : {}),
                  ...(payload.additionalFields.timeMarker === 'past'
                    ? {
                        pastInvolvements: {
                          items: payload.searchParams?.offset
                            ? concatWithUnique<number>(
                                state.enterpriseData?.[
                                  payload.additionalFields.enterprise
                                ].involvementState?.pastInvolvements?.items ||
                                  [],
                                payload.res.items.map((item) => item.id),
                              )
                            : payload.res.items.map((item) => item.id),
                          isAll: payload.isAll,
                          count: payload.res.counts,
                        },
                      }
                    : {}),
                },
              } as EnterpriseFullExpandedDTO,
            },
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.createInvolvementAction.success],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      if (
        payload.additionalFields.enterprise &&
        state.enterpriseData?.[payload.additionalFields.enterprise]
      ) {
        return {
          ...state,
          enterpriseData: {
            ...state.enterpriseData,
            [payload.additionalFields.enterprise]: {
              ...(state.enterpriseData?.[payload.additionalFields.enterprise] ||
                {}),
              involvementState: {
                ...(state.enterpriseData?.[payload.additionalFields.enterprise]
                  ?.involvementState || {}),
                currentInvolvements: {
                  ...(state.enterpriseData?.[
                    payload.additionalFields.enterprise
                  ]?.involvementState?.currentInvolvements || {}),
                  items: [
                    ...(!payload.res.endDate ||
                    new Date(payload.res.endDate) > new Date()
                      ? [payload.res.id]
                      : []),
                    ...(state.enterpriseData?.[
                      payload.additionalFields.enterprise
                    ]?.involvementState?.currentInvolvements?.items || []),
                  ],
                },
                pastInvolvements: {
                  ...(state.enterpriseData?.[
                    payload.additionalFields.enterprise
                  ]?.involvementState?.pastInvolvements || {}),
                  items: [
                    ...(payload.res.endDate &&
                    new Date(payload.res.endDate) < new Date()
                      ? [payload.res.id]
                      : []),
                    ...(state.enterpriseData?.[
                      payload.additionalFields.enterprise
                    ]?.involvementState?.pastInvolvements?.items || []),
                  ],
                },
              },
            } as EnterpriseFullExpandedDTO,
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.updateInvolvementAction.success],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      if (
        payload.additionalFields.enterprise &&
        state.enterpriseData?.[payload.additionalFields.enterprise]
      ) {
        const isPast =
          payload.res.endDate && new Date(payload.res.endDate) < new Date();
        const involvementState =
          state.enterpriseData?.[payload.additionalFields.enterprise]
            ?.involvementState;
        return {
          ...state,
          enterpriseData: {
            ...state.enterpriseData,
            [payload.additionalFields.enterprise]: {
              ...(state.enterpriseData?.[payload.additionalFields.enterprise] ||
                {}),
              involvementState: {
                ...(involvementState || {}),
                currentInvolvements: {
                  ...(involvementState?.currentInvolvements || {}),
                  items:
                    payload.res.enterprise?.id ===
                      payload.additionalFields.enterprise && !isPast
                      ? concatWithUnique(
                          [payload.res.id],
                          involvementState?.currentInvolvements?.items || [],
                        )
                      : (
                          involvementState?.currentInvolvements?.items || []
                        ).filter(
                          (involvement) => involvement !== payload.res.id,
                        ),
                },
                pastInvolvements: {
                  ...(involvementState?.pastInvolvements || {}),
                  items:
                    payload.res.enterprise?.id ===
                      payload.additionalFields.enterprise && isPast
                      ? concatWithUnique(
                          [payload.res.id],
                          involvementState?.pastInvolvements?.items || [],
                        )
                      : (
                          involvementState?.pastInvolvements?.items || []
                        ).filter(
                          (involvement) => involvement !== payload.res.id,
                        ),
                },
              },
            } as EnterpriseFullExpandedDTO,
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.deleteInvolvementAction.success],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      if (
        payload.additionalFields.enterprise &&
        state.enterpriseData?.[payload.additionalFields.enterprise]
      ) {
        return {
          ...state,
          enterpriseData: {
            ...state.enterpriseData,
            [payload.additionalFields.enterprise]: {
              ...(state.enterpriseData?.[payload.additionalFields.enterprise] ||
                {}),
              involvementState: {
                ...(state.enterpriseData?.[payload.additionalFields.enterprise]
                  ?.involvementState || {}),
                currentInvolvements: {
                  ...(state.enterpriseData?.[
                    payload.additionalFields.enterprise
                  ]?.involvementState?.currentInvolvements || {}),
                  items:
                    state.enterpriseData?.[
                      payload.additionalFields.enterprise
                    ]?.involvementState?.currentInvolvements?.items.filter(
                      (id) => id !== payload.id,
                    ) || [],
                },
                pastInvolvements: {
                  ...(state.enterpriseData?.[
                    payload.additionalFields.enterprise
                  ]?.involvementState?.pastInvolvements || {}),
                  items:
                    state.enterpriseData?.[
                      payload.additionalFields.enterprise
                    ]?.involvementState?.pastInvolvements?.items.filter(
                      (id) => id !== payload.id,
                    ) || [],
                },
              },
            } as EnterpriseFullExpandedDTO,
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.addInvolvementLoader],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      if (
        payload.additionalFields.enterprise &&
        state.enterpriseData?.[payload.additionalFields.enterprise]
      ) {
        return {
          ...state,
          enterpriseData: {
            ...state.enterpriseData,
            ...{
              [payload.additionalFields.enterprise]: {
                ...(state.enterpriseData?.[
                  payload.additionalFields.enterprise
                ] || {}),
                involvementState: {
                  ...(state.enterpriseData?.[
                    payload.additionalFields.enterprise
                  ]?.involvementState || {}),
                  state: {
                    loaders: [
                      ...(state.enterpriseData?.[
                        payload.additionalFields.enterprise
                      ].involvementState?.state?.loaders || []),
                      omit(payload, ['additionalFields']),
                    ],
                    errors:
                      state.enterpriseData?.[
                        payload.additionalFields.enterprise
                      ].involvementState?.state?.errors || [],
                  },
                },
              } as EnterpriseFullExpandedDTO,
            },
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.removeInvolvementLoader],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      if (
        payload.additionalFields.enterprise &&
        state.enterpriseData?.[payload.additionalFields.enterprise]
      ) {
        return {
          ...state,
          enterpriseData: {
            ...state.enterpriseData,
            ...{
              [payload.additionalFields.enterprise]: {
                ...(state.enterpriseData?.[
                  payload.additionalFields.enterprise
                ] || {}),
                involvementState: {
                  ...(state.enterpriseData?.[
                    payload.additionalFields.enterprise
                  ]?.involvementState || {}),
                  state: {
                    loaders:
                      state.enterpriseData?.[
                        payload.additionalFields.enterprise
                      ].involvementState?.state?.loaders.filter(
                        (loader: ILoader) => loader.id !== payload.id,
                      ) || [],
                    errors:
                      state.enterpriseData?.[
                        payload.additionalFields.enterprise
                      ].involvementState?.state?.errors || [],
                  },
                },
              } as EnterpriseFullExpandedDTO,
            },
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.addInvolvementError],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      if (
        payload.additionalFields.enterprise &&
        state.enterpriseData?.[payload.additionalFields.enterprise]
      ) {
        return {
          ...state,
          enterpriseData: {
            ...state.enterpriseData,
            ...{
              [payload.additionalFields.enterprise]: {
                ...(state.enterpriseData?.[
                  payload.additionalFields.enterprise
                ] || {}),
                involvementState: {
                  ...(state.enterpriseData?.[
                    payload.additionalFields.enterprise
                  ]?.involvementState || {}),
                  state: {
                    loaders:
                      state.enterpriseData?.[
                        payload.additionalFields.enterprise
                      ].involvementState?.state?.loaders || [],
                    errors: [
                      ...(state.enterpriseData?.[
                        payload.additionalFields.enterprise
                      ].involvementState?.state?.errors || []),
                      payload,
                    ],
                  },
                },
              } as EnterpriseFullExpandedDTO,
            },
          },
        };
      }
      return state;
    },
  )
  .handleAction(
    [involvementActions.removeInvolvementError],
    (state: IEnterpriseState, { payload }): IEnterpriseState => {
      if (
        payload.additionalFields.enterprise &&
        state.enterpriseData?.[payload.additionalFields.enterprise]
      ) {
        return {
          ...state,
          enterpriseData: {
            ...state.enterpriseData,
            ...{
              [payload.additionalFields.enterprise]: {
                ...(state.enterpriseData?.[
                  payload.additionalFields.enterprise
                ] || {}),
                involvementState: {
                  ...(state.enterpriseData?.[
                    payload.additionalFields.enterprise
                  ]?.involvementState || {}),
                  state: {
                    loaders:
                      state.enterpriseData?.[
                        payload.additionalFields.enterprise
                      ].involvementState?.state?.loaders || [],
                    errors:
                      state.enterpriseData?.[
                        payload.additionalFields.enterprise
                      ].involvementState?.state?.errors.filter(
                        (error: IError) => error.type !== payload.target,
                      ) || [],
                  },
                },
              } as EnterpriseFullExpandedDTO,
            },
          },
        };
      }
      return state;
    },
  );
/* Loader actions */
export const addLoader = loaderActions.actions.addLoader;
export const addError = loaderActions.actions.addError;
export const removeError = loaderActions.actions.removeError;
export const removeLoader = loaderActions.actions.removeLoader;

/* Selectors */
export const getEnterprisesList = (state: IStore) =>
  state.enterprise.enterprises;
export const getEnterprisesCount = (state: IStore) => state.enterprise.count;
