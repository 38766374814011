import React, { FC, useState, useEffect, useRef, RefObject } from 'react';

/* components */
import Loader from 'components/Loader';
import { SelectDropdownIcon } from 'components/icons/SelectDropdownIcon';
import { TimesIcon } from 'components/icons/TimesIcon';
import { Creation } from '../shared/components/Creation';

/* utils */
import { getUniqueId } from 'utils/helper-functions';

/* styles */
import { Color } from '../shared/styles';
import style from './SelectSearch.module.scss';

/* types */
import { ICreation, ItemsLoadType, OptionType } from 'models';
import Datalist from './Optionslist';
import { LOADED_ITEMS_LIMIT } from 'config';

export interface AsyncOptions {
  limit: number;
  query: string;
  offset: number;
}

interface Props {
  label?: string;
  error?: string;
  style?: { [key: string]: number | string };
  className?: string;
  name?: string;
  placeholder?: string;
  value?: OptionType[];
  options?: OptionType[];
  searchByFields?: string[];
  asyncOptions?: (searchParams: AsyncOptions) => Promise<OptionType[]>;
  creation?: ICreation;
  onChange: (option?: OptionType[]) => void;
  maxOptions?: number;
  optionHeight?: number;
  autoComplete?: string;
  fullWidth?: boolean;
  required?: boolean;
  isDisabled?: boolean;
  readonly?: boolean;
  children?: JSX.Element | JSX.Element[];
  tags?: number[];
  uppercase?: boolean;
}

export const SelectSearch: FC<Props> = (props) => {
  const {
    label,
    value,
    options,
    asyncOptions,
    uppercase,
    searchByFields,
    optionHeight,
    maxOptions,
    creation,
    isDisabled,
    required,
    fullWidth,
    tags,
    autoComplete,
    error,
  } = props;
  const timeout = useRef<number>();

  const isSelectedNew = Boolean(value?.length);
  const menuHeight = optionHeight && maxOptions && optionHeight * maxOptions;

  const [isAllItemsLoaded, setIsAllItemsLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [fetchedOptions, setFetchedOptions] = useState<OptionType[]>([]);

  const inputRef = useRef() as RefObject<HTMLInputElement>;
  const inputId = useRef(`input-${getUniqueId()}`);

  if (options && asyncOptions) {
    throw new Error(
      'only static options or async options function can be provided',
    );
  }

  const filteredOptionsNew = asyncOptions ? fetchedOptions : [...(options || []), ...fetchedOptions].filter(
    (option) =>
      searchByFields && searchByFields.length > 0
        ? searchByFields
            .filter((field) => (option as any)[field])
            .some((field) =>
              (option as any)[field]
                .trim()
                .toLowerCase()
                .includes(searchValue.trim().toLowerCase()),
            )
        : option.label
            ?.trim()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()),
  );
  const menuHeightMin =
    menuHeight && optionHeight && filteredOptionsNew
      ? optionHeight * filteredOptionsNew?.length <= menuHeight
        ? filteredOptionsNew.length * optionHeight
        : menuHeight
      : menuHeight;

  useEffect(() => {
    if (asyncOptions) {
      setLoading(true);
      clearTimeout(timeout.current);
      timeout.current = window.setTimeout(function () {
        asyncLoadOptions('start');
      }, 400);
    }
  }, [searchValue]);

  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, []);

  /* handlers */
  const onClickOutside = (event: MouseEvent) => {
    if (event.target instanceof HTMLElement) {
      if (
        event.target &&
        event.target.tagName === 'INPUT' &&
        inputId.current !== event.target.id
      )
        setIsMenuOpen(false);
      if (
        !event.target.className.includes('SelectSearch') &&
        !event.target.className.includes('creation')
      )
        setIsMenuOpen(false);
    }
  };

  const asyncLoadOptions = async (
    loadType: ItemsLoadType,
    query?: string,
  ): Promise<void> => {
    if (asyncOptions) {
      setLoading(true);
      const options = await asyncOptions({
        limit: Number(LOADED_ITEMS_LIMIT),
        query: query || searchValue,
        offset: loadType === 'more' ? fetchedOptions.length : 0,
      });
      if (options.length < LOADED_ITEMS_LIMIT) setIsAllItemsLoaded(true);
      setFetchedOptions([
        ...(loadType === 'more' ? fetchedOptions || [] : []),
        ...options,
      ]);
    }
    setLoading(false);
  };

  const openMenu = () => {
    if (isDisabled) return;
    setIsMenuOpen(true);
    document.addEventListener('click', onClickOutside);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    resetState();
    document.removeEventListener('click', onClickOutside);
  };

  const onInputClick = () => {
    if (isSelectedNew || isDisabled) return;

    if (!isMenuOpen) {
      inputRef.current && inputRef.current.focus();
    }
    if (isMenuOpen) {
      inputRef.current && inputRef.current.blur();
      resetState();
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const selectOption = (option: OptionType) => {
    setSearchValue('');
    setIsMenuOpen(false);
    props.onChange([option]);
  };

  const removeSelection = () => {
    resetState();
    props.onChange(undefined);
  };

  const resetState = () => {
    setSearchValue('');
  };

  return (
    <div className={fullWidth ? style.container_full : style.container}>
      {label && (
        <label
          htmlFor={inputId.current}
          className={style.label}
          style={{
            color:
              props.isDisabled && !isSelectedNew
                ? Color.disabled
                : error
                ? Color.error
                : Color.label,
          }}>
          {label}
          {required && <span className={style.required}>*</span>}
        </label>
      )}

      {isSelectedNew && (
        <div
          className={
            style.selection_single +
            (props.readonly ? ` ${style.selection_readonly}` : '')
          }>
          <span style={{ textTransform: uppercase ? 'uppercase' : 'initial' }}>
            {value?.[0].label}
          </span>

          {!props.readonly && (
            <span onClick={removeSelection} title="Remove selected option" />
          )}
        </div>
      )}
      {!isSelectedNew && (
        <div className={style.control} onClick={onInputClick}>
          <input
            className={
              style.input +
              (error ? ` ${style.input_error}` : '') +
              (!filteredOptionsNew.length && !loading ? ` ${style.input_wrong}` : '')
            }
            id={inputId.current}
            ref={inputRef}
            autoComplete={autoComplete}
            type="text"
            name={props.name}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder={
              isMenuOpen
                ? ''
                : props.placeholder
                ? props.placeholder
                : creation
                ? 'Select one or create new'
                : 'Select one'
            }
            disabled={props.isDisabled}
          />
          {/*{searchValue ? <div className={style.clear} onClick={() => setSearchValue('')}><CloseIcon/></div> : ""}*/}

          <div className={style.dropdown_indicator}>
            {isMenuOpen ? (
              <TimesIcon onClick={closeMenu} />
            ) : (
              <SelectDropdownIcon onClick={openMenu} />
            )}
          </div>
        </div>
      )}
      {error && <span className={style.error}>{error}</span>}
      {isMenuOpen && (
        <div
          className={style.menu_wrapper}
          style={{
            height: menuHeight,
            ...(tags
              ? { width: '107%', marginLeft: '-10px', marginTop: '15px' }
              : ''),
          }}>
          <div className={style.menu}>
            {filteredOptionsNew?.length ? (
              <Datalist
                tags={tags}
                loading={loading}
                menuHeight={menuHeight}
                selectOption={selectOption}
                onLoadMore={asyncLoadOptions}
                menuHeightMin={menuHeightMin}
                filteredOptions={filteredOptionsNew}
                allItemsLoaded={isAllItemsLoaded}
              />
            ) : loading ? (
              <div
                className="select-options__loader"
                style={{
                  width: '100%',
                  display: 'flex',
                  margin: '10px 0',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Loader />
              </div>
            ) : (
              <div className={style.no_options_message} key="empty">
                No results
              </div>
            )}

            {creation && (
              <Creation
                creation={creation}
                topIndent={
                  loading
                    ? undefined
                    : filteredOptionsNew?.length
                    ? menuHeightMin
                    : optionHeight
                }
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

SelectSearch.defaultProps = {
  optionHeight: 26,
  maxOptions: 6,
};

export default SelectSearch;
