import { Config } from '../../../config/api';
import { authHeader, handleErrors } from '../../../utils/API';

import {
  TaxonomyCreateRequest,
  TaxonomyCreateResponse,
  TaxonomyDeleteRequest,
  TaxonomyDeleteResponse,
  TaxonomyGetByTypeRequest,
  TaxonomyGetByTypeResponse,
  TaxonomyGetListRequest,
  TaxonomyGetListResponse,
  TaxonomyGetRequest,
  TaxonomyGetResponse,
  TaxonomyUpdateRequest,
  TaxonomyUpdateResponse,
} from '@ternala/voltore-types';
import { TaxonomyTypeGetListRequest } from '@ternala/voltore-types/lib/modules/taxonomy/type/request.dto';
import { TaxonomyTypeGetListResponse } from '@ternala/voltore-types/lib/modules/taxonomy/type/response.dto';
import { appendSearchParams } from 'utils/appendSearchParams';
import { AsyncOptions } from 'components/UI/controls/SelectSearchCustom';
import {IError} from "../../model";

class API {
  public async getTaxonomies(
    getTaxonomyData: TaxonomyGetListRequest,
    token: string,
  ): Promise<TaxonomyGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'taxonomy/all');

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async getTaxonomyTypeListAction(
    token: string,
    getTaxonomyTypeList?: TaxonomyTypeGetListRequest,
  ): Promise<TaxonomyTypeGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'taxonomy/type/list');
    if (getTaxonomyTypeList) url = appendSearchParams(url, getTaxonomyTypeList);

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async getTaxonomiesByType(
    getTaxonomyData: TaxonomyGetByTypeRequest,
    token: string,
    searchParams?: AsyncOptions,
  ): Promise<TaxonomyGetByTypeResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'taxonomy/type');

    if (getTaxonomyData.type)
      url.searchParams.append('type', String(getTaxonomyData.type));
    if (searchParams) url = appendSearchParams(url, searchParams);

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async createTaxonomy(
    createTaxonomyData: TaxonomyCreateRequest,
    token: string,
  ): Promise<TaxonomyCreateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'taxonomy/create');

    return handleErrors(
      fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(createTaxonomyData),
      }),
    );
  }

  public async getTaxonomy(
    getTaxonomyData: TaxonomyGetRequest,
    token: string,
  ): Promise<TaxonomyGetResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'taxonomy/get');

    url.searchParams.append('id', String(getTaxonomyData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
      }),
    );
  }

  public async updateTaxonomy(
    updateTaxonomyData: TaxonomyUpdateRequest,
    token: string,
  ): Promise<TaxonomyUpdateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'taxonomy/update');

    return handleErrors(
      fetch(url.toString(), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(updateTaxonomyData),
      }),
    );
  }

  public async deleteTaxonomy(
    deleteTaxonomyData: TaxonomyDeleteRequest,
    token: string,
  ): Promise<TaxonomyDeleteResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'taxonomy/delete');

    return handleErrors(
      fetch(url.toString(), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token),
        },
        body: JSON.stringify(deleteTaxonomyData),
      }),
    );
  }
}
export const TaxonomyApi = new API();
