import { appName } from "config";
import { createAction, createAsyncAction } from "typesafe-actions";

import { widgetName } from "./widjet";
import { IException } from "controllers/store";

import {
  LeaseTransactionCreateRequestExpanded,
  LeaseTransactionUpdateRequestExpanded,
  IResponsePropertiesAdditionalFields
} from "../models"
import { IError, ILoader } from "models";

import {
    LeaseTransactionGetRequest,
    LeaseTransactionDeleteRequest,
    LeaseTransactionGetListRequest,
    LeaseTransactionCreateResponse,
    LeaseTransactionGetResponse,
    LeaseTransactionUpdateResponse,
    LeaseTransactionDeleteResponse,
    LeaseTransactionGetListResponse,
    LeaseTransactionStatusHistoryFullDTO,
    UpdateLeaseStatusDTO,
    UpdateActiveLeaseStatusDTO,
} from "@ternala/voltore-types";

// ** Lease Transaction actions

export const getLeaseTransactionsAction = createAsyncAction(
    `${appName}/${widgetName}/GET_LEASE_TRANSACTIONS_REQUEST`,
    `${appName}/${widgetName}/GET_LEASE_TRANSACTIONS_SUCCESS`,
    `${appName}/${widgetName}/GET_LEASE_TRANSACTIONS_FILED`
  )<LeaseTransactionGetListRequest & { callback?: Function } & IResponsePropertiesAdditionalFields & { callback?: Function }, {response: LeaseTransactionGetListResponse, searchParams: LeaseTransactionGetListRequest, additionalFields: IResponsePropertiesAdditionalFields,  isAll: boolean }, IException>();
  
  export const createLeaseTransactionAction = createAsyncAction(
    `${appName}/${widgetName}/CREATE_LEASE_TRANSACTION_REQUEST`,
    `${appName}/${widgetName}/CREATE_LEASE_TRANSACTION_SUCCESS`,
    `${appName}/${widgetName}/CREATE_LEASE_TRANSACTION_FILED`
  )<(LeaseTransactionCreateRequestExpanded & IResponsePropertiesAdditionalFields) & {callback?: Function}, {response: LeaseTransactionCreateResponse, additionalFields: IResponsePropertiesAdditionalFields}, IException>();
  
  export const getLeaseTransactionAction = createAsyncAction(
    `${appName}/${widgetName}/GET_LEASE_TRANSACTION_REQUEST`,
    `${appName}/${widgetName}/GET_LEASE_TRANSACTION_SUCCESS`,
    `${appName}/${widgetName}/GET_LEASE_TRANSACTION_FILED`
  )<LeaseTransactionGetRequest & { callback?: Function } & IResponsePropertiesAdditionalFields, {response: LeaseTransactionGetResponse, additionalFields: IResponsePropertiesAdditionalFields}, IException>();
  
  export const updateLeaseTransactionAction = createAsyncAction(
    `${appName}/${widgetName}/UPDATE_LEASE_TRANSACTION_REQUEST`,
    `${appName}/${widgetName}/UPDATE_LEASE_TRANSACTION_SUCCESS`,
    `${appName}/${widgetName}/UPDATE_LEASE_TRANSACTION_FILED`
  )<(LeaseTransactionUpdateRequestExpanded  & IResponsePropertiesAdditionalFields) & {callback?: Function}, {response: LeaseTransactionUpdateResponse, additionalFields: IResponsePropertiesAdditionalFields}, IException>();
  
  export const updateLeaseTransactionStatusAction = createAsyncAction(
    `${appName}/${widgetName}/UPDATE_LEASE_TRANSACTION_STATUS_REQUEST`,
    `${appName}/${widgetName}/UPDATE_LEASE_TRANSACTION_STATUS_SUCCESS`,
    `${appName}/${widgetName}/UPDATE_LEASE_TRANSACTION_STATUS_FILED`
  )<UpdateLeaseStatusDTO & IResponsePropertiesAdditionalFields & { callback?: Function }, LeaseTransactionStatusHistoryFullDTO, IException>();
  
  export const updateLeaseTransactionActiveStatusAction = createAsyncAction(
    `${appName}/${widgetName}/UPDATE_ACTIVE_LEASE_TRANSACTION_STATUS_REQUEST`,
    `${appName}/${widgetName}/UPDATE_ACTIVE_LEASE_TRANSACTION_STATUS_SUCCESS`,
    `${appName}/${widgetName}/UPDATE_ACTIVE_LEASE_TRANSACTION_STATUS_FILED`
  )<(UpdateActiveLeaseStatusDTO & IResponsePropertiesAdditionalFields) & {callback?: Function}, LeaseTransactionStatusHistoryFullDTO, IException>();
  
  export const deleteLeaseTransactionAction = createAsyncAction(
    `${appName}/${widgetName}/DELETE_LEASE_TRANSACTION_REQUEST`,
    `${appName}/${widgetName}/DELETE_LEASE_TRANSACTION_SUCCESS`,
    `${appName}/${widgetName}/DELETE_LEASE_TRANSACTION_FILED`
  )<(LeaseTransactionDeleteRequest & IResponsePropertiesAdditionalFields) & {callback?: Function}, {response: LeaseTransactionDeleteResponse, additionalFields: IResponsePropertiesAdditionalFields} & {id?: number}, IException>();
  
export const addLeaseTransactionLoader = createAction(`${widgetName}/ADD_LEASE_TRANSACTION_LOADER`)<ILoader & { additionalFields: IResponsePropertiesAdditionalFields }>();
export const addLeaseTransactionError = createAction(`${widgetName}/ADD_LEASE_TRANSACTION_ERROR`)<IError & { id: string } & { additionalFields: IResponsePropertiesAdditionalFields }>();
export const removeLeaseTransactionLoader = createAction(`${widgetName}/REMOVE_LEASE_TRANSACTION_LOADER`)<{ id: string } & { additionalFields: IResponsePropertiesAdditionalFields }>();
export const removeLeaseTransactionError = createAction(`${widgetName}/REMOVE_LEASE_TRANSACTION_ERROR`)<{ target: string } & { additionalFields: IResponsePropertiesAdditionalFields }>();