import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';

/* components */
import PropertyImagesPreviewsGallery from 'pages/Properties/Gallery/GalleryPreview';
import PopupDelete from 'components/modals/PopupDelete';
import OptionsMenu from 'components/UI/controls/OptionsMenu';

/* controllers */
import {
  deletePropertyAction,
  getPropertyAction,
} from 'controllers/property/actions';

/* constants & utils */
import { PageEnum, SQFT_IN_ACRE } from 'config/constants';
import { withThousandsSeparators } from 'utils/helper-functions';

/* types */
import { IStore } from 'controllers/store';
import Tags from 'components/Tags/Tags';
import { activityHistoryTypeEnum } from '@ternala/voltore-types/lib/constants';
import HistoryContext from 'context/History';
import { addNotification } from 'controllers/modals/actions';
import uuid from 'utils/uuid';
import { NotificationTypeEnum } from 'controllers/modals/models.d';
import { CloseSmallIcon } from 'components/icons/CloseSmallIcon';
import Loader from 'components/Loader';
import { IError } from 'models';
import { AddressField } from 'components/views/address';
import { addShowedElement } from 'controllers/showElement/actions';
import { propertyModalKey } from 'components/modals/properties/PropertyModalConent';
import { ShowElementEnum } from 'controllers/showElement/models.d';
import { TagEntityEnum } from "@ternala/voltore-types/lib/card";

interface Props {
  propertyId: number;
  tabName: string;
  children?: ReactNode;
  modal?: boolean;
  onClose?: () => void;
  loading?: boolean;
  error?: IError;
}

const PropertyInfo: FC<Props> = (props) => {
  const {
    propertyId,
    modal,
    onClose,
    loading,
    error,
  } = props;
  const history = useContext(HistoryContext);

  const dispatch = useDispatch();
  const property = useSelector((store: IStore) => store.property.propertyData[propertyId])
  const [isPopupDeleteOpen, setIsPopupDeleteOpen] = useState<boolean>(false);

  useEffect(() => {
    if (modal)
      dispatch(
        getPropertyAction.request({
          id: propertyId,
        }),
      );
  }, []);

  return !error ? (
    property ? (
      <>
        <div className="property-info">
          <Helmet>
            <title>
              Property | Voltore
              {process.env.REACT_APP_COMPANY_NAME
                ? ` | ${process.env.REACT_APP_COMPANY_NAME}`
                : ''}
            </title>
            <meta name="description" content="Voltore application" />
          </Helmet>
          <header className="property-info__title">
            <h2>Property info</h2>
            <div className="options">
              <OptionsMenu
                on={{
                  edit: () => {
                    // setIsModalOpen(true)
                    dispatch(addShowedElement({
                      key: propertyModalKey,
                      id: uuid(),
                      type: ShowElementEnum.modal,
                      props: {
                        id: propertyId
                      }
                    }))
                  },
                  delete: () => setIsPopupDeleteOpen(true),
                  ...(!onClose && {
                    history: () =>
                    {
                      history.setEntity({
                        id: property.id,
                        type: activityHistoryTypeEnum.property,
                      })
                    },
                  }),
                }}
              />
              {onClose ? (
                <div className="cross-icon" onClick={onClose}>
                  <CloseSmallIcon />
                </div>
              ) : (
                ''
              )}
            </div>
          </header>

          <div className="system-id">
            <span className="system-id__key">System id:&nbsp;</span>
            <span className="system-id__value">{property?.id}</span>
          </div>

          <PropertyImagesPreviewsGallery
            images={property.images}
            isLoading={loading}
            key={"gallery" + propertyId}
          />

          <div className={`property-info__content ${modal ? 'column' : ''}`}>
            <div className="content">
              <div className="data-field">
                <div
                  className={`data-field__key_lg ${
                    property?.propertyGroup ? '' : 'no-information'
                  }`}>
                  Property Group
                </div>
                <div className="data-field__value">
                  {property?.propertyGroup ? (
                    property.propertyGroup.title
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>
              </div>

              <AddressField label={'Site address'} address={property.address} />

              <AddressField
                label={'Tax billing address'}
                address={property.taxBillingAddress}
              />

              <div className="data-field">
                <div
                  className={`data-field__key_lg ${
                    property?.propertyType && property.propertyType.length > 0
                      ? ''
                      : 'no-information'
                  }`}>
                  Property type
                </div>
                <div className="data-field__value">
                  {property?.propertyType &&
                  property.propertyType.length > 0 ? (
                    property.propertyType.map((item) => item.title).join(', ')
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>
              </div>

              <div className="data-field">
                <div
                  className={`data-field__key_lg ${
                    property?.subPropertyType &&
                    property.subPropertyType.length > 0
                      ? ''
                      : 'no-information'
                  }`}>
                  Sub-property type
                </div>
                <div className="data-field__value">
                  {property?.subPropertyType &&
                  property.subPropertyType.length > 0 ? (
                    property.subPropertyType
                      .map((item) => item.title)
                      .join(', ')
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>
              </div>

              <div className="data-field">
                <div
                  className={`data-field__key_lg ${
                    property?.secondaryPropertyType &&
                    property.secondaryPropertyType.length > 0
                      ? ''
                      : 'no-information'
                  }`}>
                  Secondary type
                </div>
                <div className="data-field__value">
                  {property?.secondaryPropertyType &&
                  property.secondaryPropertyType.length > 0 ? (
                    property.secondaryPropertyType
                      .map((item) => item.title)
                      .join(', ')
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>
              </div>

              <div className="data-field">
                <div
                  className={`data-field__key_lg ${
                    property?.buildingClass && property.buildingClass.length > 0
                      ? ''
                      : 'no-information'
                  }`}>
                  Building class
                </div>
                <div className="data-field__value">
                  {property?.buildingClass &&
                  property.buildingClass.length > 0 ? (
                    property.buildingClass.map((item) => item.title).join(', ')
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>
              </div>

              <div className="data-field">
                <div
                  className={`data-field__key_lg ${
                    property?.sqft ? '' : 'no-information'
                  }`}>
                  Building sqft
                </div>
                <div className="data-field__value">
                  {property?.sqft ? (
                    <>{withThousandsSeparators(property.sqft)} SQFT</>
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>
              </div>

              <div className="data-field">
                <div
                  className={`data-field__key_lg ${
                    property?.lotSqft ? '' : 'no-information'
                  }`}>
                  Lot sqft
                </div>
                <div className="data-field__value">
                  {property?.lotSqft ? (
                    `${withThousandsSeparators(property.lotSqft)} SQFT (
                  ${(property.lotSqft / SQFT_IN_ACRE).toFixed(2)} Acre)`
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>
              </div>
              <div className="data-field">
                <div
                  className={`data-field__key_lg ${
                    property?.parcels && property?.parcels.length > 0
                      ? ''
                      : 'no-information'
                  }`}>
                  Parcel #
                </div>
                <div className="data-field__value">
                  {property?.parcels && property?.parcels.length > 0 ? (
                    property?.parcels?.map((item) => item).join(', ')
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>
              </div>

              {/*<div className="data-field">*/}
              {/*  <div*/}
              {/*    className={`data-field__key_lg ${*/}
              {/*      property?.taxes ? '' : 'no-information'*/}
              {/*    }`}>*/}
              {/*    Property taxes*/}
              {/*  </div>*/}
              {/*  <div className="data-field__value">*/}
              {/*    {property?.taxes ? (*/}
              {/*      `$${withThousandsSeparators(property.taxes)} ${*/}
              {/*        property.taxesYear ? `(${property.taxesYear})` : ''*/}
              {/*      }`*/}
              {/*    ) : (*/}
              {/*      <span className="no-information">-</span>*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="data-field">
                <div
                  className={`data-field__key_lg ${
                    property?.zoning ? '' : 'no-information'
                  }`}>
                  Zoning
                </div>
                <div className="data-field__value">
                  {property?.zoning ? (
                    property.zoning.title
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>
              </div>

              <div className="data-field">
                <div
                  className={`data-field__key_lg ${
                    property?.floors && property.floors.length > 0
                      ? ''
                      : 'no-information'
                  }`}>
                  # of Floors
                </div>
                <div className="data-field__value">
                  {property?.floors && property.floors.length > 0 ? (
                    property.floors.length
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>
              </div>

              <div className="data-field">
                <div
                  className={`data-field__key_lg ${
                    property?.parking ? '' : 'no-information'
                  }`}>
                  Parking
                </div>
                <div className="data-field__value">
                  {property?.parking ? (
                    property.parking
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>
              </div>

              <div className="data-field">
                <div
                  className={`data-field__key_lg ${
                    property?.yearBuild ? '' : 'no-information'
                  }`}>
                  Year built
                </div>
                <div className="data-field__value">
                  {property?.yearBuild ? (
                    property.yearBuild
                  ) : (
                    <span className="no-information">-</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Tags
          cards={property.cardTagConnects || []}
          tabName={'properties'}
          entity={{
            id: propertyId,
            type: TagEntityEnum.Property,
          }}
          preview={!!onClose}
          loading={loading}
        />

        {isPopupDeleteOpen && (
          <PopupDelete
            onClose={() => setIsPopupDeleteOpen(false)}
            onSubmit={(callback) => {
              dispatch(
                deletePropertyAction.request({
                  id: propertyId,
                  callback: (status: boolean) => {
                    if (status) {
                      // if delete was success - the additional popup appear
                      dispatch(
                        addNotification({
                          id: uuid(),
                          text: 'Successfully deleted',
                          type: NotificationTypeEnum.success,
                          title: 'Successfully deleted',
                        }),
                      );
                      if (callback) callback();
                    } else {
                      // if delete was wrong - the additional popup appear
                      dispatch(
                        addNotification({
                          id: uuid(),
                          text: 'Something goes wrong',
                          type: NotificationTypeEnum.error,
                          title: 'Something goes wrong',
                        }),
                      );
                    }
                  },
                }),
              );
              dispatch(push(`/${PageEnum.PROPERTIES}`));
            }}
          />
        )}
      </>
    ) : onClose ? (
      <div className="preview-modal-loader">
        <Loader />
      </div>
    ) : (
      <div className="item-info__loader_info-tab">
        <Loader />
      </div>
    )
  ) : (
    <div className="property-info">{error?.message}</div>
  );
};
export default PropertyInfo;
