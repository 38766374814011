import React, { FC, useState, useEffect, createContext } from 'react'

import { google, Loader, LoaderOptions } from 'google-maps'
import { API_KEY_WITH_DRAWING } from 'config/google-maps'

const options: LoaderOptions = {
   language: "en"
}
const loader = new Loader(API_KEY_WITH_DRAWING, options)

const initialState = {
   GoogleMaps: undefined as typeof google.maps | undefined
}

interface Props {
   children: JSX.Element | JSX.Element[]
}

export const GoogleMapsAPIContext = createContext(initialState)

export const GoogleMapsAPIProvider: FC<Props> = ({ children }) => {
   const [mapsAPI, setMapsAPI] = useState<google | undefined>()

   useEffect(() => {
      (async () => {
         setMapsAPI(await loader.load())
      })()
   }, [])

   return (
      <GoogleMapsAPIContext.Provider value={{ GoogleMaps: mapsAPI?.maps }}>
         { children }
      </GoogleMapsAPIContext.Provider>
   )
}

export default GoogleMapsAPIProvider