import { appName } from "config";
import {createAction, createAsyncAction} from "typesafe-actions";

import { IGoogleApiSearchResponse, ILoaderState } from './models';

/* Actions */
export const widgetName = "GOOGLE_MAP_SEARCH";

export const setLoadingStatus = createAction(
    `${appName}/${widgetName}/SET_LOADING_STATUS`
  )<ILoaderState>();

export const searchRequestAction = createAsyncAction(
  `${appName}/${widgetName}/SEARCH_REQUEST`,
  `${appName}/${widgetName}/SEARCH_SUCCESS`,
  `${appName}/${widgetName}/SEARCH_FAILED`
)<string, IGoogleApiSearchResponse, IGoogleApiSearchResponse>();

