import React, { useContext } from "react";
import { ContentBlock } from 'draft-js';
import { CountActionsContext } from "../../../Contexts";
export const Action: React.FC<any> = (
  props
) => {
  const { countActions } = useContext(CountActionsContext);
  return (
    <span className={"action"} style={{color: countActions === 0 ? "#F10707" : "#4265AB"}}>
      {props.children}
    </span>
  );
};

export function findActions(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
) {
  const text = contentBlock.getText();
  let matchArr, start

  if((matchArr = /([\s+]|^)@(.*)/g.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length)
  }
}
